import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import {LocalStorageService} from '../../services/localStorage.service';

@Component({
  selector: 'app-thatsall',
  templateUrl: './thatsall.component.html',
  styleUrls: ['./thatsall.component.css']
})
export class ThatsallComponent implements OnInit {

  constructor(public router: Router,private localStorageService:LocalStorageService) { }
  timeLeft = 5;
  interval;
  ngOnInit() {
    this.startTimer();
  }

  startTimer() {
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if(this.timeLeft > 0) {
        this.timeLeft--;
        // console.log(this.timeLeft)
      } else if (this.timeLeft == 0) {
        // WC 07312022 -- To redirect to pricing if the user has selected a plan
        // console.log('this.localStorageService.getItem(\'isPricingRedirect\'): ', this.localStorageService.getItem('isPricingRedirect') )
        if (this.localStorageService.getItem('isPricingRedirect') == 'true') {
          // console.log('redirecting to pricing');
          this.router.navigate(['/pricing']);
        } else {
          // console.log('redirecting to dashboard');
          this.router.navigate(['/profile']);
        }
        // WC 07312022
        clearInterval(this.interval);
      } else {
        this.timeLeft = 60;
      }
    },1000)
  }

  goToHome() {
    clearInterval(this.interval);

    // WC 07312022 -- To redirect to pricing if the user has selected a plan
    if (this.localStorageService.getItem('isPricingRedirect')) {
      // console.log('redirecting to pricing');
      this.router.navigate(['/pricing']);
    } else {
      // console.log('redirecting to dashboard');
      this.router.navigate(['/profile']);
    }
    // WC 07312022
  }

}
