import {Component, OnInit} from '@angular/core';
import {CrudService} from '../../../services/crud.service';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-interactive-videos-dialog',
    templateUrl: './interactive-videos-dialog.component.html',
    styleUrls: ['./interactive-videos-dialog.component.css']
})
export class InteractiveVideosDialogComponent implements OnInit {
    spinner = false;
    publishedVideos = [];
    publishedCount = 0;
    sort = 'recent';
    limit = 12;
    tab1Page = 1;

    constructor(
        private crudService: CrudService,
        private dialogRef: MatDialogRef<InteractiveVideosDialogComponent>,
    ) {
    }

    ngOnInit(): void {
        this.changeFilter(this.sort);
    }

    onScrollTab(): void {
        ++this.tab1Page;
        this.getInteractiveVideos(this.limit, this.tab1Page, false, this.sort);
    }

    changeFilter(sort: any) {
        this.tab1Page = 1;
        this.spinner = true;
        this.getInteractiveVideos(this.limit, this.tab1Page, true, sort);
        // Get count of main videos
        this.crudService.getAllMainVideosCountV2().subscribe(success => {
            const videoCounts = success.data;

            for (const item of videoCounts) {

                if (item._id.isPublished) {
                    if (item._id.isPublished)
                        this.publishedCount = item.count;
                }
            }
            this.spinner = false;
        });
    }

    getInteractiveVideos(limit: number, page: number, isOnInit: boolean, sort: string) {

        // console.log("getInteractiveVideo - isPublished = " + isPublished + ", page = " + page + ", isOnInit = " + isOnInit);

        this.crudService.getInteractiveVideoV2(null, true, limit, page, sort).subscribe(success => {

            const mainVideos = success.data;

            if (isOnInit) {
                this.publishedVideos = mainVideos;
            } else {
                for (const item of mainVideos) {
                    this.publishedVideos.push(item);
                }
            }            
            this.spinner = false;
        });
    };

    videoSelected(id) {
        this.dialogRef.close(id);
    }
}
