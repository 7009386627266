import {Injectable} from '@angular/core';
import {EncryptStorage} from 'encrypt-storage';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService implements Storage {

    private allowEncrypt = true;
    private storage: Storage;
    private encryptStorage: EncryptStorage;

    constructor() {
        this.storage = window.localStorage;
        this.encryptStorage = new EncryptStorage('ff-secret-key-value');
    }

    [name: string]: any;

    length: number;

    clear(): void {
        this.storage.clear();
    }

    getItem(key: string): string | null {
        const value = this.storage.getItem(key);
        if (value && this.allowEncrypt) {
            try {
                return this.encryptStorage.decryptValue(value);
            } catch (e) {
                this.removeItem(key);
                return null;
            }
        }
        return value;
    }

    key(index: number): string | null {
        return this.storage.key(index);
    }

    removeItem(key: string): void {
        return this.storage.removeItem(key);
    }

    setItem(key: string, value: string): void {
        if (this.allowEncrypt) {
            const encryptValue = this.encryptStorage.encryptValue(value);
            return this.storage.setItem(key, encryptValue);
        }
        return this.storage.setItem(key, value);
    }
}
