import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    Output
} from '@angular/core';

@Directive({
    selector: '[appDurationPickerDirective]'
})
export class DurationPickerDirective implements OnChanges {
    private navigationKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
    @Output() choiceValue = new EventEmitter<string>();
    @Input() duration = 0;
    @Input() currentDuration = '000:00:00';
    picker: HTMLInputElement;

    constructor(public el: ElementRef) {
        setTimeout(()=>{
            this.picker = el.nativeElement;
            this.picker.value = this.currentDuration;
            this.picker.style.textAlign = 'right';
            const scrollUpBtn = document.createElement('button');
            const scrollDownBtn = document.createElement('button');
            scrollUpBtn.style.cssText = 'border: 1px solid #6e5eff;padding: 2px 6px;border-radius: 5px;background: #6e5eff;color: #ffff;font-size: 15px;margin: 0 auto;cursor: pointer;position: relative;outline: none;';
            scrollDownBtn.style.cssText = 'border: 1px solid #6e5eff;padding: 2px 8px;border-radius: 5px;background: #6e5eff;color: #ffff;font-size: 15px;margin: 0 auto;cursor: pointer;position: relative;outline: none;';
            scrollUpBtn.style.margin = '0px 5px';
            scrollDownBtn.textContent = ' - ';
            scrollUpBtn.textContent = ' + ';
            scrollUpBtn.addEventListener('click', e => {
                this.increaseValue(this.picker);
            });
            scrollDownBtn.addEventListener('click', e => {
                this.decreaseValue(this.picker);
            });
            this.picker.parentNode.insertBefore(scrollDownBtn, this.picker.nextSibling);
            this.picker.parentNode.insertBefore(scrollUpBtn, this.picker.nextSibling);
        });
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(e: KeyboardEvent) {
        if (this.navigationKeys.indexOf(e.key) > -1) {
            // let it happen, don't do anything
            return;
        }
        if (e.key === ' ' || isNaN(Number(e.key))) {
            e.preventDefault();
        }
    }

    @HostListener('keyup', ['$event'])
    onKeyUp(e: KeyboardEvent) {
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            this.decreaseValue(e.target);
        }
        if (e.key === 'ArrowUp') {
            e.preventDefault();
            this.increaseValue(e.target);
        }
        this.validateInput(e); // prevent default
    }
    @HostListener('change', ['$event']) ngOnChanges(e) {
        if (e.type === 'change') {
            this.validateInput(e);
        }
    }

    @HostListener('click', ['$event']) ngOnClick(e) {
        this.selectFocus(e);
    }

    insertFormatted = (inputBox, secondsValue) => {
        const hours = Math.floor(secondsValue / 3600);
        secondsValue %= 3600;
        const minutes = Math.floor(secondsValue / 60);
        const seconds = secondsValue % 60;
        const minutesString = String(minutes).padStart(2, '0');
        const hoursString = String(hours).padStart(3, '0');
        const secondsString = String(seconds).padStart(2, '0');
        inputBox.value = hoursString + ':' + minutesString + ':' + secondsString;
        this.choiceValue.emit(inputBox.value);
    };
    increaseValue = inputBox => {
        const rawValue = inputBox.value;
        const sectioned = rawValue.split(':');
        let secondsValue = 0;
        if (sectioned.length === 3) {
            secondsValue =
                Number(sectioned[2]) +
                Number(sectioned[1] * 60) +
                Number(sectioned[0] * 60 * 60);
        }
        secondsValue += 1;
        this.insertFormatted(inputBox, secondsValue);
    };
    decreaseValue = inputBox => {
        const rawValue = inputBox.value;
        const sectioned = rawValue.split(':');
        let secondsValue = 0;
        if (sectioned.length === 3) {
            secondsValue =
                Number(sectioned[2]) +
                Number(sectioned[1] * 60) +
                Number(sectioned[0] * 60 * 60);
        }
        secondsValue -= 1;
        if (secondsValue < 0) {
            secondsValue = 0;
        }
        this.insertFormatted(inputBox, secondsValue);
    };
    validateInput(event) {
        const sectioned = event.target.value.split(':');
        if (sectioned.length !== 3) {
            event.target.value = '000:00:00'; // fallback to default
            return;
        }
        if (isNaN(sectioned[0])) {
            sectioned[0] = '000';
        }
        if (isNaN(sectioned[1]) || sectioned[1] < 0) {
            sectioned[1] = '00';
        }
        if (sectioned[1] > 59 || sectioned[1].length > 2) {
            sectioned[1] = '59';
        }
        if (isNaN(sectioned[2]) || sectioned[2] < 0) {
            sectioned[2] = '00';
        }
        if (sectioned[2] > 59 || sectioned[2].length > 2) {
            sectioned[2] = '59';
        }
        event.target.value = sectioned.join(':');
        this.choiceValue.emit(event.target.value);
    }
    selectFocus = event => {
        // get cursor position and select nearest block;
        const cursorPosition = event.target.selectionStart;
        '000:00:00'; // this is the format used to determine cursor location
        const hourMarker = event.target.value.indexOf(':');
        const minuteMarker = event.target.value.lastIndexOf(':');
        if (hourMarker < 0 || minuteMarker < 0) {
            // something wrong with the format. just return;
            return;
        }
        if (cursorPosition < hourMarker) {
            event.target.selectionStart = 0; // hours mode
            event.target.selectionEnd = hourMarker;
        }
        if (cursorPosition > hourMarker && cursorPosition < minuteMarker) {
            event.target.selectionStart = hourMarker + 1; // minutes mode
            event.target.selectionEnd = minuteMarker;
        }
        if (cursorPosition > minuteMarker) {
            event.target.selectionStart = minuteMarker + 1; // seconds mode
            event.target.selectionEnd = minuteMarker + 3;
        }
    };
}
