import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {CrudService} from '../../services/crud.service';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DashboardService} from '../../services/dashboard.service';
import {environment} from '../../../environments/environment';
import insertTextAtCursor from 'insert-text-at-cursor';
import {ActivatedRoute} from '@angular/router';
import {Editor, Toolbar} from 'ngx-editor';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $;

@Component({
  selector: 'app-build-community',
  templateUrl: './build-community.component.html',
  styleUrls: ['./build-community.component.css']
})
export class BuildCommunityComponent implements OnInit, AfterViewInit {
  @ViewChild('maiTextarea') mainTextareaEl;
  spinner = false;
  fileToUpload: File = null;
  previewEmail = '';
  sendEmailForm: UntypedFormGroup;
  isIE = false;
  defaultEmailSubject = 'Hello';
  groups = [];
  groupsLinks = [];
  defaultEmailBody = 'Hey {firstName},\n \n' +
      'Hope you’re doing well! We are excited to introduce our new subscription community. ' +
      'Here’s the {myCommunityLink} for you to explore our subscription plans.\n \n' +
      'Thank you!\n' +
      '{businessName}';
  businessName = '';
  isShowStep = false;
  communityUrl = '';

  toolbar: Toolbar = [
    ['bold', 'italic'],
    //WC 10102024 --- PenTestFix
    //['underline', 'strike'],
    ['underline'],
    ['ordered_list', 'bullet_list'],
    //WC 10102024 --- PenTestFix
    //[{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
    ['link', 'image'],
  ];
  postBodyEditor: Editor;

  constructor(
      private activatedRoute: ActivatedRoute,
      private toaster: ToastrService,
      private crudService: CrudService,
      private dashboardService: DashboardService,
      private localStorageService: LocalStorageService
  ) {
    this.postBodyEditor = new Editor();

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.step === 'true') {
        this.isShowStep = true;
      }
    });
    this.sendEmailForm = new UntypedFormBuilder().group({
      file: new UntypedFormControl(''),
      emailSubject: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
      emailBody: new UntypedFormControl('', [Validators.required]),
      businessName: new UntypedFormControl('')
    });

  }

  get sendEmailControls() {
    return this.sendEmailForm.controls;
  }

  get postBodyLength() {
    return this.postBodyEditor.view.dom.innerText.length;
  }

  ngOnInit(): void {
    this.dashboardService.getUserData.subscribe((user) => {
      if (user) {
        if (user?.business?.name)
          this.businessName = user?.business?.name;
        else
          this.businessName = user.firstname + ' ' + user.lastname;

        // if (user?.business?.communityUrl)
        //   this.communityUrl = environment.currentDomain + 'main-community/' + user?.business?.communityUrl;
        // else
        //   this.communityUrl = environment.currentDomain + 'main-community?user=' + this.localStorageService.getItem('user');


        if (user?.business?.communityUrl)
          this.communityUrl = environment.currentDomain + 'my-community/' + user?.business?.communityUrl;
        else
          this.communityUrl = environment.currentDomain + 'my-community?user=' + this.localStorageService.getItem('user');

      }
    });


    const payload = {
      accessType: 'ADMIN',
      isPlanAssigned: true,
      isSubscriptionGroup: true
    };
    this.crudService.getAllGroups(payload).subscribe(res => {
      this.groups = res.data;
      const slugify = groupName => groupName.toLowerCase().trim()
          .replace(/[^\w\s-]/g, '')
          .replace(/[\s_-]+/g, '_')
          .replace(/^-+|-+$/g, '');
      if (this.groups && this.groups.length) {
        for (const group of this.groups) {
          this.groupsLinks.push({
            key: slugify(group.groupName) + '_link',
            value: environment.currentDomain + 'community/' + group._id
          });
        }
      }
    });

    this.getEmailTemplate();
  }

  ngAfterViewInit() {
    // this.mainTextareaEl = document.getElementById('main-textarea');
    // console.log(this.mainTextareaEl.nativeElement, 11)
  }

  onFileSelect(files: FileList) {
    this.fileToUpload = files.item(0);
    const allowTypes = [
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.ms-excel',
      'text/csv'
    ];
    if (this.fileToUpload) {
      if (!allowTypes.includes(this.fileToUpload.type)) {
        this.toaster.error('Only excel and csv file allowed')
        this.fileToUpload = null;
        return false;
      }
    }
  }

  toFormData(formValue) {
    const formData = new FormData();
    for (const key of Object.keys(formValue)) {
      const value = formValue[key];
      formData.append(key, value);
    }
    return formData;
  }

  containsDisallowedScripts(comment: string): boolean {

    // Build a regex pattern to match any of the tags in the list
    const pattern = new RegExp(`<\\/?(${environment.maliciousHtmlTags.join('|')})(\\s|>)`, 'i');

    // Test the input string for any matches
    return pattern.test(comment);
  };

  containsDisallowedEvents(comment: string): boolean {

    // Build a regex pattern to match any of the tags in the list
    const pattern = new RegExp(`\\b(${environment.maliciousEventHandlers.join('|')})\\b=`, 'i');

    // Test the input string for any matches
    return pattern.test(comment);
  }

  decodeHtml(html: string): string {
      const txt = document.createElement('textarea');
      txt.innerHTML = html; // Decode HTML entities
      return txt.value; // Return decoded value
  }

  stripHtml(html: string): string {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || ''; // Return plain text
  }
  

  onSubmit(isPreview = false) {
    if (!this.fileToUpload) {
      this.toaster.error('Please upload excel/csv file');
      return;
    }
    if (this.postBodyLength > 1000) {
      return;
    }

    // WC 10232024 --- The validation is done at the API level
    // //WC 10102024 --- PenTestFix to check for malicious HTML tags or even handlers to prevent XSS attacks
    // // Check for disallowed scripts in the comment
    // if (this.containsDisallowedScripts(this.sendEmailForm.value.emailBody)) {
    //   this.toaster.error('Invalid input detected!');
    //   return;
    // }

    // if (this.containsDisallowedEvents(this.sendEmailForm.value.emailBody)) {
    //   this.toaster.error('Invalid input detected!');
    //   return;
    // }
    // //WC 10102024 --- PenTestFix

    // console.log('onSubmit - this.sendEmailForm.value = ', this.sendEmailForm.value);

    if (this.sendEmailForm.valid) {
      this.spinner = true;
      const formData = this.sendEmailForm.value;

      const payload = {
        file: this.fileToUpload,
        emailSubject: formData.emailSubject,
        // emailBody: formData.emailBody,
        emailBody: this.stripHtml(this.decodeHtml(formData.emailBody)),
        myCommunityLink: this.communityUrl,
        businessName: this.businessName,
        isPreview
      }
      for (const groupsLink of this.groupsLinks) {
        payload[groupsLink.key] = groupsLink.value;
      }

      this.crudService.sendCommunityEmail(this.toFormData(payload)).subscribe((res) => {
        if (isPreview) {
          // this.previewEmail = res.data.emailBody || '';
          this.previewEmail = this.stripHtml(this.decodeHtml(res.data.emailBody || ''));

          $('#previewEmailModal').modal('show');
        } else {
          this.sendEmailForm.reset();
          this.fileToUpload = null;
          this.previewEmail = null;
          this.getEmailTemplate();
          this.toaster.success(res.message);
        }
      }).add(() => this.spinner = false);
    }

  }

  errorHandling = (control: string, error: string, form = this.sendEmailForm) => {
    return form.controls[control].hasError(error) && (form.controls[control].dirty || form.controls[control].touched);
  }

  openSample() {
    this.crudService.getFileTemplateForEmail().subscribe((res) => {
      this.downloadFile(res.data.fileUrl, res.data.fileName);
    }, (err) => {
      this.toaster.error('Something Went Wrong!');
    })
  }

  public downloadFile(filePath: string, fileName: string) {
    const isInternetExplorer = this.isIE || !!document.DOCUMENT_NODE;
    if (isInternetExplorer) {
      window.open(filePath, fileName);
    } else {
      const link = document.createElement('a');
      link.download = fileName;
      link.href = filePath;
      link.click();
    }
  }

  private getEmailTemplate() {
    this.spinner = true;
    this.crudService.getEmailTemplate(null).subscribe((res) => {
      if (res.data.result) {
        this.sendEmailForm.patchValue({
          emailSubject: res.data.result?.emailSubject || this.defaultEmailSubject,
          emailBody: this.stripHtml(this.decodeHtml(res.data.result?.emailBody || '')) || this.defaultEmailBody,
          // emailBody: res.data.result?.emailBody || this.defaultEmailBody,
        });
      } else {
        this.sendEmailForm.patchValue({
          emailSubject: this.defaultEmailSubject,
          emailBody: this.defaultEmailBody,
        });
      }
    }).add(() => this.spinner = false);
  }

  addVariable(variableEl) {
    this.postBodyEditor.view.dom.focus();
    insertTextAtCursor(this.postBodyEditor.view.dom, variableEl.value);
    variableEl.value = '';
  }

}
