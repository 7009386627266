import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { CrudService } from '../../../services/crud.service';

@Component({
  selector: 'app-video-tree',
  templateUrl: './video-tree.component.html',
  styleUrls: ['./video-tree.component.css'],
})
export class VideoTreeComponent implements OnInit, OnDestroy {
  rangeValue = 0.5;
  subscription: Subscription;
  currentVideoId: string;
  currentUserName: string;
  isCompleted: boolean;
  currentVideoData: any;
  currentParent: any;
  showTree = false;

  constructor(
    public readonly dialogRef: MatDialogRef<VideoTreeComponent>,
    private readonly crudService: CrudService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.currentVideoId = this.data.videoId;
    this.currentUserName = this.data.userName;
    this.isCompleted = this.data.isCompleted;
    this.getVideoInfo(this.currentVideoId);
  }

  increaseRange() {
    const val = this.rangeValue + 0.1;
    if (val <= 1) {
      this.rangeValue = val;
    }
  }

  decreaseRange() {
    const val = this.rangeValue - 0.1;
    if (val >= 0.4) {
      this.rangeValue = val;
    }
  }

  resetRange() {
    this.rangeValue = 0.5;
  }

  getVideoInfo(videoId: string) {
    this.subscription = this.crudService
      .getVideoInfov2(videoId)
      .subscribe((response) => {
        this.currentVideoData = response.data;
        if (this.currentVideoData.videoinfo.type === 'main') {
          this.getTreeData(this.currentVideoData.videoinfo._id);
        } else {
          this.getTreeData(this.currentVideoData.videoinfo.mainId);
        }
      });
  }

  getTreeData(mainId: string) {
    this.subscription = this.crudService.getTree(mainId).subscribe((res) => {
      this.showTree = true;
      this.currentParent = res.data;
    });
  }

  onCloseModal() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
