import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ToastrService} from 'ngx-toastr';

import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import insertTextAtCursor from 'insert-text-at-cursor';
import {CrudService} from '../../../services/crud.service';
import {DashboardService} from '../../../services/dashboard.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Editor, Toolbar} from "ngx-editor";
import { DomSanitizer } from '@angular/platform-browser';


declare var $;

@Component({
    selector: 'app-contact-group',
    templateUrl: './contact-group.component.html',
    styleUrls: ['./contact-group.component.css']
})
export class ContactGroupComponent implements OnInit, AfterViewInit {
    @ViewChild('maiTextarea') mainTextareaEl:ElementRef;
    spinner = false;
    fileToUpload: File = null;
    previewEmail = '';
    sendEmailForm: UntypedFormGroup;
    isIE = false;
    defaultEmailSubject = 'Hello';
    groups = [];
    isPreview = false;

    defaultEmailBody = 'Hey {firstName},\n \n' +
        'Hope you’re doing well! We are excited to introduce our new group. ' +
        'Here’s the {groupLink} for you to explore the video contents.\n \n' +
        'Thank you!\n' +
        '{groupAdminName}';

    groupDetailId: any;
    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['code', 'blockquote'],
        ['ordered_list', 'bullet_list'],
        [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];
    postBodyEditor: Editor;


    constructor(
        private activatedRoute: ActivatedRoute,
        private toaster: ToastrService,
        private crudService: CrudService,
        private dashboardService: DashboardService,
        private sanitizer: DomSanitizer
    ) {

        this.postBodyEditor = new Editor();

        this.sendEmailForm = new UntypedFormBuilder().group({
            file: new UntypedFormControl(''),
            emailSubject: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
            emailBody: new UntypedFormControl('', [Validators.required, Validators.maxLength(1000)]),
            businessName: new UntypedFormControl('')
        });

    }

    get sendEmailControls() {
        return this.sendEmailForm.controls;
    }
    get postBodyLength() {
        return this.postBodyEditor.view.dom.innerText.length;
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((param: Params) => {
            this.groupDetailId = param.id;
            // this.getGroupData();
        });
        // this.dashboardService.getUserData.subscribe((user) => {
        //     if (user) {
        //         if (user?.business?.name) {
        //             //this.sendEmailForm.patchValue({businessName: user?.business?.name});
        //             this.businessName = user?.business?.name;
        //         } else {
        //             //this.sendEmailForm.patchValue({businessName: user.firstname + ' ' + user.lastname});
        //             this.businessName = user.firstname + ' ' + user.lastname;
        //         }
        //     }
        // });


        // const payload = {
        //     accessType: 'ADMIN',
        //     isPlanAssigned: true,
        //     isSubscriptionGroup: true
        // };
        // this.crudService.getAllGroups(payload).subscribe(res => {
        //     this.groups = res.data;
        //     const slugify = groupName => groupName.toLowerCase().trim()
        //         .replace(/[^\w\s-]/g, '')
        //         .replace(/[\s_-]+/g, '_')
        //         .replace(/^-+|-+$/g, '');
        //     if (this.groups && this.groups.length) {
        //         for (const group of this.groups) {
        //             this.groupsLinks.push({
        //                 key: slugify(group.groupName) + '_link',
        //                 value: environment.currentDomain + 'community/' + group._id
        //             });
        //         }
        //     }
        // });

        this.getEmailTemplate();
    }

    ngAfterViewInit() {
        // this.mainTextareaEl = document.getElementById('main-textarea');
        // console.log(this.mainTextareaEl.nativeElement, 11)
    }

    onFileSelect(files: FileList) {
        this.fileToUpload = files.item(0);
        const allowTypes = [
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-excel',
            'text/csv'
        ];
        if (this.fileToUpload) {
            if (!allowTypes.includes(this.fileToUpload.type)) {
                this.toaster.error('Only excel and csv file allowed')
                this.fileToUpload = null;
                return false;
            }
        }
    }

    toFormData(formValue) {
        const formData = new FormData();
        for (const key of Object.keys(formValue)) {
            const value = formValue[key];
            formData.append(key, value);
        }
        return formData;
    }

    onSubmit(isPreview = false) {
        this.isPreview = isPreview;

        if (!isPreview && !this.fileToUpload)
            $('#rosterNotAvailableModal').modal('show');
        else
            this.onSubmitConf();
    }
    
    stripHtmlTags(html: string): string {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    onSubmitConf() {

        if (this.sendEmailForm.valid) {
            if (this.postBodyLength > 1000) {
                return;
            }
            this.spinner = true;
            const formData = this.sendEmailForm.value;
                let htmlContent = document.getElementById('emailBody')
                const textContent = this.stripHtmlTags(htmlContent.innerHTML);
                
            
            const payload = {
                file: this.fileToUpload,
                emailSubject: formData.emailSubject,
                // emailBody: formData.emailBody,
                emailBody: textContent,
                groupId: this.groupDetailId,
                isPreview: this.isPreview
            };        

            this.crudService.sendCommunityEmail(this.toFormData(payload)).subscribe((res) => {
                if (this.isPreview) {
                    this.previewEmail = res.data.emailBody || '';

                    $('#previewEmailModal').modal('show');
                } else {
                    $('#rosterNotAvailableModal').modal('hide');
                    this.sendEmailForm.reset();
                    this.fileToUpload = null;
                    this.previewEmail = null;
                    this.getEmailTemplate();
                    this.toaster.success(res.message);
                }
            }).add(() => this.spinner = false);
        }

    }

    errorHandling = (control: string, error: string, form = this.sendEmailForm) => {
        return form.controls[control].hasError(error) && (form.controls[control].dirty || form.controls[control].touched);
    }

    openSample() {
        this.crudService.getFileTemplateForEmail().subscribe((res) => {
            this.downloadFile(res.data.fileUrl, res.data.fileName);
        }, (err) => {
            //this.toaster.error('Something Went Wrong!');
        })
    }

    public downloadFile(filePath: string, fileName: string) {
        const isInternetExplorer = this.isIE || !!document.DOCUMENT_NODE;
        if (isInternetExplorer) {
            window.open(filePath, fileName);
        } else {
            const link = document.createElement('a');
            link.download = fileName;
            link.href = filePath;
            link.click();
        }
    }

    private getEmailTemplate() {
        this.spinner = true;
        // const params = {groupId: this.groupDetailId};

        // const opts = { params: new HttpParams({groupId: this.groupDetailId}) };

        this.crudService.getEmailTemplate(this.groupDetailId).subscribe((res) => {
            if (res.data.result) {
                this.sendEmailForm.patchValue({
                    emailSubject: res.data.result?.emailSubject || this.defaultEmailSubject,
                    emailBody: res.data.result?.emailBody || this.defaultEmailBody,
                });
            } else {
                this.sendEmailForm.patchValue({
                    emailSubject: this.defaultEmailSubject,
                    emailBody: this.defaultEmailBody,
                });
            }
        }).add(() => this.spinner = false);
    }

    addVariable(variableEl) {
        this.postBodyEditor.view.dom.focus();
        insertTextAtCursor(this.postBodyEditor.view.dom, variableEl.value);
        variableEl.value = '';
    }

}
