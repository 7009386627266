// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.signup-form > h6 {
    font-size: 15px;
    color: #3c4858;
    margin-bottom: 15px;
    font-weight: 600;
}
.signup-form .pwd-info {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
}
.signup-form .pwd-info > img {
    width: 15px;
    height: 15px;
}
.input-mess-info {
    border: none;
    box-shadow: 0px 0px 10px 2px #dad8d8;
    font-size: 11px;
    color: #3c4858;
}
.input-mess-info .popover-body {
    padding: 15px;
}
.input-mess-info .popover-body p {
    margin-bottom: 5px;
}

.btn-show-password {
    position: absolute;
    right: 0;
    top: 0;
}

.h6,
h6 {
    font-size: 1rem;
}

.tc-pp-text {
    color: #aeaeae;
    font-size: 12px;
    margin-top: 22px;
    margin-bottom: 0px;
    text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/delegate-access/delegate-access.component.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,cAAc;IACd,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,QAAQ;IACR,2BAA2B;IAC3B,eAAe;AACnB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,YAAY;IACZ,oCAAoC;IACpC,eAAe;IACf,cAAc;AAClB;AACA;IACI,aAAa;AACjB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,MAAM;AACV;;AAEA;;IAEI,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".signup-form > h6 {\n    font-size: 15px;\n    color: #3c4858;\n    margin-bottom: 15px;\n    font-weight: 600;\n}\n.signup-form .pwd-info {\n    position: absolute;\n    right: 15px;\n    top: 50%;\n    transform: translateY(-50%);\n    font-size: 12px;\n}\n.signup-form .pwd-info > img {\n    width: 15px;\n    height: 15px;\n}\n.input-mess-info {\n    border: none;\n    box-shadow: 0px 0px 10px 2px #dad8d8;\n    font-size: 11px;\n    color: #3c4858;\n}\n.input-mess-info .popover-body {\n    padding: 15px;\n}\n.input-mess-info .popover-body p {\n    margin-bottom: 5px;\n}\n\n.btn-show-password {\n    position: absolute;\n    right: 0;\n    top: 0;\n}\n\n.h6,\nh6 {\n    font-size: 1rem;\n}\n\n.tc-pp-text {\n    color: #aeaeae;\n    font-size: 12px;\n    margin-top: 22px;\n    margin-bottom: 0px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
