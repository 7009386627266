import {Component, OnInit} from '@angular/core';
import {CrudService} from "../../services/crud.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-my-badges-public',
    templateUrl: './my-badges-public.component.html',
    styleUrls: ['./my-badges-public.component.css']
})
export class MyBadgesPublicComponent implements OnInit {
    spinner = false;
    allBadges = [];
    mainID: any;
    userDetails: any;
    page = 1;
    type = 'creation';
    earnedBadgeCount = 0;
    creationBadgeCount = 0;

    constructor(public crudService: CrudService,
                public router: Router,
                public activeRoute: ActivatedRoute) {
        this.activeRoute.queryParams.subscribe(params => {
            if (params.badge && ['earned', 'creation'].includes(params.badge)) {
                this.type = params.badge;
            }
            this.mainID = params.user;
        });
    }

    ngOnInit() {
        if (!(this.mainID)) {
            this.router.navigate(['profile']);
        }

        this.spinner = true;

        this.getUserDetails();
        this.getBadgeCount();
    }

    // Get userdetails
    getUserDetails() {
        this.crudService.getUserInfoV2(this.mainID).subscribe(success => {
            this.userDetails = success.data.userInfo;
            this.getBadges();
            //console.log('this.userDetails = ', this.userDetails);

        });
    }

    getBadges(reset = false) {
        this.spinner = true;
        if (reset) {
            this.page = 1;
            this.allBadges = [];
        }
        const object: any = {
            page: this.page,
            limit: 10,
            sort: 'recent',
            type: this.type,
            userId: this.mainID
        }
        this.crudService.getBadge(object).subscribe((success: any) => {
            this.allBadges.push(...success.data);
        }).add(() => this.spinner = false);
    }

    getBadgeCount() {
        this.crudService.getBadgeCount({userId: this.mainID}).subscribe((response: any) => {
            this.earnedBadgeCount = response.data.find(x => x.type === 'earned')?.count || 0;
            this.creationBadgeCount = response.data.find(x => x.type === 'creation')?.count || 0;
        });
    }

    changeFilter(type: string) {
        this.type = type;
        this.getBadges(true);
    }

    userProfile() {
        this.router.navigate(['public-profile'], {queryParams: {user: this.mainID}});
    }

    onScroll() {
        ++this.page;
        this.getBadges();
    }
}
