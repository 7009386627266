// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.method-select {
    margin: auto;
    text-align: center;
}

.method-select-col {
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 2px solid #f8f9fa;
    cursor: pointer;
    padding: 16px;
}

.method-select img {
    width: 80%;
    height: auto;
}
@media (max-width: 768px) {
    .method-select img {
        width: 60%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/layout/modals/video-comment-type-dialog/video-comment-type-dialog.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;AACA;IACI;QACI,UAAU;IACd;AACJ","sourcesContent":[".method-select {\n    margin: auto;\n    text-align: center;\n}\n\n.method-select-col {\n    background-color: #f8f9fa;\n    border-radius: 8px;\n    border: 2px solid #f8f9fa;\n    cursor: pointer;\n    padding: 16px;\n}\n\n.method-select img {\n    width: 80%;\n    height: auto;\n}\n@media (max-width: 768px) {\n    .method-select img {\n        width: 60%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
