import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CrudService} from '../../../services/crud.service';
import {LocalStorageService} from '../../../services/localStorage.service';

@Component({
  selector: 'app-public-playlist',
  templateUrl: './public-playlist.component.html',
  styleUrls: ['./public-playlist.component.css']
})
export class PublicPlaylistComponent implements OnInit {

  groupId: any = '';
  groupAllVideos = [];
  groupDetails: any;
  spinner: any = false;
  isLoggedIn = false;
  isEmbed: any;

  constructor(private crudService: CrudService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) {
      this.activeRoute.queryParams.subscribe(params => {
        if (params.embed && params.embed === 'true') {
          this.isEmbed = params?.embed;
        }
        this.groupId = params.groupId;
        // console.log("this.groupId = ", this.groupId);
      });
    }

  ngOnInit() {

    this.spinner = true;

    this.isLoggedIn = this.localStorageService.getItem('user') != null;

    if (!this.isLoggedIn) {
      this.crudService.getAnonymousUser().subscribe(success => {
        if (success) {
          this.localStorageService.setItem('anonymousToken', success.data.token);
          this.isLoggedIn = true;

          if(!this.groupId)
            this.router.navigate(['profile']);

          this.getGroupDetails();
          this.getGroupContents();
        }
      })
    } else {
      if(!this.groupId)
       this.router.navigate(['profile']);
  
      
      this.getGroupDetails();
      this.getGroupContents();
    }
  };

  getGroupDetails() {
    this.crudService.getGroupDetail(this.groupId).subscribe(res => {
      this.groupDetails = res.data[0];
      //console.log("this.groupDetails = ", this.groupDetails);
    })
  }

  getGroupContents() {
    // console.log("getGroupContents");

    this.crudService.getGroupContentPlaylist(this.groupId).subscribe(success => {
      this.groupAllVideos = success.data;
      // console.log("this.groupAllVideos = ", this.groupAllVideos);

      this.spinner = false;
    })
  }

  // Show video
  showVideo(id: any) {
    this.router.navigate(['videos'], { queryParams: { video: id } });
  }

}
