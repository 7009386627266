import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CrudService} from '../../../services/crud.service';
import {DashboardService} from '../../../services/dashboard.service';

@Component({
    selector: 'app-online-videos',
    templateUrl: './online-videos.component.html',
    styleUrls: ['./online-videos.component.css']
})
export class OnlineVideosComponent implements OnInit {
    spinner = false;
    videos = [];
    private page = 1;
    private limit = 6;
    searchText = '';
    videoCount = 0;
    user: any;

    constructor(
        private crudService: CrudService,
        private dashboardService: DashboardService,
        private dialogRef: MatDialogRef<OnlineVideosComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
    }

    ngOnInit(): void {
    }

    getVideos() {
        let payload:any = {
            searchQuery: this.searchText,
            page: this.page,
            limit: this.limit
        }

        if(this.data.type === 'pixabay'){
            payload.videoProvider = 'Pixabay'
        }
        this.crudService.getProviderVideos(payload).subscribe((res: any) => {
            if(this.data.type === 'pixabay'){

                const videos = res.data?.hits;
                this.videoCount = res.data.totalHits;
                for (const video of videos) {
                    video.poster = video?.videos?.tiny?.thumbnail;
                    video.originalName = video?.tags;
                    video.URL = video?.videos?.tiny?.url;
                    video.name = this.getName(video?.videos?.tiny?.url);
                    this.videos.push(video);
                }

            }else{

                const videos = res.data?.videos;
                this.videoCount = res.data.total_results;
                for (const video of videos) {
                    video.poster = video.image;
                    video.originalName = this.getOriginalName(video.url);
                    //WC 02052024 --- To filter for hd quality and mp4 type
                    const url = video.video_files.find(x => (x.quality === 'hd') && (x.file_type === "video/mp4"))
                    if (url) {
                        video.URL = url.link;
                    } else {
                        video.URL = video.video_files[0].link;
                    }
                    video.name = this.getName(video.URL);
                    this.videos.push(video);
                }

            }
            // console.log(this.videos)
        }).add(() => this.spinner = false);
    }

    reset() {
        this.page = 1;
        this.videos = [];
    }

    getOriginalName(url) {
        const urlParts = url.split('/');
        const lastPart = urlParts[urlParts.length - 2];
        const fileName = lastPart.split('.')[0];
        return fileName.replace(/-/g, ' ');
    }

    getName(url) {
        let urlParts = url.split('?')[0];
        urlParts = urlParts.split('/');
        return urlParts[urlParts.length - 1];
    }

    selectVideo(video) {
        // console.log("video", video);
        
        this.dialogRef.close(video);
    }

    onScroll() {
        ++this.page;
        this.getVideos();
    }

    searchVideos() {
        this.reset();
        if (this.searchText.trim()) {
            this.spinner = true;
            this.getVideos();
        }
    }

    
}
