import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, of, switchMap} from 'rxjs';
import {CrudService} from '../services/crud.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard  {
    constructor(private crudService: CrudService, private router: Router) {
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        return this.crudService.isAdmin().pipe(switchMap((res) => {
            if (!res.data) {
                this.router.navigateByUrl('/');
            }
            return of(res.data);
        }));
    }

}
