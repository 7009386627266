import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common'
import {ActivatedRoute, Router} from '@angular/router';
import {CrudService} from '../../services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../environments/environment';
import languages from '../../services/languages';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {defaultStepOptions, videoPageSteps} from '../../tour-data';
import {ShepherdService} from 'angular-shepherd';
import {ShareVideoDialogComponent} from '../../layout/modals/share-video-dialog/share-video-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $: any;
declare var videojs: (arg0: HTMLElement, arg1: {}, arg2: () => void) => any;


@Component({
  selector: 'app-public-video',
  templateUrl: './public-video.component.html',
  styleUrls: ['./public-video.component.css'],
  // animations: [fadeInOutAnimation]
})
export class PublicVideoComponent implements OnInit, OnDestroy {
  isEmbed = false;
  isLogo = false;
  isShare = false;
  isMiniPlayer = false;
  val: any = 'recent';
  simiarVideo: any = [];
  private isCollapsed = true;
  currentProfilePic: any;
  public commentsLoaded = false;
  // private shareVideos: any = [];
  private currentMainChoice: any = {};
  totalCOmments: any = 0;
  // myfollowers: any = [];
  spinners: any = {};
  private commentObject: any = {scenerio: '', videoID: '', commentID: '', comment: ''};
  private likeObject: any = {ID: '', type: '', category: '', commentID: '', scenario: ''};
  private interval: any;
  private mainID: any = '';
  private commentId: any = '';
  private time: any = '';
  uploaderId: any = '';
  uploaderDetails: any = []
  commentText: any = '';
  replyText: any = '';
  publicURL: any = '';
  // isEmbedShow = false;
  embedVideoUrl = '';
  private mainVideoDetails: any;
  totalLikes: any = 0;
  isSameUser: any = false;
  private currentUserId: any;
  currentUserData: any;
  private currentUserLikes: any;
  // findorselect: any = '';
  createInteractiveVideoObj: any = {
    spinner: false,
    currentObj: {currentPlayingItem: {}},
    selectedItem: '',
    query: '',
    interactiveVideo: [],
    finalObj: [],
    currentPlayingItem: {},
    mainVideo: '',
    child: [],
    preview: false
  };
  iAMFollowing: any = false
  private currentUserFollowing: any = []
  private followers: Array<any> = [];
  commentData: Array<any> = [];
  private videoDetails: any;
  userReactions: Array<any> = [];

  likes: Array<any> = [];
  childVideos: Array<any> = [];
  likesReaction: Array<any> = [];
  laughs: Array<any> = [];
  dislikes: Array<any> = [];
  questions: Array<any> = [];
  amazes: Array<any> = [];
  cries: Array<any> = [];
  angry: Array<any> = [];
  private player: any;
  private viewCount: any = 0;
  likeCategories: Array<any> = [
    {
      shortcut: 0,
      id: '1',
      category: 'Laugh',
      emoji: '../../../assets/images/videoplayer/react-laugh.svg'
    },
    {
      shortcut: 1,
      id: '2',
      category: 'Like',
      emoji: '../../../assets/images/videoplayer/react-like.svg'
    },
    {
      shortcut: 2,
      id: '3',
      category: 'Dislike',
      emoji: '../../../assets/images/videoplayer/react-dislike.svg'
    },
    {
      shortcut: 3,
      id: '4',
      category: 'Question',
      emoji: '../../../assets/images/videoplayer/react-question.svg'
    },
    {
      shortcut: 4,
      id: '5',
      category: 'Amazed',
      emoji: '../../../assets/images/videoplayer/react-amazed.svg'
    },
    {
      shortcut: 5,
      id: '6',
      category: 'Crying',
      emoji: '../../../assets/images/videoplayer/react-crying.svg'
    },
    {
      shortcut: 6,
      id: '7',
      category: 'Angry',
      emoji: '../../../assets/images/videoplayer/react-angry.svg'
    }
  ];
  myEmoji: any = '';
  isLoggedIN: any = false
  isAnonymousUser = false;
  topViewedChoices: Array<any> = [];
  private totalViews: any = '';
  firstDecisions: Array<any> = [];
  mostPopularPath: any = [];
  choice: any = false;
  isChoices: any = false;
  isNotAnonymous = false;
  isSearch: any;
  videotree: any = [];
  parent: any = [];
  level: any;
  showtree = true;
  url: any = '../../../assets/video/demo test.mp4';
  viewArrow: any;
  // fullShareURL: any;
  viewDescriptionLength = 100;
  showLessContent = false
  isCopied = false;
  video_id: any;
  childCommentId: any = null;
  currentVideoName: any = 'N/A'
  rangeValue = 0.5;
  currentVideoTime: any = 0;
  totalVideoDuration: any;
  hoverItem: any;
  shareId: any;
  unAuthItem = false;
  allCollections: any = [];
  selectedNodeId: any;
  collectionId: any;
  isCreatedNode = true;
  processing = false;
  limit = 10;
  page = 1;
  viewableVideos: any = [];
  isAbleToViewVideo = false;
  videoStatus = '';
  groupsToJoin: any = [];

  private _unsubscribeAll: Subject<any> = new Subject<any>();


  constructor(
      private dialog: MatDialog,
      private router: Router,
      private shepherdService: ShepherdService,
      private localStorageService: LocalStorageService,
      private activRoute: ActivatedRoute,
      private crudService: CrudService,
      private toaster: ToastrService,
      private location: Location
  ) {

    this.activRoute.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(params => {
      if (params.embed && JSON.parse(params.embed)) {
        this.isEmbed = true;
      }
      if (params.share) {
        this.isShare = params.share;
      }
      if (params.logo && (JSON.parse(params.logo) === false)) {
        this.isLogo = true;
      }
      this.createInteractiveVideoObj.currentObj.id = params.video;
      this.mainID = params.video;

      window.scrollTo(0, 0);
      try {
        this.isLoggedIN = !(this.localStorageService.getItem('token') == null || this.localStorageService.getItem('token') === undefined);
        this.isAnonymousUser = !(this.localStorageService.getItem('anonymousToken') == null || this.localStorageService.getItem('anonymousToken') === undefined);
        if (this.localStorageService.getItem('token') || this.localStorageService.getItem('anonymousToken')) {
          this.init();
        }
      } catch (error) {
        //console.log(error)
        this.isLoggedIN = false;
        this.isAnonymousUser = true;
        this.getAnonymousUser();
      }
    });
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    // $event.returnValue =true;
    this.saveHistory();
  }

  getAnonymousUser() {
    this.crudService.getAnonymousUser().pipe(takeUntil(this._unsubscribeAll)).subscribe((success) => {
          try {
            this.localStorageService.setItem('anonymousToken', success.data.token);
          } catch {
            this.crudService.anonymousToken = success.data.token;
          }
          this.init();
        }
    );
  }

  ngOnInit() {

    let a;
    try {
      a = this.localStorageService.getItem('isTree');
    } catch {
      a = 'hide';
    }
    // setTimeout(() => {
      if (a == null) {
        this.isSearch = 'show';
      } else {
        this.isSearch = 'hide';
      }
    // }, 2000);
    try {
      if (this.localStorageService.getItem('token')) {
        this.hoverItem = true;
        this.isAnonymousUser = true;
      } else {
        this.hoverItem = false;
        this.isAnonymousUser = false;
      }
      if (!this.localStorageService.getItem('anonymousToken')) {
        this.getAnonymousUser()
      }
      if (!!this.localStorageService.getItem('isChoice')) {
        $('#cmt-count-btn').removeClass('active');
        $('.nav-link.one').removeClass('active');
        $('#cmt-breakdown-btn').addClass('active');
        $('.nav-link.two').addClass('active');
        $('#cmt-breakdown-btn').css('opacity', '1');
        $('html, body').animate({
          scrollTop: $('#cmt-breakdown-btn').offset().top
        }, 1000);
        this.localStorageService.removeItem('isChoice');
      }
    } catch {
      this.hoverItem = false;
      this.isAnonymousUser = true;
      this.init();
    }
    const that = this;
    $(window).scroll(function () {
      try {
        const topOfElement = $('#main-wraper').offset().top;
        const bottomOfElement = $('#main-wraper').offset().top + $('#main-wraper').outerHeight();
        const bottomOfScreen = $(window).scrollTop() + $(window).height();
        const topOfScreen = $(window).scrollTop();
        if ((bottomOfScreen > topOfElement) && (topOfScreen < bottomOfElement)) {
          that.isMiniPlayer = false;
        } else {
          if (!that.player.paused()) {
            that.isMiniPlayer = true;
          }
        }
      } catch (error) {
      }
    });
    this.getCurrentWidth();
    if (this.isShare) {
      setTimeout(() => {
        this.openShareModal();
      }, 2000);
    }
  }

  getVideoData(videoId: string) {
    this.crudService.getVideoInfov2(videoId).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.onSelectVideoFromTree(res.data.videoinfo);
    })
  }

  checkIsAbleToViewVideo() {

    // WC 02112023 --- Check if the current user can view the video or not

    if (this.createInteractiveVideoObj.currentObj.currentPlayingItem.isSubscription)
      this.videoStatus = 'Subscription';
    else if (this.createInteractiveVideoObj.currentObj.currentPlayingItem.isRestricted)
      this.videoStatus = 'Private';
    else
      this.videoStatus = '';

    // WC 02102023 -- To check if the logged-in user has the priviledge to watch the video or not
    // If the current user is the owner of the video then they are able to view the video
    //console.log('this.currentUserId = ', this.currentUserId);

    if ((this.currentUserId != null) && (this.currentUserId == this.createInteractiveVideoObj.currentObj.currentPlayingItem.user)) {
      this.isAbleToViewVideo = true;

    } else { // the current user is not the owner of the video, check to see if they are a group member that the video is a part of

      // If the video is neither subscription nor restrictive then everyone can view it
      //console.log('his.createInteractiveVideoObj.currentObj.currentPlayingItem.isSubscription = ', this.createInteractiveVideoObj.currentObj.currentPlayingItem.isSubscription);
      //console.log('his.createInteractiveVideoObj.currentObj.currentPlayingItem.isRestricted = ', this.createInteractiveVideoObj.currentObj.currentPlayingItem.isRestricted);

      if (!this.createInteractiveVideoObj.currentObj.currentPlayingItem.isSubscription && !this.createInteractiveVideoObj.currentObj.currentPlayingItem.isRestricted)
        this.isAbleToViewVideo = true;
      else {

        this.crudService.getGroupsByVideoIdAndUserId(this.createInteractiveVideoObj.currentObj.currentPlayingItem._id).subscribe(result => {
          this.viewableVideos = result.data;

          //console.log('this.viewableVideos = ', this.viewableVideos);

          // If there's groupMember property then the current user is the member of the group or community so they can view the video
          let countOfMembership = 0;

          this.viewableVideos.forEach(element => {
            if (element.groupMember != undefined && element.groupMember != null && element.groupMember.status == 'Joined') {
              countOfMembership++

              // As long as there's 1 group that the current user is a member of then they can view the video
              this.isAbleToViewVideo = countOfMembership > 0;
            }
          });

          if (this.createInteractiveVideoObj.currentObj.currentPlayingItem.isSubscription) {
            this.groupsToJoin = [];

            this.viewableVideos.forEach( element => {
              //console.log('element = ', element);

              if (element.group.isSubscriptionGroup)
                this.groupsToJoin.push(element);

              //console.log('this.groupsToJoin = ', this.groupsToJoin);
            });
          } else if (this.createInteractiveVideoObj.currentObj.currentPlayingItem.isRestricted) {

            this.groupsToJoin = [];

            this.viewableVideos.forEach(element => {
              if (element.group.isPrivate)
                this.groupsToJoin.push(element);
            });
          }

          //console.log('ngOnInit --- this.isAbleToViewVideo = ', this.isAbleToViewVideo);
        });
      }
    }
    // WC 02112023
  }

  tooltipMessage() {
    this.isSearch = false;
    try {
      this.localStorageService.setItem('isTree', 'hide')
    } catch {
      // console.log('isTree catch');
    }
  }

  getVideoTree() {
    try {
      this.localStorageService.setItem('isTree', 'hide');
      this.crudService.getTree(this.mainID).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
        this.parent = res.data
        this.videotree = res.data.children;
      })
    } catch {
      this.crudService.getAnonymousUser().pipe(takeUntil(this._unsubscribeAll)).subscribe((response) => {
        this.crudService.getTree(this.mainID).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
          this.parent = res.data
          this.videotree = res.data.children;
        })
      })
    }
  }

  openvideoTree() {
    $('#tree-vid-video-Modal').modal('show')

  }
  getCurrentWidth() {
    const width = window.innerWidth;
    this.viewArrow = width >= 991;
  }
  showCategory(id: any) {

    this.router.navigate(['category'], {queryParams: {category: id}});

  }

  init() {
    this.createInteractiveVideoObj.spinner = true;
    this.createInteractiveVideoObj.preview = false;
    clearInterval(this.interval);
    this.videoDetails = [];
    this.createInteractiveVideoObj.currentObj = {currentPlayingItem: {}};
    this.currentMainChoice = undefined;
    this.spinners.first = true;
    this.spinners.second = true;
    try {
      this.currentProfilePic = this.localStorageService.getItem('profilePic');
      this.currentUserId = this.localStorageService.getItem('user');
    } catch (error) {
      this.currentProfilePic = null;
      this.currentUserId = null;
    }
    this.getVideoDetails();

    //console.log('*** init - this.isAbleToViewVideo = ', this.isAbleToViewVideo);

    this.publicURL = environment.currentSharedDomain + 'videos?video=' + this.mainID;
    this.commentText = '';
    this.replyText = '';
    $('.likes-emoji').hide();
    $('.comment-emoji').hide();
  }

  userProfile(id: any) {
    if (this.currentUserId === id) {
      // this.router.navigate(['dashboard']);
      this.router.navigate(['profile']);
      return;
    }
    this.router.navigate(['public-profile'], {queryParams: {user: id}});
  }

  userVideo(id: any) {
    if (this.currentUserId === id) {
      this.router.navigate(['my-videos']);
      return;
    }
    this.router.navigate(['my-videos-public'], {queryParams: {user: this.uploaderId}});

  }

  showVideo(id: any) {
    this.router.navigate(['videos'], {queryParams: {video: id}});
  }

  getEmoji(cat: string) {
    if (cat === '1')
      return this.likeCategories[0].emoji

    if (cat === '2')
      return this.likeCategories[1].emoji

    if (cat === '3')
      return this.likeCategories[2].emoji

    if (cat === '4')
      return this.likeCategories[3].emoji

    if (cat === '5')
      return this.likeCategories[4].emoji

    if (cat === '6')
      return this.likeCategories[5].emoji

    if (cat === '7')
      return this.likeCategories[6].emoji

  }

  // show more
  showMore(comment: string) {
    this.viewDescriptionLength = comment.length;
    // console.log('str length is',this.viewDescriptionLength);
    this.isCollapsed = !this.isCollapsed;
    this.showLessContent = true
    // this.dataLength = !(comment.length > 30)
  }

  showLess() {
    this.showLessContent = false;
    this.viewDescriptionLength = 100;
  }

  // Get video details
  getVideoDetails(id?: any) {

    // console.log('in getVideoDetails');

    this.crudService.getVideoInfov2(id ? id : this.mainID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {
          this.videoDetails = success.data.videoinfo;
          this.uploaderId = this.videoDetails.user;
          if (this.videoDetails?.mainId) {
            this.mainID = this.videoDetails.mainId;
            this.currentMainChoice = {};
            this.currentMainChoice.choices = {};
            // get main video
            this.crudService.getVideoInfov2(this.mainID).pipe(takeUntil(this._unsubscribeAll)).subscribe(success1 => {
              this.setCurrentPlayingItem(success1.data.videoinfo);
              this.onSelectVideoFromTree(this.videoDetails);
            });
          } else {
            this.setCurrentPlayingItem(this.videoDetails);
          }

          //console.log('video data 1', this.videoDetails)

          this.getVideoTree();
          if (this.isLoggedIN) {
            this.crudService.getWatchedHistory(this.mainID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                success1 => {
                  this.currentMainChoice = success1.data.data[0];
                  if (!this.currentMainChoice) {
                    this.currentMainChoice = {};
                    this.currentMainChoice.mainvideoID = this.mainID
                    this.currentMainChoice.mainvideoName = this.videoDetails.name
                    this.currentMainChoice.duration = 0;
                    this.currentMainChoice.choices = [{0: ''}]
                    this.currentMainChoice.choices[0].videoID = this.mainID;
                    this.currentMainChoice.choices[0].videoName = this.videoDetails.name
                    this.currentMainChoice.choices[0].duration = 0
                  } else {
                    if (this.currentMainChoice.choices[0].videoID) {

                    } else {
                      this.currentMainChoice.choices[0].videoID = this.currentMainChoice.mainvideoID;
                      this.currentMainChoice.choices[0].videoName = this.currentMainChoice.mainvideoName
                      this.currentMainChoice.choices[0].duration = this.currentMainChoice.duration
                    }
                  }
                  // //console.log(this.currentMainChoice)
                }
            )
          }


          // WC 09272022 -- Use getUserInfoV2 to get the user info of the video creator
          this.crudService.getUserInfoV2(this.uploaderId).pipe(takeUntil(this._unsubscribeAll)).subscribe(
              success1 => {
                if (this.videoDetails && this.videoDetails?._id) {
                  // console.log('User info', success)
                  if (success1.data.userInfo?.privacySettings?.showPublicVideo === true) {
                    if (this.videoDetails.isPublished || this.videoDetails.type === 'child') {
                      if (this.videoDetails.user === this.currentUserId) {
                        this.isSameUser = true;
                      }

                      this.callChild();
                    } else {
                      this.toaster.error('Video not available!');
                      window.history.back();
                    }
                  } else {
                    if (this.videoDetails.isPublished || this.videoDetails.type === 'child') {
                      if (this.videoDetails.user === this.currentUserId) {
                        this.isSameUser = true;
                      }
                      this.callChild();
                    } else {
                      this.toaster.error('Video is private!');
                      window.history.back();
                    }
                  }
                  this.uploaderDetails = success1.data;
                  // console.log('getVideoDetails - this.uploaderDetails = ', this.uploaderDetails);

                  // WC 09282022
                  this.iAMFollowing = success1.data.userInfo.isFollowing;
                  // console.log('getVideoDetails - this.iAMFollowing = ', this.iAMFollowing);

                  if (this.isLoggedIN)
                    this.getMyData();

                  this.getAllLikes();
                  this.getAllComments()
                }

              }, error => {

              });

              this.checkIsAbleToViewVideo();
        });


  }

  setCurrentPlayingItem(videoinfo) {
    this.totalViews = videoinfo.viewCount;
    this.createInteractiveVideoObj.currentObj.currentPlayingItem = videoinfo;
    /*todo: subtitles*/
    if (this.createInteractiveVideoObj.currentObj.currentPlayingItem.subtitleTranslations) {
      this.createInteractiveVideoObj.currentObj.currentPlayingItem.subtitleTranslations
          = this.createInteractiveVideoObj.currentObj.currentPlayingItem.subtitleTranslations.map(x => {
        x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
        return x;
      });
    }
    /*todo: subtitles*/

    //   //console.log(this.videoDetails.user)
    this.mainVideoDetails = videoinfo
    this.createInteractiveVideoObj.currentObj.currentPlayingItem.currentTime = 0;
    const temp = parseInt(this.createInteractiveVideoObj.currentObj.currentPlayingItem.publishTime);
    this.createInteractiveVideoObj.currentObj.currentPlayingItem.formattedTime = new Date(temp).toUTCString();

    this.totalLikes = videoinfo?.Likes?.length || 0;

    if (this.isSearch === 'show') {
      setTimeout(() => {
        // this.shepherdService.modal = true;
        // this.shepherdService.confirmCancel = true;
        this.shepherdService.defaultStepOptions = defaultStepOptions;
        let videoPageStep = videoPageSteps;
        // console.log('this.isLoggedIN', this.isLoggedIN)
        if (!this.isLoggedIN) {
          delete videoPageStep[3];
          videoPageStep = videoPageStep.filter(x => x.id !== 'your-decisions');
          videoPageStep = videoPageStep.map(x => {
            if (x.id === 'tree-section') {
              x.buttons[1].text = 'Done';
            }
            return x;
          });
          // console.log(videoPageStep)
        }
        this.shepherdService.addSteps(videoPageStep as any);
        this.shepherdService.start();
      }, 2000);
    }

  }


  getAllLikes() {

    this.crudService.getVideoLikesInfo(this.mainID).pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
          if (success.data && success.data.length) {
            this.likes = success.data;
            for (const i of this.likes) {
              if (i._id === this.currentUserId) {
                i.isMe = true
                this.currentUserLikes = i;
                for (const j of this.likeCategories) {
                  if (this.currentUserLikes.category === j.id) {
                    this.myEmoji = j.emoji;
                    break;
                  }
                }
                break;
              }
            }
            this.parseLike();
          }
        },
        error => {
          // this.ifUser()
        })

  }

  getMyData() {

    if (this.currentUserId) {
      // WC 09272022 - Using getUnserInfoV2
      this.crudService.getUserInfoV2(this.currentUserId).pipe(takeUntil(this._unsubscribeAll)).subscribe(
          success => {
            this.currentUserData = success.data.userInfo;
            // console.log('getMyData - this.currentUserData = ', this.currentUserData);
          }
      );

      // Followers V2
      // this.crudService.getFollowers(this.limit, this.page).pipe(takeUntil(this._unsubscribeAll)).subscribe(
      //     success => {
      //       this.myfollowers = success.data.followers;
      //     }
      // )
    }

  }

  // onScroll() {
  //   ++this.page;
  //
  //   // Followers V2
  //   this.crudService.getFollowers(this.limit, this.page).pipe(takeUntil(this._unsubscribeAll)).subscribe(
  //       success => {
  //         const followersArray = success.data.followers;
  //
  //         for (let i = 0; i < followersArray.length; i++) {
  //           this.myfollowers.push(followersArray[i]);
  //         }
  //       }
  //   )
  // }


  parseLike() {
    this.likesReaction = [];
    this.laughs = [];
    this.dislikes = [];
    this.questions = [];
    this.amazes = [];
    this.cries = [];
    this.angry = [];
    // //console.log(this.currentUserFollowing,this.likes)
    for (const i of this.likes) {
      for (const j of this.currentUserFollowing) {
        if (this.isLoggedIN) {

          if (i._id === j.userinfo._id) {
            i.iAmFollowing = true;
          }
        }
      }

      if (i.category === '1')
        this.laughs.push(i)

      if (i.category === '2')
        this.likesReaction.push(i)

      if (i.category === '3')
        this.dislikes.push(i)

      if (i.category === '4')
        this.questions.push(i)

      if (i.category === '5')
        this.amazes.push(i)

      if (i.category === '6')
        this.cries.push(i)

      if (i.category === '7')
        this.angry.push(i)
    }

  }

  getAllComments(childCommentId?: any) {
    this.commentData = []


    this.crudService.getCommentInfo(childCommentId ? childCommentId : this.mainID)
        .pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {
          this.totalCOmments = success.data.length;
          for (const [key, value] of Object.entries(success.data)) {

            const value1: any = value
            // //console.log(value1);
            this.commentData.push(value1);
            // this.commentData.push()
          }
          // //console.log(this.commentData)
          if (this.commentData[0] === 'No comment found') {
            this.totalCOmments = 0;
            this.commentData = [];
          }
          this.commentData = this.commentData.reverse();
          // console.log('this.commentData: ', this.commentData);

          if (this.isLoggedIN) {
            for (const i of this.commentData) {
              if (i.likebyuserInfo) {
                for (const j of i.likebyuserInfo) {
                  if (j.profilePic === this.currentProfilePic) {
                    i.likedbyMe = true;
                  }
                }
              }
            }
          }
          this.commentsLoaded = true;
          this.spinners.second = false

        }, error => {

        });

  }

  startOver() {
    this.createInteractiveVideoObj.preview = false;
    this.createInteractiveVideoObj.spinner = true;
    clearInterval(this.interval);
    setTimeout(() => {
      // clearInterval(this.interval);
      // clearInterval(this.intervalWatchHistory)
      // //console.log(this.createInteractiveVideoObj.finalObj[0],this.createInteractiveVideoObj.currentObj.currentPlayingItem)
      this.createInteractiveVideoObj.currentObj.currentPlayingItem = JSON.parse(JSON.stringify(this.createInteractiveVideoObj.finalObj[0]));
      this.createInteractiveVideoObj.preview = true;
      this.createInteractiveVideoObj.spinner = false;

      if (this.isLoggedIN) {
        this.currentMainChoice.duration = 0;
      }

      this.createInteractiveVideoObj.currentObj.currentPlayingItem.currentTime = 0;
      this.callLoadedFunctionForMobile();
      this.getYourFirstDecision(this.mainID);
      if (this.isLoggedIN) {
        this.currentMainChoice.choices =
            {
              videoID: this.mainID,
              videoName: this.videoDetails.name,
              duration: 0,
              level: 0
            }

        const Payload = {
          mainvideoID: this.mainID,
          mainvideoName: this.videoDetails.name,
          duration: 0,
          choices: [
            this.currentMainChoice.choices
            // this.currentMainChoice.choices
          ]
        }

        this.crudService.setWatchHistory(Payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            success => {
              // //console.log(success.data);
            });
      }
    })

  }

  // copyLink() {
  //   this.isCopied = true;
  //   this.toaster.info('Link copied successfully !!')
  // }

  callChild() {
    this.crudService.getChildVideosAll(this.mainID).pipe(takeUntil(this._unsubscribeAll)).subscribe((success) => {

      const tree = [];
      this.createInteractiveVideoObj.currentObj.currentPlayingItem.parentId = '0';
      tree.push(this.createInteractiveVideoObj.currentObj.currentPlayingItem);
      for (const i of success.data) {
        // i.children = null;
        tree.push(i)

      }
      this.childVideos = success.data;
      const tree1 = this.createNestedArray(tree);

      // WC 8/27/2022 - Removing JSON parsing
      // this.createInteractiveVideoObj.finalObj = JSON.parse(JSON.stringify(tree1))
      this.createInteractiveVideoObj.finalObj = tree1;

      this.createInteractiveVideoObj.currentObj.currentPlayingItem = tree1[0];

      if (this.isLoggedIN) {
        for (const i of tree) {
          if (this.currentMainChoice?.choices[0] && i._id === this.currentMainChoice?.choices[0].videoID) {
            // console.log('this.currentMainChoice', this.currentMainChoice);

            this.createInteractiveVideoObj.currentObj.currentPlayingItem = tree1[0];
            this.createInteractiveVideoObj.currentObj.currentPlayingItem.URL = i.URL;
            this.createInteractiveVideoObj.currentObj.currentPlayingItem.time = i.time;
            this.createInteractiveVideoObj.currentObj.currentPlayingItem.poster = i.poster;
            this.createInteractiveVideoObj.currentObj.currentPlayingItem.children = i.children;

            // this.createInteractiveVideoObj.currentObj.currentPlayingItem = i;

            // WC 08092022
            if (i.subtitleUrl != undefined)
              this.createInteractiveVideoObj.currentObj.currentPlayingItem.subtitleUrl = i.subtitleUrl;
            else
              this.createInteractiveVideoObj.currentObj.currentPlayingItem.subtitleUrl = '';
            // WC 08092022
            break;
          }
        }

      }
      // //console.log(this.createInteractiveVideoObj.currentObj.currentPlayingItem)
      this.createInteractiveVideoObj.spinner = false;
      this.createInteractiveVideoObj.preview = true;
      this.callLoadedFunctionForMobile()
      this.similarVideos();
    })
  }

  onSelectVideoFromTree(item) {
    const that = this;
    this.video_id = item._id
    window.scroll(0, 0);
    that.loadNextOption(item)
    this.getVideoTree();
  }

  emphasizeVideo(item: any) {
    const payload = {
      videoId: item._id
    }
    this.crudService.emphasizeVideo(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.getVideoTree()
    })

  }

  CommentOnChildVideo(item) {
    this.similarVideos()
    this.currentVideoName = item.name
    this.showtree = false;
    this.childCommentId = item._id
    this.getAllComments(item._id)
  }

  onSelectParent(item) {
    const that = this;
    this.video_id = item.parentId
    window.scroll(0, 0);
    that.loadNextOption(item);
    this.getVideoTree();
  }

  increaseRange() {
    const val = this.rangeValue + 0.1
    if (val <= 1) {
      this.rangeValue = val;
    }
  }

  decreaseRange() {
    const val = this.rangeValue - 0.1
    if (val >= 0.4) {
      this.rangeValue = val;
    }
  }

  resetRange() {
    this.rangeValue = 0.5;
  }

  // onScroll(){


  //   // window.scrollBy(0,750)
  //   window.scrollBy({
  //     top: 750,
  //     behavior: 'smooth'
  //   });
  // }
  similarVideos() {
    // let text = this.createInteractiveVideoObj.currentObj.currentPlayingItem.Title || '';
    // WC 01272023 --- setting search term on only the 1st 3 chars for the title is causing irrelevant videos to be returned in the similar video result
    // Changing it to the 1st word
    // text = text.slice(0, 3);
    // text = text.split(' ');
    // text = text[0];
    // WC 01272023
    const category = this.createInteractiveVideoObj.currentObj.currentPlayingItem.Categories.toString();
    if (!category) {
      this.choice = true;
      this.spinners.second = false;

      this.getMostPopularPath();
      return;
    }
    this.crudService.userCategoryWiseVideosV2({category}).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {
          // success.data.splice(0, 1);
          this.simiarVideo = success.data;
          // console.log('similar videos', this.simiarVideo);

          if (this.simiarVideo.length > 0) {
            for (let i = (this.simiarVideo.length) - 1; i > -1; i--) {

              if (this.mainID === this.simiarVideo[i]._id) {

                this.simiarVideo.splice(i, 1)
              }
            }
          }
          this.choice = true;
          this.spinners.second = false;

          this.getMostPopularPath();

        })
  }

 /* selectToShare(id: any) {
    if (this.shareVideos.includes(id)) {
      const index = this.shareVideos.indexOf(id);
      if (index > -1) {
        this.shareVideos.splice(index, 1);
      }
    } else {
      this.shareVideos.push(id)
    }


  }
*/
  /*shareTousers(id: any, isMain?: any) {
    let x = null;
    // console.log('full share url', this.fullShareURL);

    let obj;
    const url = this.fullShareURL as string;
    x = url.substring(27)
    // console.log('url is ',x);

    // this.fullShareURL =id;
    $('#' + id).prop('disabled', true);
    if (isMain) {
      obj = {
        sharedWith: this.shareVideos,
        videoID: this.mainID
      }
    } else {
      obj = {
        sharedWith: this.shareVideos,
        videoID: x
      }
    }

    this.crudService.shareInternally(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {
          this.toaster.success('Video Shared!')
          this.shareVideos = [];
          for (const i of this.myfollowers) {
            i.selected = false;
          }
          $('#' + id).prop('disabled', false);
        }
    )


  }*/

  showEmojis() {
    $('.likes-emoji').toggle();
  }

  showCommentEmojis(id: any) {
    $('#' + id).toggle();
    // $('.comment-emoji').toggle();
  }

  openReactModal() {
    $('#react-Modal').modal('show')
  }

  // Like a video
  likeVideo(data: { id: any; emoji: any; }) {
    this.likeObject.ID = this.mainID;
    this.likeObject.type = 'video';
    this.likeObject.category = data.id;
    this.likeObject.commentID = 'null';
    this.likeObject.scenario = 'like';
    this.likeObject.profilePic = this.currentUserData.profilePic
    this.likeObject.firstname = this.currentUserData.firstname
    this.likeObject.lastname = this.currentUserData.lastname
    this.likeObject.userName = this.currentUserData.username
    this.likeObject.isMe = true;
    this.myEmoji = data.emoji;
    this.totalLikes++;
    this.showEmojis();
    this.likes.push(this.likeObject)
    this.parseLike();
    this.crudService.likeAndUnlike(this.likeObject).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {
        });
  }

  updatelikeVideo(data: { id: any; emoji: any; }) {
    this.likeObject.ID = this.mainID;
    this.likeObject.type = 'video';
    this.likeObject.category = data.id;
    this.likeObject.commentID = 'null';
    this.likeObject.scenario = 'like';
    this.myEmoji = data.emoji;
    // this.totalLikes++;
    this.showEmojis();
    for (const i of this.likes) {
      if (i._id === this.currentUserId) {
        i.category = this.likeObject.category;
        break;
      }

    }

    this.parseLike();
    this.crudService.likeAndUnlike(this.likeObject).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {
        });
  }

  // Unlike a video
  unlikeVideo() {
    this.likeObject.ID = this.mainID;
    this.likeObject.type = 'video';
    // this.likeObject.category = id;
    this.likeObject.commentID = 'null';
    this.likeObject.scenario = 'unlike';
    this.myEmoji = '';
    this.totalLikes--;
    this.crudService.likeAndUnlike(this.likeObject).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {
        });
  }

  // Like a comment
  likeComment(event: any, commentId: any) {
    this.likeObject.ID = this.mainID;
    this.likeObject.type = 'comment';
    this.likeObject.scenario = 'like';
    this.likeObject.commentID = commentId;
    this.likeObject.category = event.target.id;
    this.showCommentEmojis(commentId);
    for (const i of this.commentData) {
      if (i._id === commentId) {
        if (!i.likebyuserInfo) {
          i.likebyuserInfo = [];
          i.likebyuserInfo.push({_id: this.currentUserId, profilePic: this.currentProfilePic})
          i.likedbyMe = true;
          break;
        } else {
          if (i.likebyuserInfo.length === 0) {
            i.likebyuserInfo.push({_id: this.currentUserId, profilePic: this.currentProfilePic});
            i.likedbyMe = true;
            break;
          }
          let count = 0;
          for (const j of i.likebyuserInfo) {
            if (j._id === this.currentUserId) {
              ++count;
              i.likebyuserInfo.push({_id: this.currentUserId, profilePic: this.currentProfilePic});
              i.likedbyMe = true;
              break;
            }
          }
          if (!count) {
            i.likebyuserInfo.push({_id: this.currentUserId, profilePic: this.currentProfilePic});
            i.likedbyMe = true;
            break;
          }
        }
      }
    }
    this.crudService.likeAndUnlike(this.likeObject).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {

        },
        error => {
        }
    );
  }


  unlikeComment(commentId: any) {
    this.likeObject.ID = this.mainID;
    this.likeObject.type = 'comment';
    this.likeObject.category = '';
    this.likeObject.commentID = commentId;
    this.likeObject.scenario = 'unlike';
    this.showCommentEmojis(commentId);
    for (const i of this.commentData) {
      if (i._id === commentId) {
        for (let j = i.likebyuserInfo.length - 1; j > -1; j--) {
          if (i.likebyuserInfo[j]._id === this.currentUserId) {
            i.likedbyMe = false;
            i.likebyuserInfo.splice(j, 1);
            break;
          }

        }
      }

    }
    this.crudService.likeAndUnlike(this.likeObject).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {

        },
        error => {
        }
    );
  }


  changeFilter(val: any) {
    if (val === 'recent') {
      // tslint:disable-next-line:only-arrow-functions
      this.commentData.sort(function (a, b) {
        if (a.createdAT > b.createdAT) {
          return -1;
        }
        return 1
      })
    } else {
      // tslint:disable-next-line:only-arrow-functions
      this.commentData.sort(function (a, b) {
        let newA = [];
        let newB = [];
        if (!a.likebyuserInfo)
          newA = []
        else
          newA = a.likebyuserInfo

        if (!b.likebyuserInfo)
          newB = []
        else
          newB = b.likebyuserInfo

        if (newA.length > newB.length) {
          return -1;
        }
        return 1
      })
    }

  }

  // getReactionImga(id)
  // {
  //   for(let i of this.likeCategories)
  //   {
  //   if(id == i.id)
  //   {
  //     return i.emoji;
  //   }
  // }
  // }

  // getlikes()
  // {
  //   let userObj={}
  //   this.crudService.getUserInfo(i.userID).subscribe(
  //     success=>{
  //       userObj.userId = i.userID;
  //       userObj.username = success.data.userinfo.username;
  //       userObj.profilePic = success.data.userinfo.profilePic;
  //       this.userReactions.push(userObj);
  //       setTimeout(() => {
  //         this.getMyEmoji();
  //         this.laughs = this.filterReactions(this.userReactions, this.laughs, '1');
  //         this.likes = this.filterReactions(this.userReactions, this.likes, '2');
  //         this.dislikes = this.filterReactions(this.userReactions, this.dislikes, '3');
  //         this.questions = this.filterReactions(this.userReactions, this.questions, '4');
  //         this.amazes = this.filterReactions(this.userReactions, this.amazes, '5');
  //         this.cries = this.filterReactions(this.userReactions, this.cries, '6');
  //         this.angry = this.filterReactions(this.userReactions, this.angry, '7');
  //       });
  //     }, error=>{

  //     });
  // }

  // getLoginDetails() {
  //   this.crudService.userDetails().subscribe(
  //     success=>{
  //       //console.log('Login ID', success.data.details._id);
  //     });
  // }

  getMyEmoji() {
    for (const r of this.userReactions) {
      if (r.userId === this.uploaderId) {
        this.myEmoji = r.emoji;
      }
    }
  }

  // onClickEmbedButton() {
  //   const video = this.mainID;
  //   // if (this.video_id) {
  //   //   video = this.video_id;
  //   // }
  //   // tslint:disable-next-line:max-line-length
  //   this.embedVideoUrl = `<iframe allowfullscreen frameborder="0" width="600" height="400" src="${environment.currentSharedDomain}embed/${video}"></iframe>`;
  //   this.isEmbedShow = !this.isEmbedShow;
  // }


/*  popupEmail() {
    const url = `mailto:?subject=Interactive Video&body=Check this interactive video experience I made on FreeFuse: ${this.publicURL}`;
    const newwindow = window.open(url);
    if (window.focus) {
      newwindow.focus()
    }
  }

  popupfacebook() {
    const url = 'http://www.facebook.com/sharer.php?u=' + this.publicURL;
    const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
      newwindow.focus()
    }
  }

  popuptweet() {
    const url = 'https://twitter.com/intent/tweet?text=' + this.publicURL;
    const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
      newwindow.focus()
    }
  }

  popuplinkedin() {

    const url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + this.publicURL;
    const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
    if (window.focus) {
      newwindow.focus()
    }
  }*/

  // Filter user reactions
  filterReactions(sourceList: Array<any>, targetList: Array<any>, category: string): Array<any> {
    targetList = sourceList.filter(source => source.category === category);
    return targetList;
  }

  // TpopupEmail() {
  //   const url = `mailto:?subject=Interactive Video&body=Check this interactive video experience I made on FreeFuse: ${this.fullShareURL}`;
  //   const newwindow = window.open(url);
  //   if (window.focus) {
  //     newwindow.focus()
  //   }
  // }
  //
  // Tpopupfacebook() {
  //   const url = 'http://www.facebook.com/sharer.php?u=' + this.fullShareURL;
  //   const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
  //   if (window.focus) {
  //     newwindow.focus()
  //   }
  // }
  //
  // Tpopuptweet() {
  //   const url = 'https://twitter.com/intent/tweet?text=' + this.fullShareURL;
  //   const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
  //   if (window.focus) {
  //     newwindow.focus()
  //   }
  // }
  //
  // Tpopuplinkedin() {
  //
  //   const url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + this.fullShareURL;
  //   const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
  //   if (window.focus) {
  //     newwindow.focus()
  //   }
  // }
  //
  // TonClickEmbedButton() {
  //   const video = this.shareId;
  //   // if (this.video_id) {
  //   //   video = this.video_id;
  //   // }
  //   // tslint:disable-next-line:max-line-length
  //   this.embedVideoUrl = `<iframe allowfullscreen frameborder="0" width="600" height="400" src="${environment.currentSharedDomain}embed/${video}"></iframe>`;
  //   this.isEmbedShow = !this.isEmbedShow;
  // }

  // Follow
  followUser(id: any) {
    const obj: any = {
      type: 'follow',
      followToUserID: id
    };

    this.crudService.followUser(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
      // this.getAllLikes();
      if (id === this.uploaderId) {
        this.uploaderDetails.userinfo.followers.push({_id: id})
        this.iAMFollowing = true;
      }

      this.getMyData();


    }, error => {
      //
    });
  }

  unfollowUser(id: any) {
    const obj: any = {
      type: 'unfollow',
      followToUserID: id
    };
    this.crudService.followUser(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
      // this.getAllLikes();
      if (id === this.uploaderId) {
        this.iAMFollowing = false;
        this.uploaderDetails.userinfo.followers.pop();
      }

      this.getMyData();

    }, error => {
      //
    });
  }

  // Open reply modal
  openReplyModal(id: any) {
    this.commentId = this.childCommentId ? this.childCommentId : id;
    // //console.log('Comment Data: ', this.commentData);
    // for(let i of this.commentData) {
    //   if (i.commentInfo.replierinfo.length > 0) {
    //   }
    //   if (i.commentInfo.replydata.length > 0) {
    //   }
    // }
    $('#reply-Modal').modal('show');
  }

  // Open share modal
  openShareModal() {
    // this.isEmbedShow = false;
    // this.fullShareURL = environment.currentSharedDomain + 'videos?video=' + this.mainID;
    // $('#share-Modal').modal('show');
    const dialogRef = this.dialog.open(ShareVideoDialogComponent, {
      width: '100%',
      maxWidth: '600px',
      minHeight: '400px',
      panelClass: 'my-dialog',
      height: 'auto',
      data: {
        title: 'Share Video',
        videoID: this.mainID,
        URL: `${environment.currentSharedDomain}videos?video=${this.mainID}`,
        embedURL: `${environment.currentSharedDomain}embed/${this.mainID}`
      },
    });
  }

  openTreeShareModal() {
    // this.isEmbedShow = false;
    this.hoverItem = false;
    // $('#Tree-share-Modal').modal('show');
    // console.log(this.mainID)
    const dialogRef = this.dialog.open(ShareVideoDialogComponent, {
      width: '100%',
      maxWidth: '600px',
      minHeight: '400px',
      panelClass: 'my-dialog',
      height: 'auto',
      data: {
        title: 'Share Video',
        videoID: this.shareId,
        URL: `${environment.currentSharedDomain}videos?video=${this.shareId}`,
        embedURL: `${environment.currentSharedDomain}embed/${this.shareId}`
      },
    });
  }

  // hover share
  // hoverShare() {
  //
  // }


  // Post a comment
  comment() {
    if (this.commentText.length < 1) {
      this.toaster.warning('Please Type Comment!')
      return;
    }
    this.commentObject.scenerio = 'comment';
    this.commentObject.videoID = this.childCommentId ? this.childCommentId : this.mainID;
    this.commentObject.commentID = 'null';
    this.commentObject.comment = this.commentText;
    this.crudService.commentAndReply(this.commentObject).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {
          this.commentText = '';
          success.data.data.profilePic = this.currentProfilePic
          success.data.data.createdAT = success.data.data.createdAt
          // WC 09272022 - Replace currentProfileDetails with currentUserData
          /*success.data.data.userName = this.currentProfileDetails.username*/
          success.data.data.userName = this.currentUserData.username;
          this.commentData.unshift(success.data.data);
          this.totalCOmments++;
          // //console.log(success)
          this.getVideoTree()
        },
        error => {
        });
  }

  // Reply on a comment
  reply() {
    this.commentObject.scenerio = 'reply';
    this.commentObject.videoID = this.mainID;
    this.commentObject.commentID = this.commentId;
    this.commentObject.comment = this.replyText;
    this.crudService.commentAndReply(this.commentObject).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {
          this.replyText = '';
          const datatemp: any = {userInfo: {}, replyInfo: {}}

          datatemp.userInfo.profilePic = this.currentProfilePic
          datatemp.createdAT = success.data.data.createdAt
          // WC 09272022 - Replace currentProfileDetails with currentUserData which is the same object
          /*datatemp.userInfo.userName = this.currentProfileDetails.username*/
          datatemp.userInfo.userName = this.currentUserData.username
          datatemp.replyInfo.comment = success.data.data.comment
          datatemp.replyInfo.updatedAt = success.data.data.updatedAt
          datatemp.replyInfo._id = success.data.data._id
          for (const i of this.commentData) {
            if (i._id === success.data.data.replyOnCommentID) {
              if (i.reply)
                i.reply.push(datatemp)
              else {
                i.reply = [];
                i.reply.push(datatemp)
              }
            }
          }
          $('#reply-Modal').modal('hide');
          // //console.log(success)
          // this.getAllComments();
        },
        error => {
        });

  }

  closeReactons() {
    $('#react-Modal').modal('hide');
  }

  checkIsUrl(url: string): boolean {
    const devRegex = /https?:\/\/videoplyback\-encoding\-bucket\.s3\.amazonaws\.com\//;
    const prodRegex = /https?:\/\/freefuse\-encoding\-bucket\.s3\.amazonaws\.com\//;
    const devMatch = url.match(devRegex);
    const prodMatch = url.match(prodRegex);
    return !!((devMatch && devMatch.length) || (prodMatch && prodMatch.length));
  }

  // tslint:disable-next-line:no-shadowed-variable
  loadNextOption(e: any) {
    this.video_id = e._id;
    clearInterval(this.interval);
    this.createInteractiveVideoObj.preview = false;
    if (this.isLoggedIN) {
      this.getYourFirstDecision(e._id);
    }
    setTimeout(() => {
      this.createInteractiveVideoObj.currentObj.currentPlayingItem.playId = e._id;
      this.createInteractiveVideoObj.currentObj.currentPlayingItem.URL = e.URL;
      this.createInteractiveVideoObj.currentObj.currentPlayingItem.poster = e.poster;
      this.createInteractiveVideoObj.currentObj.currentPlayingItem.children = e.children;
      this.createInteractiveVideoObj.currentObj.currentPlayingItem.currentTime = 0;
      this.createInteractiveVideoObj.currentObj.currentPlayingItem.time = e.time;

      // WC 09032022
      this.createInteractiveVideoObj.currentObj.currentPlayingItem.name = e.name;
      // WC 09032022

      // WC 08092022
      if (e.subtitleUrl != undefined)
        this.createInteractiveVideoObj.currentObj.currentPlayingItem.subtitleUrl = e.subtitleUrl;
      else
        this.createInteractiveVideoObj.currentObj.currentPlayingItem.subtitleUrl = '';
      // WC 08092022

      if (this.isLoggedIN) {
        // this.currentMainChoice.choices.duration = 0;
        // this.currentLevel++; load
        this.currentMainChoice.choices =
            {
              videoID: e._id,
              videoName: e.name,
              duration: 0,
              level: 0
            }
        const Payload = {
          mainvideoID: this.mainID,
          mainvideoName: this.videoDetails.name,
          duration: 0,
          choices: [
            this.currentMainChoice.choices
            // this.currentMainChoice.choices
          ]
        }
        this.currentMainChoice.duration = 0;
        this.crudService.setWatchHistory(Payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            success => {
              // //console.log(success.data);
            });
        const requestObj = {
          videoID: e._id
        };
        if (e.type !== 'main') {
          this.crudService.setChildWatchHistory(requestObj).pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
          });
        }
        this.crudService.yourFirstDecision(this.mainID).pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
          this.firstDecisions = success.data;
        });
      }
      this.createInteractiveVideoObj.preview = true;
      this.callLoadedFunctionForMobile();
    })
  }

  callLoadedFunctionForMobile() {
    this.createInteractiveVideoObj.preview = true;
    this.createInteractiveVideoObj.spinner = false;
    const isUrl = this.checkIsUrl(this.createInteractiveVideoObj.currentObj.currentPlayingItem.URL);
    this.createInteractiveVideoObj.currentObj.currentPlayingItem.isUrl = !isUrl;

    // console.log('current item = ', this.createInteractiveVideoObj.currentObj.currentPlayingItem);
    // console.log("this.isAbleToViewVideo = ", this.isAbleToViewVideo);

    if (!this.isAbleToViewVideo) {
      this.createInteractiveVideoObj.currentObj.currentPlayingItem.URL = '';
      // console.log("this.createInteractiveVideoObj.currentObj.currentPlayingItem.URL = ", this.createInteractiveVideoObj.currentObj.currentPlayingItem.URL)

      $('#privateVideoModal').modal('show');
      return;
    }
    ;

    if (isUrl) {
      //console.log('isUrl = ', isUrl);

      setTimeout(() => {

        const dom = document.getElementById('previewVideoMobile');
        const that = this
        this.player = videojs(dom, {}, function () {
          // tslint:disable-next-line:no-shadowed-variable
          this.on('loadedmetadata', function (e: any) {
            const backward = that.player.controlBar.addChild('button', {}, 1);
            const forward = that.player.controlBar.addChild('button', {}, 2);
            const reset = that.player.controlBar.addChild('button');

            const forwardDom = forward.el();
            const backwardDom = backward.el();
            const resetDom = reset.el();
            forwardDom.innerHTML = '<img src=\'../../../assets/images/videoplayer/step-forward.svg\'/style=\'width:17px;filter: invert(100%);\'>';
            forwardDom.setAttribute('title', 'Forward');
            backwardDom.innerHTML = '<img src=\'../../../assets/images/videoplayer/step-backward.svg\' style=\'width:17px;filter: invert(100%);\'/>';
            backwardDom.setAttribute('title', 'Backward');

            resetDom.innerHTML = '<svg class="bi bi-arrow-clockwise" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">' +
                '<path fill-rule="evenodd" d="M3.17 6.706a5 5 0 017.103-3.16.5.5 0 10.454-.892A6 6 0 1013.455 5.5a.5.5 0 00-.91.417 5 5 0 11-9.375.789z" clip-rule="evenodd"/>' +
                ' <path fill-rule="evenodd" d="M8.147.146a.5.5 0 01.707 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 11-.707-.708L10.293 3 8.147.854a.5.5 0 010-.708z" clip-rule="evenodd"/>' +
                '</svg>';
            resetDom.setAttribute('title', 'Start Over');
            resetDom.setAttribute('ids', 'Start Over');


            const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            if (isMobile) {
              /* your code here */
              $('.vjs-tech').parent().addClass('isMobile')
              const fullSxcreen = that.player.controlBar.addChild('button');
              const fullSxcreenDom = fullSxcreen.el();
              fullSxcreenDom.innerHTML = '<svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-arrows-fullscreen" fill="currentColor" xmlns="http://www.w3.org/2000/svg">' +
                  '<path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"/>' +
                  '</svg>';

              // tslint:disable-next-line:only-arrow-functions
              fullSxcreenDom.onclick = function () {
                $('.vjs-tech').parent().toggleClass('fullscreen')
                $('html,body').toggleClass('onMobilrPlayer')


              }
              // tslint:disable-next-line:only-arrow-functions
              fullSxcreenDom.ontouchend = function () {
                $('.vjs-tech').parent().toggleClass('fullscreen')
                $('html,body').toggleClass('onMobilrPlayer')
              }

            }
            // tslint:disable-next-line:only-arrow-functions
            forwardDom.onclick = function () {
              that.player.currentTime(that.player.currentTime() + 10);

            }
            // tslint:disable-next-line:only-arrow-functions
            forwardDom.ontouchend = function () {
              that.player.currentTime(that.player.currentTime() + 10);
            }
            // tslint:disable-next-line:only-arrow-functions
            backwardDom.onclick = function () {
              that.player.currentTime(that.player.currentTime() - 10);
            }
            // tslint:disable-next-line:only-arrow-functions
            backwardDom.ontouchend = function () {
              that.player.currentTime(that.player.currentTime() - 10);
            }
            // tslint:disable-next-line:only-arrow-functions
            resetDom.onclick = function () {
              // alert('s')
              that.startOver()
            }
            // tslint:disable-next-line:only-arrow-functions
            resetDom.ontouchend = function () {
              that.startOver()
            }

            if (that.currentMainChoice)
              this.currentTime(that.currentMainChoice.duration)
            that.createInteractiveVideoObj.currentObj.currentPlayingItem.duration = this.duration();

            if (!that.createInteractiveVideoObj.currentObj.currentPlayingItem.time) {
              that.createInteractiveVideoObj.currentObj.currentPlayingItem.time = Math.max(0, that.createInteractiveVideoObj.currentObj.currentPlayingItem.duration - 2);
            }
            if (!that.createInteractiveVideoObj.currentObj.currentPlayingItem.children
                || !that.createInteractiveVideoObj.currentObj.currentPlayingItem.children.length) {
              // For a node without any children, the choice 'Start Over' will appear 5 seconds before the clip ends
              that.createInteractiveVideoObj.currentObj.currentPlayingItem.time = Math.max(0, that.createInteractiveVideoObj.currentObj.currentPlayingItem.duration - 2);
            }
            let plotNumber: any = 0;
            that.interval = setInterval(() => {
              that.createInteractiveVideoObj.currentObj.currentPlayingItem.currentTime = this.currentTime();
              that.createInteractiveVideoObj.currentObj.currentPlayingItem.duration = this.duration();


              this.currentVideoTime = that.createInteractiveVideoObj.currentObj.currentPlayingItem.currentTime
              this.totalVideoDuration = that.createInteractiveVideoObj.currentObj.currentPlayingItem.duration


              if (that.viewCount < 1) {
                that.viewCount++;
                const obj = {
                  videoID: that.mainID
                }
                // call view count API
                that.saveViewCount(obj, this.currentTime());
                // that.crudService.saveViewCount(obj).subscribe(success => {
                //   // watch history
                //   if (that.isLoggedIN) {
                //     // tslint:disable-next-line:no-shadowed-variable
                //     that.crudService.watchHistory(obj).subscribe(success => {

                //       if (that.currentMainChoice.choices[0])
                //         that.currentMainChoice.choices = that.currentMainChoice.choices[0]

                //       // //console.log(that.currentMainChoice.choices)

                //       that.currentMainChoice.choices.duration = this.currentTime();

                //       const Payload = {
                //         mainvideoID: that.mainID,
                //         mainvideoName: that.videoDetails.name,
                //         duration: this.currentTime(),
                //         choices: [
                //           that.currentMainChoice.choices
                //           // that.currentMainChoice.choices
                //         ]
                //       }
                //       that.crudService.setWatchHistory(Payload).subscribe(
                //         // tslint:disable-next-line:no-shadowed-variable
                //         success => {
                //           // //console.log(success.data);
                //         });

                //     }, error => {

                //     });
                //   }
                // }, error => {

                // });
              }

              that.createInteractiveVideoObj.currentObj.currentPlayingItem.mintue =
                  Math.floor(that.createInteractiveVideoObj.currentObj.currentPlayingItem.time / 60);
              that.createInteractiveVideoObj.currentObj.currentPlayingItem.seconds = (that.createInteractiveVideoObj.currentObj.currentPlayingItem.time - that.createInteractiveVideoObj.currentObj.currentPlayingItem.mintue * 60).toFixed(2);
              if (that.createInteractiveVideoObj.currentObj.currentPlayingItem.currentTime > that.createInteractiveVideoObj.currentObj.currentPlayingItem.time) {
                if (plotNumber === 0) {
                  that.addOptions();
                  clearInterval(this.interval)
                }
                plotNumber++;
              } else {
                plotNumber = 0;
                that.removeoptions()
                clearInterval(this.interval)

              }

              // auto skipping code
              // if(this.currentVideoTime ===  this.totalVideoDuration){
              //   curentnum++;
              //   if(curentnum < 2){
              //     that.skipVideo()
              //   }
              //     clearInterval(this.interval);

              // }
            }, 500);


            const currentTime = 0;
            setTimeout(() => {
              let hasChilren = true;

              // WC 12142022 --- Time marker will be removed for a node without any children
              if (that.createInteractiveVideoObj.currentObj.currentPlayingItem.children == null || that.createInteractiveVideoObj.currentObj.currentPlayingItem.children == undefined || that.createInteractiveVideoObj.currentObj.currentPlayingItem.children.length == 0) {
                hasChilren = false;

                // For a node without any children, the choice 'Start Over' will appear 5 seconds before the clip ends
                if (that.createInteractiveVideoObj.currentObj.currentPlayingItem.duration > 5)
                  that.createInteractiveVideoObj.currentObj.currentPlayingItem.time = that.createInteractiveVideoObj.currentObj.currentPlayingItem.duration - 5;
                else
                  that.createInteractiveVideoObj.currentObj.currentPlayingItem.time = 0;
              }
              // WC 12142022

              // WC 09032022 --- To show the marker in the HH:MM:SS format
              that.createInteractiveVideoObj.currentObj.currentPlayingItem.markerTime = that.convertSecondsToHHMMSSFormat(that.createInteractiveVideoObj.currentObj.currentPlayingItem.time);
              // WC 09032022

              that.createInteractiveVideoObj.currentObj.currentPlayingItem.currentTime = currentTime
              // tslint:disable-next-line:max-line-length
              // //console.log(that.createInteractiveVideoObj.currentObj.currentPlayingItem.time)
              that.placeMarker(hasChilren, that.createInteractiveVideoObj.currentObj.currentPlayingItem.time, 'previewVideoMobile', this.duration())
              //
            })
          });
        });
      })
    } else {
      setTimeout(() => {
        this.addOptionsImage();
        setTimeout(() => {
          window.open(this.createInteractiveVideoObj.currentObj.currentPlayingItem.URL, '_blank');
        }, 500)
      })
    }
  }

  private saveViewCount(obj: any, currentTime: any) {
    this.crudService.saveViewCount(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
      // watch history
      if (this.isLoggedIN) {
        // tslint:disable-next-line:no-shadowed-variable
        this.crudService.watchHistory(obj).pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {

          if (this.currentMainChoice.choices[0])
            this.currentMainChoice.choices = this.currentMainChoice.choices[0]

          // //console.log(that.currentMainChoice.choices)

          this.currentMainChoice.choices.duration = currentTime;

          const Payload = {
            mainvideoID: this.mainID,
            mainvideoName: this.videoDetails.name,
            duration: currentTime,
            choices: [
              this.currentMainChoice.choices
            ]
          }
          this.crudService.setWatchHistory(Payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(
              // tslint:disable-next-line:no-shadowed-variable
              success => {
                // //console.log(success.data);
              });

        }, error => {

        });
      }
    }, error => {
    });
  }

  skipVideo() {


    setTimeout(() => {
      if (this.createInteractiveVideoObj.currentObj.currentPlayingItem.children) {

        this.loadNextOption(this.createInteractiveVideoObj.currentObj.currentPlayingItem.children[0])
      }

    }, 3000);


  }

  addOptions() {
    const that = this;
    let nodes = [];
    // if (this.createInteractiveVideoObj.currentObj.currentPlayingItem?.playId) {
    //   nodes = this.getNextViewNode(this.createInteractiveVideoObj.currentObj.currentPlayingItem.playId, [this.parent], true) ?? [];
    // } else {
    //   nodes = this.getNextViewNode(this.createInteractiveVideoObj.currentObj.currentPlayingItem._id, [this.parent], true) ?? [];
    // }
    // console.log(this.createInteractiveVideoObj.currentObj.currentPlayingItem, nodes, this.parent)
    let hasChild: boolean;
    if (this.createInteractiveVideoObj.currentObj.currentPlayingItem.children
        && this.createInteractiveVideoObj.currentObj.currentPlayingItem.children.length) {
      hasChild = true;
      for (const i of this.createInteractiveVideoObj.currentObj.currentPlayingItem.children) {
        if (!!i.name) {
          nodes.push(i)
        }
      }
    } else {
      hasChild = false;
      nodes = [this.parent];
    }

    const width = Math.round(100 / nodes.length)
    let index = 0;
    for (const i of nodes) {
      index++
      const backward = this.player.controlBar.addChild('button', {});
      const backwardDom = backward.el();
      backwardDom.innerHTML = hasChild ? i.name : 'Start From The Beginning';
      backwardDom.setAttribute('title', hasChild ? i.name : 'Start From The Beginning');
      backwardDom.setAttribute('customChoices', 'yes');
      backwardDom.setAttribute('id', i._id);
      backwardDom.setAttribute('custom-width', width);
      backwardDom.setAttribute('custom-distance', 'distance' + index);
      $('.vjs-control-bar').addClass('added_choices')

      backwardDom.onclick = (e: any) => {
        that.loadNextOption(i);

      }
      // tslint:disable-next-line:only-arrow-functions
      backwardDom.ontouchend = function () {
        that.loadNextOption(i);
      }
    }
  }

  // getNextViewNode(target, children, first = false) {
  //   for (const node of children) {
  //     if (node._id === target) {
  //       if (!first) {
  //         if (!node?.viewedByCurrentUser) {
  //           return [node];
  //         }
  //       }
  //       if (node.children) {
  //         const data = [];
  //         for (const o of node.children) {
  //           if (!o?.viewedByCurrentUser) {
  //             data.push(o);
  //           }
  //         }
  //         if (data.length) {
  //           return data;
  //         } else {
  //           // return children;
  //           return this.getNextViewNode(node._id, [this.parent]);
  //         }
  //       }
  //     }
  //     if (node.children) {
  //       return this.getNextViewNode(target, node.children)
  //     }
  //   }
  //
  // }

  // Display Choices
  // addOptions1() {
  //   const that = this;
  //   let length = 0;
  //   if (this.createInteractiveVideoObj.currentObj.currentPlayingItem.children) {
  //     for (const i of this.createInteractiveVideoObj.currentObj.currentPlayingItem.children) {
  //       if (!!i.name) {
  //         length++
  //       }
  //     }
  //   }
  //
  //   // = this.player.controlBar.addChild("div",{});
  //
  //   let width: any = 100 / length
  //   width = Math.round(width)
  //   let index = 0;
  //   if (this.createInteractiveVideoObj.currentObj.currentPlayingItem.children) {
  //     for (const i of this.createInteractiveVideoObj.currentObj.currentPlayingItem.children) {
  //       if (!!i.name) {
  //         index++
  //         const backward = this.player.controlBar.addChild('button', {});
  //         //  //console.log(i)
  //         const backwardDom = backward.el();
  //         backwardDom.innerHTML = i.name;
  //         backwardDom.setAttribute('title', i.name);
  //         backwardDom.setAttribute('customChoices', 'yes');
  //         backwardDom.setAttribute('id', i._id);
  //         backwardDom.setAttribute('custom-width', width);
  //         backwardDom.setAttribute('custom-distance', 'distance' + index);
  //         $('.vjs-control-bar').addClass('added_choices')
  //
  //         backwardDom.onclick = (e: any) => {
  //           that.loadNextOption(i);
  //
  //         }
  //         // tslint:disable-next-line:only-arrow-functions
  //         backwardDom.ontouchend = function () {
  //           that.loadNextOption(i);
  //         }
  //       }
  //     }
  //
  //   }
  //
  // }

  addOptionsImage() {
    // console.log(this.createInteractiveVideoObj.currentObj.currentPlayingItem);
    let length = 0;
    if (this.createInteractiveVideoObj.currentObj.currentPlayingItem.children) {
      for (const i of this.createInteractiveVideoObj.currentObj.currentPlayingItem.children) {
        if (!!i.name) {
          length++
        }
      }
    }
    let width: any = 100 / length;
    width = Math.round(width)
    let index = 0;
    if (this.createInteractiveVideoObj.currentObj.currentPlayingItem.children) {
      for (const i of this.createInteractiveVideoObj.currentObj.currentPlayingItem.children) {
        if (!!i.name) {
          index++
          // const backward = this.player.controlBar.addChild('button', {});
          const videoControlBar = document.querySelector('.video-control-bar');
          const button = document.createElement('button');
          button.innerHTML = i.name;
          button.setAttribute('title', i.name);
          button.setAttribute('class', 'vjs-control vjs-button');
          button.setAttribute('customChoices', 'yes');
          button.setAttribute('id', i._id);
          button.setAttribute('custom-width', width);
          button.setAttribute('custom-distance', 'distance' + index);
          videoControlBar.append(button);
          button.onclick = () => {
            this.loadNextOption(i);
          }
          // tslint:disable-next-line:only-arrow-functions
          button.ontouchend = () => {
            this.loadNextOption(i);
          }
        }
      }
    }
  }

  styleVideoControllerBar() {
    if (this.createInteractiveVideoObj.currentObj.currentPlayingItem?.children?.length > 0) {
      return {bottom: '66px', color: 'white'};
    }
    return {}
  }

  // Remove Choices
  removeoptions() {
    $('.vjs-control.vjs-button[customchoices="yes"]').remove();
    $('.vjs-control-bar').removeClass('added_choices')

  }

  getTopViewedChild() {
    this.crudService.getTopViewedChild(this.mainID).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {
          setTimeout(() => {
            let totalViews: any = 0
            this.topViewedChoices = success.data;
            for (const i of this.topViewedChoices) {
              totalViews = totalViews + i.viewCount
            }
            for (const i of this.topViewedChoices) {
              if (totalViews === 0) {
                i.percentage = 0;
                i.progress = '0%';

              } else {
                i.percentage = this.calculatePercentage(i.viewCount, totalViews);
                i.progress = this.calculatePercentage(i.viewCount, totalViews) + '%';

              }
            }
            // //console.log('======', this.topViewedChoices);
          }, 1000);
          this.choice = false;
        }, error => {

        }
    );
  }

  getMostPopularPath() {
    this.choice = true;
    this.mostPopularPath = [];
    this.crudService.mostPopularPath(this.mainID).pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
      // console.log('most popular path is', success);

      // if (this.isArray(success.data)) {
      //   this.mostPopularPath = success.data;
      //   // this.mostPopularPath = this.mostPopularPath[0]
      // }
      // else {
      //   this.mostPopularPath.push(success.data)
      // }
      this.mostPopularPath = [];
      if (this.isArray(success.data)) {
        for (let i = 0; i < success.data.length; i++) {
          if (success.data[i]._id)
            this.mostPopularPath.push(success.data[i])
        }
      } else {
        this.mostPopularPath.push(success.data)
      }
      this.getYourFirstDecision(this.mainID);
      this.getTopViewedChild();

    });
  }

  // tslint:disable-next-line:only-arrow-functions
  isArray = function (a: { constructor: ArrayConstructor; }) {
    return (!!a) && (a.constructor === Array);
  };

  getYourFirstDecision(id: any) {
    this.crudService.yourFirstDecision(id).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        success => {
          this.firstDecisions = success.data;
        });
  }

  calculatePercentage(childViewcount: any, totalViews: any): any {
    const percantage = (childViewcount / totalViews) * 100;
    return Math.round(percantage);
  }

  // Marker for Choices
  placeMarker(haveChildren: any, markerTime: number, id: string, duration: number) {
    // console.log('markerTime = ' + markerTime + ', duration = ' + duration);
    if (!haveChildren) {
      $('.marker').remove();
      // console.log('No children, marker removed!');
    } else {
      // WC 09012022
      if (markerTime == null)
        markerTime = 0;

      if (duration == null)
        duration = 0;
      // WC 09012022

      const width = $('#' + id).width();
      let precentageOfMarker = (markerTime / duration) * 100;

      if (precentageOfMarker > 100)
        precentageOfMarker = 99;

      // console.log('precentageOfMarker = ', precentageOfMarker);

      $('.marker').remove();
      $('#' + id + ' .vjs-progress-holder.vjs-slider.vjs-slider-horizontal').append('<div class="marker" style="left:' + precentageOfMarker + '%"></div>')
      // console.log('marker placed at ' + precentageOfMarker + '%');
    }
  }

  createNestedArray(list: string | any[]) {
    const map = {};
    let node: { parentId: string; };
    const roots = [];
    let i: number;
    for (i = 0; i < list.length; i += 1) {
      map[list[i]._id] = i; // initialize the map
      list[i].children = []; // initialize the children

    }
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.parentId !== '0') {
        // if you have dangling branches check that map[node.parentId] exists
        if (list[map[node.parentId]])
          list[map[node.parentId]].children.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  ngOnDestroy() {
    if (this.isLoggedIN) {
      this.saveHistory();
    }
    try {
      if (this.shepherdService?.isActive) {
        this.shepherdService.complete();
        this.shepherdService.hide();
        $('div.shepherd-enabled').remove();
      }
    } catch (e) {
    }
    // clearInterval(this.intervalWatchHistory)
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  saveHistory() {
    clearInterval(this.interval)
    if (this.currentMainChoice?.choices[0])
      this.currentMainChoice.choices = this.currentMainChoice.choices[0]

    // //console.log(this.currentMainChoice.choices)

    //  this.currentMainChoice.choices.duration = e.target.currentTime
    if (this.currentMainChoice?.choices) {
      const Payload = {
        mainvideoID: this.mainID,
        mainvideoName: this.videoDetails.name,
        duration: this.currentMainChoice.choices.duration,
        choices: [
          this.currentMainChoice.choices
          // this.currentMainChoice.choices
        ]
      }
      this.crudService.setWatchHistory(Payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(
          success => {
            // //console.log(success.data);
          });
    }
  }

  callTreeTooltip(item) {
    this.shareId = item._id;
    // this.fullShareURL = environment.currentSharedDomain + 'videos?video=' + this.shareId;
    try {
      const token = this.localStorageService.getItem('token');
      if (token) {
        this.hoverItem = item._id;
      } else {
        this.hoverItem = false;
        this.unAuthItem = item._id;
      }
    } catch {
      this.hoverItem = false;
      this.unAuthItem = item._id;
    }
  }

  hideTreeTooltip() {
    this.unAuthItem = false;
    this.hoverItem = false;
  }

  openCollectModal(item: any) {
    // console.log(item);
    this.selectedNodeId = item._id
    this.hoverItem = false;
    this.processing = true;
    this.crudService.getAllCollections().pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.allCollections = res.data;
      this.processing = false;
    })
    $('#collectModal').modal('show');
  }

  getSelectedCollection(item: any) {
    this.collectionId = item._id
  }

  addNode() {
    if (this.collectionId == null) {
      this.toaster.error('Please select any collection first.')
    } else {
      const payload = {
        collectionId: this.collectionId,
        videoId: this.selectedNodeId
      }
      this.isCreatedNode = false
      this.crudService.addNode(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
        this.isCreatedNode = true;
        this.toaster.success('Node saved successfully');
        this.hideModal()
        // console.log('node created ', res);

      }, err => {
        if (err) {
          this.isCreatedNode = true;

        }
      })

    }
  }

  hideModal() {
    $('#collectModal').modal('hide');
  }

  // Create Question
  createQuestion(item) {
    const payload = {
      videoId: item._id
    }
    this.crudService.questionVideo(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.getVideoTree()
    })

  }

  // WC 09042022
  convertSecondsToHHMMSSFormat = (decimalSeconds) => {

    // console.log("decimalSeconds = " + decimalSeconds);

    const hh = Math.floor(decimalSeconds / 3600);

    let hhStr = '';
    if (hh < 10)
      hhStr = '0' + String(hh);
    else
      hhStr = String(hh);

    // console.log("hhStr = " + hhStr);

    const reminderSec = (decimalSeconds % 3600);

    // console.log("reminderSec = " + reminderSec);

    const mm = Math.floor(reminderSec / 60)
    // console.log("mm = " + mm);

    let mmStr = '';

    if (mm < 10)
      mmStr = '0' + String(mm);
    else
      mmStr = String(mm);

    // console.log("mmStr = " + mmStr);

    const ss = (reminderSec % 60);
    // console.log("ss = " + ss);

    let ssStr = '';
    if (ss < 10)
      ssStr = '0' + String(ss);
    else
      ssStr = String(ss);

    const pos = ssStr.indexOf('.');

    if (pos > 0) {
      ssStr = ssStr.substring(0, pos);
    }

    // console.log("ssStr = " + ssStr);

    // console.log("timeStr = " + timeStr);

    return hhStr + ':' + mmStr + ':' + ssStr;
  }

  // WC 09042022

  closeMiniPlayer() {
    this.isMiniPlayer = false;
    this.player.pause();
  }

  goBack() {
    if ((this.location.getState() as any)?.navigationId > 1)
      this.location.back();
    else
      this.router.navigateByUrl('/my-videos');
  }
}




