// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title{
    border-bottom: 1px solid #d9d9d9;
    padding: 16px 0;
}

.content{
    padding: 10px 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/feature-group-overview/feature-group-overview.component.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".title{\n    border-bottom: 1px solid #d9d9d9;\n    padding: 16px 0;\n}\n\n.content{\n    padding: 10px 20px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
