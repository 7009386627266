// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form .user-pic-text > img {
  border-radius: 8px;
  object-fit: cover;
}

.signup-btn {
  display: block;
  width: 100%;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

a :hover {
  text-decoration: none;
}

.btn-show-password {
  position: absolute;
  right: 0;
  top: 0;
}

.h6,
h6 {
  font-size: 1rem;
}

.tc-pp-text {
  color: #aeaeae;
  font-size: 12px;
  margin-top: 22px;
  margin-bottom: 0px;
  text-align: center;
}

.tc-pp-text a {
  color: #aeaeae;
  text-decoration: underline;
}

.video-common-form > h4 {
  text-align: center;
  font-size: 20px;
  color: #3c4858;
  margin-bottom: 26px;
}
`, "",{"version":3,"sources":["webpack://./src/app/beforeLogin/login/login.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,MAAM;AACR;;AAEA;;EAEE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB","sourcesContent":["form .user-pic-text > img {\r\n  border-radius: 8px;\r\n  object-fit: cover;\r\n}\r\n\r\n.signup-btn {\r\n  display: block;\r\n  width: 100%;\r\n  text-align: center;\r\n  color: #fff;\r\n  text-decoration: none;\r\n}\r\n\r\na :hover {\r\n  text-decoration: none;\r\n}\r\n\r\n.btn-show-password {\r\n  position: absolute;\r\n  right: 0;\r\n  top: 0;\r\n}\r\n\r\n.h6,\r\nh6 {\r\n  font-size: 1rem;\r\n}\r\n\r\n.tc-pp-text {\r\n  color: #aeaeae;\r\n  font-size: 12px;\r\n  margin-top: 22px;\r\n  margin-bottom: 0px;\r\n  text-align: center;\r\n}\r\n\r\n.tc-pp-text a {\r\n  color: #aeaeae;\r\n  text-decoration: underline;\r\n}\r\n\r\n.video-common-form > h4 {\r\n  text-align: center;\r\n  font-size: 20px;\r\n  color: #3c4858;\r\n  margin-bottom: 26px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
