import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CrudService} from 'src/app/services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../environments/environment';
import {DashboardService} from '../../services/dashboard.service';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
    @ViewChild('loginForm') loginForm: any;
    private isLoggedIN: any = false;
    loginObject = {
        email: '',
        password: '',
        spinner: false,
    };
    fieldPassTextType = false;
    private failedAttemptsCount = 0;
    private deactivated = false;
    redirect = '';
    private isRedirect = false;
    cid = '';
    paramsValue:any;

    constructor(private urlService: CrudService,
                private dashboardService: DashboardService,
                private toastr: ToastrService,
                private router: Router,
                private activeRoute: ActivatedRoute,
                private localStorageService: LocalStorageService,
    ) {

        // WC 04062022 - To reset the logout flag
        this.localStorageService.setItem('logout', 'false');
        // WC 04062022

        this.activeRoute.queryParams.subscribe(params => {
            //console.log("params = ", params);
            this.paramsValue = params;
            if (params.cid || params.promoCode) {
                const payload = {
                    campaignId: params.cid,
                    promoCode: params.promoCode,
                    originUrl: window.location.href
                };
                this.urlService.setMarketingCampaign(payload).subscribe();

                this.cid = params.cid;

            }

            if (params.org)
                this.localStorageService.setItem('org', params.org);
            //WC 03022024

            // Google OAuth or SAML Token, Login from Pricing Page and After Validated OTP
            if (params.token) {
                if (this.localStorageService.getItem('token')) {
                    this.localStorageService.removeItem('token')
                }

                this.localStorageService.setItem('token', params.token);
                this.localStorageService.removeItem('anonymousToken')

                this.dashboardService.isLoggedIn = true;

                // WC 05222023 -- To set the campaignId for signing with Google
                if (window.sessionStorage.getItem('campaignId') == undefined || window.sessionStorage.getItem('campaignId') == null)
                    window.sessionStorage.setItem('campaignId', 'FF02');

                // WC 08292022
                this.urlService.userDetailsV2().subscribe(success => {
                    this.dashboardService.onGetUserData();
                    this.dashboardService.onGetShoppingCart();
                    this.localStorageService.setItem('user', success.data.details._id);
                    // console.log('In LOGIN AFTER ASSIGNMENT - this.localStorageService.getItem(\'user\') = ' + this.localStorageService.getItem('user'));

                    if (success.data.details.isSubscriber != undefined)
                        this.localStorageService.setItem('isSubscriber', success.data.details.isSubscriber);
                    else
                        this.localStorageService.setItem('isSubscriber', 'false');

                    if (success.data.details.payCustomerClientSecret != undefined)
                        this.localStorageService.setItem('clientSecret', success.data.details.payCustomerClientSecret);

                    if (this.localStorageService.getItem('isPricingRedirect') == 'true') {
                        // console.log("## In LOGIN AFTER OTP VALIDATION AND BEFORE NAVIGATING TO PRICING - this.localStorageService.getItem('user') = " + this.localStorageService.getItem("user"));

                        if (success.data.details.isfirstlogin) {
                            this.router.navigate(['/complete-profile'])
                        } else {
                            this.router.navigate(['pricing']);
                        }

                        } else if (this.localStorageService.getItem('redirectURL') != null && this.localStorageService.getItem('redirectURL').indexOf('/community/') != -1) {
                        this.router.navigateByUrl(this.localStorageService.getItem('redirectURL'));
                        this.localStorageService.removeItem('redirectURL');
                    } else {

                        //*** SSO USER FLOW ***: 
                        //WC 03022024
                        //The id which SSO parameter for the landing page that the SSO user will be redirected to. 
                        //The actual  landing page is stored in the ssoClient collection for the the companyId
                        // The following code is in the backend api/auth/saml/index.js
                        // if (ssoClient.landingPage == 'videos')
                        //     var redirectUrl = `${process.env.API_HOST_stream}://${process.env.DOMAIN_URL}/login?token=${result.token}&landing=${ssoClient.landingPage}&id=${relayState}&org=${req.params.companyId}&groupId=${ssoClient.groupId}`;
                        // else // landing page is my-groups
                        //     var redirectUrl = `${process.env.API_HOST_stream}://${process.env.DOMAIN_URL}/login?token=${result.token}&landing=${ssoClient.landingPage}&id=${ssoClient.groupId}&org=${req.params.companyId}`;
                            
                        if (params.id) {

                            this.loginObject.spinner = true;

                            //console.log("params.id = ", params.id); //id contains either the video id or group id

                            this.urlService.getAllGroups().subscribe(res => {
                                const allGroups = res.data;
                    
                                // console.log("allGroups = ", allGroups);

                                if (params.landing == "videos")
                                    var groupId = params.groupId;
                                else 
                                    var groupId = params.id;

                                //Filter for the group which _id is the same as the ssoGroupId passed in the query string param
                                const group = allGroups.filter(x => JSON.stringify(x._id) == JSON.stringify(groupId));

                                // console.log("group = ", group);

                                //If the SAML user is already a member of the group
                                if (group.length > 0) {
                                    this.loginObject.spinner = false;

                                    // console.log("SAML user is a member of group = " + group);

                                    if (params.landing == "videos")
                                        this.router.navigateByUrl(`/videos?video=${params.id}`);
                                    else 
                                        this.router.navigateByUrl(`/my-group/${params.id}`);
                                } else {
                                    // console.log("SAML user is NOT a member of group = " + group);

                                    //The SAML user is not a member of the ssoGroup yet
                                    //Get the sso Group details
                                    //Check if groupId is passed in the query string or not, if it is then use it to get the group details else use id
                                    //NOTE: for landing page of video player, the groupId is passed in the URL
               
                                    this.urlService.getGroupDetail(groupId).subscribe(res => {
                                        var groupDetails = res.data[0];
                                        // console.log("SAML groupDetails = ", groupDetails);
                                        // console.log("Checking if SAML user is following group admin");
                                        this.urlService.isFollowing(groupDetails.admin).subscribe(res => {
                                            // console.log("SAML user is following group admin = ", res.data);

                                            const isFollowingGroupAdmin = res.data;

                                            if (!isFollowingGroupAdmin) {

                                                const obj: any = {
                                                    type: 'follow',
                                                    followToUserID: groupDetails.admin
                                                };
                                                //Follow the sso group admin
                                                this.urlService.followUser(obj).subscribe(res => {
                                                    // console.log("SAML user SUCCESSFULLY follow group admin - res = ", res);
                                                    //Successfully follow the sso group admin
                                                });
                                            };
                                        })

                                        //Add the SAML user to the group as a member
                                        const newMember = {
                                            groupId: groupDetails._id,
                                            members:  [{"accessType":"MEMBER","memberId":this.localStorageService.getItem('user')}]
                                        };

                                        this.urlService.joinGroup(newMember).subscribe(res => {
                                            //console.log("SAML user SUCCESSFULLY joining group = " + group + ", redirecting to " + `/my-group/${params.ssoGroupId}`);

                                            this.loginObject.spinner = false;
                                        });
                                    });
                                };
                            });

                            const landingPage = params.landing;

                            if (landingPage == 'my-group') {
                                
                                this.router.navigateByUrl(`/my-group/${params.id}`);
                                
                            } else if (landingPage == 'videos') {

                                this.router.navigateByUrl(`/videos?video=${params.id}`);

                            }

                            //* END OF SSO FLOW *//
                        } else {
                            //WC 03042024 --- REGULAR USER SIGN UP or LOGIN
                            if (success.data.details.isfirstlogin) {
                                this.router.navigate(['/complete-profile'])
                            } else {

                                //WC 01192025 --- To handle org = CNCDA
                                if (params.org == "CNCDA")
                                    this.router.navigateByUrl(`/my-group/${params.groupId}`);
                                else
                                    this.router.navigate(['profile']);
                            }
                        };
                    }
                });
                // WC 08292022
            } else {
                // console.log("### params.cid = ", params.cid);

                if (this.localStorageService.getItem('token')) {
                    if (params.org)
                        this.localStorageService.setItem('org', params.org);
                        if(params.groupId){
                            this.router.navigate(['/my-group/' + this.paramsValue?.groupId])
                        }else{
                            this.router.navigate(['profile']);
                        }
                } else {
                    if (this.localStorageService.getItem('anonymousToken')) {
                        // this.router.navigate(['dashboard']);
                        // this.router.navigate(['profile']);
                    } else {
                        this.getAnonymousUser();
                    }
                }
            }

            // WC 06252022
            // console.log("params.redirect = ", params.redirect);
            // redirect=pricing&pricing=individual&solution=Training%20and%20Learning
            if (params.redirect != undefined) {
                this.redirect = params.redirect;
                this.isRedirect = true;
                // console.log("this.localStorageService.setItem('isPricingRedirect', 'true')");
                this.localStorageService.setItem('isPricingRedirect', 'true');

                // WC 04122023
                this.localStorageService.setItem('redirectPricingParam', params.pricing);
                this.localStorageService.setItem('redirectSolutionParam', params.solution);
                // WC 04122023
            }

        });
    }

    ngOnInit() {

        setTimeout(() => {
            $(document).trigger('click')
        }, 1000)
    }

    // loginFirstStep() {
    //     this.loginObject.spinner = true;
    //
    //     // Api call here
    //     const finalObj: any = {
    //         email: this.loginObject.email,
    //     }
    //
    //     this.urlService.loginDetailsV2(finalObj).subscribe(
    //         success => {
    //
    //             // WC 10042022
    //             this.loginObject.firstname = success.data.firstname;
    //             this.loginObject.lastname = success.data.lastname;
    //             // WC 10042022
    //             this.loginObject.userName = success.data.username
    //             this.loginObject.profilePic = success.data.profilepic
    //             this.loginObject.spinner = false;
    //             this.loginObject.isFirstFormCompleted = true;
    //             this.localStorageService.setItem('profilePic', this.loginObject.profilePic)
    //         },
    //         error => {
    //             this.loginObject.spinner = false;
    //         }
    //     )
    // }

    getAnonymousUser() {
        this.urlService.getAnonymousUser().subscribe(
            success => {
                if (this.localStorageService.getItem('anonymousToken')) {
                } else {
                    this.localStorageService.setItem('anonymousToken', success.data.token);
                }
            }
        );
    }

    onSubmit() {
        this.loginObject.spinner = true;
        const finalObj: any = {
            campaignId: window.sessionStorage.getItem('campaignId'),
            email: this.loginObject.email,
            password: this.loginObject.password
        }

        // console.log("onSubmit - finalObj = ", finalObj);

        this.urlService.login(finalObj).subscribe(
            success => {

                this.localStorageService.setItem('token', success.data.token);

                this.isLoggedIN = !(this.localStorageService.getItem('token') === null || this.localStorageService.getItem('token') === undefined);

                // console.log("this.isLoggedIN = ", this.isLoggedIN);

                if (this.isLoggedIN) {
                    this.dashboardService.onGetUserData();
                    this.dashboardService.onGetShoppingCart();
                    this.urlService.userDetailsV2().subscribe(success => {
                        this.localStorageService.setItem('user', success.data.details._id);

                        // WC 05132022
                        if (success.data.details.isSubscriber != undefined)
                            this.localStorageService.setItem('isSubscriber', success.data.details.isSubscriber);
                        else
                            this.localStorageService.setItem('isSubscriber', 'false');

                        if (success.data.details.payCustomerClientSecret != undefined)
                            this.localStorageService.setItem('clientSecret', success.data.details.payCustomerClientSecret);
                        // WC 05132022


                        this.localStorageService.removeItem('anonymousToken');

                        this.deactivated = success.data.details.isDeactivated;
                        
                        if (success.data.details.isOtpVerified) {

                            let payload: any = {
                                email: this.loginObject.email.split('@')[1],
                            }

                            if (this.paramsValue.org && this.paramsValue.org?.includes('CNCDA')) {
                                payload.org = 'cncda';
                            }

                            this.urlService.getPersonalizedFlow(payload).subscribe(
                                (personalizeRes) => {
                                    // let emails = personalizeRes.data[0]?.emails?.length ? personalizeRes.data[0]?.emails : [];
                                    // if (emails.includes(this.loginObject.email.split('@')[1])) {
                                    if (personalizeRes.data.length > 0) {
                                        this.localStorageService.setItem('isPersonalize', "true");
                                        this.localStorageService.setItem('personalizedGroupId', personalizeRes.data[0]?.groupId);
                                        this.router.navigate(['/my-group/' + personalizeRes.data[0]?.groupId]);
                                    } else {
                                        if (success.data.details.isfirstlogin) {
                                            // this.router.navigate(['/complete-profile']);
                                            this.loginObject.spinner = false;                                
                                            if(this.paramsValue?.groupId){
                                                this.router.navigate(['/my-group/' + this.paramsValue?.groupId])
                                            }
                                            else if (this.localStorageService.getItem('grpId')) {
                                                const id = this.localStorageService.getItem('grpId')
            
                                                // WC 06252022
                                                if (this.redirect != ''){
                                                    this.router.navigate([this.redirect])
                                                }
                                                else{
                                                    this.router.navigate(['/my-group/' + id])
                                                }
                                                // WC 06252022
                                            } else {
                                                this.router.navigate(['/complete-profile']);
                                            }
                                        } else {
                                            if (success.data.details.isDeactivated) {
                                                this.toastr.info('Account deactivated');
                                                this.router.navigate(['/login']);
                                                this.loginObject.spinner = false;
                                            } else {
                                                const id = this.localStorageService.getItem('grpId')
                                                this.cureentGroupAccess(id)
                                                this.loginObject.spinner = false;
            
                                                // console.log("this.paramsValue?.groupId ===> ", this.paramsValue?.groupId);
                                                
                                                if(this.paramsValue?.groupId){
                                                    this.router.navigate(['/my-group/' + this.paramsValue?.groupId])
                                                }
                                                // WC 06252022
                                                else if (this.redirect != '') {
                                                    this.router.navigateByUrl(this.redirect);
                                                    // console.log('Navigating to this.redirect = ', this.redirect);
                                                } else {
            
                                                    this.router.navigate(['/profile']);
                                                    // console.log('Navigating to /Profile');
                                                }
                                                // WC 06252022
                                            }
                                        }
                                    }
                                },
                                (error) => {
                                    console.log("error", error);
                                }
                            )
                        } else {
                            this.localStorageService.setItem('email', success.data.details.email);
                            this.router.navigate(['/otp'])
                        }

                    });
                }

            },
            error => {
                this.loginObject.spinner = false;
                if (error.error.data.message === 'Otp not verified') {
                    this.localStorageService.setItem('email', this.loginObject.email);
                    this.router.navigate(['/otp'])
                } else {
                    ++this.failedAttemptsCount;
                    if (this.failedAttemptsCount > 4) {
                        this.failedAttemptsCount = 0;
                        $('#failedAttempts').modal('show');
                    }
                }
                //  console.log(error.error.data.message)
            }
        )
    }

    changeUserLogin(id) {
        if ($('#' + id).val() != '') {
            $('#' + id).siblings('.input-field').addClass('video_input_focus');
            $('#' + id).addClass('focusIn');
        } else {
            $('#' + id).siblings('.input-field').removeClass('video_input_focus');
        }
    }

    activateAccount() {
        const obj: any = {
            requestType: 'Activate'
        };
        this.urlService.deactivateAccount(obj).subscribe(
            success => {
                this.deactivated = false;
                this.toastr.success('Account Activated');
                this.router.navigate(['login']);
                this.loginObject.spinner = false;
            }, error => {
                this.toastr.error('Error occured during activation');
            }
        );
    }

    togglePasswordTextType() {
        this.fieldPassTextType = !this.fieldPassTextType;
    }

    ssoLogin() {
        this.loginForm.reset();
        const url = environment.API_URLV2 + '/auth/saml';
        // console.log(url)
        this.router.navigate([]).then(result => {
            window.location.replace(url)
        });
    }

    googleLogin() {
        this.loginForm.reset();
        const url = environment.API_URL + '/auth/google';
        //console.log(url)
        this.router.navigate([]).then(result => {
            window.location.replace(url)
        });
    }

    fbLogin() {
        this.loginForm.reset();
        const url = environment.API_URL + '/auth/facebook';
        this.router.navigate([]).then(result => {
            window.location.replace(url)
        });
    }

    ngOnDestroy() {
        //  this.localStorageService.removeItem('grpId')
    }

    cureentGroupAccess(id) {
        if (id !== null) {
            this.urlService.getGroupDetail(id).subscribe(res => {
                const grpId = this.localStorageService.getItem('grpId');
                // console.log('local storage', this.localStorageService.getItem('grpId'))
                if (grpId !== null) {
                    setTimeout(() => {
                        this.router.navigate(['/my-group/' + grpId]);
                        this.loginObject.spinner = false;
                    }, 500);
                }
            })
        } else {
            // WC 05132022 - if planId exists then route to checkout page instead of dashboard
            // this.router.navigate(['dashboard']);
            this.router.navigate(['profile']);

            this.loginObject.spinner = false;
        }

    }
}

