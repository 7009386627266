import {Component, OnInit} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../services/localStorage.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  public activities: Array<any> = [];
  public notifications: Array<any> = [];
  public spinner:any = false;
  public limit = 10;
  public page = 1;
  public likeCategories: Array<any> = [
    {
      shortcut:0,
      id: '1',
      category: 'Laugh',
      emoji: '../../../assets/images/videoplayer/react-laugh.svg'
    },
    {
      shortcut:1,
      id: '2',
      category: 'Like',
      emoji: '../../../assets/images/videoplayer/react-like.svg'
    },
    {
      shortcut:2,
      id: '3',
      category: 'Dislike',
      emoji: '../../../assets/images/videoplayer/react-dislike.svg'
    },
    {
      shortcut:3,
      id: '4',
      category: 'Question',
      emoji: '../../../assets/images/videoplayer/react-question.svg'
    },
    {
      shortcut:4,
      id: '5',
      category: 'Amazed',
      emoji: '../../../assets/images/videoplayer/react-amazed.svg'
    },
    {
      shortcut:5,
      id: '6',
      category: 'Crying',
      emoji: '../../../assets/images/videoplayer/react-crying.svg'
    },
    {
      shortcut: 6,
      id: '7',
      category: 'Angry',
      emoji: '../../../assets/images/videoplayer/react-angry.svg'
    }
  ];

  constructor(
      public apiService: CrudService,
      public localStorageService: LocalStorageService,
      public router: Router
  ) {
  }

  ngOnInit() {
    // this.getActivity();
    this.spinner = true;
    this.limit = 10;
    this.page = 1;

    if (this.localStorageService.getItem('user') != null)
      this.getNotifications(true);
  }

  onScroll() {
    ++this.page;
    this.getNotifications();
  }

  // Get user activities
  // getActivity() {
  //   this.apiService.getUserActivity().subscribe(
  //     success => {
  //       this.activities = success.data;
  //     });
  // }

  getNotifications(viewed = false) {

    // let viewType: any = 'viewAll'
    this.apiService.getAllNotificationsV2(this.limit, this.page).subscribe(success => {

      // console.log("all notifications = ", success.data);

      if (Array.isArray(success.data)) {
        this.notifications.push(...success.data);
        this.spinner = false;
      } else {
        this.spinner = false;
      }
    });

    if (viewed) {
      const payload = {
        userId: this.localStorageService.getItem('user')
      };
      // set the flag to viewed = true for all notifications which have not been viewed
      this.apiService.allNotificationsSetToViewed(payload).subscribe(() => {
        this.apiService._notifications.next(true);
      });
    }
  }

  viewNotification(id: any) {
    for (const i of this.notifications) {
      if (id === i.notificationId) {
        if (i.notificationType === 'follow') {
          this.router.navigate(['/public-profile'], {queryParams: {user: i.userInfo._id}});
        } else if (i.notificationType === 'contributor') {
          this.router.navigate(['/create-interactive-video'], {queryParams: {video: i.videoInfo._id}});
        } else if (i.notificationType === 'publishVideo') {
          this.router.navigate(['/videos'], {queryParams: {video: i.videoInfo._id}});
        } else if (i.notificationType === 'Comment') {
          this.router.navigate(['/videos'], {queryParams: {video: i.videoInfo._id}});
        } else if (i.notificationType === 'Like') {
          this.router.navigate(['/videos'], {queryParams: {video: i.videoInfo._id}});
        } else if (i.notificationType === 'collab') {
          // console.log("routing to ", "/my-group", i.category);
          this.router.navigate(['/my-group', i.category.activityTypeID], {
            queryParams: {
              video: i.videoInfo?.childVideoId ? i.videoInfo?.childVideoId : i.videoInfo._id,
              comment: i.category.id,
              requester: i.category.userID
            }
          });
        } else if (i.notificationType === 'mainCommunityCollab') {
          const queryParams: any = {
            video: i.videoInfo?.childVideoId ? i.videoInfo?.childVideoId : i.videoInfo._id,
            comment: i.category.id,
            requester: i.category.userID
          }
          if (i.sellerInfo?.business?.communityUrl) {
            // this.router.navigate(['/main-community', i.sellerInfo?.business?.communityUrl], {queryParams});
            this.router.navigate(['/my-community', i.sellerInfo?.business?.communityUrl], {queryParams});
          } else {
            queryParams.user = i.sellerInfo._id;
            // this.router.navigate(['/main-community'], {queryParams});
            this.router.navigate(['/my-community'], {queryParams});
          }
        } else if (i.notificationType === 'videoPlayerCollab' || i.notificationType === 'videoPlayerCollabResponse') {
          //console.log('*** i.mainVideoInfo = ', i.mainVideoInfo);
          const queryParams: any = {
            video: i?.mainVideoInfo?._id,
            collabVideo: i.videoInfo?.childVideoId ? i.videoInfo?.childVideoId : i.videoInfo._id,
            comment: i.category.id,
            requester: i.category.userID
          }
          this.router.navigate(['/videos'], {queryParams});
        } else if (i.notificationType === 'mainCommunityShare') {
          if (i.sellerInfo?.business?.communityUrl) {
            this.router.navigate(['/my-community', i.sellerInfo?.business?.communityUrl]);
          } else {
            const queryParams = {user: i.sellerInfo._id};
            this.router.navigate(['/my-community'], {queryParams});
          }
        } else {
          this.router.navigate(['/videos'], {queryParams: {video: i.videoInfo._id}});
        }
      }
    }
  }

  showVideo(videoId: any) {
    this.router.navigate(['videos'], {queryParams: {video: videoId}});
  }

  userProfile(id: any) {
    this.router.navigate(['public-profile'], {queryParams: {user: id}});
  }

  getEmoji(cat: any) {
    cat = Number(cat);
    if (cat === 1)
      return this.likeCategories[0].emoji

    if (cat === 2)
      return this.likeCategories[1].emoji

    if (cat === 3)
      return this.likeCategories[2].emoji

    if (cat === 4)
      return this.likeCategories[3].emoji

    if (cat === 5)
      return this.likeCategories[4].emoji

    if (cat === 6)
      return this.likeCategories[5].emoji

    if (cat === 7)
      return this.likeCategories[6].emoji
  }

}
