import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'secondsToMinute'
})
export class SecondsToMinutePipe implements PipeTransform {

  transform(seconds: number): string {
    if (seconds === Infinity) {
      return '00:00';
    }
    if (!seconds) {
      return '00:00';
    }
    seconds = Number(seconds);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    if (hours > 0) {
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedMinutes}:${formattedSeconds}`;
    }
  }

}
