import {AfterViewInit, Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {ActivatedRoute, Event, NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
// import {Gtag} from 'angular-gtag';
import {DashboardService} from './services/dashboard.service';
import {BehaviorSubject} from 'rxjs';
import {isPlatformBrowser} from '@angular/common';
import {LocalStorageService} from './services/localStorage.service';

declare var $;
declare let ga: any;
declare let pendo: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  // animations: [slideInAnimation]
})
export class AppComponent implements OnInit, AfterViewInit {
  url: any
  subscription;

  constructor(
      private router: Router,
      private titleService: Title,
      private activatedRoute: ActivatedRoute,
      private dashboardService: DashboardService,
      private localStorageService: LocalStorageService
      // googleTag: Gtag
  ) {
    // ga('create', environment.GA_TRACKING_ID, 'auto');
    // this.router.events.pipe(filter((event) =>
    //     event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
    //   googleTag.event('page_view', {
    //     page_path: event.urlAfterRedirects
    //   });
    // })
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     ga('set', 'page', event.urlAfterRedirects);
    //     ga('send', 'pageview');
    //   }
    // });

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.localStorageService.getItem('user')) {
          pendo.initialize({
            visitor: {
              id: this.localStorageService.getItem('user'),   // Required if user is logged in
              email: this.localStorageService.getItem('email'),     // Recommended if using Pendo Feedback, or NPS Email
              full_name: this.localStorageService.getItem('userName')   // Recommended if using Pendo Feedback
              // role:         // Optional

              // You can add any additional visitor level key-values here,
              // as long as it's not one of the above reserved names.
            },

            // account: {
            //   id: 'ACCOUNT-UNIQUE-ID' // Required if using Pendo Feedback
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
            // }
          });
        }
      }
    });

    // this is for change icon color according to routing
    this.subscription = router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        const rt = this.getChild(this.activatedRoute)
        rt.data.subscribe(data => {
          this.titleService.setTitle(data.title)
        })
        const str = event.url;
        if (str.includes('my-group')) {
          this.localStorageService.setItem('title', 'exist')
        }
        $('.tooltip.fade.show').remove();
        // console.log('First url',event.url);
      }
    });


    router.events.subscribe(val => {
      $('.modal').modal('hide');
      window.scrollTo(0, 0);
      this.dashboardService.onClickMobileMenu(false);
    });

    if (this.localStorageService.getItem('token')) {
      this.dashboardService.onGetUserData();
    } else if (!this.localStorageService.getItem('anonymousToken')) {
      this.dashboardService.getAnonymousUser();
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      $('[data-toggle="tooltip"]').tooltip();
    }, 1000);
  }

  ngOnInit(): void {
  }

  private getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }

  }

}
