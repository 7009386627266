// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-promo-btn {
    border: none;
    outline: none;
    background: #6c2fbb;
    color: white;
    padding: 4px 25px;
    font-size: 15px;
    border-radius: 4px;
    float: right;
}

.privacy-text {
    position: absolute;
    left: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/afterLogin/coupon/create-promo-code/create-promo-code.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,OAAO;AACX","sourcesContent":[".create-promo-btn {\n    border: none;\n    outline: none;\n    background: #6c2fbb;\n    color: white;\n    padding: 4px 25px;\n    font-size: 15px;\n    border-radius: 4px;\n    float: right;\n}\n\n.privacy-text {\n    position: absolute;\n    left: 0;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
