// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-text {
    margin-bottom: 22px;
    color: black;
}

.custom-button {
    margin-bottom: 22px;
}

h5 {
    font-size: 19px;
    margin-top: 10px;
    /* color: #48C9B0;     */
    color: #2ECC71;
}

.tick {
    width: 50px;
    height: 50px;
    margin: auto;
}

.tick svg {
    width: inherit;
    height: inherit;
    fill: #2ECC71
}`, "",{"version":3,"sources":["webpack://./src/app/afterLogin/thatsall/thatsall.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,wBAAwB;IACxB,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,eAAe;IACf;AACJ","sourcesContent":[".p-text {\r\n    margin-bottom: 22px;\r\n    color: black;\r\n}\r\n\r\n.custom-button {\r\n    margin-bottom: 22px;\r\n}\r\n\r\nh5 {\r\n    font-size: 19px;\r\n    margin-top: 10px;\r\n    /* color: #48C9B0;     */\r\n    color: #2ECC71;\r\n}\r\n\r\n.tick {\r\n    width: 50px;\r\n    height: 50px;\r\n    margin: auto;\r\n}\r\n\r\n.tick svg {\r\n    width: inherit;\r\n    height: inherit;\r\n    fill: #2ECC71\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
