export const createVideoMessage = {
    rename: 'Rename Pathway',
    restart: 'Update Starting and Ending Nodes',
    reChooseEndNode: 'Update Ending Node',
    save: 'Save',
    addFirstNode: 'Add the first node',
    ok: 'OK',
    cancel: 'Cancel',
    yes: 'Yes',
    no: 'No',
    exit: 'Exit',
    tutorial: 'I\'m still unsure, watch tutorial',
    uploadVideo: 'Upload Video',
    chooseVideo: 'Choose a video',
    createPathways: 'Create Pathways',
    addMoreChoices: 'Add more choices',
    readyToCreate: 'I\'m Ready To Create My Own Video Tree',
    publish: 'Publish the Video',
    finish: 'I’m good for now',
    saveTheStoryBoard: 'Save the storyboard',
    uploadVideosForStoryBoard: 'Upload Videos',
    contentTreeBasedOnTheScenarios: 'Create a content tree based on the scenarios',
    createAnotherScenarioWithTheSameTopic: 'Create another scenario with the same topic',
    updateTheScenario: 'Update the scenario',
    startOver: 'Start Over',
    getTheVideoForAllNodes: 'Get the videos for all nodes',
    updateVideoOnNode: 'Update the video on a node',
    generateTextToSpeech: 'Generate Text To Speech',
    generateAAudio: 'Generate a audio',
    goodWithVoice: 'I’m good with this voice',
    likeToHearVoice: 'I\'d like to hear the voice narrating the video script',
    saveTheVideo: 'Generate the video',
    iAmGoodSaveTheTree: 'I\'m good, go ahead generate the video',
    playAnotherScript: 'Play Another Script',
    generateTheVideo: 'Generate The Video'
}

export const createVideoStep = {
    welcome: 0,
    noVideo: 1,
    noVideoNo: 2,
    firstNode: 3,
    firstNodeComplete: 4,
    firstNodeRename: 5,
    firstNodeRenameComplete: 6,
    choicesSelected: 7,
    choicesSelectedCompleted: 8,
    choicesTimeAdded: 9,
    complete: 10,
    addMoreChoices: 111,
    moreNodeSelected: 112,
    moreAddChoices: 113,
    moreChoicesSelected: 114,
    moreChoicesSelectedCompleted: 115,
    moreChoicesTimeAdded: 116,
    uploadStoryBoardVideo: 117,
    seletedVideoForNode: 118,
    saveTheStoryBoard: 120,
    saveStoryBoardName: 121,
    setAITopic: 131,
    aiTopicCreated: 132,
    contentTreeCreated: 133,
    scriptBeingCreated: 134,
    scriptAttached: 135,
    attachVideosToNodes: 136,
    contentTreeNameCreator: 137,
    showVoiceModels: 138,
    nodeSelectedForVoice: 139,
    audioGenerated: 140,
    voiceSettled: 141,
    saveTheVideo: 142,
    videoSelectedForCombine: 143,
    playAnotherScript: 144,
    scriptNotGeneratedProperly: 145,
    videoNotGeneratedProperly: 146,
    updateTheScenario: 147,
    cancelNodeUpdate: 148,
}

export const createVideoEvent = {
    init: 'init',
    mainVideo: 'mainVideo',
    nodeSelected: 'nodeSelected',
    addFirstNode: 'addFirstNode',
    editFirstNodeName: 'editFirstNodeName',
    addChildNode: 'addChildNode',
    addMoreChoices: 'addMoreChoices',
    createPathways: 'createPathways',
    publish: 'publish',
    addTime: 'addTime',
    close: 'close',
    addVideoForEmptyNode: 'addVideoForEmptyNode',
    saveStoryBoardName: 'saveStoryBoardName',
    createContentTreeBasedOnScenario: 'createContentTreeBasedOnScenario',
    contentTreeCreated: 'contentTreeCreated',
    scriptBeingCreated: 'scriptBeingCreated',
    updateTheScenario: 'updateTheScenario',
    getTheVideoForAllNodes: 'getTheVideoForAllNodes',
    videoAttachedToAllChoice: 'videoAttachedToAllChoice',
    updateVideoOnNode: 'updateVideoOnNode',
    setVoiceModelToNode: 'setVoiceModelToNode',
    flowNotCompleted: 'flowNotCompleted',
    iAmGoodSaveTheTree: 'iAmGoodSaveTheTree',
    videoSelectedForCombine: 'videoSelectedForCombine',
    mergingVideoAndAudio: 'mergingVideoAndAudio'
}

export const HoursCount = [
    '00', '01', '02', '03', '04', '05', '06', '07','08', '09', '10','11','12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
]

export const MinutesSecondsCount = [
    '00', '01', '02', '03', '04', '05', '06', '07','08', '09', '10','11','12', '13', '14', '15', '16', '17', '18', '19', '20', 
    '21', '22', '23', '24', '25', '26', '27','28', '29', '30', '31', '32', '33','34', '35', '36', '37', '38', '39','40', '41', 
    '42', '43', '44', '45', '46', '47', '48', '49', '50', '51','52', '53', '54','55', '56', '57', '58', '59'
]

export default {createVideoMessage, createVideoStep, createVideoEvent};
