import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {CrudService} from './services/crud.service';
import {LocalStorageService} from './services/localStorage.service';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  anonymousToken: string;

  constructor(
      public router: Router,
      public toastr: ToastrService,
      private readonly crudService: CrudService,
      private localStorageService: LocalStorageService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token: string;
    let anonymousToken: string;
    try {
      token = this.localStorageService.getItem('token');
      anonymousToken = this.localStorageService.getItem('anonymousToken');
    } catch (error) {
      if (!this.anonymousToken) {
        this.anonymousToken = this.crudService.anonymousToken;
        anonymousToken = this.anonymousToken;
      } else {
        anonymousToken = this.anonymousToken;
      }
    }
    if (request.url.indexOf('s3-accelerate.amazonaws.com') !== -1) {

    }
    else {
      if (token) {
        const reqUrl = request.url
        if (reqUrl.includes('http://3.234.229.110:5000')) {
          reqUrl.replace('https://', 'http://');
          // request = request.clone({ headers: request.headers.set('Authorization', 'Basic Y2xvdWQtYWRtaW46SlUxNzBTNjd0Tw==') });
        } else {
          request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + token) });
          request = request.clone({ headers: request.headers.set('Device-Type', 'Web') });
        }
      } else {
        if (anonymousToken) {
          if (request.url.indexOf('/auth/local') !== -1) {
            // this.localStorageService.clear();
          } else {
            request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + anonymousToken) });
            request = request.clone({ headers: request.headers.set('Device-Type', 'Web') });

          }
        }
      }

    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
          reason: error && error.error?.reason ? error.error?.reason : '',
          status: error.status
        };
        const errorMessage = error?.error?.message;


        if (error.status === 401) {
          // WC 04062022 - To avoid showing Unauthorized error when the user logs out already.
          // For ex: user logs out in the middle of dashboard page being built, we don't want to show Unauthorized error

          // console.log('interceptor.ts - this.localStorageService.getItem(\'logout\') = ', this.localStorageService.getItem('logout'));

          // if the user doesn't log out and gets unauthorized error then show the error message and redirect to Corp landing page
          if (request.url.includes('auth/local')) {
            this.toasterError(errorMessage);
          } else  {

            this.localStorageService.clear();

            // this.toastr.error('Unauthorized!');

            this.router.navigate(['login']);

          }
        } else {

          const excludedUrls = ['users/setcontributor', 'users/getCommonItem', 'users/setPathway'];

          if (!!this.localStorageService.getItem('tempToken')) {
            this.localStorageService.removeItem('tempToken');
          } else {
            if (!excludedUrls.some(url => request.url.includes(url))) {
              this.toasterError(errorMessage);
            }
          }
        }

        return throwError(error);

      })
    )
  }

  toasterError(message?) {
    if (message) {
      if (this.searchError(message)) {
        this.toastr.error('Sorry, we experience a difficulty, please try again');
      } else {
        this.toastr.error(message);
      }
    }
  }

  searchError(errorString) {
    const substrings = ['cast to object'];
    return substrings.some(substring => errorString.toLowerCase().includes(substring));
  }
}
