import {Component, OnInit, ViewChild} from '@angular/core';
import {CrudService} from '../../../services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {DeleteConfirmationComponent} from '../../../layout/modals/delete-confirmation/delete-confirmation.component';
import {MatDialog} from '@angular/material/dialog';
import {CreateGroupComponent} from '../create-group/create-group.component';
import {LocalStorageService} from '../../../services/localStorage.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-my-group-list',
    templateUrl: './my-group-list.component.html',
    styleUrls: ['./my-group-list.component.css']
})
export class MyGroupListComponent implements OnInit {

    @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;
    @ViewChild('cloneDialog') cloneDialog: any;

    spinner: any = false
    allGroups: any = [];
    subscriptionGroups = [];
    nonSubscriptionGroups = [];
    totalGroup = 7;
    currentTab = 'subscription';
    currentUserId: any;
    isSeller = false;
    isShowStep = false;
    isEmbed: any;
    copyGroupTitle: any;
    paramsData:any;
    searchText: any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    constructor(
        public crudService: CrudService,
        public toaster: ToastrService,
        public router: Router,
        public dialog: MatDialog,
        public activatedRoute: ActivatedRoute,
        public dashboardService: DashboardService,
        public localStorageService: LocalStorageService,
    ) {
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.embed && params.embed === 'true') {
                this.isEmbed = params?.embed;
            }
            if (params.type === 'subscription') {
                this.currentTab = 'subscription';
                this.paramsData = params.type
            }
            if (params.step === 'true') {
                this.isShowStep = true;
            }
        });
        this.getAllGroups();


        this.dashboardService.getSearchTerm
            .pipe(takeUntil(this._unsubscribeAll), debounceTime(1000))
            .subscribe((res) => {
                this.searchText = res;
                this.getAllGroups();
            });
        
        this.currentUserId = this.localStorageService.getItem('user');
        this.isSeller = this.localStorageService.getItem('isSeller') === 'true';
    }

    fliterGroupNameWithSpecialChar(groupName:any){
        return groupName.replace(/&amp;/g, '&');
    }

    getAllGroups() {
        this.spinner = true;
        let obj: any = {}
        
        if (this.searchText) {
            obj.searchText = this.searchText;
        }
        this.crudService.getAllGroups(obj).subscribe(res => {
            this.allGroups = res.data;

            this.viewAllGroup();

            // console.log("this.allGroups = ", this.allGroups);

            this.subscriptionGroups = this.allGroups.filter(x => x?.isSubscriptionGroup);
            this.nonSubscriptionGroups = this.allGroups.filter(x => !x?.isSubscriptionGroup);

            if (this.subscriptionGroups.length == 0 && !this.isShowStep)
                this.currentTab = 'non-subscription';
            
            this.tabChange(this.currentTab);
            if (this.totalGroup >= this.allGroups.length) {
                this.totalGroup = this.allGroups.length;
            }

        }).add(() => this.spinner = false);
    }

    confirmDelete(item: any) {
        if ('ADMIN' === item.accessType) {
            this.deleteDialogRef.open({
                header: 'Delete Group',
                title: 'Are you sure you want to delete this group?',
            }, (e) => {
                if (e) {
                    this.crudService.deleteGroup(item._id).subscribe(res => {
                        // console.log(res);
                        this.toaster.success('Deleted successfully');
                        this.getAllGroups();
                    })
                }
            });
        } else if ('MEMBER' === item.accessType) {
            this.deleteDialogRef.open({
                header: 'Leave Group',
                title: 'Are you sure you want to leave this group?',
                trueButton: 'Leave'
            }, (e) => {
                if (e) {
                    this.crudService.leaveGroup(item._id).subscribe(res => {
                        // console.log(res);
                        this.toaster.success('Leaved successfully');
                        this.getAllGroups();
                    })
                }
            });
        } else {
            this.deleteDialogRef.open({
                header: 'Leave Group',
                title: 'Are you sure you want to cancel subscription and leave this group?',
                trueButton: 'Yes'
            }, (e) => {
                if (e) {

                    this.router.navigate(['/settings'], {queryParams: {action: "cancelSubscription" + item._id}});
                }
            });
        }
    }

    editGroup(item) {
        const dialogRef = this.dialog.open(CreateGroupComponent, {
            width: '100%',
            maxWidth: '600px',
            minHeight: '400px',
            panelClass: 'my-dialog',
            height: 'auto',
            data: {
                type: this.currentTab,
                group: item
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // console.log(result)
                this.getAllGroups();
            }
        });
    }

    cloneGroup(item) {

        this.copyGroupTitle = item.groupName + '.copy';

        const dialogRef = this.dialog.open(this.cloneDialog, {
        width: '100%',
        maxWidth: '500px',
        panelClass: 'my-dialog',
        height: 'auto',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {

                const obj = {
                    groupId: item._id,
                    groupName: this.copyGroupTitle
                };

                this.crudService.cloneAGroup(obj).subscribe((success) => {
                    if (success) {
                        // console.log("cloneGroup - success = ", success)
                        this.getAllGroups();
                    };
                })
            };
        });
    }

    tabChange(tab) {
        this.currentTab = tab;
        // this.totalGroup = 7;
        if (this.currentTab === 'subscription') {
            this.allGroups = this.subscriptionGroups;
        } else {
            this.allGroups = this.nonSubscriptionGroups;
        }
        this.viewAllGroup();
    }

    viewAllGroup() {
        this.totalGroup = this.allGroups.length;
    }

    onMyGroup(item: any) {
        //WC 06112024
        if (item.accessType == 'SUBSCRIBER') {
            this.router.navigate(['/community/' + item._id])        //to avoid being asked to subscribe when the logged in user is already a subscriber
        } else {
            this.router.navigate(['/my-group/' + item._id])
        }
    }

    createGroup() {
        const dialogRef = this.dialog.open(CreateGroupComponent, {
            width: '100%',
            maxWidth: '600px',
            minHeight: '400px',
            panelClass: 'my-dialog',
            height: 'auto',
            data: {
                type: this.currentTab
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // console.log(result)
                this.getAllGroups();
            }
        });
    }

}
