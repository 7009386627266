import {Directive, ElementRef, Renderer2} from '@angular/core';

declare var $;


@Directive({
    selector: '[thumnailsDirective]'
})
export class thumnailsDirective {


    constructor(el: ElementRef, renderer: Renderer2) {

        setTimeout(() => {
            const imgsrc = el.nativeElement.getAttribute('src');
            // if (!imgsrc.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
            // console.log(imgsrc)
            if ((imgsrc.indexOf('jpg') == -1)
                && (imgsrc.indexOf('jpeg') == -1)
                && (imgsrc.indexOf('png') == -1)
                && (imgsrc.indexOf('gif') == -1)
                && (imgsrc.indexOf('webp') == -1)
                && (imgsrc.indexOf('svg') == -1)
                && (imgsrc.indexOf('ico') == -1)) {
                el.nativeElement.setAttribute('src', 'assets/images/noimage.jpg');
            }
        })

    }
}
