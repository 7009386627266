import {Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild,} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';
import {GroupMember} from 'src/app/interfaces/group.interface';
import {UploadedFileResponse} from 'src/app/interfaces/upload-video.interface';
import {CrudService} from 'src/app/services/crud.service';
import {
  BookmarkVideo,
  BookmarkVideoResponse,
  ChildrenVideo,
  ChildVideo,
  ParentVideo,
} from '../../../interfaces/interactive-video.interface';
import {CollabActions} from '../../collab-single-video/collab-single-video.component';
import {CreateVideoComponent} from '../create-video/create-video.component';
import {ModalVideoPlayerComponent} from '../modal-video-player/modal-video-player.component';
import {DashboardService} from '../../../services/dashboard.service';
import {LocalStorageService} from '../../../services/localStorage.service';

interface ModalData {
  create: boolean,
  comment: any;
  currentUserInformation: any;
  video: BookmarkVideoResponse;
  videoId: string;
  groupMembers?: GroupMember[];
  commentId: string;
  groupId?: string;
  sellerUserId?: string;
  mainVideoId?: any;
  childVideoId?: any;
  requestVideoId: string;
  requester: string;
  readonly: boolean;
  topic: string;
}

const requestVideo = [
  {
    commentCount: 0,
    emphasize: { status: false },
    isRestricted: false,
    isVideoAdmin: false,
    level: 1,
    parentId: '1',
    question: { status: false },
    subtitleTranslations: [],
    thumbnail: [],
    viewCount: 0,
    _id: '1',
  },
];

interface CollabRequestUser {
  firstName: string;
  _id: string;
  lastName: string;
  username: string;
  profilePic?: string;
  checked?: boolean;
}


@Component({
  selector: 'app-open-collab',
  templateUrl: './open-collab.component.html',
  styleUrls: ['./open-collab.component.css'],
})
export class OpenCollabComponent implements OnInit, OnDestroy {
  @ViewChild('callRequestDialog') callRequestDialog: TemplateRef<any>;
  @ViewChild('uploadOptionsDialog') uploadOptionsDialog: TemplateRef<any>;
  @ViewChild('noFollowerNoMemberDialog') noFollowerNoMemberDialog: TemplateRef<any>;

  spinner = false;
  currentParent: ParentVideo = {
    Categories: null,
    Description: null,
    Likes: null,
    Title: null,
    URL: null,
    children: [],
    childs: null,
    commentCount: null,
    comments: null,
    createdAt: null,
    duration: null,
    emphasize: null,
    isPublished: null,
    isRestricted: null,
    isVideoAdmin: null,
    level: null,
    name: null,
    parentId: null,
    poster: null,
    publishTime: null,
    question: null,
    shareTo: null,
    shares: null,
    subtitleTranslations: null,
    subtitleUrl: null,
    thumbnail: null,
    time: null,
    totalchild: null,
    totallevel: null,
    type: null,
    updatedAt: null,
    user: null,
    userInfo: null,
    videoID: null,
    viewCount: null,
    _id: null,
  };

  findorselect: string;

  showTree = false;
  expandTree = false;
  rangeValue = 0.8;
  currentVideo: ParentVideo | ChildrenVideo | ChildVideo;
  collabMembers: CollabRequestUser[] = [];
  collabFollowers: CollabRequestUser[] = [];
  currentTab = 'member';
  followerSelectedCount = 0;
  memberSelectedCount = 0;
  comment = '';
  commentLimit = 269;
  isSeeMoreComment = true;
  blankVideo: ParentVideo | ChildrenVideo | ChildVideo;
  private commentSource: 'GROUP' | 'COMMUNITY' | 'MAIN_VIDEO' = 'GROUP';
  private currentVideoId: string;
  private commenterId: string;
  private changedAnyThing = false;
  private prevComment = '';
  private followersLimit = 10;
  private followersPage = 1;
  private subscription: Subscription;

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: ModalData,
      private readonly urlService: CrudService,
      private readonly dashboardService: DashboardService,
      private dialog: MatDialog,
      private readonly toaster: ToastrService,
      private readonly localStorageService: LocalStorageService,
      public readonly matDialogRef: MatDialogRef<OpenCollabComponent>,
  ) {    
    this.blankVideo = JSON.parse(JSON.stringify(this.currentParent));
    this.blankVideo.user = this.data.currentUserInformation?._id;
    this.blankVideo.userInfo = this.data.currentUserInformation;
    // this.matDialogRef.addPanelClass('fullscreen-dialog')
  }

  ngOnInit(): void {
    // console.log(this.data, 'this.data')
    if (this.data.sellerUserId) {
      this.commentSource = 'COMMUNITY';
    }
    if (this.data.mainVideoId) {
      this.commentSource = 'MAIN_VIDEO';
    }
    if (this.data.create) {
      this.comment = this.data.comment?.comment || 'Video Collab';
      this.prevComment = this.data.comment?.comment || 'Video Collab';
      this.currentParent.user = this.data.currentUserInformation?._id;
      this.currentParent.userInfo = this.data.currentUserInformation;
      if (this.commentSource === 'GROUP') {
        this.initMembers();
      } else {
        this.currentTab = 'followers';
        this.showTree = true;
      }
    } else {
      this.comment = this.data.comment?.comment || '';
      this.prevComment = this.data.comment?.comment || '';
      this.getTreeData(this.data.videoId);
    }
    if (this.commentSource === 'MAIN_VIDEO') {
      //console.log("$$$ MAIN_VIDEO - this.data.comment = ", this.data.comment);
      this.commenterId = this.data.comment?.userID || '';
    } else {
      this.commenterId = this.data.comment?.users?._id || '';
    }
    this.getAllFollowers(true);
    this.matDialogRef.backdropClick().subscribe(result => {
      this.matDialogRef.close(this.changedAnyThing);
    });
  }

  get allowEditing() {
    return this.data.create || this.data?.currentUserInformation?._id === this.commenterId;
  }

  getAllFollowers(reset = false) {

    //console.log("$$$ getAllFollowers");

    if (reset) {
      this.followersPage = 1;
      this.collabFollowers = [];
    }
    this.subscription = this.urlService
        .getFollowers(this.followersLimit, this.followersPage)
        .subscribe((res) => {
          if (res.data.followers && res.data.followers.length > 0) {
            for (const follower of res.data.followers) {
              const collabUser: CollabRequestUser = {
                _id: follower.followedBy,
                firstName: follower.firstname,
                lastName: follower.lastname,
                username: follower.username,
                profilePic: follower.profilePic,
                checked: false,
              };
              this.collabFollowers.push(collabUser);
            }
            // this.getAllFollowers(limit, page + 1);
          }
        });
  }

  onScrollFollowers() {
    ++this.followersPage;
    this.getAllFollowers();
  }

  getTreeData(mainId: string) {
    this.subscription = this.urlService.getTree(mainId).subscribe((res) => {
      this.showTree = true;
      this.currentParent = res.data;
      if (!this.currentParent.children) {
        this.currentParent.children = [];
      }
      // console.log(this.currentParent)
      if (this.commentSource === 'GROUP') {
        this.initMembers();
      } else {
        this.currentTab = 'followers';
      }
    }, error => {
      this.matDialogRef.close();
    });
  }

  initMembers() {
    this.showTree = true;
    this.collabMembers = [];
    // init collab members
    for (const member of this.data.groupMembers) {
      if (member.memberId !== this.data.currentUserInformation?._id) {
        const collabUser: CollabRequestUser = {
          _id: member.memberId,
          firstName: member.firstname,
          lastName: member.lastname,
          username: member.userName,
          profilePic: member.profilePic,
          checked: false,
        };
        this.collabMembers.push(collabUser);
      }
    }
    if (!this.collabMembers.length) {
      this.currentTab = 'followers';
    }
  }

  // addDataToId = (arr, id, children) => {
  //   if (arr.length > 0) {
  //     arr.forEach((i) => {
  //       if (i._id === id) {
  //         if (!i.childs || i.childs < 4) {
  //           i.children = [...(i.children || []), ...children];
  //         } else {
  //           this.toaster.info('All Four Child are Filled!');
  //         }
  //       } else {
  //         if (i.children) {
  //           this.addDataToId(i.children || [], id, children);
  //         }
  //       }
  //     });
  //   }
  // };

  onGetCollabActions(actions: CollabActions) {
    if (actions.type === 'PLAY') {
      this.openVideoPlayerModel(actions.video);
    } else {
        this.currentVideoId = actions.video._id;
        this.currentVideo = actions.video;
        if (actions.type === 'UPLOAD' || actions.type === 'ADD') {
          this.openVideoUploadModal(actions);
        } else if (actions.type === 'REQUEST') {
          this.onClickRequestCollab();
        }
      }
  }

  // openVideoUploadModal(actions: CollabActions) {
  //   const dialogRef = this.dialog.open(RecordVideoComponent, {
  //     data: {
  //       allVideos: null,
  //     },
  //     minWidth: '500px',
  //   });
  //   dialogRef.afterClosed().subscribe((result: UploadedFileResponse | undefined) => {
  //     console.log(result)
  //   });
  // }

  openVideoUploadModal(actions: CollabActions) {
    // console.log({actions})
    const dialogRef = this.dialog.open(CreateVideoComponent, {
      data: {
        allVideos: null,
        skipTranscribing: true,
        origin: "videoCollab"
      },
      minWidth: '50vw',
    });
    dialogRef
      .afterClosed()
      .subscribe((result: UploadedFileResponse | undefined) => {
        if (result) {
          this.changedAnyThing = true;
          // console.log({result})
          // return;
          if (actions.type === 'UPLOAD') {
            if (!this.data.videoId) {
              // create new video
              this.createInteractiveVideo(result);
            }
            // else {
            //   alert('dead now triggered');
            //   const updatedVideo: UpdateInteractiveVideo = {
            //     Categories: [],
            //     URL: result.URL,
            //     id: actions.video._id,
            //     name: result.name,
            //     parentId:
            //         actions.video.type !== 'main' ? actions.video.parentId : '0',
            //     poster: result.poster ? result.poster : result.thumbnail,
            //     subtitleUrl: result.subtitleUrl,
            //     type: actions.video.type ? actions.video.type : 'child',
            //     videoID: actions.video._id,
            //   };
            //   this.subscription = this.urlService
            //       .updateInteractiveVideo(updatedVideo)
            //       .subscribe(() => {
            //         this.showTree = false;
            //         this.getTreeData(this.data.videoId);
            //       });
            // }
          } else if (actions.type === 'ADD') {
            this.onClickAddVideo(result);
          }
        }
      });
  }

  createInteractiveVideo(result) {
    this.showTree = false;
    const obj: any = {
      type: 'main',
      Title: '',
      Description: '',
      Tag: 'OPEN-COLLAB',
      Categories: [],
      time: 0,
      parentId: null,
      URL: result.URL,
      name: result.name,
      poster: result.poster ? result.poster : result.thumbnail,
      subtitleUrl: result.subtitleUrl,
      videoID: result._id,
    };
    this.urlService.createInteractiveVideo(obj).subscribe((success) => {
      // this.data.create = false;
      this.data.videoId = success.data?.data?._id;
      this.data.video = success.data?.data;
      this.updateComment();
    }, (error) => {
      this.showTree = true;
    });
  }

  onClickAddVideo(video: UploadedFileResponse) {
    if (this.currentVideo.childs >= 4) {
      this.toaster.info('Total 4 Child Videos are allowed!' );
      return;
    }
    this.showTree = false;
    const childVideo: BookmarkVideo = {
      type: 'child',
      name: video.name,
      parentId: this.currentVideo._id,
      URL: video.URL,
      time: 0,
      poster: video.poster ? video.poster : video.thumbnail,
      videoID: video._id,
      subtitleUrl: video.subtitleUrl,
    };
    if (childVideo.parentId === '1') {
      childVideo.parentId = this.data.requestVideoId;
    }
    this.urlService.createInteractiveVideo(childVideo).subscribe(
        () => {
          if (this.data.requestVideoId) {
            this.data.requestVideoId = undefined;
          }
          this.getTreeData(this.data.videoId);
          this.updateComment();
          this.currentVideo = null;
        }, (error) => {
          this.showTree = true;
          // console.log('Error', error);
        });
  }

  // Video Player Dialog
  openVideoPlayerModel(video: ParentVideo | ChildrenVideo | ChildVideo) {
    const options = {
      autoplay: true,
      poster: video.poster,
      controls: true,
      sources: [{ src: video.URL, type: 'application/x-mpegURL' }],
    };
    const dialogRef = this.dialog.open(ModalVideoPlayerComponent, {
      width: '100%',
      minHeight: 'calc(64vh - 160px)',
      maxWidth: '40vw',
      panelClass: 'video-tree-dialog',
      height: '48vh',
      data: { options, video },
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  // Request Dialog
  onClickRequestCollab() {

    if (this.collabMembers.length || this.collabFollowers.length) {
      const dialogRef = this.dialog.open(this.callRequestDialog, {
        minWidth: '32%',
        panelClass: 'open-collab-dialog',
        id: 'open-collab-dialog',
        minHeight: 'calc(64vh - 160px)',
        // height: '48vh',
      });

      dialogRef.afterClosed().subscribe(() => {
        this.followersPage = 1;
        this.collabFollowers = [];
      });
    } else {
      const dialogRef = this.dialog.open(this.noFollowerNoMemberDialog, {
        id: 'noFollowerNoMemberDialog',
      });
    }
  }

  onClickCheck(checked: boolean, type: string) {
    if (type === 'follower') {
      if (checked) {
        this.followerSelectedCount++;
      } else {
        this.followerSelectedCount--;
      }
    } else if (type === 'member') {
      if (checked) {
        this.memberSelectedCount++;
      } else {
        this.memberSelectedCount--;
      }
    }
  }

  closeDialog(id: string) {
    const dialogRef = this.dialog.openDialogs.find(
        (i) => i.id === id
    );
    dialogRef.close();
  }
  sendRequest() {
    const requestedUsers: CollabRequestUser[] = [];
    for (const user of this.collabFollowers) {
      if (user.checked) {
        requestedUsers.push(user);
      }
    }
    for (const user of this.collabMembers) {
      if (user.checked) {
        requestedUsers.push(user);
      }
    }
    const payload: any = {
      commentId: this.data.commentId,
      currentVideo: this.currentVideoId,
      requestedUsers,
    };
    if (this.commentSource === 'MAIN_VIDEO') {
      if (this.data.childVideoId) {
        payload.childVideoId = this.data.childVideoId;
      } else {
        payload.mainVideoId = this.data.mainVideoId;
      }
    } else if (this.commentSource === 'GROUP') {
      payload.groupId = this.data.groupId;
      if (this.data.childVideoId) {
        payload.childVideoId = this.data.childVideoId;
      } else {
        payload.mainVideoId = this.data.mainVideoId;
      }
    } else {
      payload.sellerId = this.data.sellerUserId;
    }
    this.spinner = true;
    this.urlService.sendCollabRequest(payload).subscribe((result) => {
      this.toaster.success(result.data.message);
    }).add(() => this.spinner = false);
  }

  updateCommentText() {
    if (this.comment !== this.prevComment) {
      this.updateComment();
    }
  }

  updateComment() {
    const updateCallback = (res) => {
      this.data.commentId = res.data._id;
      this.data.comment = res.data;
      if (this.data.create) {
        this.commenterId = this.localStorageService.getItem('user');
      }
      this.prevComment = this.comment;
      // console.log(res, 'comment updated');
      if (!this.showTree) {
        this.getTreeData(this.data.videoId);
      }
      this.changedAnyThing = true;
      this.toaster.success('Comment Updated');
    }
    if (this.comment && this.data.videoId) {
      const payload: any = {
        videoId: this.data.videoId,
        commentId: this.data.commentId,
        comment: this.comment,
        scenerio: 'comment',
        type: 'OPEN_COLLAB'
      }
      this.spinner = true;
      if (this.commentSource === 'MAIN_VIDEO') {
        payload.commentID = payload.commentId;
        payload.videoID = this.data.childVideoId ? this.data.childVideoId : this.data.mainVideoId;
        payload.videoCommentId = this.data.videoId;

        //WC 08082023 --- To set the scenerio to 'reply' if the videoId is a child Video
        if (this.data.childVideoId)
          payload.scenerio = 'reply'
        //WC 08082023

        delete payload.videoId;
        delete payload.commentId;

        this.urlService.commentAndReply(payload).subscribe(res => {
          updateCallback(res.data);
        }).add(() => this.spinner = false);
      } else if (this.commentSource === 'GROUP') {
        payload.groupId = this.data.groupId;
        payload.videoCommentId = this.data.videoId;
        if (this.data.childVideoId) {
          payload.scenerio = 'reply'
        }
        
        if (this.data?.topic) {
          payload.topic = this.data?.topic;
        }

        this.urlService.addComment(payload).subscribe(res => {
          updateCallback(res);
        }).add(() => this.spinner = false);
      } else {
        payload.sellerUserId = this.data.sellerUserId;
        this.urlService.addCommunityComment(payload).subscribe(res => {
          updateCallback(res);
        }).add(() => this.spinner = false);
      }
    }
  }

  viewFullReply(item: string) {
    this.commentLimit = item.length;
    this.isSeeMoreComment = false;
  }

  viewLessReply(item: number) {
    this.commentLimit = item;
    this.isSeeMoreComment = true;
  }

  increaseRange() {
    const val = this.rangeValue + 0.1
    if (val <= 1) {
      this.rangeValue = val;
    }
  }

  decreaseRange() {
    const val = this.rangeValue - 0.1
    if (val >= 0.6) {
      this.rangeValue = val;
    }
  }

  resetRange() {
    this.rangeValue = 0.8;
  }

  toggleSize() {
    this.expandTree = !this.expandTree;
    if (this.expandTree) {
      this.matDialogRef.addPanelClass('fullscreen-dialog')
    } else {
      this.matDialogRef.removePanelClass('fullscreen-dialog')
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
