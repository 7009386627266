import {Injectable} from '@angular/core';
import { Router } from '@angular/router';
import {LocalStorageService} from '../services/localStorage.service';

@Injectable({
    providedIn: 'root'
})
export class NoAuthGuard  {
    constructor(public router: Router,private localStorageService:LocalStorageService) {
    }

    canActivate() {
        if (this.localStorageService.getItem('token')) {
            this.router.navigate(['profile']);
            return false;
        } else {
            return true;
        }
    }
}
