import { ChangeDetectorRef, Component, ElementRef, HostListener, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, tap } from 'rxjs';
import { EventType, MessageType } from '../createInteractiveVideo.interface';
import { createVideoEvent, createVideoStep } from '../createInteractiveVideo.data';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CrudService } from 'src/app/services/crud.service';
import { ToastrService } from 'ngx-toastr';
import languages from 'src/app/services/languages';
import { UtilityService } from 'src/app/services/utility.service';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { CreateVideoComponent } from 'src/app/layout/modals/create-video/create-video.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { EditVideoComponent } from '../edit-video/edit-video.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CreateQuestionnaireComponent } from 'src/app/layout/modals/create-questionnaire/create-questionnaire.component';
import { DeleteConfirmationComponent } from 'src/app/layout/modals/delete-confirmation/delete-confirmation.component';
import { PositionChoicesComponent } from '../position-choices/position-choices.component';
import { UploadedFileResponse } from 'src/app/interfaces/upload-video.interface';

declare var $;
declare var d3;

@Component({
    selector: 'app-ai-video-creator',
    templateUrl: './ai-video-creator.component.html',
    styleUrls: ['./ai-video-creator.component.css']
})
export class AiVideoCreatorComponent implements OnInit, OnDestroy {

    @ViewChild('popTmpAddChoiceEle') popTmpAddChoiceEle: PopoverDirective;
    @ViewChild('updateAIGeneratedScriptDialog') updateAIGeneratedScriptDialog: any;
    @ViewChild('updateTranscriptDialog') updateTranscriptDialog: any;
    @ViewChild('setTimerForCurrentNodeDialog') setTimerForCurrentNodeDialog: any;
    @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;
    @ViewChild('playAudio') playAudio: any;
    @ViewChild('videoPlayer') videoPlayer: any;

    nodes = [];
    latestNodeId: string;
    createInteractiveVideoObj: any = {
        spinner: false,
        selectedType: '',
        selectedVideoCheck: false,
        interactiveStep: 'one',
        tree: '',
        root: '',
        duration: 750,
        diagonal: '',
        svg: '',
        i: 0,
        currentObj: {},
        selectedItem: '',
        finalObj: [],
        endingNodes: [],
        connectorNodes: [],
        currentPlayingItem: {},
        mainVideo: '',
        child: [],
        publish: false,
        currentNodeOption: '',
        preview: false,
        totalUploadedVideoCount: 0,
        isRestricted: false,
        pathways: [],
        finalObjTree: [],
        conclusionLinks: [],
        activePathNodes: [],
    };
    private msgEvent = createVideoEvent;
    private msgStep = createVideoStep;
    mainID: any = '';
    assistEventsSubject = new BehaviorSubject<EventType>({ type: '' });
    isAiContentCreatorassist: any;
    alreadyTargetedLanguages = [];
    languages = languages;
    item: any = ';';
    isPrivate = false;
    treeData: any = [];
    currentVideoData: any = {};
    isReorderChoice = false;
    videoName: any = null;
    createChoiceObj: any = { child: [], currentNo: 2, parent: '' };
    value = 0;
    highValue = 100;
    options: any = {
        floor: 0,
        ceil: 100,
    };
    currentType = 'application/x-mpegURL';
    name;
    hh: string = '00';
    mm: string = '00';
    ss: string = '00';
    isChatWindowVisible: boolean = false;
    userMessage: string = '';
    OpenPopupAll: any = false;
    isOpenCreateVideo: any = false;
    clonedArr;

    footerSection: any;
    buttonView: any;
    zoomBtnPosition: any;
    currentHeight: any;
    private zoomListener: any;
    private visRef: any;
    dragDropNode = false;
    svgHeight;
    svgWidth;
    svgHeightForReset;
    svgWidthForReset;
    private rootInitialPosition: any;
    submitPathWaysFormError = '';
    allPathNodes = [];
    selectedPathNodes = [];
    pathNodes = [];
    selectedPathIndex = 0;
    startWatchNode: any;
    totalPaths = [];
    endWatchNode: any;
    undoOrderedNodes = [];
    redoOrderedNodes = [];
    private zoomRef: any = 1;
    scaleForReset;
    selectedNodesScript: string = '';

    childTitleChange$ = new BehaviorSubject('');
    titleChange$ = new BehaviorSubject('');
    duationChange$ = new BehaviorSubject(0);
    private interval: any;
    language;
    textareaData;
    initialValue;
    deleteCurrentNodeType = 1;
    isVisibleNodeVideo: boolean = false;
    isEditPathWay: boolean = false;
    private isInitialStep = true;
    isAddWatchPath = false;
    editWatchPath: any;
    isMessageLoad = false;
    watchPathName = '';
    isSelectedPathIndex = false;
    inputValues: string[] = [];
    inputLength: any[] = [''];
    isDisableInput: boolean = false;
    hubSpotLifeCycle: any;
    userMeData: any;
    lifeCycleStages: any;

    msgValue = '';
    messages: MessageType[] = [];
    currentMessageStep = 0;
    assignBadge: any;
    isSubmitPathWaysForm = false;
    messageMap = {
        rename: 'Rename Pathway',
        restart: 'Update Starting & Ending Nodes',
        addBadge: 'Assign Badge',
        selectBadge: 'Choose Badge',
        reChooseEndNode: 'Update Ending Node',
        addLearningObjectives: 'Set Objectives',
        hubSpotLifeCycle: 'Set HubSpot LifeCycle',
        save: 'Save',
        cancel: 'Cancel',
    };
    ishubSpotLifeCycle: boolean = false;
    allPossiblePaths = [];
    objectivesPopupData: any;
    isDisplayPopupWithObjective: boolean = false;
    private spinner: any = false;

    scaleIncrement;
    iconPosition: any = '0px';
    findorselect: string;
    private collabPage = 1;
    followers: Array<any> = [];
    isShareOpen: any = false;
    private collabLimit = 10;
    followersLength: number;
    currSubscriptionId = '';
    pricingUrl = '';
    assignPublishBadge: any;
    availableCategory: any = [];
    @ViewChildren('inputField') inputFields: QueryList<ElementRef>;
    userData: any;
    allFollowers: any = [];
    allGroups: any = [];
    groupList = [];
    followerListForPublic = [];
    isAIVideoCreatorLexie;

    constructor(
        private localStorageService: LocalStorageService,
        private dashboardService: DashboardService,
        private router: Router,
        private urlService: CrudService,
        private toastr: ToastrService,
        private activatedRoute: ActivatedRoute,
        private utilityService: UtilityService,
        private dialog: MatDialog,
        private cdr: ChangeDetectorRef
    ) {
        this.isAiContentCreatorassist = this.localStorageService.getItem('aiContentCreatorassist');
        this.isAIVideoCreatorLexie = localStorageService.getItem('aiVideoCreatorFlow');

        this.activatedRoute.queryParams.subscribe((params) => {
            this.createInteractiveVideoObj.currentObj.id = params.video;
            this.createInteractiveVideoObj.currentObj._id = params.video;
            this.mainID = params.video;

            this.initialFunction('');
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.createInteractiveVideoObj.finalObjTree.length) {
            this.callTree(this.createInteractiveVideoObj.finalObjTree);
        }
        setTimeout(() => {
            this.setHorizontalScrollbarPosition();
        }, 100);
    }

    get canSaveChoices() {
        return this.createChoiceObj.child.find(x => !x?._id);
    }

    get canPublishVideo() {
        return !this.createInteractiveVideoObj.finalObj.find(x => !!!x.URL);
    }

    get filterFollowers() {
        const keys = 'username,firstname,lastname';
        return (this.followers || []).filter(item => {
            item = item.followersDetails[0];
            return keys.split(',').some(key => item.hasOwnProperty(key) && new RegExp(this.findorselect, 'gi').test(item[key]))
        });
    }

    ngOnInit(): void {
        this.getUserInfo();

        if (this.mainID) {
            this.isAIVideoCreatorLexie = true;
        }

        if (this.isAiContentCreatorassist && !this.isAIVideoCreatorLexie) {
            this.assistEventsSubject.next({ type: this.msgEvent.init });
        }
    }

    initialFunction(p) {
        if (this.mainID) {
            this.getMainVideoInfo();
        }
    }

    getUserInfo() {
        this.dashboardService.getUserData.subscribe((res) => {
            if (res) {
                this.userData = res;
            }
        });
    }

    hideShowFooter() {
        const width = window.innerWidth;
        if (this.footerSection === 'none') {
            this.footerSection = 'block';
            this.zoomBtnPosition = '70px';
            if (width < 991) {
                this.zoomBtnPosition = '115px';
            }
            this.iconPosition = '98px';
        } else {
            this.footerSection = 'none';
            this.zoomBtnPosition = '115px';
            this.iconPosition = '0px';
        }
    }

    getMainVideoInfo(callback?) {

        this.urlService.getVideoInfov2(this.mainID).subscribe((res) => {
            if (this.localStorageService.getItem('user') != res.data.videoinfo.user) {

                this.urlService.isContributor(this.mainID).subscribe((res) => {
                    if (!res.data.isContributor) {
                        this.toastr.error('You don\'t have the ownership of the video');
                        this.router.navigate(['/profile']);
                    }
                })

            };

            this.createInteractiveVideoObj.currentObj = res.data.videoinfo;

            // if (res.data.videoinfo.isDocumentUpload) {
            //     this.assistEventsSubject.next({ type: this.msgEvent.scriptBeingCreated });
            // }

            if (this.createInteractiveVideoObj.currentObj.subtitleTranslations) {
                this.createInteractiveVideoObj.currentObj.subtitleTranslations = this.createInteractiveVideoObj.currentObj.subtitleTranslations.map(x => {
                    x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
                    return x;
                });
                if (this.createInteractiveVideoObj.currentObj.subtitleUrl) {
                    const defaultLang = this.getDefaultLangTranscription(this.createInteractiveVideoObj.currentObj)
                    this.createInteractiveVideoObj.currentObj.subtitleTranslations.unshift(defaultLang);
                }
            }

            let isCheckUrl = false;
            if (this.createInteractiveVideoObj.currentObj.URL) {
                isCheckUrl = this.utilityService.checkIsVideoUrl(this.createInteractiveVideoObj.currentObj.URL);
            }
            this.createInteractiveVideoObj.currentObj.isUrl = !isCheckUrl;
            this.createInteractiveVideoObj.currentObj.transcriptLang = this.utilityService.getTranscriptLang(this.createInteractiveVideoObj.currentObj.subtitleUrl);
            this.createInteractiveVideoObj.mainVideo = JSON.parse(JSON.stringify(this.createInteractiveVideoObj.currentObj));
            this.alreadyTargetedLanguages = this.createInteractiveVideoObj.mainVideo?.subtitleTranslations || [];
            this.alreadyTargetedLanguages = this.alreadyTargetedLanguages.map((x) => {
                return Object.keys(x)[0];
            });
            this.languages = this.languages.map(x => {
                x.disabled = this.alreadyTargetedLanguages.includes(x.code)
                return x;
            });
            this.item = res.data.videoinfo.poster;
            this.createInteractiveVideoObj.currentPlayingItem = this.createInteractiveVideoObj.currentObj;
            this.isPrivate = res.data.videoinfo.isRestricted;

            // console.log("in getMainVideoInfo - this.createInteractiveVideoObj.currentObj._id = ", this.createInteractiveVideoObj.currentObj._id);


            this.callChild('', () => {
                if (callback && typeof callback === 'function') {
                    callback();
                }
            });

        }, (err) => {
            this.router.navigateByUrl('/my-videos');
        });
    }

    getCurrentWidth() {
        const width = window.innerWidth;
        if (width < 991) {
            this.footerSection = 'none';
            this.buttonView = 'block';
            this.zoomBtnPosition = '115px';
        } else {
            this.footerSection = 'block';
            this.buttonView = 'none';
            this.zoomBtnPosition = '70px';
        }
        return width;
    }

    callTree(data) {

        if (this.createInteractiveVideoObj.interactiveStep === 'two') {
            return;
        }

        const a = this.getCurrentWidth();
        $('#tree-view svg').remove();
        this.createInteractiveVideoObj.spinner = true;
        this.createInteractiveVideoObj.interactiveStep = 'two';
        setTimeout(() => {
            const that = this;
            if (a < 991) {
                this.currentHeight = $(window).height();
            } else {
                this.currentHeight = $(window).height() - 250;
            }

            const m = [20, 20, 20, 20];
            const w = $(window).width();
            let h = this.currentHeight;
            let i = 0;

            const x = d3.scale.linear().domain([0, w]).range([0, w]);
            const y = d3.scale.linear().domain([0, h]).range([0, h]);
            let root;

            that.zoomListener = d3.behavior.zoom().x(x).y(y).scaleExtent([0.1, 10]).on('zoom', zoom);

            const vis = d3
                .select('#tree-view')
                .append('svg:svg')
                .attr('viewBox', '0 -300 900 900')
                .attr('class', 'svgViewBox')
                .attr('id', 'svg-id')
                .attr('width', w)
                .attr('height', this.currentHeight)
                .attr('xmlns', 'http://www.w3.org/2000/svg')
                .append('svg:g')

                .attr('id', 'rootg')
                .call(that.zoomListener);


            this.visRef = vis;

            vis.append('defs').append('marker')
                .attr('id', 'arrowhead')
                .attr('viewBox', '0 -5 10 10')
                .attr('refX', 10)
                .attr('refY', 0)
                .attr('markerWidth', 6)
                .attr('markerHeight', 6)
                .attr('orient', 'auto')
                .append('path')
                .attr('d', 'M0,-5L10,0L0,5');

            vis
                .append('rect')
                .attr('class', 'overlay')
                .attr('width', w + m[1] + m[3])
                .attr('height', h + m[0] + m[2])
                .attr('opacity', 0)
                .attr('fill', '#eee');

            let tree = d3.layout
                .tree()
                .size([h, w])
                .nodeSize([200 + 10, 700 + 10])
                .separation(() => {
                    return 0.5;
                });

            const diagonal = d3.svg.diagonal().projection((d) => {
                return [d.y, d.x];
            });

            root = data[0];
            root.x0 = h / 2;
            root.y0 = 0;

            this.visRef.attr('class', this.dragDropNode ? 'rearrange' : '');

            update(root);

            function update(source) {
                i = 0;
                const duration = d3.event && d3.event.altKey ? 5000 : 500;
                const levelWidth = [1];
                const childCount = (level, n) => {

                    if (n.children && n.children.length > 0) {
                        if (levelWidth.length <= level + 1) levelWidth.push(0);

                        levelWidth[level + 1] += n.children.length;
                        n.children.forEach(d => {
                            childCount(level + 1, d);
                        });
                    }
                };
                childCount(0, root);
                h = (d3.max(levelWidth) + 1) * 175;
                const ww = (levelWidth.length + 1) * 250;
                tree = tree.size([h, ww]);
                vis.select('rect.overlay')
                    .attr('height', h)
                    .attr('width', ww)

                const nodes = tree.nodes(root).reverse();

                nodes.forEach((d) => d.y = d.depth * 250);

                const node = vis.selectAll('g.node').data(nodes, (d) => {
                    return (d.id = ++i);
                });

                const nodeEnter = node
                    .enter()
                    .append('svg:g')
                    .attr('class', 'node')
                    .attr('id', (d) => 'node-' + d.id)
                    .attr('transform', (d) => 'translate(' + source.y0 + ',' + source.x0 + ')')
                    .on('click', (d) => {
                        if (d3.event.defaultPrevented) return;
                        that.clickedItem(d);
                    });

                vis.selectAll('g.node').data(nodes, (d) => {
                    return (d.ele = this);
                });

                nodeEnter.append('svg:circle')
                    .attr('r', 1e-6)
                    .style('fill', (d) => {
                        return d._children ? '#000' : '#000';
                    })

                nodeEnter.append('rect')
                    .attr('joint-selector', 'body')
                    .attr('fill', 'rgba(255,255,255,0)')
                    .attr('width', 140)
                    .attr('height', 130)
                    .attr('y', -60)
                    .attr('x', -60)


                nodeEnter.append('rect')
                    .attr('joint-selector', 'body')
                    .attr('fill', '#fff')
                    .attr('width', '100')
                    .attr('height', '90')
                    .attr('stroke', (d) => d.isEndingNode || d.isConnectorNode ? '#DC3545' : '#ccc')
                    .attr('y', -40)
                    .attr('x', -40)
                    .attr('rx', '8')
                    .attr('ry', '8')
                    .attr('id', (d) => 'node-button-' + d.id)
                    .attr('data-id', (d) => d._id)
                    .attr('class', (d) => {
                        if (d.isEndingNode) {
                            return 'not-droppable';
                        }
                        return `${d.children && d.children.length >= 4 ? 'not-droppable' : 'droppable'}`
                    });

                const dragListener = d3.behavior.drag().on('dragstart', dragstart).on('drag', drag).on('dragend', dragEnd);
                nodeEnter.call(dragListener)
                nodeEnter.on('mouseover', d => overCircle(d)).on('mouseout', d => outCircle(d));

                const imgGroup = nodeEnter.append('g');

                imgGroup.append('title').text(d => d.name);

                imgGroup.append('image')
                    .attr('y', -30)
                    .attr('x', -35)
                    .attr('clip-path', 'inset(0% round 8px)')
                    .attr('xlink:href', (d) => d.poster)
                    .attr('class', (d) => d.basck);

                imgGroup.append('image')
                    .attr('y', -30)
                    .attr('x', -5)
                    .attr('clip-path', 'inset(0% round 8px)')
                    .attr('cursor', 'pointer')
                    .attr('class', (d) => d.imvd)
                    .attr('xlink:href', (d) => d.poster1);

                const title = nodeEnter
                    .append('text')
                    .attr('y', 30)
                    .attr('x', 10);

                title.append('tspan')
                    .attr('text-anchor', 'middle')
                    .text(d => d.name)
                    .each(wrap);

                title.append('title').text(d => d.name);

                // questionnaire image
                const quesImgGroup = nodeEnter.append('svg:g')
                    .attr('transform', 'matrix(1,0,0,1,45,-50)')
                    .attr('fill', 'mediumpurple')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .attr('visibility', (d) => {
                        return `${d.questionnaireInfo ? 'visible' : 'hidden'}`
                    });

                quesImgGroup.append('rect')
                    .attr('rx', 6)
                    .attr('ry', 6)
                    .attr('height', 25)
                    .attr('width', 25);

                quesImgGroup.append('image')
                    .attr('x', 3)
                    .attr('y', 3)
                    .attr('height', 19)
                    .attr('width', 19)
                    .attr('xlink:href', 'assets/images/questionnaire.png');

                const timerIcon = nodeEnter.append('svg:g')
                    .attr('transform', (d) => {
                        const position = that.getTimerIconPosition(d);
                        return position;
                    })
                    .attr('fill', 'transparent')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .attr('visibility', (d) => {
                        return `${d?.timer ? 'visible' : 'hidden'}`
                    });

                let timerIconHTML = `<div style="font-size: medium; background: mediumpurple; border-radius: 20%; padding: 1px 4px 0px; color: white;"><i class="fa fa-clock"></i></div>`;

                timerIcon.append('foreignObject')
                    .attr('height', 25)
                    .attr('width', 25)
                    .html(timerIconHTML);

                const aiGeneratedScriptIcon = nodeEnter.append('svg:g')
                    .attr('transform', (d) => {
                        const position = that.getScriptIconPosition(d);
                        return position;
                    })
                    .attr('fill', 'white')
                    .attr('stroke', 'purple')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .attr('visibility', (d) => {
                        return `${d?.aiGeneratedScript ? 'visible' : 'hidden'}`
                    })
                    .on('click', (d) => {
                        d3.event.stopPropagation();
                        if (d?.aiGeneratedScript) {
                            that.updateAIGeneratedScriptOfNode(d);
                        }
                    });

                aiGeneratedScriptIcon.append('rect')
                    .attr('rx', 6)
                    .attr('ry', 6)
                    .attr('height', 25)
                    .attr('width', 25);

                aiGeneratedScriptIcon.append('image')
                    .attr('x', 3)
                    .attr('y', 3)
                    .attr('height', 19)
                    .attr('width', 19)
                    .attr('xlink:href', 'assets/images/script.svg');

                const subTitleIcon = nodeEnter.append('svg:g')
                    .attr('transform', (d) => {
                        return `${d.questionnaireInfo ? 'matrix(1,0,0,1,8,-50)' : 'matrix(1,0,0,1,45,-50)'}`
                    })
                    .attr('fill', '#fff')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .attr('visibility', (d) => {

                        return `${d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0) ? 'visible' : 'hidden'}`

                    });

                subTitleIcon.append('rect')
                    .attr('rx', 6)
                    .attr('ry', 6)
                    .attr('height', 25)
                    .attr('width', 35);

                subTitleIcon.append('image')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('height', 25)
                    .attr('width', 35)
                    .attr('xlink:href', 'assets/images/subtitle.svg')

                subTitleIcon.on('mouseover', function (d) {
                    if (d) {
                        let subTitleContent = '<div><b>Subtitles</b>';
                        d.subtitleTranslations.forEach((subtitleTranslation) => {
                            subTitleContent += `
                                <div>
                                    ${subtitleTranslation?.subtitle?.language} 
                                    (${subtitleTranslation?.subtitle?.code})
                                </div>
                            `;
                        });
                        subTitleContent += '</div>';
                        $(this).popover({
                            content: subTitleContent,
                            placement: 'right',
                            trigger: 'hover',
                            html: true
                        });
                        $(this).popover('show');
                    }
                }).on('mouseout', function () {
                    $(this).popover('hide');
                });

                function wrap() {
                    const self = d3.select(this);
                    let textLength = self.node().getComputedTextLength();
                    let text = self.text();
                    while (textLength > (100 - 2 * 10) && text.length > 0) {
                        text = text.slice(0, -1);
                        self.text(text + '...');
                        textLength = self.node().getComputedTextLength();
                    }
                }

                // Transition nodes to their new position.
                const nodeUpdate = node
                    .transition()
                    .duration(duration)
                    .attr('transform', (d) => {
                        return 'translate(' + d.y + ',' + d.x + ')';
                    });

                nodeUpdate
                    .select('circle')
                    .attr('r', 4.5)
                    .style('fill', (d) => {
                        return d._children ? '#000' : '#000';
                    });

                nodeUpdate.select('text').style('fill-opacity', 1);

                const nodeExit = node
                    .exit()
                    .transition()
                    .duration(duration)
                    .attr('transform', (d) => {
                        return 'translate(' + source.y + ',' + source.x + ')';
                    })
                    .remove();

                nodeExit.select('circle').attr('r', 1e-6);
                nodeExit.select('text').style('fill-opacity', 1e-6);

                const closeButton = vis
                    .append('g')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .style('display', 'none')
                    .on('click', (d) => {
                        if (d3.event.defaultPrevented) return;
                        d3.event.stopPropagation();
                        that.deleteParentIdsNode({
                            parent: closeButton.attr('data-source'),
                            child: closeButton.attr('data-target')
                        });
                    })
                    .on('mouseover', () => closeButton.style('display', null))
                    .on('mouseout', () => closeButton.style('display', 'none'));

                closeButton.append('rect')
                    .attr('joint-selector', 'body')
                    .attr('fill', 'rgba(0,0,0,0.1)')
                    .attr('transform', 'translate(-20, -20)')
                    .attr('width', 40)
                    .attr('height', 40)
                    .attr('rx', 20);

                closeButton
                    .append('rect')
                    .attr('fill', '#df1717')
                    .attr('transform', 'translate(-12.5, -12.5)')
                    .attr('width', 25)
                    .attr('height', 25)
                    .attr('rx', 12.5);

                closeButton.append('svg:path')
                    .attr('d', 'M 7.5 7.5 L 17.5 17.5 M 7.5 17.5 L 17.5 7.5')
                    .attr('stroke', '#ffffff')
                    .attr('stroke-width', '2')
                    .attr('transform', 'translate(-12, -12)');

                // Update the links...
                const jsonLinks = tree.links(nodes);
                for (const conclusionLink of that.createInteractiveVideoObj.conclusionLinks) {
                    jsonLinks.push(conclusionLink)
                }
                vis.selectAll('path.link').remove();
                const link = vis.selectAll('path.link').data(jsonLinks);


                link
                    .enter()
                    .insert('svg:path', 'g')
                    .attr('class', (d) => {
                        return 'link' + (d.type ? (' ' + d.type) : '');
                    })
                    .attr('d', (d) => {
                        const o = {
                            x: source.x0,
                            y: source.y0,
                        };
                        return diagonal({
                            source: o,
                            target: o,
                        });
                    })
                    .on('mouseover', function (d) {
                        if (d.type === 'conclusion') {
                            d3.select(this).style('stroke-width', '4px');
                            const linkElement = d3.select(this);
                            const linkWidth = linkElement.node().getTotalLength();
                            const pointAtLength = linkElement.node().getPointAtLength(linkWidth * 0.3);

                            const xb = pointAtLength.x;
                            const yb = pointAtLength.y;

                            closeButton.style('display', null)
                                .attr('transform', 'translate(' + xb + ', ' + yb + ')')
                                .attr('data-source', d.source._id)
                                .attr('data-target', d.target._id);

                            return;
                        }
                        d3.select(this).style('stroke-width', '4px');
                        const bbox = this.getBBox();
                        const xb = bbox.x + bbox.width / 2;
                        const yb = bbox.y + bbox.height / 2;
                    })
                    .on('mouseout', function () {
                        d3.select(this)
                            .style('stroke-width', null);
                        closeButton.style('display', 'none');
                    });

                link.transition().duration(duration)
                    .attr('d', (d) => {
                        let sourceX = d.source.x;
                        let sourceY = d.source.y + 40;

                        let targetX = d.target.x;
                        let targetY = d.target.y - 40;

                        if (d.source.y > d.target.y) {
                            targetX = (d.target.x + 10);
                            targetY = (d.target.y + 60);
                            sourceY = (d.source.y - 20);
                        }

                        if (d.source.y === d.target.y) {
                            targetX = (d.target.x - 50);
                            targetY = (d.target.y + 45);
                        }

                        return diagonal({
                            source: { x: sourceX, y: sourceY },
                            target: { x: targetX, y: targetY },
                        });
                    })
                    .attr('marker-end', 'url(#arrowhead)');

                link
                    .exit()
                    .transition()
                    .duration(duration)
                    .attr('d', (d) => {
                        const o = {
                            x: source.x,
                            y: source.y,
                        };
                        return diagonal({
                            source: o,
                            target: o,
                        });
                    })
                    .remove();

                nodes.forEach((d) => {
                    d.x0 = d.x;
                    d.y0 = d.y;
                });

                const rootG = document.getElementById('rootg');
                const rect = rootG.getBoundingClientRect();

                that.nodes = nodes;

                const calculateTranslation = (currentNode) => {
                    const xAxis = rect.x + (currentNode[0].y0);
                    const yAxis = (currentNode[0].x0 > 0) ? (rect.y + currentNode[0].x0) : (-currentNode[0].x0 - rect.y);

                    const x2 = window.innerWidth / 2;
                    const y2 = window.innerHeight / 2;
                    const x3 = xAxis > x2 ? (x2 - xAxis) : (xAxis - x2);
                    let y3 = 0;
                    if (currentNode[0].x0 > 0) {
                        y3 = Math.floor((yAxis > y2) ? (y2 - yAxis) : (yAxis - y2));
                    } else {
                        y3 = Math.floor((-yAxis < y2) ? (yAxis + y2) : (yAxis - (-y2)));
                    }
                    return [x3, y3];
                }
                let trans;
                if (that.latestNodeId) {
                    const latestNodeItem = that.getLatestItem(nodes, that.latestNodeId);
                    const latestNode = document.getElementById(`node-button-${latestNodeItem[0]?.id}`);
                    if (latestNode) {
                        latestNode.style.fill = '#d3c1eb';
                    }
                    trans = calculateTranslation(latestNodeItem);
                    that.translateTreeAndAddScrollBar(that.nodes);
                } else {
                    const latestNodeItem = that.getLatestItem(nodes, root._id);
                    trans = calculateTranslation(latestNodeItem);
                    that.visRef.attr('transform', 'translate(' + trans + ') scale(1)');
                }
                if (!that.rootInitialPosition) {
                    that.rootInitialPosition = trans;
                }

                that.updateCurrentHighlight();
            }

            const panSpeed = 100;
            const panBoundary = 10;
            let panTimer;
            let translateCoords;
            let translateX;
            let translateY;
            let dragStarted = false;
            let nodesChild;
            let selectedNode = null
            let draggingNode = null;
            let domNode;

            const overCircle = d => {
                if (draggingNode && d.id !== draggingNode.id) {
                    selectedNode = d;
                    updateTempConnector();
                }
            };
            const outCircle = d => {
                selectedNode = null;
                updateTempConnector();
            };

            const updateTempConnector = () => {
                if (draggingNode !== null && selectedNode !== null) {
                    $(`#node-button-${selectedNode.id}`).addClass('can-drop');
                } else {
                    $(`.can-drop`).removeClass('can-drop');
                }
            };

            function dragstart(d) {
                if (!that.dragDropNode) {
                    return;
                }
                if (d === root) {
                    return;
                }
                dragStarted = true;
                nodesChild = tree.nodes(d);
                d3.event.sourceEvent.stopPropagation();
                d3.select(this).classed('fixed', d.fixed = true);
            }

            function pan(domNode1, direction) {
                const speed = panSpeed;
                if (panTimer) {
                    clearTimeout(panTimer);
                    translateCoords = d3.transform(vis.attr('transform'));
                    if (direction === 'left' || direction === 'right') {
                        translateX = direction === 'left' ? translateCoords.translate[0] + speed : translateCoords.translate[0] - speed;
                        translateY = translateCoords.translate[1];
                    } else if (direction === 'up' || direction === 'down') {
                        translateX = translateCoords.translate[0];
                        translateY = direction === 'up' ? translateCoords.translate[1] + speed : translateCoords.translate[1] - speed;
                    }
                    const scaleX = translateCoords.scale[0];
                    const scaleY = translateCoords.scale[1];
                    const scale = that.zoomListener.scale();
                    vis.transition().attr('transform', 'translate(' + translateX + ',' + translateY + ')scale(' + scale + ')');
                    d3.select(domNode1).select('g.node').attr('transform', 'translate(' + translateX + ',' + translateY + ')');
                    panTimer = setTimeout(() => {
                        pan(domNode1, direction);
                    }, 50);
                }
            }

            function drag(d) {
                if (!that.dragDropNode) {
                    return;
                }
                if (d === root) {
                    return;
                }
                if (!dragStarted) {
                    d.x0 += d3.event.dy;
                    d.y0 += d3.event.dx;
                }
                if (dragStarted) {
                    initiateDrag(d, this);
                }


                const relCoords = d3.mouse($('#tree-view').get(0));
                if (relCoords[0] < panBoundary) {
                    panTimer = true;
                    pan(this, 'left');
                } else if (relCoords[0] > ($('#tree-view svg').width() - panBoundary)) {
                    panTimer = true;
                    pan(this, 'right');
                } else if (relCoords[1] < panBoundary) {
                    panTimer = true;
                    pan(this, 'up');
                } else if (relCoords[1] > ($('#tree-view svg').height() - panBoundary)) {
                    panTimer = true;
                    pan(this, 'down');
                } else {
                    try {
                        clearTimeout(panTimer);
                    } catch (e) {

                    }
                }

                const node1 = d3.select(this);
                node1.attr('transform', 'translate(' + d.y0 + ',' + d.x0 + ')');

            }

            function initiateDrag(d, domNode) {
                draggingNode = d;

                d3.select(domNode).select(`#node-button-${d.id}`).attr('class', 'active-drag');

                vis.selectAll('g.node').sort((a, b) => {
                    if (a.id !== draggingNode.id) return 1;
                    else return -1;
                });

                if (nodesChild.length > 1) {
                    const links = tree.links(nodesChild);
                    const nodePaths = vis.selectAll('path.link')
                        .data(links, d => d.target.id).remove();
                    const nodesExit = vis.selectAll('g.node')
                        .data(nodesChild, d => d.id).filter((d, i) => {
                            return d.id !== draggingNode.id;
                        }).remove();
                }

                const parentLink = tree.links(tree.nodes(draggingNode.parent));
                vis.selectAll('path.link').filter((d, i) => {
                    return d.target.id === draggingNode.id;

                }).remove();

                dragStarted = false;
            }

            function dragEnd(d) {
                clearTimeout(panTimer);
                if (!that.dragDropNode) {
                    return;
                }
                if (d === root) {
                    return;
                }
                domNode = this;
                if (selectedNode) {
                    if (!selectedNode.children) {
                        selectedNode.children = [];
                    }
                    if (!selectedNode.isEndingNode && selectedNode.children.length < 4) {
                        const index = draggingNode.parent.children.indexOf(draggingNode);
                        if (index > -1) {
                            draggingNode.parent.children.splice(index, 1);
                        }
                        if (typeof selectedNode.children !== 'undefined' || typeof selectedNode._children !== 'undefined') {
                            if (typeof selectedNode.children !== 'undefined') {
                                selectedNode.children.push(draggingNode);
                            } else {
                                selectedNode._children.push(draggingNode);
                            }
                        } else {
                            selectedNode.children = [];
                            selectedNode.children.push(draggingNode);
                        }
                        const object = {
                            videoId: draggingNode._id,
                            parentId: draggingNode.parentId,
                            newParentId: selectedNode._id
                        }
                        that.saveDragDropNode(object);
                    } else {
                        endDrag();
                    }
                } else {
                    endDrag();
                }
            }

            function endDrag() {
                selectedNode = null;
                d3.selectAll('.ghostCircle').attr('class', 'ghostCircle');
                d3.select(domNode).attr('class', 'node');
                d3.select(domNode).select('.ghostCircle').attr('pointer-events', '');
                if (draggingNode !== null) {
                    update(root);
                    draggingNode = null;
                    $(`.node button`).removeClass('drag-node');
                }
            }


            // zoom in / out
            function zoom() {
                if (d3.event.sourceEvent?.type === 'mousemove' && d3.event.sourceEvent.target.classList.contains('overlay')) {
                    clearTimeout(panTimer);

                    let treeContainer = document.getElementById('svg-id');
                    let transformScale = that.getTransformScale(treeContainer);
                    const scale = that.zoomRef ? that.zoomRef : transformScale;
                    const eTransform = d3.transform(that.visRef.attr('transform'));
                    const nTranslateX = (eTransform.translate[0] + d3.event.translate[0] * scale) / 2;
                    const nTranslateY = (eTransform.translate[1] + d3.event.translate[1] * scale) / 2;
                    that.visRef.attr('transform', 'translate(' + [nTranslateX, nTranslateY] + ') scale(' + scale + ')');

                    that.zoomRef = scale;
                }
            }

            this.createInteractiveVideoObj.selectedVideoCheck = false;
            this.createInteractiveVideoObj.interactiveStep = 'three';
            this.createInteractiveVideoObj.spinner = false;

        }, 500);

    }

    addChoices(no) {
        for (let i = 0; i < no; i++) {
            const name = 'Untitled ' + i;
            const obj: any = {
                name,
                URL: '',
                _id: '',
                parentId: this.createInteractiveVideoObj.currentObj._id,
            };
            this.createChoiceObj.child.push(obj);
        }
        this.createChoiceObj.parent = this.createInteractiveVideoObj.currentObj;
    }

    durationChange(duration?: string) {
        const time = duration.split(':');
        const currentTime =
            Number(time[2]) + Number(time[1]) * 60 + Number(time[0]);
        if (currentTime > this.createInteractiveVideoObj.currentObj.duration) {
            this.toastr.error('Time should be less then duration!');
            return;
        } else {
            this.duationChange$.next(currentTime);
        }
    }

    drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(this.createChoiceObj.child, event.previousIndex, event.currentIndex);
    }

    getTransformScale(treeContainer) {
        const desiredHeight = 700;
        let transformScale = desiredHeight / treeContainer.clientHeight;

        if (transformScale < 0.12) {
            transformScale = 0.12;
        }

        return transformScale;
    }

    callChild(p: any, callback?) {
        // console.log("callChild - this.createInteractiveVideoObj.currentObj._id = ", this.createInteractiveVideoObj.currentObj._id);
        this.urlService
            .getChildVideosAll(this.createInteractiveVideoObj.currentObj._id)
            .subscribe((success) => {
                const tree = [];
                if (!!!this.createInteractiveVideoObj.currentObj.URL) {
                    this.createInteractiveVideoObj.currentObj.name = this.createInteractiveVideoObj.currentObj.name ? this.createInteractiveVideoObj.currentObj.name : 'Add Video';
                    this.createInteractiveVideoObj.currentObj.poster1 = 'assets/images/H-vidcam.svg';
                    this.createInteractiveVideoObj.currentObj.basck = 'gt';
                    this.createInteractiveVideoObj.currentObj.title = this.createInteractiveVideoObj.currentObj.title ? this.createInteractiveVideoObj.currentObj.title : 'Add Video';
                    this.createInteractiveVideoObj.currentObj.imvd = 'imvd';
                } else {
                    this.createInteractiveVideoObj.currentObj.basck = 'videochart';
                    this.createInteractiveVideoObj.currentObj.imvd = 'ncs';
                }
                this.createInteractiveVideoObj.currentObj.parentId = '0';
                tree.push(this.createInteractiveVideoObj.currentObj);
                for (const i of success.data) {
                    let isCheckUrl = false;
                    if (i.URL) {
                        isCheckUrl = this.utilityService.checkIsVideoUrl(i.URL);
                    }
                    i.transcriptLang = this.utilityService.getTranscriptLang(i.subtitleUrl);
                    i.isUrl = !isCheckUrl;
                    i.durationInHHMMSS = this.utilityService.convertSecondsToHHMMSSFormat(i.duration);
                    if (!!!i.URL) {
                        i.name = i.name ? i.name : 'Add Video';
                        i.poster1 = 'assets/images/H-vidcam.svg';
                        i.basck = 'gt';
                        i.title = i.title ? i.title : 'Add Video';
                        i.imvd = 'imvd';
                    } else {
                        i.basck = 'videochart';
                        i.imvd = 'ncs';
                    }
                    if (i.subtitleTranslations) {
                        i.subtitleTranslations = i.subtitleTranslations.map(x => {
                            x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
                            return x;
                        });
                        if (i.subtitleUrl) {
                            const defaultLang = this.getDefaultLangTranscription(i);
                            i.subtitleTranslations.unshift(defaultLang);
                        }
                    }
                    tree.push(i);
                    this.treeData = tree
                }
                this.createInteractiveVideoObj.selectedVideoCheck = false;
                this.createInteractiveVideoObj.interactiveStep = 'three';
                this.createInteractiveVideoObj.spinner = false;
                this.createInteractiveVideoObj.finalObj = tree;
                this.createInteractiveVideoObj.endingNodes = tree.filter(x => x.isEndingNode);
                this.createInteractiveVideoObj.connectorNodes = tree.filter(x => x.isConnectorNode);
                this.createInteractiveVideoObj.currentPlayingItem = tree[0];
                this.createInteractiveVideoObj.currentObj.open = false;
                this.createInteractiveVideoObj.finalObjTree = this.createNestedArray(tree);
                this.getVideoPathWays();
                this.callTree(this.createInteractiveVideoObj.finalObjTree);

                if (this.isAiContentCreatorassist && this.mainID && this.isAIVideoCreatorLexie) {
                    if (this.createInteractiveVideoObj?.currentObj) {
                        if (!this.createInteractiveVideoObj.currentObj.aiGeneratedScript && !this.createInteractiveVideoObj.currentObj.URL && !this.createInteractiveVideoObj.currentObj.poster && !this.createInteractiveVideoObj.currentObj.aiVoiceModel) {
                            this.assistEventsSubject.next({ type: this.msgEvent.flowNotCompleted, data: this.msgStep.contentTreeCreated });
                        } else if (this.createInteractiveVideoObj.currentObj.aiGeneratedScript && !this.createInteractiveVideoObj.currentObj.URL && !this.createInteractiveVideoObj.currentObj.poster && !this.createInteractiveVideoObj.currentObj.aiVoiceModel) {
                            const scriptGeneratedForAllNode = this.createInteractiveVideoObj.finalObj.every((i) => i?.aiGeneratedScript && i?.aiGeneratedScript !== '');
                            this.assistEventsSubject.next({ type: this.msgEvent.flowNotCompleted, data: { event: this.msgEvent.scriptBeingCreated, isScriptGenerated: scriptGeneratedForAllNode } });
                        } else if (this.createInteractiveVideoObj.currentObj.aiGeneratedScript && this.createInteractiveVideoObj.currentObj.URL && this.createInteractiveVideoObj.currentObj.poster && !this.createInteractiveVideoObj.currentObj.aiVoiceModel) {
                            this.isVisibleNodeVideo = true;
                            const videoGeneratedForAllNode = this.createInteractiveVideoObj.finalObj.every((i) => i?.aiVideoURL && i?.aiVideoURL !== '');
                            this.assistEventsSubject.next({ type: this.msgEvent.flowNotCompleted, data: { event: this.msgEvent.videoAttachedToAllChoice, isVideoGenerated: videoGeneratedForAllNode } });
                        } else if (this.createInteractiveVideoObj.currentObj.aiGeneratedScript && this.createInteractiveVideoObj.currentObj.URL && this.createInteractiveVideoObj.currentObj.poster && this.createInteractiveVideoObj.currentObj.aiVoiceModel) {
                            this.assistEventsSubject.next({ type: this.msgEvent.flowNotCompleted, data: this.msgStep.nodeSelectedForVoice });
                            this.isVisibleNodeVideo = false;
                        } else if (this.createInteractiveVideoObj.currentObj.isDocumentUpload) {
                            this.assistEventsSubject.next({ type: this.msgEvent.flowNotCompleted, data: this.msgStep.nodeSelectedForVoice, isDocumentUpload: true });
                            this.isVisibleNodeVideo = true;
                        }
                    }
                }
                if (this.mainID) {
                    if (this.createInteractiveVideoObj?.currentObj.isDocumentUpload) {
                        this.urlService.getVideoInfov2(this.mainID).subscribe((res) => {
                            this.createInteractiveVideoObj.currentObj = res.data.videoinfo;
                            this.assistEventsSubject.next({ type: this.msgEvent.mainVideo, data: this.createInteractiveVideoObj?.currentObj });
                        });
                    } else {
                        this.assistEventsSubject.next({ type: this.msgEvent.mainVideo, data: this.createInteractiveVideoObj?.currentObj });
                    }
                }
                if (this.currentVideoData?.isEdit) {
                    const currentObj = this.createInteractiveVideoObj.finalObj.find(x => x._id === this.currentVideoData._id)
                    if (currentObj) {
                        this.clickedItem(currentObj)
                    }
                }
                if (callback && typeof callback === 'function') {
                    callback();
                }
            });
    }

    callChild1(d?) {
        $('#tree-view').html();
        $('svg[width="100%"]').remove();

        this.urlService.getChildVideosAll(this.mainID).subscribe((success) => {
            const tree = [];
            this.createInteractiveVideoObj.mainVideo.parentId = '0';
            this.createInteractiveVideoObj.mainVideo.level = 0;
            if (!!!this.createInteractiveVideoObj.mainVideo.URL) {
                this.createInteractiveVideoObj.mainVideo.name = this.createInteractiveVideoObj.mainVideo.name ? this.createInteractiveVideoObj.mainVideo.name : 'Add Video';
                this.createInteractiveVideoObj.mainVideo.poster1 = 'assets/images/H-vidcam.svg';
                this.createInteractiveVideoObj.mainVideo.basck = 'gt';
                this.createInteractiveVideoObj.mainVideo.title = this.createInteractiveVideoObj.mainVideo.title ? this.createInteractiveVideoObj.mainVideo.title : 'Add Video';
                this.createInteractiveVideoObj.mainVideo.imvd = 'imvd';
            } else {
                this.createInteractiveVideoObj.mainVideo.basck = 'videochart';
                this.createInteractiveVideoObj.mainVideo.imvd = 'ncs';
            }
            tree.push(this.createInteractiveVideoObj.mainVideo);
            for (const i of success.data) {
                let isCheckUrl = false;
                if (i.URL) {
                    isCheckUrl = this.utilityService.checkIsVideoUrl(i.URL);
                }
                i.transcriptLang = this.utilityService.getTranscriptLang(i.subtitleUrl);
                i.isUrl = !isCheckUrl;
                i.durationInHHMMSS = this.utilityService.convertSecondsToHHMMSSFormat(i.duration);
                if (!!!i.URL) {
                    i.name = i.name ? i.name : 'Add Video';
                    i.poster1 = 'assets/images/H-vidcam.svg';
                    i.basck = 'gt';
                    i.title = i.title ? i.title : 'Add Video';
                    i.imvd = 'imvd';
                } else {
                    i.basck = 'videochart';
                    i.imvd = 'ncs';
                }
                i.id = undefined;
                if (i.subtitleTranslations) {
                    i.subtitleTranslations = i.subtitleTranslations.map(x => {
                        x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
                        return x;
                    });
                    if (i.subtitleUrl) {
                        const defaultLang = this.getDefaultLangTranscription(i);
                        i.subtitleTranslations.unshift(defaultLang);
                    }
                }
                tree.push(i);
            }
            this.createInteractiveVideoObj.spinner = false;
            this.createInteractiveVideoObj.finalObj = tree;
            this.createInteractiveVideoObj.endingNodes = tree.filter(x => x.isEndingNode);
            this.createInteractiveVideoObj.connectorNodes = tree.filter(x => x.isConnectorNode);
            if (d) {
                this.createInteractiveVideoObj.connectorNodes = tree.filter(x => x.isConnectorNode === true && d._id !== x._id);
            }
            this.createInteractiveVideoObj.finalObjTree = this.createNestedArray(tree);
            this.getVideoPathWays();
            this.callTree(this.createInteractiveVideoObj.finalObjTree);
        });
    }

    updateCurrentHighlight() {
        this.submitPathWaysFormError = '';
        $('rect').removeClass('nodeSelected');
        if (this.allPathNodes.length && this.selectedPathIndex) {
            this.selectedPathNodes = this.allPathNodes[this.selectedPathIndex - 1];
            const startFind = this.selectedPathNodes.find(x => x._id === this.startWatchNode?._id);
            if (startFind) {
                for (const video of this.selectedPathNodes) {
                    $(`rect[data-id=${video._id}]`).addClass('nodeSelected');
                }
            }
        } else if (this.pathNodes) {
            this.selectedPathNodes = this.pathNodes;
            const startFind = this.pathNodes.find(x => x._id === this.startWatchNode?._id);
            if (startFind) {
                for (const video of this.pathNodes) {
                    if (this.totalPaths.length && this.totalPaths.length === 1) {
                        $(`rect[data-id=${video._id}]`).addClass('nodeSelected');
                    } else {
                        $(`rect[data-id=${video._id}]`).attr('fill', video.color);
                    }
                }
            }
        }
        if (this.startWatchNode) {
            $(`rect[data-id=${this.startWatchNode._id}]`).addClass('nodeSelected');
        }
        if (this.endWatchNode) {
            $(`rect[data-id=${this.endWatchNode._id}]`).addClass('nodeSelected');
        }

    }

    updateAIGeneratedScriptOfNode(currentNode) {
        this.selectedNodesScript = currentNode?.aiGeneratedScript ? currentNode?.aiGeneratedScript : '';
        const dialogRef = this.dialog.open(this.updateAIGeneratedScriptDialog, {
            width: '100%',
            maxWidth: '700px',
            panelClass: 'position-choices',
            height: 'auto',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                let payload = {
                    videoId: currentNode._id,
                    type: currentNode.type,
                    updatedScript: this.selectedNodesScript
                }

                this.urlService.updateAiGeneratedScript(payload).subscribe((res) => {
                    this.getMainVideoInfo();
                }, (err) => {
                    this.toastr.error('Error!', err);
                })
            }
        })
    }

    editVideo() {
        // $('#tree-Modal').modal('hide');
        $('#tree-node-modal').modal('hide');
        this.currentVideoData.isEdit = true;
        const dialogRef = this.dialog.open(EditVideoComponent, {
            disableClose: true,
            width: '100%',
            height: '80%',
            maxWidth: '80%',
            panelClass: 'my-dialog',
            data: {
                video: this.createInteractiveVideoObj.currentObj,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result?.isAnyChange) {
                this.refreshInteractiveVideo();
            } else {
                this.clickedItem(result?.video);
            }
        })
    }

    UptheMouse() {
        if (this.createChoiceObj.currentNo > 4) {
            this.createChoiceObj.currentNo = 4;
        } else if (this.createChoiceObj.currentNo < 1) {
            this.createChoiceObj.currentNo = 1;
        }
    }

    refreshInteractiveVideo() {
        $('#tree-Modal').modal('hide');
        $('#tree-node-modal').modal('hide');
        this.createChoiceObj.child = [];
        this.createInteractiveVideoObj.currentObj.open = false;
        this.initialFunction('');
    }

    getTimerIconPosition(d) {
        if (d.questionnaireInfo && (d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0))) {
            return 'matrix(1,0,0,1,-19,-50)';
        } else if (d.questionnaireInfo || (d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0))) {
            return 'matrix(1,0,0,1,18,-50)';
        } else {
            return 'matrix(1,0,0,1,45,-50)';

        }
    }

    getScriptIconPosition(d) {
        if (d.questionnaireInfo && (d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && d?.timer) {
            return 'matrix(1,0,0,1,30,-22)';
        } else if ((d.questionnaireInfo && (d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && !d?.timer)
            || (d.questionnaireInfo && !(d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && d?.timer)
            || (!d.questionnaireInfo && (d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && d?.timer)) {
            return 'matrix(1,0,0,1,-24,-50)';
        } else if ((!d.questionnaireInfo && !(d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && d?.timer)
            || (d.questionnaireInfo && !(d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && !d?.timer)
            || (!d.questionnaireInfo && (d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0)) && !d?.timer)) {
            return 'matrix(1,0,0,1,6,-50)';
        } else {
            return 'matrix(1,0,0,1,45,-50)';
        }
    }

    saveDragDropNode(object, type?) {
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.moveVideoNode(object).subscribe(() => {
            if (type === 'redo' && this.redoOrderedNodes.length !== 0) {
                this.undoOrderedNodes.push(object);
                this.redoOrderedNodes.pop();
            } else if (type === 'undo' && this.undoOrderedNodes.length !== 0) {
                this.redoOrderedNodes.push(object);
                this.undoOrderedNodes.pop();
            } else {
                this.undoOrderedNodes.push(object);
                this.redoOrderedNodes = [];
            }
            this.latestNodeId = object.newParentId;
            this.callChild1();
        }).add(() => {
            this.createInteractiveVideoObj.spinner = false;
        });
    }

    insertNode(item) {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            data: {
                links: false
            },
            minWidth: '50vw'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.createInteractiveVideoObj.spinner = true;
                const objFinal: any = {
                    type: 'child',
                    name: result.name,
                    parentId: item.parent,
                    childId: item.child,
                    URL: result.URL,
                    time: 0,
                    poster: result.poster ? result.poster : result.thumbnail,
                    videoID: result._id,
                    subtitleUrl: result.subtitleUrl,
                    isEndingNode: result.type === 'url'
                };
                this.urlService.insertNode(objFinal).subscribe((res) => {
                    this.checkEditPathWay(objFinal, res.data);
                    this.initialFunction('');
                    this.toastr.info('Node Inserted Successfully!');
                }, () => {
                    this.createInteractiveVideoObj.spinner = false;
                });
            }
        });
    }

    checkEditPathWay(objFinal, newNode) {
        for (const pathway of this.createInteractiveVideoObj.pathways) {
            if (pathway.pathNodes.includes(objFinal.parentId) && pathway.pathNodes.includes(objFinal.childId)) {
                pathway.pathNodes.push(newNode.data._id);
                const payload: any = {
                    _id: pathway._id,
                    videoId: this.mainID,
                    pathName: pathway.pathName,
                    startNodeId: pathway.startNodeId,
                    startNodeType: pathway.startNodeType,
                    endNodeId: pathway.endNodeId,
                    endNodeType: pathway.endNodeType,
                    pathNodes: pathway.pathNodes
                };
                this.urlService.setPathway(payload, true).subscribe((res) => {
                    this.getVideoPathWays();
                });
            }
        }
    }

    deleteParentIdsNode(d) {

        if (d.child && d.parent) {
            const payload = {
                videoId: this.mainID,
                connectorNodeId: d.child,
                removeParentIds: [d.parent]
            }

            this.urlService.removeParentIds(payload).subscribe((res) => {
                this.createInteractiveVideoObj.spinner = true;
                this.callChild1();
            })
        }
    }

    translateTreeAndAddScrollBar(nodes, scale?) {
        let svgContainer = document.getElementById('rootg');
        let treeContainer = document.getElementById('svg-id');
        let treeView = document.getElementById('tree-view');

        const treeRect = svgContainer.getBoundingClientRect();
        const isTreeOutsideContainer = treeRect.right > svgContainer.clientWidth || treeRect.bottom > svgContainer.clientHeight;
        const scrollTopValue = (treeRect.height) / 2;

        if (isTreeOutsideContainer) {
            treeContainer.style.width = treeRect.width < treeView.clientWidth ? treeView.clientWidth + 'px' : (treeRect.width * 2) + 'px';
            treeContainer.style.height = (treeRect.height * 2) + 'px';
            treeContainer.style.overflow = 'auto';
            const scrollLeftValue = (treeContainer.scrollWidth - treeRect.width) / 3;

            let transformScale = this.getTransformScale(treeContainer);

            if (treeRect.width > 2000) {
                treeView.scrollTop = scrollTopValue;
                let trans = [50, -250];
                this.visRef.attr('transform', 'translate(' + trans + ') scale(' + transformScale + ')');
            } else {
                let trans;
                if (treeRect.width > 1000) {
                    trans = [50, -300];
                } else {
                    trans = [100, -200];
                }
                this.visRef.attr('transform', 'translate(' + trans + ') scale(' + transformScale + ')');
                if (treeContainer.clientWidth > 2000) {
                }
                if (treeContainer.clientHeight > 1200) {
                    treeView.scrollTop = scrollTopValue;
                }
            }
            this.zoomRef = transformScale;
            this.scaleForReset = transformScale;
        }
        if (this.latestNodeId) {
            this.takeMeToLatestUpdatedNode(nodes)
        }
        else {
            const mainNode = nodes.find(node => node._id === this.mainID);

            const mainNodePosition = treeContainer.querySelector(`g[id=node-${mainNode.id}]`).getBoundingClientRect();

            const gTransform = (document.querySelector('#rootg') as SVGGElement).getCTM();

            const topValue = Math.abs(mainNodePosition.y) + Math.abs(gTransform.f);

            treeView.scrollLeft = (Math.abs(mainNodePosition.x)) - (Math.abs(gTransform.e) - treeView.clientWidth / 2);

            if (mainNode.x > 400) {
                treeView.scrollTop = topValue;

                treeView.scrollLeft = Math.abs(mainNodePosition.x) - 200;
            }
        }

        this.svgHeight = treeContainer.clientHeight;
        this.svgWidth = treeContainer.clientWidth;

        this.svgHeightForReset = treeContainer.clientHeight;
        this.svgWidthForReset = treeContainer.clientWidth;
    }

    takeMeToLatestUpdatedNode(nodes) {
        if (this.latestNodeId) {
            const latestNodeItem = this.getLatestItem(nodes, this.latestNodeId);
            const scrollX = latestNodeItem[0].y - 1000;
            const scrollY = latestNodeItem[0].x - 400;

            const container = document.getElementById('tree-view');
            container.scrollTo(scrollX, scrollY);

        }
    }

    positionChoices() {
        const dialogRef = this.dialog.open(PositionChoicesComponent, {
            width: '95%',
            maxWidth: '1300px',
            panelClass: 'position-choices',
            height: 'auto',
            data: {
                video: this.createInteractiveVideoObj.currentObj,
                nodes: this.createInteractiveVideoObj.currentObj.children
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.currentVideoData.isEdit = true;
                this.refreshInteractiveVideo();

                setTimeout(() => {
                    location.reload();
                }, 500);
            }
        })
    }

    reorderChoices(s) {
        this.isReorderChoice = s;
    }

    saveReorderChoices() {
        const object = {
            videoId: this.mainID,
            parentId: this.createChoiceObj.child[0].parentId,
            childrenOrder: this.createChoiceObj.child.map(x => x._id)
        }
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.reorderNodeChildren(object).subscribe((res: any) => {
            this.latestNodeId = res.data[0].parentId;
            this.callChild1();
            this.reorderChoices(false);
            this.createInteractiveVideoObj.spinner = false;
        });
    }

    saveStoryBoardChildNodes(currentObj?) {
        const nodes = [];
        let i = 0;
        for (const childElement of this.createChoiceObj.child) {
            ++i;
            if (childElement._id === '') {
                nodes.push({
                    level: currentObj ? currentObj?.level + 1 : this.createInteractiveVideoObj.currentObj.level + 1,
                    parentId: currentObj ? currentObj._id : this.createInteractiveVideoObj.currentObj._id,
                    mainId: this.mainID,
                    name: childElement.name ? childElement.name : 'Child ' + i
                })
            }
        }


        if (nodes.length) {
            this.createEmptyNodes(nodes, 'Object');
        } else {
            this.refreshInteractiveVideo();
        }
    }

    createEmptyNodes(obj?: any, type: string = 'notObject', emptyNodeData?) {
        this.createInteractiveVideoObj.spinner = true;
        if (!obj) {
            obj = [{
                level: 0,
                parentId: null,
                name: 'Parent Node',
                Tag: 'storyboard'
            }];
        }
        this.urlService.createEmptyNodes(obj).subscribe((success) => {
            this.createInteractiveVideoObj.mainVideo = success.data;
            this.isOpenCreateVideo = false;
            // $('#tree-Modal').modal('hide');
            $('#tree-node-modal').modal('hide');
            this.createInteractiveVideoObj.spinner = false;
            this.createInteractiveVideoObj.selectedItem = {};
            this.createInteractiveVideoObj.selectedVideoCheck = false;
            if (!this.mainID) {
                this.mainID = success.data._id;
                const obj1: any = {
                    id: this.createInteractiveVideoObj.mainVideo._id,
                    type: this.createInteractiveVideoObj.mainVideo.type,
                    name: this.createInteractiveVideoObj.mainVideo.name,
                    URL: this.createInteractiveVideoObj.mainVideo.URL,
                    Title: this.createInteractiveVideoObj.mainVideo.Title,
                    Description: this.createInteractiveVideoObj.mainVideo.Description,
                    Tag: 'storyboard',
                    Categories: this.createInteractiveVideoObj.mainVideo.Categories,
                    time: this.createInteractiveVideoObj.mainVideo.time,
                    parentId: this.createInteractiveVideoObj.mainVideo.parentId,
                    poster: this.createInteractiveVideoObj.mainVideo.poster,
                    videoID: this.createInteractiveVideoObj.mainVideo.videoID,
                };
                this.urlService.updateInteractiveVideo(obj1).subscribe();
                this.router.navigate(['ai-video-creator'], {
                    queryParams: { video: success.data._id },
                });
            }
            this.localStorageService.removeItem('freeTemp');
            if (obj[0].parentId) {
                setTimeout(() => {
                    location.reload();
                }, 1000);
            }

            if (type === 'Object') {
                this.assistEventsSubject.next({ type: this.msgEvent.addMoreChoices });
            } else {
                this.assistEventsSubject.next({ type: this.msgEvent.addFirstNode, data: success.data.data });
                if (this.mainID && !obj[0].parentId) {
                    this.router.navigate(['ai-video-creator'], {
                        queryParams: { video: success.data._id },
                    });
                }
            }
        },
            (error) => {
                this.createInteractiveVideoObj.spinner = false;
            });
        this.createInteractiveVideoObj.step = 'three';
    }

    clickedItem(d) {
        if (!this.createInteractiveVideoObj) {
            this.createInteractiveVideoObj = {};
        }

        if (!this.createInteractiveVideoObj.currentObj) {
            this.createInteractiveVideoObj.currentObj = {};
        }

        if (this.createInteractiveVideoObj.finalObj.length) {
            this.createInteractiveVideoObj.connectorNodes = this.createInteractiveVideoObj.finalObj.filter((x) => x.isConnectorNode === true && d._id !== x._id);
        }
        if (this.isAddWatchPath) {
            return this.selectWatchPath(d);
        }
        this.createInteractiveVideoObj.spinner = false;
        if (this.isAiContentCreatorassist && !this.isVisibleNodeVideo) {
            this.assistEventsSubject.next({
                type: this.msgEvent.nodeSelected,
                data: d
            });
            this.isVisibleNodeVideo = false;
            return;
        }
        this.isReorderChoice = false;


        var str = d?.name ? d?.name : d?.Title;
        this.videoName = str?.split('20')[0];
        this.createChoiceObj.child = [];

        setTimeout(() => {
            this.value = 0;
            this.highValue = d?.duration;
            this.options = {
                floor: 0,
                ceil: d?.duration,
            };
        }, 2000);

        if (d?.URL) {
            if (d?.URL.includes('.mp4')) {
                this.currentType = 'video/mp4';
            } else {
                this.currentType = 'application/x-mpegURL';
            }
        }

        const videoTitleElement = document.getElementById('videoName');
        videoTitleElement.style.fontSize = '1.5rem';
        this.createInteractiveVideoObj.currentObj = d;
        if (this.createInteractiveVideoObj.currentObj) {
            this.createInteractiveVideoObj.currentObj.open = true;
        }


        for (const i of this.createInteractiveVideoObj.finalObj) {

            if (d?._id === i.parentId) {
                if (i.parentIds) {
                    if (Array.isArray(i.parentIds) && !i.parentIds.includes(d?._id)) {
                        i.URLName = i.name;
                        this.name = i.name;
                        this.createChoiceObj.child.push(i);
                    }
                } else {
                    i.URLName = i.name;
                    this.name = i.name;
                    this.createChoiceObj.child.push(i);
                }
            }
        }

        for (const i of this.createInteractiveVideoObj.connectorNodes) {

            if (i.parentIds && i.parentIds.includes(d?._id)) {
                i.URLName = i.name;
                this.name = i.name;
                this.createChoiceObj.child.push(i);
            }
        }
        if (d?.name === 'Add Video' && !d?.videoID) {
            this.replaceVideo();
        } else {
            if (!this.createInteractiveVideoObj.currentObj?.time) {
                this.createInteractiveVideoObj.currentObj && (this.createInteractiveVideoObj.currentObj.time = 0);
            }
            const minutes = Math.floor(
                this.createInteractiveVideoObj.currentObj?.time / 60
            );
            const seconds = Math.floor(
                this.createInteractiveVideoObj.currentObj?.time - minutes * 60
            )
            let m: number | string;
            let s: number | string;
            if (minutes > 10) {
                m = minutes;
            } else {
                m = `0${minutes}`;
            }
            if (seconds > 10) {
                s = seconds;
            } else {
                s = `0${seconds}`;
            }
            this.createInteractiveVideoObj.currentObj && (this.createInteractiveVideoObj.currentObj.timeValue = `000:${m}:${s}`);
            this.currentVideoData = this.createInteractiveVideoObj.currentObj;

            if (!this.isChatWindowVisible) {
                // $('#tree-Modal').modal('show');
                $('#tree-node-modal').modal('show');
            }
            // $('#tree-Modal').on('hidden.bs.modal', () => {
            //     this.closeInteractiveVideo();
            // });
            $('#tree-node-modal').on('hidden.bs.modal', () => {
                this.closeInteractiveVideo();
            });
            const popData = this.dashboardService.getHelpPopOver();
            if (!popData?.popTmpAddChoice) {
                setTimeout(() => {
                    if (this.popTmpAddChoiceEle) {
                        this.popTmpAddChoiceEle.show();
                    }
                }, 500);
            }
        }

        if (this.createInteractiveVideoObj.currentObj?.timer) {
            const timer = this.createInteractiveVideoObj.currentObj?.timer;
            this.hh = timer?.hh ? timer.hh : '00';
            this.mm = timer?.mm ? timer.mm : '00';
            this.ss = timer?.ss ? timer.ss : '00';
            if (this.createInteractiveVideoObj.currentObj?.timer?.timerUpMessage) {
                this.userMessage = this.createInteractiveVideoObj.currentObj?.timer?.timerUpMessage;
            }
        }
        if (d?.isOpenPopUp) {
            this.isVisibleNodeVideo = false;
        } else {
            this.isVisibleNodeVideo = true;
        }
    }

    playBothAudioAndVideo() {
        if (this.videoPlayer?.nativeElement && this.playAudio?.nativeElement) {
            this.videoPlayer.nativeElement.currentTime = 0;
            this.playAudio.nativeElement.currentTime = 0;

            this.videoPlayer?.nativeElement.play();
            this.playAudio?.nativeElement.play();
        }
    }

    closeInteractiveVideo() {
        // $('#tree-Modal').modal('hide');
        $('#tree-node-modal').modal('hide');
        this.createChoiceObj.child = [];
        this.createInteractiveVideoObj.currentObj = {};
    }

    replaceVideo(isAI: boolean = false) {
        // $('#tree-Modal').modal('hide');
        $('#tree-node-modal').modal('hide');
        this.openVideoCreateModel('videoTemplate', isAI);
        this.createInteractiveVideoObj.currentObj.open = false;
        this.OpenPopupAll = true;
    }

    openVideoCreateModel(type: string, isAI: boolean = false) {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            width: '95%',
            maxWidth: '1300px',
            panelClass: 'my-dialog',
            height: 'auto',
            minHeight: '500px',
            data: {
                isAddChoice: true
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.createInteractiveVideoObj.selectedItem = result;
                if (result.type === 'application/pdf') {
                    this.createInteractiveVideoObj.selectedItem.poster = "assets/images/pdf-img.png"
                }
                if (type === 'videoTemplate') {
                    this.replaceVideoData();
                    if (isAI) {
                        this.assistEventsSubject.next({ type: this.msgEvent.close, data: result });
                    }
                } else {
                    this.createInteractiveVideo();
                }
            }
        });
    }

    getConnectorNodeData(video, allObj, selectedVideo?) {
        if (video && video?.parentId) {
            let videoObj = this.createInteractiveVideoObj.finalObj.find((i) => i._id === video.parentId);
            let parentOfSelectedVideo = videoObj?.parent;
            let index = allObj.indexOf(parentOfSelectedVideo);
            if (index >= 0) {
                allObj.splice(index, 1);
            }
            if (selectedVideo && selectedVideo.parentId) {
                let selectedVideoObj = this.createInteractiveVideoObj.finalObj.find((i) => i._id === selectedVideo.parentId);
                if (selectedVideoObj.parentIds && selectedVideoObj.parentIds.length) {
                    for (const id of selectedVideoObj.parentIds) {
                        let parentObj = this.createInteractiveVideoObj.finalObj.find((i) => i._id === id);
                        let index = allObj.indexOf(parentObj);
                        if (index >= 0) {
                            allObj.splice(index, 1);
                        }
                    }
                }
            }
            if (parentOfSelectedVideo?.parent) {
                this.getConnectorNodeData({ parentId: parentOfSelectedVideo._id }, allObj);
            }
        }
    }

    openVideoListModal(index: number, item?: any) {
        let data = [];

        if (this.createInteractiveVideoObj.finalObj.length) {
            if (item) {
                data = this.createInteractiveVideoObj.finalObj.filter((i) => i._id !== item.parentId && i.parentId !== item.parentId && i.type == 'child')
                this.getConnectorNodeData(item, data, item);
            }

        }
        if (item?.URL) {
            return;
        }

        if (item?._id) {
            this.createInteractiveVideoObj.currentNodeOption = item;
        }

        const dialogRef = this.dialog.open(CreateVideoComponent, {
            data: {
                treeNodes: this.createInteractiveVideoObj.endingNodes,
                connectorNodes: this.createInteractiveVideoObj.connectorNodes,
                nodes: data && data.length ? data : []
            },
            minWidth: '50vw'
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {

                this.createChoiceObj.child[index] = result;
                this.createChoiceObj.child[index].poster = result.poster ? result.poster : result.thumbnail;
                this.createChoiceObj.child[index].URLName = result.name;

                this.createChoiceObj.child[index].videoID = result.videoID;

                if (result.type === 'application/pdf') {
                    this.createChoiceObj.child[index].poster = "assets/images/pdf-img.png";
                }

                this.selectionChanged(result, index);

            }
        });
    }

    selectionChanged(e: any, index) {

        this.createInteractiveVideoObj.spinner = true;
        if (!this.createInteractiveVideoObj.currentNodeOption) {
            const objFinal: any = {
                type: 'child',
                name: e.name,
                URL: e.URL,
                time: 0,
                poster: e.poster ? e.poster : e.thumbnail,
                videoID: e._id,
                subtitleUrl: e.subtitleUrl,
                isEndingNode: e.type === 'url'
            };

            if (e?.isEndingNode) {
                objFinal.videoID = e.videoID;
                objFinal.endingNodeId = e._id;
            };
            if (e?.isConnectorNode) {

                objFinal._id = e._id;
                objFinal.isConnectorNode = e.isConnectorNode;
                objFinal.parentIds = e.parentIds;
                objFinal.videoID = e.videoID;

                objFinal.oldParentId = e.parentId;

                objFinal.parentId = this.createInteractiveVideoObj.currentObj._id;

            } else {
                objFinal.parentId = this.createInteractiveVideoObj.currentObj._id
            };

            this.urlService.createInteractiveVideo(objFinal).subscribe(
                (success) => {

                    this.createChoiceObj.child[index] = success.data?.data;


                    this.createChoiceObj.child[index].URLName = this.createChoiceObj.child[index].name
                    this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
                    if (this.createInteractiveVideoObj.currentObj.children === undefined) {
                        this.createInteractiveVideoObj.currentObj.children = [];
                    }
                    const childObjFinal = success.data?.data;
                    this.createInteractiveVideoObj.currentObj.children.push(childObjFinal);
                    this.callChild1();
                    this.toastr.info('Created Successfully!');
                },
                (error) => {
                    this.createInteractiveVideoObj.spinner = false;
                }
            );
        } else {
            // REPLACING A VIDEO
            let subtitleUrl = '';
            if (this.createInteractiveVideoObj.selectedItem.subtitleUrl != undefined)
                subtitleUrl = e.subtitleUrl;
            const objFinal = {
                id: this.createInteractiveVideoObj.currentNodeOption._id,
                type: this.createInteractiveVideoObj.currentNodeOption.type,
                name: e.name ? e.name : this.createInteractiveVideoObj.currentNodeOption.name,
                URL: e.URL,
                poster: e.poster ? e.poster : e.thumbnail,
                Title: this.createInteractiveVideoObj.currentNodeOption.Title,
                Description: this.createInteractiveVideoObj.currentNodeOption.Description,
                // WC 07272022
                Tag: this.createInteractiveVideoObj.currentNodeOption.Tag,
                // WC 07272022
                Categories: this.createInteractiveVideoObj.currentNodeOption.Categories,
                time: this.createInteractiveVideoObj.currentNodeOption.time,
                parentId: this.createInteractiveVideoObj.currentNodeOption.parentId,
                videoID: this.createInteractiveVideoObj.currentNodeOption.videoID || e._id,
                // WC 08092022
                subtitleUrl,
                // WC 08092022
            };

            this.urlService.updateInteractiveVideo(objFinal).subscribe(
                (success) => {
                    // this.createChoiceObj.child[index] = success.data;
                    // this.createChoiceObj.child[index].URLName = this.createChoiceObj.child[index].name
                    this.createInteractiveVideoObj.spinner = false;
                    this.createInteractiveVideoObj.currentNodeOption = null;
                    this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
                    this.callChild1();
                    this.toastr.info('Updated Successfully!');
                },
                (error) => {
                    this.createInteractiveVideoObj.spinner = false;
                }
            );
        }
    }

    createInteractiveVideo() {
        if (typeof this.createInteractiveVideoObj.selectedItem !== 'object') {
            this.toastr.warning('Please select an initial video!');
            return;
        }
        this.createInteractiveVideoObj.spinner = true;
        const obj: any = {
            type: 'main',
            poster: this.createInteractiveVideoObj.selectedItem.poster ?
                this.createInteractiveVideoObj.selectedItem.poster : this.createInteractiveVideoObj.selectedItem.thumbnail,
            name: this.createInteractiveVideoObj.selectedItem.name,
            URL: this.createInteractiveVideoObj.selectedItem.URL,
            Thumbnail: this.createInteractiveVideoObj.selectedItem.Thumbnail,
            Title: '',
            Description: '',
            // WC 07272022 - Add Tagging
            Tag: '',
            // WC 07272022
            Categories: [],
            time: 0,
            parentId: null,
            videoID: this.createInteractiveVideoObj.selectedItem._id,
            // WC 08092022
            subtitleUrl: this.createInteractiveVideoObj.selectedItem.subtitleUrl,
            // WC 08092022
        };
        if (this.createInteractiveVideoObj.selectedItem.originalName) {
            obj.name = this.createInteractiveVideoObj.selectedItem.originalName;
        }
        // Use original name if it exist in creating interating video
        this.urlService.createInteractiveVideo(obj).subscribe((success) => {
            this.closeModal();
            this.isOpenCreateVideo = false;
            // $('#upload-vid-Modal').modal('hide');
            this.createInteractiveVideoObj.spinner = false;
            this.createInteractiveVideoObj.selectedItem = {};
            this.createInteractiveVideoObj.selectedVideoCheck = false;
            this.router.navigate(['ai-video-creator'], {
                queryParams: { video: success.data.data._id },
            });
            this.localStorageService.removeItem('freeTemp');
            this.initialFunction('');
            // sync user data
            this.dashboardService.onGetUserData();
        },
            (error) => {
                this.createInteractiveVideoObj.spinner = false;
            }
        );
        this.createInteractiveVideoObj.step = 'three';
    }

    getVideoPathWays() {
        this.urlService.getPathway({ videoId: this.mainID })
            .subscribe((res) => {
                this.createInteractiveVideoObj.pathways = res.data;
                for (const watchPath of this.createInteractiveVideoObj.pathways) {
                    watchPath.startNode = this.createInteractiveVideoObj.finalObj.find(x => x._id === watchPath.startNodeId);
                    watchPath.endNode = this.createInteractiveVideoObj.finalObj.find(x => x._id === watchPath.endNodeId);
                }
                setTimeout(() => {
                    this.setHorizontalScrollbarPosition();
                }, 100);
            });
    }

    replaceVideoData() {
        let obj: any = {};
        if (this.createInteractiveVideoObj.currentObj.name === 'Add Video') {
            obj = {
                // ADDING A VIDEO
                id: this.createInteractiveVideoObj.currentObj._id,
                type: this.createInteractiveVideoObj.currentObj.type,
                name: this.createInteractiveVideoObj.selectedItem.name,
                URL: this.createInteractiveVideoObj.selectedItem.URL,
                poster: this.createInteractiveVideoObj.selectedItem.poster ?
                    this.createInteractiveVideoObj.selectedItem.poster : this.createInteractiveVideoObj.selectedItem.thumbnail,
                Title: this.createInteractiveVideoObj.selectedItem.Title,
                Description: this.createInteractiveVideoObj.currentObj.Description,
                // WC 07272022
                Tag: this.createInteractiveVideoObj.currentObj.Tag,
                // WC 07272022
                Categories: this.createInteractiveVideoObj.currentObj.Categories,
                time: this.createInteractiveVideoObj.currentObj.time,
                parentId: this.createInteractiveVideoObj.currentObj.parentId,
                videoID: this.createInteractiveVideoObj.selectedItem._id,
                // WC 08092022
                subtitleUrl: this.createInteractiveVideoObj.selectedItem.subtitleUrl,
                // WC 08092022
            };
        } else {
            // REPLACING A VIDEO
            let subtitleUrl = '';
            if (this.createInteractiveVideoObj.selectedItem.subtitleUrl != undefined)
                subtitleUrl = this.createInteractiveVideoObj.selectedItem.subtitleUrl;
            obj = {
                id: this.currentVideoData._id,
                type: this.currentVideoData.type,
                // name: this.currentVideoData.name,
                name: this.createInteractiveVideoObj.selectedItem.name,
                URL: this.createInteractiveVideoObj.selectedItem.URL,
                poster: this.createInteractiveVideoObj.selectedItem.poster ?
                    this.createInteractiveVideoObj.selectedItem.poster : this.createInteractiveVideoObj.selectedItem.thumbnail,
                Title: this.currentVideoData.Title,
                Description: this.currentVideoData.Description,
                // WC 07272022
                Tag: this.currentVideoData.Tag,
                // WC 07272022
                Categories: this.currentVideoData.Categories,
                time: this.currentVideoData.time,
                parentId: this.currentVideoData.parentId,
                videoID: this.createInteractiveVideoObj.selectedItem._id,
                // WC 08092022
                subtitleUrl,
                // WC 08092022
            };
        }
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.updateInteractiveVideo(obj).subscribe((success) => {
            let isCheckUrl = false;
            if (success.data.URL) {
                isCheckUrl = this.utilityService.checkIsVideoUrl(success.data.URL);
            }
            this.createInteractiveVideoObj.currentObj.isUrl = isCheckUrl;
            this.createInteractiveVideoObj.currentObj.poster1 = '';
            this.createInteractiveVideoObj.currentObj.poster =
                this.createInteractiveVideoObj.selectedItem.poster ? this.createInteractiveVideoObj.selectedItem.poster : this.createInteractiveVideoObj.selectedItem.thumbnail;
            this.createInteractiveVideoObj.currentObj.imvd = 'ncs';
            this.createInteractiveVideoObj.currentObj.basck = 'videochart';
            this.createInteractiveVideoObj.currentObj.URL = this.createInteractiveVideoObj.selectedItem.URL;
            this.createInteractiveVideoObj.currentObj.videoID = this.createInteractiveVideoObj.selectedItem._id;
            if (this.createInteractiveVideoObj.currentObj.name === 'Add Video') {
                this.createInteractiveVideoObj.currentObj.name = this.createInteractiveVideoObj.selectedItem.name;
                this.createInteractiveVideoObj.currentObj.Title = '';
            }
            this.createInteractiveVideoObj.currentObj.open = true;
            this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
            // this.callChild1();
            this.currentVideoData.isEdit = true;
            this.getMainVideoInfo();
            this.toastr.info('Updated Successfully!');
            this.OpenPopupAll = false;
            this.closeModal();
        },
            (error) => {
                this.createInteractiveVideoObj.spinner = false;
            }
        );
    }

    setHorizontalScrollbarPosition() {
        const footer = document.getElementById('vid-publish-footer');
        const pathwayWrapper = document.getElementById('container-fluid');
        const treeView = document.getElementById('tree-view-chart-sec');
        let footerHeight = 263;
        if (footer) {
            footerHeight = this.createInteractiveVideoObj.pathways.length ? footer.offsetHeight + 176 : footer.offsetHeight + 161;
        }

        (treeView as any).style.height = `calc(100% - ${footerHeight + 'px'})`;

        if (pathwayWrapper?.offsetHeight > 60) {
            (treeView as any).style.height = `calc(100% - ${footerHeight + (pathwayWrapper.offsetHeight - 60) + 'px'})`;

        }
    }

    closeModal() {
        this.createInteractiveVideoObj.selectedItem = {};
        $('video').trigger('pause');
    }

    createNestedArray(list) {
        this.createInteractiveVideoObj.conclusionLinks = [];
        // list[8].endingNodeId = list[1]._id;
        const map = {};
        let node;
        const roots = [];
        let i;
        for (i = 0; i < list.length; i += 1) {
            map[list[i]._id] = i; // initialize the map
            list[i].children = []; // initialize the children

            //WC 12/09/2023 --- To hold connectorChildren
            list[i].connectorChildren = [];
        }
        for (i = 0; i < list.length; i += 1) {
            node = list[i];
            if (node.endingNodeId && list[map[node.endingNodeId]]) {
                if (!list[map[node.endingNodeId]].connection) {
                    list[map[node.endingNodeId]].connection = 1;
                } else {
                    list[map[node.endingNodeId]].connection += 1;
                }
                if (list[map[node.endingNodeId]].isEndingNode) {
                    if (!list[map[node.parentId]].endNodes) {
                        list[map[node.parentId]].endNodes = [];
                    }
                    list[map[node.parentId]].endNodes.push(list[map[node.endingNodeId]]);
                    // add direct links between endnodes
                    this.createInteractiveVideoObj.conclusionLinks.push({
                        source: list[map[node.endingNodeId]],
                        target: list[map[node.parentId]],
                        type: 'conclusion'
                    });
                    continue;
                }
            }

            if (node.isConnectorNode && node.parentIds && node.parentIds.length > 0) {
                for (const parentId of node.parentIds) {
                    if (list[map[parentId]]) {
                        this.createInteractiveVideoObj.conclusionLinks.push({
                            source: list[map[parentId]],
                            target: node,
                            type: 'conclusion'
                        });
                    }
                    continue;
                }
            }
            if (node.parentId !== '0') {
                // if you have dangling branches check that map[node.parentId] exists
                if (list[map[node.parentId]])
                    list[map[node.parentId]].children.push(node);

                //WC 12/09/2023 --- To store connectorChildren
                if (node.parentIds != undefined) {
                    for (const parentId of node.parentIds) {
                        //WC 12/09/23 -- For each connector child, there will be one real parent where the connector child will be registered as children instead of connector children
                        if (list[map[parentId]]?.children && list[map[parentId]].children.length > 0) {

                            //If the connector child is registered as a children then don't add it to the connector children
                            const existingChild = list[map[parentId]].children.filter(x => { x._id == node._id });

                            if (!existingChild)
                                list[map[parentId]].connectorChildren.push(node);
                        } else
                            list[map[parentId]]?.connectorChildren.push(node);
                    }
                }
            } else {
                roots.push(node);
            }
        }

        const clonedArr = _.cloneDeep(roots);
        this.clonedArr = clonedArr;
        this.utilityService.updateConnectorChildren(clonedArr[0]);

        // const allPaths = this.utilityService.listPaths(clonedArr[0]);

        return roots;
    }

    getDefaultLangTranscription(video) {
        if (video.subtitleUrl) {
            const defaultLang = this.utilityService.getTranscriptLang(video.subtitleUrl)
            const langCode = defaultLang.code.split('-')[0];
            let defaultSubTitle = {
                subtitle: {
                    code: langCode,
                    disabled: false,
                    language: defaultLang.language
                }
            }
            defaultSubTitle[langCode] = video.subtitleUrl;
            return defaultSubTitle
        }
    }

    onAssistAIContentCreator(e) {
        // console.log("onAssistAIContentCreator - e = ", e);

        if (e.type === this.msgEvent.createContentTreeBasedOnScenario) {
            if (e.data && !e.data.isDocuments) {
                this.createContentTreeBasedOnScenario(e.data);
            } else {
                this.createContentTreeBasedOnDocumentScenario(e.data);
            }
            setTimeout(() => {
                this.assistEventsSubject.next({ type: this.msgEvent.contentTreeCreated, data: this.mainID });
            }, 1000);
        } else if (e.type === this.msgEvent.close) {
            this.isAiContentCreatorassist = null;
            this.initialFunction('');
            this.localStorageService.removeItem('aiContentCreatorassist');
            this.localStorageService.removeItem('aiVideoCreatorFlow');
            location.reload();
        } else if (e.type === this.msgEvent.scriptBeingCreated) {
            this.createScriptForStoryBoardNodes(this.createInteractiveVideoObj.currentObj);
        } else if (e.type === this.msgEvent.getTheVideoForAllNodes) {
            this.isVisibleNodeVideo = e?.data ? e.data : false;
            this.getVideoForAllNodes(this.createInteractiveVideoObj.currentObj);
        } else if (e.type === this.msgEvent.saveStoryBoardName) {
            if (this.createInteractiveVideoObj.mainVideo) {
                this.createInteractiveVideoObj.mainVideo.Title = e?.data.title;
            }
            this.addVideoTitle(true);
        } else if (e.type === this.msgEvent.updateTheScenario) {
            this.openDialogForDisplayEachChoice();
        } else if (e.type === this.msgEvent.addVideoForEmptyNode) {
            if (e.data?.currentObj && !e.data?.isAIContentTree) {
                this.currentVideoData = e.data?.currentObj;
                this.isChatWindowVisible = true;
                this.openPreviewUrl('', true);
            }
        } else if (e.type === this.msgEvent.updateVideoOnNode) {
            this.isVisibleNodeVideo = e?.data ? e.data : false;
        } else if (e.type === this.msgEvent.setVoiceModelToNode) {
            this.isVisibleNodeVideo = false;
            e.data.videoId = this.mainID;
            this.setVoiceModel(e.data);
        } else if (e.type === this.msgEvent.iAmGoodSaveTheTree) {
            this.createAIVideo(e?.data);
        } else if (e.type === this.msgEvent.mergingVideoAndAudio) {
            this.createInteractiveVideoObj.spinner = true;
            if (e.data.currentNode?.aiGeneratedScript) {
                this.generateTTSForSelectedNode(e.data);
            } else {
                this.isVisibleNodeVideo = true;
                e.data.currentNode.isOpenPopUp = true;
                this.clickedItem(e.data?.currentNode);
            }
        }
    }

    generateTTSForSelectedNode(data) {
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.generateTTS(data.payload).subscribe((res: any) => {
            const url = res.data.audioFileLocation;
            data.currentNode.aiAudioFile = url;
            this.isVisibleNodeVideo = true;
            data.currentNode.isOpenPopUp = true;
            this.clickedItem(data.currentNode);
            this.createInteractiveVideoObj.spinner = false;
        }, (err) => {
            this.createInteractiveVideoObj.spinner = false;
        })
    }

    createAIVideo(currentObj) {
        let payload = {
            videoId: this.mainID
        }
        this.createInteractiveVideoObj.spinner = true;

        if (currentObj.isDocumentUpload) {
            this.urlService.createAIVideoForDocuments(payload).subscribe((res) => {
                this.createInteractiveVideoObj.currentObj = currentObj;
                // this.callChild('');
                this.assistEventsSubject.next({ type: this.msgEvent.videoSelectedForCombine, data: res.data.result });
                this.createInteractiveVideoObj.spinner = false;
            }, (err) => {
                this.toastr.error('Error!');
                this.createInteractiveVideoObj.spinner = false;
            })
        } else {
            this.urlService.createAIVideo(payload).subscribe((res) => {
                this.createInteractiveVideoObj.currentObj = currentObj;
                // this.callChild('');
                this.assistEventsSubject.next({ type: this.msgEvent.videoSelectedForCombine, data: res.data.result });
                this.createInteractiveVideoObj.spinner = false;
            }, (err) => {
                this.toastr.error('Error!');
                this.createInteractiveVideoObj.spinner = false;
            })
        }
    }

    setVoiceModel(payload) {
        this.urlService.setVoiceModel(payload).subscribe((res) => {

            if (this.createInteractiveVideoObj.currentObj._id == undefined)
                this.createInteractiveVideoObj.currentObj._id = payload.videoId;

            this.callChild('');
        }, (err) => {
        })
    }

    getDuration(e) {
        this.createInteractiveVideoObj.currentObj.duration = e.target.duration;
        let hasChilren = false;
        if (
            this.createInteractiveVideoObj.currentObj.children == null ||
            this.createInteractiveVideoObj.currentObj.children == undefined
        ) {
            hasChilren = false;
        } else {
            hasChilren = this.createInteractiveVideoObj.currentObj.children.length > 0;
        }
        this.placeMarker(
            hasChilren,
            this.createInteractiveVideoObj.currentObj.time,
            'video-all',
            e.target.duration
        );
    }

    placeMarker(haveChildren, markerTime, id, duration) {
        const that = this;

        if (!haveChildren) {
            $('.marker').remove();
        } else {
            const width = $('#' + id).width();
            const precentageOfMarker = (markerTime / duration) * 100;
            $('.marker').remove();
            $(
                '#' + id + ' .vjs-progress-holder.vjs-slider.vjs-slider-horizontal'
            ).append(
                '<div class="marker" style="left:' + precentageOfMarker + '%"></div>'
            );

            // $('#'+id).parent().append();
        }
    }

    openPreviewUrl(url: string, isAI: boolean = false) {
        if (url) {
            window.open(url, '_blank');
            if (isAI) {
                this.assistEventsSubject.next({ type: this.msgEvent.close, data: '' });
            }
        } else {
            this.replaceVideo(isAI);
        }
    }

    openDialogForDisplayEachChoice() {
        if (this.createInteractiveVideoObj.finalObj && this.createInteractiveVideoObj.finalObj.length > 0) {
            $('#preview-choices-modal').modal('show');
        }
        this.assistEventsSubject.next({ type: this.msgEvent.updateTheScenario });
    }

    addVideoTitle(isAI: boolean = false) {
        const obj: any = {
            id: this.createInteractiveVideoObj.mainVideo._id,
            type: this.createInteractiveVideoObj.mainVideo.type,
            name: this.createInteractiveVideoObj.mainVideo.name,
            URL: this.createInteractiveVideoObj.mainVideo.URL,
            Title: this.createInteractiveVideoObj.mainVideo.Title,
            Description: this.createInteractiveVideoObj.mainVideo.Description,
            // WC 07272022 - Add tagging
            Tag: this.createInteractiveVideoObj.mainVideo.Tag,
            // WC 07282022
            Categories: this.createInteractiveVideoObj.mainVideo.Categories,
            time: this.createInteractiveVideoObj.mainVideo.time,
            parentId: this.createInteractiveVideoObj.mainVideo.parentId,
            poster: this.createInteractiveVideoObj.mainVideo.poster,
            videoID: this.createInteractiveVideoObj.mainVideo.videoID,
        };
        this.urlService.updateInteractiveVideo(obj).subscribe((success) => {
            if (isAI) {
                this.assistEventsSubject.next({
                    type: this.msgEvent.saveStoryBoardName,
                    data: this.createInteractiveVideoObj.mainVideo.Title
                })
            }
        });
    }

    getVideoForAllNodes(obj) {
        this.createInteractiveVideoObj.spinner = true;

        this.urlService.getVideosForStoryBoard(this.mainID).subscribe((res) => {
            this.mainID = res.data.videoId;
            this.createInteractiveVideoObj.spinner = false;
            this.isAIVideoCreatorLexie = null;

            this.getMainVideoInfo(() => {
                const videoGeneratedForAllNode = this.createInteractiveVideoObj.finalObj.every((i) => i?.aiVideoURL && i?.aiVideoURL !== '');
                this.assistEventsSubject.next({ type: this.msgEvent.videoAttachedToAllChoice, data: videoGeneratedForAllNode });
            });

        }, (err) => {
            this.createInteractiveVideoObj.spinner = false;
            this.assistEventsSubject.next({ type: this.msgEvent.videoAttachedToAllChoice });
        })
    }

    createScriptForStoryBoardNodes(obj) {
        this.createInteractiveVideoObj.spinner = true;

        this.urlService.getScriptsForStoryBoard(this.mainID).subscribe((res) => {
            // this.mainID = res.data?.videoId;
            this.createInteractiveVideoObj.spinner = false;
            this.isAIVideoCreatorLexie = null;

            this.getMainVideoInfo(() => {
                const scriptGeneratedForAllNode = this.createInteractiveVideoObj.finalObj.every((i) => i?.aiGeneratedScript && i?.aiGeneratedScript !== '');
                this.assistEventsSubject.next({ type: this.msgEvent.scriptBeingCreated, data: scriptGeneratedForAllNode });
            });
        }, (err) => {
            this.createInteractiveVideoObj.spinner = false;
            this.assistEventsSubject.next({ type: this.msgEvent.scriptBeingCreated });
        })
    }

    createContentTreeBasedOnScenario(payload) {
        // let payload = {
        //     "scenarios": scenario,
        //     "videoId": this.mainID
        // }

        // console.log("createContentTreeBasedOnScenario - payload = ", payload);

        this.urlService.createStoryBoardFromScenarios(payload).subscribe((res) => {
            //this.mainID = res.data.videoId;

            this.router.navigate(['ai-video-creator'], {
                queryParams: { video: res.data.videoId },
            });

        })
    }

    createContentTreeBasedOnDocumentScenario(payload) {
        // let payload = {
        //     "scenarios": scenario,
        //     "videoId": this.mainID
        // }

        console.log("in createContentTreeBasedOnScenario - payload = ", payload);

        this.urlService.createStoryBoardFromDocumentsScenarios(payload).subscribe((res) => {
            //this.mainID = res.data.videoId;

            this.router.navigate(['ai-video-creator'], {
                queryParams: { video: res.data.videoId },
            });

        })
    }

    headerSearchValues(value: string) {
        if (value) {
            const matchingItems = this.nodes.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1);
            const notMatchingItems = this.nodes.filter(item => item.name.toLowerCase().indexOf(value.toLowerCase()) === -1);
            matchingItems.forEach((i) => {
                const matchNodeItem = document.getElementById(`node-button-${i.id}`);
                matchNodeItem.style.fill = '#d3c1eb';
            });
            notMatchingItems.forEach((i) => {
                const notMatchNodeItem = document.getElementById(`node-button-${i.id}`);
                notMatchNodeItem.style.fill = null;
            });
        } else {
            this.nodes.forEach((i) => {
                const allNodeItem = document.getElementById(`node-button-${i.id}`);
                allNodeItem.style.fill = null;
            });
            const latestItem = this.getLatestItem(this.nodes, this.latestNodeId);
            const latestNodeItem = document.getElementById(`node-button-${latestItem[0].id}`);
            latestNodeItem.style.fill = '#d3c1eb';
        }
    }

    updateVideoTitle(name: any, child = false) {
        if (child) {
            this.childTitleChange$.next({ ...name });
            return;
        }
        const title = document.getElementById('videoName'); // 39
        let initialSize = 24;
        if (name.length > 39) {
            const size = name.length - 39;
            initialSize = 24 - size;
        }
        if (initialSize > 15) {
            title.style.fontSize = initialSize + 'px';
        } else {
            title.style.fontSize = '15px';
        }
        if (name.length > 2) {
            this.titleChange$.next(name);
        }
    }

    deleteChoice(selectedNode) {
        this.createInteractiveVideoObj.spinner = true;
        this.toastr.warning('Deleting a choice will also delete all the child choices!');
        if (selectedNode.type === 'main') {
            this.urlService
                .deleteBookMarkVideo(selectedNode._id)
                .subscribe((success) => {
                    this.toastr.success('Delete successfully!');
                    // $('#tree-Modal').modal('hide');
                    $('#tree-node-modal').modal('hide');
                    $('#deleteCurrentNodeModal').modal('hide');
                    this.createChoiceObj.child = [];
                    this.callChild1();
                    this.deleteCurrentNodeType = 1;
                }).add(() => {
                    this.createInteractiveVideoObj.spinner = false;
                });
        } else {
            const Payload = {
                videoId: selectedNode._id,
                mainId: selectedNode.parentId,
                isDeleteSingleNode: this.deleteCurrentNodeType === 1
            };

            this.urlService.deleteChildVideo(Payload).subscribe((success) => {
                // $('#tree-Modal').modal('hide');
                $('#tree-node-modal').modal('hide');
                $('#deleteCurrentNodeModal').modal('hide');
                this.createChoiceObj.child = [];
                this.latestNodeId = selectedNode.parentId;
                this.callChild1();

                if (!success.error)
                    this.toastr.info('Updated Successfully!');
                else
                    this.toastr.error(success.message);

                this.deleteCurrentNodeType = 1;
            }).add(() => {
                this.createInteractiveVideoObj.spinner = false;
            });
        }
    }

    ngAfterViewInit() {

        this.titleChange$
            .asObservable()
            .pipe(
                filter(Boolean),
                debounceTime(1500),
                distinctUntilChanged(),
                tap((event) => {
                    this.updateCurrentName(event);
                })
            )
            .subscribe();
        this.childTitleChange$
            .asObservable()
            .pipe(
                filter(Boolean),
                debounceTime(1500),
                distinctUntilChanged(),
                tap((event) => {
                    this.updateChildNodeName(event);
                })
            )
            .subscribe();
        this.duationChange$
            .asObservable()
            .pipe(
                filter(Boolean),
                debounceTime(1500),
                distinctUntilChanged(),
                tap((event) => {
                    this.updateCurrentTime(event);
                })
            )
            .subscribe();
    }

    updateCurrentTime(duration: any) {

        this.createInteractiveVideoObj.currentObj.time = duration;
        const obj: any = {
            id: this.createInteractiveVideoObj.currentObj._id,
            type: this.createInteractiveVideoObj.currentObj.type,
            poster: this.createInteractiveVideoObj.currentObj.poster,
            name: this.createInteractiveVideoObj.currentObj.name,
            URL: this.createInteractiveVideoObj.currentObj.URL,
            Title: this.createInteractiveVideoObj.currentObj.Title,
            Description: this.createInteractiveVideoObj.currentObj.Description,
            Tag: this.createInteractiveVideoObj.currentObj.Tag,
            Categories: this.createInteractiveVideoObj.currentObj.Categories,
            time: this.createInteractiveVideoObj.currentObj.time,
            parentId: this.createInteractiveVideoObj.currentObj.parentId,
            videoID: this.createInteractiveVideoObj.currentObj.videoID,
            subtitleUrl: this.createInteractiveVideoObj.currentObj.subtitleUrl,
        };
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.updateInteractiveVideo(obj).subscribe(
            (success) => {
                this.callChild1();
                this.toastr.info('Updated Successfully!');
            },
            (error) => {
                this.createInteractiveVideoObj.spinner = false;
                this.toastr.error('Update Failed!');
            }
        );
    }

    updateChildNodeName(i) {
        this.createInteractiveVideoObj.spinner = true;
        if (!i.name) {
            i.name = 'untitled'
        }
        const obj: any = {
            id: i._id,
            type: i.type,
            name: i.name,
            URL: i.URL,
            Title: i.Title,
            Description: i.Description,
            Tag: i.Tag,
            Categories: i.Categories,
            time: i.time,
            parentId: i.parentId,
            poster: i.poster,
            videoID: i.videoID,
            subtitleUrl: i.subtitleUrl,
        };

        this.urlService.updateInteractiveVideo(obj).subscribe(
            (success) => {
                this.latestNodeId = i._id;
                this.callChild1();
                this.toastr.info('Updated Successfully!');
            },
            (error) => {
                this.createInteractiveVideoObj.spinner = false;
            }
        );
    }

    addMoreChoices() {
        if (this.createChoiceObj.child.length > 3) {
            this.toastr.warning('Choices can not be more than 4!');
            return;
        }
        const i = this.createChoiceObj.child.length - 1;
        const name = 'Untitled ' + i;
        const obj: any = {
            name,
            URL: '',
            _id: '',
            parentId: this.createInteractiveVideoObj.currentObj._id,
        };
        this.createChoiceObj.child.push(obj);
    }

    updateCurrentName(name: any) {
        this.createInteractiveVideoObj.spinner = true;
        this.createInteractiveVideoObj.currentObj.name = name;
        if (this.createInteractiveVideoObj.currentObj.type === 'main') {
            this.createInteractiveVideoObj.mainVideo.name = name;
        }
        const obj: any = {
            id: this.createInteractiveVideoObj.currentObj._id,
            type: this.createInteractiveVideoObj.currentObj.type,
            name,
            URL: this.createInteractiveVideoObj.currentObj.URL,
            Title: this.createInteractiveVideoObj.currentObj.Title,
            Description: this.createInteractiveVideoObj.currentObj.Description,
            Tag: this.createInteractiveVideoObj.currentObj.Tag,
            Categories: this.createInteractiveVideoObj.currentObj.Categories,
            time: this.createInteractiveVideoObj.currentObj.time,
            parentId: this.createInteractiveVideoObj.currentObj.parentId,
            poster: this.createInteractiveVideoObj.currentObj.poster,
            videoID: this.createInteractiveVideoObj.currentObj.videoID,
            subtitleUrl: this.createInteractiveVideoObj.currentObj.subtitleUrl,
        };


        this.urlService.updateInteractiveVideo(obj).subscribe(
            (success) => {
                this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
                this.callChild1();
                this.toastr.info('Updated Successfully!');
                if (this.isAiContentCreatorassist) {
                    this.refreshInteractiveVideo();
                }
            },
            (error) => {
                this.createInteractiveVideoObj.spinner = false;
            }
        );
    }

    saveDraft() {
        this.toastr.success('Your video is successfully saved in draft.');
        this.router.navigate(['my-videos'], { queryParams: { currentTab: 'draft' } });
    }

    editQuestionnaire(questionnaire) {
        const dialogRef = this.dialog.open(CreateQuestionnaireComponent, {
            width: '100%',
            maxWidth: '1000px',
            height: 'auto',
            autoFocus: false,
            data: {
                questionnaire
            },
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                this.currentVideoData.isEdit = true;
                // this.refreshInteractiveVideo();
                location.reload();
            }
        });
    }

    resetTimer() {
        const payload = {
            videoId: this.createInteractiveVideoObj.currentObj._id,
            type: this.createInteractiveVideoObj.currentObj.type
        }

        this.urlService.resetTimerInVideoChoice(payload).subscribe((res) => {
            this.createInteractiveVideoObj.currentObj.timer = null;
            this.toastr.success('Timer reset successfully!');
            location.reload();
        }, (err) => {
            this.toastr.error('Error!');
        })
    }

    removeQuestionnaire() {
        this.deleteDialogRef.open({
            header: 'Remove Questionnaire',
            title: 'Are you sure you want to remove this questionnaire?',
            trueButton: 'Yes',
            falseButton: 'No',
        }, (e) => {
            if (e) {
                const payload = {
                    questionnaireId: this.createInteractiveVideoObj.currentObj.questionnaireId,
                    videoId: this.createInteractiveVideoObj.currentObj._id,
                    type: this.createInteractiveVideoObj.currentObj.type
                };
                this.urlService.detachQuestionnaireFromVideo(payload).subscribe((res) => {
                    this.currentVideoData.isEdit = true;
                    // this.refreshInteractiveVideo();
                    location.reload();
                });
            }
        });
    }

    addQuestionnaire() {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            width: '95%',
            maxWidth: '1300px',
            panelClass: 'my-dialog',
            height: 'auto',
            minHeight: '500px',
            data: {
                questionnaire: true
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                const payload = {
                    questionnaireId: result._id,
                    videoId: this.createInteractiveVideoObj.currentObj._id,
                    type: this.createInteractiveVideoObj.currentObj.type
                };
                this.urlService.attachQuestionnaireToVideo(payload).subscribe((res) => {
                    this.currentVideoData.isEdit = true;
                    // this.refreshInteractiveVideo();
                    location.reload();
                });
            }
        });
    }

    addInput() {
        if ((this.inputLength.length + 1) > 5) {
            this.toastr.info('You can add up to 5 objectives');
            return;
        }

        if (this.inputValues.length !== this.inputLength.length) {
            this.toastr.info('You can not add empty objectives');
            return;
        }

        this.inputLength.push('');
        $('#messagesCard').animate({ scrollTop: $('#messagesCard .messages').height() }, 500);

        this.inputFields.changes.subscribe(() => {
            this.inputFields.last?.nativeElement.focus();
        });
    }

    openSetTimerModal() {
        const timerData = this.createInteractiveVideoObj.currentObj?.timer;

        this.hh = timerData?.hh ? timerData.hh.toString() : '00';
        this.mm = timerData?.mm ? timerData.mm.toString() : '00';
        this.ss = timerData?.ss ? timerData.ss.toString() : '00';

        this.userMessage = timerData?.timerUpMessage ? timerData?.timerUpMessage : '';

        const dialogRef = this.dialog.open(this.setTimerForCurrentNodeDialog, {
            width: '100%',
            maxWidth: '500px',
            panelClass: 'position-choices',
            height: 'auto',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.saveTimer();
            }
        })
    }

    saveTimer() {
        const timer = this.getTime();

        const payload = {
            videoId: this.createInteractiveVideoObj.currentObj._id,
            type: this.createInteractiveVideoObj.currentObj.type,
            timer: timer
        }

        this.urlService.setTimerInVideoChoice(payload).subscribe((res) => {
            this.createInteractiveVideoObj.currentObj.timer = res.data.timer;
            this.toastr.success('Timer added successfully!');
            location.reload();
        }, (err) => {
            this.toastr.error('Error!');
        })
    }

    getTime() {
        const timer = {
            hh: this.hh,
            mm: this.mm,
            ss: this.ss,
            timerUpMessage: this.userMessage
        }
        return timer;
    }

    prependZero(number: any) {
        if (number == "00")
            return "00"
        if (number < 9)
            return "0" + number;
        else
            return number;
    }

    openDeleteConfirmation() {
        $('#deleteCurrentNodeModal').modal('show');
    }

    deleteCurrentNode() {
        this.createInteractiveVideoObj.spinner = true;
        if (this.deleteCurrentNodeType) {
            // this.createInteractiveVideoObj.spinner = true;
            if (this.createInteractiveVideoObj.currentObj.type === 'main') {
                this.urlService
                    .deleteBookMarkVideo(this.createInteractiveVideoObj.currentObj._id)
                    .subscribe((success) => {

                        this.toastr.success('Delete successfully!');
                        // $('#tree-Modal').modal('hide');
                        $('#tree-node-modal').modal('hide');
                        $('#deleteCurrentNodeModal').modal('hide');
                        this.createChoiceObj.child = [];
                        this.callChild1();
                        this.deleteCurrentNodeType = 1;
                    }).add(() => {
                        this.createInteractiveVideoObj.spinner = false;
                    });
            } else {
                const Payload = {
                    videoId: this.createInteractiveVideoObj.currentObj._id,
                    mainId: this.createInteractiveVideoObj.currentObj.parentId,
                    isDeleteSingleNode: this.deleteCurrentNodeType === 1
                };

                this.urlService.deleteChildVideo(Payload).subscribe((success) => {
                    // this.createInteractiveVideoObj.spinner = false;
                    // $('#tree-Modal').modal('hide');
                    $('#tree-node-modal').modal('hide');
                    $('#deleteCurrentNodeModal').modal('hide');
                    this.createChoiceObj.child = [];
                    this.latestNodeId = this.createInteractiveVideoObj.currentObj.parentId;
                    this.callChild1();

                    if (!success.error)
                        this.toastr.info('Updated Successfully!');
                    else
                        this.toastr.error(success.message);

                    this.deleteCurrentNodeType = 1;
                }).add(() => {
                    this.createInteractiveVideoObj.spinner = false;
                });
            }
        }
    }

    updateTranscript() {
        this.language = this.createInteractiveVideoObj.currentObj.subtitleTranslations[0];
        this.getVttFile();
        const dialogRef = this.dialog.open(this.updateTranscriptDialog, {
            width: '100%',
            maxWidth: '700px',
            panelClass: 'position-choices',
            height: 'auto',
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.uploadTranscription();
            }
        })
    }

    handleActiveWatchPath(activePath?) {
        this.createInteractiveVideoObj.activePathNodes = activePath?.pathNodes || [];
        $(`rect`).removeClass('active');
        $(`#tree-view`).removeClass('pathways');
        if (this.createInteractiveVideoObj.activePathNodes && this.createInteractiveVideoObj.activePathNodes.length) {
            for (const i of this.createInteractiveVideoObj.activePathNodes) {
                $(`rect[data-id=${i}]`).addClass('active');
            }
            $(`#tree-view`).addClass('pathways');
            return;
        }
    }

    getUserInfoWithHubSpot() {
        this.urlService.userDetailsV2().subscribe(success => {
            this.userMeData = success?.data?.details
            if (this.userMeData?.hubSpot?.integrationCompletedTS) {
                this.getCrmLifeCycleStages();
            }
        })
    }

    getCrmLifeCycleStages() {
        this.urlService.getCrmLifeCycleStages().subscribe(success => {
            this.lifeCycleStages = success?.data;
            if (!this.createInteractiveVideoObj?.mainVideo?.hubSpotLifeCycle) {
                this.createInteractiveVideoObj.mainVideo.hubSpotLifeCycle = 'None';
            }
            if (!this.hubSpotLifeCycle) {
                this.hubSpotLifeCycle = 'None'
            }
        })
    }

    toggleDragDropNode(value) {
        this.dragDropNode = value;
        this.visRef.attr('class', this.dragDropNode ? 'rearrange' : '');
    }

    cancelAddWatchPath() {
        this.msgValue = '';
        this.messages = [];
        this.currentMessageStep = 0;
        this.assignBadge = null;
        this.editWatchPath = null;
        this.startWatchNode = null;
        this.endWatchNode = null;
        this.allPathNodes = [];
        this.totalPaths = [];
        this.clearAllPathNodesColor(this.pathNodes);
        this.pathNodes = [];
        this.selectedPathNodes = [];
        this.inputLength = [''];
        this.inputValues = [];
        this.isSelectedPathIndex = false;
        this.selectedPathIndex = 0;
        this.isAddWatchPath = false;
        this.isSubmitPathWaysForm = false;
        this.submitPathWaysFormError = '';
        this.watchPathName = '';
        this.handleActiveWatchPath('');
        this.handleHoveredPath('');
        $('rect').removeClass('nodeSelected');
        $('#tree-view').removeClass('add-pathways');
    }

    handleHoveredPath(hoveredPath?) {
        if (hoveredPath && hoveredPath.length) {
            this.clearAllPathNodesColor(this.pathNodes);
            for (const i of hoveredPath) {
                $(`rect[data-id=${i._id}]`).attr('fill', hoveredPath[hoveredPath.length - 1].color);
            }
            return;
        } else {
            this.updateCurrentHighlight();
        }
    }

    addMessage = (author, content?, type?, url?, description?, data?) => {
        this.messages.push({ author, content, type, url, description, data });

        $('#messagesCard').animate({ scrollTop: $('#messagesCard .messages').height() }, 500);
    }

    clearAllPathNodesColor(pathNodes) {
        if (pathNodes) {
            for (const video of pathNodes) {
                $(`rect[data-id=${video._id}]`).attr('fill', '#fff');
            }
        }
    }

    showObjectivesPopup(data: any) {
        this.objectivesPopupData = data
    }

    hideObjectivesPopup() {
        if (!this.isDisplayPopupWithObjective) {
            this.objectivesPopupData = null;
        }
    }

    showPopupWithObjective() {
        this.isDisplayPopupWithObjective = true
    }

    hidePopupWithObjective() {
        this.isDisplayPopupWithObjective = false
        this.objectivesPopupData = null;
    }

    onEditWatchPath(i) {
        this.getUserInfoWithHubSpot();

        this.isInitialStep = true;
        this.isEditPathWay = true;

        if (this.isMessageLoad) {
            return;
        }
        this.toggleDragDropNode(false);
        this.cancelAddWatchPath();
        $('#tree-view').addClass('add-pathways');
        this.isAddWatchPath = true;
        this.editWatchPath = i;
        this.startWatchNode = i.startNode;
        this.endWatchNode = i.endNode;
        this.watchPathName = i.pathName;
        this.isSelectedPathIndex = true;
        this.updateCurrentHighlight();
        this.addMessage('bot', 'Pathway name: ' + this.watchPathName, 'text');
        this.addMessage('bot', 'Starting node', 'image', this.startWatchNode?.poster, this.startWatchNode?.name);
        this.addMessage('bot', 'Ending node', 'image', this.endWatchNode?.poster, this.endWatchNode?.name);

        if (i?.learningObjectives) {
            this.inputValues = i.learningObjectives
            this.inputLength = i.learningObjectives
            this.addMessage('bot', 'Objectives', 'input');
            this.isDisableInput = true
        }

        if (i?.hubSpotLifeCycle) {
            this.hubSpotLifeCycle = i.hubSpotLifeCycle
            this.addMessage('bot', `HubSpot LifeCycle: ${this.hubSpotLifeCycle}`, 'select');
        }

        if (this.editWatchPath.viewCompletionBadge) {
            this.addMessage('bot', 'Badge', 'badge',
                this.editWatchPath?.viewCompletionBadgeInfo[0]?.pic,
                this.editWatchPath?.viewCompletionBadgeInfo[0]?.name,
                this.editWatchPath?.viewCompletionBadgeInfo[0]);
        }
        this.getBotAnswer('', 'updatePathways');
    }

    publishPreview() {
        this.getUserInfoWithHubSpot();

        if (this.createInteractiveVideoObj.mainVideo?.hubSpotLifeCycle) {
            this.getCrmLifeCycleStages();
        }

        if (!this.createInteractiveVideoObj.mainVideo.isPublished) {
            this.urlService.getUserSubscriptionPlan().subscribe(success => {

                this.currSubscriptionId = success.data._id;

                const maxNoOfAllowedPublishedVideos = success.data.subscriptionPlanInfo.maxNoOfVideos;

                this.urlService.getAllMainVideosCountV2().subscribe(success => {

                    const videoCounts = success.data;

                    const getCountOfPublishedVideos = () => {
                        let countOfPublishedVideos = 0;
                        videoCounts.forEach(item => {
                            if (item._id.isPublished != undefined && item._id.isPublished)
                                countOfPublishedVideos = item.count;
                        });

                        return countOfPublishedVideos;
                    };

                    if (getCountOfPublishedVideos() < maxNoOfAllowedPublishedVideos) {
                        this.publishModal();
                    } else {

                        this.pricingUrl = '/pricing?subId=' + this.currSubscriptionId;

                        $('#exceed-max-videos-Modal').modal('show');
                    }
                })
            })
        } else {
            this.publishModal();
        }
    }

    publishModal() {
        const checkAllUrl = this.createInteractiveVideoObj.finalObj.filter(x => !!!x.URL);
        if (checkAllUrl.length) {
            this.toastr.error('Unable to publish until all nodes have videos');
            return;
        }
        clearInterval(this.interval);
        this.toastr.info('Preparing Video!');
        this.createInteractiveVideoObj.spinner = true;

        if (this.createInteractiveVideoObj.mainVideo?.viewCompletionBadge) {
            this.assignPublishBadge = this.createInteractiveVideoObj.mainVideo?.viewCompletionBadgeInfo[0];
        }
        this.urlService.fetchCategories().subscribe((sucess) => {
            this.availableCategory = sucess.data.categories;
            this.availableCategory.sort();
            this.closePreview();
            this.createInteractiveVideoObj.spinner = false;
            this.createInteractiveVideoObj.publish = true;
            $('#tree-publish-vid-Modal').modal(
                { backdrop: 'static', keyboard: false },
                'show'
            );
            this.filledLogin('main_title');
            this.filledLogin('user_Description');
        });
    }

    deleteWatchPath(i) {
        this.deleteDialogRef.open({
            header: 'Delete path',
            title: `Are you sure? Do you want to delete "${i.pathName}" path?`,
            trueButton: 'Delete'
        }, (e) => {
            if (e) {
                this.spinner = true;
                this.urlService.deletePathway(i._id).subscribe((res) => {
                    this.toastr.success(res.message)
                    this.getVideoPathWays();
                }).add(() => this.spinner = false);
            }
        });
    }

    getBotAnswer(userMessage: string, type = 'text', url = '', description = '', data = null) {
        if (userMessage && userMessage !== this.messageMap.addLearningObjectives) {
            this.addMessage('user', userMessage, type, url, description, data)
        }
        this.isMessageLoad = true;
        setTimeout(() => {
            let botMessage = '';
            if (!this.messages.length) {
                botMessage = 'Hello!';
                this.addMessage('bot', botMessage, 'text')
                this.getBotAnswer('');
                return;
            } else if (!this.startWatchNode) {
                this.currentMessageStep = 1;
                botMessage = 'Please choose a starting node from the tree map';
            } else if (!this.endWatchNode) {
                this.currentMessageStep = 2;
                botMessage = 'Please choose an ending node from the tree map';
            } else if (this.currentMessageStep === 111) {
                botMessage = 'Select badge from library';
            }
            else if (this.currentMessageStep === 8) {
                botMessage = "You can add up to 5 objectives"
            }
            else if (this.currentMessageStep === 9) {
                botMessage = "Please choose the HubSpot LifeCycle stages"
                this.ishubSpotLifeCycle = true
            }
            else if (this.currentMessageStep === 7) {
                if (!this.watchPathName) {
                    botMessage = 'Please enter a name of the pathways';
                } else {
                    if (this.isInitialStep) {
                        this.currentMessageStep = 0;
                        this.isInitialStep = false;
                    } else {
                        this.currentMessageStep = 6;
                    }
                    botMessage = 'What would you like to do next?'
                }
            } else if (this.currentMessageStep === 5) {
                if (this.isInitialStep) {
                    this.currentMessageStep = 0;
                    this.isInitialStep = false;
                } else {
                    this.currentMessageStep = 6;
                }
                botMessage = 'What would you like to do next?'
            } else {
                this.currentMessageStep = 3;
                if ((this.allPossiblePaths.length === 0 || this.pathNodes.length === 0) && type !== 'updatePathways') {
                    this.allPossiblePaths = [];
                    this.allPossiblePaths = this.utilityService.listPaths(this.clonedArr[0], this.startWatchNode._id, this.endWatchNode._id);
                    this.totalPaths = this.allPossiblePaths;
                    this.pathNodes = this.allPossiblePaths.flat(Infinity)
                    this.pathNodes = this.pathNodes.filter((obj, index, self) =>
                        index === self.findIndex((o) => o['_id'] === obj['_id'])
                    );
                }
                if (type === 'updatePathways') {
                    if (this.editWatchPath) {
                        this.pathNodes = [];
                        this.editWatchPath.pathNodes.forEach(id => {
                            let node = this.createInteractiveVideoObj.finalObj.find((i) => i._id === id)
                            if (node) {
                                this.pathNodes.push({
                                    URL: node.URL,
                                    poster: node.poster,
                                    time: node.time,
                                    name: node.name,
                                    _id: node._id,
                                    color: '#ffb46e'
                                })
                            }
                        });
                        this.allPossiblePaths = [this.pathNodes]
                    }
                }

                if (this.endWatchNode.isEndingNode) {
                    this.allPathNodes = this.utilityService.getParentNodesForEndNodes(this.createInteractiveVideoObj.finalObjTree, this.endWatchNode._id);
                }
                if (this.editWatchPath) {
                    if (this.utilityService.areArraysEqual(this.editWatchPath.pathNodes, this.pathNodes.map(x => x._id))) {
                        this.selectedPathIndex = 0;
                    }
                    let i = 0;
                    for (const allPathNode of this.allPathNodes) {
                        ++i;
                        if (this.utilityService.areArraysEqual(this.editWatchPath.pathNodes, allPathNode.map(x => x._id))) {
                            this.selectedPathIndex = i;
                            break;
                        }
                    }
                }
                this.updateCurrentHighlight();
                if (!this.pathNodes) {
                    botMessage = 'The end node is not a child of the start node.';
                } else if (!this.pathNodes.find(x => x._id === this.startWatchNode._id)) {
                    botMessage = 'The end node is not a child of the start node.';
                } else if (this.startWatchNode._id === this.endWatchNode._id) {
                    botMessage = 'The end node and the start node must be unique.';
                } else if (this.endWatchNode.isEndingNode && this.allPathNodes.length && !this.isSelectedPathIndex) {
                    this.currentMessageStep = 4;
                    botMessage = 'We have detected multiple pathways';
                    this.updateCurrentHighlight();
                } else if (this.allPossiblePaths.length > 1) {
                    this.currentMessageStep = 10;
                    botMessage = 'We have detected multiple pathways, Please choose any one path';
                } else if (!this.watchPathName) {
                    this.currentMessageStep = 5;
                    botMessage = 'Please enter a name of the pathways';
                }
                else {
                    if (this.isInitialStep) {
                        this.currentMessageStep = 0;
                        this.isInitialStep = false;
                    } else {
                        this.currentMessageStep = 6;
                    }
                    botMessage = 'What would you like to do next?'
                }
            }

            this.isMessageLoad = false;

            type = this.currentMessageStep === 8 ? 'input' : this.currentMessageStep === 9 ? 'select' : 'text'

            this.addMessage('bot', botMessage, type)
        }, 1000);
    }

    closePopover(popEle3: PopoverDirective, popEle32: string) {
        popEle3.hide();
        this.dashboardService.setHelpPopOver(popEle32);
    }

    undoOrder(): void {
        if (this.undoOrderedNodes.length !== 0) {
            const object = this.undoOrderedNodes[this.undoOrderedNodes.length - 1];
            const newParentId = object.newParentId;
            object.newParentId = object.parentId;
            object.parentId = newParentId;
            this.saveDragDropNode(object, 'undo');
        }
    }

    redoOrder(): void {
        if (this.redoOrderedNodes.length !== 0) {
            const object = this.redoOrderedNodes[this.redoOrderedNodes.length - 1];
            const newParentId = object.newParentId;
            object.newParentId = object.parentId;
            object.parentId = newParentId;
            this.saveDragDropNode(object, 'redo');
        }
    }

    zoomIn() {
        if (this.scaleIncrement == undefined) {
            if (this.zoomRef <= 0.10) {
                this.scaleIncrement = 0.10;
            } else {
                this.scaleIncrement = 0.01;
            }
        }

        this.zoomRef = this.zoomRef + this.scaleIncrement;

        this.zoomListener.scale(this.zoomRef);
        this.translateZoomInOut('IN', this.zoomRef);
    }

    translateZoomInOut(type: string, scale?) {
        let svgContainer = document.getElementById('rootg');
        let treeContainer = document.getElementById('svg-id');
        let treeView = document.getElementById('tree-view');

        const treeRect = svgContainer.getBoundingClientRect();
        const isTreeOutsideContainer = treeRect.right > svgContainer.clientWidth || treeRect.bottom > svgContainer.clientHeight;
        const scrollTopValue = (treeRect.height) / 2;

        if (isTreeOutsideContainer) {
            if (type === 'RESET') {
                treeContainer.style.width = this.svgWidthForReset;
                treeContainer.style.height = this.svgHeightForReset;
            }
            let transformScale = scale;

            treeContainer.style.overflow = 'auto';
            const scrollLeftValue = (treeContainer.scrollWidth - treeRect.width) / 3;

            if (treeRect.width > 2000) {
                let trans = [50, -250]
                this.visRef.attr('transform', 'translate(' + trans + ') scale(' + transformScale + ')');
            } else {
                let trans;
                if (treeRect.width > 1000) {
                    trans = [100, -300];
                } else {
                    trans = [100, -300];
                }
                this.visRef.attr('transform', 'translate(' + trans + ') scale(' + transformScale + ')');
                if (treeContainer.clientWidth > 2000) {
                    treeView.scrollLeft = scrollLeftValue;
                }
                if (treeContainer.clientHeight > 1200) {
                    treeView.scrollTop = scrollTopValue;
                }
            }
            this.zoomRef = transformScale;
        }

        const mainNode = this.nodes.find(node => node._id === this.mainID);
        const mainNodePosition = (treeContainer.querySelector(`g[id=node-${mainNode.id}]`) as SVGGElement).getCTM();
        const topValue = Math.abs(mainNodePosition.f);

        if (mainNode.x > 400) {
            treeView.scrollTop = 0;
            treeView.scrollLeft = 0;
            treeView.scrollTop = topValue - 200;
            treeView.scrollLeft = Math.abs(mainNodePosition.e) - 200;
        }

        this.svgHeight = treeContainer.clientHeight;
        this.svgWidth = treeContainer.clientWidth;
    }

    zoomOut() {
        if (this.zoomRef >= 0.1) {
            if (this.scaleIncrement == undefined)
                this.scaleIncrement = this.zoomRef * 0.1;

            this.zoomRef = this.zoomRef - this.scaleIncrement;

            this.zoomListener.scale(this.zoomRef);
            this.translateZoomInOut('OUT', this.zoomRef);
        }
    }

    zoomReset() {
        let treeContainer = document.getElementById('svg-id');
        let transformScale = this.getTransformScale(treeContainer);;
        this.zoomRef = transformScale;
        this.translateZoomInOut('RESET', this.scaleForReset);
    }

    confirmDelete(id: any) {
        this.deleteDialogRef.open({
            header: 'Delete Video',
            title: 'Are you sure you want to delete this video?'
        }, (e) => {
            if (e) {
                this.spinner = true;
                this.urlService.deleteBookMarkVideo(id).subscribe((success) => {
                    this.dashboardService.onGetUserData();
                    this.router.navigateByUrl('/my-videos');
                    this.toastr.success('Deleted successfully!');
                }, (err) => {
                    this.spinner = false;
                });
            }
        });
    }

    getFollowerContributors() {
        if (!this.mainID) {
            return;
        }
        this.urlService
            .getFollowerContributors(this.mainID, this.collabLimit, this.collabPage)
            .subscribe((res) => {
                this.followers.push(...res.data);

                this.followersLength = this.followers.length;
            });
    }

    previewVideo() {
        if (this.createInteractiveVideoObj.mainVideo.name == 'Add Video') {
            this.toastr.warning('Please Add parent Video!');
            return;
        }
        this.createInteractiveVideoObj.preview = true;
        $('#tree-preview-vid-Modal').modal('show');
        $('#tree-publish-vid-Modal').modal('hide');
    }

    closePreview() {
        $('#tree-preview-vid-Modal').modal('hide');
        this.createInteractiveVideoObj.preview = false;
        clearInterval(this.interval);
    }

    filledLogin(id) {
        $('#' + id)
            .siblings('.input-field')
            .addClass('video_input_focus');
        $('#' + id).addClass('focusIn');
    }

    selectWatchPath(d) {
        if ((this.currentMessageStep === 1 || this.currentMessageStep === 2) && !this.isMessageLoad) {
            if (!this.startWatchNode) {
                this.startWatchNode = d;
                this.getBotAnswer('Starting node', 'image', d.poster, d.name);
            } else {
                this.getBotAnswer('Ending node', 'image', d.poster, d.name);
                this.endWatchNode = d;
            }
            this.updateCurrentHighlight();
        }
    }

    openAddWatchPath() {
        this.getUserInfoWithHubSpot();
        this.hubSpotLifeCycle = ''
        this.isDisableInput = false
        if (this.isMessageLoad) {
            return;
        }
        this.toggleDragDropNode(false);
        this.cancelAddWatchPath();
        $('#tree-view').addClass('add-pathways');
        this.isAddWatchPath = true;
        this.getBotAnswer('');
    }

    openContributorModal() {
        this.findorselect = '';
        this.collabPage = 1;
        this.followers = [];
        this.getFollowerContributors();
        this.isShareOpen = true;
        $('#contributor-Modal').modal('show');
    }

    uploadTranscription() {
        const url = (this.language[this.language.subtitle.code]).split('?')[0];
        const formData = new FormData();
        formData.append('inputStr', this.textareaData);
        formData.append('fileName', url);
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.uploadFileToS3(formData).subscribe((res) => {
            this.urlService.getSignedUrl(res.data).subscribe((preSignedUrl) => {
                this.language[this.language.subtitle.code] = preSignedUrl;
            })
            this.toastr.success(res.message);
            this.createInteractiveVideoObj.spinner = false;
        });
    }

    updateInputValue(index: number, value: string) {
        this.inputValues[index] = value;
    }

    removeInput(i: number) {
        this.inputLength = this.inputLength.filter((_, index) => index !== i);
        this.inputValues = this.inputValues.filter((_, index) => index !== i);
    }

    btnNext() {
        this.isDisableInput = true
        this.inputValues = this.inputValues.filter(Boolean);
        this.inputLength.length = this.inputValues.length
        this.currentMessageStep = 6
        $('#messagesCard').animate({ scrollTop: $('#messagesCard .messages').height() }, 500);
    }

    btnCancelObjective() {
        this.currentMessageStep = 6
        this.messages.pop();
        this.inputLength = []
        this.inputLength.push('')
        this.inputValues = []
    }

    onScrollFollowers() {
        ++this.collabPage;
        this.getFollowersList();
    }

    getFollowersList(reset = false) {
        if (reset) {
            this.collabPage = 1;
            this.allFollowers = [];
        }
        this.urlService.getFollowers(this.collabLimit, this.collabPage).subscribe((res) => {
            this.allFollowers.push(...res.data.followers);
        });
    }

    recipientValueCheckV2(event: any) {
        const groupList = [];
        const followersList = [];
        setTimeout(() => {
            this.allGroups.forEach((e) => {
                if (e.checked) {
                    groupList.push(e._id)
                }
            });
            this.allFollowers.forEach((e) => {
                if (e.checked) {
                    followersList.push(e._id)
                }
            });
            this.groupList = groupList;
            this.followerListForPublic = followersList;
        });
    }

    closeContributorModal() {
        $('#contributor-Modal').modal('hide');
        this.isShareOpen = false;
    }

    onScrollCollab(): void {
        ++this.collabPage;
        this.getFollowerContributors();
    }

    sendMessage(msg?: string) {
        if (msg) {
            this.msgValue = msg;
        }

        this.msgValue = this.msgValue.trim();
        const msgValueUc = this.msgValue.toUpperCase();

        if (msgValueUc === this.messageMap.reChooseEndNode.toUpperCase()) {
            this.deSelectWatchPath(false);
            this.currentMessageStep = 2;
        } else if (msgValueUc === this.messageMap.restart.toUpperCase()) {
            this.clearAllPathNodesColor(this.pathNodes);
            this.deSelectWatchPath(true);
            this.deSelectWatchPath(false);
            this.currentMessageStep = 1;
        }
        if (this.currentMessageStep === 4 && msgValueUc === this.messageMap.save.toUpperCase()) {
            let paths = '';
            if (this.selectedPathIndex < 1) {
                let i = 0;
                for (const pathNode of this.pathNodes.reverse()) {
                    paths += `${i ? ' <i class="fas fa-chevron-right"></i> ' : ''} ${pathNode.name}`;
                    i++;
                }
            } else {
                let i = 0;
                for (const pathNode of this.allPathNodes[this.selectedPathIndex - 1]) {
                    paths += `${i ? ' <i class="fas fa-chevron-right"></i> ' : ''} ${pathNode.name}`;
                    i++;
                }
            }
            this.addMessage('bot', paths, 'text');
            this.isSelectedPathIndex = true;
        }
        if (this.currentMessageStep === 6 && msgValueUc === this.messageMap.save.toUpperCase()) {
            this.createWatchPath();
            this.msgValue = '';
            return;
        }
        if ((this.currentMessageStep === 0 || this.currentMessageStep === 6) && msgValueUc === this.messageMap.rename.toUpperCase()) {
            this.msgValue = this.watchPathName;
            this.watchPathName = '';
        }
        if (this.currentMessageStep === 111) {
            if (msgValueUc === this.messageMap.cancel.toUpperCase()) {
                this.currentMessageStep = 6;
            } else {
                this.openBadgeModal();
            }
        }
        if ((this.currentMessageStep === 0 || this.currentMessageStep === 6) && msgValueUc === this.messageMap.addBadge.toUpperCase()) {
            this.currentMessageStep = 111;
        }
        if (this.currentMessageStep === 5) {
            this.watchPathName = this.msgValue;
            this.isInitialStep = false;
        }
        if (this.currentMessageStep === 7) {
            this.currentMessageStep = 5;
            this.watchPathName = this.msgValue;
            this.isInitialStep = false;
        }
        if (this.msgValue === this.messageMap.addLearningObjectives) {

            if (this.isEditPathWay) {
                this.isDisableInput = false
                this.messages = this.messages.filter((i) => i.content !== "Objectives")
                this.inputValues = this.inputValues.length ? this.inputValues : this.inputValues = ['']
                this.inputLength = this.inputLength.length ? this.inputLength : this.inputLength = ['']
            }
            this.currentMessageStep = 8
        }

        if (this.msgValue === this.messageMap.hubSpotLifeCycle) {
            this.messages = this.messages.filter((i: any) => i.type !== 'select' && i.content !== this.messageMap.hubSpotLifeCycle)
            this.currentMessageStep = 9;
        }

        if (this.msgValue) {
            this.getBotAnswer(this.msgValue);
        }
        this.msgValue = '';
    }

    onCollabChange(follower: any): void {
        this.createInteractiveVideoObj.spinner = true;
        if (follower.followersWhoContribute.length == 0) {
            const contributor = {
                videoID: this.mainID,
                contributorID: follower.followersDetails[0]._id,
            };

            this.urlService.setContributorV2(contributor).subscribe((success) => {
                follower.followersWhoContribute.push(success.data);
                this.toastr.success(
                    follower.followersDetails[0].username + ' added as a contributor'
                );
            }).add(() => this.createInteractiveVideoObj.spinner = false);
        } else {
            const docId = follower.followersWhoContribute[0]._id;

            const data = { id: docId };
            this.urlService.deleteContributor(data).subscribe((success) => {
                follower.followersWhoContribute = [];
                this.toastr.success(
                    follower.followersDetails[0].username + ' removed as a contributor'
                );
            }).add(() => this.createInteractiveVideoObj.spinner = false);
        }
    }

    getCurrentTime(e) {
        this.interval = setInterval(() => {
            this.createInteractiveVideoObj.currentPlayingItem.currentTime =
                e.target.currentTime;
        }, 1000);

        let hasChilren: any = false;
        if (
            this.createInteractiveVideoObj.currentPlayingItem.children == null ||
            this.createInteractiveVideoObj.currentPlayingItem.children == undefined
        ) {
            hasChilren = false;
        } else {
            hasChilren = this.createInteractiveVideoObj.currentPlayingItem.children.length > 0;
        }

        this.placeMarker(
            hasChilren,
            this.createInteractiveVideoObj.currentPlayingItem.time,
            'previewVideo',
            e.target.duration
        );

        this.createInteractiveVideoObj.currentPlayingItem.currentTime =
            e.target.currentTime;
    }

    loadNextOption(e) {
        clearInterval(this.interval);
        this.createInteractiveVideoObj.currentPlayingItem.currentTime = 0;
        this.createInteractiveVideoObj.preview = false;
        setTimeout(() => {
            this.createInteractiveVideoObj.currentPlayingItem = e;
            this.createInteractiveVideoObj.preview = true;
        }, 100);
    }

    getFirstAndLastTwoElements(array: any[]): any[] {
        if (array.length < 4) {
            return array;
        } else {
            return array.slice(0, 2).concat(array.slice(-2));
        }
    }

    getSelectedPath(item) {
        this.clearAllPathNodesColor(this.pathNodes);
        this.pathNodes = item;
        this.pathNodes.forEach((i) => i['color'] = item[item.length - 1].color);
        this.allPossiblePaths = [item];
        this.updateCurrentHighlight();
        this.currentMessageStep = 7;
        this.getBotAnswer('');
        this.addMessage('user', 'Selected Path :', 'pathColor', '', item[item.length - 1].color);
    }

    openBadgeModal() {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            data: {
                badges: true,
                links: false,
                videos: false
            },
            minWidth: '50vw'
        });
        dialogRef.afterClosed().subscribe((result: UploadedFileResponse) => {
            if (result) {
                this.currentMessageStep = 6;
                this.assignBadge = result;
                this.getBotAnswer('Badge', 'badge', result.pic, result.name, result);
            }
        });
    }

    deSelectWatchPath(start) {
        if (start) {
            this.startWatchNode = null;
        } else {
            this.endWatchNode = null;
        }
        this.selectedPathNodes = [];
        this.pathNodes = [];
        this.selectedPathIndex = 0;
        this.isSelectedPathIndex = false;
        this.updateCurrentHighlight();
    }

    createWatchPath() {
        this.isSubmitPathWaysForm = true;
        const pathNodes = [];

        for (const selectedPathNode of this.selectedPathNodes) {
            pathNodes.push(selectedPathNode._id);
        }

        const payload: any = {
            videoId: this.mainID,
            pathName: this.watchPathName,
            startNodeId: this.startWatchNode._id,
            startNodeType: this.startWatchNode.type,
            endNodeId: this.endWatchNode._id,
            endNodeType: this.endWatchNode.type,
            pathNodes,
            learningObjectives: this.inputValues
        };
        if (this.assignBadge) {
            payload.viewCompletionBadge = this.assignBadge._id
        }
        if (this.editWatchPath) {
            payload._id = this.editWatchPath._id;
        }
        if (this.hubSpotLifeCycle) {
            payload.hubSpotLifeCycle = this.hubSpotLifeCycle
        }
        this.spinner = true
        this.urlService.setPathway(payload, this.editWatchPath).subscribe((res) => {
            this.isDisableInput = false
            this.cancelAddWatchPath();
            this.getVideoPathWays();
            this.toastr.success(`Pathways ${this.editWatchPath ? 'updated' : 'created'} successfully`);
        }, (err) => {
            this.isSubmitPathWaysForm = false;
            this.watchPathName = '';
            this.addMessage('bot', 'The name of the pathway already exists! select a different name', 'text');
            this.getBotAnswer('')
        }).add(() => this.spinner = false);
    }

    changeHubSpotLifeCycleStage(value: any) {
        this.hubSpotLifeCycle = value;
        this.currentMessageStep = 6
        this.ishubSpotLifeCycle = false;
        if (this.inputValues?.length) {
            this.isDisableInput = true
        }
        this.messages.push({ author: 'user', content: this.hubSpotLifeCycle, type: 'select' });
        $('#messagesCard').animate({ scrollTop: $('#messagesCard .messages').height() }, 500);
        this.cdr.detectChanges();
    }

    getVttFile(url = null) {
        this.urlService.getTextData(url || this.language[this.language.subtitle.code]).subscribe((res) => {
            this.textareaData = res;
            this.initialValue = res;
        });
    }

    getLatestItem(nodes, id: string) {
        return nodes.filter(node => node._id === id);
    }

    goBack() {
        this.router.navigateByUrl('/my-videos');
    }

    ngOnDestroy(): void {
        this.localStorageService.removeItem('aiContentCreatorassist');
        this.localStorageService.removeItem('aiVideoCreatorFlow');
    }

}
