import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CrudService} from 'src/app/services/crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpEvent, HttpEventType} from '@angular/common/http'
import {ToastrService} from 'ngx-toastr';
import {Location} from '@angular/common'
import {PopoverDirective} from 'ngx-bootstrap/popover';
import {LocalStorageService} from '../../services/localStorage.service';
import { Observable, Subject, take, takeUntil, timeout } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
// import {DashboardService} from '../../services/dashboard.service';

declare var $;

@Component({
    selector: 'uploadVideos',
    templateUrl: './uploadVideos.component.html',
    styleUrls: ['./uploadVideos.component.css'],
    // animations: [ fadeInOutAnimation ]
})
export class UploadVideosComponent implements OnInit, OnDestroy {

    constructor(
        private urlService: CrudService,
        // private dashboardService: DashboardService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private toastr: ToastrService,
        private location: Location,
        private sanitizer: DomSanitizer
    ) {
    }

    @ViewChild('popEle1') popEle1: PopoverDirective;
    @ViewChild('popEle2') popEle2: PopoverDirective;
    @ViewChild('popEle3') popEle3: PopoverDirective;

    uploadVideoObject: any = {
        spinner: false,
        allVideos: [],
        selectedVideos: [],
        format: '',
        url: '',
        isUploaded: false,
        duration: 0,
        name: '',
        type: '',
        size: '',
        preSignedUrl: '',
        file: '',
        allUploadedVideo: [],
        allUrls: [],
        changeFilterUploadVideo: 'recentFirst',
        changeFilterURL: 'recentFirst',
        uploadPercentage: 0,
        loadingVideo: false
    }
    private isUploaded: any = false;
    private allVideos: any = [];
    private selectedVideos: any = [];
    allUploadedVideo: any = [];
    private uploadPercentage: any = 0;
    spinner: any = false;
    loadingVideo: any = false;
    private uploadedCount: any = 0;
    private finalCount: any = 0
    isTemplate: any = false;
    isOpen: any = false;
    isSearch: any;
    currSubscriptionId = '';
    pricingUrl = '';

    changeFilterUploadVideoVal: any = 'recentFirst';

    // WC 072022
    // private maxNoOfVideosAllowed = 0;
    maxNoOfCreatedVideos = 0;
    isMaxVideoExceeded = false;
    // private currNoOfRetries = 0;
    // WC 072022

    // WC 09062022
    planInfo: any = null;
    templatetypes: any = [
        {
            id: 1,
            name: 'Standard Pyramid',
            type: '1:2:4',
            src: '../../../assets/images/standard-pyramid.svg'
        },
        {
            id: 2,
            name: 'Standard Triangle',
            type: '1:2',
            src: '../../../assets/images/standard-triangle.svg'
        },
        {
            id: 3,
            name: 'Standard Diamond',
            type: '1:2:4:2:1',
            src: '../../../assets/images/standard-diamond.svg'
        },
        {
            id: 4,
            name: 'Standard Zipper',
            type: '1:3:2',
            src: '../../../assets/images/standard-zipper.svg'
        },
        {
            id: 5,
            name: 'Standard 2\'s',
            type: '1:2:2',
            src: '../../../assets/images/standard-2_s.svg'
        },
        {
            id: 6,
            name: 'Stoplight',
            type: '1:1:1',
            src: '../../../assets/images/stoplight.svg'
        },
        {
            id: 7,
            name: 'Stoplight Poll',
            type: '1:1:1:4',
            src: '../../../assets/images/stoplight-poll.svg'
        }, {
            id: 8,
            name: 'Inforgraphic/Custom Workout',
            type: '1:4:3:2:1',
            src: '../../../assets/images/infographic_custom-workout.svg'
        }

    ];

    private limit = 15;
    private page = 1;
    private sort = '';
    private aiEditorVideo = 'no';
    tutorialVideo: SafeResourceUrl | null = null;
    suggestionTimeout = environment.lexieAI_Suggestion_PopUp_Timeout_Sec;
    private destroy$: Subject<void> = new Subject<void>();

    ngOnInit() {

        // this.spinner = true;
        this.allVideos = [];
        this.selectedVideos = [];
        this.allUploadedVideo = [];
        this.uploadVideoObject.file = [];
        this.uploadPercentage = 0;
        // const popData = this.dashboardService.getHelpPopOver();
        const a = this.localStorageService.getItem('isTree')
        setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
            if (a == null) {
                this.isSearch = 'show';
            } else {
                this.isSearch = 'hide';

            }
            // if (!popData['uploadVideoPopEle1']) {
            //     this.popEle1.show();
            // }
            // if (!popData['uploadVideoPopEle2']) {
            //     this.popEle2.show();
            // }
            // if (!popData['uploadVideoPopEle3']) {
            //     this.popEle3.show();
            // }
        }, 1000);

        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.tutorial === '1') {
                setTimeout(() => {
                    $('#HowToVideoModal').modal('show');
                }, 1000)
            }
        });

        this.checkEventWithinTime();

        this.urlService.aiFreeBoardSubjectObservable$
            .pipe(takeUntil(this.destroy$))
            .subscribe((res) => {
                if(res === 'Freeboard') {
                    this.openInteractiveVideo(true);
                }
                if(res === 'Storyboard') {
                    this.openStoryBoard(true);
                }
                if(res === 'Template') {
                    this.openTemplateVideo(true);
                }
                if(res === 'AI Auto Editor') {
                    this.openEditor(true);
                }
                if(res === 'AI_Content_Creator') {
                    this.openAIContentCreator(true);
                }
            })
    }

    openTutorialVideo() {
        this.tutorialVideo = this.sanitizer.bypassSecurityTrustResourceUrl("https://freefuse.com/embed/639c30eeffa90bb2e292ca1e");
    }
    clearVideo() {
        this.tutorialVideo = null; 
    }

    checkEventWithinTime(): void {
        const allEventsObservable = new Observable<Event>((observer) => {
          const listener = (event: Event) => observer.next(event);
          document.addEventListener('click', listener, true); // Change 'click' to '*' if you want to capture all events
          return () => {
            document.removeEventListener('click', listener, true);
          };
        });
    
        allEventsObservable.pipe(
            takeUntil(this.destroy$),
            take(1), // Take only one event
            timeout(this.suggestionTimeout * 1000) // 15 seconds timeout
        ).subscribe(
            () => {
                this.urlService.setEventOccurred(false);
            },
            (error) => {
                this.urlService.setEventOccurred(true);
            }
        );
    }

    templatetypesmethod(i: any) {
        this.spinner = true;
        const obj: any = {templateId: i}
        this.urlService.bookmarkTemplateVideos(obj).subscribe(
            success => {
                // console.log('xs',success.data)
                this.router.navigate(['create-interactive-video'], {queryParams: {video: success.data}});
                this.spinner = false;

            }, error => {
                this.spinner = false
            })

    }

    callInitialList() {

        // WC 03312022
        // this.spinner = true;
        this.allUploadedVideo = [];
        this.loadingVideo = true;

        this.urlService.getUploadedVideoV2(this.limit, this.page, this.sort, this.aiEditorVideo, 'video').subscribe(
            success => {
                this.allVideos = success.data
                for (const i of this.allVideos) {
                    if (!!i.originalName) {
                        i.name = i.originalName
                    }
                    this.allUploadedVideo.push(i)
                }
                this.allUploadedVideo = this.allUploadedVideo.reverse()
                this.loadingVideo = false;
                this.spinner = false;


            },
            error => {
                this.spinner = false;

            })
    }

    cancelCurrentFile(j: any) {
        for (let i = this.uploadVideoObject.file.length - 1; i >= 0; i--) {
            if (this.uploadVideoObject.file[i].id == j.id) {
                this.uploadVideoObject.file.splice(i, 1)
            }
        }
    }

    onSelectFile(event) {
        this.spinner = true;
        const file = event.target.files;
        let existItem = [];

        if (file) {
            // let file = fileInput.target.files[0];

            for (const i of file) {

                i.originalName = i.name.replace(/[{()}]/g, '').replace(/\s/g, '');
                i.originalName = i.originalName.trim();

                i.id = Math.random();
                i.uploadPercentage = 0;
                // console.log(event.target)
                this.getDuration(i).then((duration) => {
                    // duration in seconds (as float)
                    i.duration = duration;
                    // var modifiedDate = event.target.files[i].lastModifiedDate;

                    if (i.type.indexOf('image') > -1) {
                        i.format = 'image';
                    } else if (i.type.indexOf('video') > -1) {
                        i.format = 'video';
                    }
                    this.uploadVideoObject.file.push(i)

                    existItem = existItem.filter(function (item, pos) {
                        return existItem.indexOf(item) == pos;
                    })

                    for (let i = this.uploadVideoObject.file.length - 1; i >= 1; i--) {
                        for (const j of existItem) {
                            if (this.uploadVideoObject.file[i].originalName == j) {

                                this.uploadVideoObject.file.splice(i, 1);
                                break;
                            }
                        }
                    }


                });
            }

        }
        setTimeout(() => {
            this.uploadVideo();
        }, 2000)
    }

    getDuration(file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        const videoNode = document.createElement('video');
        const promise = new Promise(function (resolve, reject) {
            videoNode.addEventListener('loadedmetadata', function () {
                resolve(videoNode.duration);
            });
            videoNode.addEventListener('error', function () {
                reject(videoNode.error.message + '(' + videoNode.error.code + ')');
            });
        });

        const URL = window.URL;
        videoNode.src = URL.createObjectURL(file);
        videoNode.remove();
        return promise;
    }

    cancelUpload() {
        this.uploadVideoObject.file = [];
        $('video').trigger('pause');
        this.changeFilterUploadVideoVal = 'recentFirst';
        this.loadingVideo = false;
    }

    uploadVideo() {
        this.toastr.info('Uploading!')
        this.spinner = true;
        this.finalUpload(this.uploadVideoObject.file[0])


    }

    finalUpload(i) {

        const obj: any = {
            name: i.originalName,
            type: 'application/octet-stream',
            URL: null,
            duration: i.duration,
            thumbnail: '',
            size: i.size,
            originalName: i.name.split('.')[0]
        }

        //console.log('** uploadVideos.finalUpload - obj to be sent to createpresignedUrl = ', obj);

        this.urlService.createpresignedUrlV2(obj).subscribe(
            success => {
                i.counter = success.data.counter;
                this.uploadVideoObject.preSignedUrl = success.data.details
                // this.uploadVideoObject.selectedVideos = success.data

                //console.log('Uploading Video');
                this.urlService.uploadVideo(this.uploadVideoObject.preSignedUrl, i).subscribe(
                    success => {
                        i.uploadPercentage = 0;
                        const data: any = {name: i.originalName, counter: i.counter};

                        // WC 08082022 - Transcribing
                        //console.log('Transcribing Video');
                        this.toastr.info('Transcribing Video!')
                        this.transcribingJob(data, i)
                        // WC 08082022

                        //console.log('Transcoding Video');
                        this.toastr.info('Transcoding Video!')
                        this.transcodingJob(data, i)

                    },
                    error => {
                    })
                this.progressPercentage(i)
            },
            error => {
                this.spinner = false;

            })

    }

    progressPercentage(i) {
        const interval = setInterval(() => {
            i.uploadPercentage = this.urlService.getProgress()
            if (i.uploadPercentage > 99.9) {
                clearInterval(interval);
            }
        }, 1000)
    }

    transcodingJob(data, i) {
        this.urlService.transcodeVideo(data).subscribe(
            success => {
                i.uploadPercentage = 0;
                i.isOtherone = true;
                const requestObj: any = {
                    name: i.originalName,
                    type: 'application/octet-stream',
                    counter: i.counter,
                    transcodeId: success.data.transcodeId
                };
                if (i.uploadPercentage) {
                    i.uploadPercentage = i.uploadPercentage + 10
                }
                this.checkStatus(requestObj, i);


            }, error => {
                // let data: any = {name: this.uploadVideoObject.name};
                // this.urlService.deletePresignedURL(data).subscribe(
                //   success => {
                //     this.spinner = false;
                //     this.isUploaded = false
                //     i.uploadPercentage =0
                //     this.toastr.error('Upload not uploaded, please try again!')

                //   }
                // );
            }
        );
    }

    checkStatus(requestObj, i) {
        this.urlService.checkTranscodingStatus(requestObj).subscribe(
            success => {
                // console.log(success.data);
                if (success.data._id != null || success.data._id != undefined) {

                    this.toastr.info('Completed!');

                    this.uploadVideoObject.file.shift();
                    if (this.uploadVideoObject.file.length < 1) {
                        this.cancelUpload();
                        this.callInitialList();


                        this.spinner = false;
                        this.finalCount = 0;
                        this.uploadedCount = 0;
                        i.isOtherone = false;
                    } else {
                        this.uploadVideo();
                        this.spinner = false;
                        i.isOtherone = false;
                    }


                } else {
                    setTimeout(() => {
                        if (i.uploadPercentage < 95) {
                            i.uploadPercentage = i.uploadPercentage + 5;
                        } else {
                            i.uploadPercentage = 98;
                        }

                        this.checkStatus(requestObj, i);
                        //  this.toastr.info('Processing in progress!')
                    }, 1000)
                }

            },
            error => {
                this.checkStatus(requestObj, i);

            }
        );

    }

    // WC 08082022 - Transcribing

    transcribingJob(data, i) {
        this.urlService.transcribeVideo(data).subscribe(
            success => {
                i.transcribePercentage = 0;
                i.isOtherone = true;

                // console.log('success.data.TranscriptionJobName = ', success.data.TranscriptionJobName);

                const requestObj: any = {
                    transcriptionJobName: success.data.TranscriptionJobName
                };

                if (i.transcribePercentage) {
                    i.transcribePercentage = i.transcribePercentage + 10
                }
                this.checkTranscribingStatus(requestObj, i);

            }, error => {

            }
        );
    }

    checkTranscribingStatus(requestObj, i) {

        // console.log('checkTranscribingStatus');

        this.urlService.checkTranscribingStatus(requestObj).subscribe(
            success => {
                // console.log(success.data);
                if (success.data.TranscriptionJobStatus == 'COMPLETED') {

                    this.toastr.info('Transcribing Completed!');

                    this.uploadVideoObject.file.shift();
                    if (this.uploadVideoObject.file.length < 1) {
                        // this.cancelUpload();
                        // this.callInitialList();

                        this.spinner = false;
                        this.finalCount = 0;
                        this.uploadedCount = 0;
                        i.isOtherone = false;
                    } else {
                        this.uploadVideo();
                        this.spinner = false;
                        i.isOtherone = false;
                    }


                } else {
                    setTimeout(() => {
                        if (i.uploadPercentage < 95) {
                            i.uploadPercentage = i.uploadPercentage + 5;
                        } else {
                            i.uploadPercentage = 98;
                        }

                        this.checkTranscribingStatus(requestObj, i);
                        //  this.toastr.info('Processing in progress!')
                    }, 1000)
                }

            },
            error => {
                this.checkTranscribingStatus(requestObj, i);

            }
        );

    }

    // WC 08082022


    /** Return distinct message for sent, upload progress, & response events */
    public getEventMessage(event: HttpEvent<any>, file: File) {
        switch (event.type) {
            case HttpEventType.Sent:
                return `Uploading file "${file.name}" of size ${file.size}.`;

            case HttpEventType.UploadProgress:
                // Compute and show the % done:
                const percentDone = Math.round(100 * event.loaded / event.total);
                return `File "${file.name}" is ${percentDone}% uploaded.`;

            case HttpEventType.Response:
                return `File "${file.name}" was completely uploaded!`;

            default:
                return `File "${file.name}" surprising upload event: ${event.type}.`;
        }
    }

    changeFilterUploadVideo(val) {

        if (val == 'recentFirst') {
            this.allUploadedVideo.sort(function (a, b) {
                if (a.updatedAt > b.updatedAt) {
                    return 1;
                }
                return -1
            })
        } else if (val == 'size') {
            this.allUploadedVideo.sort(function (a, b) {
                if (a.size > b.size) {
                    return -1;
                }
                return 1
            })
        } else if (val == 'duration') {
            this.allUploadedVideo.sort(function (a, b) {
                if (a.duration > b.duration) {
                    return -1;
                }
                return 1
            })
        } else if (val == 'name') {
            this.allUploadedVideo.sort(function (a, b) {
                if (a.name > b.name) {
                    return -1;
                }
                return 1
            })

        }

    }


    openStoryBoard(isChat?: boolean) {

        this.localStorageService.setItem('interactive', 'storyBoard')
        if (isChat) {
            this.localStorageService.setItem('assistCreateVideo', String(isChat));
        }

        this.isTemplate = false;

        this.checkForMaxNoOfPublishedVideo('storyBoard');

    }

    openAIContentCreator(isChat?: boolean) {
        this.localStorageService.setItem('interactive', 'aiContentCreator')
        if (isChat) {
            this.localStorageService.setItem('aiContentCreatorassist', String(isChat));
        }

        this.isTemplate = false;

        this.checkForMaxNoOfPublishedVideo('aiContentCreator');
    }

    openInteractiveVideo(assist = false) {

        this.localStorageService.setItem('interactive', 'isInteractive')
        if (assist) {
            this.localStorageService.setItem('assistCreateVideo', String(assist));
        }
        this.isTemplate = false;

        this.checkForMaxNoOfPublishedVideo('isInteractive');

    }

    openTemplateVideo(isChat?: boolean) {

        this.checkForMaxNoOfPublishedVideo('isTemplate');
        if(isChat) {
            this.localStorageService.setItem('assistCreateVideo', String(isChat));
        }

    }


    tooltipMessage() {
        this.isSearch = false;
        this.localStorageService.setItem('isTree', 'hide')
    }


    goBack() {
        if ((this.location.getState() as any)?.navigationId > 1)
            this.location.back();
        else
            this.router.navigateByUrl('/profile');
    }


    openEditor(isChat?: boolean) {

        this.checkForMaxNoOfProcessedAIVideo();
        if(isChat) {
            this.localStorageService.setItem('assistCreateVideo', String(isChat));
        }

    }

    checkForMaxNoOfPublishedVideo(createVideoTool: string) {
        this.spinner = true;
        this.urlService.getUserSubscriptionPlan().subscribe(success => {

            this.currSubscriptionId = success.data._id;
            this.pricingUrl = '/pricing?subId=' + this.currSubscriptionId;

            //console.log('checkForMaxNoOfPublishedVideo - success.data = ', success.data);

            if (success.data.subscriptionPlanInfo.maxNoOfVideos != undefined) {

                const maxNoOfVideos = success.data.subscriptionPlanInfo.maxNoOfVideos;

                //console.log('maxNoOfVideos = ', maxNoOfVideos);

                this.urlService.getAllMainVideosCountV2().subscribe(success => {

                    const videoCounts = success.data;

                    const getCountOfPublishedVideos = () => {
                        let countOfPublishedVideos = 0;
                        videoCounts.forEach(item => {
                            // Count total published videos
                            if (item._id.isPublished !== undefined && item._id.isPublished)
                                countOfPublishedVideos += item.count;
                        });

                        //console.log('countOfPublishedVideos = ', countOfPublishedVideos);
                        return countOfPublishedVideos;
                    };

                    if (getCountOfPublishedVideos() < maxNoOfVideos) {

                        if (createVideoTool === 'isEditor') {
                            this.localStorageService.setItem('interactive', 'isEditor')
                            this.isTemplate = false;

                            this.router.navigate(['/create-interactive-video'])
                            this.localStorageService.setItem('freeTemp', 'FreeTemplate')
                        } else if (createVideoTool === 'isInteractive') {
                            this.router.navigate(['/create-interactive-video'])
                            this.localStorageService.setItem('freeTemp', 'FreeTemplate')
                        } else if (createVideoTool === 'storyBoard') {
                            this.router.navigate(['/new-create-interactive-video'])
                        } else if (createVideoTool === 'aiContentCreator') {
                            this.router.navigate(['/ai-video-creator'])
                        } else if (createVideoTool === 'isTemplate') {
                            this.isTemplate = true;
                            this.localStorageService.setItem('interactive', 'isTemplate');
                            this.router.navigate(['/upload-template-videos'])
                        }

                    } else {

                        this.pricingUrl = '/pricing?subId=' + this.currSubscriptionId;

                        $('#exceed-max-videos-Modal').modal('show');
                    }
                });
            }
        }).add(() => {
            this.spinner = false;
        });
    }

    //WC 10042023 --- PenTestFixes
    //Will let the AI Request Handler to process or reject the request
    checkForMaxNoOfProcessedAIVideo() {

        this.localStorageService.setItem('interactive', 'isEditor')
        this.isTemplate = false;

        this.router.navigate(['/create-interactive-video'])
        this.localStorageService.setItem('freeTemp', 'FreeTemplate')
    };

    // checkForMaxNoOfProcessedAIVideo() {
    //     this.spinner = true;
    //     this.urlService.getUserSubscriptionPlan().subscribe(success => {

    //         this.currSubscriptionId = success.data._id;
    //         this.pricingUrl = '/pricing?subId=' + this.currSubscriptionId;

    //         //console.log('checkForMaxNoOfProcessedAIVideo - success.data = ', success.data);

    //         if (success.data.subscriptionPlanInfo.maxNoOfAIEditorVideos != undefined) {

    //             const maxNoOfVideos = success.data.subscriptionPlanInfo.maxNoOfAIEditorVideos;

    //             //console.log('Limit of processed AI Videos = ', maxNoOfVideos);

    //             this.urlService.getNoOfProcessedAIVideos(this.localStorageService.getItem("user")).subscribe(success => {

    //                 //console.log('getNoOfProcessedAIVideos = ', success);

    //                 const videoCounts = success.data;

    //                 if (videoCounts < maxNoOfVideos) {

    //                     this.localStorageService.setItem('interactive', 'isEditor')
    //                     this.isTemplate = false;

    //                     this.router.navigate(['/create-interactive-video'])
    //                     this.localStorageService.setItem('freeTemp', 'FreeTemplate')

    //                 } else {

    //                     this.pricingUrl = '/pricing?subId=' + this.currSubscriptionId;

    //                     $('#exceed-max-ai-videos-Modal').modal('show');
    //                 }
    //             });
    //         }
    //     }).add(() => {
    //         this.spinner = false;
    //     });
    //}

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
