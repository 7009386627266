import {Component, Inject, OnInit} from '@angular/core';
import {CrudService} from '../../../services/crud.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {DashboardService} from '../../../services/dashboard.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../../services/localStorage.service';

declare var $;

@Component({
    selector: 'app-pricing-dialog',
    templateUrl: './pricing-dialog.component.html',
    styleUrls: ['./pricing-dialog.component.css']
})
export class PricingDialogComponent implements OnInit {


    spinner: any = true;
    allPlans = [];
    plans = [];
    subscriptionType = 'Monthly';
    isPlanSelectionClicked = false;
    planCounts = {
        monthly: 0,
        yearly: 0,
    }
    currentUserData: any;
    sellerBusinessName: '';
    

    private sellerAccountId: any;
    private payCustomerId: any;
    private paymentPriceId: any;
    private shoppingCartId: any;
    private groupId: any;
    private sellerAppFeePct: any;
    private isZeroAmtPlan = false;
    private isLoggedIn: string;
    sellerOnboardingStatus: string;

    constructor(
        private dashboardService: DashboardService,
        private crudService: CrudService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private localStorageService: LocalStorageService,
        private toastr: ToastrService,
        private dialogRef: MatDialogRef<PricingDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.isLoggedIn = this.localStorageService.getItem('token');
    }


    ngOnInit(): void {

        const that = this;
        $(document).on('mouseover', '.price .price-card .card', function () {
            if (!that.isPlanSelectionClicked) {
                $('.price .price-card .card').removeClass('border-active');
                $(this).addClass('border-active');
            }
        });

        $(document).on('mouseout', '.price .price-card .card', function () {
            if (!that.isPlanSelectionClicked) {
                $('.price .price-card .card').removeClass('border-active');
                $('.price .price-card .initial-active.card').addClass('border-active');
            }
        });

        $('[data-toggle="tooltip"]').tooltip();

        this.spinner = true;

        // this.data.id is the seller id
        this.crudService.getAllPlans(this.data.id).subscribe(res => {
            this.allPlans = res.data;
            if (this.data.groupId) {
                this.allPlans = this.allPlans.filter(x => x.groupId === this.data.groupId);
            }

            if (this.data.unit) {   
                //Admin flow

                if (this.data.unit == "Monthly")
                    this.planCounts.monthly = this.allPlans.filter(x => x.unit === 'Monthly').length;
                else
                    this.planCounts.yearly = this.allPlans.filter(x => x.unit === 'Yearly').length;

                this.check(!this.planCounts.monthly);

                this.spinner = false;
            } else {
                //Buyer flow
                //console.log("Buyer flow - this.data?.selectedPlanId = ", this.data?.selectedPlanId);

                if (!this.data?.selectedPlanId) {
                    //console.log("Buyer flow - plan Id is NOT selected");
                    this.planCounts.monthly = this.allPlans.filter(x => x.unit === 'Monthly').length;
                    this.planCounts.yearly = this.allPlans.filter(x => x.unit === 'Yearly').length;
                    this.check(!this.planCounts.monthly);
                    this.spinner = false;
                } else {
 
                    //console.log("Buyer flow - plan id is selected");
                    
                    const selectedPlan = this.allPlans.filter(x => x._id == this.data.selectedPlanId);
                    
                    if (selectedPlan[0].unit == "Monthly")
                        this.planCounts.monthly = this.allPlans.filter(x => x.unit === 'Monthly').length;
                    else
                        this.planCounts.yearly = this.allPlans.filter(x => x.unit === 'Yearly').length;

                    this.check(!this.planCounts.monthly);
                    this.spinner = false;

                    const plan = this.allPlans.find(x => x._id === this.data.selectedPlanId);
                    
                    if (plan) {
                        // console.log("ngOnInit --- setShoppingCart with plan = ", plan); 
                        this.setShoppingCart(plan);
                    } else {
                        this.spinner = false;
                    }
                };
            };
        });

        // WC 07242023 -- get the seller business name for receiving communication from the seller disclaimer
        this.crudService.getUserInfoV2(this.data.id).subscribe(res => {
            if (res.data) {
                //console.log('this.res.data = ', res.data);
                this.sellerBusinessName = res.data.userInfo?.business?.name;
                this.sellerOnboardingStatus = res.data.userInfo?.accountOnboardingStatus;

            } else
                this.sellerBusinessName = '';
        });

        // get the logged-in user info
        this.dashboardService.getUserData.subscribe((res) => {
            if (res) {
                this.currentUserData = res;
            }
        });
    }

    check(change = true) {
        if (change) {
            this.subscriptionType = this.subscriptionType === 'Monthly' ? 'Yearly' : 'Monthly';
        }
        if (this.subscriptionType === 'Monthly') {
            this.plans = this.allPlans.filter(x => x.unit === 'Monthly');
        } else {
            this.plans = this.allPlans.filter(x => x.unit !== 'Monthly');
        }

        for (let currPlan of this.plans) {
            if (currPlan.price == 0)
                currPlan.isFreemiumPlan = true
            else
                currPlan.isFreemiumPlan = false
        };

        // console.log("*** this.plans = ", this.plans);

    }

    setShoppingCart(plan: any): void {

        if (!this.isLoggedIn) {
            this.dialogRef.close();

            this.localStorageService.setItem('redirectURL', '/community/' + plan.groupId + '?plan_id=' + plan._id);
            this.router.navigate(['login'], { queryParams: { cid: 'EOFF' + plan.userId} });
            return;
        }
        
        // The owner of the plan is the same as the logged-in user
        if (plan.userId === this.currentUserData._id || this.data?.readonly) {

            // console.log("setShoppingCart - plan.userId = ", plan.userId);
            // console.log("setShoppingCart - this.currentUserData._id = ", this.currentUserData._id);
            // console.log("setShoppingCart - this.data?.readonly = ", this.data?.readonly);
            // console.log("RETURN");

            return;
        }
        this.spinner = true;
        this.isPlanSelectionClicked = true;
        this.paymentPriceId = plan.paymentPriceId;
        this.groupId = plan.groupId;
        if (plan.price == 0) {
            this.isZeroAmtPlan = true;
            this.localStorageService.setItem('subscriptionPlanZero', 'true');
        }

        const planIds = plan._id;
        // plan.userId is the seller Id who owns the plan
        const sellerUserId = plan.userId;
        // console.log("setShoppingCart - sellerUserId = ", sellerUserId);

        let payload = null;

        this.crudService.getUserInfoV2(sellerUserId).subscribe(success => {
            // console.log('setShoppingCart - getUserInfoV2(sellerId) = ', success);

            // WC 04022023 --- To allow rejected seller who participates in FreeFuse's partner program to sell their subscriptions
            // console.log("setShoppingCart - success.data.userInfo.business.isPartnerProgram = ", success.data.userInfo.business?.isPartnerProgram);

            if (success.data.userInfo.business.isPartnerProgram != undefined && success.data.userInfo.business.isPartnerProgram) {

                this.setPartnerProgramShoppingCart(plan, success.data);

                return;
            }

            this.sellerAccountId = success.data.userInfo.connectedAccountId;
            this.sellerAppFeePct = success.data.userInfo.appFeePct;

            // WC 02072023 --- To allow rejected seller to sell Freemium plans
            this.sellerOnboardingStatus = success.data.userInfo.accountOnboardingStatus;
            // WC 02072023

            // New shopping cart
            payload = {
                items: [planIds],
                type: 'subscriptionPlan',
                isActive: true,
                groupId: this.groupId,   // This shopping cart is for this groupId
                sellerAccountId: this.sellerAccountId,
                sellerUserId
            };

            this.crudService.setShoppingCart(payload).subscribe(success1 => {
                if (success1.data.error) {

                    this.toastr.error(success1.data.message);
                    this.spinner = false;

                } else {

                    // console.log('shoppingCartId from pricing-dialog page = ', success1.data._id);

                    this.shoppingCartId = success1.data._id;

                    payload = {
                        userId: this.currentUserData._id,
                        email: this.currentUserData.email,
                        firstname: this.currentUserData.firstname,
                        lastname: this.currentUserData.lastname,
                        sellerUserId,
                        sellerAccountId: this.sellerAccountId
                    }

                    // WC 02072023 -- Allow rejected seller to sell Freemium only without creating a stripe customer
                    // console.log('this.sellerOnboardingStatus = ', this.sellerOnboardingStatus);

                    if (this.sellerOnboardingStatus === 'Completed') {
                        // Create Stripe Pay Customer for the Connected Account
                        // createSellerPayCustomer will check wether there's already seller buyer relationship created,
                        // if not then it will create a new one else will return the existing one
                        this.crudService.createSellerPayCustomer(payload).subscribe(success3 => {
                            // console.log('NEW sellerPayCustomer successfully created - success = ', success3.data);
                            this.payCustomerId = success3.data.id;

                            // console.log("this.isZeroAmtPlan = ", this.isZeroAmtPlan);
                            if (!this.isZeroAmtPlan) {
                                //WC 11/14/2023 --- Move createSubscription to community-checkout to accomodate PromoCode
                                //this.createSubscription();
                              
                                this.dialogRef.close();
                                this.router.navigate(['/community-checkout'], { queryParams: { id: this.groupId } });
                            } else {
                                this.localStorageService.setItem('sellerShoppingCartId', this.shoppingCartId);
                                this.localStorageService.setItem('groupSubscriptionId', this.groupId);
                                this.localStorageService.setItem('groupSubscriptionAdminId', sellerUserId);
                                this.localStorageService.setItem('isFollowingSeller', success.data.isFollowing);
                                // console.log('&&& pricing-dialog - isFollowingSeller = ', this.localStorageService.getItem('isFollowingSeller'));

                                this.dialogRef.close();
                                this.spinner = false;
                                this.router.navigate(['/community-payment-confirmation']);
                            }

                        });
                    } else {
                        this.dialogRef.close();
                        this.spinner = false;
                        this.router.navigate(['/community-checkout'], {queryParams: {id: this.groupId}});
                    }
                }
            });

        });
    };

    setPartnerProgramShoppingCart(plan: any, sellerInfo: any): void {
        // ("in SET PARTNER PROGRAM SHOPPING CART");

        // console.log('&&& setPartnerProgramShoppingCart - plan = ', plan);
        // console.log('&&& setPartnerProgramShoppingCart - sellerInfo = ', sellerInfo);

        this.spinner = true;
        this.isPlanSelectionClicked = true;
        this.paymentPriceId = plan.paymentPriceId;
        this.groupId = plan.groupId;
        if (plan.price == 0) {
            this.isZeroAmtPlan = true;
            // console.log('setPartnerProgramShoppingCart - this.isZeroAmtPlan = ', this.isZeroAmtPlan);
            this.localStorageService.setItem('subscriptionPlanZero', 'true');

        }

        const planIds = plan._id;
        // plan.userId is the seller Id who owns the plan
        const sellerUserId = plan.userId;
        const appFeePct = (sellerInfo.userInfo.appFeePct != undefined) ? sellerInfo.userInfo.appFeePct : 0;

        // New shopping cart without sellerAccountId,
        // make the seller is FreeFuse and the seller who participates in Partner Program is FreeFuse's partner for reconciliation later
        const payload = {
            items: [planIds],
            type: 'subscriptionPlan',
            isActive: true,
            groupId: this.groupId,   // This shopping cart is for this groupId
            sellerAccountId: sellerInfo.connectedAccountId,
            sellerUserId,
            isPartnerProgram: true,
            appFeePct

        };

        this.crudService.setShoppingCart(payload).subscribe(success1 => {
            if (success1.data.error) {

                this.toastr.error(success1.data.message);
                this.spinner = false;

            } else {

                this.shoppingCartId = success1.data._id;

                if (this.isZeroAmtPlan) {
                    // console.log("isZeroAmtPlan !!!");
                    this.localStorageService.setItem('sellerShoppingCartId', this.shoppingCartId);
                    // console.log("this.localStorageService.setItem('sellerShoppingCartId') = ", this.localStorageService.getItem('sellerShoppingCartId'));
                    this.localStorageService.setItem('groupSubscriptionId', this.groupId);
                    this.localStorageService.setItem('groupSubscriptionAdminId', sellerUserId);
                    this.localStorageService.setItem('isFollowingSeller', sellerInfo.isFollowing);
                    // console.log("&&& PartnerProgram ZeroPlan - isFollowingSeller = ", this.localStorageService.getItem('isFollowingSeller'));

                    this.dialogRef.close();
                    this.spinner = false;
                    // this.router.navigate(['/partner-checkout'], {queryParams: {id: this.groupId}});
                    this.router.navigate(['/community-payment-confirmation']);
                    return;
                }

                this.crudService.userDetailsV2().subscribe(success => {

                    const payload = {
                        userId: success.data.details._id,
                        email: success.data.details.email,
                        firstname: success.data.details.firstname,
                        lastname: success.data.details.lastname,
                    }

                    // Create Stripe Pay Customer
                    this.crudService.createPayCustomer(payload).subscribe(success => {
                        // console.log("payCustomer successfully created - success = ", success);

                        const payload = {
                            payCustomerId: success.data.id,
                            paymentPriceId: this.paymentPriceId
                        }

                        // Create Stripe Subscription
                        this.crudService.createPaySubscription(payload).subscribe(success => {
                            // console.log("createPaySubscription is successful - success = ", success);
                            this.localStorageService.setItem('subscriptionId', success.data.subscriptionId);
                            this.localStorageService.setItem('clientSecret', success.data.clientSecret);

                            const payload = {
                                shoppingCartId: this.shoppingCartId,
                                clientSecret: success.data.clientSecret,
                                subscriptionId: success.data.subscriptionId,
                                appFeePct: success.data.appFeePct
                            };

                            this.crudService.saveClientSecretToShoppingCart(payload).subscribe();

                            this.dialogRef.close();
                            this.spinner = false;
                            this.router.navigate(['/partner-checkout'], {queryParams: {id: this.groupId}});

                        })
                    })
                })
            }
        });
    };

    createSubscription() {
        const payload = {
            payCustomerId: this.payCustomerId,
            sellerAccountId: this.sellerAccountId,
            paymentPriceId: this.paymentPriceId,
            appFeePct: this.sellerAppFeePct,
        }
        // console.log('createSubscription - payload', payload);

        // Create Stripe Subscription for the Connected Account
        this.crudService.createSellerPaySubscription(payload).subscribe(success => {
            // console.log('createSellerPaySubscription is successful, succcess = ', success);
            if (success.data) {

                // persist the client secret and subscriptionId to the shopping cart
                const payload1 = {
                    shoppingCartId: this.shoppingCartId,
                    clientSecret: success.data.clientSecret,
                    subscriptionId: success.data.subscriptionId,
                    appFeePct: success.data.appFeePct
                };

                this.crudService.saveClientSecretToShoppingCart(payload1).subscribe();
            }

            this.dialogRef.close();
            // this.router.navigate(['community-checkout']);
            this.router.navigate(['/community-checkout'], { queryParams: { id: this.groupId } });

        }).add(() => this.spinner = false);
    }

    btnJoin(){

    }
}
