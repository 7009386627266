import {Injectable} from '@angular/core';
import JSZip from 'jszip';

interface SubtitleType {
    name: string;
    url: string;
}

@Injectable({
    providedIn: 'root'
})
export class ZipService {

    constructor() {
    }

    async compress(subtitles: SubtitleType[], filename) {

        // Function to download a file from a given URL
        async function downloadFile(url) {
            const response = await fetch(url);
            return await response.blob();
        }

        // Create a new zip instance
        const zip = new JSZip();
        try {
            for (const subtitle of subtitles) {
                const fileName = subtitle.name;
                // Download the file
                const fileBlob = await downloadFile(subtitle.url);
                // Add the downloaded file to the zip
                zip.file(fileName, fileBlob);
            }
            // Generate the zip file
            const zipBlob = await zip.generateAsync({type: 'blob'});
            // Create a temporary anchor element to trigger the download
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(zipBlob);
            downloadLink.download = filename + '.zip';
            downloadLink.style.display = 'none';
            document.body.appendChild(downloadLink);
            // Click the anchor to trigger the download
            downloadLink.click();
            // Clean up the temporary anchor element
            document.body.removeChild(downloadLink);
        } catch (error) {
            console.error('Error while processing:', error);
        }
    }
}
