import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CrudService } from 'src/app/services/crud.service';
import { environment } from 'src/environments/environment';
declare var $;

@Component({
  selector: 'app-minimalist-template',
  templateUrl: './minimalist-template.component.html',
  styleUrls: ['./minimalist-template.component.css']
})
export class MinimalistTemplateComponent implements OnInit {

  @Input() sellerInformation;
  @Input() subscriptionGroups = [];
  @Input() nonSubscriptionGroups = [];
  @Input() welcomeVideo: any;
  @Input() spinner: any = false;
  @Input() allGroups: any = [];
  @Input() isOwnCommunity;
  @Input() logoPosition;
  @Input() editProfileObject:any;

  @Output() showPlans = new EventEmitter();
  @Output() invite = new EventEmitter();
  @Output() openReorderModal = new EventEmitter();
  @Output() downloadSubscribers = new EventEmitter();
  @Output() createAnnouncement = new EventEmitter();
  @Output() onMyGroup = new EventEmitter();
  @Output() uploadWelcomeVideo = new EventEmitter();
  @Output() openUpdateBusinessModal = new EventEmitter();

  currentTab = 'subscription';
  totalGroup = 7;
  updatedLogoPosition:any;

  constructor(public crudService: CrudService) { }

  ngOnInit(): void {
    //Default Minimalist logo position to the left
    this.logoPosition = 'left'
    if (this.currentTab === 'subscription') {
      this.allGroups = this.subscriptionGroups;
    } else {
      this.allGroups = this.nonSubscriptionGroups;
    }  

    if (this.editProfileObject?.business?.templateName == "Minimalist") {
      // this.welcomeVideo = environment.currentDomain + "embed/" + this.editProfileObject.welcomeVideoId;
      this.welcomeVideo = environment.currentDomain + "embed/" + this.editProfileObject.welcomeVideoId + "?autoplay=true&mute=1";
    } 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['logoPosition']) {
      this.updatedLogoPosition = changes['logoPosition'].currentValue;      
    }  
  }

  tabChange(tab) {
    this.currentTab = tab;
    
    this.totalGroup = 7;
    if (this.currentTab === 'subscription') {
      this.allGroups = this.subscriptionGroups;
    } else {
      this.allGroups = this.nonSubscriptionGroups;
    }
    if (this.totalGroup >= this.allGroups.length) {
      this.totalGroup = this.allGroups.length;
    }
  }

  formatPhoneNumber(phoneNumberString) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1,2})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
    }
    return '';
  }

  viewAllGroup() {
    this.totalGroup = this.allGroups.length;
  }

  showPricingPlans() {
    this.showPlans.emit();
  }

  openBusinessModal() {
    if (this.editProfileObject?.business?.templateName == "Minimalist") {
      this.openUpdateBusinessModal.emit();
    }
  }

  inviteModal() {
    this.invite.emit();
  }

  openReorderGroupModal() {
    $("#rearrangeGroupsDialog").modal("show");
    // this.openReorderModal.emit();
  }

  downloadSubscribersUser() {
    this.downloadSubscribers.emit();
  }

  createAnnouncementModal() {
    this.createAnnouncement.emit();
  }

  onMyGroupClick(item) {
    this.onMyGroup.emit(item);
  }

  uploadWelcomeVideoClick() {
    this.uploadWelcomeVideo.emit();
  }

  fliterGroupNameWithSpecialChar(groupName:any){
    return groupName.replace(/&amp;/g, '&');
  }

  drop(event: CdkDragDrop<string[]>) {
    let data = [];
    if (this.currentTab === "subscription") {
      moveItemInArray(
        this.subscriptionGroups,
        event.previousIndex,
        event.currentIndex
      );
      data = this.subscriptionGroups.map((x, i) => {
        return { groupId: x._id, currIndex: i };
      });
    } else {
      moveItemInArray(
        this.nonSubscriptionGroups,
        event.previousIndex,
        event.currentIndex
      );
      data = this.nonSubscriptionGroups.map((x, i) => {
        return { groupId: x._id, currIndex: i };
      });
    }
    this.crudService.reorderGroups(data).subscribe();
  }
 
}
