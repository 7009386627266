import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-video-comment-type-dialog',
  templateUrl: './video-comment-type-dialog.component.html',
  styleUrls: ['./video-comment-type-dialog.component.css']
})

export class VideoCommentTypeDialogComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
