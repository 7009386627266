import {Injectable} from '@angular/core';
import spokenLanguages from './spokenLanguages';
import * as _ from 'lodash'
import languages from './languages';

@Injectable({ providedIn: 'root' })
export class UtilityService {
  private spokenLanguages = spokenLanguages;
  isPathTracking = false;
  constructor() {}

  checkIsUrlMatch(url: string): boolean {
    const devRegex =
      /https?:\/\/videoplyback\-encoding\-bucket\.s3\.amazonaws\.com\//;
    const prodRegex =
      /https?:\/\/freefuse\-encoding\-bucket\.s3\.amazonaws\.com\//;
    const devMatch = url.match(devRegex);
    const prodMatch = url.match(prodRegex);
    return !!((devMatch && devMatch.length) || (prodMatch && prodMatch.length));
  }

  sanitizeString = (str: string, isSpacePreserved: boolean) => {

    if (str === null || str === undefined) {
      return str;
    }

    // const fileExt = str.substring(str.length-4);
    const fileExt = str.split('.').pop();
    // console.log("sanitizingString - fileExt = ", fileExt);
    const strArr = str.split('.');
    let newStr = '';
    for (let i = 0; i < strArr.length; i++) {
      if (i < strArr.length - 1) {
        newStr += strArr[i];
      }
    }
    // str = str.substring(0, str.length - 4);
    // console.log("sanitizingString - final str to be sanitized = ", str);

    str = newStr.replace(/[^a-zA-Z0-9-_\s]/g, '');
    // console.log("str after replacing with regex - str = ", str);

    // Remove spaces
    // \s: matches any whitespace symbol: spaces, tabs, and line breaks
    // +: match one or more of the preceding tokens (referencing \s)
    // g: the g at the end indicates iterative searching throughout the full string
    if (!isSpacePreserved)
      str = str.replace(/\s+/g, '').trim();

    str = str + '.' + fileExt;

    // console.log("In sanitizeString -- sanitizing result : ", str);

    return str;
  };

  onGetDuration(file) {
    // const reader = new FileReader();
    // reader.readAsDataURL(file);
    const videoNode = document.createElement('video');
    const promise = new Promise((resolve, reject) => {
      videoNode.addEventListener('loadedmetadata', () => {
        resolve(videoNode.duration);
      });
      videoNode.addEventListener('error', () => {
        reject(videoNode.error.message + '(' + videoNode.error.code + ')');
      });
    });

    const URL = window.URL;
    videoNode.src = URL.createObjectURL(file);
    videoNode.remove();
    return promise;
  }

  getVideoDimensions(videoUrl): Promise<any> {

    const videoNode = document.createElement('video');

    const promise = new Promise((resolve, reject) => {
      videoNode.addEventListener('loadedmetadata', () => {
        resolve({
          width: videoNode.videoWidth,
          height: videoNode.videoHeight
        });
      });
      videoNode.addEventListener('error', () => {
        reject(videoNode.error.message + '(' + videoNode.error.code + ')');
      });
    });

    videoNode.src = videoUrl;
    videoNode.remove();
    return promise;
  }

  // WC 09042022
  convertSecondsToHHMMSSFormat = (decimalSeconds) => {

    // console.log("decimalSeconds = " + decimalSeconds);
    if (!decimalSeconds) {
      decimalSeconds = 0;
    }
    const hh = Math.floor(decimalSeconds / 3600);

    let hhStr = '';
    if (hh < 10)
      hhStr = '0' + String(hh);
    else
      hhStr = String(hh);

    // console.log("hhStr = " + hhStr);

    const reminderSec = (decimalSeconds % 3600);

    // console.log("reminderSec = " + reminderSec);

    const mm = Math.floor(reminderSec / 60)
    // console.log("mm = " + mm);

    let mmStr = '';

    if (mm < 10)
      mmStr = '0' + String(mm);
    else
      mmStr = String(mm);

    // console.log("mmStr = " + mmStr);

    const ss = (reminderSec % 60);
    // console.log("ss = " + ss);

    let ssStr = '';
    if (ss < 10)
      ssStr = '0' + String(ss);
    else
      ssStr = String(ss);

    const pos = ssStr.indexOf('.');

    if (pos > 0) {
      ssStr = ssStr.substring(0, pos);
    }

    // console.log("ssStr = " + ssStr);

    // console.log("timeStr = " + timeStr);

    return hhStr + ':' + mmStr + ':' + ssStr;
  }

  secondsToDhms(seconds) {
    if (!seconds) {
      return '';
    }
    seconds = Number(seconds)
    const d = Math.floor(seconds / (3600 * 24))
    const h = Math.floor((seconds % (3600 * 24)) / 3600)
    const m = Math.floor((seconds % 3600) / 60)
    const s = Math.floor(seconds % 60)
    // console.log(d, h, m, s)
    const dDisplay = d > 0 ? d + (d === 1 ? ' day, ' : ' days, ') : ''
    const hDisplay = h > 0 ? h + (h === 1 ? ' hour, ' : ' hours, ') : ''
    const mDisplay = m > 0 ? m + (m === 1 ? ' minute, ' : ' minutes, ') : ''
    const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : ''
    return dDisplay + hDisplay + mDisplay + sDisplay
  }

  secondsToMin(seconds) {
    if (!seconds) {
      return '00:00';
    }
    seconds = Number(seconds);
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    if (hours > 0) {
      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    } else {
      return `${formattedMinutes}:${formattedSeconds}`;
    }
  }

  // WC 09042022

  calculatePercentage(a: any, b: any): any {
    const percentage = (a / b) * 100;
    return Math.round(percentage);
  }

  checkIsUrl(url: string): boolean {
    const devRegex = /https?:\/\/videoplyback\-encoding\-bucket\.s3\.amazonaws\.com\//;
    const prodRegex = /https?:\/\/freefuse\-encoding\-bucket\.s3\.amazonaws\.com\//;
    const devMatch = url.match(devRegex);
    const prodMatch = url.match(prodRegex);
    return !!((devMatch && devMatch.length) || (prodMatch && prodMatch.length));
  }

  checkIsVideoUrl(url: string): boolean {
    const devRegex = /https?:\/\/videoplyback\-encoding\-bucket\.s3\.amazonaws\.com\//;
    const prodRegex = /https?:\/\/freefuse\-encoding\-bucket\.s3\.amazonaws\.com\//;
    const pexelRegex = /https?:\/\/videos\.pexels\.com\/video\-files\/\d+\/\d+\-\w+\.mp4/;
    const devMatch = url.match(devRegex);
    const prodMatch = url.match(prodRegex);
    const pexelMatch = url.match(pexelRegex);
    return !!((devMatch && devMatch.length) || (prodMatch && prodMatch.length) || (pexelMatch && pexelMatch.length));
  }


  listPaths(node, startNodeId, endNodeId, path = [], isStartNode = false) {
    let color = this.getRandomColor();
    if (node._id === startNodeId) {
      isStartNode = true;
    }

    if (isStartNode) {
      path.push({
        URL: node.URL,
        poster: node.poster,
        time: node.time,
        name: node.name,
        _id: node._id
      });
    }

    if (node._id === endNodeId && isStartNode) {
      path.forEach((i) => i['color'] = color)
      return [path];
    }

    if (!node.children || node.children.length === 0) {
      return [];
    } else {
      let paths = [];
      for (const child of node.children) {
        const childPaths = this.listPaths(child, startNodeId, endNodeId, [...path], isStartNode);
        paths = paths.concat(childPaths);
      }
      return paths;
    }
  }

  updateConnectorChildren(node, path = []) {

    // Include current node in the path
    path.push(node.name);

    // Check if the node has connector children
    if (node.connectorChildren && node.connectorChildren.length > 0 ) {
        //console.log(path.join(" -> "));

        for (const connectorChild of node.connectorChildren ) {
          if (node.children)
            node.children.push(connectorChild);
          else {
            node.children = [];
            node.children.push(connectorChild);
          }
        }
    } else {
        // Recursively call for each child
        node.children.forEach(child => this.updateConnectorChildren(child, [...path]));
    }

    // console.log("path = ", path);
    // console.log("node = ", node);
  
  }

  parentNode(arr, id) {
    // console.log("arr = ", arr);
    for (const item of arr) {
      if (item._id === id) {
        // console.log("item is the end node");

        return [{
          URL: item.URL,
          poster: item.poster,
          time: item.time,
          name: item.name,
          _id: item._id
        }];
      } else if (item.children && item.children.length) {
        const t = this.parentNode(item.children, id);

        // console.log("t.push of item = ", item);
        if (t !== false) {
          t.push({
            URL: item.URL,
            poster: item.poster,
            time: item.time,
            name: item.name,
            _id: item._id
          });
          return t;
        }
      }
    }
    return false;
  }

  getRandomColor() {
    // const letters = '0123456789ABCDEF';
    // let color = '#';
    // for (let i = 0; i < 6; i++) {
    //   color += letters[Math.floor(Math.random() * 16)];
    // }
    // return color;

    // const purpleColor = [200, 160, 255];
    // const variationRange = 20;
    // const randomVariation = () => Math.floor(Math.random() * (2 * variationRange + 1)) - variationRange;

    const purpleColor = [187,178,47];
    const variationRange = 15;
    const randomVariation = () => Math.floor(Math.random() * (3 * variationRange + 1)) - variationRange;
    const randomColor = purpleColor.map(color => Math.max(0, Math.min(255, color + randomVariation())));
    const colorHexCode = `#${randomColor.map(color => color.toString(16).padStart(2, '0')).join('')}`;
    
    return colorHexCode;
  }

  getParentNodesForEndNodes = (arr, endId, parentIds = []) => {
    const parentNames = [];

    for (const item of arr) {
      if (item.endNodes) {
        for (const endNode of item.endNodes) {
          if (endNode._id === endId) {
            parentNames.push([
              ...parentIds,
              {
                _id: item._id,
                name: item.name
              }, {
                _id: endNode._id,
                name: endNode.name,
              }]);
          }
        }
      }  else if (item.children && item.children.length) {
        const newParentIds = [
          ...parentIds,
          {
            _id: item._id,
            name: item.name
          }];
        const childParentNames = this.getParentNodesForEndNodes(item.children, endId, newParentIds);
        parentNames.push(...childParentNames);
      }
    }

    return parentNames;
  };

  async urlToFile(url, filename) {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], filename);
  }

  // WC 07012023 --- To get the transcription language code
  getTranscriptLang(subtitleUrl) {
    if (subtitleUrl) {
      // console.log('subtitleUrl = ', subtitleUrl);

      const pos = subtitleUrl.lastIndexOf('/');
      let transcriptLangCode = subtitleUrl.substring(pos + 1);
      // console.log('transcriptLangCode = ', transcriptLangCode);

      transcriptLangCode = transcriptLangCode.split('.')[0];
      // console.log('transcriptLangCode = ', transcriptLangCode);

      let transcriptLang;
      
      if(transcriptLangCode.includes('-')) {
        transcriptLang = this.spokenLanguages.filter(x => x.code === transcriptLangCode)[0];
      } else {
        transcriptLang = languages.filter(x => x.code === transcriptLangCode)[0];
      }

      // console.log('transcriptLang = ', transcriptLang);

      if (transcriptLang === undefined) {
        transcriptLang = {
          language: 'English, US',
          code: 'en-US'
        };

        // console.log('transcriptLang = ', transcriptLang);
      }
      return transcriptLang;
    }
    return null;
  }

  areArraysEqual(array1, array2) {
    if (array1.length !== array2.length) {
      return false; // Different lengths, arrays are not equal
    }

    const sortedArray1 = array1.slice().sort();
    const sortedArray2 = array2.slice().sort();

    return sortedArray1.every((element, index) => element === sortedArray2[index]);
  }

  // loadScript(src) {
  //   const scriptExists = this.isScriptLoaded(src);
  //   if (!scriptExists) {
  //     const script = document.createElement('script');
  //     script.src = src;
  //     script.type = 'text/javascript';
  //     script.async = true;
  //     document.body.appendChild(script);
  //   }
  // }
  //
  // removeScript(src: string) {
  //   const scripts = Array.from(document.getElementsByTagName('script'));
  //   const scriptToRemove = scripts.find(script => script.src === src);
  //
  //   if (scriptToRemove) {
  //     document.body.removeChild(scriptToRemove);
  //   }
  // }
  //
  // isScriptLoaded(src: string): boolean {
  //   const scripts = Array.from(document.getElementsByTagName('script'));
  //   return scripts.some(script => script.src === src);
  // }

  flattenWithHierarchy(data) {
    const flattenedData = [];
    for (const item of data) {
      const itemCP = _.cloneDeep(item);
      delete itemCP.children;
      flattenedData.push(itemCP);
      if (item.children) {
        const childData = item.children;
        flattenedData.push(...this.flattenWithHierarchy(childData));
      }
    }
    return flattenedData;
  }

  linkify(plainText): string {
    let replacedText;
    let replacePattern1;
    let replacePattern2;
    let replacePattern3;

    // URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = plainText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="https://$2" target="_blank">$2</a>');

    // Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
  }
}
