import {AfterViewInit, Component, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CrudService} from '../../../../services/crud.service';
import {debounceTime, distinctUntilChanged, filter, tap} from 'rxjs/operators';
import {MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-upload-link-dialog',
    templateUrl: './upload-link-dialog.component.html',
    styleUrls: ['../create-video.component.css']
})
export class UploadLinkDialogComponent implements OnInit, AfterViewInit {
    searchChange$ = new BehaviorSubject('');
    allUrls = [];
    isPreviewLoading = false;
    linkPreviewData: any;

    constructor(
        // @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialogRef<UploadLinkDialogComponent>,
        public urlService: CrudService,
        public toastrService: ToastrService,
    ) {
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        this.searchChange$
            .asObservable()
            .pipe(
                filter(Boolean),
                debounceTime(500),
                distinctUntilChanged(),
                tap((event) => {
                    this.onSearchItem(event);
                })
            )
            .subscribe();
    }

    onSearchItem(url: any) {
        const data = {url};
        this.isPreviewLoading = true;
        this.linkPreviewData = null;
        this.urlService.getLinkPreview(data).subscribe((response) => {
            this.linkPreviewData = response.data;
            this.isPreviewLoading = false;
        }, (err) => {
            this.isPreviewLoading = false;
        })
    }

    onSearchValue(event: any) {
        const validURL = (str) => {
            let url;
            try {
                url = new URL(str);
            } catch (_) {
                return false;
            }
            return url.protocol === 'http:' || url.protocol === 'https:';
        }
        if (validURL(event.target.value)) {
            this.searchChange$.next(event.target.value);
        } else {
            this.toastrService.error('Please enter valid url')
        }
    }


    onClickUrlCard(linkPreviewData: any) {
        if (linkPreviewData) {
            // uploadLinkPreview
            const data = {
                name: this.linkPreviewData.title,
                fullName: this.linkPreviewData.title,
                type: 'url',
                thumbnail: this.linkPreviewData.images.length > 0 ? this.linkPreviewData.images[0] : this.linkPreviewData.favicons[0],
                size: 0,
                originalName: this.linkPreviewData.title,
                isAIEditorVideo: false,
                URL: this.linkPreviewData.url
            }
            this.urlService.uploadLinkPreview(data).subscribe((res) => {
                this.dialog.close(res.data);
                this.linkPreviewData = undefined;
            })
        }

    }
}
