import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CrudService} from 'src/app/services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $;

@Component({
    selector: 'resetPassword',
    templateUrl: './resetPassword.component.html',
    styleUrls: ['./resetPassword.component.css']
})
export class ResetPasswordComponent implements OnInit {

    constructor(private urlService: CrudService,
                private router: Router,
                private localStorageService: LocalStorageService,
                private toastr: ToastrService) {
    }
  resetPasswordObj:any = {email:'', invalid: false, spinner: false}

  ngOnInit() {
    if(!!this.localStorageService.getItem('token')) {
        // this.router.navigate(['dashboard']);
        this.router.navigate(['profile']);
    }
  }
  changeUserLogin(id)
    {
        if ($('#' + id).val() != '') {
            $('#' + id).siblings('.input-field').addClass('video_input_focus');
            $('#' + id).addClass('focusIn');
        } else {
            $('#' + id).siblings('.input-field').removeClass('video_input_focus');
        }
     }

     onSubmit()
     {

        this.resetPasswordObj.invalid = false;
        this.resetPasswordObj.invalidEmailError = false;

        this.resetPasswordObj.spinner = true;

         const obj: any = {email: this.resetPasswordObj.email}
        this.localStorageService.removeItem('anonymousToken')
        this.urlService.forgotPassword(obj).subscribe(
          success =>{
            this.resetPasswordObj.spinner = false;
            this.resetPasswordObj.invalid = false;
            this.resetPasswordObj.email = '';
            this.toastr.success(success.message);
          },
          error => {
            this.resetPasswordObj.spinner = false;
            this.toastr.error(error.error.error)
            if (error.error.data.toLowerCase() === ('No user found with ' + this.resetPasswordObj.email).toLowerCase()) {
              this.resetPasswordObj.invalidEmailError = true;
              this.resetPasswordObj.invalid = true;
            }
          })
     }

}
