import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {CrudService} from 'src/app/services/crud.service';
import {DashboardService} from '../../services/dashboard.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {defaultStepOptions, steps} from '../../tour-data';
import {ShepherdService} from 'angular-shepherd';
import {takeUntil} from 'rxjs/operators';
import {LocalStorageService} from '../../services/localStorage.service';
import { DomSanitizer, SafeHtml} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

declare var CanvasJS: any;
declare var $: any;

@Component({
  selector: 'app-new-dashboard',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.css']
})
export class NewDashboardComponent implements OnInit, OnDestroy {
  isColumnAreaShow = false;
  announcement: SafeHtml;
  userData: any;
  spinner = true;
  currentTab: 'community' | 'videos' | 'activity' | 'invites' | 'badges' = 'videos';
  currentActivityTab: 'watch_history' | 'likes' | 'shares' = 'watch_history';
  currentBadgeTab: 'earned' | 'creation' = 'creation';
  currentInviteTab: 'received' | 'sent' = 'received';
  myVideos: any[] = [];
  highlights: any[] = [];
  activities: any[] = [];
  likes: any[] = [];
  shares: any[] = [];
  watchHistory: any[] = [];
  groups = [];
  badges = {
    creation: [],
    earned: [],
    creationCount: 0,
    earnedCount: 0,
  };
  similarVideos = {
    limit: 6,
    page: 1,
    data: []
  };
  membershipName = '';
  spinners = {
    sixth: true,
    community: true,
    badges: true,
    videos: true,
    similar: true
  };
  isSeller = false;
  isCommunitySubscriber = false;
  userId: string;
  isInitTour = false;
  paramsValue:any;
  env:any = environment
  doNotShowCommunityDetailModal = false;


  private pagination = {
    group: {limit: 4, page: 1},
    badges: {limit: 8, page: 1},
    myVideo: {limit: 4, page: 1},
    highlight: {limit: 4, page: 1},
    watched: {limit: 4, page: 1},
    activity: {limit: 4, page: 1},
    likes: {limit: 4, page: 1},
    share: {limit: 4, page: 1},
  };
  private subscription: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
      private readonly dashboardService: DashboardService,
      private readonly crudService: CrudService,
      private router: Router,
      private route: ActivatedRoute,
      private shepherdService: ShepherdService,
      private localStorageService: LocalStorageService,
      private sanitizer: DomSanitizer
  ) {
    // this.dashboardService.clearUserData();
    // this.dashboardService.onGetUserData();
    this.spinners.sixth = true;

    this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(params => {
      this.paramsValue = params

      if (params.subscriber != undefined && params.subscriber == 'true') {
        this.dashboardService.clearUserData();
        this.dashboardService.onGetUserData();
      }
    })


  }

  ngOnInit(): void {
    console.log("Dashboard - v17");
    
    this.subscription = this.dashboardService.columnAreaShow.subscribe(
        (val) => {
          // console.log(this.isColumnAreaShow,val)
          this.isColumnAreaShow = val;
        }
    );
    this.subscription = this.dashboardService.getUserData
        .pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
          if (res) {
            this.isSeller = this.localStorageService.getItem('isSeller') === 'true';
            if (this.isSeller) {
              if(this.paramsValue.tab === "activity"){
                this.currentTab = 'activity'
                const qParams: Params = {};
                this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: qParams,
                  queryParamsHandling: ''
              });
              }else{
                this.currentTab = 'community';
              }
              this.getGroups('ADMIN');
            } else {
              if(this.paramsValue.tab === "activity"){
                this.currentTab = 'activity'
                const qParams: Params = {};
                this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: qParams,
                  queryParamsHandling: ''
              });
              }
              // this.currentTab = 'community';
              this.getGroups('SUBSCRIBER');
              // this.spinners.community = false;
            }
            this.userData = res;
            // console.log(this.userData)
            this.userId = this.userData._id;
            this.getLikesVideos(
                this.userId,
                this.pagination.likes.limit,
                this.pagination.likes.page,
                true
            );
            this.getShareVideos(
                this.userId,
                this.pagination.share.limit,
                this.pagination.share.page,
                true
            );
            this.getWatchHistory(
                this.userId,
                this.pagination.watched.limit,
                this.pagination.watched.page,
                true
            );

            this.getInvitations(
                this.userId,
                this.pagination.watched.limit,
                this.pagination.watched.page,
                true
            );

            this.getBadges();
            // this.getHighlightVideos(
            //     this.userId,
            //     this.pagination.highlight.limit,
            //     this.pagination.highlight.page,
            //     true
            // );

            // WC 06212022
            // this.getShoppingCart();

            this.getMembershipName()
            // WC 06212022

            /*check for product tour*/
            setTimeout(() => {
              if (!this.isInitTour) {
                this.crudService.getDoNotShowItem({item: 'Welcome Product Tour'}).subscribe((res1) => {
                  if (res1.data.length === 0) {
                    const sidebar = document.getElementsByClassName('sidebar');
                    if (sidebar.length) {
                      this.initTour();
                      this.crudService.setDoNotShowItem({item: 'Welcome Product Tour'}).subscribe();
                    }
                  }
                });
              }
            }, 1000);
          }
    });
    this.getUserInfo();
    this.callApisOnInit();
    this.getAnnouncement();
    // this.getSimilarVideos(true);
    this.getVideosYouMightLike(true);

    // this.openModalForCommunityDetail()

  }


  getUserInfo() {
    this.crudService.userDetailsV2().subscribe((res) => {
      if (res.data) {
        this.crudService.getUserInfoV2(res.data.details._id).subscribe((response) => {
          if (!response.data.userInfo.category) {
            this.router.navigate(['/complete-profile']);
          } else {
            let result = { ...res.data.details, ...response.data.userInfo };
            result.totalmainvideos = res.data.totalmainvideos;
            result.createdAt = res.data.details.createdAt;
            this.userData = result
          }
        })
      }
    });
  }


  callApisOnInit() {
    this.getMyVideos(
        true,
        this.pagination.myVideo.limit,
        this.pagination.myVideo.page,
        'recent',
        true
    );
    // this.getActivityVideos(
    //     this.pagination.activity.limit,
    //     this.pagination.activity.page,
    //     true
    // );
  }

  callActivitiesApi() {

  }

  onClickSkip() {
    this.dashboardService.onClickSkipImage();
  }

  onClickTab(currentTab: any) {
    // if (currentTab === 'tab2') {
    //   this.initOwlAndChart();
    // }
    this.currentTab = currentTab;
  }

  onClickActivityTab(currentTab: any) {
    this.currentActivityTab = currentTab;
  }

  onClickBadgesTab(currentTab: any) {
    this.currentBadgeTab = currentTab;
  }

  onClickInvitesTab(currentTab: any) {
    this.currentInviteTab = currentTab;
  }

  getMyVideos(isPublished: any, limit: number, page: number, sort: string, onInit?: boolean) {
    this.spinners.videos = true;
    this.subscription = this.crudService
        .getInteractiveVideoV2(null, isPublished, limit, page, sort)
        .subscribe(
            (response) => {
              if (!Array.isArray(response.data)) {
                return;
              }
              if (!response.error) {
                if (onInit) {
                  this.myVideos = response.data;
                } else {
                  this.myVideos.push(...response.data);
                }
              }
              this.spinners.videos = false;
            },
            (error) => (this.spinners.videos = false)
        );
  }

  getActivityVideos(limit: number, page: number, onInit?: boolean) {
    this.subscription = this.crudService
        .getUserActivityV2(limit, page)
        .subscribe((response) => {
          if(!Array.isArray(response.data)){
            return;
          }
          if (!response.error) {
            if (onInit) {
              this.activities = response.data;
            } else {
              this.activities.push(...response.data);
            }
          }
        });
  }

  getLikesVideos(
      userId: string,
      limit: number,
      page: number,
      onInit?: boolean
  ) {
    this.subscription = this.crudService
        .getLikedVideosV2(limit, page, userId)
        .subscribe((response) => {
          if(!Array.isArray(response.data)){
            return;
          }
          if (!response.error) {
            if (onInit) {
              this.likes = response.data;
            } else {
              this.likes.push(...response.data);
            }
          }
        });
  }

  getShareVideos(
      userId: string,
      limit: number,
      page: number,
      onInit?: boolean
  ) {
    this.subscription = this.crudService
        .getUserShareV2(userId, limit, page)
        .subscribe((response) => {
          if(!Array.isArray(response.data)){
            return;
          }
          if (!response.error) {
            if (onInit) {
              this.shares = response.data;
            } else {
              this.shares.push(...response.data);
            }
          }
        });
  }

  getHighlightVideos(
      userId: string,
      limit: number,
      page: number,
      onInit?: boolean
  ) {
    this.subscription = this.crudService
        .getViewerChoiceHighlightV2(userId, limit, page)
        .subscribe((response) => {
          if (!Array.isArray(response.data)) {
            return;
          }
          if (!response.error) {
            if (onInit) {
              this.highlights = response.data;
            } else {
              this.highlights.push(...response.data);
            }
          }
          for (const i of this.highlights) {
            i.publishedVideo.currentLevel = 2;
            if (i.childVideos.length) {
              i.childVideos = [...i.childVideos[0]];
            }
            if (i.publishedVideo.currentLevel >= i.publishedVideo.totallevel) {
              i.nextDisabled = true;
            }
          }


          this.spinners.sixth = false;
          this.initOwlAndChart();

        });
  }

  getWatchHistory(
      userId: string,
      limit: number,
      page: number,
      onInit?: boolean
  ) {
    this.subscription = this.crudService.getWatchHistoryV2(userId, limit, page)
        .subscribe(response => {
          if (!Array.isArray(response.data)) {
            return;
          }
          if (!response.error) {
            if (onInit) {
              this.watchHistory = response.data;
            } else {
              this.watchHistory.push(...response.data);
            }
          }
          // console.log(this.watchHistory)
        });
  }

  getInvitations(
      userId: string,
      limit: number,
      page: number,
      onInit?: boolean
  ) {
    // {Username} is inviting you to collab on [name of FreeFuse video] (if no name, write in "FreeFuse Draft")
    // Requests for Response on Open Collab Board
    // {Username} requests a response on [name of board] board (if no name, write in "Open Collab")
    // Invite to join community
    // {Username} is inviting you to join [community name]
    // Invite to join group
    // {Username} is inviting you to join [group name]
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.shepherdService.isActive) {
      this.shepherdService.complete();
      // console.log("calledd");
      
      }
  }

  showVideo(videoId: any) {

    this.router.navigate(['videos'], { queryParams: { video: videoId } });
  }

  // getShoppingCart() {
  //
  //   const shoppingCartId = this.localStorageService.getItem('shoppingCartId');
  //
  //   if (this.shoppingCartId != null) {
  //
  //     this.crudService.getShoppingCart(this.shoppingCartId).subscribe(success => {
  //
  //       // shopping cart is active
  //       if (success.data.isActive && success.data.shoppingCartItems != undefined) {
  //         this.noOfShoppingCartItems = success.data.shoppingCartItems.length;
  //       } else
  //         this.noOfShoppingCartItems = 0;
  //
  //     });
  //
  //   } else {
  //
  //     this.noOfShoppingCartItems = 0;
  //
  //     // Check if there's an active shopping cart by this user
  //     this.crudService.getShoppingCartByUser().subscribe(success => {
  //
  //       // There's an active shopping cart
  //       if (success.data != null) {
  //         this.localStorageService.setItem('shoppingCartId', success.data._id);
  //
  //         // shopping cart is active and there's an item in the shopping cart
  //         if (success.data.isActive && success.data.shoppingCartItems != undefined) {
  //           this.noOfShoppingCartItems = success.data.shoppingCartItems.length;
  //         };
  //       };
  //     });
  //   };
  // }

  // checkout() {
  //
  //   // This needed to show the shopping cart when the user on the checkout and the shopping cart is hidden
  //   // and they click on the shopping cart to see the shopping cart
  //   // Causing issue when the current page is not checkout page
  //   // window.location.reload();
  //
  //   if (this.router.url.indexOf('/checkout') !== -1)
  //     window.location.reload()
  //   else {
  //     if (this.localStorageService.getItem('clientSecret'))
  //       this.router.navigate(['checkout'], { queryParams: { subscription_clientSecret: this.localStorageService.getItem('clientSecret') }});
  //   }
  //
  // }
  // WC 06122022

  // WC 07122022 - get membership name
  getMembershipName() {

    if (this.userData.isSubscriber)
      this.crudService.getUserSubscriptionPlan().subscribe((success) => {

        const planName = success.data?.subscriptionPlanInfo?.name || '';
        if (planName.includes('Bronze', 0)) {
          this.membershipName = 'Bronze member';
        } else if (planName.includes('Silver', 0)) {
          this.membershipName = 'Silver member';
        } else if (planName.includes('Gold', 0)) {
          this.membershipName = 'Gold member';
        } else if (planName.includes('Platinum', 0)) {
          this.membershipName = 'Platinum member';
        } else if (planName.includes('Freemium', 0)) {
          this.membershipName = 'Freemium member';
        } else if (planName.includes('Pewter', 0)) {
          this.membershipName = 'Pewter member';
        } else if (planName.includes('Signature', 0)) {
          this.membershipName = 'Signature member';
        }

        this.localStorageService.setItem('membershipName', this.membershipName);
      })
  }

  // WC 07122022

  initOwlAndChart() {
    setTimeout(() => {
      // $('.v-choice-carousel').owlCarousel({
      //   loop: false,
      //   margin: 15,
      //   nav: true,
      //   dots: false,
      //   mouseDrag: false,
      //   touchDrag: false,
      //   pullDrag: false,
      //   freeDrag: false,
      //   responsive: {
      //     0: {
      //       items: 1,
      //     },
      //     600: {
      //       items: 1,
      //     },
      //     1000: {
      //       items: 3,
      //     }
      //   }
      // })

      for (const i of this.highlights) {
        i.prevDisabled = true;
        this.plotChilds(i)
      }

    }, 500)
  }


  nextVpopularPath(i) {
    const item = $('.childv-choice-carousel' + i.publishedVideo._id).owlCarousel();
    item.trigger('next.owl.carousel');
  }

  preVpopularPath(i) {
    const item = $('.childv-choice-carousel' + i.publishedVideo._id).owlCarousel();
    item.trigger('prev.owl.carousel');
  }

  plotChilds(i: any) {
    i.spinner = true;
    i.childVideos = i.childVideos.sort(function (a: any, b: any) {
      return b.viewCount - a.viewCount;
    });
    $('.childv-choice-carousel' + i.publishedVideo._id).owlCarousel('destroy');
    setTimeout(() => {

      $('.childv-choice-carousel' + i.publishedVideo._id).on('initialized.owl.carousel changed.owl.carousel', function (e) {
        if (!e.namespace) {
          return;
        }
        const carousel = e.relatedTarget;
        $('.slider-counter' + i.publishedVideo._id).text('Choices ' + (carousel.relative(carousel.current()) + 1) + ' of ' + carousel.items().length);
      }).owlCarousel({
        items: 1,
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
      });
      const item = $('.childv-choice-carousel' + i.publishedVideo._id).owlCarousel();
      item.on('changed.owl.carousel', function(event) {
        i.currentIndexItem = event.page.index;
      });
    }, 500)
    this.plotchart(i)
  }


  plotchart(i: any) {
    if (!!i.chart) {
      i.chart.destroy();
    }

    const data = []
    const colorData = ['#A649C7', '#EFB43A', '#3DC99C', '#DB6A8E'];
    let index = 0;
    let totalView = 0;
    for (const j of i.childVideos) {
      data.push({label: j.name, y: j.viewCount, color: colorData[index]})
      totalView = totalView + j.viewCount
      index++;
    }
    i.totalView = totalView;
    if (totalView == 0) {
      i.noViews = true;
    }
    const id = 'chartContainer' + i.publishedVideo._id;
    if (!$('#' + id).length) {
      return;
    }
    if (!i.noViews) {
      i.chart = new CanvasJS.Chart(id, {
        animationEnabled: true,
        backgroundColor: '#f5f7f6',
        data: [{
          type: 'doughnut',
          startAngle: 0,
          indexLabelFontSize: 0,
          indexLabelPlacement: 'inside',
          innerRadius: '70%',
          // innerRadius: 60,

          toolTipContent: '<b>{label}:</b> {y} (#percent%)',
          dataPoints: data
        }]
      });
      i.chart.render();

    }
    i.spinner = false;

  }

  hideAnnouncement() {
    this.localStorageService.setItem('an-profile-page', 'true');
  }
  
  private initTour() {
    if (this.isInitTour) {
      return;
    }
    this.isInitTour = true;
    this.shepherdService.modal = true;
    this.shepherdService.confirmCancel = false;
    this.shepherdService.defaultStepOptions = defaultStepOptions;
    const stepsCP = JSON.parse(JSON.stringify(steps));
    stepsCP[0].text = `
        <p>
      Welcome  ${this.userData?.firstname} ${this.userData?.lastname},
    </p>
    <p>
      My name is Mike, one of the founders of FreeFuse, and I’m so happy you’re here! Since we founded FreeFuse, we’ve
      helped awesome companies like yours build multipath media experiences to engage their audiences in a whole new
      dimension.
    </p>
    <p>
     As part of your onboarding, we've added you to the <a href="${environment.currentDomain}/my-group/${environment.FreeFuse_GroupId}">FreeFuse Community Space</a> where you can see Tips & Tricks, Example Content for different use cases, and more. We've also connected your account with our admin team so you can ask them any questions on the Discussion Board. Use the @ sign to tag one of them and write a question or comment!
    </p>
    <p>
      Now, I can’t wait to get started helping your business! Click on the button below to get started with the tour or
      you can click the x to explore on your own.
    </p>
    <div class="mt-3 text-center">
      <img class="img-fluid rounded-pill" width="150" height="150" title="Mike" src="assets/img/Mike Liu.png" alt="">
      <h6 class="mt-2">Mike, CEO of FreeFuse</h6>
    </div>`;
    // stepsCP[1].text += `<div class="mt-2 text-center"><button id="sh-upload-video" class="video-common-btn w-auto">Upload a Video</button></div>`;
    // add setp  index
    stepsCP.forEach((x, index) => {
      x.text += (`<p class="mt-2 text-center">${index + 1}/${stepsCP.length}</p>`);
      return x;
    });
    this.shepherdService.addSteps(stepsCP as any);

    this.shepherdService.start();
    
    $(document).on('click', '#sh-upload-video', () => {
      this.uploadVideo();
    });
    setTimeout(() => {
      this.dashboardService.onClickMobileMenu(true);
    }, 1000);
  }

  uploadVideo() {
    this.shepherdService.complete();
    setTimeout(() => {
      this.router.navigate(['/library']);
    });
  }

  private getAnnouncement() {

    if (!this.localStorageService.getItem('an-profile-page')) {
      this.crudService.getAnnouncement('ProfilePage').subscribe(res => {
        // this.announcement = res.data;
        // console.log("this.announcement = ", res.data);

        if (res?.data)
          this.announcement = this.sanitizer.bypassSecurityTrustHtml(res.data.text);

        //console.log("this.announcement ==> ", this.announcement);
        

      });
    }
  }

  private getGroups(accessType: any) {
    this.spinners.community = true;
    const payload = {
      limit: this.pagination.group.limit,
      page: this.pagination.group.page,
      // accessType: 'ADMIN',
      accessType,
      isPlanAssigned: true,
      isSubscriptionGroup: true
    };
    this.crudService.getAllGroups(payload).subscribe(res => {
      this.groups = res.data;

      if (accessType === 'SUBSCRIBER' && this.groups.length > 0) {
        this.currentTab = 'community';
        this.isCommunitySubscriber = true;
      }

      this.groups = this.groups.slice(0, 4);
    }).add(() => this.spinners.community = false);
  }

  // getSimilarVideos(reset = false) {
  //   ++this.similarVideos.page;
  //   if (reset) {
  //     this.similarVideos.data = [];
  //     this.similarVideos.page = 1;
  //     this.spinners.similar = true;
  //   }
  //   this.crudService.recommendedVideosByUserCategory(this.similarVideos.limit, this.similarVideos.page).subscribe(success => {
  //     // console.log("success = ", success);
  //     this.similarVideos.data.push(...success.data);
  //   }).add(() => this.spinners.similar = false)
  // }

  getVideosYouMightLike(reset = false) {

    this.spinners.similar = true;

    if (reset)
      this.similarVideos.data = [];

    this.crudService.videosYouMightLike().subscribe(success => {
      // console.log("videosYouMightLike - success = ", success);
      this.similarVideos.data.push(...success.data);
    }).add(() => this.spinners.similar = false)

  }

  userProfile(id) {
    this.router.navigate(['/public-profile'], {queryParams: {user: id}});
  }


  redirectCreateVideo() {

    if (this.localStorageService.getItem('isSubscriber') === 'true') {
      if (this.localStorageService.getItem('interactive') === 'isTemplate') {
        this.localStorageService.setItem('interactive', 'isInteractive')
        location.reload();
      } else {
        this.router.navigate(['upload-videos']);
      }
    } else {
      if (this.userData?.planName === null || this.userData?.planName === undefined) {
        document.getElementById('becomeCreator').click();
      } else {
        this.router.navigate(['pricing']);
      }
    }

  }

  private getBadges() {
    this.spinners.badges = true;
    const payload = {
      limit: this.pagination.badges.limit,
      page: this.pagination.badges.page,
      type: 'creation'
    };
    this.crudService.getBadge(payload).subscribe((res: any) => {
      this.badges.creation = res.data;
    }).add(() => this.spinners.badges = false);

    payload.type = 'earned';
    this.crudService.getBadge(payload).subscribe((res: any) => {
      this.badges.earned = res.data;
    }).add(() => this.spinners.badges = false);

    this.crudService.getBadgeCount({}).subscribe((res: any) => {
      this.badges.earnedCount = res.data.find(x => x.type === 'earned')?.count || 0;
      this.badges.creationCount = res.data.find(x => x.type === 'creation')?.count || 0;
    }).add(() => this.spinners.badges = false);
  }

  
  fliterGroupNameWithSpecialChar(groupName:any){
    return groupName.replace(/&amp;/g, '&');
  }

  onChangeForShowModal(value:any){
    this.crudService.setDoNotShowItem({item: 'Community group Detail'}).subscribe()
  }

  openModalForCommunityDetail(){
    this.crudService.getDoNotShowItem({item: 'Community group Detail'}).subscribe((res) => {
      if(res.data?.length === 0){
        $('#modalForCommunityDetail').modal('show')
      }
    }) 
  }
}

