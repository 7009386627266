import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Observable, Subject, takeUntil } from 'rxjs';
import { EventType, MessageType } from '../createInteractiveVideo.interface';
import { createVideoEvent, createVideoMessage, createVideoStep } from '../createInteractiveVideo.data';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { Router } from '@angular/router';
import { CrudService } from 'src/app/services/crud.service';
import { ToastrService } from 'ngx-toastr';
import { saveAs } from 'file-saver';
import { predefinedTopicsForAIVideoCreator } from 'src/app/tour-data';
import * as pdfjsLib from 'pdfjs-dist';
import { environment } from 'src/environments/environment';
import * as mammoth from 'mammoth';

declare var $;
pdfjsLib.GlobalWorkerOptions.workerSrc = '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.10.377/pdf.worker.min.js';
@Component({
  selector: 'app-ai-video-creator-chat',
  templateUrl: './ai-video-creator-chat.component.html',
  styleUrls: ['./ai-video-creator-chat.component.css']
})
export class AiVideoCreatorChatComponent implements OnInit {

  @Input() upEvents: Observable<EventType>;
  @Output() event = new EventEmitter<EventType>();

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  active: boolean = false;
  msgStep = createVideoStep;
  msgMap = createVideoMessage;
  msgEvent = createVideoEvent;
  currentMessageStep = 0;
  messages: MessageType[] = [];
  msgValue = '00:00:00';
  isMessageLoad: boolean;
  spinner: boolean = false;
  userData: any;
  previousAITopic: string = '';
  previousAIResponse: string = '';
  currentSelectedNode: any;
  voiceModels = [];
  selectedVoiceModel: any;
  disabledDropDown: boolean = false;
  mainId: string = '';
  @ViewChild('audioPlayer') audioPlayer: any;
  @ViewChild('aiScriptAudioPlayer') aiScriptAudioPlayer: any;
  aiGeneratedScriptAudio;
  createInteractiveVideoObj: any = {
    currentObj: '',
  };
  hierarchyArr;
  inputDisable: boolean = false;
  predefinedTopics: any[] = [];
  pdfText: any;
  docText: any;
  env: any;
  documentFile: any;
  saveDocumentsLevelResponse: any[] = [];
  isDocuments: boolean = false;
  bookmarkVideoData: any;
  responseMessageForDocument: any;

  constructor(
    private localStorageService: LocalStorageService,
    private dashboardService: DashboardService,
    private router: Router,
    private crudService: CrudService,
    private toastr: ToastrService
  ) {
    this.predefinedTopics = predefinedTopicsForAIVideoCreator
    this.env = environment
  }

  ngOnInit(): void {
    this.getUserInfo();

    this.upEvents.pipe(takeUntil(this._unsubscribeAll)).subscribe((e) => {
      if (e.type === this.msgEvent.init) {
        this.active = true;
        this.clear();
        this.getBotAnswer('');
      } else if (e.type === this.msgEvent.flowNotCompleted) {
        this.active = true;
        this.clear();
        const botMessage = `Hi ${this.localStorageService.getItem('userName')}, I'm Lexie. Happy to assist you in creating a content tree!`;
        this.addMessage('bot', botMessage);

        if (e.data === this.msgStep.contentTreeCreated) {
          this.currentMessageStep = e.data;
          this.getBotAnswer('');
        } else if (e.data === this.msgStep.scriptAttached) {
          this.addMessage('bot', 'Scripts are successfully generated. You can click on the icon to view or update them.');
          this.currentMessageStep = e.data;
        } else if (e.data === this.msgStep.attachVideosToNodes) {
          this.loadVoiceModels();
          this.addMessage('bot', 'Scripts are successfully generated. You can click on the icon to view or update them.');
          if (!e.isDocumentUpload) {
            this.addMessage('bot', 'Videos are successfully generated and attached successfully to each nodes.');
          }
          this.currentMessageStep = e.data;
        } else if (e.data === this.msgStep.nodeSelectedForVoice) {
          this.addMessage('bot', 'Scripts are successfully generated. You can click on the icon to view or update them.');
          if (!e.isDocumentUpload) {
            this.addMessage('bot', 'Videos are successfully generated and attached successfully to each nodes.');
          }
          this.addMessage('bot', 'Voice model are also attached successfully');
          this.addMessage('bot', 'Click on the node which you\'d like to hear the audio playing for the script');
          this.currentMessageStep = this.msgStep.nodeSelectedForVoice;
          this.currentMessageStep = e.data;
        } else if (e.data?.event === this.msgEvent.scriptBeingCreated) {
          if (e?.data?.isScriptGenerated) {
            this.currentMessageStep = this.msgStep.scriptAttached;
            this.addMessage('bot', 'Scripts are successfully generated. You can click on the icon to view or update them.');
          } else {
            this.addMessage('bot', 'Looks like we have trouble with generating all scripts. Would you like me to try again?');
            this.currentMessageStep = this.msgStep.scriptNotGeneratedProperly;
          }
          this.scrollMessage();
        } else if (e.data.event === this.msgEvent.videoAttachedToAllChoice) {
          if (e?.data?.isVideoGenerated) {
            this.currentMessageStep = this.msgStep.attachVideosToNodes;
            if (!e.isDocumentUpload) {
              this.addMessage('bot', 'Videos are successfully generated and attached successfully to each nodes.');
            }
          } else {
            this.addMessage('bot', 'Looks like we have trouble with generating all videos. Would you like me to try again?');
            this.currentMessageStep = this.msgStep.videoNotGeneratedProperly;
          }
          this.scrollMessage();
        }
      } else if (this.active) {
        if (e.type === this.msgEvent.contentTreeCreated) {
          this.currentMessageStep = this.msgStep.contentTreeCreated;
          this.mainId = e.data ? e.data : '';
          this.getBotAnswer('');
        } else if (e.type === this.msgEvent.scriptBeingCreated) {
          if (e?.data) {
            this.currentMessageStep = this.msgStep.scriptAttached;
            this.addMessage('bot', 'Scripts are successfully generated. You can click on the icon to view or update them.');
          } else {
            this.addMessage('bot', 'Looks like we have trouble with generating all scripts. Would you like me to try again?');
            this.currentMessageStep = this.msgStep.scriptNotGeneratedProperly;
          }
          this.scrollMessage();
        } else if (e.type === this.msgEvent.saveStoryBoardName) {
          this.currentMessageStep = this.msgStep.voiceSettled;
          this.getBotAnswer(e?.data);
          this.scrollMessage();
        } else if (e.type === this.msgEvent.updateTheScenario) {
          this.currentMessageStep = this.msgStep.aiTopicCreated;
        } else if (e.type === this.msgEvent.nodeSelected) {
          if (this.currentMessageStep === this.msgStep.choicesTimeAdded) {
            this.currentMessageStep = this.msgStep.attachVideosToNodes;
          }
          if (this.currentMessageStep === this.msgStep.uploadStoryBoardVideo) {
            this.currentMessageStep = this.msgStep.seletedVideoForNode;
            this.currentSelectedNode = e.data;
            this.getBotAnswer(e.data.name, 'image', e.data?.poster);
          }
          if (this.currentMessageStep === this.msgStep.nodeSelectedForVoice) {
            if (this.createInteractiveVideoObj.currentObj.aiVoiceModel || this.selectedVoiceModel) {
              this.currentSelectedNode = e.data;
              this.currentMessageStep = this.msgStep.playAnotherScript;
              this.generateTTSForSelectedNode();
              this.scrollMessage();
            } else {
              this.currentMessageStep = this.msgStep.attachVideosToNodes;
            }
            return;
          }
          if (this.currentMessageStep === this.msgStep.saveTheVideo) {
            this.currentMessageStep = this.msgStep.videoSelectedForCombine;
            this.currentSelectedNode = e.data;
            this.getBotAnswer(e.data.name, 'image', e.data?.poster);
            return;
          }
          this.scrollMessage();
        } else if (e.type === this.msgEvent.close) {
          this.currentMessageStep = this.msgStep.choicesTimeAdded;
          this.getBotAnswer(e?.data?.name, 'image', e?.data?.poster);
        } else if (e.type === this.msgEvent.videoAttachedToAllChoice) {
          if (e?.data) {
            this.currentMessageStep = this.msgStep.attachVideosToNodes;
            if (!e.isDocumentUpload) {
              this.addMessage('bot', 'Videos are successfully generated and attached successfully to each nodes.');
            }
          } else {
            this.addMessage('bot', 'Looks like we have trouble with generating all videos. Would you like me to try again?');
            this.currentMessageStep = this.msgStep.videoNotGeneratedProperly;
          }
          this.scrollMessage();
        } else if (e.type === this.msgEvent.videoSelectedForCombine) {
          this.addMessage('bot', e.data);
          this.currentMessageStep = this.msgStep.complete;
          this.scrollMessage();
          // setTimeout(() => {
          //   this.router.navigate(['my-videos'], { queryParams: { currentTab: 'draft' } });
          // }, 2000);
        } else if (e.type === this.msgEvent.mainVideo) {
          this.createInteractiveVideoObj.currentObj = e?.data;
          this.bookmarkVideoData = e?.data

          if (this.bookmarkVideoData?.isDocumentUpload) {
            this.active = true;
            if (this.bookmarkVideoData?.Title) {
              this.active = false;
            }

            if (this.bookmarkVideoData?.aiGeneratedScript && !this.bookmarkVideoData?.aiVoiceModel) {
              this.currentMessageStep = this.msgStep.attachVideosToNodes;
            } else if (this.bookmarkVideoData?.aiVoiceModel) {
              // this.currentMessageStep = this.msgStep.audioGenerated
              this.currentMessageStep = this.msgStep.nodeSelectedForVoice
            }
            // else if (this.bookmarkVideoData?.aiGeneratedScript && this.bookmarkVideoData?.aiVoiceModel) {

            // }
            // else {
            //   this.currentMessageStep = this.msgStep.attachVideosToNodes;
            // }
          }
        } else {
          this.currentMessageStep = this.msgStep.choicesTimeAdded;
        }
      }
    });
  }

  addMessage = (author, content, type = 'text', url?) => {
    this.messages.push({ author, content, type, url });
    $('#messagesCard').animate({ scrollTop: $('#messagesCard .messages').height() }, 500);
  }

  scrollMessage() {
    $('#messagesCard').animate({ scrollTop: $('#messagesCard .messages').height() }, 500);
  }

  onEnterKeyPressed(event: KeyboardEvent) {
    if (this.msgValue.trim() === '') {
      this.msgValue = '';
    } else {
      this.sendMessage();
    }
  }

  sendPreDefinedTopic(data: any) {
    this.sendMessage(data?.value);
  }

  sendMessage(msg?: string, fileName?: string) {
    let isDocuments = ['PDF', 'DOCX'].includes(msg);

    if (msg) {
      if (isDocuments) {
        this.msgValue = fileName;
      } else {
        this.msgValue = msg;
      }
    }

    this.msgValue = this.msgValue.trim();
    if (this.msgValue === this.msgMap.cancel) {
      this.currentMessageStep = this.msgStep.cancelNodeUpdate
      this.msgValue = this.msgMap.generateTextToSpeech
      this.addMessage('user', 'Cancel');
      this.scrollMessage();
      return
    }

    if (this.currentMessageStep === this.msgStep.setAITopic) {
      this.previousAITopic = this.msgValue;
      this.addMessage('user', this.msgValue);
      this.callChatGPTAPI(this.msgValue, msg === 'PDF' ? 'PDF' : msg === 'DOCX' ? 'DOCX' : '');
      return;
    } else if (this.msgValue === this.msgMap.contentTreeBasedOnTheScenarios) {
      this.addMessage('user', this.msgValue);
      this.createStoryBoard(this.isDocuments);
      return;
    } else if (this.currentMessageStep === this.msgStep.contentTreeCreated) {
      if (this.msgValue === this.msgMap.yes) {
        this.addMessage('user', this.msgValue);
        this.addMessage('bot', 'The scripts are being generated...')
        this.currentMessageStep = this.msgStep.scriptBeingCreated;
        this.createScriptForStoryBoardNodes();
        return;
      } else {
        this.currentMessageStep = this.msgStep.aiTopicCreated;
        this.scrollMessage();
      }
    } else if (this.currentMessageStep === this.msgStep.scriptNotGeneratedProperly) {
      if (this.msgValue === this.msgMap.yes) {
        this.addMessage('user', this.msgValue);
        this.addMessage('bot', 'The scripts are being generated...')
        this.currentMessageStep = this.msgStep.scriptBeingCreated;
        this.createScriptForStoryBoardNodes();
        return;
      } else {
        this.currentMessageStep = this.msgStep.scriptAttached;
        this.scrollMessage();
      }
    } else if (this.currentMessageStep === this.msgStep.videoNotGeneratedProperly) {
      if (this.msgValue === this.msgMap.yes) {
        this.currentMessageStep = null;
        this.addMessage('user', this.msgValue);
        this.addMessage('bot', 'Getting videos for all nodes...');
        this.scrollMessage();
        this.triggerEvent(this.msgEvent.getTheVideoForAllNodes, true);
        return;
      } else {
        this.currentMessageStep = this.msgStep.attachVideosToNodes;
        this.scrollMessage();
      }
    } else if (this.currentMessageStep === this.msgStep.scriptAttached || this.currentMessageStep === this.msgStep.attachVideosToNodes || this.currentMessageStep === this.msgStep.cancelNodeUpdate) {
      if (this.msgValue === this.msgMap.getTheVideoForAllNodes) {
        this.currentMessageStep = null;
        this.addMessage('user', this.msgValue);
        this.addMessage('bot', 'Getting videos for all nodes...');
        this.scrollMessage();
        this.triggerEvent(this.msgEvent.getTheVideoForAllNodes, true);
        return;
      }
      if (this.msgValue === this.msgMap.updateVideoOnNode) {
        this.currentMessageStep = this.msgStep.uploadStoryBoardVideo;
        this.triggerEvent(this.msgEvent.updateVideoOnNode, false);
      }
      if (this.msgValue === this.msgMap.generateTextToSpeech) {
        this.addMessage('user', this.msgValue);
        this.loadVoiceModels();
        this.currentMessageStep = this.msgStep.audioGenerated;
        this.addMessage('bot', 'Choose a voice :', 'dropdown');
        this.scrollMessage();
        return;
      }
    } else if (this.currentMessageStep === this.msgStep.saveStoryBoardName) {
      this.triggerEvent(this.msgEvent.saveStoryBoardName, { title: this.msgValue })
      return;
    } else if (this.msgValue === this.msgMap.createAnotherScenarioWithTheSameTopic) {
      this.currentMessageStep = null;
      let userPrompt = `${this.previousAIResponse}\n\n. Write a script in the same format for ${this.previousAITopic} that’s different than previous one.`;
      this.addMessage('user', this.msgValue);
      if (this.bookmarkVideoData.isDocumentUpload) {
        this.callChatGPTAPI(userPrompt, msg === 'PDF' ? 'PDF' : msg === 'DOCX' ? 'DOCX' : '');
      } else {
        this.callChatGPTAPI(userPrompt);
      }
      return;
    } else if (this.msgValue === this.msgMap.updateTheScenario) {
      this.inputDisable = false;
      this.currentMessageStep = null;
      this.addMessage('user', this.msgValue);
      this.createNestedArr();
      return;
    } else if (this.msgValue === this.msgMap.startOver) {
      this.messages = [];
      this.msgValue = '';
      this.currentMessageStep = null;
      this.getBotAnswer('');
    } else if (this.currentMessageStep === this.msgStep.seletedVideoForNode) {
      this.triggerEvent(this.msgEvent.addVideoForEmptyNode, {
        currentObj: this.currentSelectedNode,
        isAIContentTree: false
      });
      this.msgValue = '';
    } else if (this.msgValue === this.msgMap.generateAAudio) {
      this.currentMessageStep = this.msgStep.nodeSelectedForVoice;
    } else if (this.currentMessageStep === this.msgStep.audioGenerated) {
      if (this.msgValue === this.msgMap.goodWithVoice) {
        this.disabledDropDown = true;
        this.currentMessageStep = this.msgStep.nodeSelectedForVoice;
        let payload = {
          videoId: this.mainId,
          voiceModel: this.selectedVoiceModel.value
        }
        this.triggerEvent(this.msgEvent.setVoiceModelToNode, payload);
      }
    } else if (this.currentMessageStep === this.msgStep.voiceSettled) {
      if (this.msgValue === this.msgMap.saveTheVideo) {
        this.currentMessageStep = this.msgStep.saveTheVideo;
        this.triggerEvent(this.msgEvent.iAmGoodSaveTheTree, this.createInteractiveVideoObj.currentObj);
      }
      this.scrollMessage();
    } else if (this.msgValue === this.msgMap.playAnotherScript) {
      this.currentMessageStep = this.msgStep.nodeSelectedForVoice;
    } else if (this.msgValue === this.msgMap.iAmGoodSaveTheTree || this.msgValue === this.msgMap.generateTheVideo) {
      this.currentMessageStep = this.msgStep.saveTheStoryBoard;
    } else if (this.currentMessageStep === this.msgStep.complete) {
      this.router.navigate(['my-videos'], { queryParams: { currentTab: 'draft' } });
    } else if (this.currentMessageStep === this.msgStep.updateTheScenario) {
      this.mainId = null;
      this.inputDisable = true;
      this.saveTheUpdatedScenario();
      this.currentMessageStep = this.msgStep.aiTopicCreated;
      this.scrollMessage();
    }

    if (this.msgValue) {
      this.getBotAnswer(this.msgValue);
    }
    this.msgValue = '';
  }

  createNestedArr() {
    if (this.bookmarkVideoData.isDocumentUpload) {
      const formattedText = this.formatDocumentsLevels(this.responseMessageForDocument);
      this.hierarchyArr = this.parseResponse(formattedText);
    } else {
      this.hierarchyArr = this.parseResponse(this.previousAIResponse);
    }

    if (this.hierarchyArr) {
      this.addMessage('bot', 'Update a scenario :', 'UPDATE_SCENARIO');
      this.currentMessageStep = this.msgStep.updateTheScenario;
    }
  }

  parseResponseForDocuments(items: any[]): any[] {
    let map: { [key: string]: any } = {};
    let root: any[] = [];

    items.forEach(item => {
      map[item.level] = { ...item, children: [] };
    });

    items.forEach(item => {
      let levelParts = item.level.split(".");
      levelParts.pop(); // Remove the last part to find the parent level
      let parentLevel = levelParts.join(".");

      if (map[parentLevel]) {
        map[parentLevel].children!.push(map[item.level]);
      } else {
        root.push(map[item.level]);
      }
    });

    return root;
  }

  parseResponse(response: string): any[] {
    // Split the response string by lines
    const lines = response.split('\n');

    const hierarchy = [];
    let currentLevel = -1;
    let currentParent = null;

    // Add level 0 description
    hierarchy.push({ level: 0, child: [{ title: lines[1]?.trim() }] });

    // Parse each line to construct the hierarchy
    for (let i = 1; i < lines.length; i++) {
      const line = lines[i];
      const levelMatch = line.match(/^\d+(\.\d+)*/); // Extract level number
      if (!levelMatch) continue; // Skip invalid lines

      const levelStr = levelMatch[0];
      const title = line.substring(levelStr.length).trim(); // Extract title
      const level = levelStr.split('.').length - 1; // Calculate level
      const label = levelStr.replace(/\./g, '.');

      // Check if level is increased
      if (level > currentLevel) {
        hierarchy.push({ level, child: [] });
        currentParent = hierarchy[hierarchy.length - 1];
      } else if (level < currentLevel) {
        // Move up in hierarchy if level is decreased
        for (let j = currentLevel; j > level; j--) {
          currentParent = currentParent.parent;
        }
      }

      // Add child to current parent
      currentParent?.child.push({ title, label });

      // Update current level
      currentLevel = level;
    }

    return hierarchy;
  }

  saveTheUpdatedScenario() {
    if (this.bookmarkVideoData.isDocumentUpload) {

      this.saveDocumentsLevelResponse.forEach(doc => {
        this.hierarchyArr.forEach(levelObj => {
          levelObj.child.forEach(child => {
            if (child.label && doc.level === child.label) {
              doc.Topic = child.title;
            } else if (!child.label && doc.level === String(levelObj.level)) {
              doc.Topic = child.title;
            }
          });
        });
      });
    } else {
      const results = this.formatData(this.hierarchyArr)
      this.previousAIResponse = results;
    }
  }

  formatData(dataArray: any[]): string {
    let result = '';
    dataArray.forEach(item => {
      result += `Level ${item.level}:\n`;
      item.child.forEach(childItem => {
        result += `${childItem.label ? childItem.label + ' ' : ''}${childItem.title}\n`;
      });
    });
    return result;
  }

  getBotAnswer(userMessage: string, type = 'text', url = '') {

    if (userMessage) {
      this.addMessage('user', userMessage, type, url)
    }
    this.isMessageLoad = true;
    setTimeout(() => {
      /*output validation*/
      let botMessage = '';
      if (!this.messages.length) {
        this.currentMessageStep = this.msgStep.setAITopic;

        if (this.env?.isAIVideoCreatorReadDoc)
          botMessage = `Hi ${this.localStorageService.getItem('userName')}, I'm Lexie. Happy to assist you in creating a multipath video! You can either type the topic that you'd like the content tree created for or you can upload a PDF or DOCX document with max file size of 1 MB.`;
        else
          botMessage = `Hi ${this.localStorageService.getItem('userName')}, I'm Lexie. Happy to assist you in creating a multipath video! What’s the topic you’d like to create the content tree for?`;
        this.addMessage('bot', botMessage)
        this.getBotAnswer('');
        return;
      }
      if (this.currentMessageStep === this.msgStep.contentTreeCreated) {
        if (this.isDocuments) {
          botMessage = 'The content tree has been successfully created with the associated scripts! You can now click on the icon to view or update the scripts as needed.';
          this.currentMessageStep = this.msgStep.attachVideosToNodes;
        } else {
          botMessage = 'Content tree created successfully! Would you like to create scripts for all choices? You can update the scripts afterwards.';
        }
      } else if (this.currentMessageStep === this.msgStep.uploadStoryBoardVideo) {
        botMessage = 'Select the node that you’d like the video to be uploaded for'
      } else if (this.currentMessageStep === this.msgStep.saveTheStoryBoard) {
        this.currentMessageStep = this.msgStep.saveStoryBoardName;
        botMessage = `OK! You’d like to generate the video. What would the name be?`;
      } else if (this.currentMessageStep === this.msgStep.contentTreeNameCreator) {
        botMessage = `It’s combining the video and audio so it will take a while.`;
        setTimeout(() => {
          this.router.navigate(['my-videos'], { queryParams: { currentTab: 'draft' } });
        }, 2000);
      } else if (this.currentMessageStep === this.msgStep.nodeSelectedForVoice) {
        botMessage = `Click on the node which you'd like to hear the audio playing for the script`;
      }

      this.isMessageLoad = false;
      if (botMessage) {
        this.addMessage('bot', botMessage)
      }
    }, 1000);
  }

  loadVoiceModels() {
    this.crudService.getVoiceModels().subscribe((res) => {
      this.voiceModels = res.data;
      //console.log("this.voiceModels = ", this.voiceModels);
      this.voiceModels.forEach((ele) => {
        ele['audioUrl'] = `assets/voices/${ele.value}.mp3`
      })

      const isDefaultValue = this.voiceModels.find((i) => i.default === true)
      if (isDefaultValue) {
        this.selectedVoiceModel = isDefaultValue;
      }

    }, (err) => {
      this.voiceModels = [];
    })
  }

  onSelectVoiceModel(voiceModel) {
    this.selectedVoiceModel = voiceModel;
    this.audioPlayer?.nativeElement.pause();
    (this.audioPlayer).nativeElement.src = this.selectedVoiceModel.audioUrl;
    this.scrollMessage();
    this.triggerEvent(this.msgEvent.updateVideoOnNode, false);
  }

  generateTTSForSelectedNode() {
    let payload = {
      voiceModel: this.selectedVoiceModel?.value ? this.selectedVoiceModel?.value : this.createInteractiveVideoObj.currentObj?.aiVoiceModel,
      text: this.currentSelectedNode.aiGeneratedScript,
      videoId: this.currentSelectedNode._id,
      type: this.currentSelectedNode.type
    }

    //console.log("payload = ", payload);
    this.triggerEvent(this.msgEvent.mergingVideoAndAudio, { currentNode: this.currentSelectedNode, payload: payload });
  }


  encodeToBase64(str: string): string {
    const utf8String = unescape(encodeURIComponent(str));
    return btoa(utf8String);
  }

  createScriptForStoryBoardNodes() {
    this.triggerEvent(this.msgEvent.scriptBeingCreated, '');
  }

  createStoryBoard(isDocuments?: boolean) {
    //this.triggerEvent(this.msgEvent.createContentTreeBasedOnScenario, this.previousAIResponse);
    if (this.previousAIResponse || this.saveDocumentsLevelResponse) {
      if (this.mainId) {
        if (isDocuments) {
          this.triggerEvent(this.msgEvent.createContentTreeBasedOnScenario,
            {
              "scenarios": this.saveDocumentsLevelResponse,
              "videoId": this.mainId,
              "isDocuments": true
            });
        } else {
          this.triggerEvent(this.msgEvent.createContentTreeBasedOnScenario,
            {
              "scenarios": this.previousAIResponse,
              "videoId": this.mainId,
              "isDocuments": false
            });
        }
      } else {

        if (this.bookmarkVideoData.isDocumentUpload) {

          const obj = [{
            level: 0,
            parentId: null,
            name: this.saveDocumentsLevelResponse && this.saveDocumentsLevelResponse.length && this.saveDocumentsLevelResponse[0]?.Topic ? this.saveDocumentsLevelResponse[0]?.Topic : '',
            isDocumentUpload: this.isDocuments
          }];

          this.crudService.createEmptyNodes(obj).subscribe((success) => {
            this.mainId = success.data._id;
            this.bookmarkVideoData = success.data

            this.triggerEvent(this.msgEvent.createContentTreeBasedOnScenario,
              {
                "scenarios": this.saveDocumentsLevelResponse,
                "videoId": this.mainId,
                "isDocuments": this.isDocuments
              }
            );
          })
        } else {
          const obj = [{
            level: 0,
            parentId: null,
            name: this.hierarchyArr && this.hierarchyArr.length && this.hierarchyArr[0]?.child[0]?.title ? this.hierarchyArr[0]?.child[0]?.title : '',
            isDocumentUpload: this.isDocuments
          }];

          this.crudService.createEmptyNodes(obj).subscribe((success) => {
            this.mainId = success.data._id;
            this.bookmarkVideoData = success.data

            this.triggerEvent(this.msgEvent.createContentTreeBasedOnScenario,
              {
                "scenarios": this.previousAIResponse,
                "videoId": this.mainId,
                "isDocuments": this.isDocuments
              }
            );
          })
        }
      }

    } else {
      this.msgValue = '';
      this.addMessage('bot', 'What’s the topic you’d like to create the content tree for?');
      this.currentMessageStep = this.msgStep.setAITopic;
      this.scrollMessage();
    }
  }

  // callChatGPTAPI(msgValue: string) {
  //   this.spinner = true;

  //   const obj = [{
  //     level: 0,
  //     parentId: null,
  //     name: msgValue
  //   }];

  //   //console.log("createEmptyNodes - payload = ", obj);

  //   this.crudService.createEmptyNodes(obj).subscribe((success) => {

  //     this.mainId = success.data._id;

  //     let payload;
  //     payload = {
  //       "assistantType" : "ScenarioWriter",
  //       "userPrompt" : msgValue,
  //       "videoId" : this.mainId
  //     };

  //     // if (this.currentSelectedNode) {
  //     //   payload['videoId'] = this.mainId ? this.mainId : '';
  //     // }

  //     //console.log("createLexieChat - payload = ", payload);

  //     this.crudService.createLexieChat(payload).subscribe((res: any) => {
  //       if(res) {
  //         //console.log("callChatGPT - res = ", res);

  //         const responseMessage = res.data.choices[0].message.content;
  //         // const responseMessage = res.data;
  //         this.previousAIResponse = responseMessage;
  //         console.log("responseMessage ===> ", responseMessage);

  //         this.addMessage('bot', responseMessage);
  //         this.spinner = false;
  //         this.currentMessageStep = this.msgStep.aiTopicCreated;
  //       }
  //     }, (err) => {
  //       this.toastr.error('Error!');
  //       this.spinner = false;
  //     })
  //   });
  // }

  callChatGPTAPI(msgValue: string, msgType?: any) {
    this.spinner = true;

    // let msgContent = '';
    // if(msgType === 'PDF') {
    //   msgContent = this.pdfText;
    // } else if (msgType === 'DOCX') {
    //   msgContent = this.docText;
    // }else {
    //   msgContent = msgValue;
    // }

    // const obj = [{
    //     level: 0,
    //     parentId: null,
    //     name: msgContent
    //   }];

    const obj = [{
      level: 0,
      parentId: null,
      name: msgValue,
      isDocumentUpload: this.isDocuments
    }];


    this.crudService.createEmptyNodes(obj).subscribe((success) => {

      this.mainId = success.data._id;
      this.bookmarkVideoData = success.data

      if (!msgType && !this.bookmarkVideoData.isDocumentUpload) {
        const payload = {
          "assistantType": "ScenarioWriter",
          // "userPrompt": msgValue,
          "userPrompt": msgValue,
          "videoId": this.mainId
        };
        this.crudService.createLexieChat(payload).subscribe((res: any) => {
          if (res) {
            const responseMessage = res.data.choices[0].message.content;
            this.previousAIResponse = responseMessage;
            // console.log("responseMessage ===> ", responseMessage);

            // Limit levels to 3
            const filteredResponse = this.limitLevels(responseMessage);


            this.addMessage('bot', filteredResponse);
            this.spinner = false;
            this.currentMessageStep = this.msgStep.aiTopicCreated;
          }
        }, (err) => {
          this.toastr.error('Error!');
          this.spinner = false;
        });
      } else {
        const formData = new FormData()
        formData.append('file', this.documentFile[0])
        this.crudService.createChatCompletionWithDocumentInput(formData).subscribe((res: any) => {
          if (res) {
            this.responseMessageForDocument = res.data
            this.saveDocumentsLevelResponse = this.transformScenarios(this.responseMessageForDocument);

            const formattedText = this.formatDocumentsLevels(this.responseMessageForDocument);

            this.addMessage('bot', formattedText);
            this.spinner = false;
            this.currentMessageStep = this.msgStep.aiTopicCreated;
          }
        }, (err) => {
          this.toastr.error('Error!');
          this.spinner = false;
        });
      }

    },
      (err) => {
        this.spinner = false;
      });
  }


  transformScenarios(scenarios: any[]): any[] {
    return scenarios.map(scenario => {
      // Get all keys in the scenario object, it will be "Level 0", "Level 1.1", etc.
      const levelKeys = Object.keys(scenario);

      // Process each level key
      return levelKeys.map(levelKey => {
        let levelValue: string | null = null;

        // If it's "Level 0", assign level as "0"
        if (levelKey === "Level 0") {
          levelValue = "0";
        } else {
          // Use regex to match any level format "1", "1.1", "1.1.1", etc.
          const levelMatch = levelKey.match(/Level\s(\d+(\.\d+)*)/);
          if (levelMatch) {
            levelValue = levelMatch[1]; // Extract level, e.g., "1", "1.1", "1.1.1"
          }
        }

        // Now map and return the corresponding topic, script, and keywords with the level assigned
        return {
          ...scenario[levelKey], // Copy the content (Topic, Script, Keywords)
          level: levelValue || "0" // Add the level, default to "0" if not found
        };
      });
    }).flat(); // Flatten the resulting array to avoid nested arrays
  }


  // formatDocumentsLevels(data) {
  //   let output = [];

  //   function traverseData(data) {
  //     data.forEach((item) => {
  //       const key = Object.keys(item)[0]; // Get the level key (e.g., "Level 0", "Level 1.1")
  //       const topic = item[key].Topic;
  //       output.push(`${key}: ${topic}`);
  //     });
  //   }

  //   traverseData(data);
  //   return output.join("\n");
  // }


  formatDocumentsLevels(data: any[]): string {
    let formattedString = '';
    const levelMap: Map<number, any[]> = new Map();

    // Step 1: Group topics by their level
    data.forEach(item => {
      const key = Object.keys(item)[0];
      const level = this.getLevelFromKey(key);
      const topic = item[key].Topic;
      const children = item[key].children || [];

      if (!levelMap.has(level)) {
        levelMap.set(level, []);
      }

      levelMap.get(level)?.push({ key, topic, children });
    });

    // Step 2: Sort levels and build the output
    const sortedLevels = Array.from(levelMap.keys()).sort((a, b) => a - b);

    sortedLevels.forEach((level, index) => {
      if (index > 0) {
        formattedString += '\n'; // Line break before each level (except first)
      }

      formattedString += `Level ${level}:\n`;

      const levelData = levelMap.get(level);
      levelData?.forEach(item => {
        const key = item.key;
        const topic = item.topic;

        // 👇 For Level 0, just show the topic
        // For all others, remove "Level " prefix and prepend key
        const line = level === 0
          ? `${topic}\n`
          : `${key.replace(/^Level\s*/, '')} ${topic}\n`;

        formattedString += line;
      });
    });

    return formattedString;
  }

  // Helper function to format each topic and its children
  formatTopic(item: any, level: number): string {
    let formattedString = '';
    formattedString += `${this.getIndentation(level)}${item.key} ${item.topic}\n`;

    // Format children (if any)
    item.children.forEach(child => {
      formattedString += `${this.getIndentation(level + 1)}${child.key} ${child.Topic}\n`;
    });

    return formattedString;
  }

  // Helper function to get level from key (e.g., '1.1' -> level 1)
  getLevelFromKey(key: string): number {
    const parts = key.split('.');  // Split key by dot
    return parts.length - 1;  // Level is determined by how deep the key goes (e.g., '1.1' -> level 1)
  }

  // Helper function to get indentation based on the level
  getIndentation(level: number): string {
    return '    '.repeat(level);  // 4 spaces per level
  }



  limitLevels(responseMessage: string, maxLevel = 2) {
    const lines = responseMessage.split('\n'); // Split the response into lines
    let currentLevel = -1;
    let filteredMessage = '';
    let levelZeroProcessed = false; // Track if Level 0 has been processed

    for (let line of lines) {
      // Check for the separator and break if found
      if (line.trim() === '---') {
        break; // Stop processing if separator is found
      }

      // Check if the line contains "Level" and update the current level
      if (line.startsWith('Level')) {
        const levelMatch = line.match(/Level (\d+)/);
        if (levelMatch) {
          currentLevel = parseInt(levelMatch[1], 10);
        }

        // If it's Level 0 and has already been processed, skip adding it
        if (currentLevel === 0 && levelZeroProcessed) {
          break; // Stop processing if Level 0 has been completed
        }

        // Add the level line
        // filteredMessage += line + '\n'; // Add level line
        filteredMessage += '\n' + line; // Add level line

        // Add a newline for spacing after each level
        filteredMessage += '\n'; // Add extra newline for spacing
        levelZeroProcessed = currentLevel === 0; // Mark Level 0 as processed
        continue; // Move to the next iteration after handling the level
      }

      // Only include lines from levels 0 to 2
      if (currentLevel >= 0 && currentLevel <= maxLevel && line.trim()) {
        filteredMessage += line + '\n'; // Add level lines without extra spacing
      }
    }

    // Trim extra newlines and return the final output
    return filteredMessage.trim().replace(/\n{2,}/g, '\n\n'); // Ensure only single blank lines between sections
  }


  getUserInfo() {
    this.dashboardService.getUserData.subscribe((res) => {
      if (res) {
        this.userData = res;
      }
    });
  }

  cancel() {
    this.clear();
    this.active = false;
    this.triggerEvent(this.msgEvent.close);
  }

  triggerEvent(type, data?) {
    this.event.emit({ type, data });
  }

  clear() {
    this.msgValue = '';
    this.messages = [];
    this.currentMessageStep = this.msgStep.welcome;
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  onChangeFileEvent(event: any) {
    let fileList: FileList = event.target.files;
    this.documentFile = fileList
    // Check file size (1MB = 1,048,576 bytes)
    const maxSize = this.env.aiVideoCreatorMaxDocSize * 1024 * 1024; // 1MB

    if (fileList[0]?.size > maxSize) {
      this.toastr.error("File size exceeds 1MB. Please upload a smaller file.");
      return;
    }

    const allowedFileTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    ];

    if (!allowedFileTypes.includes(fileList[0]?.type)) {
      this.spinner = false;
      this.toastr.error("You can upload only PDF and DOCX file!")
      return
    }

    this.isDocuments = true;

    let fileValue: any = fileList[0];

    // For pdf file content
    if (fileValue.type === 'application/pdf') {
      // Use FileReader to read the file as an ArrayBuffer
      const reader = new FileReader();
      reader.onload = () => {
        const arrayBuffer: any = reader.result as ArrayBuffer;

        // Use PDF.js to load the PDF
        pdfjsLib.getDocument(arrayBuffer).promise.then(pdf => {
          let pdfText = '';

          // Extract text from each page
          const numPages = pdf.numPages;
          const textPromises = [];

          for (let pageNum = 1; pageNum <= numPages; pageNum++) {
            textPromises.push(this.extractTextFromPage(pdf, pageNum));
          }

          // Once all text is extracted from the PDF pages, send it to the API
          Promise.all(textPromises).then(texts => {
            pdfText = texts.join('\n');
            this.pdfText = pdfText
            this.sendMessage('PDF', fileValue.name);
          });
        }).catch(error => {
          console.error('Error loading PDF:', error);
          this.toastr.error("Error processing PDF file.");
        });
      };

      reader.readAsArrayBuffer(fileValue);

    }

    // For doc file content
    if (['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.wordprocessingml.template'].includes(fileValue.type)) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const arrayBuffer = e.target.result;

        // Convert the DOCX file to plain text using Mammoth.js
        // mammoth.extractRawText({ arrayBuffer: arrayBuffer })
        mammoth.convertToHtml({ arrayBuffer: arrayBuffer })
          .then((result) => {
            const plainText = this.stripHtml(result.value);
            this.docText = plainText;
            this.sendMessage('DOCX', fileValue.name);

            // Send the content to the API
            // this.sendToApi(this.fileContent);
          })
          .catch((err) => {
            console.error('Error reading DOCX file', err);
          });
      };
      reader.readAsArrayBuffer(fileValue);
    }

  }

  // Helper function to extract text from a page in the PDF
  extractTextFromPage(pdf, pageNum: number): Promise<string> {
    return new Promise((resolve, reject) => {
      pdf.getPage(pageNum).then(page => {
        page.getTextContent().then(textContent => {
          const textItems = textContent.items;
          let pageText = '';

          for (let i = 0; i < textItems.length; i++) {
            pageText += textItems[i].str + ' ';
          }

          resolve(pageText);
        }).catch(reject);
      }).catch(reject);
    });
  }

  stripHtml(html: string): string {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  }
}
