import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { DashboardService } from "../../services/dashboard.service";
import { LocalStorageService } from "../../services/localStorage.service";
import { lexieChatBotAccess, lexieChatBotSuggestions } from "src/app/tour-data";
import { CrudService } from "src/app/services/crud.service";
import { Router } from "@angular/router";

declare var $;

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.css"],
})
export class LayoutComponent implements OnInit {
  @ViewChild("tooltip") tooltip;
  @Input() embed = false;
  @Input() footer = false;
  @Input() breadcrumb = "";
  @Input() spinner = false;
  isLoggedIN = false;
  isMiniSidebar: boolean;
  isLexieChatBot: boolean = false;
  profilePic: string = "";
  suggestions = [];
  tooltipVisible: boolean = false;
  isDisplaySideBar: boolean = false;
  currentUrl: string = "";

  constructor(
    private readonly dashboardService: DashboardService,
    private localStorageService: LocalStorageService,
    private crudService: CrudService,
    private readonly router: Router
  ) {
    this.profilePic = this.localStorageService.getItem("profilePic")
      ? this.localStorageService.getItem("profilePic")
      : null;

    this.currentUrl = window.location.pathname;
    const currentRouteSuggestion = lexieChatBotSuggestions.filter(
      (i) => i.route === this.currentUrl
    );

    this.isDisplaySideBar = ["/integrations"].includes(this.router.url);

    if (currentRouteSuggestion && currentRouteSuggestion.length > 0) {
      this.suggestions = currentRouteSuggestion;
    } else {
      this.suggestions.push(lexieChatBotSuggestions[0]);
    }
  }

  ngOnInit(): void {
    if (
      this.localStorageService.getItem("user") &&
      this.localStorageService.getItem("token")
    ) {
      this.isLoggedIN = true;
    }

    this.dashboardService.miniSidebar.subscribe((val) => {
      this.isMiniSidebar = val;
    });

    if (this.chatBotAvailable) {
      if (this.tooltip) {
        setTimeout(() => {
          this.tooltip.show();
          this.tooltipVisible = true;
          setTimeout(() => {
            this.tooltip.hide();
            this.tooltipVisible = false;
          }, 3000);
        }, 5000);
      }
    }

    this.crudService.eventOccurred$.subscribe((value) => {
      if (value) {
        $("#aiChatBot").modal("show");
      } else {
        $("#aiChatBot").modal("hide");
      }
    });
  }

  get chatBotAvailable(): boolean {
    return lexieChatBotAccess.includes(this.currentUrl);
  }

  openLexieChatBot() {
    this.isLexieChatBot = !this.isLexieChatBot;
    $("#aiChatBot").modal("hide");
  }

  closeLexieChatBot() {
    this.isLexieChatBot = false;
  }
}
