import {Component, OnInit} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LocalStorageService} from '../../services/localStorage.service';

@Component({
  selector: 'app-user-activitiy',
  templateUrl: './user-activitiy.component.html',
  styleUrls: ['./user-activitiy.component.css'],
})

export class UserActivitiyComponent implements OnInit {
  // WC 05132022
  private limit = 9;
  private page = 1;
  selectedUserActivity = '';
  // WC 05132022

  currentUser: any;
  watchHistory: Array<any> = [];
  activityList: Array<any> = [];
  likedVideos: Array<any> = [];
  spinner = false;
  sharedVideos: Array<any> = [];
  private likeCategories: Array<any> = [
    {
      shortcut: 0,
      id: '1',
      category: 'Laugh',
      emoji: '../../../assets/images/videoplayer/react-laugh.svg'
    },
    {
      shortcut:1,
      id: '2',
      category: 'Like',
      emoji: '../../../assets/images/videoplayer/react-like.svg'
    },
    {
      shortcut:2,
      id: '3',
      category: 'Dislike',
      emoji: '../../../assets/images/videoplayer/react-dislike.svg'
    },
    {
      shortcut:3,
      id: '4',
      category: 'Question',
      emoji: '../../../assets/images/videoplayer/react-question.svg'
    },
    {
      shortcut:4,
      id: '5',
      category: 'Amazed',
      emoji: '../../../assets/images/videoplayer/react-amazed.svg'
    },
    {
      shortcut:5,
      id: '6',
      category: 'Crying',
      emoji: '../../../assets/images/videoplayer/react-crying.svg'
    },
    {
      shortcut:6,
      id: '7',
      category: 'Angry',
      emoji: '../../../assets/images/videoplayer/react-angry.svg'
    }
  ];

  constructor(
      private apiService: CrudService,
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private localStorageService: LocalStorageService,
  ) {

    this.activatedRoute.queryParams.subscribe((params) => {

      this.currentUser = this.localStorageService.getItem('user');
      this.setUserActivity(params.selectedUserActivity);

    });

  }

ngOnInit() {

  this.currentUser = this.localStorageService.getItem('user');

  // console.log("ngOnInit - this.selectedUserActivity = ", this.selectedUserActivity);

  if (this.selectedUserActivity == undefined || this.selectedUserActivity == '')
    this.setUserActivity('history');

}

// Get shared videos
getSharedVideosV2() {

  this.spinner = true;

  this.apiService.getUserShareV2(this.currentUser, this.limit, this.page).subscribe(
    success => {
      if(!Array.isArray(success.data)){

        // this.sharedVideos = [];

        this.spinner = false;
        return
      }

      this.sharedVideos.push(...success.data);
      // console.log("this.sharedVideo = ", this.sharedVideos);

      this.spinner = false;
    });

}

// Get liked videos
getLikedVideosV2() {

  this.spinner = true;

  this.apiService.getLikedVideosV2(this.limit, this.page, this.currentUser).subscribe(
    success=>{
      if(!Array.isArray(success.data)){
        // this.likedVideos = []

        this.spinner = false;
        return;

      }

     this.likedVideos.push(...success.data);

     this.spinner = false;
    });

}

  // Get activity
  getUserActivityV2() {

    this.spinner = true;

    this.apiService.getUserActivityV2(this.limit, this.page).subscribe(
      success=>{
        if(!Array.isArray(success.data)){
          // this.activityList = []

          this.spinner = false;
          return;

        }

        // WC 05132022
        this.activityList.push(...success.data);
        // console.log("this.acitivityList = ", this.activityList);

        this.spinner = false;
        // WC 05132022

      });


  }

  userProfile(id:any){
    this.router.navigate(['public-profile'], { queryParams: { user: id } });
  }

  getEmoji(cat:any){
    // console.log(cat)
    if(cat == '1')
    return this.likeCategories[0].emoji

    if(cat == '2')
    return this.likeCategories[1].emoji

    if(cat == '3')
    return this.likeCategories[2].emoji

    if(cat == '4')
    return this.likeCategories[3].emoji

    if(cat == '5')
    return this.likeCategories[4].emoji

    if (cat == '6')
      return this.likeCategories[5].emoji

    if (cat == '7')
      return this.likeCategories[6].emoji
  }

  // Navigate to public videos
  showVideo(videoId: any) {
    this.router.navigate(['videos'], {queryParams: {video: videoId}});
  }

  showCommunity(e: any) {
    if (e.business.communityUrl) {
      this.router.navigate(['my-community/' + e.business.communityUrl]);
    } else {
      this.router.navigate(['my-community'], {queryParams: {user: e._id}});
    }
  }

  // WC 05132022
  setUserActivity(activityType: any) {
    this.spinner = true;
    this.page = 0;  // reset the page
    this.watchHistory = [];
    this.activityList = [];
    this.likedVideos = [];
    this.sharedVideos = [];

    this.selectedUserActivity = activityType;
    // console.log("setUserActivity, this.selectedUserActivity = " + this.selectedUserActivity + ", this.page = " + this.page);
    this.onUserActivityScroll();
  }

  onUserActivityScroll(): void {

    ++this.page;

    // console.log("onUserActivityScroll, this.selectedUserActivity = " + this.selectedUserActivity + ", this.page = " + this.page);

    switch (this.selectedUserActivity) {

      case 'activity':
        this.getUserActivityV2();
        break;
      case 'history':
        this.getWatchHistoryV2();
        break;
      case 'likes':
        this.getLikedVideosV2();
        break;
      case 'shares':
        this.getSharedVideosV2();
        break;
    }
  }

  getWatchHistoryV2() {

    this.spinner = true;

    this.apiService.getWatchHistoryV2(this.currentUser, this.limit, this.page).subscribe(
      success => {

        if (!Array.isArray(success.data)) {
          // this.watchHistory = [];

          this.spinner = false;
          return
        }

        this.watchHistory.push(...success.data);
        //console.log("this.watchHistory = ", this.watchHistory);

        this.spinner = false;

      })
  }

  // WC 05132022
}
