import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {GroupVideo} from '../group.model';
import {GroupService} from '../group.service';
import {CrudService} from '../../../services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../../services/localStorage.service';

@Component({
  selector: 'app-group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.css'],
})
export class GroupListComponent implements OnInit, OnDestroy {
  groupVideos: GroupVideo[] = [];
  groupDetailId = '';
  groupDetail: any;
  spinner = false;
  spinner1 = false;
  isTogglePrivacy = false;
  isEmbed: any;
  private page = 0;
  private subscription: Subscription;

  constructor(
      private readonly toaster: ToastrService,
      private readonly crudService: CrudService,
      private readonly groupService: GroupService,
      private readonly router: Router,
      private readonly route: ActivatedRoute,
      private readonly localStorageService: LocalStorageService,
  ) {
  }

  ngOnInit(): void {

    this.route.params.subscribe((params: Params) => {
      this.groupDetailId = params.id;
      this.getGroupData();
      this.getCurrentGroupDetail(this.groupDetailId);
    });
    this.route.queryParams.subscribe((params: Params) => {
      if (params.embed && params.embed === 'true') {
        this.isEmbed = params?.embed;
      }
    });
  }

  getCurrentGroupDetail(id: any) {
    this.spinner = true;
    this.crudService.getGroupDetail(id).subscribe(res => {
      this.groupDetail = res.data[0];
      this.groupDetail.isAdmin = this.localStorageService.getItem('user') === this.groupDetail?.admin;
      // console.log("this.groupDetail = ", this.groupDetail);
      // console.log("this.groupDetail.isSendingEmailReminder = ", this.groupDetail.isSendingEmailReminder);

      if (!this.groupDetail) {
        this.router.navigate(['/']);
        return;
      }
    }).add(() => this.spinner = false);
  }

  getGroupData() {
    ++this.page;
    this.spinner1 = true;
    this.groupService
        .getGroupCompletionRate(this.groupDetailId, 5, this.page)
        .subscribe((res: any) => {
          for (const item of res.groupVideos) {
            this.groupVideos.push(item);
          }
        }).add(() => this.spinner1 = false);
  }

  onClickTableRow(groupVideo: GroupVideo) {
    // console.log('onClickTableRow - groupVideo = ', groupVideo);
    // this.router.navigate(['/group-detail', groupVideo._id]);
    this.router.navigate(['/group-detail/' + this.groupDetailId], {
      queryParams: {
        videoId: groupVideo.videoInfo._id,
        embed: this.isEmbed
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  saveEmailReminder($event) {
    const isSendingEmailReminder = !!$event.target.checked;
    const formData = new FormData();
    formData.append('groupId', this.groupDetailId);
    // @ts-ignore
    formData.append('isSendingEmailReminder', isSendingEmailReminder);
    this.crudService.updateGroup(this.groupDetailId, formData).subscribe(res => {
      this.groupDetail = res.data;
      this.groupDetail.isAdmin = this.localStorageService.getItem('user') === this.groupDetail?.admin;
      this.toaster.success('Email Reminder change successfully!!')
    });
  }

  togglePrivacy() {
    this.isTogglePrivacy = !this.isTogglePrivacy;
  }

  selectAll() {
    this.groupVideos.forEach(x => {
      x.videoInfo.privacy = true;
    });
  }
}
