import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CrudService } from 'src/app/services/crud.service';

@Component({
  selector: 'app-lexie-ai-chatbot',
  templateUrl: './lexie-ai-chatbot.component.html',
  styleUrls: ['./lexie-ai-chatbot.component.css']
})
export class LexieAiChatbotComponent implements OnInit {

  @Output() closeLexieChat = new EventEmitter();
  @ViewChild('scrollBar') private scrollBar: ElementRef;
  @Input() profilePic: string = '';
  spinner: boolean = false;

  aiMessage: string = '';
  messages = [];
  @Input() suggestions = [];
  payloadOfChatBot:any;
  disabled: boolean = false;

  constructor(
    public crudService: CrudService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
  }

  closeLexieChatBot() {
    this.closeLexieChat.emit();
  }

  onSuggestionClick(message) {
    if(message.content === "Freeboard" || message.content === "Storyboard" || message.content === "Template" || message.content === 'AI Auto Editor') {
      this.crudService.aiFreeBoardClicked(message.content);
      return;
    }

    this.messages.push({
      "role": "user",
      "content": message.content
    })
    if(message.questions && message.questions.length) {
      message.questions.forEach(element => {
        this.messages.push({
          "role": "assistant",
          "content": element.q1
        })

        if(element.values) {
          this.messages.push({
            "role": "assistant",
            "content": element.q1,
            "isValues": element?.values ? element.values : []
          })
        }
      });
    } else {
      this.aiMessage = message.content;
      this.sendMessage('SUGGESTION-CLICK');
    }
  }

  onOptionClick(message, item) {
    this.aiMessage = message;
    this.messages.push({
      role: "user",
      content: message
    })
    this.sendMessage('SUGGESTION-CLICK');
    item.isValues = [];
  }

  sendMessage(type?: string) {
    if (this.aiMessage.trim() !== '') {
      if(!type) {
        this.messages.push({
          "role": "user",
          "content": this.aiMessage
        })
      }

      this.payloadOfChatBot = {
        "assistantType" : "ScenarioWriter",
        "userPrompt" : this.aiMessage
      }
      
      this.spinner = true;
      this.disabled = true;
      this.aiMessage = '';
      this.crudService.createLexieChat(this.payloadOfChatBot).subscribe((res: any) => {
        this.payloadOfChatBot = {};
        const responseMessage = res.data.choices[0].message.content;
        this.messages.push({"role": "assistant","content" : responseMessage});
        const index = this.messages.length - 1;
        
        this.typewriter(responseMessage, typedContent => {
          this.messages[index].content = typedContent;
          setTimeout(() => {
            this.scrollChatToBottom();
          }, 100);
        }, () => {
          this.disabled = false;
        });
        this.aiMessage = '';
        this.spinner = false;
      }, (err) => {
        this.spinner = false;
        this.disabled = false;
        this.toaster.error('Error!');
      });
    }
  }

  typewriter(text: string, callback: (typedContent: string) => void, finalCallback: () => void) {
    const delay = 10;
    let i = 0;
    const type = () => {
      if (i < text.length) {
        const typedContent = text.substring(0, i + 1);
        i++;
        setTimeout(() => {
          callback(typedContent);
          type();
        }, delay);
      } else {
        finalCallback();
      }
    };
    type();
  }

  scrollChatToBottom() {
    if (this.scrollBar) {
      this.scrollBar.nativeElement.scrollTop = this.scrollBar.nativeElement.scrollHeight;
    }
  }

  onAIContentCreator(type: string) {
    this.crudService.aiFreeBoardClicked(type);
  }

}
