import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'minuteSeconds'
})
export class MinuteSecondsPipe implements PipeTransform {

  // transform(value: number): string {
    // value = Number(value);
    // const h = Math.floor(value / 3600);
    // const m = Math.floor(value % 3600 / 60);
    // const s = Math.floor(value % 3600 % 60);
    // if (!value) {
    //   return '0 S';
    // }
    // const hDisplay = h > 0 ? h + (h === 1 ? ' H : ' : ' H, ') : '';
    // const mDisplay = m > 0 ? m + (m === 1 ? ' M :' : ' M, ') : '';
    // const sDisplay = s > 0 ? s + (s === 1 ? ' S :' : ' S') : '';
    // return hDisplay + mDisplay + sDisplay;

    // console.log("decimalSeconds = " + decimalSeconds);

  transform(decimalSeconds: number): string {
    if (!decimalSeconds) {
      decimalSeconds = 0;
    }
    const hh = Math.floor(decimalSeconds / 3600);

    let hhStr = '';
    if (hh < 10)
      hhStr = '0' + String(hh);
    else
      hhStr = String(hh);

    // console.log("hhStr = " + hhStr);

    const reminderSec = (decimalSeconds % 3600);

    // console.log("reminderSec = " + reminderSec);

    const mm = Math.floor(reminderSec / 60)
    // console.log("mm = " + mm);

    let mmStr = '';

    if (mm < 10)
      mmStr = '0' + String(mm);
    else
      mmStr = String(mm);

    // console.log("mmStr = " + mmStr);

    const ss = (reminderSec % 60);
    // console.log("ss = " + ss);

    let ssStr = '';
    if (ss < 10)
      ssStr = '0' + String(ss);
    else
      ssStr = String(ss);

    const pos = ssStr.indexOf('.');

    if (pos > 0) {
      ssStr = ssStr.substring(0, pos);
    }

    // console.log("ssStr = " + ssStr);

    // console.log("timeStr = " + timeStr);

    return hhStr + ':' + mmStr + ':' + ssStr;
  }

}
