import { Component, OnInit } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";
import { CrudService } from "src/app/services/crud.service";
import { MatDialog } from "@angular/material/dialog";
import { environment } from "src/environments/environment";
import { BehaviorSubject } from "rxjs";
import { EventType } from "../createInteractiveVideo/createInteractiveVideo.interface";
import { createVideoEvent } from "../createInteractiveVideo/createInteractiveVideo.data";
import { LocalStorageService } from "src/app/services/localStorage.service";

declare var $: any;
declare var d3;

@Component({
    selector: "app-upload-template-videos",
    templateUrl: "./upload-template-videos.component.html",
    styleUrls: ["./upload-template-videos.component.css"],
})
export class UploadTemplateVideosComponent implements OnInit {
    spinner: any = false;
    currentSelectedMenu: any = "marketing";
    selectedTreeData: any;
    templateValue: any[] = [
        {
            number: "01",
            title: "Welcome/How can I help Template",
            _id: environment.How_can_I_help_Template,
            type: "marketing",
        },
        {
            number: "02",
            title: "No Touch Customer Experience",
            _id: environment.No_Touch_Customer_Experience,
            type: "marketing",
        },
        {
            number: "03",
            title: "2 Scenario Stack",
            _id: environment.second_Scenario_Stack,
            type: "marketing",
        },
        {
            number: "01",
            title: "Training Paths",
            _id: environment.Training_Paths,
            type: "training",
        },
        {
            number: "02",
            title: "Training Paths + Scenarios",
            _id: environment.Training_Paths_Scenarios,
            type: "training",
        },
        {
            number: "03",
            title: "Learning Theory / Activity Paths",
            _id: environment.Learning_Theory_Activity_Paths,
            type: "training",
        },
    ];

    footerSection: any;
    buttonView: any;
    zoomBtnPosition: any;
    currentHeight: any;
    private zoomListener: any;
    private visRef: any;
    dragDropNode = false;
    nodes = [];
    latestNodeId: string;
    private rootInitialPosition: any;
    private zoomRef: any = 1;
    svgHeight;
    svgWidth;
    svgHeightForReset;
    svgWidthForReset;
    scaleIncrement;
    scaleForReset;

    assistEventsSubject = new BehaviorSubject<EventType>({ type: '' });
    private msgEvent = createVideoEvent;
    isAssistCreateVideo: any;

    constructor(private location: Location, private router: Router, private crudService: CrudService, public dialog: MatDialog, private localStorageService: LocalStorageService) {
        this.isAssistCreateVideo = this.localStorageService.getItem('assistCreateVideo');
    }

    ngOnInit(): void {
        if (this.isAssistCreateVideo) {
            this.assistEventsSubject.next({ type: this.msgEvent.init });
        }
    }

    onAssistEvent(e) {
        if (e.type === this.msgEvent.close) {
            this.isAssistCreateVideo = null;
            this.localStorageService.removeItem('assistCreateVideo');
        }
    }

    changeMenu(value: any) {
        this.currentSelectedMenu = value;
    }

    openModalForTreeTemplate(data: any) {
        this.spinner = true;
        this.selectedTreeData = data;
        this.getMainVideoDetail()  
    }
    
    
    getMainVideoDetail() {
        this.crudService.getTree(this.selectedTreeData._id).subscribe((res) => {
            $("#treeTeamplate").modal("show");
            this.callTree(res.data)
            $('#treeTeamplate').on('hidden.bs.modal', () => {
                this.rootInitialPosition = undefined
            });
        })
    }

    goBack() {
        if ((this.location.getState() as any)?.navigationId > 1)
            this.location.back();
        else this.router.navigateByUrl("/profile");
    }

    getCurrentWidth() {
        const width = window.innerWidth;
        if (width < 991) {
            this.footerSection = 'none';
            this.buttonView = 'block';
            this.zoomBtnPosition = '115px';
        } else {
            this.footerSection = 'block';
            this.buttonView = 'none';
            this.zoomBtnPosition = '70px';
        }
        return width;
    }


    callTree(data) {
        const a = this.getCurrentWidth();
        $('#tree-view svg').remove();
        setTimeout(() => {
            this.spinner = false;
            const that = this;
            if (a < 991) {
                this.currentHeight = $(window).height();
            } else {
                this.currentHeight = $(window).height() - 300;
            }

            const m = [20, 20, 20, 20];
            const w = $(window).width();
            let h = this.currentHeight;
            let i = 0;

            const x = d3.scale.linear().domain([0, w]).range([0, w]);
            const y = d3.scale.linear().domain([0, h]).range([0, h]);
            let root;

            that.zoomListener = d3.behavior.zoom().x(x).y(y).scaleExtent([0.1, 10]).on('zoom', zoom);
            // console.log("zoomListener ", this.zoomListener);

            const vis = d3
                .select('#tree-view')
                .append('svg:svg')
                .attr('viewBox', '0 -300 600 900')
                .attr('class', 'svgViewBox')
                .attr('id', 'svg-id')
                .attr('width', 1000)
                .attr('height', 550)
                .attr('xmlns', 'http://www.w3.org/2000/svg')
                .append('svg:g')
                .attr('id', 'rootg')
                .call(that.zoomListener);


            this.visRef = vis;

            vis.append('defs').append('marker')
                .attr('id', 'arrowhead')
                .attr('viewBox', '0 -5 10 10')
                .attr('refX', 10)
                .attr('refY', 0)
                .attr('markerWidth', 6)
                .attr('markerHeight', 6)
                .attr('orient', 'auto')
                .append('path')
                .attr('d', 'M0,-5L10,0L0,5');

            vis
                .append('rect')
                .attr('class', 'overlay')
                .attr('width', w + m[1] + m[3])
                .attr('height', h + m[0] + m[2])
                .attr('opacity', 0)
                .attr('fill', '#eee');

            let tree = d3.layout
                .tree()
                .size([h, w])
                .nodeSize([200 + 10, 700 + 10])
                .separation(() => {
                    return 0.5;
                });

            const diagonal = d3.svg.diagonal().projection((d) => {
                return [d.y, d.x];
            });

            root = data;
            root.x0 = h / 2;
            root.y0 = 0;

            // initialize the display to show a few nodes.

            this.visRef.attr('class', this.dragDropNode ? 'rearrange' : '');

            update(root);

            function update(source) {
                i = 0;
                const duration = d3.event && d3.event.altKey ? 5000 : 500;
                // Compute the new height, function counts total children of root node and sets tree height accordingly.
                // This prevents the layout looking squashed when new nodes are made visible or looking sparse when nodes are removed
                // This makes the layout more consistent.
                const levelWidth = [1];
                const childCount = (level, n) => {

                    if (n.children && n.children.length > 0) {
                        if (levelWidth.length <= level + 1) levelWidth.push(0);

                        levelWidth[level + 1] += n.children.length;
                        n.children.forEach(d => {
                            childCount(level + 1, d);
                        });
                    }
                };
                childCount(0, root);
                h = (d3.max(levelWidth) + 1) * 175; // 25 pixels per line
                const ww = (levelWidth.length + 1) * 250; // 25 pixels per line
                tree = tree.size([h, ww]);
                vis.select('rect.overlay')
                    .attr('height', h)
                    .attr('width', ww)
                // Compute the new tree layout.
                const nodes = tree.nodes(root).reverse();

                // Normalize for fixed-depth.
                nodes.forEach((d) => d.y = d.depth * 250);

                // Update the nodes...
                const node = vis.selectAll('g.node').data(nodes, (d) => {
                    return (d.id = ++i);
                });

                // Enter any new nodes at the parent's previous position.
                const nodeEnter = node
                    .enter()
                    .append('svg:g')
                    .attr('class', 'node')
                    .attr('id', (d) => 'node-' + d.id)
                    .attr('transform', (d) => 'translate(' + source.y0 + ',' + source.x0 + ')')
                    .on('click', (d) => {
                        if (d3.event.defaultPrevented) return;
                        // that.clickedItem(d);
                    });

                // Update the nodes...
                vis.selectAll('g.node').data(nodes, (d) => {
                    return (d.ele = this);
                });

                nodeEnter.append('svg:circle')
                    .attr('r', 1e-6)
                    .style('fill', (d) => {
                        return d._children ? '#000' : '#000';
                    })

                nodeEnter.append('rect')
                    .attr('joint-selector', 'body')
                    .attr('fill', 'rgba(255,255,255,0)')
                    .attr('width', 140)
                    .attr('height', 130)
                    .attr('y', -60)
                    .attr('x', -60)


                nodeEnter.append('rect')
                    .attr('joint-selector', 'body')
                    .attr('fill', '#fff')
                    .attr('width', '100')
                    .attr('height', '90')
                    //WC 12072023 --- To set the color of connector node and ending node to red
                    //.attr('stroke', (d) => d.isEndingNode  ? '#DC3545' : d.isConnectorNode ? '#3149e8' : '#ccc')
                    .attr('stroke', (d) => d.isEndingNode || d.isConnectorNode ? '#DC3545' : '#ccc')
                    .attr('y', -40)
                    .attr('x', -40)
                    .attr('rx', '8')
                    .attr('ry', '8')
                    .attr('id', (d) => 'node-button-' + d.id)
                    .attr('data-id', (d) => d._id)
                    .attr('class', (d) => {
                        if (d.isEndingNode) {
                            return 'not-droppable';
                        }
                        return `${d.children && d.children.length >= 4 ? 'not-droppable' : 'droppable'}`
                    });

                const dragListener = d3.behavior.drag().on('dragstart', dragstart).on('drag', drag).on('dragend', dragEnd);
                nodeEnter.call(dragListener)
                nodeEnter.on('mouseover', d => overCircle(d)).on('mouseout', d => outCircle(d));

                const imgGroup = nodeEnter.append('g');

                imgGroup.append('title').text(d => d.name);

                imgGroup.append('image')
                    .attr('y', -30)
                    .attr('x', -35)
                    .attr('clip-path', 'inset(0% round 8px)')
                    .attr('xlink:href', (d) => d.poster)
                    .attr('class', (d) => d.basck);

                imgGroup.append('image')
                    .attr('y', -30)
                    .attr('x', -5)
                    .attr('clip-path', 'inset(0% round 8px)')
                    .attr('cursor', 'pointer')
                    .attr('class', (d) => d.imvd)
                    .attr('xlink:href', (d) => d.poster1);

                const title = nodeEnter
                    .append('text')
                    .attr('y', 30)
                    .attr('x', 10);

                title.append('tspan')
                    .attr('text-anchor', 'middle')
                    .text(d => d.name)
                    .each(wrap);

                title.append('title').text(d => d.name);

                const btnGroup = nodeEnter.append('svg:g')
                    .attr('transform', 'matrix(1,0,0,1,10,50)')
                    .attr('fill', '#7d43be')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .attr('visibility', (d) => {
                        if (!d.URL || d.isEndingNode || d.isUrl) {
                            return 'hidden';
                        }
                        return `${d.children && d.children.length >= 4 ? 'hidden' : 'visible'}`
                    })
                    .on('click', (d) => {
                        if (d3.event.defaultPrevented) return;
                        d3.event.stopPropagation();
                        if (!d?.children || d?.children?.length < 4) {
                            // that.createInteractiveVideoObj.currentObj = d;
                            // that.openVideoListModal(d?.children?.length || 0);
                        }
                    });

                // btnGroup.append('title').text('Add choice');

                btnGroup.append('rect')
                    .attr('rx', 20)
                    .attr('ry', 20)
                    .attr('x', -10)
                    .attr('y', -10)
                    .attr('width', 20)
                    .attr('height', 20);

                btnGroup.append('svg:path')
                    .attr('d', 'M -4 0 4 0 M 0 -4 0 4')
                    .attr('stroke', '#ffffff')
                    .attr('stroke-width', '2');

                // questionnaire image
                const quesImgGroup = nodeEnter.append('svg:g')
                    .attr('transform', 'matrix(1,0,0,1,45,-50)')
                    .attr('fill', 'mediumpurple')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .attr('visibility', (d) => {
                        return `${d.questionnaireInfo ? 'visible' : 'hidden'}`
                    });

                quesImgGroup.append('rect')
                    .attr('rx', 6)
                    .attr('ry', 6)
                    .attr('height', 25)
                    .attr('width', 25);

                quesImgGroup.append('image')
                    .attr('x', 3)
                    .attr('y', 3)
                    .attr('height', 19)
                    .attr('width', 19)
                    .attr('xlink:href', 'assets/images/questionnaire.png');

                // const timerIcon = nodeEnter.append('svg:g')
                //     .attr('transform', (d) => {
                //         const position = that.getTimerIconPosition(d);
                //         return position;
                //     })
                //     .attr('fill', 'transparent')
                //     .attr('cursor', 'pointer')
                //     .attr('class', 'node-action')
                //     .attr('visibility', (d) => {
                //         return `${d?.timer ? 'visible' : 'hidden'}`
                //     });

                let timerIconHTML = `<div style="font-size: medium; background: mediumpurple; border-radius: 20%; padding: 1px 4px 0px; color: white;"><i class="fa fa-clock"></i></div>`;

                // timerIcon.append('foreignObject')
                //     .attr('height', 25)
                //     .attr('width', 25)
                //     .html(timerIconHTML);

                const subTitleIcon = nodeEnter.append('svg:g')
                    .attr('transform', (d) => {
                        return `${d.questionnaireInfo ? 'matrix(1,0,0,1,8,-50)' : 'matrix(1,0,0,1,45,-50)'}`
                    })
                    .attr('fill', '#fff')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .attr('visibility', (d) => {

                        return `${d.videoID && (d.subtitleUrl || d.subtitleTranslations?.length > 0) ? 'visible' : 'hidden'}`

                    });

                subTitleIcon.append('rect')
                    .attr('rx', 6)
                    .attr('ry', 6)
                    .attr('height', 25)
                    .attr('width', 35);

                subTitleIcon.append('image')
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('height', 25)
                    .attr('width', 35)
                    .attr('xlink:href', 'assets/images/subtitle.svg')

                subTitleIcon.on('mouseover', function (d) {
                    if (d) {
                        let subTitleContent = '<div><b>Subtitles</b>';
                        d.subtitleTranslations.forEach((subtitleTranslation) => {
                            subTitleContent += `
                            <div>
                                ${subtitleTranslation?.subtitle?.language} 
                                (${subtitleTranslation?.subtitle?.code})
                            </div>
                        `;
                        });
                        subTitleContent += '</div>';
                        // $(this).popover({
                        //     content: subTitleContent,
                        //     placement: 'right',
                        //     trigger: 'hover',
                        //     html: true
                        // });
                        // $(this).popover('show');
                    }
                }).on('mouseout', function () {
                    $(this).popover('hide');
                });

                function wrap() {
                    const self = d3.select(this);
                    let textLength = self.node().getComputedTextLength();
                    let text = self.text();
                    while (textLength > (100 - 2 * 10) && text.length > 0) {
                        text = text.slice(0, -1);
                        self.text(text + '...');
                        textLength = self.node().getComputedTextLength();
                    }
                }

                // Transition nodes to their new position.
                const nodeUpdate = node
                    .transition()
                    .duration(duration)
                    .attr('transform', (d) => {
                        return 'translate(' + d.y + ',' + d.x + ')';
                    });
                // // .attr("-webkit-transform", function (d) {
                // });



                nodeUpdate
                    .select('circle')
                    .attr('r', 4.5)
                    .style('fill', (d) => {
                        return d._children ? '#000' : '#000';
                    });

                nodeUpdate.select('text').style('fill-opacity', 1);

                // Transition exiting nodes to the parent's new position.
                const nodeExit = node
                    .exit()
                    .transition()
                    .duration(duration)
                    .attr('transform', (d) => {
                        return 'translate(' + source.y + ',' + source.x + ')';
                    })
                    .remove();

                nodeExit.select('circle').attr('r', 1e-6);
                nodeExit.select('text').style('fill-opacity', 1e-6);

                // Add choice between two nodes
                const plusButton = vis
                    .append('g')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .style('display', 'none')
                    .on('click', (d) => {
                        if (d3.event.defaultPrevented) return;
                        d3.event.stopPropagation();
                        // that.insertNode({
                        //     parent: plusButton.attr('data-source'),
                        //     child: plusButton.attr('data-target')
                        // });
                    })
                    .on('mouseover', () => plusButton.style('display', null))
                    .on('mouseout', () => plusButton.style('display', 'none'));

                // plusButton.append('rect')
                //     .attr('joint-selector', 'body')
                //     .attr('fill', 'rgba(0,0,0,0.1)')
                //     .attr('transform', 'translate(-20, -20)') // center the button inside the `g`
                //     .attr('width', 40)
                //     .attr('height', 40)
                //     .attr('rx', 40);

                // plusButton
                //     .append('rect')
                //     .attr('fill', '#7d43be') // center the button inside the `g`
                //     .attr('transform', 'translate(-12, -12)') // center the button inside the `g`
                //     .attr('width', 25)
                //     .attr('height', 25)
                //     .attr('rx', 25);

                // plusButton.append('title').text('Add choice');

                // plusButton.append('svg:path')
                //     .attr('d', 'M -5 0 5 0 M 0 -5 0 5')
                //     .attr('stroke', '#ffffff')
                //     .attr('stroke-width', '2');

                const closeButton = vis
                    .append('g')
                    .attr('cursor', 'pointer')
                    .attr('class', 'node-action')
                    .style('display', 'none')
                    .on('click', (d) => {
                        if (d3.event.defaultPrevented) return;
                        d3.event.stopPropagation();
                        // that.deleteParentIdsNode({
                        //     parent: closeButton.attr('data-source'),
                        //     child: closeButton.attr('data-target')
                        // });
                    })
                    .on('mouseover', () => closeButton.style('display', null))
                    .on('mouseout', () => closeButton.style('display', 'none'));

                closeButton.append('rect')
                    .attr('joint-selector', 'body')
                    .attr('fill', 'rgba(0,0,0,0.1)')
                    .attr('transform', 'translate(-20, -20)')
                    .attr('width', 40)
                    .attr('height', 40)
                    .attr('rx', 20);

                closeButton
                    .append('rect')
                    .attr('fill', '#df1717')
                    .attr('transform', 'translate(-12.5, -12.5)')
                    .attr('width', 25)
                    .attr('height', 25)
                    .attr('rx', 12.5);

                closeButton.append('svg:path')
                    .attr('d', 'M 7.5 7.5 L 17.5 17.5 M 7.5 17.5 L 17.5 7.5')
                    .attr('stroke', '#ffffff')
                    .attr('stroke-width', '2')
                    .attr('transform', 'translate(-12, -12)');

                // Update the links...
                const jsonLinks = tree.links(nodes);
                // for (const conclusionLink of that.createInteractiveVideoObj.conclusionLinks) {
                //     jsonLinks.push(conclusionLink)
                // }
                vis.selectAll('path.link').remove();
                const link = vis.selectAll('path.link').data(jsonLinks);


                // Enter any new links at hte parent's previous position
                link
                    .enter()
                    .insert('svg:path', 'g')
                    .attr('class', (d) => {
                        return 'link' + (d.type ? (' ' + d.type) : '');
                    })
                    .attr('d', (d) => {
                        const o = {
                            x: source.x0,
                            y: source.y0,
                        };
                        return diagonal({
                            source: o,
                            target: o,
                        });
                    })
                    // Add choice between two nodes
                    .on('mouseover', function (d) {
                        if (d.type === 'conclusion') {
                            d3.select(this).style('stroke-width', '4px');
                            const linkElement = d3.select(this);
                            const linkWidth = linkElement.node().getTotalLength();
                            const pointAtLength = linkElement.node().getPointAtLength(linkWidth * 0.3);

                            const xb = pointAtLength.x;
                            const yb = pointAtLength.y;

                            closeButton.style('display', null)
                                .attr('transform', 'translate(' + xb + ', ' + yb + ')')
                                .attr('data-source', d.source._id)
                                .attr('data-target', d.target._id);

                            return;
                        }
                        d3.select(this).style('stroke-width', '4px');
                        // calculate the center of the path
                        const bbox = this.getBBox();
                        const xb = bbox.x + bbox.width / 2;
                        const yb = bbox.y + bbox.height / 2;

                        plusButton.style('display', null)
                            .attr('transform', 'translate(' + xb + ', ' + yb + ')')
                            .attr('data-source', d.source._id)
                            .attr('data-target', d.target._id);
                    })
                    .on('mouseout', function () {
                        d3.select(this)
                            .style('stroke-width', null)
                        plusButton.style('display', 'none');
                        closeButton.style('display', 'none');
                    });

                // Transition links to their new position.
                link.transition().duration(duration)
                    .attr('d', (d) => {
                        let sourceX = d.source.x;
                        let sourceY = d.source.y + 40;

                        let targetX = d.target.x;
                        let targetY = d.target.y - 40;

                        if (d.source.y > d.target.y) {
                            targetX = (d.target.x + 10);
                            targetY = (d.target.y + 60);
                            sourceY = (d.source.y - 20);
                        }

                        if (d.source.y === d.target.y) {
                            targetX = (d.target.x - 50);
                            targetY = (d.target.y + 45);
                        }

                        return diagonal({
                            source: { x: sourceX, y: sourceY },
                            target: { x: targetX, y: targetY },
                        });
                    })
                    .attr('marker-end', 'url(#arrowhead)');

                // Transition exiting nodes to the parent's new position.
                link
                    .exit()
                    .transition()
                    .duration(duration)
                    .attr('d', (d) => {
                        const o = {
                            x: source.x,
                            y: source.y,
                        };
                        return diagonal({
                            source: o,
                            target: o,
                        });
                    })
                    .remove();
                // link.attr('marker-start', 'url(#arrow)');

                // Stash the old positions for transition.
                nodes.forEach((d) => {
                    d.x0 = d.x;
                    d.y0 = d.y;
                });

                const rootG = document.getElementById('rootg');
                const rect = rootG.getBoundingClientRect();

                that.nodes = nodes;

                const calculateTranslation = (currentNode) => {
                    // console.log("currentNode ===> ", currentNode)
                    const xAxis = rect.x + (currentNode[0].y0);
                    const yAxis = (currentNode[0].x0 > 0) ? (rect.y + currentNode[0].x0) : (-currentNode[0].x0 - rect.y);

                    const x2 = window.innerWidth / 2;
                    const y2 = window.innerHeight / 2;
                    const x3 = xAxis > x2 ? (x2 - xAxis) : (xAxis - x2);
                    let y3 = 0;
                    if (currentNode[0].x0 > 0) {
                        y3 = Math.floor((yAxis > y2) ? (y2 - yAxis) : (yAxis - y2));
                    } else {
                        y3 = Math.floor((-yAxis < y2) ? (yAxis + y2) : (yAxis - (-y2)));
                    }
                    return [x3, y3];
                }
                let trans;
                if (that.latestNodeId) {
                    const latestNodeItem = that.getLatestItem(nodes, that.latestNodeId);
                    const latestNode = document.getElementById(`node-button-${latestNodeItem[0]?.id}`);
                    if (latestNode) {
                        latestNode.style.fill = '#d3c1eb';
                    }
                    trans = calculateTranslation(latestNodeItem);
                    // that.visRef.attr('transform', 'translate(' + trans + ') scale(1)');
                    // that.zoomListener.translate(trans);
                    that.translateTreeAndAddScrollBar(that.nodes);
                } else {
                    const latestNodeItem = that.getLatestItem(nodes, root._id);
                    trans = calculateTranslation(latestNodeItem);
                    that.visRef.attr('transform', 'translate(' + trans + ') scale(1)');
                }
                if (!that.rootInitialPosition) {
                    that.rootInitialPosition = trans;
                }
                // that.visRef.attr('transform', 'translate(' + trans + ') scale(1)');
                // that.zoomListener.translate(trans);


                // that.updateCurrentHighlight();
            }

            const panSpeed = 100;
            const panBoundary = 10;
            let panTimer;
            let translateCoords;
            let translateX;
            let translateY;
            let dragStarted = false;
            let nodesChild;
            let selectedNode = null
            let draggingNode = null;
            let domNode;

            const overCircle = d => {
                if (draggingNode && d.id !== draggingNode.id) {
                    selectedNode = d;
                    updateTempConnector();
                }
            };
            const outCircle = d => {
                selectedNode = null;
                updateTempConnector();
            };

            // Function to update the temporary connector indicating dragging affiliation
            const updateTempConnector = () => {
                if (draggingNode !== null && selectedNode !== null) {
                    $(`#node-button-${selectedNode.id}`).addClass('can-drop');
                } else {
                    $(`.can-drop`).removeClass('can-drop');
                }
            };

            function dragstart(d) {
                if (!that.dragDropNode) {
                    return;
                }
                if (d === root) {
                    return;
                }
                dragStarted = true;
                nodesChild = tree.nodes(d);
                d3.event.sourceEvent.stopPropagation();
                d3.select(this).classed('fixed', d.fixed = true);
            }

            function pan(domNode1, direction) {
                const speed = panSpeed;
                if (panTimer) {
                    clearTimeout(panTimer);
                    translateCoords = d3.transform(vis.attr('transform'));
                    if (direction === 'left' || direction === 'right') {
                        translateX = direction === 'left' ? translateCoords.translate[0] + speed : translateCoords.translate[0] - speed;
                        translateY = translateCoords.translate[1];
                    } else if (direction === 'up' || direction === 'down') {
                        translateX = translateCoords.translate[0];
                        translateY = direction === 'up' ? translateCoords.translate[1] + speed : translateCoords.translate[1] - speed;
                    }
                    const scaleX = translateCoords.scale[0];
                    const scaleY = translateCoords.scale[1];
                    const scale = that.zoomListener.scale();
                    vis.transition().attr('transform', 'translate(' + translateX + ',' + translateY + ')scale(' + scale + ')');
                    d3.select(domNode1).select('g.node').attr('transform', 'translate(' + translateX + ',' + translateY + ')');
                    that.zoomListener.scale(that.zoomListener.scale());
                    that.zoomListener.translate([translateX, translateY]);
                    that.zoomListener.event(that.visRef);
                    panTimer = setTimeout(() => {
                        pan(domNode1, direction);
                    }, 50);
                }
            }

            function drag(d) {
                if (!that.dragDropNode) {
                    // clearTimeout(panTimer);
                    return;
                }
                if (d === root) {
                    // clearTimeout(panTimer);
                    return;
                }
                if (!dragStarted) {
                    d.x0 += d3.event.dy;
                    d.y0 += d3.event.dx;
                }
                if (dragStarted) {
                    initiateDrag(d, this);
                }


                // get coords of mouseEvent relative to svg container to allow for panning
                const relCoords = d3.mouse($('#tree-view').get(0));
                if (relCoords[0] < panBoundary) {
                    panTimer = true;
                    pan(this, 'left');
                } else if (relCoords[0] > ($('#tree-view svg').width() - panBoundary)) {
                    panTimer = true;
                    pan(this, 'right');
                } else if (relCoords[1] < panBoundary) {
                    panTimer = true;
                    pan(this, 'up');
                } else if (relCoords[1] > ($('#tree-view svg').height() - panBoundary)) {
                    panTimer = true;
                    pan(this, 'down');
                } else {
                    try {
                        clearTimeout(panTimer);
                    } catch (e) {

                    }
                }

                const node1 = d3.select(this);
                node1.attr('transform', 'translate(' + d.y0 + ',' + d.x0 + ')');

            }

            function initiateDrag(d, domNode) {
                draggingNode = d;

                d3.select(domNode).select(`#node-button-${d.id}`).attr('class', 'active-drag');

                vis.selectAll('g.node').sort((a, b) => { // select the parent and sort the path's
                    if (a.id !== draggingNode.id) return 1; // a is not the hovered element, send "a" to the back
                    else return -1; // a is the hovered element, bring "a" to the front
                });
                // if nodes has children, remove the links and nodes
                if (nodesChild.length > 1) {
                    // remove link paths
                    const links = tree.links(nodesChild);
                    const nodePaths = vis.selectAll('path.link')
                        .data(links, d => d.target.id).remove();
                    // remove child nodes
                    const nodesExit = vis.selectAll('g.node')
                        .data(nodesChild, d => d.id).filter((d, i) => {
                            return d.id !== draggingNode.id;
                        }).remove();
                }

                // remove parent link
                const parentLink = tree.links(tree.nodes(draggingNode.parent));
                vis.selectAll('path.link').filter((d, i) => {
                    return d.target.id === draggingNode.id;

                }).remove();

                dragStarted = false;
            }

            function dragEnd(d) {
                clearTimeout(panTimer);
                if (!that.dragDropNode) {
                    return;
                }
                if (d === root) {
                    return;
                }
                domNode = this;
                if (selectedNode) {
                    if (!selectedNode.children) {
                        selectedNode.children = [];
                    }
                    if (!selectedNode.isEndingNode && selectedNode.children.length < 4) {
                        // now remove the element from the parent, and insert it into the new elements children
                        const index = draggingNode.parent.children.indexOf(draggingNode);
                        if (index > -1) {
                            draggingNode.parent.children.splice(index, 1);
                        }
                        if (typeof selectedNode.children !== 'undefined' || typeof selectedNode._children !== 'undefined') {
                            if (typeof selectedNode.children !== 'undefined') {
                                selectedNode.children.push(draggingNode);
                            } else {
                                selectedNode._children.push(draggingNode);
                            }
                        } else {
                            selectedNode.children = [];
                            selectedNode.children.push(draggingNode);
                        }
                        // Make sure that the node being added to is expanded so user can see added node is correctly moved
                        // expand(selectedNode);
                        // sortTree();
                        const object = {
                            videoId: draggingNode._id,
                            parentId: draggingNode.parentId,
                            newParentId: selectedNode._id
                        }
                        // that.saveDragDropNode(object);
                    } else {
                        endDrag();
                    }
                } else {
                    endDrag();
                }
            }

            function endDrag() {
                selectedNode = null;
                d3.selectAll('.ghostCircle').attr('class', 'ghostCircle');
                d3.select(domNode).attr('class', 'node');
                // now restore the mouseover event or we won't be able to drag a 2nd time
                d3.select(domNode).select('.ghostCircle').attr('pointer-events', '');
                if (draggingNode !== null) {
                    update(root);
                    draggingNode = null;
                    $(`.node button`).removeClass('drag-node');
                }
            }


            // zoom in / out
            function zoom() {
                if (d3.event.sourceEvent?.type === 'mousemove' && d3.event.sourceEvent.target.classList.contains('overlay')) {
                    clearTimeout(panTimer);

                    let treeContainer = document.getElementById('svg-id');
                    let transformScale = that.getTransformScale(treeContainer);
                    const scale = that.zoomRef ? that.zoomRef : transformScale;
                    const eTransform = d3.transform(that.visRef.attr('transform'));
                    const nTranslateX = (eTransform.translate[0] + d3.event.translate[0] * scale) / 2;
                    const nTranslateY = (eTransform.translate[1] + d3.event.translate[1] * scale) / 2;
                    that.visRef.attr('transform', 'translate(' + [nTranslateX, nTranslateY] + ') scale(' + scale + ')');
                    // that.visRef.attr('transform', 'translate(' + d3.event.translate + ') scale(' + d3.event.scale + ')');

                    that.zoomRef = scale;
                }
            }

            // this.createInteractiveVideoObj.selectedVideoCheck = false;
            // this.createInteractiveVideoObj.interactiveStep = 'three';
            // this.createInteractiveVideoObj.spinner = false;

            // WC 03012024 --- To prevent the tree scrolled to high and hide most of them including the main node
            // if (!that.latestNodeId) {
            //     that.translateTreeAndAddScrollBar(that.nodes);
            // }
        }, 500);

    }

    getLatestItem(nodes, id: string) {
        return nodes.filter(node => node._id === id);
    }

    translateTreeAndAddScrollBar(nodes, scale?) {
        let svgContainer = document.getElementById('rootg');
        let treeContainer = document.getElementById('svg-id');
        let treeView = document.getElementById('tree-view');

        const treeRect = svgContainer.getBoundingClientRect();
        const isTreeOutsideContainer = treeRect.right > svgContainer.clientWidth || treeRect.bottom > svgContainer.clientHeight;
        const scrollTopValue = (treeRect.height) / 2;

        if (isTreeOutsideContainer) {
            treeContainer.style.width = treeRect.width < treeView.clientWidth ? treeView.clientWidth + 'px' : (treeRect.width * 2) + 'px';
            treeContainer.style.height = (treeRect.height * 2) + 'px';
            treeContainer.style.overflow = 'auto';
            const scrollLeftValue = (treeContainer.scrollWidth - treeRect.width) / 3;

            let transformScale = this.getTransformScale(treeContainer);

            if (treeRect.width > 2000) {
                treeView.scrollTop = scrollTopValue;
                let trans = [50, -250];
                this.visRef.attr('transform', 'translate(' + trans + ') scale(' + transformScale + ')');
            } else {
                let trans;
                if (treeRect.width > 1000) {
                    trans = [50, -300];
                } else {
                    trans = [100, -200];
                }
                this.visRef.attr('transform', 'translate(' + trans + ') scale(' + transformScale + ')');
                if (treeContainer.clientWidth > 2000) {
                    // treeView.scrollLeft = scrollLeftValue;
                }
                if (treeContainer.clientHeight > 1200) {
                    treeView.scrollTop = scrollTopValue;
                }
            }
            // this.zoomRef = transformScale;
            // this.scaleForReset = transformScale;
        }
        if (this.latestNodeId) {
            // this.takeMeToLatestUpdatedNode(nodes)
        }
        else {
            const mainNode = nodes.find(node => node._id === this.selectedTreeData._id);

            const mainNodePosition = treeContainer.querySelector(`g[id=node-${mainNode.id}]`).getBoundingClientRect();

            const gTransform = (document.querySelector('#rootg') as SVGGElement).getCTM();

            const topValue = Math.abs(mainNodePosition.y) + Math.abs(gTransform.f);

            treeView.scrollLeft = (Math.abs(mainNodePosition.x)) - (Math.abs(gTransform.e) - treeView.clientWidth / 2);

            if (mainNode.x > 400) {
                treeView.scrollTop = topValue;

                if (treeView.scrollTop == 0)
                    console.log("treeView.scrollTop = 0")
                else
                    console.log("treeView.scrollTop = ", treeView.scrollTop);

                treeView.scrollLeft = Math.abs(mainNodePosition.x) - 200;

                if (treeView.scrollLeft == 0)
                    console.log("treeView.scrollLeft = 0")
                else
                    console.log("treeView.scrollLeft = ", treeView.scrollLeft);
            }
        }

        this.svgHeight = treeContainer.clientHeight;
        this.svgWidth = treeContainer.clientWidth;

        this.svgHeightForReset = treeContainer.clientHeight;
        this.svgWidthForReset = treeContainer.clientWidth;
    }

    getTransformScale(treeContainer) {
        const desiredHeight = 700;
        let transformScale = desiredHeight / treeContainer.clientHeight;



        //WC 02172024 to avoid scaling too small

        if (transformScale < 0.12) {
            transformScale = 0.12;

        }
        //WC 02172024

        return transformScale;
    }


    zoomIn() {
        if (this.scaleIncrement == undefined) {
            if (this.zoomRef <= 0.10) {
                this.scaleIncrement = 0.10;
            } else {
                this.scaleIncrement = 0.10;
            }
        }

        this.zoomRef = this.zoomRef + this.scaleIncrement;

        this.zoomListener.scale(this.zoomRef);

        this.translateZoomInOut('IN', this.zoomRef);
        //WC 02172024

    }

    zoomOut() {
        // this.zoomListener.event(this.visRef);

        if (this.zoomRef >= 0.1) {

            //WC 02172024
            //this.zoomRef = this.zoomRef - 0.1;
            if (this.scaleIncrement == undefined)
                this.scaleIncrement = this.zoomRef * 0.1;

            //console.log("this.scaleIncrement = ", this.scaleIncrement);

            this.zoomRef = this.zoomRef - this.scaleIncrement;
            //WC 02172024

            this.zoomListener.scale(this.zoomRef);
            this.translateZoomInOut('OUT', this.zoomRef);
        }
        // this.visRef
        //     .transition()
        //     .duration(750)
        //     .attr('transform', 'scale(' + this.zoomRef + ')');
    }

    zoomReset() {
        // this.visRef
        //     .transition()
        //     .duration(750)
        //     .attr('transform', 'scale(' + 1 + ')');
        let treeContainer = document.getElementById('svg-id');
        let transformScale = this.getTransformScale(treeContainer);;
        this.zoomRef = transformScale;
        // this.translateZoomInOut('RESET', transformScale);
        this.visRef.attr('transform', 'translate(' + this.rootInitialPosition + ') scale(1)');
        // this.zoomListener.scale(this.zoomRef);
        // this.zoomListener.translate(this.rootInitialPosition);
        // this.zoomListener.event(this.visRef);
    }

    translateZoomInOut(type: string, scale?) {
        let svgContainer = document.getElementById('rootg');
        let treeContainer = document.getElementById('svg-id');
        let treeView = document.getElementById('tree-view');

        const treeRect = svgContainer.getBoundingClientRect();
        const isTreeOutsideContainer = treeRect.right > svgContainer.clientWidth || treeRect.bottom > svgContainer.clientHeight;
        const scrollTopValue = (treeRect.height) / 2;

        if (isTreeOutsideContainer) {
            let currentTransform = this.visRef.attr('transform');
            
            if (type === 'IN') {
                // WC 02/17/2024 --- To avoid making the node size too big after increase zooming by 10%
                // treeContainer.style.width = (this.svgWidth * 2) + 'px';
                // treeContainer.style.height = (this.svgHeight * 2) + 'px';
            }
            if (type === 'OUT') {
                // WC 02/17/2024 --- To avoid making the node size too big after increase zooming by 10%
                // treeContainer.style.width = (this.svgWidth / 2) + 'px';
                // treeContainer.style.height = (this.svgHeight / 2) + 'px';
            }
            if (type === 'RESET') {
                treeContainer.style.width = this.svgWidthForReset;
                treeContainer.style.height = this.svgHeightForReset;
            }
            let transformScale = scale;

            treeContainer.style.overflow = 'auto';
            // const scrollLeftValue = (treeContainer.scrollWidth - treeRect.width) / 3;

            // let transformScale = scale ? scale : 0.7;
            if (treeRect.width > 2000) {
                // transformScale = scale ? scale : 0.2;
                let trans = [50, -250]
                this.visRef.attr('transform', `${currentTransform.split(' ')[0]}  scale(${transformScale})`);
            } else {
                let trans;
                if (treeRect.width > 1000) {
                    trans = [100, -300];
                } else {
                    trans = [100, -300];
                }

                this.visRef.attr('transform', `${currentTransform.split(' ')[0]}  scale(${transformScale})`);
                if (treeContainer.clientWidth > 2000) {
                }
                if (treeContainer.clientHeight > 1200) {
                    treeView.scrollTop = scrollTopValue;
                }
            }


            // this.visRef.attr('transform', currentTransform);
            this.zoomRef = transformScale;
        }

        const mainNode = this.nodes.find(node => node._id === this.selectedTreeData._id);
        const mainNodePosition = (treeContainer.querySelector(`g[id=node-${mainNode.id}]`) as SVGGElement).getCTM();
        const topValue = Math.abs(mainNodePosition.f);

        if (mainNode.x > 400) {
            treeView.scrollTop = 0;
            treeView.scrollLeft = 0;
            treeView.scrollTop = topValue - 200;
            treeView.scrollLeft = Math.abs(mainNodePosition.e) - 200;
        }

        this.svgHeight = treeContainer.clientHeight;
        this.svgWidth = treeContainer.clientWidth;
    }

    selectTemplate(){
        this.crudService.cloneTemplate({ videoId : this.selectedTreeData._id}).subscribe((res)=> {
            this.router.navigate(['create-interactive-video'], {queryParams : {video : res.data.clonedTemplateId}})
        },
        (error) => {
            // console.log("error", error)
        }
        )
    }

}
