import {AfterViewChecked, Component, OnDestroy, Inject, OnInit, TemplateRef, ViewChild, ElementRef, Renderer2, HostListener, AfterViewInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {CrudService} from '../../../services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {DeleteConfirmationComponent} from '../../../layout/modals/delete-confirmation/delete-confirmation.component';
import {takeUntil} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {CreateGroupComponent} from '../create-group/create-group.component';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material/dialog';
import {ModalVideoPlayerComponent} from '../../../layout/modals/modal-video-player/modal-video-player.component';
import {OpenCollabComponent} from '../../../layout/modals/open-collab/open-collab.component';
import {UploadedFileResponse} from '../../../interfaces/upload-video.interface';
import {BookmarkVideoResponse} from '../../../interfaces/interactive-video.interface';
import {CreateVideoComponent} from '../../../layout/modals/create-video/create-video.component';
import {PricingDialogComponent} from '../pricing-dialog/pricing-dialog.component';
import {DashboardService} from '../../../services/dashboard.service';
import {
    VideoCommentTypeDialogComponent
} from '../../../layout/modals/video-comment-type-dialog/video-comment-type-dialog.component';

import {ExcelService} from '../../../services/excel.service';
import sanitizeSVG from '@mattkrick/sanitize-svg';
import {LocalStorageService} from '../../../services/localStorage.service';

import {ShareVideoDialogComponent} from "../../../layout/modals/share-video-dialog/share-video-dialog.component";
import {environment} from "../../../../environments/environment";
import {UtilityService} from "../../../services/utility.service";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { QRCodeModalComponent } from '../../qr-code-modal/qr-code-modal.component';
import { UploadLinkDialogComponent } from 'src/app/layout/modals/create-video/upload-link-dialog/upload-link-dialog.component';
import { Editor, NgxEditorComponent, Toolbar } from 'ngx-editor';
import { EventEmitter } from 'protractor';
import { MAT_SORT_HEADER_INTL_PROVIDER_FACTORY } from '@angular/material/sort';
import { AnalyticsModalComponent } from '../../new-my-videos/analytics-modal/analytics-modal.component';
import { QuestionnairesDashboardComponent } from '../../questionnaires-dashboard/questionnaires-dashboard.component';

interface CollabRequestData {
    video?: string,
    comment?: string,
    requester?: string,
    requesterData?: any
}

declare var $: any;

@Component({
    selector: 'app-new-my-group',
    templateUrl: './new-my-group.component.html',
    styleUrls: ['./new-my-group.component.css']
})
export class NewMyGroupComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy  {

    @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;
    @ViewChild('notMemberDialog') notMemberDialog: TemplateRef<any>;
    @ViewChild('afterMemberDialog') afterMemberDialog: TemplateRef<any>;
    @ViewChild('addNewTabDialog') addNewTabDialog: TemplateRef<any>;
    @ViewChild('moveToTabDialog') moveToTabDialog: TemplateRef<any>;
    @ViewChild('commentReply') commentReply!: ElementRef;
    @ViewChild('offcanvasRight') offcanvasRight: ElementRef;
    @ViewChild('closeButton') closeButton: ElementRef;
    @ViewChild('maiTextarea', { static: true }) ngxEditor: ElementRef; // Reference to ngx-editor component
    // @Output() closed: EventEmitter = new EventEmitter();

    collabRequestData: CollabRequestData = {
        comment: undefined,
        video: undefined,
        requester: undefined
    };
    spinner = false;
    contentSpinner = false;
    allGroups = [];
    groupDetail: any;
    groupPlanDetails = [];
    memberLength: any;
    groupMembers = {
        active: '',
        admin: [],
        members: [],
        subscribers: []
    };
    baseUrl = environment.currentDomain + 'my-group/'
    // allUsers: Array<any> = [];
    isProcessPartial = false;
    posterThumbnail: any;
    selectedFile: any;
    comment: any = ' '
    commentId: any = null;
    isPrivate = false;
    isSubscriptionGroup = false;
    groupId: any;
    currentUserInformation: any;
    grouNameLength: any = 0;
    descriptionLength: any = 0;
    adminTitleLength: any = null;
    contentLength: any = 0
    groupName: string;
    adminTitle: string = null;
    courseTitle = '';
    description = '';
    // isClicked: any = [];
    isPublics = true;
    reply: any = '';
    allReply: any = null;
    tempCommentId: any;
    videoItem = [];
    linkItem = [];
    // allVideos = [];
    groupAllTabsContent = [];
    groupActiveTabContent = [];
    groupAllVideos: Array<any> = [];
    groupAllLinks: Array<any> = [];
    isMediaContent = false;
    isLinkMediaContent = false;
    isDescriptionEdit = false;
    isTitleEdit = false;
    isAdminTitleEdit = false;
    isCourseTitle = false
    isPosterEdit = false;
    commentLimit = 1000;
    replyLimit = 300;
    likeClicked = false;
    followersToAdd: Array<any> = [];
    ownerFollowersToAdd: Array<any> = [];
    myVideos: Array<any> = [];
    ownerVideos: Array<any> = [];
    addVideoPage = 1;
    addGroupShareVideoPage = 1;
    myLinks: Array<any> = [];
    myDocuments: Array<any> = [];
    ownerLinks: Array<any> = [];
    addLinkPage = 1;
    addOwnerLinkPage = 1;
    isSeeMoreComment = true
    isSeeMoreReply = true
    seeMoreCommentId: any = null;
    currentGroupOwnerId: any = null;
    currentGroupAdminInfo: any;
    // membersExist: Array<any> = [];
    currentAccessView: any = 'Join';
    requestCount = 0;
    isAdmin = false;
    isMember = false;
    isRequested = false;
    onlyAdminArray: Array<any> = []
    adminCount = 0;
    addMemberProcess = false;
    updateComments: string = null;
    replyId: any;
    likeId: any;
    countLikeDisLike = 0;
    isLiked = false;
    term: any;
    // processing = true;
    subscription: Subscription;
    isFreemiumPlan = false;

    selectType = [
        {id: 1, value: 'ADMIN'},
        {id: 2, value: 'MEMBER'}
    ];
    selectTypeSubscription = [
        {id: 1, value: 'ADMIN'},
        {id: 3, value: 'SUBSCRIBER'}
    ];

    buyer = {
        isBuyer: false,
        isSubscriber: false,
    }
    uploadFiles = [];
    allGroupComments: Array<any> = [];
    loadAllGroups = false;
    subscriptionGroupShoppingCartExist = false;
    currentUserId: string;
    currentCommentPage = 1;
    private selectedPlanId: string;
    private notMemberDialogOpened = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    communityName: string;
    fieldPassTextType = false;
    passwordInput = '';
    passwordValidated = false;
    passwordInputInValid = '';
    currentContentTab:any;
    status = '';
    currentImage: any;
    contentTabs = [];
    tabName = '';
    currentMoveItem: any;
    currentMemberTab = 'admin';
    currentAddVideoTab = 'admin';
    currentAddLinkTab = 'admin';
    childCommentId: any = null;
    isEmbed: any;
    //WC 11032023
    isJoin: any;
    isCompleted: any
    //WC 11032023
    private markVideoToRefresh = false;
    groupTabsContentSize = null;
    defaultEditTabType:any;
    isEditGroupTabName:boolean = false;
    indexForEditTabName:number;
    allTabsForReOrder:any[] = [];
    isCalledContentInFunction:boolean = true;
    linkType: 'Links' | 'Documents' = "Links"
    selectedBadge:any;

    postBodyEditor: Editor;
    updateCommentEditor: Editor;
    updateReplyCommentEditor: Editor;
    replyCommentEditor: Editor;
    paramsValue:any;
    UpdatedCommentVideoType: any;

    toolbar: Toolbar = [
        ['bold', 'italic'],
        //WC 10102024 --- PenTestFix
        //['underline', 'strike'],
        ['underline'],
        ['ordered_list', 'bullet_list'],
        //WC 10102024 --- PenTestFix
        //[{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
        ['link', 'image'],
    ];
    offcanvasElement:any;
    isDisableComment = false;
    subscriberLength:number;
    memberlength:number;
    isOpenUserMenu:boolean = false;
    isOpenUserMenuForUpdate:boolean = false;
    isOpenUserMenuForReply:boolean = false;
    isAddSignValue:boolean = true;
    myFollowers:any[] = [];
    mentionUsers: any[] = [];
    searchUser:any;
    userPage = 1;
    userLimit = 10;
    selectedCommentTypeForMentionUser:any = 'comment';
    currentMentionUsersType: "subscriber" | "member" | "followers" = "followers"
    topicValue:any= '';
    currentTopicValue:any = 'Forum';
    editTopicValue: any = ''
    topicDescription: any;
    editTopicIndex:any;
    allTopicsForReOrder:any[] = []
    placeHolderValue:any = "Search a user";
    searchUserName:any;
    isPressEnter:boolean = false;
    lastKnownMentions: Set<string> = new Set();


    //WC 07192024 --- To detect if the user is typing @ to mention another user
    isMentioning: boolean = false;
    currentMessage: string = "";
    previousMessage: string = "";
    newMentionPos: any = null;
    editorActivity: string = "";
    keyPressStr:any;
    previousKeyPressStr: any;
    isPinnedComments: boolean = false;
    topicWithStarCount: any;
    isPersonalize:any;

    get buyerButNotSubscriber() {
        
        // console.log("in buyserButNotSubscriber - this.groupPlanDetails.length = ", this.groupPlanDetails.length);

        if (this.groupPlanDetails.length) {
            return !this.isAdmin &&
            !this.isMember &&
            !this.buyer.isSubscriber &&
            (this.buyer.isBuyer || this.isSubscriptionGroup);
        }
        return !this.isMember && !this.isAdmin;
    }

    // get isFixedCurrentContentTab() {
    //     return ['links', 'videos'].includes(this.currentContentTab);
    // }

    get isFixedCurrentContentTab() {
        // if(this.groupDetail?.tabOrders?.length){
        //     return ['Videos', 'Links'].includes(this.defaultEditTabType);
        // }else{
        //     return ['Videos', 'Links'].includes(this.defaultEditTabType);
        // }
        // console.log("this.defaultEditTabType", this.defaultEditTabType);
        
        return ['Videos', 'Links', undefined, null].includes(this.defaultEditTabType);

    }

    @HostListener('document:click', ['$event'])
        onClick(event: any) {
            // Check if the click event happened outside of the offcanvas            
            if (!this.offcanvasElement.contains(event.target as Node)) {
                // Offcanvas is closed
                    // this.handleOffcanvasClose();
                
            }

            if(event.target.className.includes("serachInput") || event.target.className.includes("tagUser") || event.target.className.includes("usertype") || event.target.className.includes("active")){
                return
            }else{                
                this.isOpenUserMenu = false;
                this.isOpenUserMenuForUpdate = false;
                this.isOpenUserMenuForReply = false;            
            }
        }

    constructor(private activateRoute: ActivatedRoute,
                private excelService: ExcelService,
                private toaster: ToastrService,
                private api: CrudService,
                private router: Router,
                private dashboardService: DashboardService,
                private localStorageService: LocalStorageService,
                private utilityService: UtilityService,
                private sanitizer: DomSanitizer,
                private dialog: MatDialog,
                private renderer: Renderer2,
                private elementRef: ElementRef,
                @Inject(DOCUMENT) private document) {   
                
                    this.postBodyEditor = new Editor();
                    this.updateCommentEditor = new Editor();
                    this.replyCommentEditor = new Editor();
                    this.updateReplyCommentEditor = new Editor();
                
                this.currentUserId = this.localStorageService.getItem('user');
                this.isPersonalize = this.localStorageService.getItem('isPersonalize');
                // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.activateRoute.queryParams.subscribe(params => {

            //WC 11032023 --- To handle redirect from login when an anonymous user is joining a non-subscription group
            if (params.action && params.action === 'join') {
                this.isJoin = true;
            }
            //WC 11032023
            if (params.embed && params.embed === 'true') {
                this.isEmbed = params?.embed;
            }
            this.collabRequestData.comment = params?.comment;
            this.collabRequestData.video = params?.video;
            this.childCommentId = params?.video
            this.collabRequestData.requester = params?.requester;
            this.status = params?.status;
        });        
    }

    ngOnInit(): void {

        // this.getFollowers(true)

        this.offcanvasElement = this.elementRef.nativeElement.querySelector('#offcanvasRight');

        this.buyer.isBuyer = this.router.url.indexOf('/community') !== -1;
        this.activateRoute.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.paramsValue = res;
            if (res.plan_id) {
                this.selectedPlanId = res.plan_id;
                //console.log("this.selectedPlanId = ", this.selectedPlanId);
            }
        });

        // console.log('this.buyer = ', this.buyer);

        this.activateRoute.params.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.groupId = res.id;
            this.getCountOfStarCommentsForGroup();

            if (this.localStorageService.getItem('token') || this.localStorageService.getItem('anonymousToken')) {
                this.initAll();
            } else {
                this.getAnonymousUser();
            }
        });

        this.dashboardService.getShoppingCarts.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
            this.subscriptionGroupShoppingCartExist = res.find(x => (x.groupId === this.groupId));
            // console.log('onInit - this.subscriptionGroupShoppingCartExist = ', this.subscriptionGroupShoppingCartExist)
        });

        //this.getFollowers(true);

    }

    ngAfterViewInit(): void {

        //WC 09142024 --- user is being redirected from clicking join on the featured community
        if (this.paramsValue?.featureGroupRequest && this.paramsValue?.featureGroupRequest === 'join'){

            const currentUrl = window.location.pathname;
            // console.log("in ngAfterViewInit - currentUrl = ", currentUrl);
    
            // Non-subscription group
            if (currentUrl.includes("my-group")) {
                //document.getElementById("joinGroup").click();

                // Subscription group
                this.spinner = true;
                const checkSubscribeBtnAndClick = () => {
                    const subscribeBtn: HTMLElement = document.getElementById("joinGroup");

                    if(subscribeBtn) {
                        subscribeBtn.click();
                        this.spinner = false;
                        clearInterval(intervalId);
                    }
                    
                };

                const intervalId = setInterval(checkSubscribeBtnAndClick, 1000);

            } else {
                // Subscription group
                this.spinner = true;
                const checkSubscribeBtnAndClick = () => {
                    const subscribeBtn: HTMLElement = document.getElementById("subscribeGroup");

                    if(subscribeBtn) {
                        subscribeBtn.click();
                        this.spinner = false;
                        clearInterval(intervalId);
                    }
                    
                };

                const intervalId = setInterval(checkSubscribeBtnAndClick, 1000);
                  
            }
        }
        // this.checkScrollVisibility();
        // this.checkScrollVisibilityForGroupTabs();
    }

    checkScrollVisibility(): void {
        setTimeout(() => {
            const scrollRight = document.getElementById("right-arrow-board");
            const scrollLeft = document.getElementById("left-arrow-board");
            const productListContainer: any = document.getElementById("theme-scrollbar-board");

            // Check scroll visibility on load
            const maxScrollLeft = productListContainer.scrollWidth - productListContainer.clientWidth;

            if (productListContainer.scrollWidth <= productListContainer.clientWidth) {
                scrollLeft.style.display = 'none';
                scrollRight.style.display = 'none';
            } else {
                scrollLeft.style.display = 'block';
                scrollRight.style.display = 'block';
            }
        }, 2000)
    }

    checkScrollVisibilityForGroupTabs(): void {
        setTimeout(() => {
            const scrollRight = document.getElementById("right-arrow");
            const scrollLeft = document.getElementById("left-arrow");
            const productListContainer: any = document.getElementById("theme-scrollbar");

            // Check scroll visibility on load
            const maxScrollLeft = productListContainer.scrollWidth - productListContainer.clientWidth;

            if (productListContainer.scrollWidth <= productListContainer.clientWidth) {
                scrollLeft.style.display = 'none';
                scrollRight.style.display = 'none';
            } else {
                scrollLeft.style.display = 'block';
                scrollRight.style.display = 'block';
            }
        }, 1500)
    }


    initAll() {

        this.addGroupShareVideoPage = 1;
        this.ownerVideos = [];
        this.ownerLinks = [];
        this.currentAddVideoTab = 'admin';
        this.currentAddLinkTab = 'admin';
        this.currentMemberTab = 'admin';
        this.currentContentTab = 'videos';
        this.groupAllTabsContent = [];
        this.getCurrentGroupDetail(this.groupId);
        // this.getAllUsers();
        this.getCurrentUserInfo();

    }

    getAnonymousUser() {
        this.api.getAnonymousUser().pipe(takeUntil(this._unsubscribeAll)).subscribe((success) => {
            try {
                this.localStorageService.setItem('anonymousToken', success.data.token);
            } catch {
                this.api.anonymousToken = success.data.token;
            }
            this.initAll();
        });
    }

    getAllGroups(admin = null) {
        this.spinner = true;
        this.loadAllGroups = true;
        let obj: any = {};
        //console.log("admin ==> ", admin);
        //console.log("this.buyer ==> ", this.buyer);
        
        if (this.buyer.isBuyer && this.groupDetail.isSubscriptionGroup) {
            obj = {
                accessType: 'ADMIN',
                isPlanAssigned: true,
                isSubscriptionGroup: true
            }
        }
        
        if (admin) {
            obj.userId = admin;
        }
        this.api.getAllGroups(obj).subscribe(res => {
            $('.owl-carousel-two').owlCarousel('destroy');
            $('.tooltip.show').remove();

            this.allGroups = res.data;

            setTimeout(() => {                
                const owl = $('.owl-carousel-two');
                owl.owlCarousel({
                    loop: false,
                    margin: 10,
                    autoWidth: true,
                    nav: true,
                    dots: false,
                    responsive: {
                        0: {
                            items: 1
                        },
                        600: {
                            items: 3
                        },
                        1000: {
                            items: 10
                        }
                    }
                });
            }, 500);

        }).add(() => {
            setTimeout(() => {
                this.spinner = false;
                this.loadAllGroups = false;
            }, 500);
        });
    }


    onMyGroup(item: any) {
        if (this.buyer.isBuyer) {
            this.router.navigate(['/community/' + item._id], {queryParams: {embed: this.isEmbed}});
        } else {
            this.router.navigate(['/my-group/' + item._id], {queryParams: {embed: this.isEmbed}});
        }
    }

    getCurrentMember() {
        this.api.getGroupDetail(this.groupId).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.groupDetail = res.data[0];
            // return
            if (this.currentUserId) {
                this.api.getGroupUser(this.groupId, this.currentUserId)
                    .pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                    this.followersToAdd = res.data;                    
                });
            }
            if (this.currentUserId !== this.groupDetail.admin) {
                this.api.getGroupUser(this.groupId, this.groupDetail.admin)
                    .pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                    this.ownerFollowersToAdd = res.data;    
                });
            }
        })
    }


    viewFullReply(item: string, str: string, id: any) {
        if (str === 'comment') {
            this.commentLimit = item.length;
            this.isSeeMoreComment = false;
            this.seeMoreCommentId = id;
        } else {
            this.replyLimit = item.length;
            this.isSeeMoreReply = false;
        }

    }

    viewLessReply(item: number, str: string, id: any) {
        if (str === 'comment') {
            this.commentLimit = item;
            this.isSeeMoreComment = true;
            this.seeMoreCommentId = id;
        } else {
            this.replyLimit = item;            
            this.isSeeMoreReply = true;
        }
    }

    // getAllUsers() {
    //     if (this.currentUserId) {
    //         this.api.getAllUsers(this.currentUserId).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
    //             this.allUsers = res.data.followersinfo;
    //             this.allUsers.forEach(e => {
    //                 e.info.isSelected = false;
    //                 e.info.accessType = null;
    //             });
    //         })
    //     }
    // }

    onScrollMyVideo(): void {
        ++this.addVideoPage;
        this.getAllMainVideo();
    }

    getAllMainVideo(reset?) {
        if (reset) {
            this.addVideoPage = 1;
            this.myVideos = [];
        }
        this.api.getInteractiveVideoV2(null, true, 10, this.addVideoPage, 'recent')
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            const allMainVideo = res.data;
            allMainVideo.forEach(e => {
                if (e.isPublished) {
                    this.myVideos.push(e)
                }
            });

        })
    }

    onScrollOwnerVideo(): void {
        ++this.addGroupShareVideoPage;
        this.getAllOwnerMainVideo();
    }

    getAllOwnerMainVideo(reset?) {
        if (reset) {
            this.addGroupShareVideoPage = 1;
            this.ownerVideos = [];
        }
        this.api.getAdminSharedVideos(10, this.addGroupShareVideoPage, 'recent')
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            const allMainVideo = res.data;
            allMainVideo.forEach(e => {
                if (e.isPublished) {
                    this.ownerVideos.push(e)
                }
            });

        })
    }

    onScrollMyLink(): void {
        ++this.addLinkPage;
        this.getAllMainLink();
    }

    getAllMainLink(reset?) {
        this.spinner = true;
        if (reset) {
            this.addLinkPage = 1;
            this.myLinks = [];
            this.myDocuments = [];
        }

        const object: any = {
            page: this.addLinkPage,
            limit: 10,
            sort: 'recent',
            // type: 'url',
            aiEditorVideo: 'no',
        }

        if(this.linkType === "Links"){
            object.type = 'url'
        }else{
            object.type = "pdf"
        }

        this.api.getLibraryVideos(object)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.spinner = false;
            const allMainLink = res.data;
            allMainLink.forEach(e => {
                if (e.type === 'url') {
                    this.myLinks.push(e)
                }
                if (e.type === 'application/pdf') {
                    this.myDocuments.push(e)
                }
            });
        })
    }

    changeLinksTab(type:any){
        this.linkType = type;
        this.term = '';
        this.getAllMainLink(true);
        this.linkItem = []
    }

    removeBadge(){
        this.selectedBadge = "";
    }

    openBadgeModal() {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            data: {
                badges: true,
                links: false,
                videos: false
            },
            minWidth: '50vw'
        });
        dialogRef.afterClosed().subscribe((result: UploadedFileResponse) => {
            if (result) {
                this.selectedBadge = result;
            }
        });
    }

    onScrollOwnerLink(): void {
        ++this.addOwnerLinkPage;
        this.getAllOwnerMainLink();
    }

    getAllOwnerMainLink(reset?) {
        if (reset) {
            this.addOwnerLinkPage = 1;
            this.ownerLinks = [];
        }
        const object: any = {
            page: this.addOwnerLinkPage,
            limit: 10,
            sort: 'recent',
            type: 'url',
            aiEditorVideo: 'no',
            isGroupContent: true
        }
        this.api.getLibraryVideos(object)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            const allMainLink = res.data;
            allMainLink.forEach(e => {
                if (e.type === 'url') {
                    this.ownerLinks.push(e)
                }
            });

        })
    }

    getCurrentUserInfo() {
        if (this.currentUserId) {
            this.api.getUserInfoV2(this.currentUserId).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                this.currentUserInformation = res.data.userInfo;
                //console.log("this.currentUnserInformation = ", this.currentUserInformation);
            });
        }
    }

    redirectCommunity(userId?:any){
        // console.log("this.currentGroupOwnerId = ", this.currentGroupOwnerId);
        
        let routeURL = this.currentGroupAdminInfo?.business?.communityPathSegment ? this.currentGroupAdminInfo?.business?.communityPathSegment : 'my-community'

        if(this.isEmbed && this.isEmbed === "true"){
            if(this.currentGroupAdminInfo?.business?.communityUrl){
                this.router.navigate([`/${routeURL}/`, this.currentGroupAdminInfo?.business?.communityUrl], {queryParams: { embed : true }});
            }else{
                this.router.navigate([`/${routeURL}`], {queryParams: {user: userId, embed : true }});
            }
        }else{
            if(this.currentGroupAdminInfo?.business?.communityUrl){
                this.router.navigate([`/${routeURL}/`, this.currentGroupAdminInfo?.business?.communityUrl]);
            }else{
                this.router.navigate([`/${routeURL}`], {queryParams: {user: userId}});
            }
        }
    }
    
    fliterGroupNameWithSpecialChar(groupName:any){

        if (groupName)
            return groupName.replace(/&amp;/g, '&');
    }

    filterGroupDetailWithSpecialChat(groupDetail:any){
        this.groupDetail.groupName = groupDetail?.groupName?.replace(/&amp;/g, '&');
        this.groupDetail.description = groupDetail?.description?.replace(/&amp;/g, '&');
        this.groupDetail.pageContentTitle = groupDetail?.pageContentTitle?.replace(/&amp;/g, '&');
        this.groupDetail.pageAdminTitle = groupDetail?.pageAdminTitle?.replace(/&amp;/g, '&');
    }

    getCurrentGroupDetail(id: any) {
        this.ownerFollowersToAdd = [];
        this.followersToAdd = [];
        this.onlyAdminArray = [];
        this.adminCount = 0;
        this.requestCount = 0;
        this.groupMembers.members = [];
        this.groupMembers.admin = [];
        this.groupMembers.subscribers = [];
        this.groupDetail = [];
        this.groupAllVideos = [];
        this.groupAllLinks = [];
        this.spinner = true;
        this.api.getGroupDetail(id).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.groupDetail = res.data[0];  

            if(this.paramsValue?.commentId && this.paramsValue?.topic){
                this.spinner = true;
                const encodedTopic = encodeURIComponent(this.paramsValue?.topic); 
                if (!this.localStorageService.getItem('token')) {

                    if (this.groupDetail.isSubscriptionGroup)
                        this.localStorageService.setItem('redirectURL', '/community/' + this.groupId + '?commentId=' + this.paramsValue?.commentId + '&topic='+ encodedTopic);
                    else
                        this.localStorageService.setItem('redirectURL', '/my-group/' + this.groupId + '?commentId=' + this.paramsValue?.commentId + '&topic='+ encodedTopic);
                    
                    this.router.navigate(['login'], { queryParams: { cid: 'EOFF' + this.groupDetail.admin} });
                }
            }

            //console.log("this.groupDetail = ", this.groupDetail);
            
            this.filterGroupDetailWithSpecialChat(this.groupDetail);
            this.groupDetail.thumbnailSetting = this.groupDetail?.thumbnailSetting ? this.groupDetail?.thumbnailSetting : 'contain'
            this.dashboardService.setGroupDetails = this.groupDetail;

            if(!this.groupDetail?.tabOrders?.length){
                this.contentTabs = this.groupDetail?.userDefinedTabs || [];
                this.defaultEditTabType = 'Videos';
            }
            if(this.groupDetail?.tabOrders?.length){
                this.contentTabs = this.groupDetail?.tabOrders || [];
                this.defaultEditTabType = 'userDefine';
            }
            this.currentGroupOwnerId = this.groupDetail.admin;
            this.isCalledContentInFunction = true;
            this.refreshAllGroupContent()
            
            if (!this.groupDetail) {
                this.router.navigate(['/']);
                return;
            }
           
            // const user = this.localStorageService.getItem('user');
            // const members = this.groupDetail?.members;
            // const isExist = members.some(el => el.memberId === user);

            // WC 020203
            // if (!isExist) {
            //     this.toaster.info('You have to join the group before doing collab');
            // }
            // WC 020203


            // if (this.groupDetail.planId) {
            this.getCurrentGroupPlanInfo();
            this.getCurrentGroupAdminInfo();
            // } else {
            //     this.groupPlanDetails = null;
            // }

            this.getAllGroupComments(true);
            this.adminCount = 0;
            this.isAdmin = false;
            this.isMember = false;
            this.isRequested = false;
            this.buyer.isSubscriber = false;
            const membersExist = this.groupDetail.members;
            let memberInfo = [];
            let subscriberInfo = [];
            membersExist.forEach(item => {
                if (item.accessType === 'ADMIN' && item.memberId === this.currentUserId) {
                    this.currentAccessView = 'Invite';
                    this.isAdmin = true;
                    this.isMember = false;
                    // this.getAllGroupComments(true);
                } else if (item.accessType === 'MEMBER' && item.status === 'Joined' && item.memberId === this.currentUserId) {
                    this.currentAccessView = '';
                    this.isAdmin = false;
                    this.isMember = true;
                    // this.getAllGroupComments(true);
                } else if (item.accessType === 'MEMBER' && item.status === 'Requested' && item.memberId === this.currentUserId) {
                    this.currentAccessView = 'Request Sent';
                    this.isAdmin = false;
                    this.isMember = false;
                    this.isRequested = true;
                } else if (item.accessType === 'SUBSCRIBER' && item.memberId === this.currentUserId) {
                    this.buyer.isSubscriber = true;
                    this.isAdmin = false;
                    this.isMember = false;
                    // this.getAllGroupComments(true);
                }
                if (item.status === 'Requested') {
                    ++this.requestCount;
                }
                if (item.accessType === 'SUBSCRIBER') {
                    this.groupMembers.subscribers.push(item);
                    subscriberInfo.push(item)
                    this.subscriberLength = subscriberInfo?.length
                }
                if (item.accessType === 'ADMIN') {
                    this.groupMembers.admin.push(item);
                    this.groupMembers.subscribers.push(item);
                    this.groupMembers.members.push(item);
                    ++this.adminCount;

                    // Ensure that the groupId is listed first
                    if (item.memberId === this.currentGroupOwnerId) {
                        this.onlyAdminArray.push(item);
                    }
                }
         
                if (item.accessType === 'MEMBER' && item.status === 'Joined') {
                    this.groupMembers.members.push(item);
                    memberInfo.push(item)
                    this.memberlength = memberInfo.length;
                }
            });

            // Get the 2 more admins to be listed on the only Admin Array
            this.groupMembers.admin.forEach(item => {

                if (this.onlyAdminArray.length <= 2) {

                    if (item.memberId !== this.currentGroupOwnerId) {
                        this.onlyAdminArray.push(item);
                    }
                };
            });

            if (this.adminCount >= this.onlyAdminArray.length) {
                this.adminCount = this.adminCount - this.onlyAdminArray.length;
            };

            if (this.buyer.isBuyer) {
                this.getAllGroups(this.currentGroupOwnerId);
            } else {
                this.getAllGroups();
            }

            if (this.isAdmin) {
                // get videos for add content
                this.getAllMainVideo(true);
                this.getAllMainLink(true);
                // get owner videos
                if (this.currentUserId !== this.currentGroupOwnerId) {
                    this.getAllOwnerMainVideo(true);
                    this.getAllOwnerMainLink(true);
                }
                this.getCurrentMember();
            }

            const a = this.groupDetail.groupName as string;
            this.groupName = (this.groupDetail.groupName).trim()
            this.grouNameLength = a.length;

            this.description = (this.groupDetail.description).trim();
            const b = this.groupDetail.description as string;
            if (b) {
                this.descriptionLength = b.length - 3;
            }
            this.isPrivate = this.groupDetail.isPrivate;
            // SC 01042023
            this.isSubscriptionGroup = this.groupDetail.isSubscriptionGroup;
            // WC 01042023

            this.memberLength = this.groupDetail.members.length;

            this.adminTitle = (this.groupDetail.pageAdminTitle).trim();
            const c = this.groupDetail.pageAdminTitle as string;
            this.adminTitleLength = c.length;

            this.courseTitle = (this.groupDetail.pageContentTitle).trim();
            const d = this.groupDetail.pageContentTitle as string;
            this.contentLength = d?.length;

            this.posterThumbnail = this.groupDetail.thumbnail ? this.groupDetail.thumbnail : 'assets/images/noimage.jpg';
            
            this.spinner = false;
            this.isMediaContent = this.groupDetail.videos?.length <= 0;

            this.checkForCollabRequest();
        }, (error) => {
            this.spinner = false;
        });
    }


    checkForCollabRequest() {
        if (this.isAdmin || this.isMember || this.buyer.isSubscriber) {
            const CRGroup = this.localStorageService.getItem('CRGroup');
            const CRComment = this.localStorageService.getItem('CRComment');
            const CRVideo = this.localStorageService.getItem('CRVideo');
            const CRRequester = this.localStorageService.getItem('CRRequester');
            if (this.groupId === CRGroup && CRComment && CRVideo && CRRequester) {
                this.collabRequestData.comment = CRComment;
                this.collabRequestData.video = CRVideo;
                this.collabRequestData.requester = CRRequester;
                this.api.getUserInfoV2(CRRequester).subscribe((res)=>{
                    this.collabRequestData.requesterData  = res.data?.userInfo;
                    this.localStorageService.removeItem('CRGroup');
                    this.localStorageService.removeItem('CRComment');
                    this.localStorageService.removeItem('CRVideo');
                    this.localStorageService.removeItem('CRRequester');
                    this.dialog.open(this.afterMemberDialog, {
                        width: '450px'
                    });
                });
                return;
            }
            if (this.collabRequestData.comment && this.collabRequestData.video) {
                const payload = {
                    id: this.collabRequestData.comment,
                    groupId: this.groupId,
                };
                this.api.getGroupComments(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                    if (res.data && res.data.length) {
                        const item = res.data[0];
                        if (item.type === 'OPEN_COLLAB' && (item._id === this.collabRequestData.comment)) {
                            this.onClickOpenCollab(item, this.collabRequestData.video);
                        }
                    }
                });
            }
        } else {
            // if user is not a member
            if (this.collabRequestData.comment && this.collabRequestData.video && !this.notMemberDialogOpened) {
                this.notMemberDialogOpened = true;
                this.localStorageService.setItem('CRGroup', this.groupId);
                this.localStorageService.setItem('CRComment', this.collabRequestData.comment);
                this.localStorageService.setItem('CRVideo', this.collabRequestData.video);
                this.localStorageService.setItem('CRRequester', this.collabRequestData.requester);
                this.dialog.open(this.notMemberDialog, {
                    width: '450px'
                });
            }
        }
    }

    getCurrentGroupPlanInfo() {

        this.isFreemiumPlan = false;

        this.api.getAllPlans(this.groupDetail.admin).subscribe(res => {
            
            this.groupPlanDetails = res.data.filter(x => x.groupId === this.groupDetail._id);
            
            // WC 02072023 -- To allow rejected Stripe acct to sell Freemium plans
            let noOfFreemium = 0;
            for (const item of this.groupPlanDetails) {
                if (item.price === 0) {
                    noOfFreemium++;

                    if (noOfFreemium === this.groupPlanDetails.length) {
                        this.isFreemiumPlan = true;

                        // console.log("this.isFreemiumPlan = ", this.isFreemiumPlan);
                    }
                }
            }
            // WC 02072023 -- To allow rejected Stripe acct to sell Freemium plans
        });
    }

    getCurrentGroupAdminInfo() {
        this.api.getUserInfoV2(this.currentGroupOwnerId).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.currentGroupAdminInfo = res.data.userInfo;
            //console.log("this.currentGroupAdminInfo", this.currentGroupAdminInfo);         
            if (this.selectedPlanId) {
                this.showPlans();
            }
        });
    }

    requestStatus(item: any, value: any, index: any) {
        let payload = {};
        if (value === 'accept') {
            payload = {
                groupId: this.groupId,
                membersId: item.memberId,
                requestType: 'accept'
            }
        } else {
            payload = {
                groupId: this.groupId,
                membersId: item.memberId,
                requestType: 'reject'
            }
        }
        this.api.acceptRejectRequest(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.requestCount = this.requestCount - 1
            this.toaster.success(value === 'accept' ? 'Added Successfully' : 'Rejected Successfully');
            this.getCurrentGroupDetail(this.groupId)
            this.refreshAllGroupContent();
        })
    }

    gotoVideos(item: any) {
        if (this.buyerButNotSubscriber) {
            this.showPlans();
            return;
        }
        
        let matchTab = item?.userDefinedTab === null ? this.currentContentTab : item?.userDefinedTab
        let isExistsInTabWithBadge = this.groupDetail?.tabsWithBadge?.some((i) => i.name === matchTab);
        let badgeId:any;
        let isDefaultTab = item?.userDefinedTab === null ? true : false

        this.groupDetail?.tabsWithBadge?.map((i)=>{
            if(i.name === matchTab){
                badgeId = i.badgeId
            }
        })
        if(isExistsInTabWithBadge){
            this.router.navigate(['videos'], {queryParams: {video: item.videoId, embed: this.isEmbed, back: this.isEmbed, groupId : item?.groupId, tabName : this.currentContentTab, isDefaultTab : isDefaultTab,badgeId: badgeId}});
        }else{
            this.router.navigate(['videos'], {queryParams: {video: item.videoId, embed: this.isEmbed, back: this.isEmbed}});
        }
    }

    
    // video upload end
    openUploadLinkDialog() {
        $('#add_link').modal('hide')
        const dialogRef = this.dialog.open(UploadLinkDialogComponent, {
        width: "65%",
        minHeight: "400px",
        panelClass: "my-dialog",
        height: "auto",
        data: {
            allVideos: null,
        },
        });

        dialogRef.afterClosed().subscribe((result) => {
        if (result) {
            $('#add_link').modal('show')
            this.getAllMainLink(true);
        }
        });
    }

    onFileChangeEvent(event: any) {
        this.spinner = true;
        let fileList: FileList = event.target.files;    
    
            if(fileList[0]?.type !== 'application/pdf'){
            this.toaster.error("You can upload only PDF file!")
            return
            }
        
            const payloadObj = {
            name: fileList[0].name,
            type: fileList[0]?.type,
            URL: null,
            duration: 0,
            thumbnail: "",
            size: 0,
            originalName: fileList[0].name,
            isAIEditorVideo: false,
            };
        
        
            this.api.createpresignedUrlV2(payloadObj).subscribe((success) =>{
        
            this.api.uploadDocument( success.data.details, fileList[0]).subscribe(() => {
                this.spinner = false
                this.getAllMainLink(true);
            }, (error) => {
                this.toaster.info('Error!', error);
            });
        
            }, (error) => {
            // console.log("error ===> ", error)
            }
            )
        }

    goToLink(link:any){
        if (this.buyerButNotSubscriber) {
            this.showPlans();
            return;
        }
        window.open(link, '_blank');
    }

    // Update cureent item
    UpdateCurrentGroupDetail(id: any) {
        this.isProcessPartial = true;
        this.api.getGroupDetail(id).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.groupDetail = res.data[0];
            this.filterGroupDetailWithSpecialChat(this.groupDetail);
            const a = this.groupDetail.groupName as string;
            this.groupName = (this.groupDetail.groupName).trim()
            this.grouNameLength = a.length;
            this.description = (this.groupDetail.description).trim()
            const b = this.groupDetail.description as string;
            this.descriptionLength = b.length;
            this.courseTitle = (this.groupDetail.pageContentTitle).trim()
            const c = this.groupDetail.pageContentTitle as string
            this.contentLength = c.length
            this.isPrivate = this.groupDetail.isPrivate;
            this.memberLength = this.groupDetail.members.length
            this.isProcessPartial = false;

        })

    }

    showAddMemberModal() {
        $('#add_member').modal('show');
    }


    updateGroupPrivacy(event: any) {
        if (this.isAdmin) {
            this.isPublics = event;
            $('#group_privacy').modal('show');
        }

    }

    getPublicPrivate(event: any) {
        if (event.target.checked) {
            this.isPublics = !this.isPublics;
        } else {
            this.isPublics = !this.isPublics;
        }
        this.editCurrentPrivacy(event.target.checked)
    }

    onSelectFile(event: any) {
        const file = event.target.files[0];
        if (file.size > 10485760) {
            this.toaster.error('File size should be less than 10MB');
            return;
        }
        if (file) {
            const fileType = file.type;
            if (fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png') {
                const img = new Image()
                img.src = URL.createObjectURL(event.target.files[0])
                img.onload = () => {
                    if (img.width < 400 || img.height < 150) {
                        this.toaster.info('Minimum image size should be 400 x 150')
                    } else {
                        const reader = new FileReader();
                        reader.onload = e => this.posterThumbnail = reader.result;
                        reader.readAsDataURL(file);
                        this.selectedFile = event.target.files[0];
                        this.editCurrentPoster(this.selectedFile)
                    }
                }
            } else {
                this.toaster.info('Only jpeg and png files allowed');
            }
        }
    }


    editTitle(item: any) {
        if (!item) {
            return this.toaster.error('group title is required');
        }
        const formData = new FormData()
        formData.append('groupId', this.groupId);
        formData.append('groupName', item);
        formData.append('description', this.groupDetail.description);
        formData.append('isPrivate', this.groupDetail.isPrivate);
        formData.append('pageAdminTitle', this.groupDetail.pageAdminTitle);
        formData.append('pageContentTitle', this.groupDetail.pageContentTitle);
        formData.append('file', null);
        formData.append('customUrl', 'https://freefusetest.be/my-group/');
        this.spinner = true;
        this.api.updateGroup(this.groupId, formData).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.UpdateCurrentGroupDetail(this.groupId)
            this.getAllGroups();
        }).add(() => this.spinner = false);
    }

    editDescription(item: string) {
        if (!item) {
            return this.toaster.error('description is required');
        }
        const formData = new FormData()
        formData.append('groupId', this.groupId);
        formData.append('groupName', this.groupDetail.groupName);
        formData.append('description', item.trim());
        formData.append('isPrivate', this.groupDetail.isPrivate);
        formData.append('pageAdminTitle', this.groupDetail.pageAdminTitle);
        formData.append('pageContentTitle', this.groupDetail.pageContentTitle);
        formData.append('file', null);
        formData.append('customUrl', 'https://freefusetest.be/my-group/');
        this.spinner = true;
        this.api.updateGroup(this.groupId, formData).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.UpdateCurrentGroupDetail(this.groupId)
        }).add(() => this.spinner = false);
    }

    editCurrentPrivacy(event: any) {
        const formData = new FormData()
        formData.append('groupId', this.groupId);
        formData.append('groupName', this.groupDetail.groupName);
        formData.append('description', this.groupDetail.description);
        formData.append('isPrivate', event);
        formData.append('pageAdminTitle', this.groupDetail.pageAdminTitle);
        formData.append('pageContentTitle', this.groupDetail.pageContentTitle);
        formData.append('file', null);
        formData.append('customUrl', 'https://freefusetest.be/my-group/');
        this.spinner = true;
        this.api.updateGroup(this.groupId, formData).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.hideModal()
            this.UpdateCurrentGroupDetail(this.groupId);
        }).add(() => this.spinner = false);
    }

    editCurrentPoster(event: any) {
        const formData = new FormData()
        formData.append('groupId', this.groupId);
        formData.append('groupName', this.groupDetail.groupName);
        formData.append('description', this.groupDetail.description);
        formData.append('isPrivate', this.groupDetail.isPrivate);
        formData.append('pageAdminTitle', this.groupDetail.pageAdminTitle);
        formData.append('pageContentTitle', this.groupDetail.pageContentTitle);
        formData.append('file', event);
        formData.append('customUrl', 'https://freefusetest.be/my-group/');
        this.spinner = true;
        this.api.updateGroup(this.groupId, formData).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.hideModal()
            this.UpdateCurrentGroupDetail(this.groupId);
            this.getAllGroups();
        }).add(() => this.spinner = false);
    }

    editCurrentAdminTitle(event: any) {
        const formData = new FormData()
        formData.append('groupId', this.groupId);
        formData.append('groupName', this.groupDetail.groupName);
        formData.append('description', this.groupDetail.description);
        formData.append('isPrivate', this.groupDetail.isPrivate);
        formData.append('pageAdminTitle', event);
        formData.append('pageContentTitle', this.groupDetail.pageContentTitle);
        formData.append('file', null);
        formData.append('customUrl', 'https://freefusetest.be/my-group/');
        this.spinner = true;
        this.api.updateGroup(this.groupId, formData).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.hideModal()
            this.UpdateCurrentGroupDetail(this.groupId);
        }).add(() => this.spinner = false);
    }

    editCourseContent(event: any) {
        if (!event) {
            return this.toaster.error('content title is required');
        }
        const formData = new FormData()
        formData.append('groupId', this.groupId);
        formData.append('groupName', this.groupDetail.groupName);
        formData.append('description', this.groupDetail.description);
        formData.append('isPrivate', this.groupDetail.isPrivate);
        formData.append('pageAdminTitle', this.groupDetail.pageAdminTitle);
        formData.append('pageContentTitle', event);
        formData.append('file', null);
        formData.append('customUrl', 'https://freefusetest.be/my-group/');
        this.spinner = true;
        this.api.updateGroup(this.groupId, formData).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.hideModal()
            this.UpdateCurrentGroupDetail(this.groupId);
        }).add(() => this.spinner = false);
    }

    hideModal() {
        $('#passwordProtected').modal('hide');
        $('#group_privacy').modal('hide');
        $('#add_video').modal('hide');
        $('#add_link').modal('hide');
        $('#update_comment').modal('hide');
        $('#joinGroupModal').modal('hide');
        setTimeout(() => {
            $('#view_member').modal('hide');
        }, 500);
        $('#add_member').modal('hide');
    }

    onInputCourseContent(event: any) {
        const text = event.target.textContent.trim();
        if (text.length > 100) {
            event.target.textContent = this.courseTitle;
        } else {
            this.courseTitle = text;
        }
    }

    onInputGroupName(event: any) {
        const text = event.target.textContent.trim();
        if (text.length > 100) {
            event.target.textContent = this.groupName;
        } else {
            this.groupName = text;
        }
    }

    onInputDescription(event: any) {
        const text = event.target.textContent.trim();
        if (text.length > 500) {
            event.target.textContent = this.description;
        } else {
            this.description = text;
        }
    }


    selectUserToAdd(item: any) {
        this.followersToAdd.map(e => {
            if (item._id === e._id) {
                e.isSelected = !e.isSelected;
            }
        })
    }

    selectOwnerUserToAdd(item: any) {
        this.ownerFollowersToAdd.map(e => {
            if (item._id === e._id) {
                e.isSelected = !e.isSelected;
            }
        })
    }

    getSelectedItemType(item, allValue) {
        this.followersToAdd.map(e => {
            if (allValue._id === e._id) {
                e.accessType = item.target.value;

                if (!e.isSelected)
                    e.isSelected = true;

                if (e.accessType === 'undefined')
                    e.isSelected = false;
            }
        })
    }

    getOwnerSelectedItemType(item, allValue) {
        this.ownerFollowersToAdd.map(e => {
            if (allValue._id === e._id) {
                e.accessType = item.target.value;

                if (!e.isSelected)
                    e.isSelected = true;

                if (e.accessType === 'undefined')
                    e.isSelected = false;
            }
        })
    }

    addGroupMembers() {
        const payload = {
            groupId: this.groupId,
            members: []
        }

        // console.log("this.allGroupMembers = ", this.allGroupMembers);

        this.followersToAdd.forEach(e => {
            if (e.isSelected) {
                payload.members.push({
                    accessType: e.accessType,
                    memberId: e.user._id
                });
            }
        });
        this.ownerFollowersToAdd.forEach(e => {
            if (e.isSelected) {
                payload.members.push({
                    accessType: e.accessType,
                    memberId: e.user._id
                });
            }
        });
        this.addMemberProcess = true;
        this.api.addMember(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.hideModal()
            this.addMemberProcess = false;
            this.refreshAllGroupContent();
            this.getCurrentGroupDetail(this.groupId)
            this.getCurrentMember();

        }, err => {
            this.addMemberProcess = false;
        })

    }

    removeGroupMember(item: any, index: any) {
        this.deleteDialogRef.open({
            header: 'Remove member',
            title: 'Are you sure you want to remove this member?',
            trueButton: 'Remove'
        }, (e) => {
            if (e) {
                this.groupDetail.members.splice(index, 1)
                this.toaster.success('Member removed successfully !!');
                this.api.removeMember(this.groupId, item.memberId).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                    this.getCurrentMember();
                    this.refreshAllGroupContent();
                    this.getCurrentGroupDetail(this.groupId);
                    this.hideModal()
                })
            }
        });

    }

    updateAccessLevel(item: any) {
        let access = null;
        if (item.accessType === 'ADMIN') {
            access = 'MEMBER'
        } else {
            access = 'ADMIN'
        }
        const payload = {
            groupId: this.groupId,
            members: [
                {
                    accessType: access,
                    memberId: item.memberId
                }
            ]
        }
        this.api.changeAccessLevel(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.getCurrentGroupDetail(this.groupId)
        })
    }

    // addReply(reply: any, id: any) {
    //     this.isMentioning = false;
    //     this.searchUserName = "";
    //     this.searchUser = "";
    //     this.myFollowers = [];
    //     this.isOpenUserMenuForReply = false;

    //     if (this.isDisableComment) {
    //         return; // If already in progress, do nothing
    //     }

    //     this.isDisableComment = true;

    //     if (this.buyerButNotSubscriber && this.status != 'subscribed') {
    //         this.showPlans();
    //         this.isDisableComment = false; 
    //         return;
    //     }

    // // Validation is done at the API level
    // //     //WC 10102024 --- PenTestFix to check for malicious HTML tags or even handlers to prevent XSS attacks
    // //     // Check for disallowed scripts in the comment
    // //     if (this.containsDisallowedScripts(reply)) {
    // //         this.toaster.error('Invalid input detected!');
    // //         this.isDisableComment = false; 
    // //         return;
    // //     }
  
    // //   if (this.containsDisallowedEvents(reply)) {
    // //     this.toaster.error('Invalid input detected!');
    // //     this.isDisableComment = false; 
    // //     return;
    // //   }
    // //   //WC 10102024 --- PenTestFix

        

    //     if (!this.isValidComment(reply)) {
    //         // this.toaster.error("Please enter reply first.");
    //         this.isDisableComment = false; 
    //         return;
    //     }

    //     this.tempCommentId = id;
            
    //     let payload: any = {
    //         groupId: this.groupId,
    //         commentId: id,
    //         comment: reply,
    //         scenerio: 'reply',
    //         mentionUsers:  this.getMentionedUser(reply)
    //     }

    //     if (this.currentTopicValue) {
    //         payload.topic = this.currentTopicValue;
    //     }
    
    //     if (this.updateReplyCommentId && this.updateReplyCommentId !== null) {
    //         payload.updateReplyCommentId = this.updateReplyCommentId;
    //     }
    
    //     // console.log("addComment - payload = ", payload);
        
    
    //     this.api.addComment(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            

    //         if (res.data?.result?.replyOnCommentID && this.updateReplyCommentId){
    //             const datatemp: any = { users: {} }
    //             datatemp.users.profilePic = this.currentUserInformation.profilePic
    //             datatemp.createdAT = res.data?.result.createdAt
    //             datatemp.users.username = this.currentUserInformation.username
    //             datatemp.users._id = this.currentUserInformation._id
    //             datatemp.comment = res.data?.result.comment
    //             datatemp.updatedAt = res.data?.result.updatedAt
    //             datatemp._id = res.data?.result._id
    //             datatemp.groupId = res.data?.result.groupId
    //             datatemp.groupMemberId = res.data?.result.groupMemberId
    //             datatemp.reply = res.data?.result.reply
    //             datatemp.topic = res.data?.result.topic
    //             datatemp.type = res.data?.result.type
    //             datatemp.replyOnCommentID = res.data?.result.replyOnCommentID
                
    //             for (const i of this.allGroupComments) {
                
    //                 if (i._id === res.data?.result?.replyOnCommentID) {                        
    //                     if (i.replies && i.replies?.length){                
    //                         i.replies.push(datatemp)
    //                     }
    //                     else {  
    //                         i.replies = [];
    //                         i.replies.push(datatemp)
    //                     }
    //                 }
    //             }
    //         }else{
    //             this.getAllGroupComments(true);
    //         }
    //         this.comment = "";
    //         this.commentId = "";
    //         this.updateReplyCommentId = "";
    //         this.reply = "";
    //         this.mentionUsers = [];
    //         this.isDisableComment = false; 

    //     }, (error) => {
    //         this.isDisableComment = false;
    //     });
    // }

    addReply(reply: any, id: any) {
        this.isMentioning = false;
        this.searchUserName = "";
        this.searchUser = "";
        this.myFollowers = [];
        this.isOpenUserMenuForReply = false;

        if (this.isDisableComment) {
            return; // If already in progress, do nothing
        }

        this.isDisableComment = true;

        if (this.buyerButNotSubscriber && this.status != 'subscribed') {
            this.showPlans();
            this.isDisableComment = false; 
            return;
        }


    // Validation is done at the API level
    //     //WC 10102024 --- PenTestFix to check for malicious HTML tags or even handlers to prevent XSS attacks
    //     // Check for disallowed scripts in the comment
    //     if (this.containsDisallowedScripts(reply)) {
    //         this.toaster.error('Invalid input detected!');
    //         this.isDisableComment = false; 
    //         return;
    //     }
  
    //   if (this.containsDisallowedEvents(reply)) {
    //     this.toaster.error('Invalid input detected!');
    //     this.isDisableComment = false; 
    //     return;
    //   }
    //   //WC 10102024 --- PenTestFix


        

        if (!this.isValidComment(reply)) {
            // this.toaster.error("Please enter reply first.");
            this.isDisableComment = false; 
            return;
        }

        this.tempCommentId = id;
            
        let payload: any = {
            groupId: this.groupId,
            commentId: id,
            comment: reply,
            scenerio: 'reply',
            mentionUsers:  this.getMentionedUser(reply)
        }

        if (this.currentTopicValue) {
            payload.topic = this.currentTopicValue;
        }
    
        if (this.updateReplyCommentId && this.updateReplyCommentId !== null) {
            payload.updateReplyCommentId = this.updateReplyCommentId;
        }
    
        // console.log("addComment - payload = ", payload);
        
    
        this.api.addComment(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            

            if (res.data?.result?.replyOnCommentID){
                const datatemp: any = { users: {} }
                datatemp.users.profilePic = this.currentUserInformation.profilePic
                datatemp.createdAT = res.data?.result.createdAt
                datatemp.users.username = this.currentUserInformation.username
                datatemp.users.firstname = this.currentUserInformation?.firstname
                datatemp.users.lastname = this.currentUserInformation?.lastname
                datatemp.users._id = this.currentUserInformation._id
                datatemp.comment = res.data?.result.comment
                datatemp.updatedAt = res.data?.result.updatedAt
                datatemp._id = res.data?.result._id
                datatemp.groupId = res.data?.result.groupId
                datatemp.groupMemberId = res.data?.result.groupMemberId
                datatemp.reply = res.data?.result.reply
                datatemp.topic = res.data?.result.topic
                datatemp.type = res.data?.result.type
                datatemp.replyOnCommentID = res.data?.result.replyOnCommentID
                
                for (const i of this.allGroupComments) {
                    if (i._id === res.data?.result?.replyOnCommentID) {
                        // Check if we are updating an existing reply
                        const replyIndex = i.replies?.findIndex(reply => reply._id === this.updateReplyCommentId);
                        if (replyIndex !== -1) {
                            // Update existing reply
                            i.replies[replyIndex] = datatemp;
                        } else {
                            // Push new reply if it doesn't exist
                            if (i.replies && i.replies.length) {
                                i.replies.push(datatemp);
                            } else {
                                i.replies = [datatemp];
                            }
                        }
                    }
                }

            }else{
                this.getAllGroupComments(true);
            }
            this.comment = "";
            this.commentId = "";
            this.updateComments = "";
            this.updateReplyCommentId = "";
            this.reply = "";
            this.mentionUsers = [];
            this.isDisableComment = false; 

        }, (error) => {
            this.isDisableComment = false;
        });
    }

    handleOffcanvasClose(){
        const offcanvasElement = this.offcanvasRight.nativeElement;

            // if (offcanvasElement.classList.contains('hiding')) {
                document.body.style.overflow = 'auto'; // or any default color
            // } 
    }


    viewAllReply(id: any) {
        this.selectedCommentTypeForMentionUser = 'reply'
        this.isOpenUserMenuForReply = false;

        this.allReply = null;
        const modalElement = this.offcanvasRight.nativeElement;
        
        if (modalElement) {
            modalElement.classList.add('show');
            // modalElement.style.display = 'block';
            if(this.paramsValue?.commentId){
                    setTimeout(() => {
                        window.scrollTo(250,1200);
                    },0)
            }
        }else{

        }
        
        if(this.paramsValue?.commentId){
            if (!this.localStorageService.getItem('token') && window.location.pathname.includes('/my-group/') ) {
                
            }else{
                this.removeQueryParamsFromURL()
            }
        }
        
        this.allReply = this.paramsValue?.commentId ? this.paramsValue?.commentId : id
        // this.allReply = id;
        this.replyId = null;
    }

    removeQueryParamsFromURL(){
        const urlTree = this.router.parseUrl(this.router.url);
        const queryParams = urlTree.queryParams;

        // If query parameters exist, remove them and navigate to the same route
        if (queryParams.commentId || queryParams.topic) {
        const urlWithoutParams = this.router.createUrlTree([], { queryParams: {} }).toString();
        this.router.navigateByUrl(urlWithoutParams);
        }
    }

    getLastReplyTime(value){
        return value[value?.length - 1 ]?.updatedAt
    }

    viewReply(id: any) {
        if (this.buyerButNotSubscriber) {
            this.showPlans();
            return;
        }
        this.allReply = null;
        if (this.replyId === id) {
            this.replyId = null;
        } else {
            this.replyId = id;
        }
    }


    likeDislikeComment(id: any, item: any, value: any) {
        if (this.buyerButNotSubscriber) {
            this.showPlans();
            return;
        }
        this.likeId = id;

        let scenario = item.some((i: any) => i.userId === this.currentUserId);
        let category;
       
        const finalpayload = {
            ID: this.groupId,
            category: scenario ? 0 : 1,
            commentID: id,
            scenario: scenario ? "unlike" : "like",
            type: 'comment'
        }

        this.api.likeDislikeComment(finalpayload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.countLikeDisLike = 0;
            // this.getAllGroupComments(true);
            this.allGroupComments.forEach((e) => {
                const userHasLiked = e.likebyuserInfo.some((user: any) => user.userId === this.currentUserId); // Check if the current user has liked
                const isLiked = userHasLiked;

                if (e._id === id) {
                    if (isLiked === false) {
                        // If the user hasn't liked, add the like
                        e.likeCount += 1;
                        e.likebyuserInfo.push({
                            userId: this.currentUserId,
                        });
                    } else {
                        // If the user has liked, remove the like
                        e.likeCount -= 1;
                        e.likebyuserInfo = e.likebyuserInfo.filter((user: any) => user.userId !== this.currentUserId);
                    }
                }
            });

        })
    }

    get isPendingAttachments() {
        return this.uploadFiles.filter(x => !x.URL).map(x => x.file);
    }

    async onSelectAttachments(event) {
        const files = event.target.files;
        if (files && files.length) {
            for (const file of files) {
                const cleanImage = await sanitizeSVG(file)
                if (!cleanImage) {
                    this.toaster.error('invalid SVG file');
                } else {
                    if (file.size > 10485760) {
                        this.toaster.error('File size should be less than 10MB');
                        continue;
                    }
                    if (file.type.indexOf('image') > -1 || file.type.indexOf('pdf') > -1) {
                        this.uploadFiles.push({file: cleanImage});
                    } else {
                        this.toaster.error('Only pdf and image files are allowed!');
                    }
                }
            }
            this.uploadAttachments();
        }
        event.target.value = '';
    }

    uploadAttachments() {
        const newFiles = this.isPendingAttachments;
        if (newFiles.length) {
            const formData = new FormData();

            formData.append('groupId', this.groupId);

            for (const newFile of newFiles) {
                formData.append('file', newFile, newFile.name);
            }
            //console.log("formData = ", formData);
            this.api.uploadAttachments(formData).subscribe((res) => {
                const data = res.data.attachmentUrls || [];
                this.uploadFiles.filter(x => !x.URL).map(x => {
                    if (data.length) {
                        x.URL = data.splice(0, 1)[0];
                    }
                    return x;
                });
                // check is any upload pending
                if (this.isPendingAttachments.length) {
                    this.uploadAttachments();
                }
            }, (error) => {
                this.toaster.error(error?.error?.data);
                this.uploadFiles = [];
            });
        }
    }

    removeUploadFile(i) {
        const file = this.uploadFiles[i]?.URL;
        if (file) {
            this.api.deleteUploadedAttachments([file]).subscribe((res) => {
                this.uploadFiles.splice(i, 1);
            });
        } else {
            this.uploadFiles.splice(i, 1);
        }
    }

    onClickAddVideo() {
        const dialogRef = this.dialog.open(VideoCommentTypeDialogComponent, {
            width: '600px',
            panelClass: 'add-video-dialog',
            id: 'add-video-dialog',
            minHeight: 'calc(40vh - 160px)',
            height: 'auto',
        });

        dialogRef.afterClosed().subscribe((type) => {
            if (type) {
                this.onClickUpload(type);
            }
        });
    }

    onClickUpload(type: string) {
        const dialog = this.dialog.openDialogs.find((i) => i.id === 'add-video-dialog');
        dialog?.close();
        if (type === 'single') {
            this.onSelectSingleVideo();
        } else {
            this.onClickOpenCollab();
        }
    }

    onSelectSingleVideo() {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            data: {
                allVideos: null,
                links: false,
                isAddChoice: true
            },
            minWidth: '50vw'
          });
          dialogRef.afterClosed().subscribe((result: UploadedFileResponse) => {
            if (result) {
                this.addComment('video', 'VIDEO', result._id);
            }
        });
    }

    onClickOpenCollab(item?: any, requestVideoId?: string) {
        const video: BookmarkVideoResponse = item?.videoInfo;
        const videoId = item?.videoId;
        if (!video) {
            if (this.buyerButNotSubscriber) {
                this.showPlans();
                return;
            }
            // const obj = {templateId: 2};
            // this.subscription = this.api.bookmarkTemplateVideos(obj).subscribe((success) => {
            //     const payload = {
            //         groupId: this.groupId,
            //         comment: 'video caption',
            //         scenerio: 'comment',
            //         type: 'OPEN_COLLAB',
            //         videoId
            //     }
            //     this.api.addComment(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
            // Update later on close collab modal
            // this.addComment('video caption', 'OPEN_COLLAB', result.data.videoinfo._id);
            const data:any = {
                create: true,
                comment: null,
                videoId,
                groupMembers: this.groupDetail.members,
                commentId: null,
                groupId: this.groupId,
                currentUserInformation: this.currentUserInformation,
                requestVideoId
            };
            if (this.currentTopicValue) {
                data.topic = this.currentTopicValue;
            }
            this.openCollabModal(data);
            //     });
            // })
        } else {
            const data:any = {
                create: false,
                comment: item,
                videoId,
                groupMembers: this.groupDetail.members,
                commentId: item._id,
                groupId: this.groupId,
                currentUserInformation: this.currentUserInformation,
                requestVideoId,
                childVideoId: this.childCommentId ? this.childCommentId : videoId
            };

            if (this.currentTopicValue) {
                data.topic = this.currentTopicValue;
            }
            this.openCollabModal(data);
        }
    }

    openCollabModal(data: any) {
        const dialogRef = this.dialog.open(OpenCollabComponent, {
            width: '72%',
            panelClass: 'my-dialog',
            height: 'auto',
            minHeight: '15vw',
            data,
        });
        dialogRef.afterClosed().subscribe(result => {
            // console.log('onClickOpenCollab', result);
            // if (result) {
            this.getAllGroupComments(true);
            // }
        });
    }

    isValidComment(comment: string): boolean {
        // console.log('isValidComment: ', comment);
        

        // Ensure comment is a string and not null or undefined
        if (typeof comment !== 'string') {
            return false; // or handle as appropriate
        }

        // Remove HTML tags from the comment using a regular expression
        const commentText = comment ? comment?.replace(/<\/?[^>]+(>|$)/g, "") : '';

        const hasImgTag = comment && comment.indexOf('<img') !== -1;

        // Check if the remaining text consists only of whitespace
        return commentText.trim() !== '' || hasImgTag;
    }


    getMentionedUser = (text: any) => {

        // Regular expression to match the user values in the URLs
        const userRegex = /user=([a-z0-9]+)/g;
        let users: any[] = [];
        let match;

        // Extract all matches and push the user values to the array
        while ((match = userRegex.exec(text)) !== null) {
            users.push(match[1]);
        }

        return users;
    };


    containsDisallowedScripts(comment: string): boolean {

        // Build a regex pattern to match any of the tags in the list
        const pattern = new RegExp(`<\\/?(${environment.maliciousHtmlTags.join('|')})(\\s|>)`, 'i');
    
        // Test the input string for any matches
        return pattern.test(comment);
    };
    
    containsDisallowedEvents(comment: string): boolean {
    
        // Build a regex pattern to match any of the tags in the list
        const pattern = new RegExp(`\\b(${environment.maliciousEventHandlers.join('|')})\\b=`, 'i');
    
        // Test the input string for any matches
        return pattern.test(comment);
    };
    

    addComment(comment: any, type: string, videoId?: string) {

       
        this.searchUserName = "";
        this.isMentioning = false;
        this.searchUser = "";
        this.myFollowers = [];
        this.isOpenUserMenu = false;
        this.isOpenUserMenuForUpdate = false;

        if (this.isDisableComment) {
            return; // If already in progress, do nothing
        }
        
        this.isDisableComment = true;

        if (this.buyerButNotSubscriber && this.status != 'subscribed') {
            this.showPlans();
            this.isDisableComment = false; 
            return;
        }
        if (!this.isValidComment(comment)) {
            // this.toaster.error('Please enter comment first.');
            this.isDisableComment = false; 
            return;
        }

        //WC 10232024 --- Validation is done at the API level
        // //WC 10102024 --- PenTestFix to check for malicious HTML tags or even handlers to prevent XSS attacks
        // // Check for disallowed scripts in the comment
        // if (this.containsDisallowedScripts(comment)) {
        //     this.toaster.error('Invalid input detected!');
        //     this.isDisableComment = false; 
        //     return;
        // }
    
        // if (this.containsDisallowedEvents(comment)) {
        //     this.toaster.error('Invalid input detected!');
        //     this.isDisableComment = false; 
        //     return;
        // }
        // //WC 10102024 --- PenTestFix


        if (this.isPendingAttachments.length) {
            this.toaster.error('Please wait while the attachments are uploaded.');
            return;
        }

        let planComment = this.removeCommentTag(comment);

        // this.mentionUsers.map((i:any) =>{
        //     if(!planComment.includes(i.name)){
        //         this.mentionUsers = this.mentionUsers.filter(j => j.name !== i.name);
        //     }
        // })   
        
        // this.mentionUsers = this.mentionUsers.filter(user => user.id);

        // // Step 2: Ensure uniqueness based on the 'name' property
        // this.mentionUsers = this.mentionUsers.filter((user, index, self) =>
        //     index === self.findIndex((t) => t.name === user.name)
        // );

        const payload: any = {
            groupId: this.groupId,
            comment,
            scenerio: 'comment',
            type: this.UpdatedCommentVideoType ? this.UpdatedCommentVideoType : type ,
            videoId
        }
        if (this.commentId) {
            // update comment
            payload.commentId = this.commentId;
        }

        if (this.currentTopicValue) {
            payload.topic = this.currentTopicValue;
        }

        const attachments = [];
        for (const uploadFile of this.uploadFiles) {
            attachments.push(this.firstUrl(uploadFile.URL));
        }
        
        if (attachments.length) {
            payload.attachments = attachments;
        }
        
        payload.mentionUsers =  this.getMentionedUser(comment);
        
        // console.log("addComment - payload = ", payload);

        this.api.addComment(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {

            this.getAllGroupComments(true);
            this.hideModal();
            this.UpdatedCommentVideoType = "";
            this.comment = null;
            this.commentId = null;
            this.uploadFiles = [];
            this.isDisableComment = false; 
            this.mentionUsers = [];

        },
        (error) => {
            this.isDisableComment = false;
        });

    }

    onScrollComment() {
        ++this.currentCommentPage;
        this.getAllGroupComments();
    }

    getAllGroupComments(reset = false) {
        if (!this.groupId) {
            return;
        }
        if (reset) {
            this.spinner = true;
            this.currentCommentPage = 1;
            this.allGroupComments = [];
        }
        let payload:any = {
            groupId: this.groupId,
            page: this.currentCommentPage,
            topic: this.currentTopicValue,
            limit: 10
        };

        if (this.isPinnedComments) {
            payload.isPinned = this.isPinnedComments
        }
        this.api.getGroupComments(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            // this.allGroupComments = []
            // this.allGroupComments = res.data;
            this.spinner = false;
            let newComment = (res.data && res.data.length) ? res.data : [];
            newComment = newComment.map(x => {
                // x.commentHtml = this.utilityService.linkify(x.comment);
                x.commentHtml = x.comment;
                x.replies = x.replies.map(replyObj => ({
                    ...replyObj,
                    ...(!replyObj.users ? {users: replyObj.userInfo} : {}),
                    ...(replyObj.replyInfo ? replyObj.replyInfo : {})
                }))
                return x;
            });
            // newComment.forEach((element, index) => {
                this.allGroupComments.push(...newComment)

                // if(this.paramsValue?.commentId){
                //     const decodedTopic = decodeURIComponent(this.paramsValue.topic || '');
                //     this.currentTopicValue = decodedTopic;
                //     setTimeout(() => { 
                //         this.commentReply.nativeElement.click();
                //     }, 0);
                // }

                let commentFound = false;
                if (this.paramsValue.commentId) {
                    this.spinner = true;
                    for (const comment of newComment) {
                        if (comment._id === this.paramsValue.commentId) {
                        commentFound = true;
                        setTimeout(() => {
                            this.spinner = false;
                            this.commentReply.nativeElement.click();
                        }, 0);
                        break; // Exit loop since comment is found
                        }else{
                            this.currentCommentPage++; // Increment page number
                            this.getAllGroupComments(false); // Fetch next page
                        }
                    }
                }

                
                // if (element.type === 'VIDEO') {
                //     this.subscription = this.api.getSingleUploadedVideo(element.videoId).subscribe((res1) => {
                //         this.allGroupComments[index].videoInfo = res1.data;
                //     });
                // } else if (element.type === 'OPEN_COLLAB') {
                //     this.subscription = this.api.getVideoInfov2(element.videoId).subscribe((res2) => {
                //         this.allGroupComments[index].videoInfo = res2.data;
                //     }, (error) => {
                //         console.log(error);
                //     });
                // }
            // });
            // if (this.collabRequestData.comment && this.collabRequestData.video) {
            //     for (const item of newComment) {
            //         if (item.type === 'OPEN_COLLAB' && (item._id === this.collabRequestData.comment)) {
            //             setTimeout(() => {
            //                 this.onClickOpenCollab(item, this.collabRequestData.video);
            //             }, 1000);
            //         }
            //     }
            // }
            this.likeClicked = !this.likeClicked;
        }, (error) => {
            this.toaster.error(error.message);
        })
    }

    openVideoPlayerModel(video: UploadedFileResponse) {
        const options = {
            autoplay: true,
            poster: video.poster,
            controls: true,
            sources: [{src: video.URL, type: 'application/x-mpegURL'}]
        };
        const dialogRef = this.dialog.open(ModalVideoPlayerComponent, {
            width: '100%',
            minHeight: 'calc(64vh - 160px)',
            maxWidth: '40vw',
            panelClass: 'video-tree-dialog',
            height: '48vh',
            data: {options, video},
        });

        dialogRef.afterClosed().subscribe(() => {
        });
    }

    updateComment(item: any) {
        
        this.selectedCommentTypeForMentionUser = 'updateComment'
        this.isOpenUserMenuForUpdate = false;

        if (this.buyerButNotSubscriber) {
            this.showPlans();
            return;
        }
        // this.updateComments = item.comment;
        this.updateComments = item.comment || ''; // Fallback to an empty string if null or undefined

        this.previousMessage = this.updateComments;

        this.commentId = item._id;

        if(item.type === "VIDEO"){
            this.UpdatedCommentVideoType = item.type
          }
          if(item.type === "OPEN_COLLAB"){
            this.UpdatedCommentVideoType = item.type
          }

        $('#update_comment').modal('show');

    }

    updateReplyCommentId: any;

    // updateReplyComment(item: any) {
    //     // this.handleOffcanvasClose();
    //     console.log('item: ', item);
    //     // this.closeButton.nativeElement.click();
    //     this.selectedCommentTypeForMentionUser = 'updateReplyComment'
    //     // this.isOpenUserMenuForUpdate = false;

    //     if (this.buyerButNotSubscriber) {
    //         this.showPlans();
    //         return;
    //     }
    //     this.updateReplyComments = item.comment;
    //     this.previousMessage = this.updateReplyComments;

    //     this.commentId = item._id;

    //     if (item.type === "VIDEO") {
    //         this.UpdatedCommentVideoType = item.type
    //     }
    //     if (item.type === "OPEN_COLLAB") {
    //         this.UpdatedCommentVideoType = item.type
    //     }

    //     $('#update_reply_comment').modal('show');
    // }

    updateReplyComment(item: any) {
        this.selectedCommentTypeForMentionUser = 'updateComment'
        this.isOpenUserMenuForUpdate = false;

        if (this.buyerButNotSubscriber) {
            this.showPlans();
            return;
        }
        this.reply = item.comment;
        this.previousMessage = this.reply;

        this.commentId = item.replyOnCommentID;
        this.updateReplyCommentId = item._id;

        if (item.type === "VIDEO") {
            this.UpdatedCommentVideoType = item.type
        }
        if (item.type === "OPEN_COLLAB") {
            this.UpdatedCommentVideoType = item.type
        }

        // // Delay modal opening
        // $('#update_comment_reply').modal('hide'); // Ensure it's hidden first

        // setTimeout(() => {
        //     $('#update_comment_reply').modal('show');
        // }, 0); // A small timeout to ensure bindings are updated
    }



    confirmDeleteComment(item: any) {
        if (this.buyerButNotSubscriber) {
            this.showPlans();
            return;
        }
        this.deleteDialogRef.open({
            header: 'Delete comment',
            title: 'Are you sure you want to delete this comment?'
        }, (e) => {
            if (e) {
                this.api.deleteComment(item._id).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                    this.toaster.success('Comment deleted successfully');
                    if(item.replyOnCommentID){
                        for (const i of this.allGroupComments) {
                            
                            if (i._id === item?.replyOnCommentID) {                        
                                if (i.replies && i.replies?.length){  
                                    i.replies = i.replies.filter((i:any) => i.comment !== item.comment)
                                }
                            }
                        }
                    }else{
                        this.getAllGroupComments(true);
                    }
                });
            }
        });
    }

    addVideo() {
        if (this.isAdmin) {
            if(!this.groupDetail?.tabOrders?.length){
                if(this.currentContentTab === this.getVideosTabName()){
                    this.defaultEditTabType = "Videos"
                }else if(this.currentContentTab === this.getLinksTabName()){
                    this.defaultEditTabType = "Links"
                }else{
                    this.defaultEditTabType = 'userDefine'
                }
            }

            $('#add_video').modal('show');
        }
    }

    addLink() {
        if (this.isAdmin) {
            $('#add_link').modal('show');
        }
    }

    getVideoItem(item) {
        const index = this.videoItem.indexOf(item._id);
        if (index === -1) {
            this.videoItem.push(item._id)
        } else {
            this.videoItem.splice(index, 1)
        }

    }

    getLinkItem(item) {
        const index = this.linkItem.indexOf(item._id);
        if (index === -1) {
            this.linkItem.push(item._id)
        } else {
            this.linkItem.splice(index, 1)
        }

    }

    saveVideoItem() {
        const payload: any = {
            groupId: this.groupId,
            videoId: this.videoItem
        }
        
        if (!this.isFixedCurrentContentTab && this.defaultEditTabType && this.defaultEditTabType === 'userDefine' ) {
            payload.tabName = this.currentContentTab;
        }
        
        this.isProcessPartial = true;
        this.api.saveVideoContent(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            // this.isClicked = [];
        
            this.getGroupDetail();
            if(!this.groupDetail?.tabOrders?.length){
                this.contentTabs = this.groupDetail?.userDefinedTabs || [];                
            }
            if(this.groupDetail?.tabOrders?.length){
                this.contentTabs = this.groupDetail?.tabOrders || [];
            }

        this.api.getGroupContent(this.groupId)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
                this.groupAllVideos = success.data;
                    
                // this.contentTabs = this.groupDetail?.userDefinedTabs || [];
        this.api.getGroupTabsContentSize(this.groupId)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
            this.groupTabsContentSize = success.data;       
            this.videoItem = [];
            this.isProcessPartial = false;
            this.toaster.success('Video added successfully !!')
            this.hideModal();
            // this.getGroupBasic(this.groupId);
            this.isCalledContentInFunction = false;
            this.getGroupDetail();
            this.getGroupContentByTabName(this.currentContentTab);
            this.refreshAllGroupContent()
        })
        }).add(() => this.contentSpinner = false);

        }, err => {
            if (err) {
                this.isProcessPartial = false;
            }
        })
    }

    saveLinkItem() {
        const payload: any = {
            groupId: this.groupId,
            linkId: this.linkItem
        }
        if (!this.isFixedCurrentContentTab) {
            payload.tabName = this.currentContentTab;
        }
        this.isProcessPartial = true;
        this.api.addGroupLinkContent(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            
            this.api.getGroupLinkContent(this.groupId)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
            this.groupAllLinks = success.data;
            this.isLinkMediaContent = !!this.groupAllLinks?.length;
            this.linkItem = [];
            this.isProcessPartial = false;
            this.isCalledContentInFunction = false;
            this.toaster.success('Link added successfully !!')
            this.hideModal();
            this.refreshAllGroupContent()
        }).add(() => this.contentSpinner = false);
        
        }, err => {
            if (err) {
                this.isProcessPartial = false;
            }
        })
    }

    // getGroupBasic(id: any) {
    //     this.api.getGroupDetail(id).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
    //         this.allVideos = res.data[0].videos
    //     })
    // }

    // seeMore() {
    //     this.viewAllContent = !this.viewAllContent
    //     this.seeMoreItem = !this.seeMoreItem;
    //     if (this.viewAllContent) {
    //         this.groupVideLength = this.groupAllVideos.length;
    //     } else {
    //         this.groupVideLength = 6;
    //     }
    // }

    // For get data again to show :
    refreshAllGroupContent() {
        // if (this.isFixedCurrentContentTab) {
        //     this.contentSpinner = true;
        //     this.api.getGroupContent(this.groupId)
        //         .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
        //         this.groupAllVideos = success.data;                
        //         this.isMediaContent = !!this.groupAllVideos?.length;
        //     }).add(() => this.contentSpinner = false);

        //     this.api.getGroupLinkContent(this.groupId)
        //         .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
        //         this.groupAllLinks = success.data;
        //         this.isLinkMediaContent = !!this.groupAllLinks?.length;
        //     }).add(() => this.contentSpinner = false);
        // } else {
        //     this.getGroupContentByTabName(this.currentContentTab);
        // }

        // =============================
        if(!this.isMoveContent){
            this.isMediaContent = !!this.groupAllVideos?.length;
            this.isLinkMediaContent = !!this.groupAllLinks?.length;
        }

        if(this.isCalledContentInFunction){            
            if(this.groupDetail?.tabOrders?.length){
                this.currentContentTab = this.groupDetail?.tabOrders[0].name
                this.defaultEditTabType = this.groupDetail?.tabOrders[0].type 
                this.getGroupContentByTabName(this.currentContentTab);
                        }else{
                this.currentContentTab = this.getVideosTabName() ? this.getVideosTabName() : this.getLinksTabName() ? this.getLinksTabName() :  this.groupDetail?.userDefinedTabs[0];
                this.getGroupContentByTabName(this.currentContentTab);
            }
        }
        
        
        if ((this.defaultEditTabType !== 'userDefine' && this.defaultEditTabType !== undefined) || this.groupDetail?.tabOrders?.length) {
        // if ((this.defaultEditTabType !== 'userDefine' && this.defaultEditTabType === undefined)) {
                
            this.contentSpinner = true;
            this.api.getGroupContent(this.groupId)
                .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
                this.groupAllVideos = success.data;  
                this.isMediaContent = !!this.groupAllVideos?.length;
            }).add(() => this.contentSpinner = false);

            this.api.getGroupLinkContent(this.groupId)
                .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
                this.groupAllLinks = success.data;
                this.isLinkMediaContent = !!this.groupAllLinks?.length;
            }).add(() => this.contentSpinner = false);


            this.api.getGroupContent(this.groupId, this.currentContentTab).subscribe((res) => {
                this.groupActiveTabContent = res.data;
            
                const activeTab = this.groupAllTabsContent.find(x => x.name === this.currentContentTab);
                
                if (activeTab) {
                    activeTab.data = res.data                
                } else {
                    this.groupAllTabsContent.push({
                        name: this.currentContentTab,
                        data: res.data
                    });     
                    
                }
    
            })
        } else {            
            this.getGroupContentByTabName(this.currentContentTab);
        }

        //WC 02212024
        this.api.getGroupTabsContentSize(this.groupId)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
            this.groupTabsContentSize = success.data;       
        }).add(() => this.contentSpinner = false);

        //WC 02212024
    }

    getCountOfVideos(name:any){
        let count;
        this.groupTabsContentSize?.map((i:any) =>{
            
            if(name === i?.tabName){
                count = i?.tabContentCount
            }
        })
        
        return count;
    }

    getGroupContentByTabName(tab) {
        
        this.contentSpinner = true;
        this.api.getGroupContent(this.groupId, tab)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
            this.groupActiveTabContent = success.data;
            
            const activeTab = this.groupAllTabsContent.find(x => x.name === tab);
            
            if (activeTab) {
                activeTab.data = success.data                
            } else {
                this.groupAllTabsContent.push({
                    name: tab,
                    data: success.data
                });     
                
            }

        }).add(() => this.contentSpinner = false);

            return this.groupAllTabsContent
    }

    isMoveContent:boolean = false;
    // Delete Group content
    moveGroupContent(item: any) {
        // this.tabName = '';
        this.currentMoveItem = item;
        if(!this.groupDetail?.tabOrders?.length){
            if(this.currentMoveItem?.userDefinedTab === null){
                this.tabName =  this.groupDetail?.userDefinedTabs[0];
            }else{
                this.tabName = this.getVideosTabName()
                this.isMediaContent = !!this.groupAllVideos?.length;
            }
        }

        
        if(this.groupDetail?.tabOrders?.length){
            if(this.currentContentTab === this.groupDetail?.tabOrders[0].name){
                if(this.groupDetail?.tabOrders[1].type === "Links"){
                    this.tabName = this.groupDetail?.tabOrders[2].name;
                }else{
                    this.tabName = this.groupDetail?.tabOrders[1].name;
                }

            }else{
                this.tabName = this.groupDetail?.tabOrders[0].name;
            }
        }
        
        this.getGroupDetail();
        if(!this.groupDetail?.tabOrders?.length){
            this.contentTabs = this.groupDetail?.userDefinedTabs || [];
        }
        if(this.groupDetail?.tabOrders?.length){
            this.contentTabs = this.groupDetail?.tabOrders || [];
        }
        
        const dialogRef = this.dialog.open(this.moveToTabDialog, {
            width: '500px',
            maxWidth: '90%',
            panelClass: 'my-dialog',
            height: 'auto'
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.spinner = true;
            this.isMoveContent = true;
            if (result) {
                const payload: any = {
                    id: item._id,
                    newTabName: this.tabName
                }

                if(!this.groupDetail?.tabOrders?.length){
                    if(this.tabName === this.getVideosTabName()){
                        payload.newTabName = null
                    }
                }
                if(this.groupDetail?.tabOrders?.length){
                    this.groupDetail?.tabOrders?.map((i:any)=>{
                        if(i.name === this.tabName){
                            if(i.type === "Videos"){
                                payload.newTabName = null
                            }else{
                                payload.newTabName = this.tabName
                            }
                        }
                    })
                }

                this.api.moveGroupContent(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                    this.api.getGroupTabsContentSize(this.groupId)
                    .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
                        this.spinner = false;
                        this.groupTabsContentSize = success.data;
                        this.currentContentTab = this.tabName;

                        
                    // if (this.tabName === this.getVideosTabName()) {     
                    //     console.log("if");
                    //     this.markVideoToRefresh = true;
                    //     this.api.getGroupContent(this.groupId)
                    //     .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
                    //     this.groupAllVideos = success.data;  
                    //     })
                    //     // this.getGroupContentByTabName(this.currentContentTab);
                    // }

                    if(!this.groupDetail?.tabOrders?.length){
                        
                        if(this.tabName === this.getVideosTabName()){
                            this.isMediaContent = true
                            this.onChangeContentTab(this.tabName, 'Videos')
                        }else{                            
                            this.onChangeContentTab(this.tabName, 'userDefine')
                        }

                        this.api.getGroupContent(this.groupId).subscribe((res) =>{
                            this.groupAllVideos = res.data;
                        });

                        
                    }

                    // console.log("000000000000");

                    // this.getGroupContentByTabName(this.currentContentTab);

                    this.tabName = '';
                    this.groupAllTabsContent = [];
                    this.groupActiveTabContent = [];
                    this.toaster.success('Moved successfully')
                    this.isCalledContentInFunction = false;
                    this.refreshAllGroupContent();
                    this.isMoveContent = false;
                    })
                });
            } else {
                this.tabName = '';
                this.spinner = false;
            }
        });
    }

    // Delete Group content
    deleteGroupContent(item: any, index: any) {
        this.deleteDialogRef.open({
            header: 'Delete content',
            title: 'Are you sure? Do you want to delete this content?',
            trueButton: 'Delete'
        }, (e) => {
            if (e) {
                this.api.deleteGroupContent(this.groupId, item._id).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                    this.api.getGroupTabsContentSize(this.groupId)
                    .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
                    this.groupTabsContentSize = success.data;
                    this.groupAllVideos.splice(index, 1)
                    this.toaster.success('Deleted successfully')
                    this.refreshAllGroupContent();
                    })
                  
                });
            }
        });
    }

    // Delete Group Link content
    deleteGroupLinkContent(event:any, item: any, index: any) {
        event.preventDefault();
        this.deleteDialogRef.open({
            header: 'Delete content',
            title: 'Are you sure? Do you want to delete this content?',
            trueButton: 'Delete'
        }, (e) => {
            if (e) {
                this.api.deleteGroupLinkContent(this.groupId, item._id).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                    this.groupAllLinks.splice(index, 1)
                    this.toaster.success('Deleted successfully')
                    this.refreshAllGroupContent();
                });
            }
        });

         // Stop event propagation to prevent redirection
        event.stopPropagation();
    }

    isDescriptionEditFunction(item: any) {
        if (item === 'description') {
            this.isDescriptionEdit = true;
        } else if (item === 'title') {
            this.isTitleEdit = true;
        } else if (item === 'adminTitle') {
            this.isAdminTitleEdit = true;
        } else if (item === 'poster') {
            this.isPosterEdit = true;
        } else if (item === 'courseTitle') {
            this.isCourseTitle = true;
        }
    }

    isDescriptionEditLeave(item: any) {
        if (item === 'description') {
            this.isDescriptionEdit = false;
        } else if (item === 'title') {
            this.isTitleEdit = false;
        } else if (item === 'adminTitle') {
            this.isAdminTitleEdit = false;
        } else if (item === 'poster') {
            this.isPosterEdit = false;
        } else if (item === 'courseTitle') {
            this.isCourseTitle = false;
        }
    }


    openMembers(type: 'MEMBER' | 'SUBSCRIBER' | 'ADMIN') {
        this.groupMembers.active = type;
        if (!this.buyerButNotSubscriber) {
            $('#view_member').modal('show');
        }
    }

    // Show user profile
    showUser(userId: any) {
        this.router.navigate(['public-profile'], {queryParams: {user: userId, embed: this.isEmbed}});
    }

    goToGroupCompletionRate() {
        if (this.buyerButNotSubscriber) {
            this.showPlans();
            return;
        }
        this.router.navigate(['/group-completion/' + this.groupId], {queryParams: {embed: this.isEmbed}});
    }

    goToGroupPlaylist() {
        if (this.buyerButNotSubscriber) {
            this.showPlans();
            return;
        }
        this.router.navigate(['/playlist'], {queryParams: {groupId: this.groupId, embed: this.isEmbed}});
    }

    invite() {
            
        const dialogRef = this.dialog.open(ShareVideoDialogComponent, {
            width: '100%',
            maxWidth: '600px',
            minHeight: '400px',
            panelClass: 'my-dialog',
            height: 'auto',
            data: {
                title: 'Invite ',
                groupID: this.groupId,
                name: this.groupName,
                URL: this.groupDetail.isSubscriptionGroup ? environment.currentDomain + "community/" + this.groupId : this.baseUrl + this.groupId,
                // embedURL:  this.baseUrl + this.groupId + '?embed=true'
            },
        });
    }

    createGroup() {
        const dialogRef = this.dialog.open(CreateGroupComponent, {
            width: '100%',
            maxWidth: '600px',
            minHeight: '400px',
            panelClass: 'my-dialog',
            height: 'auto',
            // data: {
            //     plan: item
            // },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.onMyGroup({_id: result});
            }
        });
    }

    showPlansForAdminView(readOnly, unit) {

        const dialogRef = this.dialog.open(PricingDialogComponent, {
            width: '550px',
            maxWidth: '90%',
            minHeight: '400px',
            maxHeight: '90vh',
            panelClass: 'my-dialog',
            height: 'auto',
            data: {
                id: this.groupDetail.admin,
                groupId: this.groupDetail._id,
                selectedPlanId: this.selectedPlanId,
                readonly: (!this.buyerButNotSubscriber || readOnly),
                unit
            },
        });
        dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {      
            this.selectedPlanId = null;
        });

    }

    closeNotCompletedCommunityOnboardingMessage() {
        $('#notCompletedCommunityOnboardingMessage').modal('hide');
    }

    showPlans(readOnly = false) {
        
        // //WC 07282024 --- To not allow the user to subscriber for non-freemium plans if the Stripe onboarding status is still not completed yet
        if (!readOnly && this.currentGroupAdminInfo?.accountOnboardingStatus !== 'Completed' ) {

            if (!this.isFreemiumPlan) {
                //$('#notCompletedCommunityOnboardingMessage').modal('show');
                readOnly = true;
                
            }
        
        }


        if (!this.localStorageService.getItem('token') && window.location.pathname.includes('/my-group/') ) {
            const encodedTopic = encodeURIComponent(this.paramsValue?.topic);        
            if(this.paramsValue?.commentId && this.paramsValue?.topic){

                this.localStorageService.setItem('redirectURL', '/my-group/' + this.groupId + '?commentId=' + this.paramsValue?.commentId + '&topic='+ encodedTopic);
            }else{
                this.localStorageService.setItem('redirectURL', '/my-group/' + this.groupId);
            }
            this.router.navigate(['login'], { queryParams: { cid: 'EOFF' + this.groupDetail.admin} });
            return;
        }
        

        if (this.groupDetail.isPasswordProtected && !this.passwordValidated) {
            $('#passwordProtected').modal('show');
            return;
        }
        // console.log('showPlans - subscriptionGroupShoppingCartExist = ', this.subscriptionGroupShoppingCartExist);

        // if there's a shopping cart for the group Id then redirect to community checkout
        if (this.subscriptionGroupShoppingCartExist) {
            // this.router.navigateByUrl('community-checkout');
            this.router.navigate(['/community-checkout'], {queryParams: {id: this.groupDetail._id}});
            return;
        }

        // console.log("this.selectedPlanId", this.selectedPlanId);
        
        // if (this.buyerButNotSubscriber) {
        let isGroupPage = window.location.pathname.includes('/my-group/')  

        //WC 06112024 --- Don't ask to join if the user is the group admin
        if(isGroupPage && !this.isAdmin){
            $('#joinGroupModal').modal('show')
            return
        }

        //WC 06122024 --- to show the correct monthly or yearly plan subscription when the user clicks on the 'Subscribed' button
        if (this.currentUserInformation?.subscriptionInfo) {
            const groupPlan = this.currentUserInformation.subscriptionInfo.filter(x => x.groupId == this.groupId);

            if (groupPlan.length > 0) {
                this.selectedPlanId = groupPlan[0].planId;
                readOnly = true 
            }
        }

        // console.log("showPlans - readyOnly = readOnly");

        const dialogRef = this.dialog.open(PricingDialogComponent, {
            width: '550px',
            maxWidth: '90%',
            minHeight: '400px',
            maxHeight: '90vh',
            panelClass: 'my-dialog',
            height: 'auto',
            data: {
                id: this.groupDetail.admin,
                groupId: this.groupDetail._id,
                selectedPlanId: this.selectedPlanId,
                readonly: (!this.buyerButNotSubscriber || readOnly)
            },
        });
        dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {      
            this.selectedPlanId = null;
        });
        // } else {
        //     // unsubscribe here
        // }

    }

    navigateToCommunity() {

        // this.router.navigate(['/main-community'], {queryParams: {user: this.groupDetail.admin}});
        this.router.navigate(['/my-community'], {queryParams: {user: this.groupDetail.admin}});
    }

    joinOrLeft() {
        //WC 11032023 --- if the user is not logged in yet
        if (!this.localStorageService.getItem('token')) {
            this.localStorageService.setItem('redirectURL', '/community/' + this.groupId + "?action=join");
            this.router.navigate(['login'], { queryParams: { cid: 'EOFF' + this.groupDetail.admin} });
            return;
        }
        //WC 11032023

        if (!(this.isMember || this.isAdmin)) {
            if (this.groupDetail.isPasswordProtected && !this.passwordValidated) {
                $('#passwordProtected').modal('show');
                return;
            }
            const payload = {
                groupId: this.groupId,
                members: [{
                    accessType: 'MEMBER',
                    memberId: this.currentUserId
                }]
            }
            this.addMemberProcess = true;
            this.api.joinGroup(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                this.hideModal()
                this.addMemberProcess = false;
                this.getCurrentGroupDetail(this.groupId);
                // this.refreshAllGroupContent();
                this.getCurrentMember();
                if (this.isPrivate) {
                    this.toaster.success('Your request to join the group has been sent to the admin, you\'ll get a notification when it\'s approved');
                } else {
                    //this.toaster.success('You have successfully joined the group');
                    if (res.message == "Success")
                        this.toaster.success('You have successfully joined the group');
                }
            }, err => {
                this.addMemberProcess = false;
            });
        }
    }

    validatePassword() {
        this.spinner = true;
        this.passwordInputInValid = '';
        this.api.validateGroupPassword({groupId: this.groupId, password: this.passwordInput})
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            if (res.data._id != null) {
                //console.log("res.data = ", res.data);

                //WH 10022023 --- PenTestFix against response modification
                if (res.data._id.toString() == this.groupId) {
                    this.passwordValidated = true;
                    this.hideModal();
                    if (this.isSubscriptionGroup) {
                        this.showPlans();
                    } else {
                        this.joinOrLeft();
                    }
                } else {
                    this.passwordInputInValid = 'Incorrect Password';
                }
            } else {
                this.passwordInputInValid = 'Incorrect Password';
            }
        }).add(() => this.spinner = false);
    }

    togglePasswordTextType() {
        this.fieldPassTextType = !this.fieldPassTextType;
    }

    ngOnDestroy(): void {
        this.localStorageService.removeItem('title')
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.dashboardService.setGroupDetails = null;
    }

    ngAfterViewChecked(): void {

        //WC 11032023 --- Anonymous user being redirected to login to create an account before joining a non-subscription group and then redirected back to the community
        if (this.isJoin && !this.isCompleted) {
            this.api.getGroupDetail(this.groupId).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                this.groupDetail = res.data[0];
                this.document.getElementById("joinGroup").click();
                this.isCompleted = true;
            })
        }
        //WC 11032023

        $('[data-toggle="tooltip"]').tooltip();

    }

    onChangeContentTab(tab, type?) {
        
        if(type){
            this.defaultEditTabType = type;
        }
        this.currentContentTab = tab;
        
        if (!this.isFixedCurrentContentTab) {
            
            const activeTab = this.groupAllTabsContent.find(x => x.name === tab);
            // if (activeTab) {
            //     this.groupActiveTabContent = activeTab.data;
            // } else {
                // if(!this.isMoveContent){
                    this.getGroupContentByTabName(tab);
                // }
            // }
        } else {       
                 
            if (this.markVideoToRefresh) {
                this.markVideoToRefresh = false;
                this.refreshAllGroupContent();
            }
            if(type === "Videos"){
                this.api.getGroupContent(this.groupId).subscribe((res) =>{
                    this.groupAllVideos = res.data;
                    //console.log("groupAllVideos", this.groupAllVideos);
                    // this.isMediaContent = false
                });

            }
        }
    }

    updateAttachments(att, comment) {
        this.spinner = true;
        let attachments = comment.attachments.filter(x => x !== att);
        attachments = attachments.map(x => this.firstUrl(x));

        // Payload
        // {
        //     "_id": "6451d5d585f8a5118dfc448e",
        //     "groupId": "6431a2f90d8d5419852d2da6",
        //     "scenerio": "comment",
        //     "attachments": [
        //         "https://video-playback-public.s3-accelerate.amazonaws.com/Attachments/hAhtysgwXVXT-PTInt6dYudU.png?AWSAccessKeyId=AKIA3Z722SHJDMX54EWC&Expires=1683098686&Signature=2I%2FT1d%2FGpweTf9QQZ0pLa3SV88Q%3D"
        //     ]
        // }
        const payload = {
            commentId: comment._id,
            groupId: comment.groupId,
            scenerio: 'comment',
            attachments,
            comment: comment.comment
        };
        this.api.addComment(payload)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                this.toaster.success('Attachment deleted successfully');
                this.getAllGroupComments(true);
        }).add(() => this.spinner = false);
    }


    viewAttachments(attachment) {
        if (this.urlType(attachment, 'image')) {
            this.currentImage = attachment;
            $('#viewAttachmentsModal').modal('show');
        } else {
            window.open(attachment, '_blank');
        }
    }

    firstUrl(item) {
        // console.log(item)
        return item.split('?')[0];
    }

    urlType(url, type: 'image' | 'pdf') {
        if (typeof url !== 'string') return false;
        if (type === 'image') {
            return (url.match(/^http[^?]*.(jpg|jpeg|gif|png|tiff|bmp|svg)(\?(.*))?$/gmi) != null);
        }
        if (type === 'pdf') {
            return (url.match(/^http[^?]*.(pdf)(\?(.*))?$/gmi) != null);
        }
        return false;
    }

    addNewTab() {
        this.selectedBadge = ""
        this.isEditGroupTabName = false;
        const dialogRef = this.dialog.open(this.addNewTabDialog, {
            width: '500px',
            maxWidth: '90%',
            panelClass: 'my-dialog',
            height: 'auto'
        });
        dialogRef.afterClosed().subscribe((result) => {
            // this.checkScrollVisibilityForGroupTabs();
            this.tabName = '';
        });
    }

    createGroupTab() {
        this.spinner = true;
        const payload:any = {
            groupId: this.groupId,
            tabName: this.tabName
        }
        
        if(this.selectedBadge){
            payload.badgeId = this.selectedBadge._id
        }

        this.api.createGroupTab(payload).subscribe((res) => {
            this.isCalledContentInFunction = false;
            this.currentContentTab = this.tabName

            this.refreshAllGroupContent();
            this.api.getGroupDetail(this.groupId).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                this.groupDetail = res.data[0];
                if(!this.groupDetail?.tabOrders?.length){
                    this.contentTabs = this.groupDetail?.userDefinedTabs || [];
                    
                }
                if(this.groupDetail?.tabOrders?.length){
                    this.contentTabs = this.groupDetail?.tabOrders || [];
                }
            })
            this.api.getGroupTabsContentSize(this.groupId)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
            this.groupTabsContentSize = success.data;
            this.defaultEditTabType = "userDefine"
            this.dialog.closeAll();
            this.tabName = '';
            })
        }).add(() => this.spinner = false);
    }

    deleteGroupTab(tabName, type?) {
        
        this.deleteDialogRef.open({
            header: 'Remove Tab',
            title: 'Are you sure you want to remove this tab?',
            trueButton: 'Remove'
        }, (e) => {
            if (e) {
                this.spinner = true;
                const payload:any = {
                    groupId: this.groupId,
                    tabName
                }
                if(type){
                    payload.defaultDeleteTabType = type;
                }
                
                this.api.deleteGroupTab(payload).subscribe((res) => {
                    // this.contentTabs = res.data?.userDefinedTabs || [];
                    this.api.getGroupDetail(this.groupId).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
                        this.groupDetail = res.data[0];
                        // this.checkScrollVisibilityForGroupTabs();
                        if(!this.groupDetail?.tabOrders?.length){
                            this.contentTabs = this.groupDetail?.userDefinedTabs || [];
                            
                        }
                        if(this.groupDetail?.tabOrders?.length){
                            this.contentTabs = this.groupDetail?.tabOrders || [];
                        }
                    })
                    this.api.getGroupTabsContentSize(this.groupId)
                    .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
                    this.groupTabsContentSize = success.data;
                    this.groupAllTabsContent = [];
                    this.dialog.closeAll();
                    if (this.currentContentTab === tabName) {
                        // this.currentContentTab = 'videos';
                            this.currentContentTab = this.getVideosTabName() ? this.getVideosTabName() : this.getLinksTabName() ? this.getLinksTabName() :  this.groupDetail?.userDefinedTabs[0];
                            this.getGroupContentByTabName(this.currentContentTab);
                        }
                        
                        if(!this.groupDetail?.tabOrders?.length){
                            this.defaultEditTabType = "Videos"
                            this.getGroupContentByTabName(this.currentContentTab);
                        }

                        if(this.groupDetail?.tabOrders?.length){
                            this.currentContentTab = this.groupDetail?.tabOrders[0].name
                            this.defaultEditTabType = this.groupDetail?.tabOrders[0].type   
                            this.getGroupContentByTabName(this.currentContentTab);
                            // this.markVideoToRefresh = true;
                        }
                        
                        this.contentSpinner = false;
                        this.spinner = false;
                    })
                })
            }
        });
    }

    getGroupDetail(){
        this.api.getGroupDetail(this.groupId).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.groupDetail = res.data[0];
        })
    }
    getVideosTabName(): string {
        if(!this.groupDetail?.deletedDefaultTabs?.includes("Videos")){
            if (this.groupDetail && this.groupDetail?.renamedDefaultTabs?.length) {
                const videosTab = this.groupDetail.renamedDefaultTabs?.find(tab => tab?.type === 'Videos');
                return videosTab ? videosTab?.name : 'Videos';
            } else {
                return 'Videos';
            }
        }
    }
    
    getLinksTabName(): string {        
        if(!this.groupDetail?.deletedDefaultTabs?.includes("Links")){
            if (this.groupDetail && this.groupDetail?.renamedDefaultTabs?.length) {
                const linksTab = this.groupDetail.renamedDefaultTabs?.find(tab => tab?.type === 'Links');
                return linksTab ? linksTab?.name : 'Links';
            } else {
                return 'Links';
            }
        }
    }

    editGroupTab(tabName:any, index?){
        
        this.isEditGroupTabName = true;
        this.indexForEditTabName = index;
        this.tabName = tabName;
        this.currentContentTab = tabName
        this.selectedBadge = ""
        this.api.getGroupContent(this.groupId, tabName)
        .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
        this.groupActiveTabContent = success.data;
        
        const activeTab = this.groupAllTabsContent.find(x => x.name === tabName);
        if (activeTab) {
            activeTab.data = success.data
        } else {
            this.groupAllTabsContent.push({
                name: tabName,
                data: success.data
            });                 
        }
    }).add(() => this.contentSpinner = false);
        
        const dialogRef = this.dialog.open(this.addNewTabDialog, {
            width: '500px',
            maxWidth: '90%',
            panelClass: 'my-dialog',
            height: 'auto'
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.tabName = '';
        });
    }

    DefaultEditGroupTab(name:any, type:any, index?){
        this.tabName = name;
        this.isEditGroupTabName = true;
        this.defaultEditTabType = type;
        
        this.indexForEditTabName = index;
        this.currentContentTab = name;
        this.selectedBadge = ""
        this.api.getGroupContent(this.groupId, name)
        .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
        this.groupActiveTabContent = success.data;
        
        const activeTab = this.groupAllTabsContent.find(x => x.name === name);
        if (activeTab) {
            activeTab.data = success.data
        } else {
            this.groupAllTabsContent.push({
                name: name,
                data: success.data
            });                 
        }
    }).add(() => this.contentSpinner = false);
        const dialogRef = this.dialog.open(this.addNewTabDialog, {
            width: '500px',
            maxWidth: '90%',
            panelClass: 'my-dialog',
            height: 'auto',
        });
        dialogRef.afterClosed().subscribe((result) => {
            this.tabName = '';
            this.defaultEditTabType = '';
        });
    }


    editGroupTabName(){
        this.spinner = true;
        let groupSharingIdArray = [];
        this.groupAllTabsContent = [];
        
        this.groupActiveTabContent?.map((i:any) => groupSharingIdArray.push(i._id))
        
        let payload: any = {
            groupId: this.groupId,
            tabName: this.tabName,
            index: this.indexForEditTabName,
            groupSharingId : groupSharingIdArray
        }
        

        if(this.selectedBadge){
            payload.badgeId = this.selectedBadge._id
        }

        if(this.defaultEditTabType){
            payload.defaultEditTabType = this.defaultEditTabType
        }
        
        this.api.editGroupTab(payload).subscribe((res)=>{
            this.getGroupDetail();
            this.api.getGroupContent(this.groupId, this.tabName)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
            this.groupActiveTabContent = success.data;
            })

            this.api.getGroupTabsContentSize(this.groupId)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
            this.spinner = false;
            this.groupTabsContentSize = success.data;
            this.dialog.closeAll();
            this.getGroupDetail();
            this.currentContentTab = this.tabName;
            this.isCalledContentInFunction = false;
            
            if(this.defaultEditTabType === 'Videos' || this.defaultEditTabType === 'Links'){
                if(!this.groupDetail?.tabOrders?.length && this.defaultEditTabType === 'Videos') {
                    this.currentContentTab = this.getVideosTabName();
                }
                
                if(!this.groupDetail?.tabOrders?.length && this.defaultEditTabType === 'Links') {
                    this.currentContentTab = this.getLinksTabName();
                }
                
                if(this.groupDetail?.tabOrders?.length) {
                    this.currentContentTab = this.tabName;
                }
                this.refreshAllGroupContent();
            }
            this.selectedBadge = ""
            })
        }, (error) => {
            this.spinner = false;
        })
    }

    isDisplayEdit(tabName:any){
        return !this.groupDetail?.tabsWithBadge?.some((i) => i.name === tabName);
    }

    actionOnBadge(action:any){
        $("#badgeUpdateDeleteModal").modal('hide')

        let payload:any = {
            action: action,
            tabName: this.currentContentTab,
            groupId: this.groupId,
        }

        if(action === 'deleteBadge'){

            this.deleteDialogRef.open({
                header: 'Remove Badge',
                title: 'Are you sure you want to remove this badge?',
                trueButton: 'Remove'
            }, (e) => {
                if(e){
                    this.spinner = true;
                    this.api.actionOnTabBadge(payload).subscribe((res)=>{
                        this.spinner = false;
                        this.toaster.success(`Badge is remove from ${this.currentContentTab} tab`)
                        this.getGroupDetail();
                    })
                }
            })
        }

        if(action === 'editBadge'){
            const dialogRef = this.dialog.open(CreateVideoComponent, {
                data: {
                    badges: true,
                    links: false,
                    videos: false
                },
                minWidth: '50vw'
            });
            dialogRef.afterClosed().subscribe((result: UploadedFileResponse) => {
                if (result) {
                    this.selectedBadge = result;
                    this.spinner = true;
                    payload.badgeId = this.selectedBadge?._id
                    this.api.actionOnTabBadge(payload).subscribe((res)=>{
                        this.spinner = false;
                        this.getGroupDetail();
                        this.toaster.success("Badge is updated")
                    })
                }
            });
        }
    }

    openModalForBadge(tabName:any){
        this.spinner = true;
        this.currentContentTab = tabName;
        let badgeId;
        this.groupDetail?.tabsWithBadge?.map((i:any) => {
            if(i.name === tabName){
                badgeId = i.badgeId
            }
        })
        this.api.getSpecificBadge(badgeId).subscribe((res:any) => {
            this.selectedBadge = res.data;
            this.spinner = false;
            $("#badgeUpdateDeleteModal").modal('show')
        })
    }

    reOrderTabs(){
        this.allTabsForReOrder = [];
        if(!this.groupDetail?.tabOrders?.length){
            if(this.getVideosTabName()){
                this.allTabsForReOrder.push({name: this.getVideosTabName(), type: 'Videos'})
            }
            if(this.getLinksTabName()){
                this.allTabsForReOrder.push({name: this.getLinksTabName(), type: 'Links'})
            }
    
            this.groupDetail?.userDefinedTabs?.map((i)=>{
                this.allTabsForReOrder.push({name: i, type: 'userDefine'})
            })
        }else{
            this.allTabsForReOrder = this.groupDetail?.tabOrders;
        }

        $('#reOrderGroupTab').modal('show')        
    }

    downloadGroupMembers() {
        this.spinner = true;
        const name = `${this.groupName}_${this.isSubscriptionGroup ? 'subscribers' : 'members'}`;
        this.api.downloadGroupMembers(this.groupId)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            const fileToExport = res.data.map((item: any) => {
                delete item._id;
                return item;
            });
            this.excelService.exportToExcel(fileToExport, name);
        }).add(() => this.spinner = false);
    }

    openReorderGroupModal(){
        $('#reOrderGroupModal').modal('show')  
        if(!this.groupDetail?.tabOrders?.length){
            if(this.currentContentTab === this.getVideosTabName()){
                this.defaultEditTabType = "Videos"
            }
            if(this.currentContentTab === this.getLinksTabName()){
                this.defaultEditTabType = 'Links'
            }
        }      
    }

    drop(event: CdkDragDrop<string[]>) {
        let data = [];
        let arrayToModify: any[];
        
        //console.log("this.currentContentTab ==> ", this.currentContentTab);
    
        if (this.currentContentTab === this.getVideosTabName()) {
            arrayToModify = this.groupAllVideos;
        } else if (this.currentContentTab === this.getLinksTabName()) {
            arrayToModify = this.groupAllLinks;
        } else if (!this.isFixedCurrentContentTab) {
            arrayToModify = this.groupActiveTabContent;
        }
    
        if (arrayToModify) {
            moveItemInArray(arrayToModify, event.previousIndex, event.currentIndex);
            data = arrayToModify.map((x, i) => ({ contentId: x._id, currIndex: i }));
            this.api.reorderGroupContent(data).subscribe();
        }
    }

    dropTabs(event: CdkDragDrop<string[]>){
        let data = [];
        moveItemInArray(this.allTabsForReOrder, event.previousIndex, event.currentIndex);
        data = this.allTabsForReOrder.map((value:any,index:number) => ({ name: value.name, type: value.type, currIndex: index }))
        let payload = {
            groupId: this.groupId,
            orderTabs: data
        }
        this.api.reOrderGroupTabs(payload).subscribe((res)=>{
            this.getGroupDetail();
        })
    }

    getObjectFitValue(value:any){
        this.groupDetail.thumbnailSetting = value
        let data = {
            groupId:this.groupId,
            thumbnailSetting : value
        }
        this.api.updateGroupThumbnailSetting(data).subscribe((res)=>{
            this.toaster.success('Image setting change successfully!')
        },
        (error)=>{
            // console.log("error", error);
            this.toaster.error('Error in changing image setting')
        })
    }

    addTopic(){
        this.topicValue = '';
        $('#addCommentTopicModal').modal('show');
    }

    createTopic(){
        this.spinner = true;
        this.isPinnedComments = false;
        const payload:any = {
            groupId: this.groupId,
            topicValue: this.topicValue,
            topicDescription: this.topicDescription
        }
        this.api.createGroupTopic(payload).subscribe((res)=>{
            this.spinner = false;
            this.currentTopicValue = this.topicValue;
            // this.checkScrollVisibility();
            this.getCountOfStarCommentsForGroup();
            $('#addCommentTopicModal').modal('hide');
            this.getGroupDetail();
        }, (error) => {
            this.spinner = false;
        })
    }

    changeCurrentTopic(value:any){
        // this.currentCommentPage = 1;
        this.currentTopicValue = value;
        this.isPinnedComments = false;
        this.getAllGroupComments(true);

    }

    // editGroupTopic(value:any, index){
    //     this.topicValue = value;
    //     this.editTopicIndex = index;
    //     this.currentTopicValue = value;
    //     this.editTopicValue = value;
    //     $('#addCommentTopicModal').modal('show');
    // }

    // editTopic(){
    //     this.spinner = true;
    //     const payload:any = {
    //         groupId: this.groupId,
    //         topicValue: this.topicValue,
    //         index : this.editTopicIndex,
    //         oldTopicValue: this.editTopicValue
    //     }
    //     this.api.editGroupTopic(payload).subscribe((res)=>{
    //         this.spinner = false;
    //         this.getGroupDetail();
    //         this.getAllGroupComments(true);
    //         this.currentTopicValue = this.topicValue;
    //         $('#addCommentTopicModal').modal('hide');
    //         this.editTopicValue = '';
    //         this.editTopicIndex = '';
    //     }, (error) => {
    //         this.spinner = false;
    //     })
    // }

    deleteGroupTopic(value:any){
        this.isPinnedComments = false;
        this.currentTopicValue = value;
        this.deleteDialogRef.open({
            header: 'Remove Tab',
            title: 'Are you sure you want to remove this topic?',
            trueButton: 'Remove'
        }, (e) => {
            if (e) {
                this.spinner = true;
                const payload:any = {
                    groupId: this.groupId,
                    topicValue: value
                }

                this.api.deleteGroupTopic(payload).subscribe((res) => {
                    this.spinner = false;
                    this.getGroupDetail();
                    // this.checkScrollVisibility();
                    this.currentTopicValue = 'Forum'
                    this.getAllGroupComments(true);
                    this.currentTopicValue = "Forum";
                })
            }
        })
    }

    reOrderTopics(){
        this.allTopicsForReOrder = this.groupDetail?.userDefinedTopics;
        $('#reOrderGroupTopic').modal('show') 
    }

    dropTopics(event: CdkDragDrop<string[]>){
        let data = [];
        moveItemInArray(this.allTopicsForReOrder, event.previousIndex, event.currentIndex);
        data = this.allTopicsForReOrder.map((value:any,index:number) => ({ name: value.name, index: index }))
        let payload = {
            groupId: this.groupId,
            orderTopics: data
        }
        this.api.reOrderGroupTopics(payload).subscribe((res)=>{
            this.getGroupDetail();
        })
    }

    getFollowers(reset = false){

        if (reset) {
            this.userPage = 1;
            this.myFollowers = [];
        }

        // this.api.getFollowers(this.userLimit, this.userPage).pipe(takeUntil(this._unsubscribeAll)).subscribe(
        //     success => {
        //         const followersArray = success.data.followers;
        //         for (const item of followersArray) {
        //             this.myFollowers.push(item);
        //         }
        //         // console.log("myFollowers", this.myFollowers);
                
        //     }
        // )

        this.api.getUsersForMention('', this.userPage, this.userLimit).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            success => {
                const followersArray = success.data;
                for (const item of followersArray) {
                    this.myFollowers.push(item);
                }
                // console.log("myFollowers", this.myFollowers);
                
            }
        )
    }

    extractLastUsername(htmlString: string, commentType:any) {
        // Regular expression to match any text within <p> tags
        const regex = /<p>(.*?)<\/p>/g;
        let match;
        let lastUsername = null;

        // Iterate over all matches to find the last one containing an @
        while ((match = regex.exec(htmlString)) !== null) {
            const content = match[1].trim();
            const atIndex = content.lastIndexOf('@');
            if (atIndex !== -1) {
                const potentialUsername = content.slice(atIndex).trim();                
                // console.log("potentialUsername ==> ", potentialUsername)
                if(potentialUsername === '@'){
                    this.currentIndex = 0;
                    if(commentType === 'comment'){
                        this.isOpenUserMenu = true;
                    }else if(commentType === 'reply'){
                        this.isOpenUserMenuForReply = true;
                    }else{
                        this.isOpenUserMenuForUpdate = true;
                    }
                }

                if (potentialUsername.length > 1) {
                    lastUsername = potentialUsername;
                } else {
                    lastUsername = '@';
                }
            }
        }

        return lastUsername;
    }

    stripLastEmptyParagraph(htmlString: string) {

        // console.log("stripLastEmptyParagraph - htmlString = ", htmlString);

        if (!htmlString)
            return;

        // Regular expression to match the last <p></p> tag
        const regex = /<p><\/p>(?!.*<p><\/p>)/;
        // Replace the last <p></p> with an empty string
        return htmlString.replace(regex, '');
    }

    // This method will keep the logic for real-time updates
    onNgModelChange(value: string): void {

        // console.log("onNgModelChange - value = ", value);
        // console.log("onNgModelChange - this.isMentioning = ", this.isMentioning);
        //console.log("called comment ===> ", this.comment);

        // console.log("onNgModelChange - this.searchUserName = ", this.searchUserName);
        
        //isMentioning is set to true when the user presses '@'
        if (this.isMentioning) { 

            this.newMentionPos = this.findStartMentionPos(value);
            // console.log("onNgModelChange - newMentionPos = ", this.newMentionPos);

            if (this.newMentionPos != null) {
    
                if (this.newMentionPos.insertPos == "end of string") {
                    const lastMentionUserName = this.extractLastUsername(value, 'comment');
                    // console.log("onNgModelChange - lastMentionUserName = ", lastMentionUserName);
    
                    // const plainTextValue = this.stripHtml(value);
                    // console.log("onNgModelChange - plainTextValue = ", plainTextValue);
    
                    this.searchUserName = lastMentionUserName?.substring(1);
                    // console.log("onNgModelChange - this.searchUserName for end of string mention = ", this.searchUserName);
    
                    this.searchFollowers(this.searchUserName);
    
                } else {
                    // console.log("onNgModelChange - this.searchUserName for NOT end of string mention = ", this.searchUserName);
    
                    this.searchFollowers(this.searchUserName);
                }
            }
        };

        if (this.isMentioning && !this.isPressEnter) {
            this.isOpenUserMenu = true;
            // console.log("opening up the pop up menu")
        }

    }

    findStartMentionPos(newMessage: string) {
        // console.log("findStartMentionPos - this.previousMessage = ", this.previousMessage);
        // console.log("findStartMentionPos - newMessage = ", newMessage);
      
        // Loop through the characters of the new string
        for (let i = 0; i < newMessage.length; i++) {
          if (this.previousMessage[i] !== newMessage[i]) {
            // If the characters differ, check if the new character is '@'
            if (newMessage[i] === '@') {
                //If the new char is '@', check if it's added at the end or not
                // console.log("newMessage.length = ", newMessage.length);

                //The number 5 here derives from newMessage = <p>@</p> and 4 chars length for </p> and then add 1
                if (i == (newMessage.length - 5))
                    return {
                        "newMentionPos" : i,
                        "insertPos" : "end of string"
                    };
                else {
                    return {
                        "newMentionPos" : i,
                        "insertPos" : "not end of string"
                    };
                }
            } 
          }
        }
      
        // If no new '@' was found, return -1
        // return {
        //     "newMentionPos" : -1,
        //     "insertPos" : ""
        // };

          return {
                "newMentionPos" : 1,
                "insertPos" : "end of string"
            }
    }

    wrapMentionWithAnchorTag(input: string) {
        // console.log("in wrapMentionWithAnchorTag - this.newMentionPos = ", this.newMentionPos);
        
        // Regular expression to find "@username" strings not wrapped in anchor tags

        // console.log("in wrapMentionWithAnchorTag - input = ", input);

        if (input == null)
            return;

        // Finds substrings that start with @ followed by characters that are not whitespace, <, or >.
        // Optionally allows additional words separated by spaces to be part of the match.
        // Ensures that the match is immediately followed by the closing paragraph tag </p>.
        // Applies this search globally to the entire input string, returning all such matches.

        var regex = null;

        if (this.newMentionPos.insertPos == "end of string") {
            // regex = /(@[^\s<>]+(?:\s[^\s<>]+)*)(?=<\/p>)/g;
            regex = /(@[^\s<>]+(?:\s[^\s<>]+)*)(?=<\/p>|$)/g;


            // console.log("end of string regex = ", regex);
        } else {
            regex = /(@[^\s<>]+(?:\s[^\s<>]*)*<\/p><p>)/g;

            // console.log("in wrapMentionWithAnchorTag - NOT end of string regex = ", regex);
        }

        return input.replace(regex, (match, p1, p2) => {

            // console.log("in wrapMentionWithAnchorTag - match = ", match);
            // console.log("in wrapMentionWithAnchorTag - p1 = ", p1);
            // console.log("in wrapMentionWithAnchorTag - p2 = ", p2);

            let username = p1.slice(1); // Remove the '@' symbol

            // console.log("in wrapMentionWithAnchorTag - Before regex replacement - username = ", username)
            if (username.includes("</p><p>")) {

                const regex = /<\/p><p>/g;
                // Replace the last <p></p> with an empty string
                username = username.replace(regex, '');
                // console.log("in wrapMentionWithAnchorTag - After regex replacement - username = ", username)

                this.searchUserName = username;
            }
            
            // console.log("in wrapMentionWithAnchorTag - this.myFollowers = ", this.myFollowers);
            // console.log("in wrapMentionWithAnchorTag - this.searchUserName = ", this.searchUserName);

            const highlightedUser = this.myFollowers.find(user =>
                user.username?.toLowerCase().includes(this.searchUserName?.toLowerCase())
            );

            // console.log("in wrapMentionWithAnchorTag - highlighterUser = ", highlightedUser);

            const replacementText = `<a href="${environment.currentDomain}public-profile?user=${highlightedUser?._id}">@${highlightedUser?.username}</a>&nbsp;`;
            // console.log("*** in wrapMentionWithAnchorTag - replacementText = ", replacementText);

            return replacementText;
          });
    }
    
    addLineBreak(type: string) {

        let content: string;
    
        // Get the content based on the type
        if (type === 'postBodyEditor') {
        content = this.comment;
        } else if (type === 'replyCommentEditor') {
        content = this.reply;
        } else if (type === 'emailBodyForUpdateComment') {
        content = this.updateComments;
        } else {
        return;
        }
    
        // Replace multiple consecutive empty <p> tags with a single <br> tag
        const replaceEmptyParagraphs = (content: string): string => {
        return content.replace(/(<p><\/p>\s*){2,}/g, (match) => {
            // The number of <p></p> tags in the match
            const emptyLineCount = match.match(/<p><\/p>/g)?.length || 0;
            // Use only one <br> tag for multiple empty paragraphs
            return '<br>';
        });
        };
    
        // Process the content to handle line breaks
        content = replaceEmptyParagraphs(content);
    
        // Ensure proper closing tag if needed
        if (!content.endsWith('</p>') && !content.endsWith('<br>')) {
        content += '</p>';
        }
    
        // Save the updated content back based on the type
        if (type === 'postBodyEditor') {
            this.comment = content;
        } else if (type === 'replyCommentEditor') {
            this.reply = content;
        } else if (type === 'emailBodyForUpdateComment') {
            this.updateComments = content;
        }
    }
  
    onEnterKeyPressed(event: KeyboardEvent, type?: any) { 
        // console.log("onEnterKeyPressed - event = ", event.key);
        // console.log("onEnterKeyPressed - this.comment = ", this.comment);

        this.editorActivity = type;
        
        // Check if '@' is pressed and start mentioning
        if (event.key === '@') {
            // console.log("*** onEnterKeyPressed  - @ is typed")
            this.isMentioning = true;
            this.isPressEnter = false;
            this.searchUserName = "";
            this.searchUser = "";
            this.myFollowers = [];
            this.keyPressStr = "";
            this.previousKeyPressStr = "@";

            // console.log("*** onEnterKeyPressed  - this.previousKeyPressStr = ", this.previousKeyPressStr)

            //Save the current message as the previous message so we can use it for comparison with the new message
            if (type === 'postBodyEditor')
                this.previousMessage = this.comment;
            else if(type === 'replyCommentEditor')
                this.previousMessage = this.reply
            else
                this.previousMessage = this.updateComments

        } else if (event.key === 'Enter') {
            
            event.preventDefault(); // Add this line

            if (!this.isMentioning){
                this.addLineBreak(type);
                return;
            }

            if (this.isMentioning && this.currentIndex >= 0) {
                // console.log("ifff");
                
                this.selectHighlightedUser();
                if(type === 'postBodyEditor'){
                    this.comment = this.wrapMentionWithAnchorTag(this.comment);
                    // console.log("this.comment ", this.comment);
                    this.comment = this.stripLastEmptyParagraph(this.comment);
                }else if(type === 'replyCommentEditor'){
                    this.reply = this.wrapMentionWithAnchorTag(this.reply);
                    this.reply = this.stripLastEmptyParagraph(this.reply);
                }
                else {
                    this.updateComments = this.wrapMentionWithAnchorTag(this.updateComments);
                    this.updateComments = this.stripLastEmptyParagraph(this.updateComments);
                }

            }else{
                // console.log("else");
                this.selectHighlightedUser();

                //if(type === 'postBodyEditor'){
                if(type.startsWith('postBodyEditor')){
                    if (this.isOpenUserMenu){
                        this.isPressEnter = true;
                        this.isAddSignValue = true;
                    }
    
                    this.comment = this.wrapMentionWithAnchorTag(this.comment);
                    // console.log("this.comment ===> ", this.comment);
                    
                    this.newMentionPos = null
                    
                    this.isOpenUserMenu = false;
    
                    this.comment = this.stripLastEmptyParagraph(this.comment);

                    // console.log("this.comment", this.comment)
    
                }
    
                if(type.startsWith('replyCommentEditor')){
                    if (this.isOpenUserMenuForReply){
                        this.isPressEnter = true;
                        this.isAddSignValue = true;
                    }
    
                    this.reply = this.wrapMentionWithAnchorTag(this.reply);
    
                    this.newMentionPos = null
                    
                    this.isOpenUserMenuForReply = false;
    
                    this.reply = this.stripLastEmptyParagraph(this.reply);
    
                }
    
                if (type.startsWith('emailBodyForUpdateComment')){
                    if (this.isOpenUserMenuForUpdate){
                        this.isPressEnter = true;
                        this.isAddSignValue = true;
                    }
    
                    this.updateComments = this.wrapMentionWithAnchorTag(this.updateComments);
    
                    this.newMentionPos = null
                    
                    this.isOpenUserMenuForUpdate = false;
    
                    this.updateComments = this.stripLastEmptyParagraph(this.updateComments);
    
                }

        
            }

            this.isMentioning = false;

        } else if (event.key === 'Backspace') {

            // console.log("onKeyPress - this.previousKeyPressStr = ", this.previousKeyPressStr);

            this.handleBackspace(event, type);
        } else if(event.key === 'ArrowUp'){
            event.preventDefault();
            this.moveHighlightUp(type);
        } else if(event.key === 'ArrowDown'){
            event.preventDefault();
            this.moveHighlightDown(type);
        } else {
            // console.log("onKeyPress - typing letter one by one - type = ", type);

            if (!this.isMentioning)
                return;
            
            //Ignore typing on the user modal search input
            if(type == 'postBodyEditor_userModal')
                return;
            else if (type == 'replyCommentEditor_userModal')
                return;
            else if (type == 'emailBodyForUpdateComment_userModal')
                return;
            else if (type == 'emailBodyForUpdateReplyComment_userModal')
                return;

            //key press other than '@' or Enter and we are typing mentioned username inside the string
            if ((event.code.startsWith("Key") || event.code.startsWith("Digit") || event.code == "Space")) {

                this.previousKeyPressStr += event.key;
                // console.log("onKeyPress - this.previousKeyPressStr = ", this.previousKeyPressStr);

                this.keyPressStr += event.key;
                // console.log("onKeyPress - this.keyPressStr = ", this.keyPressStr);

                //If the user types Space right after @ then close the pop-up and set the isMentioning to false
                if (this.keyPressStr.trim() == "" && event.code == "Space") {
                    this.isMentioning = false;

                    if(type == 'postBodyEditor')
                        this.isOpenUserMenu = false;
                    else if (type == 'replyCommentEditor')
                        this.isOpenUserMenuForReply = false;
                    else if (type == 'emailBodyForUpdateComment')
                        this.isOpenUserMenuForUpdate = false;
                }

                this.searchUserName += event.key;
                // console.log("onEnterKeyPressed - - Typing mention - this.searchUserName ", this.searchUserName);
            }
            
        }
    }

    currentIndex: number = 0; 
    @ViewChild('commentUsersContainer') commentUsersContainer: ElementRef;
    @ViewChild('replyCommentUsersContainer') replyCommentUsersContainer: ElementRef;
    @ViewChild('updateCommentUsersContainer') updateCommentUsersContainer: ElementRef;
    @ViewChild('updateReplyCommentUsersContainer') updateReplyCommentUsersContainer: ElementRef;

    moveHighlightUp(type:any) {
        // console.log("this.currentIndex ==>", this.currentIndex);
        
        if (this.currentIndex > 0) {
            // if()
            this.currentIndex--;
            // console.log("this.currentIndex =>", this.currentIndex);
            
            this.scrollToHighlightedUser(type);
        }
    }

    moveHighlightDown(type:any) {
        if (this.currentIndex <= this.myFollowers.length - 1) {
            this.currentIndex++;
            this.scrollToHighlightedUser(type);
        }
    }

    scrollToHighlightedUser(type:any) {
        let container;
        if(type.startsWith('postBodyEditor')){
            container = this.commentUsersContainer.nativeElement;
        }else if(type.startsWith('replyCommentEditor')){
            container = this.replyCommentUsersContainer.nativeElement;
        }else if(type.startsWith("emailBodyForUpdateComment")){
            container = this.updateCommentUsersContainer.nativeElement;
        }
        else if (type.startsWith("emailBodyForUpdateReplyComment")) {
            container = this.updateReplyCommentUsersContainer.nativeElement;
        }

        const items = container.getElementsByClassName('follower-item');
        
        if (items[this.currentIndex]) {
            const item = items[this.currentIndex];
            const itemOffsetTop = item.offsetTop;
            const itemHeight = item.offsetHeight;
            const containerScrollTop = container.scrollTop;
            const containerHeight = container.clientHeight;
    
            const isAboveView = itemOffsetTop < containerScrollTop;
            const isBelowView = itemOffsetTop > containerScrollTop;
    
            if (isAboveView) {
                container.scrollTop = itemOffsetTop;
            } else if (isBelowView) {
                container.scrollTop = itemOffsetTop + itemHeight - containerHeight;
            }else{
                // console.log("else")
            }
        }
    }
    
    selectHighlightedUser() {
        if (this.currentIndex >= 0 && this.currentIndex < this.myFollowers.length) {
            // console.log("this.currentIndex", this.currentIndex);
            this.selectTagUser(this.myFollowers[this.currentIndex]);
        }
    }
    
    handleBackspace(event: KeyboardEvent, type?: any) {

        // console.log("in handleBackspace - type = ", type);

        //Ignore typing on the user modal search input
        if(type == 'postBodyEditor_userModal')
          return;
        else if (type == 'replyCommentEditor_userModal')
          return;
        else if (type == 'emailBodyForUpdateComment_userModal')
          return;

        //Remove the last char from the user search
        this.searchUserName = this.searchUserName?.substring(0, this.searchUserName?.length - 1);
        
        this.previousKeyPressStr = this.previousKeyPressStr?.substring(0, this.previousKeyPressStr?.length - 1);
        // console.log("in handleBackspace -- this.previousKeyPressStr = ", this.previousKeyPressStr);

        const selection = window.getSelection();
        // console.log("in handleBackSpace - selection = ", selection);

        if (selection.rangeCount === 0) return;
        const range = selection.getRangeAt(0);
        const anchorNode = range.startContainer;
        const mentionTag: any = anchorNode.parentNode as HTMLElement;
        // console.log("in handleBackSpace - mentionTag",  mentionTag);
        
        //Looking for anchor tag
        if (mentionTag && mentionTag.tagName === 'A' && mentionTag.href) {
          let mentionText = mentionTag.innerText;
        //   console.log("in handleBackSpace - mentionText = ", mentionText);

          const originalMentionText = mentionTag.innerText; // Save the original mention text with '@'
          mentionText = mentionText.startsWith('@') ? mentionText.substring(1) : mentionText; // Remove the '@' symbol
          const parentParagraph = mentionTag.parentElement as HTMLElement;
      
          // Remove the mention tag
        //   console.log("in handleBackSpace - REMOVING mentionText = ", mentionText);
          const textNode = document.createTextNode(' ');
          parentParagraph.replaceChild(textNode, mentionTag);
      
          // Restore the cursor position
          const newRange = document.createRange();
          newRange.setStart(textNode, 1);
          newRange.setEnd(textNode, 1);
          selection.removeAllRanges();
          selection.addRange(newRange);
          this.mentionUsers = this.mentionUsers.filter(user => !user.name.includes(mentionText));
      
          //Reset mentioning
          this.isMentioning = false;
            
          // Do not open user menu based on type
        //   console.log("in handleBackSpace - Backspace pressed inside mention tag - mentionText = ", mentionText);
        } else {
            //Not deleting anchored mention user but deleting/backspace non-anchored usernameletter by letter until it deletes @
            //Delete @
            if (this.previousKeyPressStr == "") {
                this.isMentioning = false;

                if (type == 'postBodyEditor')
                    this.isOpenUserMenu = false;
                else if (type === 'replyCommentEditor')
                    this.isOpenUserMenuForReply = false;
                else if (type === 'emailBodyForUpdateComment')
                    this.isOpenUserMenuForUpdate = false;

            } else if (this.previousKeyPressStr == "@") {
                //User backspaces until @
                // console.log("*** in handleBackspace -- REACHING @ - this.previousKeyPressStr = ", this.previousKeyPressStr);

                this.isMentioning = true;

                if (type == 'postBodyEditor')
                    this.isOpenUserMenu = true;
                else if (type === 'replyCommentEditor')
                    this.isOpenUserMenuForReply = true;
                else if (type === 'emailBodyForUpdateComment')
                    this.isOpenUserMenuForUpdate = true;

            } else {
                //Use case type @ followed by text and select them and hit delete button
                //The isMentioning is still true but @ followed by text is deleted
                if (type == 'postBodyEditor' && this.isMentioning) {
                  if (!this.comment.includes(this.previousKeyPressStr)) {
                    this.isOpenUserMenu = false;
                    this.isMentioning = false;
                  }
                } else if (type == 'replyCommentEditor' && this.isMentioning) {
                  if (!this.reply.includes(this.previousKeyPressStr)) {
                    this.isOpenUserMenuForReply = false;
                    this.isMentioning = false;
                  }
                } else if (type == 'emailBodyForUpdateComment' && this.isMentioning) {
                  if (!this.updateComments.includes(this.previousKeyPressStr)) {
                    this.isOpenUserMenuForUpdate = false;
                    this.isMentioning = false;
                  }
                }
            }
        }
      }

    stripHtml(html: string): string {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }

    onNgModelChangeForUpdateComment(value:string){
        //isMentioning is set to true when the user presses '@'
        if (this.isMentioning) { 

            this.newMentionPos = this.findStartMentionPos(value);
            // console.log("onNgModelChange - newMentionPos = ", this.newMentionPos);

            if (this.newMentionPos != null) {
    
                if (this.newMentionPos.insertPos == "end of string") {
                    const lastMentionUserName = this.extractLastUsername(value, 'updateComment');
                    // console.log("onNgModelChange - lastMentionUserName = ", lastMentionUserName);
    
                    this.searchUserName = lastMentionUserName?.substring(1);
                    // console.log("onNgModelChange - this.searchUserName for end of string mention = ", this.searchUserName);
    
                    this.searchFollowers(this.searchUserName);
    
                } else {
                    // console.log("onNgModelChange - this.searchUserName for NOT end of string mention = ", this.searchUserName);
    
                    this.searchFollowers(this.searchUserName);
                }
            }
        };

        if (this.isMentioning && !this.isPressEnter) {
            this.isOpenUserMenuForUpdate = true;
            // console.log("opening up the pop up menu")

            //this.isMentioning = false;
        } 
    }

    onNgModelChangeForUpdateReplyComment(value:string){
        //isMentioning is set to true when the user presses '@'
        if (this.isMentioning) { 

            this.newMentionPos = this.findStartMentionPos(value);
            // console.log("onNgModelChange - newMentionPos = ", this.newMentionPos);

            if (this.newMentionPos != null) {
    
                if (this.newMentionPos.insertPos == "end of string") {
                    const lastMentionUserName = this.extractLastUsername(value, 'updateReplyComment');
                    // console.log("onNgModelChange - lastMentionUserName = ", lastMentionUserName);
    
                    this.searchUserName = lastMentionUserName?.substring(1);
                    // console.log("onNgModelChange - this.searchUserName for end of string mention = ", this.searchUserName);
    
                    this.searchFollowers(this.searchUserName);
    
                } else {
                    // console.log("onNgModelChange - this.searchUserName for NOT end of string mention = ", this.searchUserName);
    
                    this.searchFollowers(this.searchUserName);
                }
            }
        };

        if (this.isMentioning && !this.isPressEnter) {
            this.isOpenUserMenuForUpdate = true;
            // console.log("opening up the pop up menu")

            //this.isMentioning = false;
        } 
    }

    onNgModelChangeForReply(value:string){

        // console.log("onNgModelChangeForReply - value = ", value);
        
        //isMentioning is set to true when the user presses '@'
        if (this.isMentioning) { 

            this.newMentionPos = this.findStartMentionPos(value);
            // console.log("onNgModelChangeForReply - newMentionPos = ", this.newMentionPos);

            if (this.newMentionPos != null) {
    
                if (this.newMentionPos.insertPos == "end of string") {
                    const lastMentionUserName = this.extractLastUsername(value, 'reply');
                    // console.log("onNgModelChangeForReply - lastMentionUserName = ", lastMentionUserName);
            
                    this.searchUserName = lastMentionUserName?.substring(1);
                    // console.log("onNgModelChangeForReply - this.searchUserName for end of string mention = ", this.searchUserName);
    
                    this.searchFollowers(this.searchUserName);
            
                } else {
                    // console.log("onNgModelChangeForReply - this.searchUserName for NOT end of string mention = ", this.searchUserName);
    
                    this.searchFollowers(this.searchUserName);
                }
            }
        };

        if (this.isMentioning && !this.isPressEnter) {
            this.isOpenUserMenuForReply = true;
            // console.log("opening up the pop up menu")

            //this.isMentioning = false;
        }  
    }

    removeCommentTag(comment:any){
        const commentText = comment ? comment.replace(/<\/?[^>]+(>|$)/g, "") : '';        
        return commentText
    }

    openUserPopUp(type:any){
        // console.log("*** In openUserPopUp");

        this.searchUser=''
        this.currentMentionUsersType = 'followers'
        this.placeHolderValue = "Search followers"
        //this.getFollowers(true)

        this.searchFollowers("");
        this.selectedCommentTypeForMentionUser = type;
        if(type === 'comment'){

            this.isOpenUserMenu = !this.isOpenUserMenu; 
            this.editorActivity = 'postBodyEditor'
              // Append "@" to the comment value
            if (this.comment) {
                // Check if the last element is a <p> tag and append "@"
                const regex = /<\/p>\s*$/i;
                
                if (regex.test(this.comment)) {
                    this.comment = this.comment.replace(regex, '@</p>');

                    // console.log("this.comment after replace", this.comment);

                } else {
                    this.comment = '<p>@</p>';
                }
            } else {
                this.comment = '<p>@</p>';
            }

            this.newMentionPos = this.findStartMentionPos(this.comment);

        }else if(type === 'updateComment'){
            this.isOpenUserMenuForUpdate = !this.isOpenUserMenuForUpdate;

            this.editorActivity = 'emailBodyForUpdateComment'
            // Append "@" to the comment value
            if (this.updateComments) {
                // Check if the last element is a <p> tag and append "@"
                const regex = /<\/p>\s*$/i;
                
                if (regex.test(this.updateComments)) {
                    this.updateComments = this.updateComments.replace(regex, '@</p>');

                    // console.log("this.comment after replace", this.updateComments);

                } else {
                    this.updateComments = '<p>@</p>';
                }
            } else {
                this.updateComments = '<p>@</p>';
            }

            this.newMentionPos = this.findStartMentionPos(this.updateComments);

        }
        else {
            this.isOpenUserMenuForReply = !this.isOpenUserMenuForReply;

            this.editorActivity = 'replyCommentEditor'
            // Append "@" to the comment value
            if (this.reply) {
                // Check if the last element is a <p> tag and append "@"
                const regex = /<\/p>\s*$/i;
                
                if (regex.test(this.reply)) {
                    this.reply = this.reply.replace(regex, '@</p>');

                    // console.log("this.comment after replace", this.reply);

                } else {
                    this.reply = '<p>@</p>';
                }
            } else {
                this.reply = '<p>@</p>';
            }

            this.newMentionPos = this.findStartMentionPos(this.reply);
        }        
    }

    insertStringAt(original: string, insert: string, position: number) {
        // console.log("###insertStringAt - original = ", original);
        // console.log("###insertStringAt - position = ", position);
        // console.log("###insertStringAt - original.length = ", original.length);
        // console.log("###insertStringAt - insert = ", insert);

        if (position < 0 || position > original.length) {
          return original;
        } else {
        //   console.log("original.slice(0, position) = ", original.slice(0, position));
        //   console.log("original.slice(position) = ", original.slice(position));

        //   console.log("insertString At - this.newMentionPos.insertPos = ", this.newMentionPos.insertPos);

          if (this.newMentionPos.insertPos == "end of string")
            return original.slice(0, position) + insert + original.slice(position);
          else {
            //Insert from the filtered user list in the middle of the comment
            if (!original.slice(position).startsWith("</p><p>"))
                return original.slice(0, position) + insert + "</p><p>" + original.slice(position);
            else
                return original.slice(0, position) + insert + original.slice(position);
          }
        }   
    }

    replaceSubstringWithSpaces(str: string, pos1: number, length: number) {
        // console.log(" in replaceSubstringWithSpaces - str = ", str);
        // console.log(" in replaceSubstringWithSpaces - pos1 = ", pos1);
        // console.log(" in replaceSubstringWithSpaces - length = ", length);

        if (str == null) 
            return "";

        if (pos1 < 0 || length < 0 || pos1 >= str?.length) {
          return str;
        }
        
        // Calculate the end position
        const endPos = Math.min(pos1 + length, str?.length);
        
        // Create a string of spaces with the required length
        const spaces = ''.repeat(endPos - pos1);
        
        // Replace the substring with spaces
        const result = str?.slice(0, pos1) + spaces + str?.slice(endPos);
        
        return result;
    }

    selectTagUser(item:any) {

        // this.newMentionPos = this.findStartMentionPos(this.comment);

        
        //this.searchUserName = item.username;
        
        var updatedComment = "";
        var updatedReply = "";
        var updatedUpdateComments = "";
        var updatedUpdateReplyComments = "";
        // this.searchUserName = item.username
        
        // console.log("In selectTagUser - item = ", item);
        // console.log("In selectTagUser - this.newMentionPos.insertPos = ", this.newMentionPos?.insertPos);
        // console.log("In selectTagUser - this.newMentionPos.newMentionPos = ", this.newMentionPos?.newMentionPos);
        // console.log("In selectTagUser - this.editorActivity = ", this.editorActivity);
        // console.log("In selectTagUser - this.comment ======> ", this.comment);
        // console.log("in selectTagUser - this.searchUserName = ", this.searchUserName);

        //A new comment
        if (this.editorActivity.startsWith("postBodyEditor")) {

            //Delete any string after this.newMentionPost.newMentionPos that equals to the searchUserName before inserting the selected user name from the dropdown list
            //When selecting a user from the dropDrown directly without typing any partial username, this.comment doesn't contain any string which equals to this.searchUserName so we have to check
            //If the user types @ followed by several chars and then decides to select the user from the dropdown list then the comment will contain searchUserName
            //if (this.comment.includes(this.searchUserName)) {

            //console.log("!!!! in selectTagUser - this.keyPressStr = ", this.keyPressStr);

            //if keyPressStr is greater than 0 that means the user is typing the mentioned username
            if (this.keyPressStr?.length > 0) {
                updatedComment = this.replaceSubstringWithSpaces(this.comment, this.newMentionPos.newMentionPos + 1, this.searchUserName.length)
                // console.log("In selectTagUser - updatedComment AFTER REMOVING OLD USERNAME = ", updatedComment);
            } else {
                updatedComment = this.comment;
            }

            this.searchUserName = item.username;

            //Adding 1 to the newMentPos to insert in the next position
            updatedComment = this.insertStringAt(updatedComment, item.username, this.newMentionPos.newMentionPos + 1);
            // console.log("*** In selectTagUser - updatedComment after inserting selected user tag = ", updatedComment);

            this.comment = this.wrapMentionWithAnchorTag(updatedComment);

            this.previousMessage = this.comment;
        } else if (this.editorActivity.startsWith("replyCommentEditor")) { //user replying to a comment

            //if (this.reply.includes(this.searchUserName)) {
            if (this.keyPressStr?.length > 0) {
                //Delete any string after this.newMentionPost.newMentionPos that equals to the searchUserName before inserting the selected user name from the dropdown list
                updatedReply = this.replaceSubstringWithSpaces(this.reply, this.newMentionPos.newMentionPos + 1, this.searchUserName.length)
                // console.log("In selectTagUser - updatedReply AFTER REMOVING OLD USERNAME = ", updatedReply);
            } else {
                updatedReply = this.reply
            }

            this.searchUserName = item.username;

            //Adding 1 to the newMentPos to insert in the next position
            updatedReply = this.insertStringAt(updatedReply, item.username, this.newMentionPos.newMentionPos + 1);
            // console.log("In selectTagUser - updatedReply after inserting selected user tag = ", updatedReply);

            this.reply = this.wrapMentionWithAnchorTag(updatedReply);

            this.previousMessage = this.reply;
        }
        else {

            //if (this.updateComments.includes(this.searchUserName)) {
                if (this.keyPressStr?.length > 0) {
                //Delete any string after this.newMentionPost.newMentionPos that equals to the searchUserName before inserting the selected user name from the dropdown list
                updatedUpdateComments = this.replaceSubstringWithSpaces(this.updateComments, this.newMentionPos.newMentionPos + 1, this.searchUserName.length)
                // console.log("In selectTagUser - updatedUpdateComments AFTER REMOVING OLD USERNAME = ", updatedUpdateComments);
            } else {
                updatedUpdateComments = this.updateComments;
            }

            this.searchUserName = item.username;

            //Adding 1 to the newMentPos to insert in the next position
            updatedUpdateComments= this.insertStringAt(updatedUpdateComments, item.username, this.newMentionPos.newMentionPos + 1);
            // console.log("In selectTagUser - updatedUpdateComments after inserting selected user tag = ", updatedUpdateComments);

            this.updateComments = this.wrapMentionWithAnchorTag(updatedUpdateComments);

            this.previousMessage = this.updateComments;
        }
        
        this.isOpenUserMenu = false;
        this.isOpenUserMenuForUpdate = false;
        this.isOpenUserMenuForReply = false;
        this.isAddSignValue = true;
        //this.getFollowers(true);
        this.isMentioning = false;
        this.searchUserName = "";
        this.searchUser = "";
    }
    
    searchFollowers(value:any){
        this.searchUserName = value;

        this.api.getUsersForMention(value).pipe(takeUntil(this._unsubscribeAll)).subscribe(
            success => {
                this.myFollowers = success.data;
                if(this.myFollowers.length === 0){
                    this.isOpenUserMenuForReply = false;
                    this.isOpenUserMenuForUpdate = false;
                    this.isOpenUserMenu = false;
                    this.isMentioning = false;
                }
                this.currentIndex = 0;
                // console.log("in searchFollowers - this.myFollowers = ", this.myFollowers);
            }
        )
    }

    onScrollUsers(){
        ++this.userPage;
        this.getFollowers();
    }

    changeMentionUserType(type:any){
        this.currentMentionUsersType = type;             
        this.searchUser = "";  
        if(type === 'followers'){
            this.getFollowers(true)
            this.placeHolderValue = "Search followers"
        }else if(type === 'subscriber'){
            this.placeHolderValue = "Search subscribers"
        }else{
            this.placeHolderValue = "Search members"
        }
    }
    
    clickRightArrow() {
        const scrollRight = document.getElementById("right-arrow");
        const scrollLeft = document.getElementById('left-arrow');
        const productListContainer = document.querySelector(".theme-scrollbar");
    
        // Function to check arrow visibility
        const checkIfEndReached = () => {
            // const maxScrollLeft = productListContainer.scrollWidth - productListContainer.clientWidth;
            // scrollRight.style.display = (productListContainer.scrollLeft >= maxScrollLeft) ? 'none' : 'block';
            // scrollLeft.style.display = (productListContainer.scrollLeft <= 0) ? 'none' : 'block';
        };
    
        // Click event for right arrow
        scrollRight.addEventListener('click', () => {
            const scrollAmount = productListContainer.clientWidth;
            const maxScrollLeft = productListContainer.scrollWidth - productListContainer.clientWidth;
    
            // Scroll right, ensuring not to exceed maxScrollLeft
            productListContainer.scrollBy({
                top: 0,
                left: Math.min(scrollAmount, maxScrollLeft - productListContainer.scrollLeft),
                behavior: 'smooth'
            });
    
            setTimeout(checkIfEndReached, 500); // Check after scroll animation completes
        });
    
        // Click event for left arrow
        scrollLeft.addEventListener('click', () => {
            const scrollAmount = productListContainer.clientWidth;
    
            // Scroll left, ensuring not to go below 0
            productListContainer.scrollBy({
                top: 0,
                left: -Math.min(scrollAmount, productListContainer.scrollLeft),
                behavior: 'smooth'
            });
    
            setTimeout(checkIfEndReached, 500); // Check after scroll animation completes
        });
    
        // Check arrows visibility on scroll and initial load
        productListContainer.addEventListener('scroll', checkIfEndReached);
    
        // Initial check to set arrows visibility
        checkIfEndReached();
    }

    clickRightArrowBoard() {
        const scrollRight = document.getElementById("right-arrow-board");
        const scrollLeft = document.getElementById('left-arrow-board');
        const productListContainer = document.getElementById("theme-scrollbar-board");

        // Function to check arrow visibility
        const checkIfEndReached = () => {
            // const maxScrollLeft = productListContainer.scrollWidth - productListContainer.clientWidth;
            // const scrollLeftPosition = productListContainer.scrollLeft;

            // // Hide right arrow if at the end
            // scrollRight.style.display = (scrollLeftPosition >= maxScrollLeft) ? 'none' : 'block';

            // // Hide left arrow if at the beginning
            // scrollLeft.style.display = (scrollLeftPosition <= 0) ? 'none' : 'block';

            // // Hide arrows if no scrolling is possible (content does not overflow)
            // if (productListContainer.scrollWidth <= productListContainer.clientWidth) {
            //     scrollLeft.style.display = 'none';
            //     scrollRight.style.display = 'none';
            // }
        };

        // Click event for right arrow
        scrollRight.addEventListener('click', () => {
            const scrollAmount = productListContainer.clientWidth;
            const maxScrollLeft = productListContainer.scrollWidth - productListContainer.clientWidth;

            // Scroll right, ensuring not to exceed maxScrollLeft
            productListContainer.scrollBy({
                top: 0,
                left: Math.min(scrollAmount, maxScrollLeft - productListContainer.scrollLeft),
                behavior: 'smooth'
            });

            setTimeout(checkIfEndReached, 500); // Check after scroll animation completes
        });

        // Click event for left arrow
        scrollLeft.addEventListener('click', () => {
            const scrollAmount = productListContainer.clientWidth;

            // Scroll left, ensuring not to go below 0
            productListContainer.scrollBy({
                top: 0,
                left: -Math.min(scrollAmount, productListContainer.scrollLeft),
                behavior: 'smooth'
            });

            setTimeout(checkIfEndReached, 500); // Check after scroll animation completes
        });

        // Check arrows visibility on scroll and initial load
        productListContainer.addEventListener('scroll', checkIfEndReached);

        // Initial check to set arrows visibility
        checkIfEndReached();
    }

    highlightUser(index:any){
        this.currentIndex = index;
    }

apiCalled: boolean = false; // Initialize flag
showUserDetails(event: MouseEvent, type: any) {
    const targetElement = event.target as HTMLElement;
    let editorElement;
    let user;
    let isDisplayTootip = false;
    let username;
    let userId;
    // Access ngx-editor element using document.getElementById()
    if (type === 'comment') {
        editorElement = document.getElementById('emailBody');
    }
    if (type === 'updateComment') {
        editorElement = document.getElementById('emailBodyForUpdateComment');
    }
    if (type === 'replyComment') {
        editorElement = document.getElementById('reply');
    }
    if (type === 'updateReplyComment') {
        editorElement = document.getElementById('emailBodyForUpdateReplyComment');
    }
  
    if (editorElement) {
        // Find all <a> tags within ngx-editor content
        const mentionLinks = editorElement.querySelectorAll('a');
  
        mentionLinks.forEach(link => {
            link.addEventListener('mouseenter', () => {
              isDisplayTootip = true;

              const href = link.getAttribute('href');
              const urlParams = new URLSearchParams(href.split('?')[1]);
              userId = urlParams.get('user');

              if(!userId){
                return
              }
                username = link.textContent?.trim().substring(1); // Extract username from mention link
  
                if (!this.apiCalled) { // Check if API call has already been made
                    this.apiCalled = true; // Set flag to true
  
                  this.api.getUserInfoV2(userId).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                    success => {
                      user = success.data?.userInfo;
                        if (user && isDisplayTootip && user._id === userId) {
                            const tooltip = document.createElement('div');
                            tooltip.classList.add('tooltip001');
          
                            // Create outer span
                            const outerSpan = document.createElement('span');
          
                            // Create image element if profilePic exists
                            if (user.profilePic) {
                                const img = document.createElement('img');
                                img.src = user.profilePic;
                                img.width = 32;
                                img.height = 32;
                                img.alt = 'Profile Picture';
                                outerSpan.appendChild(img);
                            } else {
                                const firstnameFirstLetter = user.firstname ? user.firstname.charAt(0) : '';
                                const lastnameFirstLetter = user.lastname ? user.lastname.charAt(0) : '';
          
                                const emptySpan = document.createElement('span');
                                emptySpan.classList.add('profile-image');
                                emptySpan.setAttribute('title', `${user.firstname}, ${user.lastname}`);
                                emptySpan.textContent = `${firstnameFirstLetter}${lastnameFirstLetter}`;
                                outerSpan.appendChild(emptySpan);
                            }
          
                            // Create inner span for username
                            const innerSpan = document.createElement('span');
                            innerSpan.classList.add('username');
                            innerSpan.textContent = user.username;
                            outerSpan.appendChild(innerSpan);
          
                            // Append the entire structure to tooltip
                            tooltip.appendChild(outerSpan);
          
                            // Calculate position based on mention link position
                            const linkRect = link.getBoundingClientRect();
                            const tooltipX = linkRect.left - editorElement.getBoundingClientRect().left - tooltip.offsetHeight - 1;
                            const tooltipY = linkRect.top - editorElement.getBoundingClientRect().top - tooltip.offsetHeight - 45;
                            tooltip.style.left = tooltipX + 'px';
                            tooltip.style.top = tooltipY + 'px';
          
                            // Append tooltip to the document body or relevant container
                            editorElement.appendChild(tooltip);
                        }
                    }
                )
                  
                }
            });
  
            link.addEventListener('mouseleave', () => {
                const tooltip = document.querySelector('.tooltip001');
                user = null;
                username = null;
                userId = null;
                isDisplayTootip = false;
                if (tooltip) {
                    tooltip.remove(); // Remove the tooltip when mouse leaves the mention link
                }
                this.apiCalled = false; // Reset flag for the next hover
            });
        });
  
    } else {
        // console.log("Element with id 'emailBody' not found.");
    }
  }
  

  openMeetingCalendar(){
    window.open(this.groupDetail.meetingCalendar, '_blank');
    }
    
    editGroupTopic(topicName:any, topicDescription:any, index:number) {
        this.editTopicValue = topicName;
        this.isPinnedComments = false;
        this.topicValue = topicName;
        this.topicDescription = topicDescription;
        this.editTopicIndex = index;
        $('#addCommentTopicModal').modal('show');
    } 

    editTopic() {
        this.spinner = true;
        this.isPinnedComments = false;
            let payload:any = {
                groupId: this.groupId,
                topicValue: this.editTopicValue,
                index: this.editTopicIndex,
                topicDescription: this.topicDescription
        }
        
        if(this.editTopicValue !== this.topicValue) {
            payload.displayedText = this.topicValue;
        }
        
            this.api.editGroupTopic(payload).subscribe((res)=>{
                    this.currentTopicValue = res.data.userDefinedTopics[this.editTopicIndex].name;
                    this.spinner = false;
                    this.getGroupDetail();
                    this.getAllGroupComments(true);
                    $('#addCommentTopicModal').modal('hide');
                    this.topicValue = '';
                    this.editTopicValue = '';
                    this.editTopicIndex = '';
                    this.topicDescription = '';
            }, (error) => {
                this.spinner = false;
        })
    }

    closeTopicModal() {
        this.topicValue = '';
        this.editTopicValue = '';
        this.editTopicIndex = '';
        this.topicDescription = '';
        $('#addCommentTopicModal').modal('hide');
    }

    setPinComment(comment: any) {
        let obj = {
            commentId: comment._id,
        }
        this.api.setPinnedGroupMessage(obj).subscribe((res: any) => {
            // this.getAllGroupComments(true);

            if (!comment.replyOnCommentID || comment.replyOnCommentID === null) {
                this.allGroupComments.map(x => {
                    if (x._id === comment._id) {
                        if (x?.pinnedByUserId?.length) {
                            x?.pinnedByUserId.push(this.currentUserId)
                        } else {                        
                            x.pinnedByUserId = [this.currentUserId]
                        }
                    }
                })
            } else {
                this.allGroupComments.map(x => {
                    if (x._id === comment.replyOnCommentID) {
                        x.replies.map(y => {
                            if(y._id === comment._id) {
                                if (y?.pinnedByUserId?.length) {                                    
                                    y?.pinnedByUserId.push(this.currentUserId)
                                } else {                                    
                                    y.pinnedByUserId = [this.currentUserId]
                                }
                            }
                        })
                    }
                })
                if (this.isPinnedComments) {
                    this.getPinnedComments(this.currentTopicValue)
                }
            }

            this.getCountOfStarCommentsForGroup()

        }, (error) => {
            console.log("error: ", error);
        })
    }

    setAdminPinComment(comment:any){
        let obj = {
            commentId: comment._id,
            isAdminPinComment: true
        }
        this.api.setPinnedGroupMessage(obj).subscribe((res: any) => {
            this.getAllGroupComments(true);

            // this.allGroupComments.map(x => {
            //     if (x._id === comment._id) {
            //         if (x?.pinnedByAdminUserId) {
            //             x.pinnedByAdminUserId = null;
            //             x.pinnedByAdminDate = null;
            //         } else {                        
            //             x.pinnedByAdminUserId = this.currentUserId;
            //             x.pinnedByAdminDate = new Date().toISOString();
            //         }
            //     }
            // })
            
        }, (error) => {
            console.log("error: ", error);
        })
    }
    
    removePinComment(comment: any) {
        let obj = {
            commentId: comment._id,
        }
        this.api.removePinnedGroupMessage(obj).subscribe((res: any) => {
            if (!comment.replyOnCommentID || comment.replyOnCommentID === null) {
                this.allGroupComments.map(x => {
                    if (x._id === comment._id) {
                        if (x?.pinnedByUserId?.length) {
                            let index = x.pinnedByUserId.indexOf(this.currentUserId);
                            if (index > -1) {
                                x.pinnedByUserId.splice(index, 1);
                            }
                        }
                    }
                })
            } else {
                this.allGroupComments.map(x => {
                    if (x._id === comment.replyOnCommentID) {
                        x.replies.map(y => {
                            if (y._id === comment._id) {
                                if (y?.pinnedByUserId?.length) {
                                    let index = y.pinnedByUserId.indexOf(this.currentUserId);
                                    if (index > -1) {
                                        y.pinnedByUserId.splice(index, 1);
                                    }
                                }
                            }
                        })
                    }
                })
            }
            
            if (this.isPinnedComments) {
                this.getPinnedComments(this.currentTopicValue)
            }
            this.getCountOfStarCommentsForGroup()
        }, (error) => {
            console.log("error: ", error);
        })
    }

    removeAdminPinComment(comment:any) {
        let obj = {
            commentId: comment._id,
            isAdminPinComment: true
        }
        this.api.removePinnedGroupMessage(obj).subscribe((res: any) => {
            this.getAllGroupComments(true);
        }, (error) => {
            console.log("error: ", error);
        })
    }

    isPinnedComment(comment:any) {
        let isPined = false;
        if (comment?.pinnedByUserId?.includes(this.currentUserId)) {
            isPined = true
        } else {
            isPined = false
        }

        return isPined
    }

    isPinnedCommentByAdmin(comment:any) {
        let isPinedByAdmin = false;
        if (comment?.pinnedByAdminUserId && comment?.pinnedByAdminDate && comment?.pinnedByAdminUserId !== null && comment?.pinnedByAdminDate !== null) {
            isPinedByAdmin = true
        } else {
            isPinedByAdmin = false
        }

        return isPinedByAdmin
    }

    getPinnedComments(topic: any) {
        this.currentTopicValue = topic;
        this.isPinnedComments = true;
        this.getAllGroupComments(true);
    }

    isShowLikeButton(likeUsers: any): boolean {
        const hasLiked = likeUsers.some((i: any) => i.userId === this.currentUserId);
        return !hasLiked;
    }

    isShowDisLikeButton(likeUsers: any): boolean {
        const hasLiked = likeUsers.some((i: any) => i.userId === this.currentUserId);
        return hasLiked;
    }


    showAnalytics(data:any){
        this.spinner = true;
        this.api.getTree(data?.contentData?._id).subscribe((success) => {
            if (!!!success.data?.URL) {
                success.data.basck = 'gt';
            } else {
                success.data.basck = 'videochart';
                success.data.imvd = 'ncs';
            }

             const dialogRef = this.dialog.open(AnalyticsModalComponent, {
                  width: '100%',
                  maxWidth: '1400px',
                  panelClass: 'analytics-modal',
                  height: 'auto',
                  data: {topChoices: success.data}
                });
            
                dialogRef.afterClosed().subscribe((result) => {
                  if (result) {
                  }
                });
            this.spinner = false;
        });

    }

    showQuestionnaires(data:any){
        const dialogRef = this.dialog.open(QuestionnairesDashboardComponent, {
            data: {
                videoId: data?.contentData?._id,
            },
            panelClass: 'questionnairesDashboard',
            minWidth: '50vw'
        });
    }

    getCountOfStarCommentsForGroup() {
        this.api.getCountOfStarCommentsForGroup(this.groupId).subscribe((res) => {
            this.topicWithStarCount = res.data
        })
    }

    isStarComments(topic:any) {
        const topicData = this.topicWithStarCount?.find((item: any) => item.topicName === topic);
        return topicData?.pinnedCommentsCount === 0 ? false : true;
    }

    isShowAdminSection(): boolean {
        let result: boolean;

        if (this.isPersonalize === 'true') {
            result = this.isAdmin;
        } else {
            result = true;
        }

        return result;
    }


}

