import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { CrudService } from "../../services/crud.service";
import { DeleteConfirmationComponent } from "../../layout/modals/delete-confirmation/delete-confirmation.component";
import { Subject } from "rxjs";
import { debounceTime, takeUntil } from "rxjs/operators";
import { UploadLinkDialogComponent } from "../../layout/modals/create-video/upload-link-dialog/upload-link-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { UtilityService } from "../../services/utility.service";
import { ToastrService } from "ngx-toastr";
import languages from "../../services/languages";
import { RecordVideoComponent } from "../../layout/modals/record-video/record-video.component";
import { DashboardService } from "../../services/dashboard.service";
import { CreateBadgeComponent } from "../../layout/modals/create-badge/create-badge.component";
import { ActivatedRoute, Router } from "@angular/router";
import { CreateQuestionnaireComponent } from "../../layout/modals/create-questionnaire/create-questionnaire.component";
import { LocalStorageService } from "../../services/localStorage.service";
import { DomSanitizer } from "@angular/platform-browser";
import { environment } from "src/environments/environment";
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { error } from "console";

declare var $: any;

@Component({
  selector: "app-library",
  templateUrl: "./library.component.html",
  styleUrls: ["./library.component.css"],
  // animations: [fadeInOutAnimation]
})
export class LibraryComponent implements OnInit, OnDestroy {
  @ViewChild("deleteDialogRef") deleteDialogRef: DeleteConfirmationComponent;

  allVideos: Array<any> = [];
  currentVideoObj: any = {};
  showVideo = false;
  spinner = true;
  currentTab:
    | "videos"
    | "ai-editor-videos"
    | "links"
    | "audios"
    | "badges"
    | "documents"
    | "medias"
    | "questionnaire" = "videos";
  currentBadgeType: "creation" | "earned" = "earned";
  adminGroups = [];
  currentUserId: string;
  currentGroupIndex = 0;
  aiVideoCount = 0;
  videoCount = 0;
  documentCount = 0;
  urlCount = 0;
  audioCount = 0;
  earnedBadgeCount = 0;
  creationBadgeCount = 0;
  questionnaireCount = 0;
  folderVideoCount = 0;
  allBadges = [];
  allQuestionnaire = [];
  canDownloadLibraryVideo = false;
  isIE = false;
  questionnaire: any = {};
  selectedEarnBadge: any = {};
  doNotShowEditVideoModal = false;
  folderName:any;
  isDisplayFolderInformation:boolean = false;
  folderVideoList:any[] = []
  selectedFolderInfo:any;
  selectedVideoIdForAddingFolder:any[] = [];
  selectedVideoIdForDeletingFolder:any[] = [];
  dropboxParamsValue:any;

  private limit = 15;
  private page = 1;
  sortBy = "recent";
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private searchText: string;

  constructor(
    public apiService: CrudService,
    private dashboardService: DashboardService, 
    private activatedRoute: ActivatedRoute,
    private utilService: UtilityService,
    private dialog: MatDialog,
    private toaster: ToastrService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private elRef: ElementRef
  ) {
    this.currentUserId = this.localStorageService.getItem("user");
    pdfMake.vfs = pdfFonts.pdfMake.vfs; // Load virtual file system fonts
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  ngOnInit() {

    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.badge && ["earned", "creation"].includes(params.badge)) {
        this.currentBadgeType = params.badge;
      }

      if (params.folderId) {
        this.getFolderInfo(params.folderId);
      }

      if (!params.folderId || params.folderId === undefined) {
        this.selectedFolderInfo = null;
        this.isDisplayFolderInformation = false;
        this.folderVideoList = [];
        this.getAllVideos(true);
      }

      if (
        params.type &&
        [
          "videos",
          "ai-editor-videos",
          "links",
          "audios",
          "badges",
          "questionnaire",
        ].includes(params.type)
      ) {
        this.changeTab(params.type);
      }

      if (params.upload == "dropbox" && params.token) {
        this.dropboxParamsValue = params;
        setTimeout(() => {
          this.openUploadVideo();
          }, 3000);
      }

    });
    this.dashboardService.getSearchTerm
      .pipe(takeUntil(this._unsubscribeAll), debounceTime(1000))
      .subscribe((res) => {
        this.searchText = res;
        if (this.selectedFolderInfo?._id) {
          this.page = 1;
          this.getVideoCountByFolderId(this.selectedFolderInfo?._id);
          this.getFolderData(this.selectedFolderInfo?._id, false);
        } else {
          this.getQuestionnaire(true)
          this.getAllVideos(true);
        }
      });
    this.getCheckGroupAdmin();
    // this.getAllVideos(true);

    // WC 07292023 --- To check if the user can download a video in the library or not
    // console.log(this.localStorageService.getItem("membershipName"));

    if (this.localStorageService.getItem("membershipName") == "Gold member")
      this.canDownloadLibraryVideo = true;
    else {
      this.dashboardService.getUserData
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res) => {
          if (res) {
            // console.log("res = ", res);
            if (res.canDownloadVideo != undefined)
              this.canDownloadLibraryVideo = res.canDownloadVideo;
          }
        });
    }
  }

  getCheckGroupAdmin() {
    this.apiService.getAllGroups({ accessType: "ADMIN" }).subscribe((res) => {
      if (res.data && res.data.length) {
        this.adminGroups = res.data.filter(
          (x) => x.admin !== this.currentUserId
        );
        // console.log(this.adminGroups)
      }
    });
  }

  onScroll(): void {
    ++this.page;
    if (this.currentTab === "badges") {
      this.getBadges();
    } else if (this.currentTab === "questionnaire") {
      this.getQuestionnaire();
    } else if(this.selectedFolderInfo){
      this.getFolderData(this.selectedFolderInfo?._id, true)
    }else {
        //console.log("page", this.page)
        this.getAllVideos();
    }

  }

  // Get all videos | links | audios
  getAllVideos(reset = false) {
    this.spinner = true;
    //console.log("reset", reset);
    
    if (reset) {
      this.page = 1;
      this.allVideos = [];
      this.getUploadedVideoCount();
    }
    const object: any = {
      page: this.page,
      limit: this.limit,
      sort: this.sortBy,
      type: "video",
      aiEditorVideo: "no"
    };
    if (this.currentVideoSubTab === "links") {
      object.type = "url";
    } else if (this.currentVideoSubTab === "ai-editor-videos") {
      object.aiEditorVideo = "yes";
    } else if (this.currentVideoSubTab === "audios") {
      object.type = "audio";
    }  else if (this.currentVideoSubTab === "documents") {
      object.type = "pdf";
    } 

    if(this.isFolderAddVideos){
      object.folderVideos = true;
    }
    if (this.searchText) {
      object.search = this.searchText;
    }
    this.apiService
      .getLibraryVideos(object)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((success) => {
        for (let i = 0; i < success.data.length; i++) {
          
          if (this.currentVideoSubTab === "audios") {
            success.data[i].poster = "assets/images/wave-audio.png";
          }

          if (
            success.data[i].subtitleTranslations &&
            success.data[i].subtitleTranslations.length
          ) {
            success.data[i].subtitleTranslations = success.data[
              i
            ].subtitleTranslations.map((x) => {
              x.subtitle = languages.find((c) => c.code === Object.keys(x)[0]);
              return x;
            });
            if (success.data[i].subtitleUrl) {
              const defaultLang = this.getDefaultLangTranscription(
                success.data[i]
              );
              //WC 06112024 --- Remove adding closed captioning as the subtitle trans causing duplicate
              //success.data[i].subtitleTranslations.unshift(defaultLang);
            }
          }
          this.allVideos.push(success.data[i]);
        }
        this.spinner = false;
      });
  }

  getDefaultLangTranscription(video) {
    if (video.subtitleUrl) {
      const defaultLang = this.utilService.getTranscriptLang(video.subtitleUrl);
      const langCode = defaultLang.code.split("-")[0];
      let defaultSubTitle = {
        subtitle: {
          code: langCode,
          disabled: false,
          language: defaultLang.language,
        },
      };
      defaultSubTitle[langCode] = video.subtitleUrl;
      return defaultSubTitle;
    }
  }

  getQuestionnaire(reset?) {
    this.spinner = true;
    if (reset) {
      this.page = 1;
      this.allQuestionnaire = [];
      this.getQuestionnaireCount();
    }
    let object: any = {
      page: this.page,
      limit: this.limit,
      sort: this.sortBy,
    };

    if (this.searchText) {
      object.searchText = this.searchText;
    }
    this.apiService
      .getQuestionnaire(object)
      .subscribe((res: any) => {
        this.allQuestionnaire.push(...res.data);
        // this.badgeCount = res.data.length;
      })
      .add(() => (this.spinner = false));
  }

  getQuestionnaireCount() {
    let obj: any = {}
    // if (this.searchText && this.currentTab === 'questionnaire'){
    if (this.searchText){
      obj.searchText = this.searchText
    }
    this.apiService.getQuestionnaireCount(obj).subscribe((response: any) => {
      this.questionnaireCount = response.data?.[0]?.count || 0;
    });
  }

  getBadges(reset?) {
    this.spinner = true;
    if (reset) {
      this.page = 1;
      this.allBadges = [];
      this.getBadgeCount();
    }
    const object: any = {
      page: this.page,
      limit: this.limit,
      sort: this.sortBy,
      type: this.currentBadgeType,
    };
    this.apiService
      .getBadge(object)
      .subscribe((res: any) => {
        this.allBadges.push(...res.data);
        // this.badgeCount = res.data.length;
      })
      .add(() => (this.spinner = false));
  }

  getBadgeCount() {
    this.apiService.getBadgeCount({}).subscribe((response: any) => {
      this.earnedBadgeCount =
        response.data.find((x) => x.type === "earned")?.count || 0;
      this.creationBadgeCount =
        response.data.find((x) => x.type === "creation")?.count || 0;
    });
  }

  // sort could have the following values: "recent", "name", "size", "duration"
  sortVideos(event: any) {
    this.sortBy = event.target.value;

    
    if (this.currentTab === "badges") {
      this.getBadges(true);
    } else if (this.currentTab === "questionnaire") {
      this.getQuestionnaire(true);
    } else if(this.selectedFolderInfo?._id){
      this.page = 1;
      this.getFolderData(this.selectedFolderInfo?._id, false)
    } 
    else {
      this.getAllVideos(true);
    }
  }

  backToVideos(){
    this.spinner = true;
    this.sortBy = 'recent';
    this.removeFolderIdFromURL();
    // console.log("this.sortBy", this.sortBy);
    this.currentTab='videos'
    this.isDisplayFolderInformation = false;
    this.isFolderAddVideos = false;
    this.selectedFolderInfo = '';
    this.getAllVideos(true);

  }

  showModal(id: any) {
    if(!this.isDisplayFolderInformation){
      this.currentVideoObj = this.allVideos.find((x) => x._id === id);
    }else{
      this.currentVideoObj = this.folderVideoList.find((x) => x._id === id);
    }

    if (this.currentTab === "links") {
      window.open(this.currentVideoObj?.URL, "_blank");
      return;
    }

    //console.log("currentVideoObj" , this.currentVideoObj);
    

    this.currentVideoObj.transcriptLang = this.utilService.getTranscriptLang(
      this.currentVideoObj?.subtitleUrl
    );

    $("#preview-Modal").modal("show");
    $("#preview-Modal").unbind("hidden.bs.modal");
    $("#preview-Modal").on("hidden.bs.modal", () => {
      this.currentVideoObj = null;
      this.showVideo = false;
    });
    this.showVideo = true;
    // show video true
  }

  getUploadedVideoCount() {
    // reset the count
    this.videoCount = 0;
    this.urlCount = 0;
    this.documentCount = 0;
    this.aiVideoCount = 0;

    const object: any = {};
    // if (this.searchText && (this.currentVideoSubTab === 'videos' || this.currentTab === 'medias')) {
    if (this.searchText) {
      object.search = this.searchText;
    }
    this.apiService
      .getLibraryVideosCount(object)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((success) => {
        this.videoCount = 0;
        this.urlCount = 0;
        this.audioCount = 0;
        this.documentCount = 0;
        success.data.forEach((e, i) => {
          const isVideo = Object.values(e).includes("application/octet-stream");
          const isUrl = Object.values(e).includes("url");
          const isAudio = Object.values(e).includes("audio/mpeg");
          const isDocument = Object.values(e).includes("application/pdf");
          
          if (isVideo) {
            this.videoCount = e.count;
          }
          if (isUrl) {
            this.urlCount = e.count;
          }
          if (isAudio) {
            this.audioCount = e.count;
          }
          if (isDocument) {
            this.documentCount = e.count;
          }
        });
      });
    const objectAI: any = {
      aiEditorVideo: "yes",
    };
    // if (this.searchText && this.currentVideoSubTab === 'ai-editor-videos') {
    if (this.searchText) {
      objectAI.search = this.searchText;
    }
    this.apiService
      .getLibraryVideosCount(objectAI)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((success) => {
        success.data.forEach((e, i) => {
          this.aiVideoCount = 0;
          const isVideo = Object.values(e).includes("application/octet-stream");
          if (isVideo) {
            this.aiVideoCount = e.count;
          }
        });
      });
    this.getBadgeCount();
    this.getQuestionnaireCount();
  }

  openConfirmationDelete(item: any) {
    let type: string;
    if (this.currentVideoSubTab === "links") {
      type = "Link";
    } else if (this.currentVideoSubTab === "audios") {
      type = "Audio";
    } else if (this.currentVideoSubTab === "documents") {
      type = "Document";
    } else {
      type = "Video";
    }

    if(item?.isFolder){
      type = "Folder"
    }

    let title = item?.isFolder ? 'Are you sure you want to delete the folder and its videos?' : `Are you sure you want to delete this ${type.toLowerCase()}?`

    this.deleteDialogRef.open(
      {
        header: `Delete ${type}`,
        title: title,
      },
      (e) => {
        if (e) {
          this.spinner = true;
          if(!item?.isFolder){
            this.apiService
              .deletePresignedURL({ id: item._id, s3Url: item?.objectURL })
              .pipe(takeUntil(this._unsubscribeAll))
              .subscribe((success) => {
                this.spinner = false;
                this.getAllVideos(true);
                if(this.selectedFolderInfo?._id){
                  this.getFolderData(this.selectedFolderInfo?._id, false)
                }
                this.toaster.success("Deleted successfully");
              }, error => {
                this.spinner = false;
              });
          }else{
            this.apiService.deleteFolder(item?._id).subscribe((res) =>{
              this.toaster.success("Folder deleted successfully")
              this.spinner = false;
              this.getAllVideos(true);
            }, error => {
              this.spinner = false;
            })
          }
        }
      }
    );
  }

  openConfirmationDeleteBadge(item: any) {
    this.deleteDialogRef.open(
      {
        header: `Delete Badge`,
        title: `Are you sure you want to delete this badge?`,
      },
      (e) => {
        if (e) {
          this.apiService
            .deleteBadge(item._id)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((success) => {
              this.getBadges(true);
            });
        }
      }
    );
  }

  changeTab(tab) {
    this.isDisplayFolderInformation = false;
    this.dashboardService.setActivePage = tab;
    this.currentTab = tab;
    this.removeFolderIdFromURL();
    // this.getQuestionnaireCount()
    if (tab === "badges") {
      this.getBadges(true);
    } else if (tab === "questionnaire") {
      this.currentVideoSubTab = ''
      this.getQuestionnaire(true);
    } else if(tab === "medias"){
      this.currentVideoSubTab = "links"
      this.getAllVideos(true)
    } else {
      this.isFolderAddVideos = false;
      this.selectedFolderInfo = '';
      this.currentVideoSubTab = "videos";
      this.getAllVideos(true);
    }
  }

  currentVideoSubTab:any = "videos";
  changeVideoTab(tab: any) {
    this.removeFolderIdFromURL();
    //console.log("tab ==> ", tab);
    this.dashboardService.setActivePage = tab;
    this.currentVideoSubTab = tab;
    this.getAllVideos(true);
  }

  removeFolderIdFromURL() {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { folderId: null },  // Set folderId to null to remove it
      queryParamsHandling: 'merge'  // Retain other existing query parameters
    });
  }

  onBadgeTypeChange(type) {
    this.currentBadgeType = type;
    this.getBadges(true);
  }

  onFilterGroup(i) {
    this.currentGroupIndex = i;
    this.getAllVideos(true);
  }

  // video upload end
  openUploadLinkDialog() {
    const dialogRef = this.dialog.open(UploadLinkDialogComponent, {
      width: "65%",
      minHeight: "400px",
      panelClass: "my-dialog",
      height: "auto",
      data: {
        allVideos: null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllVideos(true);
      }
    });
  }

  openUploadVideo() {
    let obj = {
      isAIEditor: this.currentVideoSubTab === "ai-editor-videos",
      folderId: this.selectedFolderInfo?._id ? this.selectedFolderInfo?._id : undefined,
      dropboxValue: this.dropboxParamsValue ? this.dropboxParamsValue : undefined
    }
    const dialogRef = this.dialog.open(RecordVideoComponent, {
      disableClose: true,
      width: "100%",
      maxWidth: "800px",
      height: "auto",
      data: obj,
      panelClass: 'recored-section',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        if(this.selectedFolderInfo){
          this.getFolderData(this.selectedFolderInfo?._id, false);
        }else{
          this.getAllVideos(true);
            // this.apiService.getDoNotShowItem({item: 'VideoEditorPopUp'}).subscribe((res) => {
            //   if(res.data?.length === 0){
            //     $('#editVideoInformationModal').modal('show');
            //   }
            // }) 
        }
      }
    });
  }


  onChangeForShowModal(value:any){
    this.apiService.setDoNotShowItem({item: 'VideoEditorPopUp'}).subscribe()
  }

  redirectToEditPage(){
    this.router.navigate(['video-editor'], {queryParams: {video: this.allVideos[0]._id, redirect: 'library'}})
  }

  openUploadAudioDialog() {
    const dialogRef = this.dialog.open(RecordVideoComponent, {
      disableClose: true,
      width: "100%",
      maxWidth: "800px",
      height: "auto",
      panelClass: 'recored-section',
      data: {
        isAudio: true,
        skipTranscribing: true,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getAllVideos(true);
      }
    });
  }

  updateTitle(newName, video: any) {
    
    if (!newName) { 
      this.spinner = false;
      this.toaster.error("Name must be required!")
      return;
    }
    
    if (video.name !== newName) {
      const obj = {
        videoID: video._id,
        name: newName,
      };
      this.apiService.updateUploadedVideoName(obj).subscribe((res) => {
        video.name = newName;
        if(video?.isFolder){
          this.toaster.success("Folder name updated successfully!");
        }else{
          this.toaster.success("Title updated successfully!");
        }
      });
    }
  }

  openCreateBadge(badge?) {
    this.selectedEarnBadge = badge;
    if (badge && this.currentBadgeType === "earned") {
      $("#earnedBadgeModal").modal("show");
      return;
    }
    const dialogRef = this.dialog.open(CreateBadgeComponent, {
      width: "100%",
      maxWidth: "1000px",
      height: "auto",
      autoFocus: false,
      data: {
        badge,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.currentBadgeType = "creation";
        this.getBadges(true);
      }
    });
  }

  openCreateQuestionnaire(questionnaire?) {
    const dialogRef = this.dialog.open(CreateQuestionnaireComponent, {
      width: "100%",
      maxWidth: "1000px",
      height: "auto",
      panelClass: "questionnaries-section",
      autoFocus: false,
      data: {
        questionnaire,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getQuestionnaire(true);
      }
    });
  }

  onSelectFile(event: any, id: string) {
    const file = event.target.files[0];
    if (file.size > 10485760) {
      this.toaster.error("File size should be less than 10MB");
      return;
    }
    if (file) {
      const fileType = file.type;
      if (
        fileType === "image/jpeg" ||
        fileType === "image/jpg" ||
        fileType === "image/png"
      ) {
        const img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
          if (img.width < 400 || img.height < 150) {
            this.toaster.info("Minimum image size should be 400 x 150");
          } else {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("id", id);
            this.spinner = true;
            this.apiService.uploadWebsiteURLThumbnail(formData).subscribe(
              (res) => {
                this.toaster.success("thumbnail updated successfully !");
                this.getAllVideos(true);
                this.spinner = false;
              },
              (err) => {
                if (err) {
                  this.spinner = true;
                  this.toaster.error(err.error.data);
                }
              }
            );
          }
        };
      } else {
        this.toaster.info("Only jpeg and png files allowed");
      }
    }
  }

  public downloadVideo(video) {
    const payload = {
      videoId: video._id,
      type: "library",
    };

    this.apiService.getOriginalUploadedVideoUrl(payload).subscribe((res) => {
      const filePath = res.data.videoSourceUrl;

      const isInternetExplorer = this.isIE || !!document.DOCUMENT_NODE;

      const pos = video.fullName.lastIndexOf(".");
      const fileExt = video.fullName.substring(pos);

      const fileName = video.name + fileExt;

      if (isInternetExplorer) {
        window.open(filePath, fileName);
      } else {
        const link = document.createElement("a");
        link.download = fileName;
        link.href = filePath;

        link.click();
      }
    });
  }

  onFileChangeEvent(event: any) { 
    this.spinner = true;   
    let fileList: FileList = event.target.files;    

    if(fileList[0]?.type !== 'application/pdf'){
      this.spinner = false;
      this.toaster.error("You can upload only PDF file!")
      return
    }

    const payloadObj = {
      name: fileList[0].name,
      type: fileList[0]?.type,
      URL: null,
      duration: 0,
      thumbnail: "",
      size: 0,
      originalName: fileList[0].name,
      isAIEditorVideo: false,
    };


    this.apiService.createpresignedUrlV2(payloadObj).subscribe((success) =>{
      
      this.apiService.uploadDocument( success.data.details, fileList[0]).subscribe(() => {
        this.spinner = false;
        this.getAllVideos(true);
      }, (error) => {        
        this.toaster.info('Error!', error);
    });

    }, (error) => {
      // console.log("error ===> ", error)
    }
    )
  }

  openDoc(url:any){
    window.open(url, '_blank');
  }

  getFolderInfo(folderId:any) {
    this.apiService.getFolderInfo({ folderId: folderId }).subscribe((res: any) => {
      this.spinner = true;
      this.folderVideoCount = 0;
      this.selectedFolderInfo = res.data
      this.spinner = true;
      this.isDisplayFolderInformation = true;
      this.folderVideoList = [];
      this.page = 1;

      this.getVideoCountByFolderId(folderId);
      this.getFolderData(folderId, true);
    }, (error) => {
      this.toaster.error(error.message)
    })
  }

  openFolder(folder:any){
    this.spinner = true;

    if (this.selectedFolderInfo?._id || this.selectedFolderInfo?._id !== undefined) {
      this.getVideoCountByFolderId(folder?._id);
      this.getFolderData(folder?._id, false);
    }

    this.folderVideoCount = 0;
    this.selectedFolderInfo = folder
    this.spinner = true;
    this.isDisplayFolderInformation = true;
    this.folderVideoList = [];
    this.page = 1;

    // Add folderId as a query parameter in the URL
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { folderId: folder?._id },
      queryParamsHandling: 'merge'
    });

  
  }

  getFolderData(id:any, reset?){
    if(!reset){
      this.spinner = true;
    }
    let obj:any = {
      folderId : id,
      limit: this.limit,
      page: this.page,
      sort : this.sortBy
    }

    if (this.searchText) {
      obj.search = this.searchText;
    }

    this.apiService.getVideoListByFolderId(obj).subscribe((res) => {
      // console.log("res ===> ", res.data);
      // console.log("reset ===> ", reset);
      
      this.spinner = false;

      if (!reset){
        this.folderVideoList = res.data
      }else{
        this.folderVideoList.push(...res.data)
      }
    })
  }

  getVideoCountByFolderId(id:any) {
    let obj: any = {
      folderId: id
    }

    if (this.searchText) {
      obj.search = this.searchText;
    }

    this.apiService.getVideoCountByFolderId(obj).subscribe((res) => {
      this.folderVideoCount = res.data
    })
  }

  openModalforaddFolder(){
    $('#modalForAddFolder').modal('show');
    this.folderName = '';
  }

  btnAddFolderName(){
    this.spinner = true;
    if(!this.folderName){
      this.spinner = false;
      this.toaster.error("Folder Name must be required!")
      return;
    }
    this.apiService.addFolder({name: this.folderName}).subscribe((res) => {
      this.toaster.success("Folder created successfully!")
      this.getAllVideos(true);
      $('#modalForAddFolder').modal('hide');
      this.isFolderAddVideos = false;
      this.spinner = false;
    })
  }

  isFolderAddVideos:boolean = false;
  allVideoListModal(){
    this.isFolderAddVideos = true;
    this.getAllVideos(true);
    $("#modalForDisplayVideoList").modal('show')
    this.selectedVideoIdForAddingFolder = [];
  }

  selectVideoForFolder(event:any){
    let value = event.target.value
    if(this.selectedVideoIdForAddingFolder.includes(value)){
      this.selectedVideoIdForAddingFolder = this.selectedVideoIdForAddingFolder.filter((i:any) => i !== value)
    }else{
      this.selectedVideoIdForAddingFolder.push(value)
    }
  }

  btnAddVideosToFolder(){
    this.spinner = true;
    let payload = {
      videoIds: JSON.stringify(this.selectedVideoIdForAddingFolder),
      folderId: this.selectedFolderInfo?._id
    }
    this.apiService.addVideoToFolder(payload).subscribe((res) =>{
      this.toaster.success("Videos added successfully!")
      this.openFolder(this.selectedFolderInfo)
      this.spinner = false;
      $("#modalForDisplayVideoList").modal('hide')
    })
  }

  moveVideosToFolder(folder:any){
    this.spinner = true;
    this.folderVideoList = [];
    this.page = 1;
    this.getFolderData(folder?._id, false);
    $('#modalForMoveVideoListToFolder').modal('show')
  }

  
  removeVideoToFolder(event:any){
    let value = event.target.value
    if(this.selectedVideoIdForDeletingFolder.includes(value)){
      this.selectedVideoIdForDeletingFolder = this.selectedVideoIdForDeletingFolder.filter((i:any) => i !== value)
    }else{
      this.selectedVideoIdForDeletingFolder.push(value)
    }
  }
  
  btnMoveVideosToFolder(){
    this.spinner = true;
    this.apiService.removeVideosFromFolder({videoIds: JSON.stringify(this.selectedVideoIdForDeletingFolder)}).subscribe((res)=>{
      this.toaster.success("Videos have been moved from the folder.")
      this.spinner = false;
      $('#modalForMoveVideoListToFolder').modal('hide')
      this.getAllVideos(true);
    })
  }

  generatePdf() {
    const docDefinition = {
      content: [
        { text: 'Hello, world!', style: 'header' },
        'Here is some regular content...',
        { text: 'Here is a list:', style: 'subheader' },
        {
          ol: [
            'Item 1',
            'Item 2',
            'Item 3'
          ]
        }
      ],
      styles: {
        header: {
          fontSize: 22,
          bold: true
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 20, 0, 8] // left, top, right, bottom
        }
      }
    };
    // pdfMake.createPdf(docDefinition).open();
    pdfMake.createPdf(docDefinition).download();
  }

  isShowObjective:boolean = false;
  selectedHoverBadge:any

showObjectivesPopup(data: any) {
    this.selectedHoverBadge = data
    this.isShowObjective = true;
}

hideObjectivesPopup() {        
    if(!this.isShowObjective){
        this.selectedHoverBadge = null;
    }
}

showPopupWithObjective(){
    this.isShowObjective = true
}

hidePopupWithObjective(){
    this.isShowObjective = false
    this.selectedHoverBadge = null;
}

foldersData:any[] = []
selectFolderIdForMoveVideo:any;
selectedVideoForForMoveFolder:any;


openfoldersModal(video:any){
  this.selectedVideoForForMoveFolder = video;
  this.selectFolderIdForMoveVideo = '';
  $('#foldersModal').modal('show');
  this.spinner = true;
  this.getFolder();
}

getFolder(){
  this.foldersData = [];
  this.apiService.getFolder().subscribe((res) => {
    // console.log("res", res.data);
    this.spinner = false;
    this.foldersData = res.data;
  })
}

FolderForMoveVideo(folder:any){
  this.selectFolderIdForMoveVideo = folder._id
}

btnMoveVideoToFolder(){
  this.spinner = true;
  let payload = {
    videoId: this.selectedVideoForForMoveFolder._id,
    folderId: this.selectFolderIdForMoveVideo
  }

  this.apiService.addVideoToFolder(payload).subscribe((res) =>{
    this.toaster.success("Videos Moved successfully!")
    this.spinner = false;
    this.getAllVideos(true);
    if(this.selectedFolderInfo?._id){
      this.getFolderData(this.selectedFolderInfo?._id, false)
    }
    $("#foldersModal").modal('hide')
    this.foldersData = [];
  })
}

}
