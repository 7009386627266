import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {GroupVideo} from './group.model';

const API_URL = environment.API_URLV2;

interface GroupResponse {
  error: boolean;
  message: string;
  data: GroupVideo[];
}

@Injectable({providedIn: 'root'})
export class GroupService {
  private _groupVideos = new BehaviorSubject<GroupVideo[]>([]);

  get groupVideos() {
    return this._groupVideos.asObservable();
  }

  constructor(private http: HttpClient) {}

  getGroupCompletionRate(groupId: string, limit: number, page: number) {
    return this.http
        .get<GroupResponse>(
            `${API_URL}/users/getGroupCompletionRate?groupId=${groupId}&limit=${limit}&page=${page}`
        )
        .pipe(
            map((groupVideoData) => {
              return {
                groupVideos: groupVideoData.data.map((groupVideo) => {
                  const completedCount = groupVideo.groupMembersInfo.filter(m => m.isCompleted);
                  const totalMembers = groupVideo.groupMembersInfo.length;
                  const completionRate = completedCount.length !== 0 ? (completedCount.length / totalMembers) : 0;
                  return {
                      _id: groupVideo._id,
                      videoInfo: groupVideo.videoInfo,
                      groupMembersInfo: groupVideo.groupMembersInfo,
                      viewCompletionBadgeInfo: groupVideo.viewCompletionBadgeInfo,
                      totalMembers,
                      progressCount: completedCount.length,
                      completionRate
                  };
                }),
              };
            }),
            tap((groupData) => {
              this._groupVideos.next(groupData.groupVideos);
            })
        );
  };

  getGroupVideoCompletionRate(groupId: string, videoId: string, limit: number, page: number) {
    return this.http
        .get<GroupResponse>(
            `${API_URL}/users/getGroupVideoCompletionRate?groupId=${groupId}&videoId=${videoId}&limit=${limit}&page=${page}`
        )
        .pipe(
            map((groupVideoData: any) => {
              groupVideoData.data = [groupVideoData.data];
              return {
                groupVideos: groupVideoData.data.map((groupVideo) => {
                  const completedCount = groupVideo.groupMembersInfo.filter(m => m.isCompleted);
                  const totalMembers = groupVideo.groupMembersInfo.length;
                  const completionRate = completedCount.length !== 0 ? (completedCount.length / totalMembers) : 0;
                  return {
                      _id: groupVideo._id,
                      videoInfo: groupVideo.videoInfo,
                      groupMembersInfo: groupVideo.groupMembersInfo,
                      viewCompletionBadgeInfo: groupVideo.viewCompletionBadgeInfo,
                      totalMembers,
                      progressCount: completedCount.length,
                      completionRate
                  };
                }),
              };
            }),
            tap((groupData) => {
              this._groupVideos.next(groupData.groupVideos);
            })
        );
  }
}
