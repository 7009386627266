// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp-code-form .media > img{
    width: 25px;
    height: 25px;
}
.otp-code-form .media-body h5{
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 4px;
}
.otp-code-form .media-body p{
    font-size: 13px;
}
.otp-code-form button.resend-code{
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    font-size: 10px;
    color: #6e5eff;
    height: 100%;
    outline: none;
    margin-right: 4px;
}
.otp-code-form .main_focus_input{
    padding-right: 90px;
}

.video-common-sec {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 2rem 0 4rem 0; */
    padding-block: 120px;
}

/* Responsive */
@media only screen and (max-width: 420px) and (min-width: 320px){
    .video-common-sec {
        padding-block: 112px;
    }
}
@media only screen and (max-width: 390px) and (min-width: 320px){
    .video-common-sec {
        padding-block: 76px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/beforeLogin/otp/otp.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,2BAA2B;IAC3B,uBAAuB;IACvB,YAAY;IACZ,eAAe;IACf,cAAc;IACd,YAAY;IACZ,aAAa;IACb,iBAAiB;AACrB;AACA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,2BAA2B;IAC3B,oBAAoB;AACxB;;AAEA,eAAe;AACf;IACI;QACI,oBAAoB;IACxB;AACJ;AACA;IACI;QACI,mBAAmB;IACvB;AACJ","sourcesContent":[".otp-code-form .media > img{\r\n    width: 25px;\r\n    height: 25px;\r\n}\r\n.otp-code-form .media-body h5{\r\n    font-size: 15px;\r\n    font-weight: 600;\r\n    margin-bottom: 4px;\r\n}\r\n.otp-code-form .media-body p{\r\n    font-size: 13px;\r\n}\r\n.otp-code-form button.resend-code{\r\n    position: absolute;\r\n    right: 0px;\r\n    top: 50%;\r\n    transform: translateY(-50%);\r\n    background: transparent;\r\n    border: none;\r\n    font-size: 10px;\r\n    color: #6e5eff;\r\n    height: 100%;\r\n    outline: none;\r\n    margin-right: 4px;\r\n}\r\n.otp-code-form .main_focus_input{\r\n    padding-right: 90px;\r\n}\r\n\r\n.video-common-sec {\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    /* margin: 2rem 0 4rem 0; */\r\n    padding-block: 120px;\r\n}\r\n\r\n/* Responsive */\r\n@media only screen and (max-width: 420px) and (min-width: 320px){\r\n    .video-common-sec {\r\n        padding-block: 112px;\r\n    }\r\n}\r\n@media only screen and (max-width: 390px) and (min-width: 320px){\r\n    .video-common-sec {\r\n        padding-block: 76px;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
