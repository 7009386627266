import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CrudService} from '../../services/crud.service';
import * as _ from 'lodash';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $: any;
declare var owlCarousel: any;
declare var CanvasJS: any;

@Component({
  selector: 'app-new-landing-page',
  templateUrl: './new-landing-page.component.html',
  styleUrls: ['./new-landing-page.component.css']
})
export class NewLandingPageComponent implements OnInit, OnDestroy {
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  // public spinners:any = { first:false, second:false, third:false };
  spinners: any = { first: false, second: false, third: false, sixth: false }
  todaysTopViewed: any = [];
  trendingVideos: Array<any> = [];
  highlyInteractiveVideos: Array<any> = [];
  latestVideos: Array<any> = [];
  private popularCategories: Array<any> = [];
  top4Categories: Array<any> = [];
  private topChoices: Array<any> = [];
  recommendedVideos: any = []
  allTemVideos: any = { trendingVideos: [], latestVideos: [], recommendedVideos: [] }
  // new Variables

  private finalLoad: any = false
  private watchHistory: Array<any> = [];
  private top3: Array<any> = [];
  private publishedVideos: Array<any> = [];
  viewerChoices: Array<any> = [];
  private showIndicators = true;
  private recentPublished: any;
  private userInfo: any = {};
  private totalMainVideos: any;
  private userID: any;
  private currentuser: any;

  private followers: Array<any> = [];
  recentFollowers: Array<any> = [];
  private following: Array<any> = [];
  recentFollowing: Array<any> = [];
  private topViewedChoices: Array<any> = [];
  private topChoicesLoaded = false;

  private likedVideos: Array<any> = [];
  private sharedVideos: Array<any> = [];
  private activityList: Array<any> = [];
  private sortedActivityList: Array<any> = [];
  index: any = 0;
  private dashboardObj: any = { spinner: false, username: '', bio: '' }
  curentUserIdForLikeDislike: string;
  likeId: any;
  isLiked = false;
  isLoggedIn = true;
  countLikeDisLike = 0;
  allGroupData: any = [];

  constructor(private router: Router,
              private toaster: ToastrService,
              private localStorageService: LocalStorageService,
              private apiService: CrudService) {
    this.currentuser = this.localStorageService.getItem('user');
  }
  myobj: any = [
    { id: 1, name: 'Option-1', img: 'assets/img/angular.png' },
    { id: 1, name: 'Option-2', img: 'assets/img/angular.png' },
    { id: 1, name: 'Option-3', img: 'assets/img/angular.png' },
  ]
  ngOnDestroy() {
    $('body').removeClass('landingPage')
  }
  width: any;
  ngOnInit() {

    this.spinners.first = true;

    $('body').addClass('landingPage')
    this.spinners.second = true;

    if (this.localStorageService.getItem('token')) {
      this.getAllTopVideos();
      this.isLoggedIn = false;
      this.getAllGroups();
      this.getCurrentUserDetail();

      // this.getAllGroupDetail();
    } else {
      if (this.localStorageService.getItem('anonymousToken')) {
        this.getAllTopVideos();
      } else {
        this.getAnonymousUser();
      }
    }
    this.getCurrentWidth();

    //  New Code :
    this.currentuser = this.localStorageService.getItem('user');
    this.spinners.first = true;
    this.spinners.second = true;
    this.spinners.third = true;
    this.spinners.fourth = true;
    this.spinners.fifth = true;
    this.spinners.sixth = true;

    let x = this.localStorageService.getItem('user');
    this.curentUserIdForLikeDislike = x;

  }

  SendLikeEvent() {}

  followersLength: any;
  followinglength: any;
  allUserData: any;
  allCategory: any = []
  getCurrentUserDetail() {
    this.apiService.userDetailsV2().subscribe(
      success => {
        if (success.data.details.category.length < 1) {
          this.router.navigate[('complete-profile')]
          return;
        }
        this.dashboardObj = success.data.details;
        this.dashboardObj.username = this.add3Dots(this.dashboardObj.username, 12);
        if (this.dashboardObj.bio.length > 0)
          this.dashboardObj.bio[0] = this.add3Dots(this.dashboardObj.bio[0], 50);
        //this.dashboardObj.spinner = true;
        this.userID = success.data.details._id;

        this.totalMainVideos = success.data.totalmainvideos;
        this.spinners.first = false;
        this.apiService.getUserInfoV2(this.userID).subscribe(
          success => {
            //console.log('user info is', success);
            this.allUserData = success.data;
            this.followersLength = this.allUserData.followersinfo.length;
            this.followinglength = this.allUserData.followinginfo.length;
            this.allCategory = this.allUserData.userinfo.category;

            this.userInfo = success.data.userinfo;
            this.followers = success.data.followersinfo;
            let arr: Array<any> = _.sortBy(this.followers, ['createdAt']);
            arr = arr.reverse();
            this.recentFollowers = arr;
            this.following = success.data.followinginfo;
            let arr2: Array<any> = _.sortBy(this.following, ['createdAt']);;
            arr2 = arr2.reverse();
            this.recentFollowing = arr2;
            this.publishedVideos = success.data.publishedVideo;

            let sortedArr = _.sortBy(this.publishedVideos, ['updatedAt']);
            this.recentPublished = sortedArr[0];

            // this.getWatchHistory();
            this.getViewerChoices()
            this.spinners.second = false;
          });
      });
  }


  add3Dots(s: any, limit: number): string {
    // var dots = "...";
    // if(s.length > limit) {
    //   s = s.substring(0,limit) + dots;
    // }
    return s;
  }

  getViewerChoices() {
    //viewerChoices

    this.spinners.sixth = true;
    this.apiService.getViewerChoiceHighlight(this.userID).subscribe(
      success => {
        this.viewerChoices = success.data.reverse();
        for (let i of this.viewerChoices) {
          i.publishedVideo.currentLevel = 2;
          if (i.publishedVideo.currentLevel >= i.publishedVideo.totallevel) {
            i.nextDisabled = true;
          }
        }


        this.spinners.sixth = false;
        setTimeout(() => {
          $('.v-choice-carousel').owlCarousel({
            loop: false,
            margin: 15,
            nav: true,
            dots: false,
            mouseDrag: false,
            touchDrag: false,
            pullDrag: false,
            freeDrag: false,
            responsive: {
              0: {
                items: 1,
              },
              600: {
                items: 1,
              },
              1000: {
                items: 3,
              }
            }
          })

          for (let i of this.viewerChoices) {
            i.prevDisabled = true;
            this.plotChilds(i)
          }

        }, 500)

      }, error => { }
    )
  }
  plotChilds(i: any) {
    i.spinner = true;

    i.childVideos = i.childVideos.sort(function (a: any, b: any) {
      return b.viewCount - a.viewCount;
    });

    $('.childv-choice-carousel' + i.publishedVideo._id).owlCarousel('destroy');
    setTimeout(() => {

      $('.childv-choice-carousel' + i.publishedVideo._id).on('initialized.owl.carousel changed.owl.carousel', function (e) {
        if (!e.namespace) {
          return;
        }
        var carousel = e.relatedTarget;
        $('.slider-counter' + i.publishedVideo._id).text('Choices ' + (carousel.relative(carousel.current()) + 1) + ' of ' + carousel.items().length);
      }).owlCarousel({
        items: 1,
        loop: true,
        margin: 0,
        nav: false,
        dots: true,
      });

    }, 1000)
    this.plotchart(i)

  }
  plotchart(i: any) {
    if (!!i.chart) {
      i.chart.destroy();
    }

    let data = []
    let colorData = ['#A649C7', '#EFB43A', '#3DC99C', '#DB6A8E'];
    let index = 0;
    let totalView = 0;
    for (let j of i.childVideos) {
      data.push({ label: j.name, y: j.viewCount, color: colorData[index] })
      totalView = totalView + j.viewCount
      index++;
    }
    i.totalView = totalView;
    if (totalView == 0) {
      i.noViews = true;
    }
    let id = "chartContainer" + i.publishedVideo._id;
    if (!i.noViews) {
      i.chart = new CanvasJS.Chart(id, {
        animationEnabled: true,
        backgroundColor: "#4d0078",
        data: [{
          type: "doughnut",
          startAngle: 0,
          indexLabelFontSize: 0,
          indexLabelPlacement: "inside",
          innerRadius: "70%",
          //innerRadius: 60,

          toolTipContent: "<b>{label}:</b> {y} (#percent%)",
          dataPoints: data
        }]
      });
      i.chart.render();

    }
    i.spinner = false;

  }

  nextVpopularPath(i) {
    if (i.childVideos.length < 1) {
      return;
    }
    i.publishedVideo.currentLevel++;
    if (i.publishedVideo.currentLevel >= i.publishedVideo.totallevel) {
      i.nextDisabled = true;
    }
    i.spinner = true;
    let nectItem = i.childVideos[0]
    this.apiService.getTopChoices(nectItem._id).subscribe(
      success => {

        //i.childVideos
        let correctArray: any = []
        for (let j of success.data) {
          j.video.viewCount = j.viewCount
          correctArray.push(j.video)
        }
        if (correctArray.length < 1) {
          i.nextDisabled = true;
          i.spinner = false;
          return;
        }
        i.childVideos = correctArray;
        i.prevDisabled = false;
        this.plotChilds(i)


        ////console.log('xssd',correctArray)
      })
  }
  preVpopularPath(i) {
    i.spinner = true;
    this.apiService.getTopChoices(i.publishedVideo._id).subscribe(
      success => {

        //i.childVideos
        let correctArray: any = []
        for (let j of success.data) {
          j.video.viewCount = j.viewCount
          correctArray.push(j.video)
        }

        i.childVideos = correctArray;
        i.prevDisabled = true;
        i.nextDisabled = false;
        i.publishedVideo.currentLevel = 2;
        if (i.publishedVideo.currentLevel >= i.publishedVideo.totallevel) {
          i.nextDisabled = true;
        }
        this.plotChilds(i)



        ////console.log('xssd',correctArray)
      })

  }



  posterImg: boolean;
  getCurrentWidth() {
    let width = window.innerWidth;
    this.width = width
    //console.log(width);
    if (width < 991) {
      this.posterImg = false;

    } else {
      this.posterImg = true;

    }
  }
  getAnonymousUser() {
    this.apiService.getAnonymousUser().subscribe(
      success => {
        if (this.localStorageService.getItem('anonymousToken')) {
        } else {
          this.localStorageService.setItem('anonymousToken', success.data.token);
        }
        this.getAllTopVideos();
      }
    );
  }

  getAllTopVideos() {
    this.apiService.getAllTopVideos().subscribe(
      success => {
        ////console.log(success.data);
        this.todaysTopViewed = success.data[0].topVideoList;
        if (!(!!success.data[0].thumbnailadmin)) {
          this.todaysTopViewed.thumbnailadmin = this.todaysTopViewed.poster
        }
        else {
          this.todaysTopViewed.thumbnailadmin = success.data[0].thumbnailadmin
        }
        this.spinners.first = false;
        this.getLandingPageData()

      },
      error => {

      });
  }

  getTopChoices(item) {
    if (this.width > 991) {
      if (!!item.topViewedChoices) {
        return;
      }
      item.spinner = true;

      //WC 03022022
      if (item.id == undefined)
        item.id = item._id;
        
      this.apiService.getTopChoices(item.id).subscribe(
        success => {
          //console.log('Top Choices: ', success.data);
          let topChoices = success.data;
          let totalViews = 0;
          for (let c of topChoices) {
            totalViews = c.viewCount + totalViews;
          }

          for (let c of topChoices) {
            if (totalViews == 0) {
              c.percentage = 0;
              c.progress = 0;
            }
            else {
              c.percentage = this.calculatePercentage(c.viewCount, totalViews);
              c.progress = this.calculatePercentage(c.viewCount, totalViews) + '%';
            }
          }

          item.topViewedChoices = topChoices;
          item.spinner = false;


        },
        error => {

        });
    } else {
      this.showVideo(item.id)
    }

  }


  showVideoChoices(videoId: any) {
    ////console.log(t)
    this.localStorageService.setItem('isChoice', 'true');
    this.router.navigate(['videos'], {queryParams: {video: videoId}});
  }

  calculatePercentage(childViewcount: any, totalViews: any): any {
    let percantage = (childViewcount / totalViews) * 100;
    return Math.round(percantage);
  }

  getLandingPageData() {
    //WC 03022022 calling getrecommendedVideos V2 with pagination
    //this.apiService.getrecommendedVideos().subscribe(

    this.apiService.getrecommendedVideosV2(7, 1).subscribe(
      success => {
        this.spinners.second = false;
        this.recommendedVideos = success.data

        setTimeout(() => {
          $('.reocmends').owlCarousel({
            loop: false,
            margin: 15,
            nav: true,
            dots: false,
            responsive: {
              0: {
                items: 1
              },
              800: {
                items: 1,

              },
              1100: {
                items: 3,

              },
              // 800:{
              //     items:3
              // }
            }
          });
        }, 500);
        this.getTrendingData()
      })

  }


  getTrendingData() {

    //WC 03022022 -- calling gettopViewedVideosV2
    //this.apiService.gettopViewedVideos().subscribe(  
    this.apiService.gettopViewedVideosV2(7,1).subscribe(
      success => {
        this.trendingVideos = success.data;

        //WC 08122022
        // let arrTrending: Array<any> = success.data;
        // arrTrending = _.sortBy(arrTrending, ['viewCount']);
        // arrTrending = arrTrending.reverse();
        // this.trendingVideos = _.take(arrTrending, 5);
        //console.log("this.trendingVideos = ", this.trendingVideos);
        //WC 08122022

        setTimeout(() => {
          $('.trending').owlCarousel({
            loop: false,
            margin: 15,
            nav: true,
            dots: false,
            responsive: {
              0: {
                items: 1
              },
              800: {
                items: 1,

              },
              1100: {
                items: 3,

              },
              // 800:{
              //     items:3
              // }
            }
          });
        }, 500);

        this.getHighlyInteractive();
      })

  }

  allGroups: Array<any> = [];
  isComments = false;
  counter = 0;
  allGroupComments: Array<any> = [];
  groupId: any;
  currentGroupLength:any;
  getAllGroups() {
    // owl-next
    this.apiService.getAllGroups().subscribe(
      success => {
        let groups: Array<any> = success.data;
        // groups = _.sortBy(groups, ['viewCount']);
        // groups = groups.reverse();
        this.allGroups = _.take(groups, 5);
        if(this.allGroups.length > 0){
          this.getAllComments(groups[0])
        }
        this.currentGroupLength = this.allGroups.length;
        //console.log('all group is', this.allGroups);
      })

  }

  
  getAllComments(item: any, index?: any) {

    //console.log('item is', item, 'index is', index);
    this.allGroupComments = [];
    this.groupId = item._id
    this.getAllCommentExtracted(item)
  }
  replyId: any;
  replyView: any;
  reply: any

  onReply(item) {
    this.reply = null;
    this.replyId = item._id
  }

  getReplyId(item) {
    this.replyView = item._id
  }
  viewAllComment() {

  }
  addReplyOfComment(item: any, id) {
    //console.log(item);
    if (!item) {
      this.toaster.error('Please enter reply first.')
    } else {
      let payload = {
        "groupId": this.groupId,
        "commentId": id,
        "comment": item,
        "scenerio": "reply"
      }
      this.apiService.addComment(payload).subscribe(res => {
        //console.log(res);
        this.replyId = false // only for hide comment input

        // this.refreshAllGroupComments(this.groupId);
      })
    }


  }
 currentIndex:any;
  getAllCommentsNext(item: any, index?: any) {
    if(this.currentGroupLength  == index+1){
      this.getAllCommentExtracted(this.allGroups[0])
    }else{
      this.getAllCommentExtracted(this.allGroups[index+1])
    }
  }
  getAllCommentsPre(item: any, index?: any) {
    if(index == 0){
      this.getAllCommentExtracted(this.allGroups[this.currentGroupLength-1])
    }else{
      this.currentIndex = index;
      this.getAllCommentExtracted(this.allGroups[index-1])
    }
  }


  getAllCommentExtracted(item: any, index?: any) {
    this.isComments = true;
    this.apiService.getCommment(item._id).subscribe(res => {
      this.allGroupComments = res.data;
      //console.log('all comments are',this.allGroupComments)
      this.isComments = false;
    })

  }

  refreshAllGroupComments(id: any) {
    this.apiService.getCommment(id).subscribe(res => {
      this.allGroupComments = res.data;
    })
  }
likeProhgrss = true
  likeDislikeComment(id: any, item: any, value: any) {
    //console.log('item is', item.length);
    var x = this.localStorageService.getItem('user');
    this.likeId = id;
    this.curentUserIdForLikeDislike = x;
    this.allGroupComments.forEach(e => {
      if (e.likeCount > 0) {
        this.isLiked = true
      } else {
        this.isLiked = false;
      }
      if (e._id == id && this.isLiked == false && e.likeCount == 0) {
        e.likeCount = e.likeCount + 1
        this.isLiked = true;
      } else if (e._id == id && this.isLiked == true && e.likeCount > 0) {
        if (e.likeCount > 0) {
          e.likeCount = e.likeCount - 1
          this.isLiked = false;
        }
      }
    })

    var scenario;
    var category;
    if (item.length > 0) {
      scenario = 'unlike'
      category = 0;
    } else {
      scenario = 'like'
      category = 1;
    }
    let finalpayload = {
      "ID": this.groupId,
      "category": category,
      "commentID": id,
      "scenario": scenario,
      "type": "comment"
    }
      this.apiService.likeDislikeComment(finalpayload).subscribe(res => {
        // this.refreshAllGroupComments(this.groupId)
      })
  }

  onGroupDetail(item){
    this.router.navigate(['/my-group/'+item._id])
  }

  
  getHighlyInteractive() {

    //WC 08122022 - Calling V2
    //this.apiService.gethighlyInteractiveVideos().subscribe(
    this.apiService.gethighlyInteractiveVideosV2(6,1).subscribe(
      success => {

        // WC 08222022
        // let arrHighlyInt: Array<any> = success.data;
        // arrHighlyInt = _.sortBy(arrHighlyInt, ['totalchild']);
        // arrHighlyInt = arrHighlyInt.reverse();
        // this.highlyInteractiveVideos = _.take(arrHighlyInt, 6);

        this.highlyInteractiveVideos = success.data;

        setTimeout(() => {
          $('.high').owlCarousel({
            loop: false,
            margin: 15,
            nav: true,
            dots: false,
            responsive: {
              0: {
                items: 1
              },
              800: {
                items: 1,

              },
              1100: {
                items: 3,

              },
              // 800:{
              //     items:3
              // }
            }
          });
        }, 100);
        this.getlatestVideo()
      })

  }


  getlatestVideo() {
    // Latest Videos

    //WC 08122022 -- Calling V2
    //this.apiService.getlatestPublishedVideos().subscribe(
      this.apiService.getlatestPublishedVideosV2(7,1).subscribe(  
      success => {
        this.latestVideos = success.data;
        // for (let i of this.latestVideos) {
        //   i.id = i._id
        // }
        setTimeout(() => {
          $('.latest').owlCarousel({
            loop: false,
            margin: 15,
            nav: true,
            dots: false,
            responsive: {
              0: {
                items: 1
              },
              800: {
                items: 1,

              },
              1100: {
                items: 3,

              },
              // 800:{
              //     items:3
              // }
            }
          });
        }, 100);
        //this.gettopCategpries()
      })

  }

  // Popular categories

  // getPopCategpries() {
  //   this.apiService.getpopularCategories().subscribe(
  //     success => {

  //       let arrPopCategories: Array<any> = success.data;
  //       arrPopCategories = _.sortBy(arrPopCategories, ['totalVideos']);
  //       arrPopCategories = arrPopCategories.reverse();
  //       this.popularCategories = arrPopCategories;

  //       for (let cat of this.popularCategories) {
  //         if (cat.categoryName === 'Music' || cat.categoryName === 'Gaming' || cat.categoryName === 'Entertainment' || cat.categoryName === 'Beauty & Fashion') {
  //           this.top4Categories.push(cat);
  //         }
  //       }
  //       for (let t of this.top4Categories) {
  //         if (t.categoryName === 'Music') {
  //           t.subHeading = 'Move that beat';
  //           t.categoryImage = '../../../assets/images/Images for Trending and Landing Page/Landing Page Music.png';
  //         } else if (t.categoryName === 'Gaming') {
  //           t.subHeading = 'Love your game more';
  //           t.categoryImage = '../../../assets/images/Images for Trending and Landing Page/Landing Page Gaming.png';
  //         } else if (t.categoryName === 'Entertainment') {
  //           t.subHeading = 'Movie time';
  //           t.categoryImage = '../../../assets/images/Images for Trending and Landing Page/Landing Page Movies.png';
  //         } else if (t.categoryName === 'Beauty & Fashion') {
  //           t.subHeading = 'I am what I am';
  //           t.categoryImage = '../../../assets/images/Images for Trending and Landing Page/Landing Page Fashion.png';
  //         }
  //       }
  //     },
  //     error => {

  //     });
  // }

  showCategory(id: any) {
    this.router.navigate(['category'], { queryParams: { category: id } });
  }

  // Show video
  showVideo(videoId: any) {
    ////console.log(t)
    this.router.navigate(['videos'], { queryParams: { video: videoId } });
  }

  // Show user profile
  showUser(userId: any) {
    this.router.navigate(['public-profile'], { queryParams: { user: userId } });
  }
}
