// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-transparent {
    background: transparent;
    border: none;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--purple-1);
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
}

.link-preview {
    max-height: 400px;
    width: 100%;
    object-fit: contain;
}

.ev-process {
    text-align: center;
    position: relative;
    margin-top: 1rem;
    border: 1px dotted black;
    border-radius: 10px;
    padding: 28px 15px;
}
`, "",{"version":3,"sources":["webpack://./src/app/afterLogin/createInteractiveVideo/edit-video/edit-video.component.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;IACnB,wCAAwC;IACxC,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,wBAAwB;IACxB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".form-transparent {\n    background: transparent;\n    border: none;\n    padding-bottom: 5px;\n    border-bottom: 1px solid var(--purple-1);\n    margin-bottom: 10px;\n    text-align: center;\n    width: 100%;\n}\n\n.link-preview {\n    max-height: 400px;\n    width: 100%;\n    object-fit: contain;\n}\n\n.ev-process {\n    text-align: center;\n    position: relative;\n    margin-top: 1rem;\n    border: 1px dotted black;\n    border-radius: 10px;\n    padding: 28px 15px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
