// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bg {
    height: 100%;
    margin-top: 63px;
    min-height: 90vh;
    background-color: #6c00c2;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: fixed;
}

.main-img {
    height: 359px;
    width: 100%;
    object-fit: contain;
}

/*WC 04062022*/
/*.col-md-7 p {
    color: white;
    font-size: 25px;
    text-align: justify;
}*/

.col-md-7 p {
    /*WC 04062022*/
    /*color: white;*/
    color:darkmagenta;
    /*font-size: 25px;*/
    font-size: 20px;
    text-align: justify;
}

.container-fluid h3 {
    text-align: center;
    margin-bottom: 36px;
    color: white;
    font-size: 35px;
    font-weight: 600;
}

@media(max-width: 991px) {
    .bg {
        position: static;
    }
    .container-fluid h3 {
        text-align: center;
        margin-bottom: -33px;
        color: white;
        font-size: 25px;
        font-weight: 600;
    }
    .col-md-7 p {
        color: white;
        font-size: 16px;
        text-align: justify;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/beforeLogin/about/about.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;AACvB;;AAEA,cAAc;AACd;;;;EAIE;;AAEF;IACI,cAAc;IACd,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI;QACI,gBAAgB;IACpB;IACA;QACI,kBAAkB;QAClB,oBAAoB;QACpB,YAAY;QACZ,eAAe;QACf,gBAAgB;IACpB;IACA;QACI,YAAY;QACZ,eAAe;QACf,mBAAmB;IACvB;AACJ","sourcesContent":[".bg {\r\n    height: 100%;\r\n    margin-top: 63px;\r\n    min-height: 90vh;\r\n    background-color: #6c00c2;\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    overflow: hidden;\r\n    position: fixed;\r\n}\r\n\r\n.main-img {\r\n    height: 359px;\r\n    width: 100%;\r\n    object-fit: contain;\r\n}\r\n\r\n/*WC 04062022*/\r\n/*.col-md-7 p {\r\n    color: white;\r\n    font-size: 25px;\r\n    text-align: justify;\r\n}*/\r\n\r\n.col-md-7 p {\r\n    /*WC 04062022*/\r\n    /*color: white;*/\r\n    color:darkmagenta;\r\n    /*font-size: 25px;*/\r\n    font-size: 20px;\r\n    text-align: justify;\r\n}\r\n\r\n.container-fluid h3 {\r\n    text-align: center;\r\n    margin-bottom: 36px;\r\n    color: white;\r\n    font-size: 35px;\r\n    font-weight: 600;\r\n}\r\n\r\n@media(max-width: 991px) {\r\n    .bg {\r\n        position: static;\r\n    }\r\n    .container-fluid h3 {\r\n        text-align: center;\r\n        margin-bottom: -33px;\r\n        color: white;\r\n        font-size: 25px;\r\n        font-weight: 600;\r\n    }\r\n    .col-md-7 p {\r\n        color: white;\r\n        font-size: 16px;\r\n        text-align: justify;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
