import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CrudService} from '../../services/crud.service';
import {DashboardService} from '../../services/dashboard.service';
import {LocalStorageService} from '../../services/localStorage.service';

@Component({
  selector: 'app-followers',
  templateUrl: './followers.component.html',
  styleUrls: ['./followers.component.css']
})
export class FollowersComponent implements OnInit {

  followerList: Array<any> = []
  currentUser: any;
  spinner: any = false;
  private userId: any;
  private mainID: any;
  private page = 1;

  constructor(
      private dashboardService: DashboardService,
      private crudService: CrudService,
      private router: Router,
      private activeRoute: ActivatedRoute,
      private localStorageService: LocalStorageService,
  ) {
    this.activeRoute.queryParams.subscribe(params => {
      this.userId = params.user;
      this.mainID = params.user;
    });
  }

  ngOnInit() {
    this.spinner = true;
    this.currentUser = this.localStorageService.getItem('user')
    this.getFollowers();
  }

  onScroll(): void {
    ++this.page;

    this.getFollowers();
  }

  getFollowers() {

    this.crudService.getFollowers( 10, this.page,this.userId).subscribe(success => {

      const followers = success.data.followers;

      for (const item of followers) {
        this.followerList.push(item);
      }

      this.spinner = false;
    })
  }

  updateFollowings(id: number, activity: string) {
    this.dashboardService.onGetUserData();
    for (const item of this.followerList) {
      if (item.followedBy === id) {
        if (activity === 'follow') {
          ++item.followto_noOfFollowers;
          item.followedByMe = true;
          break;
        } else {
          --item.followto_noOfFollowers;
          item.followedByMe = false;
          break;
        }
      }
    }
  }


  // Follow back
  followBack(id: any) {
    const obj: any = {};
    obj.type = 'follow';
    obj.followToUserID = id;
    this.crudService.followUser(obj).subscribe(success => {
      this.updateFollowings(obj.followToUserID, obj.type)
    });
  }

  // unFollow back
  unfollowBack(id: any) {
    const obj: any = {};
    obj.type = 'unfollow';
    obj.followToUserID = id;
    this.crudService.followUser(obj).subscribe(success => {
      this.updateFollowings(obj.followToUserID, obj.type)
    });
  }

  viewPublicProfile(id: any) {
    this.router.navigate(['/public-profile'], { queryParams: { user: id } });
  }

}
