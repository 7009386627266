import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {CrudService} from '../../services/crud.service';
import {DashboardService} from '../../services/dashboard.service';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $;

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit, OnDestroy {
    isSpinner = false;
    isMobileMenuShow = false;
    isLoggedIn = true;
    isColumnAreaShow = false;
    subscription: Subscription;
    userData: any;
    isLoading = false;
    isMiniSidebar: any;
    isGroup = false;
    isVideos = false;
    isCommunity = false;
    userDataWithMe:any;
    isActiveProfile:boolean = false;
    coBrandingLogo:any;

    constructor(
        private readonly crudService: CrudService,
        private readonly router: Router,
        private readonly dashboardService: DashboardService,
        private readonly localStorageService: LocalStorageService,
    ) { 
        this.dashboardService.coBrandingLogo$.subscribe(logo => {
            this.coBrandingLogo = logo;
        });  

        var url = window.location.pathname;
        let searchUrl = window.location.search;
        if (url.includes('my-group') || searchUrl.includes('groupType')) {
            this.isGroup = true;
        }

        if (url.includes('questionnaires') || url.includes('my-videos') || searchUrl.includes('videoType')) {
            this.isVideos = true;
        }

        if(url.includes('community') || url.includes('staaj-health')) {
            this.isCommunity = true;
        }

        if (url.includes('profile') || url.includes('user-activity') || searchUrl.includes('userType')) {
            this.isActiveProfile = true;
        }

        this.crudService.userDetailsV2().subscribe(success => {
            this.userDataWithMe = success.data.details;
                this.crudService.HubSpotsubject$.next(success.data?.details);
        })

            this.crudService.HubSpotsubject$.subscribe((res)=>{
                this.userDataWithMe = res                
            })

    }

    ngOnInit(): void {
        this.isSpinner = true;
        this.isLoggedIn = !JSON.parse(this.localStorageService.getItem('logout'));
        this.subscription = this.dashboardService.columnAreaShow.subscribe(
            (val) => {
                this.isColumnAreaShow = val;
            }
        );
        this.subscription = this.dashboardService.mobileMenuShow.subscribe(
            (val) => {
                // console.log(val)
                this.isMobileMenuShow = val;
            }
        );
        this.subscription = this.dashboardService.miniSidebar.subscribe(
            (val) => {
                this.isMiniSidebar = val;
            }
        );

        this.dashboardService.getUserData.subscribe(
            (res) => {
                if (res) {
                    this.userData = res;
                    this.isSpinner = false;
                    // console.log("this.userData = ", this.userData);
                }
            }).add(() => this.isLoading = false)
    }

    onClickMobileMenu() {
        this.dashboardService.onClickMobileMenu(false);
    }

    onToggleSidebar() {
        this.dashboardService.onToggleSidebar(!this.isMiniSidebar);
    }

    onClickSkip() {
        this.dashboardService.onClickSkipImage();
        this.router.navigate(['/profile']);
    }

    onClickLogout() {
        this.isLoading = true;
        this.subscription = this.crudService.logout('').subscribe(
            (success) => {
                this.isLoading = false;
                this.localStorageService.clear();
                this.localStorageService.setItem('logout', 'true');
                window.sessionStorage.clear();
                this.dashboardService.resetSkipImage();
                this.dashboardService.clearUserData();
                this.router.navigate(['/']);
                this.dashboardService.coBrandingLogoSubject.next(null);
            },
            (error) => (this.isLoading = false)
        );
    }

    onClickEarnOnFF() {
        if (this.router.url.indexOf('/onboarding') === -1)
            this.router.navigate(['/onboarding']);
        else {
            if (!this.userData.agreeToTerms)
                window.location.reload();     // To show the Earn on FF Agreement
        }
    }

    upgradeSubscription() {

        this.router.navigate(['/pricing'], {queryParams: {subId: this.userData.subscriptionId}});
    }


    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    becomeCreator() {
        const MonthlyFreemiumPlanId = '6310da4c16d7473718a9ce79';

        const payload = {
            planId : MonthlyFreemiumPlanId,
          };

          this.crudService.setUserSubscriptionPlan(payload).subscribe(success => {

            this.localStorageService.setItem('isSubscriber', 'true');
            // console.log("successfully set subscriber status");

            this.dashboardService.clearUserData();
            this.dashboardService.onGetUserData();

            $('#successful-become-creator-Modal').modal('show');

          });
    }

}
