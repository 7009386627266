import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userFilter'
})
export class UserFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }
    const searchLower = searchText.toLowerCase();
    return items.filter(item => {
      return Object.values(item.user).some(value =>
        String(value).toLowerCase().includes(searchLower)
      );
    });
  }

}
