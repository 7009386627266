import {Component, OnInit} from '@angular/core';

declare var $;

@Component({
    selector: 'app-delete-confirmation',
    templateUrl: './delete-confirmation.component.html',
    styleUrls: ['./delete-confirmation.component.css']
})
export class DeleteConfirmationComponent implements OnInit {

    header = 'Delete';
    title = 'Are you sure? Do you want to delete this record?';
    trueButton = 'Delete';
    falseButton = 'Cancel';
    confirmation = false;
    modalId = '';
    private dmRef: any;

    constructor() {
        this.modalId = 'deleteModal' + Math.round(Math.random() * 100 * 100);
    }

    ngOnInit(): void {
    }

    open(options: { header?: string, title?: string, trueButton?: string, falseButton?: string }, callback) {
        this.header = 'Delete';
        this.title = 'Are you sure? Do you want to delete this record?';
        this.trueButton = 'Delete';
        this.falseButton = 'Cancel';
        this.confirmation = false;
        if (options.header) {
            this.header = options.header;
        }
        if (options.title) {
            this.title = options.title;
        }
        if (options.trueButton) {
            this.trueButton = options.trueButton;
        }
        if (options.falseButton) {
            this.falseButton = options.falseButton;
        }
        this.dmRef = $(`#${this.modalId}`);
        this.dmRef.modal('show');
        this.dmRef.unbind();
        this.dmRef.on('hidden.bs.modal', () => {
            callback(this.confirmation)
        });
    }

    submit(b: boolean) {
        this.confirmation = b;
        this.dmRef.modal('hide');
    }
}
