// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-footer-sec {
  margin-top: 30px;
  margin-bottom: 30px;
}

.video-footer-sec > div {
  text-align: center;
}

.video-footer-sec > div a {
  margin: 0px 10px;
  font-size: 12px;
  color: #3c4858;
}

@media only screen and (max-width: 992px) {
  .video-footer-sec {
    margin-bottom: 70px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/layout/footer/without-login/without-login.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,cAAc;AAChB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".video-footer-sec {\n  margin-top: 30px;\n  margin-bottom: 30px;\n}\n\n.video-footer-sec > div {\n  text-align: center;\n}\n\n.video-footer-sec > div a {\n  margin: 0px 10px;\n  font-size: 12px;\n  color: #3c4858;\n}\n\n@media only screen and (max-width: 992px) {\n  .video-footer-sec {\n    margin-bottom: 70px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
