import { Component, OnInit, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CrudService } from 'src/app/services/crud.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../services/localStorage.service';

declare var Stripe;

@Component({
  selector: 'subscription-change-confirmation',
  templateUrl: './subscription-change-confirmation.component.html',
  styleUrls: ['./subscription-change-confirmation.component.css'],
  // [fadeInOutAnimation]
})

export class SubscriptionChangeConfirmationComponent implements OnInit {

  private stripe = Stripe(environment.Stripe_PK);
  spinner = false;
  upgradePlanId = '';
  currentSubscriptionId = '';
  public invoiceLineItems: any = [];
  public totalProratedAmount = 0;
  public updatedPayPriceId = '';
  public payCustomerSubscriptionId = '';

  constructor(private urlService: CrudService,
              private router: Router,
              private route: ActivatedRoute,
              private localStorageService: LocalStorageService,
              @Inject(DOCUMENT) private document,
              private toastr: ToastrService) {

    this.route.queryParams.subscribe(params => {
      //console.log('params.upgradePlanId = ', params.upgradePlanId);

      this.upgradePlanId = params.upgradePlanId;
    });

  }

  ngOnInit(): void {

    this.spinner = true;

    this.currentSubscriptionId = this.localStorageService.getItem('upgrade_CurrentSubscriptionId');
    //console.log('this.currentSubscriptionId = ', this.currentSubscriptionId);

    this.urlService.getPlanInfo(this.upgradePlanId).subscribe(success=> {

      this.updatedPayPriceId = success.data.paymentPriceId;

      this.urlService.getUserSubscriptionPlanById(this.currentSubscriptionId).subscribe(success => {

        this.payCustomerSubscriptionId = success.data.payCustomerSubscriptionId;

        this.urlService.getProrationPreviewInvoice(this.payCustomerSubscriptionId, this.updatedPayPriceId).subscribe(success => {
            const data = success.data.lines.data;
            //console.log('data = ', data);

            this.invoiceLineItems = [];
            this.totalProratedAmount = 0;

            for (let i=0; i < data.length; i++) {
              if (data[i].type == 'invoiceitem') {
                data[i].period.start = this.convertUnixTimeStampToMMDDYYYY(data[i].period.start);
                data[i].period.end = this.convertUnixTimeStampToMMDDYYYY(data[i].period.end)
                this.invoiceLineItems.push(data[i]);
                this.totalProratedAmount += data[i].amount;

              }
            };

            //console.log('this.invoiceLineItems = ', this.invoiceLineItems);
            //console.log('this.totalProratedAmount= ', this.totalProratedAmount);
        });
      })

    });

    this.spinner = false;
  }

  convertUnixTimeStampToMMDDYYYY = (unixTimeStamp: any) => {
    let date = unixTimeStamp;
    if (unixTimeStamp) {
        date = new Date(unixTimeStamp * 1000).toLocaleDateString('en-US');
    }
    return date;
  };

  upgradeSubscription() {

    const payload = {
      payCustomerSubscriptionId: this.payCustomerSubscriptionId,
      updatedPaymentPriceId: this.updatedPayPriceId
    }

    // console.log("payload = ", payload);

    this.urlService.updatePaySubscription(payload).subscribe(success => {
      // console.log("success = ", success);

      if (!success.error) {
        this.toastr.success(success.message);

        this.router.navigate(['profile']);
      } else {
        this.toastr.error(success.message);
      }
    })
  };

}
