// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    border: none;
}

.main-textarea, select, select:focus {
    border: none;
    max-height: 500px;
    border-radius: 8px;
    background: #f9f8fb;
}

textarea:focus, select:focus {
    outline: none;
    box-shadow: none;
}

.text-center-small {
    font-weight: 400;
    font-size: 16px;
    color: #0f062b;
    margin-top: 30px;
    text-align: left;
}

.invalid-feedback {
    display: block;
}

.email-preview {
    white-space: pre-line;
}
@media (max-width: 420px) {
    .video-common-btn {
        font-size: 14px;
        padding: 8px 10px !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/afterLogin/group/contact-group/contact-group.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB;AACA;IACI;QACI,eAAe;QACf,4BAA4B;IAChC;AACJ","sourcesContent":[".card {\n    border: none;\n}\n\n.main-textarea, select, select:focus {\n    border: none;\n    max-height: 500px;\n    border-radius: 8px;\n    background: #f9f8fb;\n}\n\ntextarea:focus, select:focus {\n    outline: none;\n    box-shadow: none;\n}\n\n.text-center-small {\n    font-weight: 400;\n    font-size: 16px;\n    color: #0f062b;\n    margin-top: 30px;\n    text-align: left;\n}\n\n.invalid-feedback {\n    display: block;\n}\n\n.email-preview {\n    white-space: pre-line;\n}\n@media (max-width: 420px) {\n    .video-common-btn {\n        font-size: 14px;\n        padding: 8px 10px !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
