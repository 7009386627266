import {Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import {CrudService} from '../../../services/crud.service';
import {environment} from '../../../../environments/environment';
import {UploadLinkDialogComponent} from './upload-link-dialog/upload-link-dialog.component';
import {UtilityService} from '../../../services/utility.service';
import {Router} from '@angular/router';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {PopoverDirective} from 'ngx-bootstrap/popover';
import {DashboardService} from '../../../services/dashboard.service';
import {RecordVideoComponent} from '../record-video/record-video.component';
import {takeUntil} from 'rxjs/operators';
import languages from '../../../services/languages';
import {Subject} from 'rxjs';
import {CreateBadgeComponent} from '../create-badge/create-badge.component';
import {CreateQuestionnaireComponent} from '../create-questionnaire/create-questionnaire.component';
import {LocalStorageService} from '../../../services/localStorage.service';

declare var $: any;

interface MatDataType {
  treeNodes?: any,
  connectorNodes?: any,
  nodes?: any,
  links?: boolean,
  videos?: boolean,
  audios?: boolean,
  badges?: boolean,
  questionnaire?: boolean,
  skipTranscribing?: any,
  origin?: any,  //origin of the request to open the create-video popup, could be from the video collab
  isAddChoice?: boolean
  isAddPath?: boolean
}

@Component({
  selector: 'app-create-video',
  templateUrl: './create-video.component.html',
  styleUrls: ['./create-video.component.css'],
})
export class CreateVideoComponent implements OnInit, OnDestroy {
  @ViewChild('popCreateBadgeEle') popCreateBadgeEle: PopoverDirective;
  @ViewChild('popCreateQuestionnaireEle') popCreateQuestionnaireEle: PopoverDirective;
  @ViewChild('popProcessedVideoEle') popProcessedVideoEle: PopoverDirective;
  @ViewChild('popUploadVideoEle') popUploadVideoEle: PopoverDirective;
  @ViewChild('popUploadAudioEle') popUploadAudioEle: PopoverDirective;
  @ViewChild('audioElement') audioElement: ElementRef;

  spinner = false;
  treeNodes = [];
  connectorNodes = [];
  nodes = [];
  allVideos = [];
  allBadges = [];
  allQuestionnaire = [];
  searchText = '';
  selectedValue = 'recent';
  limit = 12;
  page = 1;
  sort = '';
  distance = 2;
  throttle = 0;
  totalVideoCount = 0;
  totalUrlCount = 0;
  totalAudioCount = 0;
  totalDocumentCount = 0;
  totalBadgeCount = 0;
  totalQuestionnaireCount = 0;
  aiEditorVideo: 'no' | 'yes' = 'no';
  cvCurrentTab: 'videos' | 'links' | 'nodes' | 'audios' | 'badges' | 'questionnaire' | 'connectorNodes' | 'documents' | 'treeNodes' = 'videos';
  // searchChange$ = new BehaviorSubject('');
  isPreviewLoading = false;
  // linkPreviewData: any;
  isTranscribing = false;

  uploadVideoObject: any = {
    spinner: false,
    allVideos: [],
    selectedVideos: [],
    format: '',
    url: '',
    isUploaded: false,
    duration: 0,
    name: '',
    type: '',
    size: '',
    preSignedUrl: '',
    file: [],
    allUploadedVideo: [],
    allUrls: [],
    changeFilterUploadVideo: 'recent',
    changeFilterURL: 'recent',
    uploadPercentage: 0,
    loadingVideo: false,
  };

  currentPlayingAudio: any;
  pricingUrl: string;

  isExceedMaxAiVideo = false;
  linkTab = true;
  videoTab = true;
  audioTab = false;
  badgesTab = false;
  questionnaireTab = false;

  
  isDisplayFolderInformation:boolean = false;
  folderInfomation:any[] = []
  selectedFolderInfo:any;

  origin = null;

  private maxFileSize = 999000000000;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
      @Inject(MAT_DIALOG_DATA) public data: MatDataType,
      public dialogRef: MatDialogRef<CreateVideoComponent>,
      public router: Router,
      public dialog: MatDialog,
      public urlService: CrudService,
      private toaster: ToastrService,
      private utilService: UtilityService,
      private dashboardService: DashboardService,
      private localStorageService: LocalStorageService,
  ) {
    //WC 06122024
    this.origin = data.origin;  //videoCollab

    if (data.links === false) {
      this.cvCurrentTab = 'videos';
      this.linkTab = false;
    }
    if (data.videos === false) {
      this.cvCurrentTab = 'links';
      this.videoTab = false;
    }
    if (data.audios) {
      this.cvCurrentTab = 'audios';
      this.audioTab = data.audios;
    }
    if (data.badges) {
      this.cvCurrentTab = 'badges';
      this.badgesTab = data.badges;
    }
    if (data.questionnaire) {
      this.cvCurrentTab = 'questionnaire';
      this.videoTab = false;
      this.linkTab = false;
      this.questionnaireTab = data.questionnaire;
    }
    if (data.treeNodes && data.treeNodes.length) {

      this.treeNodes = data.treeNodes;
  
      this.treeNodes.map(x => {
        x.URLName = x.name;
        return x;
      })
    }
    if (data.connectorNodes && data.connectorNodes.length) {
      this.connectorNodes = data.connectorNodes;
      this.connectorNodes.map(x => {
        x.URLName = x.name;
        return x;
      })
    }

    if (data.nodes && data.nodes.length) {
     
      //WC 11242023 --- Filter out the node which is added for showing them on the video tree on the video player since we don't have
      //connector node representation there. But in the create video mode, these nodes need to be suppressed because they are the 
      //real representation of the tree node/connector node
      this.nodes = data.nodes.filter((node) => !node?.endingNodeId && !node?.connectorNodeId && node.videoID);

      //WC 11252023 --- Set all videos in the tree nodes to be connector nodes
      this.nodes = this.nodes.map(x => {
        x.isConnectorNode = true;
        return x;
      })

    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    if (this.popUploadVideoEle)
      this.closePopover(this.popUploadVideoEle, 'popUploadVideo');
    if (this.popUploadAudioEle)
      this.closePopover(this.popUploadAudioEle, 'popUploadAudio');
    if (this.popProcessedVideoEle)
      this.closePopover(this.popProcessedVideoEle, 'popUploadAudio');
    if (this.popCreateQuestionnaireEle)
      this.closePopover(this.popCreateQuestionnaireEle, 'popCreateQuestionnaire');
    if (this.popCreateBadgeEle)
      this.closePopover(this.popCreateBadgeEle, 'popCreateBadge');
  }

  ngOnInit(): void {
    let isAiEditor = this.localStorageService.getItem('interactive') === 'isEditor';

    //WC 06122024 --- Overwrite the AI Editor if the origin of the request is from video Collab
    if (this.origin && this.origin == "videoCollab")
      isAiEditor = false;

    isAiEditor ? (this.aiEditorVideo = 'yes') : (this.aiEditorVideo = 'no');
    this.sort = 'recent';
    this.getAllVideos(true);
    const popData = this.dashboardService.getHelpPopOver();
    if (!popData?.popUploadVideo) {
      setTimeout(() => {
        if (this.popUploadVideoEle)
          this.popUploadVideoEle.show();
      }, 1000);
    }
    if (!popData?.popUploadAudio) {
      setTimeout(() => {
        if (this.popUploadAudioEle)
          this.popUploadAudioEle.show();
      }, 1000);
    }
    if (!popData?.popUploadAudio) {
      setTimeout(() => {
        if (this.popProcessedVideoEle)
          this.popProcessedVideoEle.show();
      }, 1000);
    }
    if (!popData?.popCreateQuestionnaire) {
      setTimeout(() => {
        if (this.popCreateQuestionnaireEle)
          this.popCreateQuestionnaireEle.show();
      }, 1000);
    }
    if (!popData?.popCreateBadge) {
      setTimeout(() => {
        if (this.popCreateBadgeEle)
          this.popCreateBadgeEle.show();
      }, 1000);
    }
  }

  get allowedFileType() {
    if (this.aiEditorVideo === 'yes')
      return 'video/mp4';
    else
      return 'video/mp4,video/x-m4v,video/*';
  }

  cvTabChange(tab) {
    this.isDisplayFolderInformation = false;
    this.cvCurrentTab = tab;    
    this.getAllVideos(true);
  }

  resetVideoData() {
    this.limit = 15;
    this.page = 1;
  }

  onSearchUploadedVideos() {
    if (this.sort === '')
      this.sort = 'recent';
    this.getAllVideos(true);
  }

  onSelectSorting(event: any) {
    this.sort = event.target.value;
    this.getAllVideos(true);
  }

  onScroll(): void {
    ++this.page;
    this.getAllVideos();
    // this.popProcessedVideoEle.hide();
  }

  // Get all videos
  getAllVideos(reset = false) {
    if (reset) {
      this.spinner = true;
      this.page = 1;
      this.allVideos = [];
      this.allBadges = [];
      this.allQuestionnaire = [];
      if (this.cvCurrentTab === 'badges') {
        this.countBadges();
      } else if (this.cvCurrentTab === 'questionnaire') {
        this.countQuestionnaire();
      } else {
        this.countUploadedVideos();
      }
    }
    if (this.cvCurrentTab === 'badges') {
      this.getBadges();
      return;
    }
    if (this.cvCurrentTab === 'questionnaire') {
      this.getQuestionnaire();
      return;
    }
    const object: any = {
      page: this.page,
      limit: this.limit,
      sort: this.sort,
      type: 'video',
      aiEditorVideo: 'no'
    }
    if (this.cvCurrentTab === 'links') {
      object.type = 'url';
    } else if ((this.aiEditorVideo === 'yes') && this.cvCurrentTab === 'videos') {
      object.aiEditorVideo = this.data.isAddChoice ? 'no' : 'yes';
    } else if (this.cvCurrentTab === 'audios') {
      object.type = 'audio';
    } else if (this.cvCurrentTab === 'documents'){
      object.type = 'pdf'
    }
    if (this.searchText) {
      object.search = this.searchText;
    }
    this.urlService.getLibraryVideos(object)
        .pipe(takeUntil(this._unsubscribeAll)).subscribe((success) => {
      for (let i = 0; i < (success.data.length); i++) {
        if (this.cvCurrentTab === 'audios') {
          success.data[i].poster = 'assets/images/wave-audio.png';
        }
        if (success.data[i].subtitleTranslations) {
          success.data[i].subtitleTranslations = success.data[i].subtitleTranslations.map(x => {
            x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
            return x;
          });
        }
        this.allVideos.push(success.data[i]);
      }
      this.spinner = false;
    }, err => {
      this.spinner = false;
    });
  }

  countUploadedVideos() {
    this.aiEditorVideo = this.data.isAddChoice ? 'no' : 'yes'
    this.urlService
      .getUploadedVideoCount(this.searchText, this.aiEditorVideo)
      .subscribe((res) => {
        res.data.forEach((e, i) => {
          const isAudio = Object.values(e).includes('audio/mpeg');
          const isVideo = Object.values(e).includes('application/octet-stream');
          const isUrl = Object.values(e).includes('url');
          const isDocument = Object.values(e).includes('application/pdf');
          if (isVideo) {
            this.totalVideoCount = e.count;
          }
          if (isUrl) {
            this.totalUrlCount = e.count;
          }
          if (isAudio) {
            this.totalAudioCount = e.count;
          }
          if (isDocument) {
            this.totalDocumentCount = e.count;
          }
        });
      });
  }

  onSelectFile(event) {
    const file = event.target.files;
    // let existItem = [];
    if (file) {
      for (const i of file) {
        if (this.aiEditorVideo === 'yes' && i.size > this.maxFileSize) {
          this.toaster.error('The file size limit has been reached for ' + i.name);
          continue;
        }
        if (this.aiEditorVideo === 'yes' && i.type !== 'video/mp4') {
          this.toaster.error('The file type is not valid for ' + i.name);
          continue;
        }
        // console.log(i)
        // console.log("onSelectFile - Before sanitizing i.name = ", i.name);

        // i.name is the original uploaded file name
        // i.originalName = i.name.replace(/[{()}]/g, "").replace(/\s/g, "");
        // i.originalName = i.originalName.trim();

        i.originalName = this.utilService.sanitizeString(i.name, true);
        // console.log("onSelectFile - After sanitizing i.originalName = ", i.originalName);

        i.id = Math.random();
        i.uploadPercentage = 0;
        this.utilService.onGetDuration(i).then((duration) => {
          i.duration = duration;
          if (i.type.indexOf('image') > -1) {
            i.format = 'image';
          } else if (i.type.indexOf('video') > -1) {
            i.format = 'video';
          }
          i.isUploading = true;
          i.isTranscribing = false;
          i.isTranscoding = false;
          this.uploadVideoObject.file.push(i);

          // existItem = existItem.filter((item, pos) => {
          //   return existItem.indexOf(item) === pos;
          // });
          // for (let i = this.uploadVideoObject.file.length - 1; i >= 1; i--) {
          //   for (const j of existItem) {
          //     if (this.uploadVideoObject.file[i].originalName === j) {
          //       this.uploadVideoObject.file.splice(i, 1);
          //       break;
          //     }
          //   }
          // }
        });
      }
    }
    setTimeout(() => {
      this.uploadVideo();
    }, 2000);
  }

  uploadVideo() {
    if (this.uploadVideoObject.file[0]) {
      this.toaster.info('Uploading Video');
      this.finalUpload(this.uploadVideoObject.file[0]);
    }
  }

  finalUpload(i) {

    // console.log('create-video.finalUpload - i = ', i);

    const obj = {
      name: this.utilService.sanitizeString(i.name, false),
      type: 'application/octet-stream',
      URL: null,
      duration: i.duration,
      thumbnail: '',
      size: i.size,
      // originalName: i.name.split(".")[0],
      originalName: i.originalName.split('.')[0],
    };


    if (this.localStorageService.getItem('interactive') === 'isEditor') {
      // tslint:disable-next-line: no-string-literal
      obj['bucketName'] = environment.AI_VIDEO_UPLOAD_BUCKET;
    }

    // obj.name = i.name.replace(/[{()}]/g, "").replace(/\s/g, "");
    // obj.name = obj.name.trim();
    // obj.name = this.sanitizeString(i.name);
    // console.log("finalUpload - obj.name = ", obj.name);

    let Objdata;

    // console.log('create-video.finalUpload - obj to be sent to createpresignedUrl = ', obj);

    this.urlService.createpresignedUrlV2(obj).subscribe(
        (success) => {
          i.counter = success.data.counter;
          this.uploadVideoObject.preSignedUrl = success.data.details;
          const pathArray = this.uploadVideoObject.preSignedUrl.split('?');
          const firstUrl = pathArray[0];
          this.urlService.uploadVideo(this.uploadVideoObject.preSignedUrl, i).subscribe(() => {
                i.isUploading = false;
                i.isTranscribing = true;
                i.uploadPercentage = 0;
                if (this.localStorageService.getItem('interactive') === 'isEditor') {
                  Objdata = {
                    name: i.name,
                    counter: i.counter,
                    bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
                    file: firstUrl,
                  };

                  // if (environment.AI_PARSE_URL.indexOf('ai-editor-requesthandler')) {
                  //   console.log('parseAiEditorVideoV2 - NEW')
                  //   this.parseAiEditorVideoV2(firstUrl, i);
                  // } else {
                  //   console.log('parseAiEditorVideo - OLD')
                  //   this.parseAiEditorVideo(firstUrl, i);
                  // }

                  this.parseAiEditorVideoV2(firstUrl, i);
                } else {
                  Objdata = {
                    name: i.name,
                    counter: i.counter,
                    file: firstUrl,
                  };
                  const data: any = {
                    name: obj.name,
                    counter: i.counter,
                    file: firstUrl,
                  };
                  if (this.data.skipTranscribing || !this.isTranscribing) {
                    this.transcodingJob(data, i)
                  } else {
                    this.transcribingJob(data, i);
                  }
                }
            },
            (error) => {
              this.toaster.info('Error!', error);
            }
          );

        this.progressPercentage(i);
      },
      (error) => {
        i.isTranscoding = false;
      }
    );
  }

  progressPercentage(i) {
    const interval = setInterval(() => {
      i.uploadPercentage = this.urlService.getProgress();
      if (i.uploadPercentage > 99.9) {
        clearInterval(interval);
      }
    }, 1000);
  }

  transcribingJob(data, i) {
    this.toaster.info('Transcribing Video');
    let requestObj;
    this.urlService.transcribeVideo(data).subscribe(
      (success) => {
        i.uploadPercentage = 0;
        i.isOtherone = true;

        if (this.localStorageService.getItem('interactive') === 'isEditor') {
          requestObj = {
            name: data.name,
            type: 'application/octet-stream',
            counter: i.counter,
            transcodeId: success.data.transcodeId,
            bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
          };
        } else {
          requestObj = {
            name: data.name,
            counter: i.counter,
            transcriptionJobName: success.data.TranscriptionJobName,
          };
        }

        if (i.uploadPercentage) {
          // console.log('in if i.uploadPercentage', i.uploadPercentage);
          i.uploadPercentage = i.uploadPercentage + 10;
        }

        this.checkTranscribingStatus(data, requestObj, i);
      },
      (error) => {}
    );
  }

  checkTranscribingStatus(data, requestObj, i) {
    // console.log("checkTranscribingStatus");

    this.urlService.checkTranscribingStatus(requestObj).subscribe(
      (success) => {
        if (success.data.TranscriptionJobStatus === 'COMPLETED') {
          this.toaster.info('Transcribing Completed');

          data.transcriptFileUri = success.data.Transcript.TranscriptFileUri;

          // this.toastr.info('Transcoding Video');
          this.transcodingJob(data, i);
        } else {
          // console.log('2. in else of check status', i.uploadPercentage);
          if (i.uploadPercentage < 95) {
            i.uploadPercentage = i.uploadPercentage + 5;
            // console.log('3. in if else of check status', i.uploadPercentage);
          } else {
            i.uploadPercentage = 98;
          }

          setTimeout(() => {
            this.checkTranscribingStatus(data, requestObj, i);
          }, 1500);
        }
      },
      (error) => {
        this.toaster.error('Transcribing Failed');
      }
    );
  }

  transcodingJob(data, i) {
    i.isTranscribing = false;
    i.isTranscoding = true;
    this.toaster.info('Transcoding Video');

    let requestObj;

    this.urlService.transcodeVideo(data).subscribe(
      (success) => {
        // console.log("transcodVideo success.data = ", success.data);

        i.uploadPercentage = 0;
        i.isOtherone = true;
        if (this.localStorageService.getItem('interactive') === 'isEditor') {
          requestObj = {
            name: data.name,
            type: 'application/octet-stream',
            counter: i.counter,
            transcodeId: success.data.transcodeId,
            bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
          };
        } else {
          requestObj = {
            name: data.name,
            type: 'application/octet-stream',
            counter: i.counter,
            transcodeId: success.data.transcodeId,
          };
        }
        if (i.uploadPercentage) {
          // console.log('in if i.uploadPercentage', i.uploadPercentage);
          i.uploadPercentage = i.uploadPercentage + 10;
        }
        this.checkStatus(requestObj, i);
      },
      (error) => {}
    );
  }

  checkStatus(requestObj, i) {
    this.urlService.checkTranscodingStatus(requestObj).subscribe(
      (success) => {
        if (success.data._id != null || success.data._id !== undefined) {
          this.toaster.info('Transcoding Completed');
          this.uploadVideoObject.file.shift();
          if (this.uploadVideoObject.file.length < 1) {
            this.cancelUpload(i);
            this.resetVideoData();
            this.getAllVideos(true);
            i.isTranscoding = false;
            i.isOtherone = false;
          } else {
            this.uploadVideo();
            i.isTranscoding = false;
            i.isOtherone = false;
          }
        } else {
          // console.log('2. in else of check status', i.uploadPercentage);
          if (i.uploadPercentage < 95) {
            i.uploadPercentage = i.uploadPercentage + 5;
            // console.log('3. in if else of check status', i.uploadPercentage);
          } else {
            i.uploadPercentage = 98;
          }
          setTimeout(() => {
            this.checkStatus(requestObj, i);
            // console.log('4. in setTimeout', i.uploadPercentage);
          }, 1500);
        }
      },
      (error) => {
        this.toaster.error('Transcoding Failed');
      }
    );
  }

  parseAiEditorVideoV2(file: string, i) {
    const user = this.localStorageService.getItem('user');
    const email = this.localStorageService.getItem('email');

    // WC 06142023 --- To check for max no of AI Editor processed
    this.urlService.getUserSubscriptionPlan().subscribe(success => {

      const maxNoOfAIEditor = success.data.subscriptionPlanInfo.maxNoOfAIEditorVideos;

      // console.log("parseAiEditorVideoV2 -  maxNoOfAIEditor = ", maxNoOfAIEditor);

      const formData = {
        userID: user,
        userEmail: email,
        url: file,
        webhook_url: environment.AI_WEB_HOOK,
        maxNoOfAIEditor
      };

      i.isTranscoding = false;
      i.isTranscribing = false;

      // console.log("formData = ", formData);

      this.urlService.parseAIEditorVideoV2(formData).subscribe(
        (res: any) => {
          this.toaster.info(
            '',
            `Your video is being processed by the auto editor, you'll receive an email notification when the processing is done.`
          );

          this.uploadVideoObject.file.shift();
          if (this.uploadVideoObject.file.length < 1) {
            this.cancelUpload(i);
            this.resetVideoData();
            this.getAllVideos(true);
            i.isTranscoding = false;
            i.isTranscribing = false;
          } else {
            this.uploadVideo();
            i.isTranscribing = false;
            i.isTranscoding = false;
          }
          // WC 08292022
        },
        (error) => {
          // Error callback
          // console.log(
          //     'Error in calling parse AI Editor API - error: ',
          //     error.message
          // );
          this.toaster.error(
              'We are sorry, AI Editor is currently not available. Please try again later'
          );
          this.cancelUpload(i);
          this.resetVideoData();
          this.getAllVideos(true);
          i.isTranscoding = false;
          i.isTranscribing = false;
        }
      );
    });
  }

  cancelUpload(i) {
    this.uploadVideoObject.file = [];
    i.isTranscoding = false;
  }



  convertSecondsToHHMMSSFormat = (decimalSeconds) => {
    const hh = Math.floor(decimalSeconds / 3600);
    let hhStr = '';
    if (hh < 10) hhStr = '0' + String(hh);
    else hhStr = String(hh);
    const reminderSec = decimalSeconds % 3600;
    const mm = Math.floor(reminderSec / 60);
    let mmStr = '';
    if (mm < 10) mmStr = '0' + String(mm);
    else mmStr = String(mm);
    const ss = reminderSec % 60;
    let ssStr = '';
    if (ss < 10) ssStr = '0' + String(ss);
    else ssStr = String(ss);

    const pos = ssStr.indexOf('.');

    if (pos > 0) {
      ssStr = ssStr.substring(0, pos);
    }
    let timeStr = hhStr + ':' + mmStr + ':' + ssStr;
    if (timeStr === 'NaN:NaN:NaN') {
      timeStr = '00:00:00';
    }
    return timeStr;
  };

  openUploadLinkDialog() {

    const dialogRef = this.dialog.open(UploadLinkDialogComponent, {
      width: '65%',
      minHeight: '400px',
      panelClass: 'my-dialog',
      height: 'auto',
      data: {
        allVideos: null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.sort = 'recent';
        this.getAllVideos(true);
      }
    });
  }

  uploadDocuments(event:any){
    let fileList: FileList = event.target.files;

    if(fileList[0]?.type !== 'application/pdf'){
      this.toaster.error("You can upload only PDF file!")
      return
    }

    const payloadObj = {
      name: fileList[0].name,
      type: fileList[0]?.type,
      URL: null,
      duration: 0,
      thumbnail: "",
      size: 0,
      originalName: fileList[0].name,
      isAIEditorVideo: false,
    };

    // console.log("fileList", fileList);

    this.urlService.createpresignedUrlV2(payloadObj).subscribe((success) =>{

      this.urlService.uploadDocument( success.data.details, fileList[0]).subscribe(() => {
        this.getAllVideos(true);
      }, (error) => {
        this.toaster.info('Error!', error);
    });
    
    }, (error) => {
      // console.log("error ===> ", error)
    }
    )
  }


  selectedVideoForPath: any[] = [];

  selectVideo(video) {
    if (this.aiEditorVideo === 'yes') {
      // const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      //   width: '400px',
      //   data: {
      //     body: 'Do you want to reprocess the video?',
      //   },
      // });

      // dialogRef.afterClosed().subscribe((result) => {
      //   if (result) {
      //     this.dialogRef.close(video);
      //   }
      // });
    } else {
      
      if (this.data.isAddPath) {
        const index = this.selectedVideoForPath.findIndex(v => v === video?._id);

        if (index === -1) {
          this.selectedVideoForPath.push(video?._id);
        } else {
          this.selectedVideoForPath.splice(index, 1);
        }

        return;
      }
      this.dialogRef.close(video);
    }
  }

  goToVideo(video) {
    this.localStorageService.removeItem('freeTemp');
    this.dialog.closeAll();
    this.router.navigate(['/create-interactive-video'], {queryParams: {video: video?.aiEditorVideoId}})
  }

  closePopover(popEle3: PopoverDirective, popEle32: string) {
    popEle3.hide();
    this.dashboardService.setHelpPopOver(popEle32);
  }

  openUploadVideo(uploadType?:any) {

    this.uploadVideoObject.spinner = true;

    this.urlService.checkForMaxAIVideos().subscribe(success => {
      this.isExceedMaxAiVideo = success.data.error;

      if (success.data.error) {
        this.toaster.error(success.data.message);
        this.uploadVideoObject.spinner = false;
        return;
      }

      if (this.aiEditorVideo === 'yes' && this.isExceedMaxAiVideo) {
        $('#exceed-max-ai-videos-Modal').modal('show');
      } else {
        console.log('uploadType: ', uploadType);
        const dialogRef = this.dialog.open(RecordVideoComponent, {
          disableClose: true,
          width: '100%',
          maxWidth: '800px',
          height: 'auto',
          panelClass: 'recordSection',
          data: {
            isAIEditor: this.aiEditorVideo === 'yes',
            uploadType: uploadType
          },
        })
  
        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.getAllVideos(true);
          }
        });
      };
    }, (error) => {
      this.toaster.error(error.error.message)
    }).add(() => {
      this.uploadVideoObject.spinner = false;
    });
    
  }

  openUploadAudio() {
    const dialogRef = this.dialog.open(RecordVideoComponent, {
      disableClose: true,
      width: '100%',
      maxWidth: '800px',
      height: 'auto',
      panelClass: 'recordSection',
      data: {
        isAudio: true,
        skipTranscribing: true
      },
    })

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getAllVideos(true);
      }
    });
  }

  openCreateQuestionnaire() {
    const dialogRef = this.dialog.open(CreateQuestionnaireComponent, {
      width: '100%',
      maxWidth: '1000px',
      height: 'auto',
      autoFocus: false,
      data: {},
    })

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getAllVideos(true);
      }
    });
  }

  openCreateBadge() {
    const dialogRef = this.dialog.open(CreateBadgeComponent, {
      disableClose: true,
      width: '100%',
      maxWidth: '1000px',
      height: 'auto',
      autoFocus: false,
      panelClass: "createBadge",
      data: {
        isAudio: true,
        skipTranscribing: true
      },
    })

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getAllVideos(true);
      }
    });
  }


  checkForMaxNoOfProcessedAIVideo_OLD() {
    this.uploadVideoObject.spinner = true;
    this.isExceedMaxAiVideo = false;
    this.urlService.getUserSubscriptionPlan().subscribe(success => {

      const currSubscriptionId = success.data._id;
      this.pricingUrl = '/pricing?subId=' + currSubscriptionId;

      if (success.data.subscriptionPlanInfo.maxNoOfAIEditorVideos !== undefined) {

        const maxNoOfVideos = success.data.subscriptionPlanInfo.maxNoOfAIEditorVideos;
        this.isExceedMaxAiVideo = this.totalVideoCount > maxNoOfVideos;

        // this.urlService.getNoOfProcessedAIVideos(this.localStorageService.getItem('user')).subscribe(success1 => {
        //   const videoCounts = success1.data;
        //   this.isExceedMaxAiVideo = videoCounts > maxNoOfVideos;
        //
        // });
      }
    }).add(() => {
      this.uploadVideoObject.spinner = false;
    });
  }

  checkForMaxNoOfProcessedAIVideo() {
    this.uploadVideoObject.spinner = true;
    this.isExceedMaxAiVideo = false;
    this.urlService.checkForMaxAIVideos().subscribe(success => {
        this.isExceedMaxAiVideo = success.data.error;

        if (this.isExceedMaxAiVideo)
          this.toaster.error(success.data.message);
    }).add(() => {
      this.uploadVideoObject.spinner = false;
    });
  }

  playAudio(audio) {
    this.currentPlayingAudio = audio;
    this.currentPlayingAudio.paused = false;
    this.audioElement.nativeElement.src = this.currentPlayingAudio.URL;
    this.audioElement.nativeElement.play();
  }

  endAudio() {
    this.currentPlayingAudio = null;
  }

  private getBadges() {
    this.spinner = true;
    const payload: any = {
      limit: this.limit,
      page: this.page,
      sort: this.sort,
      type: 'creation'
    };
    if (this.searchText) {
      payload.search = this.searchText;
    }
    this.urlService.getBadge(payload).subscribe((res: any) => {
      this.allBadges.push(...res.data);
    }).add(() => this.spinner = false);
  }

  private countBadges() {
    this.urlService.getBadgeCount({}).subscribe((res: any) => {
      this.totalBadgeCount = res.data.find(x => x.type === 'creation').count || 0;
    }).add(() => this.spinner = false);
  }

  private getQuestionnaire() {
    this.spinner = true;
    const payload: any = {
      limit: this.limit,
      page: this.page,
      sort: this.sort
    };
    if (this.searchText) {
      payload.search = this.searchText;
    }
    this.urlService.getQuestionnaire(payload).subscribe((res: any) => {
      this.allQuestionnaire.push(...res.data);
    }).add(() => this.spinner = false);
  }

  private countQuestionnaire() {
    this.urlService.getQuestionnaireCount({}).subscribe((res: any) => {
      this.totalQuestionnaireCount = res.data?.[0]?.count || 0;
    }).add(() => this.spinner = false);
  }

  openFolder(folder:any){ 
    this.page = 1;   
    this.selectedFolderInfo = folder
    this.folderInfomation = [];
    this.spinner = true;
    this.isDisplayFolderInformation = true;
   this.getFolderInformation(folder._id)
  }

  onScrollForFolder(){
    ++this.page;
    // console.log("this.selectedFolderInfo?._id", this.selectedFolderInfo?._id);
    
    this.getFolderInformation(this.selectedFolderInfo?._id);
  }

  getFolderInformation(folderId:any){
    let obj = {
      folderId : folderId,
      limit: this.limit,
      page: this.page,
      sort : 'recent'
    }
    this.urlService.getVideoListByFolderId(obj).subscribe((res) => {
      // this.folderInfomation = res.data;
      this.folderInfomation.push(...res.data)
      this.spinner = false;
    })
  }

  isVideoSelectedForPath(video:any){
    return this.selectedVideoForPath.includes(video?._id);
  }

  addVideosIntoPath() {
    this.dialogRef.close(this.selectedVideoForPath);

  }
}
