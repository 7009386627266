import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { DeleteConfirmationComponent } from "../../layout/modals/delete-confirmation/delete-confirmation.component";
import { CrudService } from "../../services/crud.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { PricingDialogComponent } from "../group/pricing-dialog/pricing-dialog.component";
import { DashboardService } from "../../services/dashboard.service";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { SlugifyPipe } from "../../pipes/slugify.pipe";
import { InteractiveVideosDialogComponent } from "../../layout/modals/interactive-videos-dialog/interactive-videos-dialog.component";
import { VideoCommentTypeDialogComponent } from "../../layout/modals/video-comment-type-dialog/video-comment-type-dialog.component";
import { CreateVideoComponent } from "../../layout/modals/create-video/create-video.component";
import { UploadedFileResponse } from "../../interfaces/upload-video.interface";
import { BookmarkVideoResponse } from "../../interfaces/interactive-video.interface";
import { OpenCollabComponent } from "../../layout/modals/open-collab/open-collab.component";
import { ModalVideoPlayerComponent } from "../../layout/modals/modal-video-player/modal-video-player.component";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ShareVideoDialogComponent } from "../../layout/modals/share-video-dialog/share-video-dialog.component";
import { ExcelService } from "../../services/excel.service";
import sanitizeSVG from "@mattkrick/sanitize-svg";
import { LocalStorageService } from "../../services/localStorage.service";
import { UtilityService } from "../../services/utility.service";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { DatePipe, Location } from "@angular/common";
import { isDate } from "util";
import { QRCodeModalComponent } from "../qr-code-modal/qr-code-modal.component";
import { Editor, Toolbar } from "ngx-editor";
import { HttpParams } from "@angular/common/http";

declare var $;

interface CollabRequestData {
  video?: string;
  comment?: string;
  requester?: string;
  requesterData?: any;
  init?: boolean;
}

@Component({
  selector: "app-main-community",
  templateUrl: "./main-community.component.html",
  styleUrls: ["./main-community.component.css"],
})
export class MainCommunityComponent implements OnInit {
  @ViewChild("deleteDialogRef") deleteDialogRef: DeleteConfirmationComponent;
  @ViewChild('addNewTabDialog') addNewTabDialog: TemplateRef<any>;
  @ViewChild('groupInput') groupInput!: ElementRef;
  @ViewChild('moveToTabDialog') moveToTabDialog: TemplateRef<any>;
  @ViewChild('commentReply') commentReply!: ElementRef;
  @ViewChild('offcanvasRight') offcanvasRight: ElementRef;

  isToggleSetting = false;
  collabRequestData: CollabRequestData = {
    comment: undefined,
    video: undefined,
    requester: undefined,
    init: false,
  };
  spinner: any = false;
  allGroups: any = [];
  subscriptionGroups = [];
  nonSubscriptionGroups = [];
  totalGroup = 7;
  currentTab = "subscription";
  currentSettingTab = "settings";
  currentBusinessTab = "business-details";
  sellerInformation: any;
  currentUserInformation: any;
  currentUserId: any;
  allComments = [];
  cPage = 1;
  cLimit = 8;
  isSubscribedAnyPlan = false;
  db = {
    commentModal: "",
    updateCommentModal: "",
    replyModal: "",
    commentLimit: 1000,
    replyLimit: 300,
    // isSeeMoreComment: true,
    isSeeMoreReply: true,
    replyId: null,
    viewAllReplyId: null,
    seeMoreCommentId: null,
  };
  editProfileObject = {
    business: {
      name: "",
      cust_service_address: {
        city: "",
        country: "",
        line1: "",
        line2: "",
        postal_code: "",
        state: "",
      },
      cust_service_phone: "",
      website: "",
      businessDescription: "",
      communityUrl: "",
      logoPosition: "right",
      theme: {
        url: null,
        backgroundImage: null,
        backgroundColor: "#F8FAFB",
        color: "#6c2fbb",
      },
      templateName: "",
    },
    connectedAccountId: "",
    welcomeVideoId: "",
  };
  welcomeVideo: any;
  doNotShowWelcomeVideo = false;
  // baseUrl = environment.currentDomain + 'main-community/'
  baseUrl = environment.currentDomain + "my-community/";
  isEmbed = false;
  communityUrl: any = null;
  uploadFiles = [];
  currentImage = "";
  sellerId: any;
  private commentId: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  // private fileuplaod:any;
  private selectedFile: any;
  banner: string = "";
  announcementFrm: UntypedFormGroup;
  isLoggedIN: boolean = false;
  processing: boolean = false;
  selectedTemplateId: string = "";

  // featuredVideoTabs:any[] = [];
  isDisplayfeaturedVideos: boolean = false;
  tabName: any;
  groupId: any;
  groupDetail: any;
  currentContentTab: any;
  isAdmin: boolean = false;
  term: any;
  addVideoPage = 1;
  myVideos: any[] = [];
  videoItem: any[] = [];
  groupActiveTabContent: any[] = [];
  isProcessPartial = false;
  isTitleEdit:boolean = false;
  currentMoveItem: any;
  isFreemiumPlan = false;
  groupPlanDetails = [];

  toolbar: Toolbar = [
    ['bold', 'italic'],
    //WC 10102024 --- PenTestFix
    //['underline', 'strike'],
    ['underline'],
    // ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    //[{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
    ['link', 'image'],
    // ['text_color', 'background_color'],
    // ['align_left', 'align_center', 'align_right', 'align_justify'],
];
postBodyEditor: Editor;
updateCommentEditor: Editor;
replyCommentEditor: Editor;
paramsValue:any;
currentTopicValue:any = 'Forum';
topicValue:any= '';
allTopicsForReOrder:any[] = []
isDisableComment = false;
UpdatedCommentVideoType:any;
offcanvasElement:any;
isOpenUserMenu:boolean = false;
isOpenUserMenuForUpdate:boolean = false;
isOpenUserMenuForReply:boolean = false;
isAddSignValue:boolean = true;
myFollowers:any[] = [];
mySubscribers:any[] = [];
mentionUsers: any[] = [];
searchUser:any;
userPage = 1;
userLimit = 10;
subscriberPage = 1;
subscriberLimit = 10;
selectedCommentTypeForMentionUser:any = 'comment';
currentMentionUsersType: "subscriber" | "followers" = "followers"
placeHolderValue:any = "Search a user";
editorActivity:string = "";
keyPressStr = "";
previousKeyPressStr = "";
isMentioning: boolean = false;
currentMessage: string = "";
previousMessage: string = "";
newMentionPos: any = null;
searchUserName:any;
isPressEnter:boolean = false;

topicDescription: any;
editTopicValue: any = ''
editTopicIndex: any;
isPinnedComments: boolean = false;



  @HostListener('document:click', ['$event'])
onClick(event: any) {
    // // Check if the click event happened outside of the offcanvas            
    // if (!this.offcanvasElement.contains(event.target as Node)) {
    // // Offcanvas is closed
    //     this.handleOffcanvasClose();
    // }

    if(event.target.className.includes("serachInput") || event.target.className.includes("tagUser") || event.target.className.includes("usertype") || event.target.className.includes("active")){
        return
    }else{                
        this.isOpenUserMenu = false;
        this.isOpenUserMenuForUpdate = false;
        this.isOpenUserMenuForReply = false;            
    }
}

  constructor(
    public slugifyPipe: SlugifyPipe,
    public crudService: CrudService,
    public dashboardService: DashboardService,
    public toaster: ToastrService,
    public router: Router,
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    public excelService: ExcelService,
    public localStorageService: LocalStorageService,
    public utilityService: UtilityService,
    private datePipe: DatePipe,
    private location: Location,
  ) {
    
    this.postBodyEditor = new Editor();
    this.updateCommentEditor = new Editor();
    this.replyCommentEditor = new Editor();


    this.activatedRoute.queryParams.subscribe((queryParams) => {
      this.paramsValue = queryParams;

      if (queryParams.embed && queryParams.embed === "true") {
        this.isEmbed = queryParams.embed;
      }
      if (queryParams.type === "subscription") {
        this.currentTab = "subscription";
      }
      if (queryParams.user) {
        this.sellerId = queryParams.user;
      } else {
        this.sellerId = this.localStorageService.getItem("user");
      }
      this.collabRequestData.comment = queryParams?.comment;
      this.collabRequestData.video = queryParams?.video;
      this.collabRequestData.requester = queryParams?.requester;

      this.activatedRoute.params.subscribe((params) => {
        if (params.slug) {
          this.communityUrl = params.slug;
        }
        if (
          !this.localStorageService.getItem("token") &&
          !this.localStorageService.getItem("anonymousToken")
        ) {
          this.crudService.getAnonymousUser().subscribe((success) => {
            // console.log("main-community constructor - Got anonymous token");
            this.localStorageService.setItem(
              "anonymousToken",
              success.data.token
            );
            this.init();
          });
        } else {
          this.init();
        }
      });
    });

    this.announcementFrm = new UntypedFormGroup({
      startDate: new UntypedFormControl(
        this.datePipe.transform(new Date(), "yyyy-MM-dd"),
        [Validators.required]
      ),
      endDate: new UntypedFormControl(null, [Validators.required]),
      text: new UntypedFormControl(null, [Validators.required]),
    });

    this.announcementFrm
      .get("endDate")
      .setValidators(this.endDateValidator.bind(this));
    this.announcementFrm.get("startDate").valueChanges.subscribe(() => {
      this.announcementFrm.get("endDate").updateValueAndValidity();
    });
    //this.localStorageService.setItem('selectedTemplateId', 'Minimalist');
    //this.selectedTemplateId = localStorageService.getItem('selectedTemplateId');
  }

  openReorderModal() {
    $("#rearrangeGroupsDialog").modal("show");
  }

  endDateValidator(control) {
    const startDateValue = this.announcementFrm.get("startDate").value;
    const endDateValue = control.value;
    if (startDateValue && endDateValue) {
      return startDateValue < endDateValue ? null : { endDateInvalid: true };
    }
    return null;
  }

  errorHandling = (control: string, error: string) => {
    return (
      this.announcementFrm.controls[control].hasError(error) &&
      (this.announcementFrm.controls[control].dirty ||
        this.announcementFrm.controls[control].touched)
    );
  };

  get isDisableDiscussionBoard() {
    return (
      !this.sellerId || (!this.isSubscribedAnyPlan && !this.isOwnCommunity)
    );
  }

  get isOwnCommunity() {
    return this.sellerId === this.currentUserId;
  }

  ngOnInit(): void {
    this.dashboardService.getUserData.subscribe((res) => {
      if (res) {
        this.currentUserInformation = res;
      }
    });
  }

  getCommunityAnnouncement() {
    this.crudService
      .getCommunityAnnouncement(this.sellerId)
      .subscribe((res) => {
        if (res.data?.startDate && res.data?.endDate && res.data?.text) {
          this.announcementFrm.patchValue({
            startDate: this.datePipe.transform(
              new Date(res.data.startDate),
              "yyyy-MM-dd"
            ),
            endDate: this.datePipe.transform(
              new Date(res.data.endDate),
              "yyyy-MM-dd"
            ),
            text: res.data.text,
          });

          const isDateInRange = this.isDateInRange(
            this.announcementFrm.value.startDate,
            this.announcementFrm.value.endDate
          );

          if (isDateInRange) {
            this.banner = res.data.text;
          }
        }
      });
  }

  init() {
    //console.log("init");
    const initAll = () => {
      this.getAllGroups();
      this.checkForCollabRequest();
      this.currentUserId = this.localStorageService.getItem("user");
      this.getSellerDetails();
  
    };

    if (this.communityUrl != null) {
      this.crudService
        .getSellerIdByCommunityUrl(this.communityUrl)
        .subscribe((success) => {
          if (success.data.sellerId != null) {
            this.sellerId = success.data.sellerId;
            //console.log('getSellerIdByCommunityUrl this.sellerId = ', success.data.sellerId);
            initAll();
            this.getCommunityAnnouncement();
          } else $("#invalid-url-Modal").modal("show");
        });
    } else {
      initAll();
    }

    if (
      this.localStorageService.getItem("user") &&
      this.localStorageService.getItem("token")
    ) {
      this.isLoggedIN = true;
    }
  }

  getSellerDetails() {
    this.spinner = true;
    this.crudService.getUserInfoV2(this.sellerId).subscribe((res) => {
      if (!res.data?.userInfo) {
        this.router.navigateByUrl("/");
        return;
      }
      this.spinner = false;
      this.sellerInformation = res.data?.userInfo;
      //console.log("sellerInformation", this.sellerInformation);
      
      this.isDisplayfeaturedVideos = this.sellerInformation.featuredVideoGroupId ? true : false
      this.getAllComments();

      // if (this.paramsValue?.commentId && this.paramsValue?.topic) {   
      //   if (!this.localStorageService.getItem('token')) {
      //       this.redirectLogin();
      //   } else{
      //     const decodedTopic = decodeURIComponent(this.paramsValue.topic || '');
      //     this.currentTopicValue = decodedTopic;
      //     setTimeout(() => {
      //       this.commentReply.nativeElement.click();
      //     }, 5000);
      //   }
      // }
      
      
      if (this.sellerInformation.featuredVideoGroupId) {
        this.getGroupDetail(this.sellerInformation.featuredVideoGroupId)
      }
   
      this.editProfileObject.business = JSON.parse(
        JSON.stringify(this.sellerInformation)
      ).business;
      if (!this.editProfileObject.business.cust_service_address) {
        this.editProfileObject.business.cust_service_address = {
          city: "",
          country: "",
          line1: "",
          line2: "",
          postal_code: "",
          state: "",
        };
      }
      if (!this.editProfileObject?.business) {
        this.router.navigateByUrl("/");
        return;
      }
      if (!this.editProfileObject.business.theme) {
        this.editProfileObject.business.theme = {
          url: null,
          backgroundImage: null,
          backgroundColor: "#F8FAFB",
          color: "#6c2fbb",
        };
      }
      this.editProfileObject.connectedAccountId = JSON.parse(
        JSON.stringify(this.sellerInformation)
      ).connectedAccountId;
      this.editProfileObject.welcomeVideoId = JSON.parse(
        JSON.stringify(this.sellerInformation)
      ).welcomeVideoId;

      //WC 01122024 --- Getting the template name
      this.selectedTemplateId = this.editProfileObject.business.templateName;
      console.log("getSellerDetails - this.editProfileObject.business.templateName = ", this.editProfileObject.business.templateName);

      if (this.selectedTemplateId == "" || this.selectedTemplateId == undefined)
        this.selectedTemplateId = "Default";
      //WC 01122024

      //WC 11012024
      if (this.editProfileObject.welcomeVideoId || this.isOwnCommunity) {
        this.getWelcomeVideo();
      }
    });
  }

  getWelcomeVideo() {
    console.log("in getWelcomeVideo");

    const getSingleUploadedVideo = () => {
      console.log("in getWelcomeVideo.getSingleUploadedVideo");

      this.welcomeVideo =
        environment.currentDomain +
        "embed/" +
        this.editProfileObject.welcomeVideoId;

      console.log("in getWelcomeVideo.getSingleUploadedVideo - this.editProfileObject.business.templateName = ", this.editProfileObject.business.templateName);
      console.log("in getWelcomeVideo.getSingleUploadedVideo - this.editProfileObject.welcomeVideoId = ", this.editProfileObject.welcomeVideoId);

      //WC this.editProfileObject.business.templateName == undefined is the same as  Default
      if (this.editProfileObject.welcomeVideoId && (this.editProfileObject.business.templateName == undefined || this.editProfileObject.business.templateName == "Default" || this.editProfileObject.business.templateName == "Modern")) {
        $("#welcomeVideoModal").modal("show");
        $("#welcomeVideoModal").on("hidden.bs.modal", () => {
          this.welcomeVideo = null;
          if (this.doNotShowWelcomeVideo) {
            if (this.currentUserId) {
              this.crudService
                .setDoNotShowItem({
                  sellerId: this.sellerId,
                  item: "Welcome Video",
                })
                .subscribe();
            } else {
              this.localStorageService.setItem(
                "hide_video_" + this.sellerId,
                "true"
              );
            }
          }
        });
      } 
    };

    //For every user, chec the value of the Do Not Show flag for Welcome Video whether it's true or false
    if (this.currentUserId) {
      const payload = {
        sellerId: this.sellerId,
        item: "Welcome Video",
      };

      //Get the value of Do Not Show Welcome Video
      this.crudService.getDoNotShowItem(payload).subscribe((res) => {
        console.log("in getWelcomeVideo - getDoNotShowItem - res = ", res);

        //The user doesn't set Do Not Show Welcome Video to true then get the Welcome Video
        if (res.data.length === 0) {
          console.log("in getWelcomeVideo - Show Welcome Video");

          this.doNotShowWelcomeVideo = false;
          getSingleUploadedVideo();
        } else {
          console.log("in getWelcomeVideo - DO NOT Show Welcome Video");

          this.doNotShowItem = res.data;
          console.log("in getWelcomeVideo - this.doNotShowItem = ", this.doNotShowItem);
          this.doNotShowWelcomeVideo = true;
        }
      });
    } else {
      if (!this.localStorageService.getItem("hide_video_" + this.sellerId)) {
        this.doNotShowWelcomeVideo = false;
        getSingleUploadedVideo();
      }
    }
  }

  doNotShowItem:any[] = []
  updateDoNotShowWelcomeVideo(event) {
   
    this.doNotShowWelcomeVideo = event.target.checked;

    console.log("updateDoNotShowWelcomeVideo - event.target.checked = ", event.target.checked);
    console.log("updateDoNotShowWelcomeVideo - this.doNotShowItem = ",this.doNotShowItem );

    if (!event.target.checked) {
      console.log("updateDoNotShowWelcomeVideo - RESET DoNotShowWelcomeVideo for this.doNotShowItem[0]._id = ", this.doNotShowItem[0]._id);
      this.crudService.removeDoNotShowItem({ doNotShowId: this.doNotShowItem[0]._id }).subscribe();
    } else {
      console.log("updateDoNotShowWelcomeVideo - SET DoNotShowWelcomeVideo");
      this.crudService.setDoNotShowItem({
          sellerId: this.sellerId,
          item: "Welcome Video",
        })
        .subscribe();
    }
  }

  openUpdateBusinessModal() {
    this.currentBusinessTab = "business-details"

    console.log("openUpdateBusinessModal - this.editProfileObject.welcomeVideoId = ", this.editProfileObject.welcomeVideoId);
    if (this.editProfileObject.welcomeVideoId) 
      this.welcomeVideoForModal = environment.currentDomain + "embed/" + this.editProfileObject.welcomeVideoId;
    else 
      this.welcomeVideoForModal = null;

  }

  welcomeVideoForModal: any;
  closeBussinessModal() {
      this.welcomeVideoForModal = null;
  }

  uploadWelcomeVideo() {
    const dialogRef = this.dialog.open(InteractiveVideosDialogComponent, {
      minWidth: "50vw",
      maxHeight: "70vh",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateWelcomeVideoDetails(result);
      }
    });
  }

  updateWelcomeVideoDetails(id) {
    this.spinner = true;
    const obj = { welcomeVideoId: id };
    this.crudService
      .updateProfileProfile(obj)
      .subscribe((success) => {
        this.welcomeVideo = null;
        this.welcomeVideoForModal = null;
        this.crudService.getUserInfoV2(this.sellerId).subscribe((res) => { 
          this.sellerInformation = res.data?.userInfo;
          this.editProfileObject.business = JSON.parse(
            JSON.stringify(this.sellerInformation)
          ).business;
          this.editProfileObject.welcomeVideoId = JSON.parse(
            JSON.stringify(this.sellerInformation)
          ).welcomeVideoId;
          if (this.editProfileObject.welcomeVideoId || this.isOwnCommunity) { 
            this.welcomeVideoForModal =
              environment.currentDomain +
              "embed/" +
              this.editProfileObject.welcomeVideoId;
          }
        })
        this.toaster.success("Welcome Video updated successfully");
      })
      .add(() => {
        // $('#update_business_modal').modal('hide');
        this.spinner = false;
      });
  }

  getAllGroups() {
    this.spinner = true;
    const payload: any = {
      accessType: "ADMIN",
      isPlanAssigned: true,
      isSubscriptionGroup: true,
    };
    if (this.sellerId) {
      payload.userId = this.sellerId;
    }
    // get subscription groups
    this.crudService
      .getAllGroups(payload)
      .subscribe((res) => {
        this.subscriptionGroups = res.data;
        this.subscriptionGroups = this.subscriptionGroups.filter(
          (x) => x?.isSubscriptionGroup
        );
        this.isSubscribedAnyPlan = this.subscriptionGroups.find(
          (x) => x?.loggedInUserAccess?.accessType === "SUBSCRIBER"
        );
        this.tabChange(this.currentTab);
      })
      .add(() => (this.spinner = false));

    delete payload.isPlanAssigned;
    payload.isSubscriptionGroup = false;
    // non subscription groups
    this.crudService
      .getAllGroups(payload)
      .subscribe((res) => {
        this.nonSubscriptionGroups = res.data;
        this.nonSubscriptionGroups = this.nonSubscriptionGroups.filter(
          (x) => !x?.isSubscriptionGroup
        );
      })
      .add(() => (this.spinner = false));
  }

  updateBusinessDetails() {
    this.spinner = true;
    const obj = { business: this.editProfileObject.business };
    const update = () => {
      obj.business.theme.backgroundImage = (
        obj.business.theme.backgroundImage || ""
      ).split("?")[0];
      this.crudService
        .updateProfileProfile(obj)
        .subscribe((success) => {
          this.selectedFile = null;
          this.isToggleSetting = false;
          this.dashboardService.onGetUserData();
          this.getSellerDetails();
          this.toaster.success("Business details are updated successfully");
        })
        .add(() => {
          $("#update_business_modal").modal("hide");
          this.spinner = false;
        });
    };
    if (obj.business.communityUrl) {
      this.crudService
        .getSellerIdByCommunityUrl(obj.business.communityUrl)
        .subscribe((res) => {
          if (res.data?.sellerId && res.data.sellerId !== this.currentUserId) {
            this.spinner = false;
            this.toaster.error("community URL already exist");
          } else {
            update();
          }
        });
    } else {
      update();
    }
  }

  tabChange(tab) {
    this.currentTab = tab;
    this.totalGroup = 7;
    if (this.currentTab === "subscription") {
      this.allGroups = this.subscriptionGroups;
    } else {
      this.allGroups = this.nonSubscriptionGroups;
    }
    if (this.totalGroup >= this.allGroups.length) {
      this.totalGroup = this.allGroups.length;
    }
  }

  settingsTabChange(tab) {
    this.currentSettingTab = tab;
  }

  changeTemp(temp: string) {
    this.spinner = true;
    // this.editProfileObject.business.theme.backgroundColor = "#6c2fbb";
    // this.editProfileObject.business.theme.color = "#000000";
    this.editProfileObject.business.templateName = "";
    this.localStorageService.removeItem("selectedTemplateId");
    this.localStorageService.setItem("selectedTemplateId", temp);
    this.selectedTemplateId =
      this.localStorageService.getItem("selectedTemplateId");

    this.editProfileObject.business.templateName = temp;
    this.updateBusinessDetails();

    setTimeout(() => {
      this.spinner = false;
    }, 2000);
  }

  viewAllGroup() {
    this.totalGroup = this.allGroups.length;
  }

  onMyGroup(item: any) {
    if (this.isEmbed) {
      this.router.navigate(["/community/" + item._id], {
        queryParams: { embed: this.isEmbed },
      });
    } else {
      this.router.navigate(["/community/" + item._id]);
    }
  }

  getCurrentGroupPlanInfo() {

    this.isFreemiumPlan = false;

    this.crudService.getAllPlans(this.sellerInformation?._id).subscribe(res => {
        this.groupPlanDetails = res.data
        
        // WC 02072023 -- To allow rejected Stripe acct to sell Freemium plans
        for (const item of this.groupPlanDetails) {

            let noOfFreemium = 0;
            if (item.price === 0) {
                noOfFreemium++;
                // console.log("noOfFreemium", noOfFreemium)
                if (noOfFreemium === this.groupPlanDetails.length) {
                    this.isFreemiumPlan = true;
                    // console.log("this.isFreemiumPlan = ", this.isFreemiumPlan);
                }
            }
        }
    });

  
}


  showPlans(readOnly = false) {    
    // if (!readOnly && this.sellerInformation?.accountOnboardingStatus !== 'Completed' ) {

    //   if (!this.isFreemiumPlan) {
    //       readOnly = true;          
    //     }  
    // }

    const dialogRef = this.dialog.open(PricingDialogComponent, {
      width: "90%",
      maxWidth: "100%",
      // minHeight: "400px",
      // maxHeight: "90vh",
      panelClass: "pricing-dialog",
      height: "auto",
      data: {
        id: this.sellerId,
        embed: this.isEmbed,
        readonly: readOnly
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      //console.log(result)
    });
  }

  formatPhoneNumber(phoneNumberString) {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1,2})(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return (
        "+" + match[1] + " (" + match[2] + ") " + match[3] + "-" + match[4]
      );
    }
    return "";
  }

  // Show user profile
  showUser(userId: any) {
    this.router.navigate(["public-profile"], { queryParams: { user: userId } });
  }

  redirectLogin(){
    if (!this.localStorageService.getItem('token')) {
      if(this.paramsValue?.commentId && this.paramsValue?.topic){
        const encodedTopic = encodeURIComponent(this.paramsValue?.topic);        
          this.localStorageService.setItem('redirectURL', `/my-community/${this.sellerInformation.business?.communityUrl ? this.sellerInformation.business?.communityUrl : this.sellerInformation.business?.name}?commentId=${this.paramsValue?.commentId}&topic=${encodedTopic}`);
        }else{
          this.localStorageService.setItem('redirectURL', `/my-community/${this.sellerInformation.business?.communityUrl ? this.sellerInformation.business?.communityUrl : this.sellerInformation.business?.name}`);
      }      
      // this.router.navigate(['login'], { queryParams: { cid: 'EOFF' + this.groupDetail.admin} });
      
      this.router.navigate(['login'], { queryParams: { cid: 'EOFF' + this.sellerId} });
    }else{
      $('#modalForJoinCommunity').modal('show')
    }
  }

  containsDisallowedScripts(comment: string): boolean {

    // Build a regex pattern to match any of the tags in the list
    const pattern = new RegExp(`<\\/?(${environment.maliciousHtmlTags.join('|')})(\\s|>)`, 'i');

    // Test the input string for any matches
    return pattern.test(comment);
  };

  containsDisallowedEvents(comment: string): boolean {

    // Build a regex pattern to match any of the tags in the list
    const pattern = new RegExp(`\\b(${environment.maliciousEventHandlers.join('|')})\\b=`, 'i');

    // Test the input string for any matches
    return pattern.test(comment);
  }


  addComment(comment: any, type: string, videoId?: string) {   

    this.searchUserName = "";
    this.isMentioning = false;
    this.searchUser = "";
    this.myFollowers = [];
    this.isOpenUserMenu = false;
    this.isOpenUserMenuForUpdate = false;

    if (this.isDisableDiscussionBoard) {
      this.redirectLogin();
      this.isDisableComment = false; 
      return;
    }
    if (!this.isValidComment(comment)) {
      this.toaster.error("Please enter comment first.");
      this.isDisableComment = false; 
      return;
    }

    // WC 10232024 --- Validation is done at the API level
    // //WC 10102024 --- PenTestFix to check for malicious HTML tags or even handlers to prevent XSS attacks
    // // Check for disallowed scripts in the comment
    // if (this.containsDisallowedScripts(comment)) {
    //   this.toaster.error('Invalid input with malicious script detected!');
    //   this.isDisableComment = false; 
    //   return;
    // }

    // if (this.containsDisallowedEvents(comment)) {
    //   this.toaster.error('Invalid input with malicious event detected!');
    //   this.isDisableComment = false; 
    //   return;
    // }
    // //WC 10102024 --- PenTestFix


    if (this.isPendingAttachments.length) {
      this.toaster.error("Please wait while the attachments are uploaded.");
      return;
    }

    this.isDisableComment = true;
    
    //let planComment = this.removeCommentTag(comment);

    // this.mentionUsers.map((i:any) =>{
    //     if(!planComment.includes(i.name)){
    //         // this.mentionUsers = this.mentionUsers.filter(j => j.name !== i.name);
  
    //         this.mentionUsers = this.mentionUsers.filter((user, index, self) =>
    //           user.id && // Check if the user object has an 'id' property
    //           index === self.findIndex((t) => (
    //             t.name === user.name && // Find the first occurrence of the user with the same name
    //             t.id // Ensure that the comparison object also has an 'id' property
    //           ))
    //         );
            
    //     }
    // })   
    
    // this.mentionUsers = this.mentionUsers.filter(user => user.id);

    // // Step 2: Ensure uniqueness based on the 'name' property
    // this.mentionUsers = this.mentionUsers.filter((user, index, self) =>
    //   index === self.findIndex((t) => t.name === user.name)
    // );


    const payload: any = {
      sellerUserId: this.sellerId,
      comment,
      scenerio: "comment",
      type: this.UpdatedCommentVideoType ? this.UpdatedCommentVideoType : type,
      videoId,
      mentionUsers: this.getMentionedUser(comment)
    };
    if (this.commentId) {
      // update comment
      payload.commentId = this.commentId;
    }

    if (this.currentTopicValue) {
      payload.topic = this.currentTopicValue;
    }

    const attachments = [];
    for (const uploadFile of this.uploadFiles) {
      attachments.push(this.firstUrl(uploadFile.URL));
    }
    if (attachments.length) {
      payload.attachments = attachments;
    }

    this.crudService
      .addCommunityComment(payload)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.getAllComments();
        this.hideModal();
        this.db.commentModal = "";
        this.UpdatedCommentVideoType = "";
        this.commentId = "";
        this.uploadFiles = [];
        this.isDisableComment = false; 
        this.searchUserName = "";
      }, (error) => {
        this.isDisableComment = false;
      });
  }


  
  isValidComment(comment: string): boolean {
        
    // Remove HTML tags from the comment using a regular expression
    const commentText = comment ? comment.replace(/<\/?[^>]+(>|$)/g, "") : '';

    const hasImgTag = comment && comment.indexOf('<img') !== -1;

    // Check if the remaining text consists only of whitespace
    return commentText.trim() !== '' || hasImgTag;
}


  hideModal() {
    $("#update_comment").modal("hide");
    $('#add_video').modal('hide');
  }

  openUpdateComment(item: any) {
    if (this.isDisableDiscussionBoard) {
      return;
    }
    this.selectedCommentTypeForMentionUser = 'updateComment'

    if(item.type === "VIDEO"){
      this.UpdatedCommentVideoType = item.type
    }
    if(item.type === "OPEN_COLLAB"){
      this.UpdatedCommentVideoType = item.type
    }
    
    this.db.updateCommentModal = item.comment;
    this.commentId = item._id;
    $("#update_comment").modal("show");
  }

  openConfirmationDeleteModal() {
    this.deleteDialogRef.open(
      {
        header: `Delete Announcement`,
        title: `Are you sure you want to delete announcement?`,
      },
      (e) => {
        if (e) {
          this.processing = true;
          this.crudService.deleteCommunityAnnouncement().subscribe(
            (success) => {
              $("#announcementModal").modal("hide");
              this.getCommunityAnnouncement();
              this.toaster.success("Announcement deleted successfully !!");

              this.announcementFrm = new UntypedFormGroup({
                startDate: new UntypedFormControl(
                  this.datePipe.transform(new Date(), "yyyy-MM-dd"),
                  [Validators.required]
                ),
                endDate: new UntypedFormControl(null, [Validators.required]),
                text: new UntypedFormControl(null, [Validators.required]),
              });

              this.announcementFrm
                .get("endDate")
                .setValidators(this.endDateValidator.bind(this));
              this.announcementFrm
                .get("startDate")
                .valueChanges.subscribe(() => {
                  this.announcementFrm.get("endDate").updateValueAndValidity();
                });

              this.banner = "";
              this.processing = false;
            },
            (err) => {
              this.processing = false;
              this.toaster.error("Announcement deletion failed !!");
            }
          );
        }
      }
    );
  }

  confirmDeleteComment(item: any) {
    if (this.isDisableDiscussionBoard) {
      return;
    }
    this.deleteDialogRef.open(
      {
        header: "Delete comment",
        title: "Are you sure you want to delete this comment?",
      },
      (e) => {
        if (e) {
          const payload = {
            sellerUserId: this.sellerId,
            commentId: item._id,
          };
          this.crudService
            .deleteCommunityComment(payload)
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((res) => {
              this.toaster.success("Deleted successfully");
              if(item.replyOnCommentID){
                for (const i of this.allComments) {
                    
                    if (i._id === item?.replyOnCommentID) {                        
                        if (i.replies && i.replies?.length){  
                            i.replies = i.replies.filter((i:any) => i.comment !== item.comment)
                        }
                    }
                }
            }else{
                this.getAllComments();
            }
            });
        }
      }
    );
  }

  addReply(reply: any, id: any) {    

    this.isMentioning = false;
    this.searchUserName = "";
    this.searchUser = "";
    this.myFollowers = [];
    this.isOpenUserMenuForReply = false;

    if (this.isDisableDiscussionBoard) {
      this.redirectLogin();
      this.isDisableComment = false; 
      return;
    }
    
    if (!this.isValidComment(reply)) {
      // this.toaster.error("Please enter reply first.");
      this.isDisableComment = false; 
      return;
    }

    //WC 10232024 --- Validation is done at the API level
    // //WC 10102024 --- PenTestFix to check for malicious HTML tags or even handlers to prevent XSS attacks
    // // Check for disallowed scripts in the comment
    // if (this.containsDisallowedScripts(reply)) {
    //   this.toaster.error('Invalid input detected!');
    //   this.isDisableComment = false; 
    //   return;
    // }

    // if (this.containsDisallowedEvents(reply)) {
    //   this.toaster.error('Invalid input detected!');
    //   this.isDisableComment = false; 
    //   return;
    // }
    // //WC 10102024 --- PenTestFix

    this.isDisableComment = true;

    //let planComment = this.removeCommentTag(reply);

    // this.mentionUsers.map((i:any) =>{
    //     if(!planComment.includes(i.name)){
    //         this.mentionUsers = this.mentionUsers.filter(j => j.name !== i.name);
    //     }
    // })

    // this.mentionUsers = this.mentionUsers.filter(user => user.id);

    // // Step 2: Ensure uniqueness based on the 'name' property
    // this.mentionUsers = this.mentionUsers.filter((user, index, self) =>
    //   index === self.findIndex((t) => t.name === user.name)
    // );


    let payload:any = {
      sellerUserId: this.sellerId,
      commentId: id,
      comment: reply,
      scenerio: "reply",
      mentionUsers: this.getMentionedUser(reply)
    };

    if (this.currentTopicValue) {
      payload.topic = this.currentTopicValue;
    }

    if (this.updateReplyCommentId && this.updateReplyCommentId !== null) {
      payload.updateReplyCommentId = this.updateReplyCommentId;
    }

    this.crudService
      .addCommunityComment(payload)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {

        if(res.data?.replyOnCommentID){
          const datatemp: any = { users: {} }
          datatemp.users.profilePic = this.currentUserInformation.profilePic
          datatemp.createdAT = res.data?.createdAt
          datatemp.users.username = this.currentUserInformation.username
          datatemp.users.firstname = this.currentUserInformation.firstname
          datatemp.users.lastname = this.currentUserInformation.lastname
          datatemp.comment = res.data?.comment
          datatemp.updatedAt = res.data?.updatedAt
          datatemp._id = res.data?._id
          datatemp.groupId = res.data?.groupId
          datatemp.groupMemberId = res.data?.groupMemberId
          datatemp.reply = res.data?.reply
          datatemp.topic = res.data?.topic
          datatemp.type = res.data?.type
          datatemp.replyOnCommentID = res.data?.replyOnCommentID
          datatemp.users._id = this.currentUserInformation._id
          
          for (const i of this.allComments) {
          
              if (i._id === res.data?.replyOnCommentID) {                        
                // Check if we are updating an existing reply
                const replyIndex = i.replies?.findIndex(reply => reply._id === this.updateReplyCommentId);
                if (replyIndex !== -1) {
                  // Update existing reply
                  i.replies[replyIndex] = datatemp;
                } else {
                  // Push new reply if it doesn't exist
                  if (i.replies && i.replies.length) {
                    i.replies.push(datatemp);
                  } else {
                    i.replies = [datatemp];
                  }
                }
              }
          }
      }else{
          this.getAllComments();
      }

        this.db.commentModal = "";
        this.commentId = null;
        this.db.replyModal = "";
        this.updateReplyCommentId = "";
        this.isDisableComment = false; 
        this.searchUserName = "";
      }, (error) => {
        this.isDisableComment = false;
      });
  }

  viewAllReply(id: any) {

    if(this.isDisableDiscussionBoard){
      $('#modalForJoinCommunity').modal('show')
      return
    }

    this.db.replyId = null;    
    // if (this.db.viewAllReplyId === id) {
    //   this.db.viewAllReplyId = null;
    // } else {
    //   this.db.viewAllReplyId = id;
    // }
    this.selectedCommentTypeForMentionUser = 'reply'

    const modalElement = this.offcanvasRight.nativeElement;
        
    if (modalElement) {
        modalElement.classList.add('show');
        modalElement.style.display = 'block';
        if(this.paramsValue?.commentId){
          setTimeout(() => {
              window.scrollTo(250,1200);
          },0)
      }
    }
    
    if(this.paramsValue?.commentId && this.localStorageService.getItem('token')){
        this.removeQueryParamsFromURL()
    }

    this.db.replyModal = ''
    // this.db.viewAllReplyId = id;  
    this.db.viewAllReplyId = this.paramsValue?.commentId ? this.paramsValue?.commentId : id
  
  }

    removeQueryParamsFromURL(){
      const params = new HttpParams().delete('commentId')
    
      const baseUrl = this.router.url.split('?')[0]; // Get the base URL without query params
      this.location.go(baseUrl, params.toString()); 
    }

  viewReply(id: any) {
    if (this.isDisableDiscussionBoard) {
      return;
    }
    this.db.viewAllReplyId = null;
    if (this.db.replyId === id) {
      this.db.replyId = null;
    } else {
      this.db.replyId = id;
    }
  }

  getLastReplyTime(value){
    return value[value?.length - 1 ]?.updatedAt
  }

  viewFullReply(item: string, str: string, id: any) {
    if (str === "comment") {
      // this.db.commentLimit = item.length;
      // this.db.isSeeMoreComment = false;
      this.db.seeMoreCommentId = id;
    } else {
      this.db.replyLimit = item.length;
      this.db.isSeeMoreReply = false;
    }
  }

  viewLessReply(item: number, str: string, id: any) {
    if (str === "comment") {
      // this.db.commentLimit = item;
      // this.db.isSeeMoreComment = true;
      this.db.seeMoreCommentId = null;
    } else {
      this.db.replyLimit = item;
      this.db.isSeeMoreReply = true;
    }
  }

  logoPosition: any;
  changeLogoPosition(event: any) {
    this.editProfileObject.business.logoPosition = event.target.value;
    this.sellerInformation.business.logoPosition = event.target.value;
    this.logoPosition = event.target.value;
  }

  likeDislikeComment(id: any, item: any, value: any) {
    if (this.isDisableDiscussionBoard) {
      this.redirectLogin();
      return;
    }

    let scenario = item.some((i: any) => i.userId === this.currentUserId);
    let category;

    const payload = {
      ID: this.sellerId,
      sellerUserId: this.sellerId,
      category : scenario ? 0 : 1,
      commentID: id,
      scenario : scenario ? "unlike" : "like",
      type: "comment",
    };

    this.crudService
      .communityCommentLikeUnlike(payload)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        // this.getAllComments();

        this.allComments.forEach((e) => {
          const userHasLiked = e.likebyuserInfo.some((user: any) => user.userId === this.currentUserId); // Check if the current user has liked
          const isLiked = userHasLiked;

          if (e._id === id) {
            if (isLiked === false) {
              // If the user hasn't liked, add the like
              e.likeCount += 1;
              e.likebyuserInfo.push({
                userId: this.currentUserId,
              });
            } else {
              // If the user has liked, remove the like
              e.likeCount -= 1;
              e.likebyuserInfo = e.likebyuserInfo.filter((user: any) => user.userId !== this.currentUserId);
            }
          }
        });
      });
  }


  scrollComment() {
    ++this.cPage;
    this.getAllComments(false);
  }

  getAllComments(reset = true) {    
    if (reset) {
      this.cPage = 1;
      this.allComments = [];
    }
    if (this.sellerId) {
      let payload: any = {
        sellerUserId: this.sellerId,
          topic: this.currentTopicValue,
          page: this.cPage,
          limit: this.cLimit,
      }

      if (this.isPinnedComments) {
            payload.isPinned = this.isPinnedComments
        }
        
      this.crudService
        .getCommunityComments(payload)
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          // console.log("res ====> ", res.data);

          const newComment = (res?.data || []).map((x) => {
            // x.commentHtml = this.utilityService.linkify(x.comment);
            x.commentHtml = x.comment; 

            x.replies = x.replies.map((replyObj) => ({
              ...replyObj,
              ...(!replyObj.users ? { users: replyObj.userInfo } : {}),
              ...(!replyObj.replyInfo ? replyObj.replyInfo : {}),
            }));
            return x;
          });
          
          this.allComments.push(...newComment);

          let commentFound = false;
                if (this.paramsValue.commentId) {
                    for (const comment of newComment) {
                        if (comment._id === this.paramsValue.commentId) {
                        commentFound = true;
                        setTimeout(() => {
                            this.commentReply.nativeElement.click();
                        }, 0);
                        break; // Exit loop since comment is found
                        }else{
                            this.cPage++; // Increment page number
                            this.getAllComments(false); // Fetch next page
                        }
                    }
                }

        //   if(this.paramsValue?.commentId){
        //     setTimeout(() => {  
        //         this.commentReply.nativeElement.click();
        //     }, 0);
        // }
        });
    }
  }

  checkForCollabRequest() {
    if (
      this.collabRequestData.comment &&
      this.collabRequestData.video &&
      !this.collabRequestData.init
    ) {
      this.crudService
        .getCommunityComments({
          sellerUserId: this.sellerId,
          commentId: this.collabRequestData.comment,
        })
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((res: any) => {
          this.collabRequestData.init = true;
          if (res.data) {
            const item = res.data[0];
            if (item && item.type === "OPEN_COLLAB") {
              this.onClickOpenCollab(item, this.collabRequestData.video);
            }
          }
        });
    }
  }

  slugifyUrl() {
    this.editProfileObject.business.communityUrl = this.slugifyPipe.transform(
      this.editProfileObject.business.communityUrl
    );
  }

  async onSelectFile(event) {
    const files = event.target.files;
    if (files && files.length) {
      for (const file of files) {
        const cleanImage = await sanitizeSVG(file);
        if (!cleanImage) {
          this.toaster.error("invalid SVG file");
        } else {
          if (file.size > 10485760) {
            this.toaster.error("File size should be less than 10MB");
            continue;
          }
          if (
            file.type.indexOf("image") > -1 ||
            file.type.indexOf("pdf") > -1
          ) {
            this.uploadFiles.push({ file: cleanImage });
          } else {
            this.toaster.error("Only pdf and image files are allowed!");
          }
        }
      }
      this.uploadAttachments();
    }
    event.target.value = "";
  }

  uploadAttachments() {
    const newFiles = this.isPendingAttachments;
    if (newFiles.length) {
      const formData = new FormData();
      for (const newFile of newFiles) {
        formData.append("file", newFile, newFile.name);
      }
      // console.log(payload);
      this.crudService.uploadAttachments(formData).subscribe((res) => {
        const data = res.data.attachmentUrls || [];
        this.uploadFiles
          .filter((x) => !x.URL)
          .map((x) => {
            if (data.length) {
              x.URL = data.splice(0, 1)[0];
            }
            return x;
          });
        // check is any upload pending
        if (this.isPendingAttachments.length) {
          this.uploadAttachments();
        }
      },
      (error) =>{
        // console.log("error", error);
        
        this.toaster.error(error?.error?.data);
        this.uploadFiles = [];
      }
      );
    }
  }

  get isPendingAttachments() {
    return this.uploadFiles.filter((x) => !x.URL).map((x) => x.file);
  }

  removeUploadFile(i) {
    const file = this.uploadFiles[i]?.URL;
    if (file) {
      this.crudService.deleteUploadedAttachments([file]).subscribe((res) => {
        this.uploadFiles.splice(i, 1);
      });
    } else {
      this.uploadFiles.splice(i, 1);
    }
  }

  onClickAddVideo() {
    const dialogRef = this.dialog.open(VideoCommentTypeDialogComponent, {
      width: "600px",
      panelClass: "add-video-dialog",
      id: "add-video-dialog",
      minHeight: "calc(40vh - 160px)",
      height: "auto",
    });

    dialogRef.afterClosed().subscribe((type) => {
      if (type) {
        this.onClickUpload(type);
      }
    });
  }

  onClickUpload(type: string) {
    const dialog = this.dialog.openDialogs.find(
      (i) => i.id === "add-video-dialog"
    );
    dialog?.close();
    if (type === "single") {
      this.onSelectSingleVideo();
    } else {
      this.onClickOpenCollab();
    }
  }

  onSelectSingleVideo() {
    const dialogRef = this.dialog.open(CreateVideoComponent, {
      data: {
        allVideos: null,
        links: false,
      },
      minWidth: "50vw",
    });
    dialogRef.afterClosed().subscribe((result: UploadedFileResponse) => {
      if (result) {
        this.addComment("video", "VIDEO", result._id);
      }
    });
  }

  onClickOpenCollab(item?: any, requestVideoId?: string) {
    const video: BookmarkVideoResponse = item?.videoInfo;
    const videoId = item?.videoId;
    if (!videoId) {
      const data: any = {
        create: true,
        comment: null,
        videoId,
        // groupMembers: this.groupDetail.members,
        commentId: null,
        // groupId: this.groupId,
        sellerUserId: this.sellerId,
        currentUserInformation: this.currentUserInformation,
        requestVideoId,
        readonly: this.isDisableDiscussionBoard,
      };
      if (this.currentTopicValue) {
        data.topic = this.currentTopicValue;
    }
      this.openCollabModal(data);
      //     });
      // })
    } else {
      const data:any = {
        create: false,
        comment: item,
        videoId,
        // groupMembers: this.groupDetail.members,
        commentId: item._id,
        // groupId: this.groupId,
        sellerUserId: this.sellerId,
        currentUserInformation: this.currentUserInformation,
        requestVideoId,
        readonly: this.isDisableDiscussionBoard,
      };
      if (this.currentTopicValue) {
        data.topic = this.currentTopicValue;
    }
      this.openCollabModal(data);
    }
  }

  openCollabModal(data: any) {
    const dialogRef = this.dialog.open(OpenCollabComponent, {
      width: "72%",
      panelClass: "my-dialog",
      height: "auto",
      minHeight: "15vw",
      data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('onClickOpenCollab', result);
      if (result) {
        this.getAllComments();
      }
    });
  }

  openVideoPlayerModel(video: UploadedFileResponse) {
    const options = {
      autoplay: true,
      poster: video?.poster,
      controls: true,
      sources: [{ src: video?.URL, type: "application/x-mpegURL" }],
    };
    const dialogRef = this.dialog.open(ModalVideoPlayerComponent, {
      width: "100%",
      minHeight: "calc(64vh - 160px)",
      maxWidth: "40vw",
      panelClass: "video-tree-dialog",
      height: "48vh",
      data: { options, video },
    });

    dialogRef.afterClosed().subscribe(() => { });
  }

  drop(event: CdkDragDrop<string[]>) {
    let data = [];
    if (this.currentTab === "subscription") {
      moveItemInArray(
        this.subscriptionGroups,
        event.previousIndex,
        event.currentIndex
      );
      data = this.subscriptionGroups.map((x, i) => {
        return { groupId: x._id, currIndex: i };
      });
    } else {
      moveItemInArray(
        this.nonSubscriptionGroups,
        event.previousIndex,
        event.currentIndex
      );
      data = this.nonSubscriptionGroups.map((x, i) => {
        return { groupId: x._id, currIndex: i };
      });
    }
    // this.crudService.reorderGroups({payload: data}).subscribe();

    this.crudService.reorderGroups(data).subscribe();
  }


  invite() {
    // let URL = this.baseUrl + "?user=" + this.sellerInformation?._id;
    // let embedURL = URL + "&embed=true";
    // if (this.sellerInformation?.business?.communityUrl) {
    //   URL = this.baseUrl + this.sellerInformation?.business?.communityUrl;
    //   embedURL = URL + "?embed=true";
    // }

    //console.log("this.sellerInformation?.business?.communityPathSegment = ", this.sellerInformation?.business?.communityPathSegment);

    let URL =  this.sellerInformation?.business?.communityPathSegment ?  environment.currentDomain + this.sellerInformation?.business?.communityPathSegment + '/' + "?user=" + this.sellerInformation?._id : this.baseUrl + "?user=" + this.sellerInformation?._id;
    let embedURL = URL + "&embed=true";
    if (this.sellerInformation?.business?.communityUrl) {
      URL = this.sellerInformation?.business?.communityPathSegment ? environment.currentDomain + this.sellerInformation?.business?.communityPathSegment + '/' + this.sellerInformation?.business?.communityUrl : this.baseUrl + this.sellerInformation?.business?.communityUrl;
      embedURL = URL + "?embed=true";
    }
    
    let name = "";
    if (this.sellerInformation?.business?.name) {
      name = this.sellerInformation?.business?.name;
    } else {
      name =
        this.sellerInformation.firstname +
        " " +
        this.sellerInformation.lastname;
    }
    // console.log("url", URL);
    // console.log("embedURL", embedURL);
    
    const dialogRef = this.dialog.open(ShareVideoDialogComponent, {
      width: "100%",
      maxWidth: "600px",
      minHeight: "400px",
      panelClass: "my-dialog",
      height: "auto",
      data: {
        title: "Invite ",
        sellerID: this.sellerInformation?._id,
        name,
        URL,
        embedURL,
      },
    });
  }

  downloadSubscribers() {
    this.spinner = true;
    let name =
      this.sellerInformation.firstname +
      "_" +
      this.sellerInformation.lastname +
      "_subscribers_";
    if (this.sellerInformation?.business?.name) {
      name = this.sellerInformation?.business?.name + "_subscribers_";
    }
    this.crudService
      .downloadCommunitySubscribers()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        const fileToExport = res.data.map((item: any) => {
          delete item._id;
          return item;
        });
        this.excelService.exportToExcel(fileToExport, name);
      })
      .add(() => (this.spinner = false));
  }

  createAnnouncement() {
    $("#announcementModal").modal("show");
  }

  isDateInRange(startDate, endDate) {
    const today = new Date().toISOString().split("T")[0];
    return today >= startDate && today <= endDate;
  }

  onCreateAnnouncement() {
    if (this.announcementFrm.valid) {
      let payload = {};
      payload["startDate"] = this.datePipe.transform(
        this.announcementFrm.value.startDate,
        "MM/dd/yyy"
      );
      payload["endDate"] = this.datePipe.transform(
        this.announcementFrm.value.endDate,
        "MM/dd/yyy"
      );
      payload["text"] = this.announcementFrm.value.text;

      this.crudService.updateCommunityAnnouncement(payload).subscribe((res) => {
        if (res) {
          $("#announcementModal").modal("hide");
          this.getCommunityAnnouncement();
        }
      });
    }
  }

  updateAttachments(att, comment) {
    this.spinner = true;
    let attachments = comment.attachments.filter((x) => x !== att);
    attachments = attachments.map((x) => this.firstUrl(x));
    const payload = {
      commentId: comment._id,
      scenerio: "comment",
      attachments,
    };
    this.crudService
      .addCommunityComment(payload)
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((res) => {
        this.getAllComments();
      })
      .add(() => (this.spinner = false));
  }

  viewAttachments(attachment) {
    if (this.urlType(attachment, "image")) {
      this.currentImage = attachment;
      $("#viewAttachmentsModal").modal("show");
    } else {
      window.open(attachment, "_blank");
    }
  }

  firstUrl(item) {
    // console.log(item)
    return item.split("?")[0];
  }

  urlType(url, type: "image" | "pdf") {
    if (typeof url !== "string") return false;
    if (type === "image") {
      return (
        url.match(/^http[^?]*.(jpg|jpeg|gif|png|tiff|bmp|svg)(\?(.*))?$/gim) !=
        null
      );
    }
    if (type === "pdf") {
      return url.match(/^http[^?]*.(pdf)(\?(.*))?$/gim) != null;
    }
    return false;
  }

  onSelectBackgroundImg(event: any) {
    const file = event.target.files[0];
    if (file.size > 10485760) {
      this.toaster.error("File size should be less than 10MB");
      return;
    }
    if (
      file.type === "image/png" ||
      file.type === "image/jpeg" ||
      file.type === "image/jpg"
    ) {
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = async () => {
        const isModernTemplate = this.editProfileObject.business.templateName === "Modern";
        const minWidth = !isModernTemplate ? 1568 : 400;
        const minHeight = !isModernTemplate ? 875 : 600;
        const errorMessage = !isModernTemplate ? "1568 x 875" : "400 x 600";

        if (img.width < minWidth || img.height < minHeight) {
          this.toaster.info(`Minimum image size should be ${errorMessage}`);
        } else {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.editProfileObject.business.theme.backgroundImage = reader.result;
            // console.log(reader.result, img.src)
          };
          reader.readAsDataURL(file);
          const cleanImage = await sanitizeSVG(file);
          if (!cleanImage) {
            this.toaster.error("invalid SVG file");
            return;
          }
          this.selectedFile = cleanImage;
          // this.editCurrentPoster(this.selectedFile)
        }
      };
    } else {
      this.toaster.info("Please select a PNG or JPG image file.");
    }
  }

  resetThemeSettings() {
    this.editProfileObject.business.templateName = "Default";
    this.editProfileObject.business.theme = {
      url: null,
      backgroundImage: null,
      backgroundColor: "#F8FAFB",
      color: "#6c2fbb",
    };
    this.isToggleSetting = false;
    this.selectedFile = null;
    this.updateBusinessDetails();
    this.localStorageService.removeItem("selectedTemplateId");
    this.selectedTemplateId = "";
  }

  saveThemeSettings() {
    if (this.selectedFile) {
      ;
      const formData = new FormData();
      formData.append("file", this.selectedFile);
      this.spinner = true;
      this.crudService.uploadImageToS3(formData).subscribe((res: any) => {
        this.spinner = false;
        this.editProfileObject.business.theme.backgroundImage = (
          res.data.Location || ""
        ).split("?")[0];
        this.updateBusinessDetails();
      });
    } else {
      this.updateBusinessDetails();
    }
  }

  getGroupDetail(id: any) {
    this.crudService.getGroupDetail(id).pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      this.groupDetail = res.data[0];
      this.getGroupTabsContentSize();
      this.getAllMainVideo();

      if(this.isDisplayfeaturedVideos){
        if(!this.isOwnCommunity){          
          if(!this.groupDetail?.userDefinedTabs.length){
            this.isDisplayfeaturedVideos = false
          }else{
            this.isDisplayfeaturedVideos = true
          }
        }
      }
      //console.log("this.groupDetail.members ==> ", this.groupDetail.members);
      
      this.groupDetail.members.forEach((item: any) => {
        if (item.accessType === 'ADMIN' && item.memberId === this.currentUserId) {
          this.isAdmin = true;
        }
      })
    })
  }

  getGroupTabsContentSize(){
    this.crudService.getGroupTabsContentSize(this.sellerInformation.featuredVideoGroupId)
    .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
      this.groupDetail.userDefinedTabs = success.data
      this.currentContentTab = this.groupDetail?.userDefinedTabs.length ? this.groupDetail?.userDefinedTabs[0].tabName : ''
      this.getGroupContentByTabName()

    }).add(() => this.spinner = false);
  } 

  setFeaturedVideosInCommunity(id: any) {
    this.crudService.setFeaturedVideosInCommunity({ 'featuredVideoGroupId': id }).subscribe((res) => {
      // console.log("res", res)
    })
  }

  createFeatureGroup() {
    this.groupDetail = {};
    this.groupActiveTabContent = [];
    this.spinner = true;
    const formData = new FormData();
    formData.append('groupName', 'Featured Videos');
    formData.append('pageAdminTitle', 'Admin title');
    formData.append('pageContentTitle', 'Content title');
    formData.append('description', 'description');
    formData.append('isPrivate', 'false');
    formData.append('isSendingEmailRemainder', 'false');
    formData.append('isPasswordProtected', 'false');
    formData.append('isFeaturedVideos', 'true');
    formData.append('password', null);
    formData.append('file', undefined);

    this.crudService.createGroup(formData).subscribe((res) => {
      this.spinner = false;
      this.groupId = res?.data?._id;
      this.setFeaturedVideosInCommunity(this.groupId);
      // this.getSellerDetails();

      this.crudService.getUserInfoV2(this.sellerId).subscribe((res) => {
        if (!res.data?.userInfo) {
          this.router.navigateByUrl("/");
          return;
        }
        this.sellerInformation = res.data?.userInfo;      
        
        if (this.sellerInformation.featuredVideoGroupId) {
          this.getGroupDetail(this.sellerInformation.featuredVideoGroupId)
          this.isDisplayfeaturedVideos = this.sellerInformation.featuredVideoGroupId ? true : false
        }
      })
      
      this.isToggleSetting = false;
    })
  }

  addTab() {
    const dialogRef = this.dialog.open(this.addNewTabDialog, {
      width: '500px',
      maxWidth: '90%',
      panelClass: 'my-dialog',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe((result) => {
      this.tabName = '';
    });
  }

  createGroupTab() {
    this.spinner = true;
    const payload = {
      groupId: this.sellerInformation.featuredVideoGroupId,
      tabName: this.tabName
    }

    this.crudService.createGroupTab(payload).subscribe((res) => {
      this.crudService.getGroupTabsContentSize(this.sellerInformation.featuredVideoGroupId)
      .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
        this.groupDetail.userDefinedTabs = success.data
        this.currentContentTab = this.tabName;
        this.getGroupContentByTabName()
        this.dialog.closeAll();
        this.tabName = '';
      })
    }).add(() => this.spinner = false);
  }

  changeContentTab(value: any) {
    this.currentContentTab = value;
    this.getGroupContentByTabName();
  }

  addVideo() {
    if (this.isAdmin) {
      $('#add_video').modal('show');
    }
  }

  getAllMainVideo(reset?) {
    if (reset) {
      this.addVideoPage = 1;
      this.myVideos = [];
    }
    this.crudService.getInteractiveVideoV2(null, true, 10, this.addVideoPage, 'recent')
      .pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
        const allMainVideo = res.data;
        allMainVideo.forEach(e => {
          if (e.isPublished) {
            this.myVideos.push(e)
          }
        });

      })
  }

  onScrollMyVideo(): void {
    ++this.addVideoPage;
    this.getAllMainVideo();
  }

  getVideoItem(item) {
    const index = this.videoItem.indexOf(item._id);
    if (index === -1) {
      this.videoItem.push(item._id)
    } else {
      this.videoItem.splice(index, 1)
    }

  }

  saveContentVideos() {
    const payload: any = {
      groupId: this.sellerInformation.featuredVideoGroupId,
      videoId: this.videoItem,
      tabName: this.currentContentTab
    }

    this.isProcessPartial = true;
    this.crudService.saveVideoContent(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
      this.crudService.getGroupTabsContentSize(this.sellerInformation.featuredVideoGroupId)
      .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
        this.groupDetail.userDefinedTabs = success.data
        this.videoItem = [];
        this.isProcessPartial = false;
        this.toaster.success('Video added successfully !!')
        this.hideModal();
        // this.getGroupBasic(this.groupId);
        this.getGroupContentByTabName()
      })

    }, err => {
      if (err) {
        this.isProcessPartial = false;
      }
    })
  }

  getGroupContentByTabName(id?) {
    this.spinner = this.currentContentTab ? true : false    
    let group_id = id ? id : this.sellerInformation.featuredVideoGroupId
    
    this.crudService.getGroupContent(group_id, this.currentContentTab)
    .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
        const owlElement = $('.owl-carousel-two');        
        owlElement.owlCarousel('destroy');
      $('.tooltip.show').remove();
      this.groupActiveTabContent = success.data;
      
      setTimeout(() => {
        const owl = $('.owl-carousel-two');
          owl.owlCarousel({
              loop: false,
              margin: 10,
              autoWidth: true,
              nav: true,
              dots: false,
              responsive: {
                  0: {
                      items: 1
                  },
                  600: {
                      items: 3
                  },
                  1000: {
                      items: 10
                  }
              }
          });
      }, 0);

      setTimeout(() => $('.owl-carousel-two').trigger('refresh.owl.carousel'), 0);

        // const activeTab = this.groupAllTabsContent.find(x => x.name === this.currentContentTab);
        // if (activeTab) {
        //     activeTab.data = success.data
        // } else {
        //     this.groupAllTabsContent.push({
        //         name: tab,
        //         data: success.data
        //     });
        // }
    }).add(() => this.spinner = false);
}

  deleteGroupTab(tabName) {
    this.isPinnedComments = false;
  this.deleteDialogRef.open({
      header: 'Remove Tab',
      title: 'Are you sure you want to remove this tab?',
      trueButton: 'Remove'
  }, (e) => {
      if (e) {
          this.spinner = true;
          const payload = {
              groupId: this.sellerInformation.featuredVideoGroupId,
              tabName
          }
          this.crudService.deleteGroupTab(payload).subscribe((res) => {
            this.crudService.getGroupTabsContentSize(this.sellerInformation.featuredVideoGroupId)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
              this.groupDetail.userDefinedTabs = success.data
              this.dialog.closeAll();
              if (this.currentContentTab === tabName) {
                    this.currentContentTab = this.groupDetail?.userDefinedTabs?.length ? this.groupDetail?.userDefinedTabs[0].tabName : [];
                    this.getGroupContentByTabName();
                  }
            })


          }).add(() => this.spinner = false);
      }
  });
}

openReorderGroupModal(){
  $('#reOrderFeaturedVideoModal').modal('show')        
}

dropFeatureVideo(event: CdkDragDrop<string[]>) {
  const owlElement = $('.owl-carousel-two');
  
  if (owlElement.length > 0) {
    owlElement.owlCarousel('destroy');
  }

  setTimeout(() => {
    const owl = $('.owl-carousel-two');
      owl.owlCarousel({
          loop: false,
          margin: 10,
          autoWidth: true,
          nav: true,
          dots: false,
          responsive: {
              0: {
                  items: 1
              },
              600: {
                  items: 3
              },
              1000: {
                  items: 10
              }
          }
      });
  }, 0);

  let data = [];
    
    moveItemInArray(
      this.groupActiveTabContent,
      event.previousIndex,
      event.currentIndex
    );
    
    data = this.groupActiveTabContent.map((x, i) => {
      return { contentId: x._id, currIndex: i };
    });
    this.crudService.reorderGroupContent(data).subscribe();
    // this.crudService.reorderGroups(data).subscribe();
}

deleteFeatureGroup(){
  this.spinner = true;
  this.crudService.setFeaturedVideosInCommunity({ 'featuredVideoGroupId': null }).subscribe((res)=>{
    this.crudService.getUserInfoV2(this.sellerId).subscribe((res) => {
      if (!res.data?.userInfo) {
        this.router.navigateByUrl("/");
        return;
      }
      this.groupDetail = {};
      this.groupActiveTabContent = [];
      this.spinner = false;
      this.sellerInformation = res.data?.userInfo;         
      this.isDisplayfeaturedVideos = false
      this.isToggleSetting = false;
    })
  })
}

editTitle(item: any) {
  // console.log("item", item);
   
  if (!item || item.trim() === '') {
    return this.toaster.error('Group title is required');
  }

  const formData = new FormData();
  formData.append('groupId', this.sellerInformation.featuredVideoGroupId);
  formData.append('groupName', item);
  formData.append('description', this.groupDetail.description);
  formData.append('isPrivate', this.groupDetail.isPrivate);
  formData.append('pageAdminTitle', this.groupDetail.pageAdminTitle);
  formData.append('pageContentTitle', this.groupDetail.pageContentTitle);
  formData.append('file', null);
  formData.append('customUrl', 'https://freefusetest.be/my-group/');
  this.spinner = true;   
  this.crudService.updateGroup(this.sellerInformation.featuredVideoGroupId, formData).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
    this.isTitleEdit = false;
  }).add(() => this.spinner = false);
}


showInput(){
  if(this.isOwnCommunity){
    this.isTitleEdit = true
    setTimeout(() => {
      if (this.groupInput) {
        this.groupInput.nativeElement.focus();
      }
    }, 500);
  }
}

isMoveContent:boolean = false;

moveGroupContent(item: any) {
  this.tabName = '';
  this.currentMoveItem = item;
  const dialogRef = this.dialog.open(this.moveToTabDialog, {
      width: '500px',
      maxWidth: '90%',
      panelClass: 'my-dialog',
      height: 'auto'
  });
  dialogRef.afterClosed().subscribe((result) => {
      if (result) {
          const payload: any = {
              id: item._id,
              newTabName: this.tabName
          }
          this.isMoveContent = true
          this.crudService.moveGroupContent(payload).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.crudService.getGroupTabsContentSize(this.sellerInformation.featuredVideoGroupId)
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
              this.groupDetail.userDefinedTabs = success.data
              this.tabName = '';
              this.groupActiveTabContent = [];
              this.toaster.success('Moved successfully')
              this.getGroupContentByTabName();
            })

          
            });
          } else {
            this.tabName = '';
          }
          setTimeout(() => {
            this.isMoveContent = false
          }, 2000);
  });
}


   // Delete Group content
   deleteGroupContent(item: any, index: any) {
    this.deleteDialogRef.open({
        header: 'Delete content',
        title: 'Are you sure? Do you want to delete this content?',
        trueButton: 'Delete'
    }, (e:any) => {
        if (e) {
            this.crudService.deleteGroupContent(this.sellerInformation.featuredVideoGroupId, item._id).pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            this.crudService.getGroupTabsContentSize(this.sellerInformation.featuredVideoGroupId)
              .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
                this.groupDetail.userDefinedTabs = success.data
                this.toaster.success('Deleted successfully')
                  this.getGroupContentByTabName()
            })

              });
        }
    });
}

fliterGroupNameWithSpecialChar(groupName:any){
  return groupName.replace(/&amp;/g, '&');
}


changeCurrentTopic(value:any){
  this.currentTopicValue = value;
  this.isPinnedComments = false;
  this.getAllComments(true);
}

getUserInfo(){
  this.crudService.getUserInfoV2(this.sellerId).subscribe((res) => {
    if (!res.data?.userInfo) {
      this.router.navigateByUrl("/");
      return;
    }
    this.spinner = false;
    this.sellerInformation = res.data?.userInfo;
  })
}

deleteCommunityTopic(value:any){
  this.currentTopicValue = value;
  this.deleteDialogRef.open({
      header: 'Remove Tab',
      title: 'Are you sure you want to remove this topic?',
      trueButton: 'Remove'
  }, (e) => {
      if (e) {
          this.spinner = true;
          const payload:any = {
              topicValue: value
          }

          this.crudService.deleteCommunityTopic(payload).subscribe((res) => {
              this.spinner = false;
              this.getUserInfo();
              this.currentTopicValue = "Forum";
          })
      }
  })
}

addTopic(){
  this.topicValue = '';
  $('#addCommentTopicModal').modal('show');
}

createTopic(){
  this.spinner = true;
  this.isPinnedComments = false;
  const payload:any = {
      topicValue: this.topicValue,
      topicDescription: this.topicDescription
  }
  this.crudService.createCommunityTopic(payload).subscribe((res)=>{
      this.spinner = false;
      this.currentTopicValue = this.topicValue;
      this.getUserInfo();
      $('#addCommentTopicModal').modal('hide');
  }, (error) => {
      this.spinner = false;
  })
}

reOrderTopics(){
  this.allTopicsForReOrder = this.sellerInformation?.communityTopics;
  $('#reOrderGroupTopic').modal('show') 
}

dropTopics(event: CdkDragDrop<string[]>){
  let data = [];
  moveItemInArray(this.allTopicsForReOrder, event.previousIndex, event.currentIndex);
  data = this.allTopicsForReOrder.map((value:any,index:number) => ({ name: value.name, index: index }))
  let payload = {
      orderTopics: data
  }
  this.crudService.reOrderCommunityTopics(payload).subscribe((res)=>{
      this.getUserInfo();
  })
}

handleOffcanvasClose(){
  const offcanvasElement = this.offcanvasRight.nativeElement;

      // if (offcanvasElement.classList.contains('hiding')) {
          document.body.style.overflow = 'auto'; // or any default color
      // } 
}


getFollowers(reset = false){

  if (reset) {
      this.userPage = 1;
      this.myFollowers = [];
  }

  this.crudService.getUsersForMention('',this.userPage, this.userLimit ).pipe(takeUntil(this._unsubscribeAll)).subscribe(
    success => {
        const followersArray = success.data;
        for (const item of followersArray) {
            this.myFollowers.push(item);
        }
        // console.log("myFollowers", this.myFollowers);
        
    }
)
}


getSubscribers(reset = false) {
  if (reset) {
    this.subscriberPage = 1;
    this.mySubscribers = []; 
  }

  this.crudService.getSubscribers(this.sellerId, this.subscriberPage, this.subscriberLimit)
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(
      success => {
        const subscriberArray = success.data;
        
        if (reset) {
          this.mySubscribers = subscriberArray; 
        } else {
          this.mySubscribers = this.mySubscribers.concat(subscriberArray); 
        }
      }
    )
}

onNgModelChange(value: string): void {

  // console.log("onNgModelChange - value = ", value);
  // console.log("onNgModelChange - this.isMentioning = ", this.isMentioning);

  //isMentioning is set to true when the user presses '@'
  if (this.isMentioning) { 

      this.newMentionPos = this.findStartMentionPos(value);
      // console.log("onNgModelChange - newMentionPos = ", this.newMentionPos);

      if (this.newMentionPos != null) {
    
          if (this.newMentionPos.insertPos == "end of string") {
              const lastMentionUserName = this.extractLastUsername(value, 'comment');
              // console.log("onNgModelChange - lastMentionUserName = ", lastMentionUserName);
      
              this.searchUserName = lastMentionUserName?.substring(1);
              // console.log("onNgModelChange - this.searchUserName for end of string mention = ", this.searchUserName);
    
              this.searchFollowers(this.searchUserName);
      
          } else {
              // console.log("onNgModelChange - this.searchUserName for NOT end of string mention = ", this.searchUserName);
    
              this.searchFollowers(this.searchUserName);
          }
      }
  };

  if ((this.isMentioning) && !this.isPressEnter) {
    this.isOpenUserMenu = true;
    // console.log("opening up the pop up menu")

  } 
}

onNgModelChangeForUpdateComment(value:string){
  //isMentioning is set to true when the user presses '@'
  if (this.isMentioning) { 

      this.newMentionPos = this.findStartMentionPos(value);
      // console.log("onNgModelChange - newMentionPos = ", this.newMentionPos);

      if (this.newMentionPos != null) {
    
          if (this.newMentionPos.insertPos == "end of string") {
              const lastMentionUserName = this.extractLastUsername(value, 'updateComment');
              // console.log("onNgModelChange - lastMentionUserName = ", lastMentionUserName);
    
              this.searchUserName = lastMentionUserName?.substring(1);
              // console.log("onNgModelChange - this.searchUserName for end of string mention = ", this.searchUserName);
    
              this.searchFollowers(this.searchUserName);
    
          } else {
              // console.log("onNgModelChange - this.searchUserName for NOT end of string mention = ", this.searchUserName);
    
              this.searchFollowers(this.searchUserName);
          }
      };
  };

  if ((this.isMentioning) && !this.isPressEnter) {
    this.isOpenUserMenuForUpdate = true;
    // console.log("opening up the pop up menu")
  }
}

onNgModelChangeForReply(value:string){
        
  //isMentioning is set to true when the user presses '@'
  if (this.isMentioning) { 

      this.newMentionPos = this.findStartMentionPos(value);
      // console.log("onNgModelChange - newMentionPos = ", this.newMentionPos);

      if (this.newMentionPos != null) {
    
          if (this.newMentionPos.insertPos == "end of string") {
              const lastMentionUserName = this.extractLastUsername(value, 'reply');
              // console.log("onNgModelChange - lastMentionUserName = ", lastMentionUserName);
      
              this.searchUserName = lastMentionUserName?.substring(1);
              // console.log("onNgModelChange - this.searchUserName for end of string mention = ", this.searchUserName);
    
              this.searchFollowers(this.searchUserName);
      
          } else {
              // console.log("onNgModelChange - this.searchUserName for NOT end of string mention = ", this.searchUserName);
    
              this.searchFollowers(this.searchUserName);
          }
      }
  };

  if (this.isMentioning && !this.isPressEnter) {
    this.isOpenUserMenuForReply = true;
    // console.log("opening up the pop up menu")
  } 
}

addLineBreak(type: string) {

  let content: string;

  // Get the content based on the type
  if (type === 'postBodyEditor') {
    content = this.db.commentModal;
  } else if (type === 'replyCommentEditor') {
    content = this.db.replyModal;
  } else if (type === 'emailBodyForUpdateComment') {
    content = this.db.updateCommentModal;
  } else {
    return;
  }

  // Replace multiple consecutive empty <p> tags with a single <br> tag
  const replaceEmptyParagraphs = (content: string): string => {
    return content.replace(/(<p><\/p>\s*){2,}/g, (match) => {
      // The number of <p></p> tags in the match
      const emptyLineCount = match.match(/<p><\/p>/g)?.length || 0;
      // Use only one <br> tag for multiple empty paragraphs
      return '<br>';
    });
  };

  // Process the content to handle line breaks
  content = replaceEmptyParagraphs(content);

  // Ensure proper closing tag if needed
  if (!content.endsWith('</p>') && !content.endsWith('<br>')) {
    content += '</p>';
  }

  // Save the updated content back based on the type
  if (type === 'postBodyEditor') {
    this.db.commentModal = content;
  } else if (type === 'replyCommentEditor') {
    this.db.replyModal = content;
  } else if (type === 'emailBodyForUpdateComment') {
    this.db.updateCommentModal = content;
  }
}



onEnterKeyPressed(event: KeyboardEvent, type?: any) {
  // console.log("onEnterKeyPressed - event = ", event.key);
  
  this.editorActivity = type;

  if (event.key === '@') {
    this.isMentioning = true;
    this.isPressEnter = false;
    this.searchUserName = "";
    this.searchUser = "";
    this.myFollowers = [];
    this.keyPressStr = "";
    this.previousKeyPressStr = "@";

    // console.log("*** onEnterKeyPressed  - this.previousKeyPressStr = ", this.previousKeyPressStr)
      
    //Save the current message as the previous message so we can use it for comparison with the new message
    if (type === 'postBodyEditor')
      this.previousMessage = this.db.commentModal
    else if (type === 'replyCommentEditor')
      this.previousMessage = this.db.replyModal
    else
      this.previousMessage = this.db.updateCommentModal

  } else if (event.key === 'Enter') {
      event.preventDefault();
      if (!this.isMentioning){
        this.addLineBreak(type);
        return;
      }
      
      if (this.isMentioning && this.currentIndex >= 0) {
        // console.log("if calling", this.currentIndex, this.isMentioning)
        this.selectHighlightedUser();
        if(type === 'postBodyEditor'){
          this.db.commentModal = this.wrapMentionWithAnchorTag(this.db.commentModal);
          this.db.commentModal = this.stripLastEmptyParagraph(this.db.commentModal);
        }else if(type === 'replyCommentEditor'){
            this.db.replyModal = this.wrapMentionWithAnchorTag(this.db.replyModal);
            this.db.replyModal = this.stripLastEmptyParagraph(this.db.replyModal);
        }else{
            this.db.updateCommentModal = this.wrapMentionWithAnchorTag(this.db.updateCommentModal);
            this.db.updateCommentModal = this.stripLastEmptyParagraph(this.db.updateCommentModal);
        }
      }else{
        // console.log("else calling");
        this.selectHighlightedUser();
        if (type.startsWith('postBodyEditor')) {
          if (this.isOpenUserMenu) {
            this.isPressEnter = true;
            this.isAddSignValue = true;
          }
    
              this.db.commentModal = this.wrapMentionWithAnchorTag(this.db.commentModal);
              // console.log("this.comment ===> ", this.db.commentModal);
              
              this.newMentionPos = null
              
              this.isOpenUserMenu = false;
    
              this.db.commentModal = this.stripLastEmptyParagraph(this.db.commentModal);
          }
    
        if (type.startsWith('replyCommentEditor')) {
          if (this.isOpenUserMenuForReply) {
            this.isPressEnter = true;
            this.isAddSignValue = true;
          }
    
              this.db.replyModal = this.wrapMentionWithAnchorTag(this.db.replyModal);
          // console.log("this.comment ===> ", this.db.replyModal);
              this.newMentionPos = null
              
              this.isOpenUserMenuForReply = false;
    
              this.db.replyModal = this.stripLastEmptyParagraph(this.db.replyModal);
              // console.log("this.comment111 ===> ", this.db.replyModal);

    
          }
    
        if (type.startsWith('emailBodyForUpdateComment')) {
          if (this.isOpenUserMenuForUpdate) {
            this.isPressEnter = true;
            this.isAddSignValue = true;
          }
    
              this.db.updateCommentModal = this.wrapMentionWithAnchorTag(this.db.updateCommentModal);
    
              this.newMentionPos = null
              
              this.isOpenUserMenuForUpdate = false;
    
              this.db.updateCommentModal = this.stripLastEmptyParagraph(this.db.updateCommentModal);
    
          }
      }

      this.isMentioning = false;

  } else if (event.key === 'Backspace') {

    this.handleBackspace(event, type);

  }else if (event.key === 'ArrowUp'){

    event.preventDefault();
    this.moveHighlightUp(type);

  } else if (event.key === 'ArrowDown'){

    event.preventDefault();
    this.moveHighlightDown(type);

  } else {

    // console.log("onKeyPress - typing letter one by one - type = ", type);

    if (!this.isMentioning)
      return;
          
    //Ignore typing on the user modal search input
    if(type == 'postBodyEditor_userModal')
      return;
    else if (type == 'replyCommentEditor_userModal')
      return;
    else if (type == 'emailBodyForUpdateComment_userModal')
      return;

    //key press other than '@' or Enter and we are in the middle of mentioning inside the string
    if ((event.code.startsWith("Key") || event.code.startsWith("Digit") || event.code == "Space")) {

      this.previousKeyPressStr += event.key;
      // console.log("onKeyPress - this.previousKeyPressStr = ", this.previousKeyPressStr);

      this.keyPressStr += event.key;
      // console.log("onKeyPress - this.keyPressStr = ", this.keyPressStr);

      //If the user types Space right after @ then close the pop-up and set the isMentioning to false
      if (this.keyPressStr.trim() == "" && event.code == "Space") {
        this.isMentioning = false;

        if(type == 'postBodyEditor')
            this.isOpenUserMenu = false;
        else if (type == 'replyCommentEditor')
            this.isOpenUserMenuForReply = false;
        else if (type == 'emailBodyForUpdateComment')
            this.isOpenUserMenuForUpdate = false;
      }

      this.searchUserName += event.key;
      // console.log("onEnterKeyPressed - - Typing mention - this.searchUserName ", this.searchUserName);

    } 
  }
}

currentIndex: number = 0; 
@ViewChild('commentUsersContainer') commentUsersContainer: ElementRef;
@ViewChild('replyCommentUsersContainer') replyCommentUsersContainer: ElementRef;
@ViewChild('updateCommentUsersContainer') updateCommentUsersContainer: ElementRef;

moveHighlightUp(type:any) {
    // console.log("this.currentIndex ==>", this.currentIndex);
    
    if (this.currentIndex > 0) {
        this.currentIndex--;
        // console.log("this.currentIndex =>", this.currentIndex);
        
        this.scrollToHighlightedUser(type);
    }
}

moveHighlightDown(type:any) {
    if (this.currentIndex <= this.myFollowers.length - 1) {
        this.currentIndex++;
        this.scrollToHighlightedUser(type);
    }
}

scrollToHighlightedUser(type:any) {
    let container;
    if(type.startsWith('postBodyEditor')){
        container = this.commentUsersContainer.nativeElement;
    }else if(type.startsWith('replyCommentEditor')){
        container = this.replyCommentUsersContainer.nativeElement;
    }else if(type.startsWith("emailBodyForUpdateComment")){
        container = this.updateCommentUsersContainer.nativeElement;
    }
    const items = container.getElementsByClassName('follower-item');
    
    if (items[this.currentIndex]) {
        const item = items[this.currentIndex];
        const itemOffsetTop = item.offsetTop;
        const itemHeight = item.offsetHeight;
        const containerScrollTop = container.scrollTop;
        const containerHeight = container.clientHeight;

        const isAboveView = itemOffsetTop < containerScrollTop;
        const isBelowView = itemOffsetTop > containerScrollTop;

        if (isAboveView) {
            container.scrollTop = itemOffsetTop;
        } else if (isBelowView) {
            container.scrollTop = itemOffsetTop + itemHeight - containerHeight;
        }else{
            // console.log("else")
        }
    }
}

selectHighlightedUser() {
    if (this.currentIndex >= 0 && this.currentIndex < this.myFollowers.length) {
        // console.log("this.currentIndex", this.currentIndex);
        this.selectTagUser(this.myFollowers[this.currentIndex]);
    }
}

handleBackspace(event: KeyboardEvent, type?: any) {

  // console.log("in handleBackspace - event = ", event);

  // console.log("in handleBackspace - type = ", type);

  //Ignore typing on the user modal search input
  if(type == 'postBodyEditor_userModal')
    return;
  else if (type == 'replyCommentEditor_userModal')
    return;
  else if (type == 'emailBodyForUpdateComment_userModal')
    return;

  // console.log("in handleBackspace -- this.db.commentModal = ", this.db.commentModal);

  //Remove the last char from the user search
  this.searchUserName = this.searchUserName?.substring(0, this.searchUserName?.length - 1);
  // console.log("in handleBackspace -- this.searchUserName = ", this.searchUserName);

  this.previousKeyPressStr = this.previousKeyPressStr?.substring(0, this.previousKeyPressStr?.length - 1);
  // console.log("in handleBackspace -- this.previousKeyPressStr = ", this.previousKeyPressStr);

  const selection = window.getSelection();
  // console.log("in handleBackspace -- selection = ", selection);

  if (selection.rangeCount === 0) return;
  const range = selection.getRangeAt(0);
  // console.log("in handleBackspace -- range = ", range);

  const anchorNode = range.startContainer;
  const mentionTag: any = anchorNode.parentNode as HTMLElement;
  // console.log("in handleBackSpace - mentionTag",  mentionTag);
  // console.log("in handleBackSpace - mentionTag.innerText",  mentionTag.innerText);
  
  //Checking for anchor tag
  if (mentionTag && mentionTag.tagName === 'A' && mentionTag.href) {
    let mentionText = mentionTag.innerText;
    // console.log("in handleBackSpace -  mentionTag after setting from innerText",  mentionTag);
    const originalMentionText = mentionTag.innerText; // Save the original mention text with '@'
    mentionText = mentionText.startsWith('@') ? mentionText.substring(1) : mentionText; // Remove the '@' symbol
    const parentParagraph = mentionTag.parentElement as HTMLElement;

    // Remove the mention tag
    const textNode = document.createTextNode(' ');
    parentParagraph.replaceChild(textNode, mentionTag);

    // Restore the cursor position
    const newRange = document.createRange();
    newRange.setStart(textNode, 1);
    newRange.setEnd(textNode, 1);
    selection.removeAllRanges();
    selection.addRange(newRange);
    this.mentionUsers = this.mentionUsers.filter(user => !user.name.includes(mentionText));

    //Reset mentioning
    this.isMentioning = false;

    // Do not open user menu based on type
    // console.log("Backspace pressed inside mention tag - mentionText = ", mentionText);
    // console.log("Updated comment ===> ", this.db.commentModal);
  } else {

    //Not deleting anchored mention user but deleting/backspace non-anchored usernameletter by letter until it deletes @
    if (this.previousKeyPressStr == "") {
      this.isMentioning = false;

      if (type == 'postBodyEditor')
          this.isOpenUserMenu = false;
      else if (type === 'replyCommentEditor')
          this.isOpenUserMenuForReply = false;
      else if (type === 'emailBodyForUpdateComment')
          this.isOpenUserMenuForUpdate = false;

    } else if (this.previousKeyPressStr == "@") {
      //User backspaces until @
      // console.log("*** in handleBackspace -- REACHING @ - this.previousKeyPressStr = ", this.previousKeyPressStr);

      this.isMentioning = true;

      if (type == 'postBodyEditor')
          this.isOpenUserMenu = true;
      else if (type === 'replyCommentEditor')
          this.isOpenUserMenuForReply = true;
      else if (type === 'emailBodyForUpdateComment')
          this.isOpenUserMenuForUpdate = true;

    } else {
      //Use case type @ followed by text and select them and hit delete button
      //The isMentioning is still true but @ followed by text is deleted
      if (type == 'postBodyEditor' && this.isMentioning) {
        if (!this.db.commentModal.includes(this.previousKeyPressStr)) {
          this.isOpenUserMenu = false;
          this.isMentioning = false;
        }
      } else if (type == 'replyCommentEditor' && this.isMentioning) {
        if (!this.db.replyModal.includes(this.previousKeyPressStr)) {
          this.isOpenUserMenuForReply = false;
          this.isMentioning = false;
        }
      } else if (type == 'emailBodyForUpdateComment' && this.isMentioning) {
        if (!this.db.updateCommentModal.includes(this.previousKeyPressStr)) {
          this.isOpenUserMenuForUpdate = false;
          this.isMentioning = false;
        }
      }
    }
  }
}


wrapMentionWithAnchorTag(input: string) {

    if (input == null)
      return;
  
    // console.log("in wrapMentionWithAnchorTag - this.newMentionPos = ", this.newMentionPos);
    
    // Regular expression to find "@username" strings not wrapped in anchor tags

    // console.log("in wrapMentionWithAnchorTag - input = ", input);

    // Finds substrings that start with @ followed by characters that are not whitespace, <, or >.
    // Optionally allows additional words separated by spaces to be part of the match.
    // Ensures that the match is immediately followed by the closing paragraph tag </p>.
    // Applies this search globally to the entire input string, returning all such matches.

    var regex = null;

    if (this.newMentionPos?.insertPos == "end of string") {
        // regex = /(@[^\s<>]+(?:\s[^\s<>]+)*)(?=<\/p>)/g;
        regex = /(@[^\s<>]+(?:\s[^\s<>]+)*)(?=<\/p>|$)/g;


        // console.log("end of string regex = ", regex);
    } else {
        regex = /(@[^\s<>]+(?:\s[^\s<>]*)*<\/p><p>)/g;

        // console.log("in wrapMentionWithAnchorTag - NOT end of string regex = ", regex);
    }

    return input.replace(regex, (match, p1, p2) => {

        // console.log("in wrapMentionWithAnchorTag - match = ", match);
        // console.log("in wrapMentionWithAnchorTag - p1 = ", p1);
        // console.log("in wrapMentionWithAnchorTag - p2 = ", p2);

        let username = p1.slice(1); // Remove the '@' symbol

        // console.log("in wrapMentionWithAnchorTag - Before regex replacement - username = ", username)
        if (username.includes("</p><p>")) {

            const regex = /<\/p><p>/g;
            // Replace the last <p></p> with an empty string
            username = username.replace(regex, '');
            // console.log("in wrapMentionWithAnchorTag - After regex replacement - username = ", username)

            this.searchUserName = username;
        }
        
        // console.log("in wrapMentionWithAnchorTag - this.myFollowers = ", this.myFollowers);
        // console.log("in wrapMentionWithAnchorTag - this.searchUserName = ", this.searchUserName);

        const highlightedUser = this.myFollowers.find(user =>
            user.username?.toLowerCase().includes(this.searchUserName?.toLowerCase())
        );

        // console.log("in wrapMentionWithAnchorTag - highlighterUser = ", highlightedUser);

    
        const replacementText = `<a href="${environment.currentDomain}public-profile?user=${highlightedUser?._id}">@${highlightedUser?.username}</a>&nbsp;`;
        return replacementText;
      });
}

stripLastEmptyParagraph(htmlString: string) {
  // Regular expression to match the last <p></p> tag
  const regex = /<p><\/p>(?!.*<p><\/p>)/;
  // Replace the last <p></p> with an empty string
  return htmlString.replace(regex, '');
}

findStartMentionPos(newMessage: string) {
  //console.log("findStartMentionPos - newMessage = ", newMessage);

  // Loop through the characters of the new string
  for (let i = 0; i < newMessage.length; i++) {
    // console.log("this.previousMessage[i] ", this.previousMessage[i] , newMessage[i]);
    
    if (this.previousMessage[i] !== newMessage[i]) {
      // If the characters differ, check if the new character is '@'
      if (newMessage[i] === '@') {
          //If the new char is '@', check if it's added at the end or not
          //console.log("i = ", i);
          // console.log("newMessage.length = ", newMessage.length);

          //The number 5 here derives from newMessage = <p>@</p> and 4 chars length for </p> and then add 1
          if (i == (newMessage.length - 5))
              return {
                  "newMentionPos" : i,
                  "insertPos" : "end of string"
              };
          else {
              return {
                  "newMentionPos" : i,
                  "insertPos" : "not end of string"
              };
          }
      } 
    }
  }

  // If no new '@' was found, return -1
  // return {
  //     "newMentionPos" : -1,
  //     "insertPos" : ""
  // };

  return {
    "newMentionPos" : 1,
    "insertPos" : "end of string"
  }
}


extractLastUsername(htmlString: string, commentType:any) {
  // Regular expression to match any text within <p> tags
  const regex = /<p>(.*?)<\/p>/g;
  let match;
  let lastUsername = null;

  // Iterate over all matches to find the last one containing an @
  while ((match = regex.exec(htmlString)) !== null) {
      const content = match[1].trim();
      const atIndex = content.lastIndexOf('@');
      if (atIndex !== -1) {
          const potentialUsername = content.slice(atIndex).trim();                

          if(potentialUsername === '@'){
            this.currentIndex = 0;
            this.searchUser = null;
              if(commentType === 'comment'){
                  this.isOpenUserMenu = true;
              }else if(commentType === 'reply'){
                  this.isOpenUserMenuForReply = true;
              }else{
                  this.isOpenUserMenuForUpdate = true;
              }
          }

          if (potentialUsername.length > 1) {
              lastUsername = potentialUsername;
          } else {
              lastUsername = '@';
          }
      }
  }

  return lastUsername;
}


removeCommentTag(comment:any){
  const commentText = comment ? comment.replace(/<\/?[^>]+(>|$)/g, "") : '';        
  return commentText
}



openUserPopUp(type:any){
  this.searchUser=''
  this.currentMentionUsersType = 'followers'
  this.placeHolderValue = "Search followers"
  this.getFollowers(true)
  // this.getSubscribers(true)
  this.selectedCommentTypeForMentionUser = type;
  if(type === 'comment'){
      this.isOpenUserMenu = !this.isOpenUserMenu; 

      this.editorActivity = 'postBodyEditor'
      // Append "@" to the comment value
    if (this.db.commentModal) {
        // Check if the last element is a <p> tag and append "@"
        const regex = /<\/p>\s*$/i;
        
        // console.log("this.db.commentModal", this.db.commentModal);
        
        if (regex.test(this.db.commentModal)) {
            this.db.commentModal = this.db.commentModal.replace(regex, '@</p>');

            // console.log("this.db.commentModal after replace", this.db.commentModal);

        } else {
            this.db.commentModal = '<p>@</p>';
        }
    } else {
        this.db.commentModal = '<p>@</p>';
    }

    this.newMentionPos = this.findStartMentionPos(this.db.commentModal);

  }else if(type === 'updateComment'){
      this.isOpenUserMenuForUpdate = !this.isOpenUserMenuForUpdate;

      this.editorActivity = 'emailBodyForUpdateComment'
      // Append "@" to the comment value
      if (this.db.updateCommentModal) {
          // Check if the last element is a <p> tag and append "@"
          const regex = /<\/p>\s*$/i;
          
          if (regex.test(this.db.updateCommentModal)) {
              this.db.updateCommentModal = this.db.updateCommentModal.replace(regex, '@</p>');

              // console.log("this.comment after replace", this.db.updateCommentModal);

          } else {
              this.db.updateCommentModal = '<p>@</p>';
          }
      } else {
          this.db.updateCommentModal = '<p>@</p>';
      }

      this.newMentionPos = this.findStartMentionPos(this.db.updateCommentModal);

  }else{
      this.isOpenUserMenuForReply = !this.isOpenUserMenuForReply;

      this.editorActivity = 'replyCommentEditor'
      // Append "@" to the comment value
      if (this.db.replyModal) {
          // Check if the last element is a <p> tag and append "@"
          const regex = /<\/p>\s*$/i;
          
          if (regex.test(this.db.replyModal)) {
              this.db.replyModal = this.db.replyModal.replace(regex, '@</p>');

              // console.log("this.comment after replace", this.db.replyModal);

          } else {
              this.db.replyModal = '<p>@</p>';
          }
      } else {
          this.db.replyModal = '<p>@</p>';
      }

      this.newMentionPos = this.findStartMentionPos(this.db.replyModal);

  }        
}


getMentionedUser = (text: any) => {

  // Regular expression to match the user values in the URLs
  const userRegex = /user=([a-z0-9]+)/g;
  let users: any[] = [];
  let match;

  // Extract all matches and push the user values to the array
  while ((match = userRegex.exec(text)) !== null) {
      users.push(match[1]);
  }

  return users;
};

insertStringAt(original: string, insert: string, position: number) {
  // console.log("insertStringAt - position = ", position);
  // console.log("insertStringAt - original.length = ", original.length);
  // console.log("insertStringAt - insert = ", insert);

  if (position < 0 || position > original.length) {
    return original;
  } else {
    // console.log("original.slice(0, position) = ", original.slice(0, position));
    // console.log("original.slice(position) = ", original.slice(position));

    // console.log("insertString At - this.newMentionPos.insertPos = ", this.newMentionPos.insertPos);

    if (this.newMentionPos.insertPos == "end of string")
      return original.slice(0, position) + insert + original.slice(position);
    {
      //Insert from the filtered user list in the middle of the comment
      if (!original.slice(position).startsWith("</p><p>"))
          return original.slice(0, position) + insert + "</p><p>" + original.slice(position);
      else
          return original.slice(0, position) + insert + original.slice(position);
    }
  }   
}

replaceSubstringWithSpaces(str: string, pos1: number, length: number) {
  // console.log(" in replaceSubstringWithSpaces - str = ", str);
  // console.log(" in replaceSubstringWithSpaces - pos1 = ", pos1);
  // console.log(" in replaceSubstringWithSpaces - length = ", length);

  if (str == null) 
      return "";

  if (pos1 < 0 || length < 0 || pos1 >= str?.length) {
    return str;
  }
  
  // Calculate the end position
  const endPos = Math.min(pos1 + length, str?.length);
  
  // Create a string of spaces with the required length
  const spaces = ''.repeat(endPos - pos1);
  
  // Replace the substring with spaces
  const result = str?.slice(0, pos1) + spaces + str?.slice(endPos);
  
  return result;
}

selectTagUser(item:any, type?:any) {
  // console.log("In selectTagUser - item = ", item);
  // console.log("In selectTagUser - this.newMentionPos.insertPos = ", this.newMentionPos.insertPos);
  // console.log("In selectTagUser - this.newMentionPos.newMentionPos = ", this.newMentionPos.newMentionPos);
  // console.log("In selectTagUser - this.editorActivity = ", this.editorActivity);
  // console.log("this.searchUserName **= ", this.searchUserName);

  //this.searchUserName = item.username;

  var updatedComment = "";
  var updatedReply = "";
  var updatedUpdateComments = "";

  //A new comment
  if (this.editorActivity.startsWith("postBodyEditor")) {

    //Delete any string after this.newMentionPost.newMentionPos that equals to the searchUserName before inserting the selected user name from the dropdown list
    //When selecting a user from the dropDrown directly without typing any partial username, this.comment doesn't contain any string which equals to this.searchUserName so we have to check
    //If the user types @ followed by several chars and then decides to select the user from the dropdown list then the comment will contain searchUserName
    //if (this.db.commentModal.includes(this.searchUserName)) {

    //if keyPressStr is greater than 0 that means the user is typing the mentioned username
    if (this.keyPressStr.length > 0) {
      updatedComment = this.replaceSubstringWithSpaces(this.db.commentModal, this.newMentionPos.newMentionPos + 1, this.searchUserName.length)
      // console.log("In selectTagUser - updatedComment AFTER REMOVING OLD USERNAME = ", updatedComment);
    } else {
      updatedComment = this.db.commentModal;
    }

    this.searchUserName = item.username;

    //Adding 3 to the newMentPos to insert in the next position
    updatedComment = this.insertStringAt(updatedComment, item.username, this.newMentionPos.newMentionPos + 1);
    // console.log("In selectTagUser - updatedComment after inserting selected user tag = ", updatedComment);

    this.db.commentModal = this.wrapMentionWithAnchorTag(updatedComment);

    this.previousMessage = this.db.commentModal;
  } else if (this.editorActivity.startsWith("replyCommentEditor")) { //user replying to a comment

    //if (this.db.replyModal.includes(this.searchUserName)) {
    //if keyPressStr is greater than 0 that means the user is typing the mentioned username
    if (this.keyPressStr.length > 0) {
      //Delete any string after this.newMentionPost.newMentionPos that equals to the searchUserName before inserting the selected user name from the dropdown list
      updatedReply = this.replaceSubstringWithSpaces(this.db.replyModal, this.newMentionPos.newMentionPos + 1, this.searchUserName.length)
      // console.log("In selectTagUser - updatedComment AFTER REMOVING OLD USERNAME = ", updatedComment);
    } else {
      updatedReply = this.db.replyModal;
    }

    this.searchUserName = item.username;

    //Adding 3 to the newMentPos to insert in the next position
    updatedReply = this.insertStringAt(updatedReply, item.username, this.newMentionPos.newMentionPos + 1);
    // console.log("In selectTagUser - updatedReply after inserting selected user tag = ", updatedReply);

    this.db.replyModal = this.wrapMentionWithAnchorTag(updatedReply);

    this.previousMessage = this.db.replyModal;
  } else {
    // console.log("this.db.updateCommentModal ==> ", this.db.updateCommentModal);
    
    //if (this.db.updateCommentModal.includes(this.searchUserName)) {
    //if keyPressStr is greater than 0 that means the user is typing the mentioned username
    if (this.keyPressStr.length > 0) {
      //Delete any string after this.newMentionPost.newMentionPos that equals to the searchUserName before inserting the selected user name from the dropdown list
      updatedUpdateComments = this.replaceSubstringWithSpaces(this.db.updateCommentModal, this.newMentionPos.newMentionPos + 1, this.searchUserName.length)
      // console.log("In selectTagUser - updatedUpdateComments AFTER REMOVING OLD USERNAME = ", updatedUpdateComments);
    } else {
      updatedUpdateComments = this.db.updateCommentModal;
    }

    this.searchUserName = item.username;

    //Adding 3 to the newMentPos to insert in the next position
    updatedUpdateComments= this.insertStringAt(updatedUpdateComments, item.username, this.newMentionPos.newMentionPos + 1);
    // console.log("In selectTagUser - updatedUpdateComments after inserting selected user tag = ", updatedUpdateComments);

    this.db.updateCommentModal = this.wrapMentionWithAnchorTag(updatedUpdateComments);

    this.previousMessage = this.db.updateCommentModal;
  }
  
  this.isOpenUserMenu = false;
  this.isOpenUserMenuForUpdate = false;
  this.isOpenUserMenuForReply = false;
  this.isAddSignValue = true;
  //this.getFollowers(true);
  this.isMentioning = false;
  this.searchUserName = "";
  this.searchUser = "";
}

searchFollowers(value:any){
  this.crudService.getUsersForMention(value).pipe(takeUntil(this._unsubscribeAll)).subscribe(
    success => {
        this.myFollowers = success.data
        this.currentIndex = 0;
        // console.log("in searchFollowers - this.myFollowers = ", this.myFollowers);
    }
)
}

onScrollUsers(){  
  if(this.currentMentionUsersType === 'followers'){
    ++this.userPage;
    this.getFollowers();
  }else{
    ++this.subscriberPage;
    this.getSubscribers();
  }
}

changeMentionUserType(type:any){
  this.currentMentionUsersType = type;             
  this.searchUser = "";  
  if(type === 'followers'){
    this.placeHolderValue = "Search followers"
    this.myFollowers = [];
    this.getFollowers(true)
  }else{
    
    // console.log("called");
    
    this.mySubscribers = [];
    this.placeHolderValue = "Search subscribers"
    this.getSubscribers(true)
  }
}
clickRightArrow() {
  const scrollRight = document.getElementById("right-arrow");
  const scrollLeft = document.getElementById('left-arrow');
  const productListContainer = document.getElementById("theme-scrollbar");

  // Function to check arrow visibility
  const checkIfEndReached = () => {
      const maxScrollLeft = productListContainer.scrollWidth - productListContainer.clientWidth;
      scrollRight.style.display = (productListContainer.scrollLeft >= maxScrollLeft) ? 'none' : 'block';
      scrollLeft.style.display = (productListContainer.scrollLeft <= 0) ? 'none' : 'block';
  };

  // Click event for right arrow
  scrollRight.addEventListener('click', () => {
      const scrollAmount = productListContainer.clientWidth;
      const maxScrollLeft = productListContainer.scrollWidth - productListContainer.clientWidth;

      // Scroll right, ensuring not to exceed maxScrollLeft
      productListContainer.scrollBy({
          top: 0,
          left: Math.min(scrollAmount, maxScrollLeft - productListContainer.scrollLeft),
          behavior: 'smooth'
      });

      setTimeout(checkIfEndReached, 500); // Check after scroll animation completes
  });

  // Click event for left arrow
  scrollLeft.addEventListener('click', () => {
      const scrollAmount = productListContainer.clientWidth;

      // Scroll left, ensuring not to go below 0
      productListContainer.scrollBy({
          top: 0,
          left: -Math.min(scrollAmount, productListContainer.scrollLeft),
          behavior: 'smooth'
      });

      setTimeout(checkIfEndReached, 500); // Check after scroll animation completes
  });

  // Check arrows visibility on scroll and initial load
  productListContainer.addEventListener('scroll', checkIfEndReached);

  // Initial check to set arrows visibility
  checkIfEndReached();
}
clickRightArrowBoard() {
  const scrollRight = document.getElementById("right-arrow-board");
  const scrollLeft = document.getElementById('left-arrow-board');
  const productListContainer = document.getElementById("theme-scrollbar-board");

  // Function to check arrow visibility
  const checkIfEndReached = () => {
      const maxScrollLeft = productListContainer.scrollWidth - productListContainer.clientWidth;
      scrollRight.style.display = (productListContainer.scrollLeft >= maxScrollLeft) ? 'none' : 'block';
      scrollLeft.style.display = (productListContainer.scrollLeft <= 0) ? 'none' : 'block';
  };

  // Click event for right arrow
  scrollRight.addEventListener('click', () => {
      const scrollAmount = productListContainer.clientWidth;
      const maxScrollLeft = productListContainer.scrollWidth - productListContainer.clientWidth;

      // Scroll right, ensuring not to exceed maxScrollLeft
      productListContainer.scrollBy({
          top: 0,
          left: Math.min(scrollAmount, maxScrollLeft - productListContainer.scrollLeft),
          behavior: 'smooth'
      });

      setTimeout(checkIfEndReached, 500); // Check after scroll animation completes
  });

  // Click event for left arrow
  scrollLeft.addEventListener('click', () => {
      const scrollAmount = productListContainer.clientWidth;

      // Scroll left, ensuring not to go below 0
      productListContainer.scrollBy({
          top: 0,
          left: -Math.min(scrollAmount, productListContainer.scrollLeft),
          behavior: 'smooth'
      });

      setTimeout(checkIfEndReached, 500); // Check after scroll animation completes
  });

  // Check arrows visibility on scroll and initial load
  productListContainer.addEventListener('scroll', checkIfEndReached);

  // Initial check to set arrows visibility
  checkIfEndReached();
}


highlightUser(index:any){
  this.currentIndex = index;
}

apiCalled: boolean = false; // Initialize flag

showUserDetails(event: MouseEvent, type: any) {
  const targetElement = event.target as HTMLElement;
  let editorElement;
  let user;
  let isDisplayTootip = false;
  let username;
  let userId;
  // Access ngx-editor element using document.getElementById()
  if (type === 'comment') {
      editorElement = document.getElementById('emailBody');
  }
  if (type === 'updateComment') {
      editorElement = document.getElementById('emailBodyForUpdateComment');
  }
  if (type === 'replyComment') {
      editorElement = document.getElementById('reply');
  }

  if (editorElement) {
      // Find all <a> tags within ngx-editor content
      const mentionLinks = editorElement.querySelectorAll('a');

      mentionLinks.forEach(link => {
          link.addEventListener('mouseenter', () => {
            isDisplayTootip = true;

              const href = link.getAttribute('href');
                const urlParams = new URLSearchParams(href.split('?')[1]);
                userId = urlParams.get('user');

                if(!userId){
                  return
                }
            
              username = link.textContent?.trim().substring(1); // Extract username from mention link

              if (!this.apiCalled) { // Check if API call has already been made
                  this.apiCalled = true; // Set flag to true

                this.crudService.getUserInfoV2(userId).pipe(takeUntil(this._unsubscribeAll)).subscribe(
                  success => {
                    user = success.data?.userInfo;
                      if (user && isDisplayTootip && user._id === userId) {
                          const tooltip = document.createElement('div');
                          tooltip.classList.add('tooltip001');
        
                          // Create outer span
                          const outerSpan = document.createElement('span');
        
                          // Create image element if profilePic exists
                          if (user.profilePic) {
                              const img = document.createElement('img');
                              img.src = user.profilePic;
                              img.width = 32;
                              img.height = 32;
                              img.alt = 'Profile Picture';
                              outerSpan.appendChild(img);
                          } else {
                              const firstnameFirstLetter = user.firstname ? user.firstname.charAt(0) : '';
                              const lastnameFirstLetter = user.lastname ? user.lastname.charAt(0) : '';
        
                              const emptySpan = document.createElement('span');
                              emptySpan.classList.add('profile-image');
                              emptySpan.setAttribute('title', `${user.firstname}, ${user.lastname}`);
                              emptySpan.textContent = `${firstnameFirstLetter}${lastnameFirstLetter}`;
                              outerSpan.appendChild(emptySpan);
                          }
        
                          // Create inner span for username
                          const innerSpan = document.createElement('span');
                          innerSpan.classList.add('username');
                          innerSpan.textContent = user.username;
                          outerSpan.appendChild(innerSpan);
        
                          // Append the entire structure to tooltip
                          tooltip.appendChild(outerSpan);
        
                          // Calculate position based on mention link position
                          const linkRect = link.getBoundingClientRect();
                          const tooltipX = linkRect.left - editorElement.getBoundingClientRect().left - tooltip.offsetHeight - 1;
                          const tooltipY = linkRect.top - editorElement.getBoundingClientRect().top - tooltip.offsetHeight - 45;
                          tooltip.style.left = tooltipX + 'px';
                          tooltip.style.top = tooltipY + 'px';
        
                          // Append tooltip to the document body or relevant container
                          editorElement.appendChild(tooltip);
                      }
                  }
              )
                
              }
          });

          link.addEventListener('mouseleave', () => {
              const tooltip = document.querySelector('.tooltip001');
              userId = null;
              user = null;
              username = null;
              isDisplayTootip = false;
              if (tooltip) {
                  tooltip.remove(); // Remove the tooltip when mouse leaves the mention link
              }
              this.apiCalled = false; // Reset flag for the next hover
              // this.myFollowers = []; // Clear any fetched data
          });
      });

  } else {
      // console.log("Element with id 'emailBody' not found.");
  }
}

  updateReplyCommentId: any;

  updateReplyComment(item: any) {
    if (this.isDisableDiscussionBoard) {
      return;
    }
    this.selectedCommentTypeForMentionUser = 'updateComment'

    this.db.replyModal = item.comment;
    this.previousMessage = this.db.replyModal;

    this.commentId = item.replyOnCommentID;
    this.updateReplyCommentId = item._id;

    if (item.type === "VIDEO") {
      this.UpdatedCommentVideoType = item.type
    }
    if (item.type === "OPEN_COLLAB") {
      this.UpdatedCommentVideoType = item.type
    }

    // // Delay modal opening
    // $('#update_comment_reply').modal('hide'); // Ensure it's hidden first

    // setTimeout(() => {
    //     $('#update_comment_reply').modal('show');
    // }, 0); // A small timeout to ensure bindings are updated
  }

  editGroupTopic(topicName: any, topicDescription: any, index: number) {
    this.editTopicValue = topicName;
    this.isPinnedComments = false;
    this.topicValue = topicName;
    this.topicDescription = topicDescription;
    this.editTopicIndex = index;
    $('#addCommentTopicModal').modal('show');
  }

  closeTopicModal() {
    this.topicValue = '';
    this.editTopicValue = '';
    this.editTopicIndex = '';
    this.topicDescription = '';
    $('#addCommentTopicModal').modal('hide');
  }

  editTopic() {
        this.spinner = true;
        this.isPinnedComments = false;
            const payload:any = {
                groupId: this.groupId,
                topicValue: this.topicValue,
                index: this.editTopicIndex,
                topicDescription: this.topicDescription
            }
      this.crudService.editCommunityTopic(payload).subscribe((res)=>{
                this.spinner = false;
                this.getUserInfo();
                this.currentTopicValue = this.topicValue;
                $('#addCommentTopicModal').modal('hide');
                this.topicValue = '';
                this.editTopicValue = '';
                this.editTopicIndex = '';
                this.topicDescription = '';
            }, (error) => {
                this.spinner = false;
        })
  }
  
  isPinnedComment(comment) {
    let isPined = false;
    if (comment?.pinnedByUserId?.includes(this.currentUserId)) {
      isPined = true
    } else {
      isPined = false
    }

    return isPined
  }

  setPinComment(comment: any) {
    let obj = {
      commentId: comment._id,
    }
    this.crudService.setPinnedGroupMessage(obj).subscribe((res: any) => {
      if (!comment.replyOnCommentID || comment.replyOnCommentID === null) {
        this.allComments.map(x => {
          if (x._id === comment._id) {
            if (x?.pinnedByUserId?.length) {
              x?.pinnedByUserId.push(this.currentUserId)
            } else {
              x.pinnedByUserId = [this.currentUserId]
            }
          }
        })
      } else {
        this.allComments.map(x => {
          if (x._id === comment.replyOnCommentID) {
            x.replies.map(y => {
              if (y._id === comment._id) {
                if (y?.pinnedByUserId?.length) {
                  y?.pinnedByUserId.push(this.currentUserId)
                } else {
                  y.pinnedByUserId = [this.currentUserId]
                }
              }
            })
          }
        })
        if (this.isPinnedComments) {
          this.getPinnedComments(this.currentTopicValue)
        }
      }
    }, (error) => {
      console.log("error: ", error);
    })

  }

  removePinComment(comment: any) {
    let obj = {
      commentId: comment._id,
    }
    this.crudService.removePinnedGroupMessage(obj).subscribe((res: any) => {
      if (!comment.replyOnCommentID || comment.replyOnCommentID === null) {
        this.allComments.map(x => {
          if (x._id === comment._id) {
            if (x?.pinnedByUserId?.length) {
              let index = x.pinnedByUserId.indexOf(this.currentUserId);
              if (index > -1) {
                x.pinnedByUserId.splice(index, 1);
              }
            }
          }
        })
      } else {
        this.allComments.map(x => {
          if (x._id === comment.replyOnCommentID) {
            x.replies.map(y => {
              if (y._id === comment._id) {
                if (y?.pinnedByUserId?.length) {
                  let index = y.pinnedByUserId.indexOf(this.currentUserId);
                  if (index > -1) {
                    y.pinnedByUserId.splice(index, 1);
                  }
                }
              }
            })
          }
        })
      }
      if (this.isPinnedComments) {
        this.getPinnedComments(this.currentTopicValue)
      }
    }, (error) => {
      console.log("error: ", error);
    })
  }

  getPinnedComments(topic: any) {
    this.currentTopicValue = topic;
    this.isPinnedComments = true;
    this.getAllComments(true);
  }

  isShowLikeButton(likeUsers: any): boolean {
    const hasLiked = likeUsers.some((i: any) => i.userId === this.currentUserId);

    // Show the like button only if the current user hasn't liked the comment yet.
    return !hasLiked;
  }

  isShowDisLikeButton(likeUsers: any): boolean {
    const hasLiked = likeUsers.some((i: any) => i.userId === this.currentUserId);

    // Show the dislike button only if the current user has liked the comment.
    return hasLiked;
  }


}
