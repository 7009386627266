import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService} from 'ngx-toastr';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-saml',
  templateUrl: './saml.component.html',
  styleUrls: ['./saml.component.css']
})
export class SamlComponent implements OnInit {

  companyId = "";
  ssoParamId = "";

  constructor(
    private toastr: ToastrService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {

    this.activeRoute.params.subscribe(params => {
      if (params.companyId == undefined)
        this.toastr.error('Missing Company Id');
      else
        this.companyId = params.companyId;

    })

    this.activeRoute.queryParams.subscribe(params => {
      if (params.id != undefined)
        this.ssoParamId = params.id;
    })
  }

  ngOnInit(): void {

    //WC 03242024 --- SSO URL: https://freefusetest.be/saml/TestCompany for redirect to group page
    // or https://freefusetest.be/saml/TestCompany?id=<videoId> to redirect to videos page with videoId
    // console.log("this.companyId = ", this.companyId);

    if (this.ssoParamId != "")
      var url = environment.API_URLV2 + `/auth/saml/${this.companyId}?id=${this.ssoParamId}`;
    else
      var url = environment.API_URLV2 + `/auth/saml/${this.companyId}`;

    // console.log(url)
    this.router.navigate([]).then(result => {
        window.location.replace(url)
    });
  }

}
