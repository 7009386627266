import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CrudService } from 'src/app/services/crud.service';
import { CreateCouponComponent } from '../create-coupon/create-coupon.component';
import { MatDialog } from '@angular/material/dialog';
import { CreatePromoCodeComponent } from '../create-promo-code/create-promo-code.component';

@Component({
  selector: 'app-edit-coupon',
  templateUrl: './edit-coupon.component.html',
  styleUrls: ['./edit-coupon.component.css']
})
export class EditCouponComponent implements OnInit {

  couponDetail;
  promoCodes = [];
  spinner: boolean = false;
  processing: boolean = false;
  customerList = [];

  constructor(
    private urlService: CrudService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.spinner = true;
    this.activatedRoute.params.subscribe((params) => {
      this.getCouponDetail(params.id);
    });
    //this.getAllPromoCodes();
  }

  //couponId is Stripe coupon id
  getCustomerCouponRedemptions(couponId: string) {
    this.spinner = true;

    //console.log("getCustomerCouponRedemptions - couponId = ", couponId);

    this.urlService.listCustomerCouponRedemptions(couponId).subscribe((res: any) => {
      this.customerList = res.data;

      //console.log("this.customerList = ", this.customerList);
      this.spinner = false;
    });
  }

  // formatUTCDateToMMDDYY(date) {
  //   const year = date.getUTCFullYear().toString().slice(-2); // Get last 2 digits of year
  //   const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  //   const day = date.getUTCDate().toString().padStart(2, '0');

  //   //return `${month}/${day}/${year}`;
  //   console.log("formatUTCDateToMMDDYY - Date = ", `${month}/${day}/${year}`)
  // }

  // unixTimestampToUTCDate(unixTimestamp) {
  //   // Create a new Date object using the Unix timestamp
  //   const date = new Date(unixTimestamp);

  //   // Convert to UTC string
  //   return date.toUTCString();
  // }

  unixTSToUTCTS(unixTS) {

    // Create a new Date object using the Unix timestamp
    const date = new Date(unixTS);

    // Format the date and time in UTC
    const year = date.getUTCFullYear();
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
    const day = date.getUTCDate().toString().padStart(2, '0');
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    const seconds = date.getUTCSeconds().toString().padStart(2, '0');

    //console.log(`${month}/${day}/${year} ${hours}:${minutes}:${seconds}`)

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  }

  getCouponDetail(id: string) {
    this.spinner = true;
    this.urlService.getCouponDetail(id).subscribe((res: any) => {
      this.couponDetail = res.data;
      //console.log("this.couponDetail = ", this.couponDetail);
      this.promoCodes = this.couponDetail.promoCodes;
      //console.log("this.promoCodes = ", this.promoCodes);

      this.promoCodes.forEach(element => {

        element.info.created = this.unixTSToUTCTS(element.info.created * 1000);

        if (element.info.expires_at != null) {
          //let expiresAt = this.datePipe.transform(this.unixTSToUTCTS(element.info.expires_at * 1000), 'MM/dd/yyyy');
          //let utcDate = this.unixTimestampToUTCDate(element.info.expires_at * 1000);

          let expiresAt = this.unixTSToUTCTS(element.info.expires_at * 1000);

          element.info.expires_at = expiresAt;
          //console.log("promoCode expires_at = ", element.info.expires_at);
        } else 
          element.info.expires_at = "";
      });

      this.getCustomerCouponRedemptions(this.couponDetail.couponId);

      this.spinner = false;
    });

    
  }

  editCouponDetail() {
    const dialogRef = this.dialog.open(CreateCouponComponent, {
      width: '100%',
      maxWidth: '600px',
      maxHeight: '600px',
      panelClass: 'my-dialog',
      height: 'auto',
      data: {
        couponData: this.couponDetail ? this.couponDetail : '',
      }
    });

    dialogRef.afterClosed().subscribe((result) => {

      //console.log("result = ", result);

      if (result === 'deleteCoupon') {
          this.router.navigate(['/coupon']);
        } else {
          //this.getCouponDetail(result.data);
          this.getCouponDetail(this.couponDetail._id);
        }
    });
  }

  createEditPromoCode(data) {

    //console.log("createEditPromoCode - data = ", data);

    if (data == '') {
      data = {
        couponId: this.couponDetail._id,
      }

      //console.log("After assignment - data = ", data)
    }

    const dialogRef = this.dialog.open(CreatePromoCodeComponent, {
      width: '100%',
      maxWidth: '600px',
      maxHeight: '600px',
      panelClass: 'my-dialog',
      height: 'auto',
      data: {
        promoCodeData: data ? data : '',
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //this.getAllPromoCodes();
        this.getCouponDetail(this.couponDetail._id);
      }
    });
  }

  updatePromoCodeStatus(promoCode) {
    //console.log("updatePromoCodeStatus - promoCode = ", promoCode);
      
    this.processing = true;
    let formData = {};
    formData['id'] = promoCode._id;
    formData['isActive'] = promoCode.info.active;
    this.urlService.updatePromoCode(formData).subscribe((res) => {
      this.processing = false;
      this.toastr.success('Promo code updated successfully !!');
    }, (err) => {
      this.processing = false;
      promoCode.info.active = !promoCode.info.active;
    })
  }

  trackByIndex(index: number): number {
		return index;
	}

}
