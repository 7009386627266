import { Component, OnInit } from '@angular/core';
import { CrudService } from 'src/app/services/crud.service';
import { Router, ActivatedRoute } from '@angular/router';
import {LocalStorageService} from '../../services/localStorage.service';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/services/dashboard.service';
declare var $;

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  passwordPattern = /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/;
  paramsValue: any;
  passwordMismatch: any;

  constructor(private urlService: CrudService,
              private router: Router,
              private localStorageService: LocalStorageService,
              private dashboardService: DashboardService,
              private toastr: ToastrService,
              private activeRoute: ActivatedRoute) {

    this.activeRoute.queryParams.subscribe(params => {
      this.paramsValue = params
      // console.log("OnInit params.cid = ", params.cid);
      // console.log("params.promoCode = ", params.promoCode);
      // console.log("parans.redirect = ", params.redirect);

      if (params.redirect == 'pricing') 
        this.localStorageService.setItem('isPricingRedirect', 'true');
      else {
        //WC 02012024 --- To handle redirect to other page
        this.localStorageService.setItem('redirect', params.redirect);
        //console.log("signup - this.localStorageService.getItem('redirect') = ", this.localStorageService.getItem('redirect'));
      }

      // WC 07112022 - Marketing campaign ID is passed in the "cid" query string
      // WC 07122022 - Promo code ex: DISC-10 which means discount 10% passed in the "promoCode" query string
      // WC 03072023 - To default CID to FF02 if it's not provided
      if (params.cid || params.promoCode) {

        if (params.cid) {
          this.localStorageService.setItem('campaignId', params.cid);
          // console.log("this.localStorageService.getItem('campaignId')", this.localStorageService.getItem('campaignId'));
        }

        if (params.promoCode) {
          this.localStorageService.setItem('promoCode', params.promoCode);
        }

        const payload = {
          campaignId : params.cid,
          promoCode : params.promoCode,
          originUrl: window.location.href
        };

        this.urlService.setMarketingCampaign(payload).subscribe();
        // console.log("Marketing Campaign / Promo Code is saved");

      } else {

        // From Pricing section of the landing page
        if (this.localStorageService.getItem('isPricingRedirect') == 'true') {
          this.localStorageService.setItem('campaignId', 'Pricing');

          const payload = {
            campaignId : 'Pricing',
            originUrl: window.location.href
          };

          this.urlService.setMarketingCampaign(payload).subscribe();

        } else {
          // Not from pricing section of the landing
          if (params.cid == undefined || params.cid == null) {
            this.localStorageService.setItem('campaignId', 'FF02');
            // console.log("this.localStorageService.getItem", this.localStorageService.getItem('campaignId'));

            const payload = {
              campaignId : 'FF02',
              originUrl: window.location.href
            };

            this.urlService.setMarketingCampaign(payload).subscribe();
          }
        }
      }
   });

  }


  public signupObject: any = { email: '', firstname: '', lastname: '', username: '', password: '', cPassword: '', isFirstFormCompleted: false, spinner: false, highSchool: '' }

  public fieldPassTextType = false;
  public fieldCPassTextType = false;
  public orgName = "";
  public highSchoolList = [];

  isPersonalize:any;
  groupId: any;

  ngOnInit() {

    // Existing user
    if (this.localStorageService.getItem('token')) {

      // WC 08202022 - org passed in the query string
      this.activeRoute.queryParams.subscribe(params => {

        if (params.org) {

          const payload = {
            org: params.org
          };

          this.urlService.updateUserOrg(payload).subscribe(success => {
            // console.log("org value is updated!", success)
          });
        };
      });

      // Save the param before navigating to the dashboard
      // this.router.navigate(['dashboard'])
      if(!this.paramsValue.org){
        this.router.navigate(['profile']);
      }

    } else  {
      // New sign up
      this.activeRoute.queryParams.subscribe(params => {

        // WC 08202022 - org passed in the query string
        if (params.org) {
            this.localStorageService.setItem('org', params.org);

            //WC 01192025 --- CNCDA will alter the sign up screen to add the high school lists
            if (params.org == "CNCDA") {
              this.orgName = "CNCDA";
              //WC 01192025 ---- CNCDA signup query string params include the groupId
              this.localStorageService.setItem('groupId', params.groupId);
            }
        };
      });
    };
  }
  redirectToLogin(){
    if(this.paramsValue.org && this.paramsValue.groupId){
      this.router.navigate(['login'], {queryParams: {org: this.paramsValue.org, groupId: this.paramsValue.groupId}});
    }else{
      this.router.navigate(['login'])
    }
  }

  changeUserLogin(id) {
    if ($('#' + id).val() != '') {
      $('#' + id).siblings('.input-field').addClass('video_input_focus');
      $('#' + id).addClass('focusIn');
    } else {
      $('#' + id).siblings('.input-field').removeClass('video_input_focus');
    }

    if (id == 'user_cpassword') {
      if (this.signupObject.password !== this.signupObject.cPassword) {
        // Set an error on the confirm password field if passwords don't match
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }
    }

    //WC 02062025 --- To check if they are personalizedFlow or not
    
    if (id == 'user_email') {

      //console.log("changeUserLogin - this.signupObject.email = " + this.signupObject.email);

      let payload: any = {
        email: this.signupObject.email.split('@')[1],
        org:  this.orgName
      }

      this.urlService.getPersonalizedFlow(payload).subscribe(
        (res) => {
          // let emails = res.data[0]?.emails?.length ? res.data[0]?.emails : [];

          // if (emails.includes(this.signupObject.email.split('@')[1])) {
          if (res.data.length > 0) { //if it's not an empty array then it's a personalized flow
            this.highSchoolList = res.data[0].highSchoolList
            this.isPersonalize = true;
            this.localStorageService.setItem('isPersonalize', "true");
            this.localStorageService.setItem('personalizedGroupId', res.data[0]?.groupId);
                                        
            this.groupId = res.data[0]?.groupId;

            this.orgName = res.data[0]?.org;
            this.localStorageService.setItem('org', this.orgName);
            //console.log("this.orgName = ", this.orgName);
            
          }
      })
    }
  }
  
  onSubmit() {
    this.signupObject.spinner = true;

    //WC 01192025 --- Persist High School
    // console.log("onSubmit - this.orgName = ", this.orgName);
    // console.log("onSubmit - this.isPersonalize = ", this.isPersonalize);
    // console.log("personalize and CNCDA = ", this.isPersonalize && this.orgName == "CNCDA");

    if (this.orgName == 'CNCDA') {
      this.localStorageService.setItem("CNCDA_HighSchool", this.signupObject.highSchool);
    };

    if (this.signupObject.password !== this.signupObject.cPassword) {
      this.passwordMismatch = true;
      this.signupObject.spinner = false;
      return;
    }

    this.passwordMismatch = false;


    const getFinalObj = () => {
      // Community User
      if (this.localStorageService.getItem('redirectURL') != null && this.localStorageService.getItem('redirectURL').indexOf('/community/') != -1) {
        return {
          campaignId : this.localStorageService.getItem('campaignId'),
          role: 'user',
          firstname: this.signupObject.firstname,
          lastname: this.signupObject.lastname,
          username: this.signupObject.username,
          email: this.signupObject.email,
          password: this.signupObject.password,
          isCommunity: true
        }
      } else {
        return {
          campaignId : this.localStorageService.getItem('campaignId'),
          role: 'user',
          firstname: this.signupObject.firstname,
          lastname: this.signupObject.lastname,
          username: this.signupObject.username,
          email: this.signupObject.email,
          password: this.signupObject.password,
          isCommunity: false,
          org: (this.isPersonalize && this.orgName == "CNCDA") ? this.orgName + " - " + this.signupObject.highSchool : this.orgName
        }
      }
    };

    let finalObj:any = getFinalObj();

    if (this.isPersonalize) {
      finalObj.isPersonalize = this.isPersonalize;
    }
    
    if (this.localStorageService.getItem('anonymousToken')) {
      this.urlService.registerAnonymousUser(finalObj).subscribe(
        success => {
          this.handleLogin(true);
        },
        error => {
          if (error.status === 400){
            this.toastr.error(error?.error?.error);
          }
          if(error){
            this.signupObject.spinner = false;
          }

        });
    } else {
      this.urlService.signup(finalObj).subscribe(
        success => {

          this.handleLogin(false);

        },
        error => {
          this.toastr.error(error?.error?.error);
          // this.
          this.signupObject.spinner = false;
        });
    }
  }

  handleLogin_ORIGINAL(isSetGroupId:boolean) {
    let payload: any = {
      email: this.signupObject.email.split('@')[1],
    }

    if (this.orgName.includes('CNCDA')) {
      payload.org = 'cncda';
    }

    this.urlService.getPersonalizedFlow(payload).subscribe(
      (res) => {
        let emails = res.data[0]?.emails?.length ? res.data[0]?.emails : [];

        if (emails.includes(this.signupObject.email.split('@')[1])) {
          const loginPayload = {
            email: this.signupObject.email,
            password: this.signupObject.password,
            isPersonalize: true
          };

          this.urlService.login(loginPayload).subscribe(
            (loginRes) => {
              this.localStorageService.setItem('isPersonalize', "true");
              this.localStorageService.setItem('token', loginRes.data.token);
              this.localStorageService.setItem('user', loginRes.data.id);
              this.localStorageService.removeItem('anonymousToken');
              this.signupObject.spinner = false;

              const groupId = res.data[0]?.groupId;

              //Add the personalized flow user as a member to the group                            
              const newMember = {
                groupId: groupId,
                members:  [{"accessType":"MEMBER","memberId":this.localStorageService.getItem('user')}]
              };

              this.urlService.joinGroup(newMember).subscribe(res => {
                this.router.navigate(['/my-group/' + groupId]);
              });
              
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          this.localStorageService.removeItem('anonymousToken');
          if (isSetGroupId) {
            this.localStorageService.setItem('groupId', this.paramsValue.groupId);
          }
          this.router.navigate(['/otp']);
          this.localStorageService.setItem('email', this.signupObject.email);
          this.signupObject.spinner = false;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  handleLogin(isSetGroupId:boolean) {

    //WC isPersonalize is checked when the user enters their email addr
    if (this.isPersonalize) {
      const loginPayload = {
        email: this.signupObject.email,
        password: this.signupObject.password,
        isPersonalize: true
      };

      this.urlService.login(loginPayload).subscribe(
        (loginRes) => {
          this.dashboardService.onGetUserData();
          this.localStorageService.setItem('isPersonalize', "true");
          this.localStorageService.setItem('token', loginRes.data.token);
          this.localStorageService.setItem('user', loginRes.data.id);
          this.localStorageService.removeItem('anonymousToken');
          this.signupObject.spinner = false;

          //Add the personalized flow user as a member to the group                            
          const newMember = {
            groupId: this.groupId,
            members:  [{"accessType":"MEMBER","memberId":this.localStorageService.getItem('user')}]
          };

          this.urlService.joinGroup(newMember).subscribe(res => {
            this.router.navigate(['/my-group/' + this.groupId]);
          });
          
        },
        (error) => {
          console.log(error);
        }
      );
    } else {
      this.localStorageService.removeItem('anonymousToken');
      if (isSetGroupId) {
        this.localStorageService.setItem('groupId', this.paramsValue.groupId);
      }
      this.router.navigate(['/otp']);
      this.localStorageService.setItem('email', this.signupObject.email);
      this.signupObject.spinner = false;
    }
  };

  togglePasswordTextType() {
    this.fieldPassTextType = !this.fieldPassTextType;
  }

  toggleCPasswordTextType() {
    this.fieldCPassTextType = !this.fieldCPassTextType;
  }
}
