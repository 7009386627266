import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {EventType, MessageType} from '../createInteractiveVideo.interface';
import {takeUntil} from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';
import {CrudService} from '../../../services/crud.service';
import {createVideoEvent, createVideoMessage, createVideoStep} from '../createInteractiveVideo.data';
import {ToastrService} from 'ngx-toastr';
import {CreateVideoComponent} from '../../../layout/modals/create-video/create-video.component';
import {MatDialog} from '@angular/material/dialog';
import {UtilityService} from '../../../services/utility.service';
import {Router} from '@angular/router';
import {DashboardService} from '../../../services/dashboard.service';
import {LocalStorageService} from '../../../services/localStorage.service';

declare var $;

@Component({
    selector: 'app-create-video-chat',
    templateUrl: './create-video-chat.component.html',
    styleUrls: ['./create-video-chat.component.css']
})
export class CreateVideoChatComponent implements OnInit, OnDestroy {
    active = false;
    @Input() upEvents: Observable<EventType>;
    @Output() event = new EventEmitter<EventType>();
    msgValue = '00:00:00';
    isMessageLoad: boolean;
    messages: MessageType[] = [];
    currentMessageStep = 0;
    msgStep = createVideoStep;
    msgMap = createVideoMessage;
    msgEvent = createVideoEvent;
    isSubmitPathWaysForm = false;
    currentSelectedNode: any;
    createInteractiveVideoObj: any = {
        currentObj: '',
        choices: 1,
        currentChoice: 0
    };
    userData: any;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private videoCount = {
        video: 0,
        ai: 0,
        url: 0,
        total: 0
    };
    chatWindowTitle: string = 'Create Video';
    isAIContentCreator: boolean = false;
    previousAIResponse: string = '';
    previousAITopic: string = '';
    spinner: boolean = false;
    nestedTreeArray = [];

    constructor(
        private router: Router,
        private crudService: CrudService,
        private toastr: ToastrService,
        private dialog: MatDialog,
        private utility: UtilityService,
        private dashboardService: DashboardService,
        private localStorageService: LocalStorageService
    ) {
        this.setChatTitle();
    }

    ngOnInit(): void {
        this.getUploadedVideoCount();
        this.getUserInfo();
        this.upEvents.pipe(takeUntil(this._unsubscribeAll)).subscribe((e) => {
            //console.log('child', e)
            if (e.type === this.msgEvent.init) {
                this.active = true;
                this.clear();
                this.getBotAnswer('');
            } else if (this.active) {
                if (e.type === this.msgEvent.addFirstNode) {
                    if (e.data && this.currentMessageStep === this.msgStep.firstNode) {
                        // this.createInteractiveVideoObj.currentObj = e.data;
                        this.setCurrentVideo(e.data);
                        this.getBotAnswer(e.data.name, 'image', e.data?.poster);
                    } else {
                        this.getBotAnswer('');
                    }
                } else if (e.type === this.msgEvent.addChildNode) {
                    if (e.data) {
                        if (this.createInteractiveVideoObj.currentChoice >= this.createInteractiveVideoObj.choices) {
                            if (this.currentMessageStep === this.msgStep.choicesSelected) {
                                this.currentMessageStep = this.msgStep.choicesSelectedCompleted;
                            } else {
                                this.currentMessageStep = this.msgStep.moreChoicesSelectedCompleted;
                            }
                        }
                        this.getBotAnswer(e.data.name, 'image', e.data?.poster);
                    }
                } else if (e.type === this.msgEvent.editFirstNodeName) {
                    this.setCurrentVideo(e.data);
                    // this.createInteractiveVideoObj.currentObj = e.data;
                    // this.currentMessageStep = 4;
                    // this.getBotAnswer('');
                } else if (e.type === this.msgEvent.nodeSelected) {
                    if (this.currentMessageStep === this.msgStep.addMoreChoices
                        || this.currentMessageStep === this.msgStep.moreNodeSelected) {
                        this.currentMessageStep = this.msgStep.moreNodeSelected;
                        this.currentSelectedNode = e.data;
                        this.currentSelectedNode.timeValue
                            = this.utility.convertSecondsToHHMMSSFormat(this.currentSelectedNode.time || 0);
                        this.getBotAnswer(e.data.name, 'image', e.data?.poster);
                    }
                    if (this.currentMessageStep === this.msgStep.uploadStoryBoardVideo) {
                        this.currentMessageStep = this.msgStep.seletedVideoForNode;
                        this.currentSelectedNode = e.data;
                        this.getBotAnswer(e.data.name);
                    }
                } else if (e.type === this.msgEvent.close) {
                    this.currentMessageStep = this.msgStep.choicesTimeAdded;
                    this.getBotAnswer(e?.data?.name, 'image', e?.data?.poster);
                } else if (e.type === this.msgEvent.saveStoryBoardName) {
                    this.currentMessageStep = this.msgStep.choicesTimeAdded;
                    this.getBotAnswer(e?.data);
                } else {
                    this.currentMessageStep = this.msgStep.choicesTimeAdded;
                }
            }
        });
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    getUserInfo() {

        this.dashboardService.getUserData.subscribe((res) => {
            if (res) {
              this.userData = res;
            }
          });
    }

    setChatTitle() {
        const titleName = this.localStorageService.getItem('interactive');
        if (titleName === 'storyBoard') {
            this.chatWindowTitle = 'Story Board';
        } else if (titleName === 'isInteractive') {
            this.chatWindowTitle = 'Free Board';
        } else if (titleName === 'isEditor') {
            this.chatWindowTitle = 'AI-Editor';
        } else if (titleName === 'aiContentCreator') {
            this.isAIContentCreator = true;
            this.chatWindowTitle = 'AI Content Creator';
        } else {
            this.chatWindowTitle = 'Template';
        }
    }

    setCurrentVideo(e) {
        this.createInteractiveVideoObj.currentObj = e;
        if (this.createInteractiveVideoObj.currentObj) {
            this.createInteractiveVideoObj.currentObj.timeValue
                = this.utility.convertSecondsToHHMMSSFormat(this.createInteractiveVideoObj.currentObj.time || 0);
        }
    }

    sendMessage(msg?: string) {
        if (msg) {
            this.msgValue = msg;
        }
        this.msgValue = this.msgValue.trim();
        /*input validation*/
        if (this.currentMessageStep === this.msgStep.noVideo) {
            if (this.msgValue === this.msgMap.yes) {
                this.isMessageLoad = true;
                this.addMessage('user', this.msgValue)
                this.openVideoCreateModel('');
                return;
            } else if (this.msgValue === this.msgMap.exit) {
                setTimeout(() => {
                    // this.cancel();
                    this.router.navigate(['/upload-videos']);
                }, 1000);
            }
        } if (this.currentMessageStep === this.msgStep.noVideoNo) {
            if (this.msgValue === this.msgMap.uploadVideo) {
                this.isMessageLoad = true;
                this.addMessage('user', this.msgValue)
                this.openVideoCreateModel('');
                return;
            } else if (this.msgValue === this.msgMap.exit) {
                setTimeout(() => {
                    // this.cancel();
                    this.router.navigate(['/upload-videos']);
                }, 1000);
            } else if (this.msgValue === this.msgMap.tutorial) {
                setTimeout(() => {
                    // this.cancel();
                    this.router.navigate(['/upload-videos'], {queryParams: {tutorial: 1}});
                }, 1000);
            }
        } else if (this.msgValue === this.msgMap.addFirstNode) {
            this.addMessage('user', this.msgValue)
            this.triggerEvent(this.msgEvent.addFirstNode, {name: this.chatWindowTitle});
            return;
        } else if (this.currentMessageStep === this.msgStep.firstNodeComplete) {
            if (this.msgValue === this.msgMap.yes) {
                this.currentMessageStep = this.msgStep.firstNodeRename;
            } else {
                this.currentMessageStep = this.msgStep.firstNodeRenameComplete;
            }
        } else if (this.currentMessageStep === this.msgStep.firstNodeRename) {
            this.currentMessageStep = this.msgStep.firstNodeRenameComplete;
            this.createInteractiveVideoObj.currentObj.name = this.msgValue;
            this.triggerEvent(this.msgEvent.editFirstNodeName, this.createInteractiveVideoObj.currentObj);
            // return;
        } else if (this.currentMessageStep === this.msgStep.firstNodeRenameComplete) {
            this.currentMessageStep = this.msgStep.choicesSelected;
            this.createInteractiveVideoObj.choices = this.msgValue;
            this.createInteractiveVideoObj.currentChoice = 0;
            this.triggerEvent(this.msgEvent.addMoreChoices, {name: this.chatWindowTitle, choices: this.createInteractiveVideoObj.choices});
            // for (let i = 0; i < this.createInteractiveVideoObj.choices; i++) {
            //     this.triggerEvent(this.msgEvent.addMoreChoices);
            // }
        } else if (this.currentMessageStep === this.msgStep.choicesSelected) {
            ++this.createInteractiveVideoObj.currentChoice;
            this.triggerEvent(this.msgEvent.addChildNode, {
                currentObj: this.createInteractiveVideoObj.currentObj,
                index: this.createInteractiveVideoObj.currentChoice
            });
            this.msgValue = '';
        } else if (this.currentMessageStep === this.msgStep.choicesSelectedCompleted) {
            if (!this.msgValue) {
                this.msgValue = '00:00:00';
            }
            const time = this.msgValue.split(':');
            const currentTime = Number(time[2]) + Number(time[1]) * 60 + Number(time[0]);
            if (currentTime > Number(this.createInteractiveVideoObj.currentObj.duration)) {
                this.toastr.error('Time should be less then duration!');
                return;
            }
            this.triggerEvent(this.msgEvent.addTime, {
                duration: currentTime,
                currentObj: this.createInteractiveVideoObj.currentObj
            });
            this.currentMessageStep = this.msgStep.choicesTimeAdded;
        } else if (this.currentMessageStep === this.msgStep.choicesTimeAdded) {
            if (this.msgValue === this.msgMap.createPathways) {
                setTimeout(() => {
                    this.triggerEvent(this.msgEvent.createPathways);
                    this.cancel();
                }, 1000);
            } else if (this.msgValue === this.msgMap.publish) {
                setTimeout(() => {
                    this.triggerEvent(this.msgEvent.publish);
                    this.cancel();
                }, 1000);
            } else if (this.msgValue === this.msgMap.addMoreChoices) {
                this.currentMessageStep = this.msgStep.addMoreChoices;
            } else if (this.msgValue === this.msgMap.uploadVideosForStoryBoard) {
                this.currentMessageStep = this.msgStep.uploadStoryBoardVideo;
            } else if (this.msgValue === this.msgMap.saveTheStoryBoard) {
                this.currentMessageStep = this.msgStep.saveTheStoryBoard;
            } else {
                this.currentMessageStep = this.msgStep.complete;
            }
        } else if (this.currentMessageStep === this.msgStep.moreNodeSelected) {
            this.currentMessageStep = this.msgStep.choicesTimeAdded;
        } else if (this.currentMessageStep === this.msgStep.moreAddChoices) {
            this.createInteractiveVideoObj.currentObj = this.currentSelectedNode;
            this.currentMessageStep = this.msgStep.moreChoicesSelected;
            this.createInteractiveVideoObj.choices = this.msgValue;
            this.createInteractiveVideoObj.currentChoice = 0;
            if (this.chatWindowTitle === 'Story Board') {
                this.triggerEvent(this.msgEvent.addMoreChoices, {name: this.chatWindowTitle, choices: this.createInteractiveVideoObj.choices, currentObj: this.createInteractiveVideoObj.currentObj});
            }
            // for (let i = 0; i < this.createInteractiveVideoObj.choices; i++) {
            //     this.triggerEvent(this.msgEvent.addMoreChoices);
            // }
        } else if (this.currentMessageStep === this.msgStep.moreChoicesSelected) {
            ++this.createInteractiveVideoObj.currentChoice;
            this.triggerEvent(this.msgEvent.addChildNode, {
                currentObj: this.currentSelectedNode,
                index: this.createInteractiveVideoObj.currentChoice
            });
            this.msgValue = '';
        } else if (this.currentMessageStep === this.msgStep.moreChoicesSelectedCompleted) {
            if (!this.msgValue) {
                this.msgValue = '00:00:00';
            }
            const time = this.msgValue.split(':');
            const currentTime = Number(time[2]) + Number(time[1]) * 60 + Number(time[0]);
            if (currentTime > Number(this.currentSelectedNode.duration)) {
                this.toastr.error('Time should be less then duration!');
                return;
            }
            this.triggerEvent(this.msgEvent.addTime, {duration: currentTime, currentObj: this.currentSelectedNode});
            this.currentMessageStep = this.msgStep.choicesTimeAdded;
        } else if (this.currentMessageStep === this.msgStep.complete) {
            setTimeout(() => {
                this.cancel();
            }, 1000);
        } else if (this.currentMessageStep === this.msgStep.seletedVideoForNode) {
            this.triggerEvent(this.msgEvent.addVideoForEmptyNode, {
                currentObj: this.currentSelectedNode,
                isAIContentTree: this.chatWindowTitle === 'AI Content Creator' ? true : false
            });
            this.msgValue = '';
        } else if (this.currentMessageStep === this.msgStep.saveStoryBoardName) {
            this.triggerEvent(this.msgEvent.saveStoryBoardName, {title: this.msgValue})
            return;
        } else if (this.currentMessageStep === this.msgStep.setAITopic) {
            this.previousAITopic = this.msgValue;
            this.addMessage('user', this.msgValue);
            this.callChatGPTAPI(this.msgValue);
            return;
        } else if (this.msgValue === this.msgMap.createAnotherScenarioWithTheSameTopic) {
            this.currentMessageStep = null;
            let userPrompt = `${this.previousAIResponse}\n\n. Write a script in the same format for ${this.previousAITopic} that’s different than previous one.`;
            this.addMessage('user', this.msgValue);
            this.callChatGPTAPI(userPrompt);
            return;
        }

        if (this.msgValue) {
            this.getBotAnswer(this.msgValue);
        }
        this.msgValue = '';
    }

    buildHierarchy(levels) {
        const hierarchy = [];
        const map = {};
        
        levels.forEach(({ level }) => {
            const parts = level.split('.');
            const currentLevel = {
                level: level,
                child: []
            };
            
            if (parts.length === 1) {
                // Root level
                hierarchy.push(currentLevel);
            } else {
                const parentLevel = parts.slice(0, -1).join('.');
                if (!map[parentLevel]) {
                    // Create parent level if not exist
                    map[parentLevel] = { child: [] };
                }
                map[parentLevel].child.push(currentLevel);
            }
            
            // Store current level in map
            map[level] = currentLevel;
        });
        
        return hierarchy;
    }

    addMessage = (author, content, type = 'text', url?) => {
        this.messages.push({author, content, type, url});
        $('#messagesCard').animate({scrollTop: $('#messagesCard .messages').height()}, 500);
    }

    getBotAnswer(userMessage: string, type = 'text', url = '') {

        if (userMessage) {
            this.addMessage('user', userMessage, type, url)
        }
        this.isMessageLoad = true;
        setTimeout(() => {
            /*output validation*/
            let botMessage = '';
            if (!this.messages.length) {
                botMessage = `Hi ${this.localStorageService.getItem('userName')}, I'm Lexie. Happy to assist you!`;
                if(this.isAIContentCreator) {
                    this.currentMessageStep = this.msgStep.setAITopic;
                    botMessage = `Hi ${this.localStorageService.getItem('userName')}, I'm Lexie. Happy to assist you in creating a content tree! What’s the topic you’d like to create the content tree for?`;
                }
                // this.messages.push({author: 'bot', content: botMessage, type: 'text'});
                this.addMessage('bot', botMessage)
                this.getBotAnswer('');
                return;
            }
            if (this.currentMessageStep === this.msgStep.welcome) {
                if (this.videoCount.total === 0) {
                    this.currentMessageStep = this.msgStep.noVideo;
                    botMessage = 'So I noticed you don’t have any videos uploaded to get started. Would you like to upload videos?';
                } else {
                    this.currentMessageStep = this.msgStep.firstNode;
                    if (this.chatWindowTitle === 'Free Board') {
                        botMessage = 'Choose a video from your library to start building your content';
                    }
                    if (this.chatWindowTitle === 'Template') {
                        botMessage = 'For creating a video from a template, we have a several pre-built templates that you can choose from. Simply click on the “Marketing & PLG Templates” or “Training Templates”.';
                        this.getBotAnswer('');
                    }
                    if (this.chatWindowTitle === 'AI-Editor') {
                        botMessage = 'If you have a long video, our AI Editor will be to break up the videos into a content tree automatically. All you have to do is to upload the video.';
                        this.getBotAnswer('');
                    }
                }
            } else if (this.currentMessageStep === this.msgStep.noVideo) {
                this.currentMessageStep = this.msgStep.noVideoNo;
                botMessage = 'We need a video to create a video tree. What would you like to do?';
            } else if (this.currentMessageStep === this.msgStep.firstNode) {
                if (this.createInteractiveVideoObj.currentObj) {
                    this.currentMessageStep = this.msgStep.firstNodeComplete;
                    botMessage = `Would you like to change the title of the node?` +
                        ` This will affect the name shown in the Content Tree and the choice shown to the viewer, the current name is ${this.createInteractiveVideoObj.currentObj.name}`;
                } else {
                    if (this.chatWindowTitle === 'Template') {
                        this.currentMessageStep = this.msgStep.complete;
                        botMessage = 'You can then click on the available templates to see the pre-built storyboard. If you select the template, it will be created for you and you can add the videos or continue building the tree.';
                    } else if (this.chatWindowTitle === 'AI-Editor') {
                        this.currentMessageStep = this.msgStep.complete;
                        botMessage = 'For best result, please upload an MP4 video that’s longer than 5 minutes. Once the AI Editor is done and the content tree is ready for your review, you’ll receive an email notification with the URL.';
                    } else {
                        botMessage = 'Oops! Choose a video from your library to start building your content';
                    }
                }
            } else if (this.currentMessageStep === this.msgStep.firstNodeRename) {
                botMessage = 'What do you want the name to be?';
            } else if (this.currentMessageStep === this.msgStep.firstNodeRenameComplete) {
                botMessage = 'Congrats, you just created the 1st node in the video tree! Now, how many choices would you like to add?'
            } else if (this.currentMessageStep === this.msgStep.choicesSelected) {
                if (this.createInteractiveVideoObj.currentChoice === 0) {
                    botMessage = 'Which video would you like to add for the 1st choice?'
                } else if (this.createInteractiveVideoObj.currentChoice === 1) {
                    botMessage = 'Which video would you like to add for the 2nd choice?'
                } else if (this.createInteractiveVideoObj.currentChoice === 2) {
                    botMessage = 'Which video would you like to add for the 3rd choice?'
                } else {
                    botMessage = 'Which video would you like to add for the 4th choice?'
                }
            } else if (this.currentMessageStep === this.msgStep.choicesSelectedCompleted) {
                botMessage = `When would you like the choices to come up? We suggest that the choices to show a few seconds before the video ends. Currently the video is ${this.utility.secondsToDhms(this.createInteractiveVideoObj.currentObj.duration)} long`
            } else if (this.currentMessageStep === this.msgStep.choicesTimeAdded) {
                botMessage = 'Looking good! I hope this helped you build your content easier. Is there anything else I can help you with?'
            } else if (this.currentMessageStep === this.msgStep.complete) {
                botMessage = 'Alright! It\'s been a pleasure assisting you. You can publish your video whenever you\'re ready. Bye Now'
            } else if (this.currentMessageStep === this.msgStep.addMoreChoices) {
                botMessage = 'Select the node from the video tree where you\'d like to add more choices'
            } else if (this.currentMessageStep === this.msgStep.moreNodeSelected) {
                if (this.currentSelectedNode?.children
                    && this.currentSelectedNode?.children?.length
                    && this.currentSelectedNode?.children?.length === 4) {
                    botMessage = 'Sorry, maximum choices allowed are 4. Select another node'
                } else {
                    this.currentMessageStep = this.msgStep.moreAddChoices;
                    botMessage = 'Now, how many choices would you like to add?'
                }
            } else if (this.currentMessageStep === this.msgStep.moreChoicesSelected) {
                if (this.createInteractiveVideoObj.currentChoice === 0) {
                    botMessage = 'Which video would you like to add for the 1st choice?'
                } else if (this.createInteractiveVideoObj.currentChoice === 1) {
                    botMessage = 'Which video would you like to add for the 2nd choice?'
                } else if (this.createInteractiveVideoObj.currentChoice === 2) {
                    botMessage = 'Which video would you like to add for the 3rd choice?'
                } else {
                    botMessage = 'Which video would you like to add for the 4th choice?'
                }
            } else if (this.currentMessageStep === this.msgStep.moreChoicesSelectedCompleted) {
                botMessage = `When would you like the choices to come up? We suggest that the choices to show a few seconds before the video ends. Currently the video is ${this.utility.secondsToDhms(this.currentSelectedNode.duration)} long`
            } else if (this.currentMessageStep === this.msgStep.uploadStoryBoardVideo) {
                botMessage = 'Select the node that you’d like the video to be uploaded for'
            } else if (this.currentMessageStep === this.msgStep.seletedVideoForNode) {
                botMessage = 'Choose a video from your library to be uploaded to the selected node'
            } else if (this.currentMessageStep === this.msgStep.saveTheStoryBoard) {
                this.currentMessageStep = this.msgStep.saveStoryBoardName;
                const name = this.chatWindowTitle === 'AI Content Creator' ? 'Content Tree' : 'StoryBoard';
                botMessage = `OK! You’d like to save the ${name}. What would the name be?`;
            }

            this.isMessageLoad = false;
            if (botMessage) {
                this.addMessage('bot', botMessage)
            }
        }, 1000);
    }

    cancel() {
        this.clear();
        this.active = false;
        this.triggerEvent(this.msgEvent.close);
    }

    clear() {
        this.msgValue = '';
        this.messages = [];
        this.currentMessageStep = this.msgStep.welcome;
        this.createInteractiveVideoObj.currentObj = '';
        this.createInteractiveVideoObj.currentChoice = 0;
        this.createInteractiveVideoObj.choices = 1;
        // this.editWatchPath = null;
        // this.startWatchNode = null;
        // this.endWatchNode = null;
        // this.isAddWatchPath = false;
        this.isSubmitPathWaysForm = false;
        // this.submitPathWaysFormError = '';
        // this.watchPathName = '';
        // this.handleActiveWatchPath('');
        $('.video-component').removeClass('nodeSelected');
    }

    durationChange(duration?: string) {
        this.msgValue = duration;
    }

    private getUploadedVideoCount(bot = false) {
        this.videoCount = {
            video: 0,
            ai: 0,
            url: 0,
            total: 0
        };
        this.crudService.getUploadedVideoCount('', 'no')
            .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
            success.data.forEach((e, i) => {
                const isVideo = Object.values(e).includes('application/octet-stream');
                const isUrl = Object.values(e).includes('url');
                if (isVideo) {
                    this.videoCount.video = e.count;
                }
                if (isUrl) {
                    this.videoCount.url = e.count;
                }
            });
            this.videoCount.total = this.videoCount.video + this.videoCount.ai + this.videoCount.url;
            if (bot) {
                if (this.videoCount.total) {
                    this.currentMessageStep = this.msgStep.welcome;
                }
                this.getBotAnswer('');
            }
        });
        // this.crudService.getUploadedVideoCount('', 'yes')
        //     .pipe(takeUntil(this._unsubscribeAll)).subscribe(success => {
        //     success.data.forEach((e, i) => {
        //         const isVideo = Object.values(e).includes('application/octet-stream');
        //         if (isVideo) {
        //             this.videoCount.ai = e.count;
        //         }
        //     });
        //     this.videoCount.total = this.videoCount.video + this.videoCount.ai + this.videoCount.url;
        // });
    }

    triggerEvent(type, data?) {
        this.event.emit({type, data});
    }

    openVideoCreateModel(type: string) {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            width: '95%',
            maxWidth: '1300px',
            panelClass: 'my-dialog',
            height: 'auto',
            minHeight: '500px',
            data: {
                allVideos: null,
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            this.getUploadedVideoCount(true);
        });
    }

    callChatGPTAPI(msgValue: string) {
        this.spinner = true;

        let payload;
        payload = {
            "assistantType" : "ScenarioWriter",
            "userPrompt" : msgValue
        };

        if (this.currentSelectedNode) {
            payload['videoId'] = this.createInteractiveVideoObj?.currentObj ? this.createInteractiveVideoObj.currentObj._id : '';
        }

        this.crudService.createLexieChat(payload).subscribe((res: any) => {
            if(res) {
                const responseMessage = res.data.choices[0].message.content;
                this.previousAIResponse = responseMessage;
                this.addMessage('bot', responseMessage);
                this.spinner = false;
                this.currentMessageStep = this.msgStep.aiTopicCreated;
            }
        }, (err) => {
            this.toastr.error('Error!');
            this.spinner = false;
        })
    }

}
