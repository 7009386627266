import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CrudService } from "src/app/services/crud.service";
import { LocalStorageService } from "src/app/services/localStorage.service";
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import * as html2pdf from "html2pdf.js";
import { HttpClient } from "@angular/common/http";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { ToastrService } from "ngx-toastr";
import { jsPDF } from "jspdf";

@Component({
  selector: "app-main-certificates",
  templateUrl: "./main-certificates.component.html",
  styleUrls: ["./main-certificates.component.css"],
})
export class MainCertificatesComponent implements OnInit {
  badgeData: any;
  currentUserId: any;
  userInfo: any;
  imageDataUrl: string; // To store the data URL of the image
  safeImageUrl: SafeUrl;
  spinner: boolean = false;
  isBase64Image: boolean = false;
  isBase64SignatureImage: boolean = false;
  base64ImageData: string;
  isDisplayDownLoad:boolean = false;
  base64SignatureImageData: string;
  completedItem: string; //Video title or pathway name
  completedDate: any;

  constructor(
    public dialogRef: MatDialogRef<MainCertificatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private urlService: CrudService,
    private localStorageService: LocalStorageService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService,
  ) {
    this.currentUserId = this.localStorageService.getItem("user");
    pdfMake.vfs = pdfFonts.pdfMake.vfs; // Load virtual file system fonts
  }

  ngOnInit(): void {
    this.spinner = true;
    this.badgeData = this.data.badge;
    // console.log("this.badgeData ===> ", this.badgeData)

    if(typeof this.badgeData.pic == 'object'){
      const file = this.badgeData.pic;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.isBase64Image = true;
        this.base64ImageData = e.target.result;        
      };
      reader.readAsDataURL(file);
    }

    if(typeof this.badgeData.signatureImage == 'object'){
      const file = this.badgeData.signatureImage;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.isBase64SignatureImage = true;
        this.base64SignatureImageData = e.target.result;        
      };
      reader.readAsDataURL(file);
    }
    
    
    
    // this.completedItem = this.data.badge?.videoInfo ? this.data.badge?.videoInfo?.Title : this.data.badge?.pathwayInfo?.pathName;

    if(this.data.badge?.videoInfo){
      this.completedItem = this.data.badge?.videoInfo ? this.data.badge?.videoInfo?.Title : this.data.badge?.pathwayInfo?.pathName;
    }
    if(this.data.badge?.groupId){
      this.completedItem = `${this.data.badge?.tabName} Tab`
    }

    this.completedDate = this.data.badge?.createdAt;

    //console.log(this.badgeData.pic);
    this.userInformation();
  }

  userInformation() {
    this.urlService.userDetailsV2().subscribe((res) => {
      this.userInfo = res.data?.details;
      if (this.userInfo) {
        this.spinner = false;
      }
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  async generatePdf() {
    this.isDisplayDownLoad = true;
    let payload:any = {
      badgeImage: this.badgeData.pic,
    }

    if(this.badgeData?.signatureImage && typeof this.badgeData.signatureImage !== 'object'){
      payload.signatureImage = this.badgeData.signatureImage
    }


    if(typeof this.badgeData.pic == 'object'){
      const options = {
        filename: "Certificate.pdf",
        image: { type: "png", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
      };

      const element = document.getElementById("main-container").innerHTML;

      const elementWithCorrectedUrl = element.replace(/&amp;/g, "&");

      let obj = {
        options,
        elementWithCorrectedUrl
      }
      this.isDisplayDownLoad = false
      this.dialogRef.close(obj);
      return
    }

    this.urlService
      .convertImageToBase64(payload)
      .subscribe((res) => {
        // console.log("res", res);
        
        this.isBase64Image = true;
        this.base64ImageData = "data:image/png;base64," + res.data?.badgeBase64;

        if (this.badgeData?.signatureImage && typeof this.badgeData.signatureImage !== 'object'){
          this.isBase64SignatureImage = true;
          this.base64SignatureImageData = "data:image/png;base64," + res.data?.signatureBase64;
        }

        setTimeout(() => {
          const options:any = {
            filename: "Certificate.pdf",
            image: { type: "png", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "in", format: "letter", orientation: "landscape" },
          };

          // const doc = new jsPDF(options.jsPDF);
          // Draw a rectangle with the background color
          // doc.setFillColor(this.badgeData?.certificateBackground || "#ffffff"); // Use certificateBackground or default to white
          // doc.rect(0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height, 'F');

          // console.log("doc ===> ", doc)
          const element = document.getElementById("main-container").innerHTML;

          const elementWithCorrectedUrl = element.replace(/&amp;/g, "&");
          // const imgData = 'data:image/jpeg;base64,' + btoa(elementWithCorrectedUrl);

          // Add image to the PDF
          // doc.addImage(imgData, 'JPEG', 0, 0, doc.internal.pageSize.width, doc.internal.pageSize.height);
      
          // Save the PDF
          // doc.save(options.filename);
          
          // console.log("elementWithCorrectedUrl", elementWithCorrectedUrl)
          let obj = {
            options,
            elementWithCorrectedUrl
          }
          // html2pdf()
          //   .from(elementWithCorrectedUrl)
          //   .set(options)
          //   .save();
          this.isDisplayDownLoad = false
          this.dialogRef.close(obj);
        }, 100);
      }, (error) => {
        
        this.dialogRef.close();
        this.toastr.error("Something went wrong, Please try again!")
        // console.log("error", error);
      });
  }
}
