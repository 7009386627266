import {Component, OnInit, ViewChild} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {MatDialog} from '@angular/material/dialog';
import {DeleteConfirmationComponent} from '../../layout/modals/delete-confirmation/delete-confirmation.component';
import {ShareVideoDialogComponent} from '../../layout/modals/share-video-dialog/share-video-dialog.component';
import {environment} from '../../../environments/environment';
import {LocalStorageService} from '../../services/localStorage.service';
import {DashboardService} from '../../services/dashboard.service';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';

declare var $;

@Component({
    selector: 'app-new-search-results',
    templateUrl: './new-search-results.component.html',
    styleUrls: ['./new-search-results.component.css']
})
export class NewSearchResultsComponent implements OnInit {
    @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;
    @ViewChild('cloneDialog') cloneDialog: any;
    @ViewChild('transferDialog') transferDialog: any;

    public spinner: any = false;
    public results: Array<any> = [];
    public users: Array<any> = [];
    public searchText: any = '';
    public isLoggedIN: any = false
    public loggedINUserId = ''
    public resultType: any = 'video';
    public minDecision: any;
    public queryParams: any = {
        updatedAt: '',
        interactivity: '',
        minDecision: '',
        maxDecision: '',
        minOutcome: '',
        maxOutcome: '',
        feature: ''
    }
    public maxDecision: any;
    public minOutcome: any;
    public maxOutcome: any;
    public categoriesSelected: any = []
    public categories: Array<any> = [];
    public features: Array<string> = [];
    public groups: Array<string> = [];
    public community: Array<string> = [];
    val: any = 'recent';
    tempImg = 'https://www.google.com/imgres?imgurl=https%3A%2F%2Flookaside.fbsbx.com%2Flookaside%2Fcrawler%2Fmedia%2F%3Fmedia_id%3D5171257096225081&imgrefurl=https%3A%2F%2Fwww.facebook.com%2Fimage-not-available-855398481144319%2Fposts%2F%3Fref%3Dpage_internal&tbnid=iTT6lHG6a0ZoXM&vet=12ahUKEwjTvMTVzcvyAhXEeisKHcgiC30QMygOegUIARDWAQ..i&docid=_9rrU1dO_sO8BM&w=638&h=638&q=image%20Not%20available%3B&ved=2ahUKEwjTvMTVzcvyAhXEeisKHcgiC30QMygOegUIARDWAQ';
    copyVideoTitle: string;
    transferToEmailAddr: any;
    deleteWaitingId: any;
    private currentItemback: any;
    private isSecond: boolean;
    topChoices: any[] = [];
    childChoices1: any[];
    childChoices2: any[];
    childChoices3: any[];
    childChoices4: any[];
    childChoices5: any[];
    childChoices6: any[];
    resetIt: boolean;
    currentItem: any = {};
    currentItem1: any = {};
    currentItem2: any = {};
    currentItem3: any = {};
    currentItem4: any = {};
    currentItem5: any = {};
    currentItem6: any = {};
    currentItem7: any = {};

    choice1: any;
    choice2: any;
    choice3: any;
    choice6: any;
    choice4: any;
    choice5: any;
    choice7: any;

    page:number = 1;
    countOfResult:any;
    searchResult: any;
    videoType: any;
    groupType: any;
    userType: any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();


    constructor(
        public dialog: MatDialog,
        public crudService: CrudService,
        public toastr: ToastrService,
        public router: Router,
        public activeRoute: ActivatedRoute,
        public localStorageService: LocalStorageService,
        private dashboardService: DashboardService,
    ) {
        this.activeRoute.queryParams.subscribe(params => {
            if (!this.localStorageService.getItem('token')) {
                if (!this.localStorageService.getItem('anonymousToken')) {
                    this.getAnonymousUser();
                }
            }

            this.searchText = params.search;
             this.dashboardService.getSearchTerm
                .pipe(takeUntil(this._unsubscribeAll), debounceTime(1000))
                .subscribe((res) => {
                    this.searchText = res;
                    if (!res && this.router.url.includes('/search-results')) {
                        if (params.videoType) {
                            this.router.navigate(['search-results'], {queryParams: {search: '', videoType: params.videoType}});
                        }
                        if(params.groupType) {
                            this.router.navigate(['search-results'], {queryParams: {search: '', groupType: params.groupType}});
                        }
                        if(params.userType) {
                            this.router.navigate(['search-results'], {queryParams: {search: '', userType: params.userType}});
                        }
                    }
                });
          
            if (params.search) {
                this.page = 1
            }

            this.videoType = params.videoType;
            this.groupType = params.groupType;
            this.userType = params.userType;

            if (this.searchText && !this.videoType && !this.groupType && !this.userType) {
                this.videoType = 'all'
                this.groupType = 'all'
                this.userType = 'all'
                this.resultType = 'videos'
            }

            this.getSearchResultCount();
            this.isLoggedIN = !(this.localStorageService.getItem('token') == null && this.localStorageService.getItem('token') === undefined);
            this.loggedINUserId = this.localStorageService.getItem('user');
        });
    }


    showCategory(id: any) {

        this.router.navigate(['category'], {queryParams: {category: id}});

    }


    getAnonymousUser() {
        this.crudService.getAnonymousUser().subscribe(
            success => {
                if (this.localStorageService.getItem('anonymousToken')) {
                } else {
                    this.localStorageService.setItem('anonymousToken', success.data.token);
                }
            }
        );
    }

    ngOnInit() {
        // this.getCategories();

    }

    getCategories() {
        this.crudService.fetchCategories().subscribe(
            success => {
                this.categories = success.data.categories;
            });
    }

    getSearchResultCount(){
        this.searchResult = '';
        this.results = [];
        this.users = [];
        this.groups = [];
        this.community = [];
        this.spinner = true
        this.countOfResult = ""
        if (this.videoType) {
            this.resultType = 'video';
        } else if (this.groupType) {
            this.resultType = 'groups'
        } else if (this.userType) {
            this.resultType = 'users';
        }else {
            this.resultType = 'video'
        }
        this.crudService.getSearchResultCount(this.searchText, this.videoType, this.groupType === "ownGroups" ? "ownGroups" : "all").subscribe((success) =>{
            this.countOfResult = success.data;
            this.getSearchResults()
        }, (error) => {
            this.toastr.error('No result Found')
            this.spinner = false;
           })
    }
    getSearchResults(sortValue?:any) {
        
        if(!sortValue){
            this.sort = 'recent'
            this.val = 'recent'
        }else{
            this.sort = sortValue
        }
        this.spinner = true;
        this.crudService.simpleVideoSearch(this.searchText, this.page, this.sort, 10, this.videoType, this.groupType === "ownGroups" ? "ownGroups" : "all").subscribe(
            success => {
                //console.log('search result is', success.data);
                this.searchResult = success.data.result
                if (this.searchResult?.searchdata) {
                        // this.results = this.searchResult?.searchdata;
                        //console.log('search inside result is', this.results);

                        const videos =  this.searchResult?.searchdata.results;
                            for (const item of videos) {
                                this.results.push(item);
                            }
                        // this.results = this.results.reverse();
                }

                if (this.searchResult?.searchbyuser) {
                    let users = this.searchResult?.searchbyuser.results;
                    for (const item of users) {
                        this.users.push(item);
                    }
                }
                if (this.searchResult?.searchbygroup) {
                    let groups = this.searchResult?.searchbygroup.results;
                    for (const item of groups) {
                        this.groups.push(item);
                    }
                }
                if (this.searchResult?.searchbyCommunity) {
                    let community = this.searchResult?.searchbyCommunity.results;
                    for (const item of community) {
                        this.community.push(item);
                    }
                }
                // this.users  = success.data.searchbyuser;
                // this.results = success.data.searchdata;
                // this.results = this.results.reverse();
                this.getCategories();
                this.spinner = false;
            },(error) => {
                this.toastr.error('No result Found')
                this.spinner = false;
            });
    }

    getVideos(){
        ++this.page;
        this.getSearchResults(this.sort);
    }

    viewuser(id: any) {
        this.router.navigate(['public-profile'], {queryParams: {user: id}});
    }

    viewCommunity(communityUrl:any){
        this.router.navigate(['my-community/', communityUrl]);
    }

    applyUploadDateFilter(event: any) {

        this.queryParams.updatedAt = event.target.value;
        this.spinner = true;
        this.filterData();

    }

    viewGroup(group: any) {
        if(group?.isSubscriptionGroup){
            this.router.navigate(['/community/' + group._id])
        }else{
            this.router.navigate(['/my-group/' + group._id])
        }
    }

    applyInteractivityFilter(event: any) {
        this.queryParams.interactivity = event.target.value;
        this.spinner = true;
        this.filterData();

    }

    applyDecisionFilter(event: any) {

        this.queryParams.minDecision = this.minDecision;
        this.queryParams.maxDecision = this.maxDecision;
        this.spinner = true;
        this.filterData();

    }

    applyOutcomeFilter(event: any) {


        this.queryParams.minOutcome = this.minOutcome;
        this.queryParams.maxOutcome = this.maxOutcome;
        this.spinner = true;
        this.filterData();
    }

    applyFeatureFilter(event: any) {

        if (event.target.checked) {
            this.categoriesSelected.push(event.target.value)
        } else {
            let index = 0;
            for (const i of this.categoriesSelected) {
                if (event.target.value == i) {
                    this.categoriesSelected.splice(index, 1)
                    break;
                }
                index++
            }
        }
        // this.categoriesSelected
        // this.queryParams.feature = this.queryParams.feature +','+ event.target.value;
        this.spinner = true;
        this.filterData();

    }

    showFilters() {
        $('.filters-div').toggle();
    }

    viewVides(id: any) {
        this.router.navigate(['videos'], {queryParams: {video: id}});

    }

    filterData() {
        this.results = [];

        const param: any = {
            word: this.searchText,
            filter: true,
            updatedAt: this.queryParams.updatedAt,
            interactivity: this.queryParams.interactivity,
            minDecision: this.queryParams.minDecision,
            maxDecision: this.queryParams.maxDecision,
            minOutcome: this.queryParams.minOutcome,
            maxOutcome: this.queryParams.maxOutcome,
            feature: this.categoriesSelected.join(',')
        };
        this.crudService.searchVideo(param).subscribe(success => {
            // console.log(success.data)
            this.users = success.data.result.searchbyuser;
            this.results = success.data.result.searchdata;
            this.results = this.results.reverse();
            // console.log('result is', this.results);

            this.spinner = false;

        }, error => {
            this.spinner = false;
        });
    }

    sort:any;
    changeFilter(val: any) {
        this.page = 1
        this.searchResult = '';
        this.results = [];
        this.users = [];
        this.groups = [];
        this.community = [];
        this.spinner = true;
        // this.countOfResult= ""
        this.sort = val
        this.getSearchResults(val)

        return
        if (val === 'recent') {
            this.results.sort((a, b) => {
                if (a.publishTime > b.publishTime) {
                    return -1;
                }
                return 1
            })
        } else {
            this.results.sort((a, b) => {
                if (a.publishTime > b.publishTime) {
                    return 1;
                }
                return -1
            })
        }

    }

    changeTab(value:any){
        this.resultType = value;
        this.val = 'recent'
        this.page = 1;
        this.results = [];
        this.users = [];
        this.groups = [];
        this.community = [];
        this.getSearchResults(this.val)
    }


    edit(id: any) {
        // 10192022 - To fix the issue with opening AI Editor Video uploading list if we access AI Editor before editing a video
        this.localStorageService.setItem('interactive', 'isInteractive');

        this.router.navigate(['create-interactive-video'], {
            queryParams: {video: id},
        });
    }

    cloneVideo(item) {
        const obj = {
            videoId: item._id,
            copyVideoTitle: '',
        };
        this.copyVideoTitle = item.Title + '.copy';
        const dialogRef = this.dialog.open(this.cloneDialog, {
            width: '100%',
            maxWidth: '500px',
            panelClass: 'my-dialog',
            height: 'auto',
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                if (!this.copyVideoTitle.trim()) {
                    this.copyVideoTitle = item.Title + '.copy';
                }
                obj.copyVideoTitle = this.copyVideoTitle;
                this.crudService.cloneVideo(obj).subscribe(() => {
                    this.toastr.success('Video copied successfully!');
                });
            }
        });
    }

    confirmDelete(id: any) {
        this.deleteWaitingId = id;
        // console.log(this.deleteWaitingId)
        this.deleteDialogRef.open({
            header: 'Delete Video',
            title: 'Are you sure you want to delete this video?'
        }, (e) => {
            if (e) {
                this.spinner = true;
                this.crudService.deleteBookMarkVideo(id).subscribe((success) => {
                    // this.getMainVideos();
                    // this.createInteractiveVideoObj.spinner = false;
                    this.toastr.success('Deleted successfully!');
                    this.router.navigate(['/search-result'], {queryParams: {search: ''}});
                });
            }
        });
    }

    transferVideoOwnership(item) {
        const obj = {
            videoId: item._id,
            transferToEmailAddr: '',
        };

        const dialogRef = this.dialog.open(this.transferDialog, {
            width: '100%',
            maxWidth: '500px',
            panelClass: 'my-dialog',
            height: 'auto',
        });

        dialogRef.afterClosed().subscribe((res) => {
            if (res) {
                obj.transferToEmailAddr = this.transferToEmailAddr;
                this.crudService.transferVideoOwnership(obj).subscribe(() => {
                    this.toastr.success('Video ownership transferred successfully!');
                    // this.getMainVideos();
                });
            }
        });
    }

    showVideo(id: any) {
        this.router.navigate(['videos'], {queryParams: {video: id}});
    }

    showAnalytics(video: any) {
        this.spinner = true;
        this.currentItem = video;
        this.currentItemback = video;
        this.isSecond = false;
        this.emptyChoicesData();
        this.crudService.getTopChoicesAnalytics(video._id).subscribe((success) => {
            this.topChoices = success.data;
            // console.log('this.topChoices', this.topChoices);
            this.currentItem.totalViews = 0;
            for (const i of this.topChoices) {
                this.currentItem.totalViews = this.currentItem.totalViews + i.viewCount;
            }
            if (this.currentItem.totalViews === 0) {
                this.currentItem.totalViews = 1;
            }
            // this.currentItem.
            $('#anlyticsModal').modal('show');
            this.spinner = false;
        });
    }

    getChildDetails1(id: any) {
        this.choice1 = id;
        // this.leftAarrow = false;
        this.spinner = true;

        this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
            this.childChoices1 = success.data;
            if (this.childChoices1.length == 0) {
                confirm('No child available for the selected video.');
                this.spinner = false;
            } else {
                this.resetIt = true;
                this.isSecond = true;
                this.currentItem1.totalViews = 0;
                for (const i of this.childChoices1) {
                    this.currentItem1.totalViews =
                        this.currentItem1.totalViews + i.viewCount;
                }
                if (this.currentItem1.totalViews == 0) {
                    this.currentItem1.totalViews = 1;
                }
                this.spinner = false;
            }
        });
        // console.log(this.childChoices1)
    }

    getChildDetails2(id: any) {
        this.choice2 = id;
        this.spinner = true;

        this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
            this.childChoices2 = success.data;
            if (this.childChoices2.length == 0) {
                confirm('No child available for the selected video.');
            }
            this.resetIt = true;
            this.isSecond = true;
            this.currentItem2.totalViews = 0;
            for (const i of this.childChoices2) {
                this.currentItem2.totalViews =
                    this.currentItem2.totalViews + i.viewCount;
            }
            if (this.currentItem2.totalViews == 0) {
                this.currentItem2.totalViews = 1;
            }
            // this.currentItem.
            // $('#anlyticsModal').modal('show');
            this.spinner = false;
        });
        // console.log(this.childChoices2)
    }

    getChildDetails3(id: any) {
        this.choice3 = id;
        this.spinner = true;
        this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
            this.childChoices3 = success.data;
            if (this.childChoices3.length == 0) {
                confirm('No child available for the selected video.');
                this.spinner = false;
            } else {
                this.resetIt = true;
                this.isSecond = true;
                this.currentItem3.totalViews = 0;
                for (const i of this.childChoices3) {
                    this.currentItem3.totalViews =
                        this.currentItem3.totalViews + i.viewCount;
                }
                if (this.currentItem3.totalViews == 0) {
                    this.currentItem3.totalViews = 1;
                }
                // this.currentItem.
                // $('#anlyticsModal').modal('show');
                this.spinner = false;
            }
        });
        // console.log(this.childChoices3)
    }

    getChildDetails4(id: any) {
        this.choice4 = id;
        this.spinner = true;

        this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
            this.childChoices4 = success.data;
            if (this.childChoices4.length == 0) {
                confirm('No child available for the selected video.');
                this.spinner = false;
            } else {
                this.resetIt = true;
                this.isSecond = true;
                this.currentItem4.totalViews = 0;
                for (const i of this.childChoices4) {
                    this.currentItem4.totalViews =
                        this.currentItem4.totalViews + i.viewCount;
                }
                if (this.currentItem4.totalViews == 0) {
                    this.currentItem4.totalViews = 1;
                }
                // this.currentItem.
                // $('#anlyticsModal').modal('show');
                this.spinner = false;
            }
        });
        // console.log(this.childChoices4)
    }

    getChildDetails5(id: any) {
        this.choice5 = id;
        this.spinner = true;

        this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
            this.childChoices5 = success.data;
            if (this.childChoices5.length == 0) {
                confirm('No child available for the selected video.');
                this.spinner = false;
            } else {
                this.resetIt = true;
                this.isSecond = true;
                this.currentItem5.totalViews = 0;
                for (const i of this.childChoices5) {
                    this.currentItem5.totalViews =
                        this.currentItem5.totalViews + i.viewCount;
                }
                if (this.currentItem5.totalViews == 0) {
                    this.currentItem5.totalViews = 1;
                }
                // this.currentItem.
                // $('#anlyticsModal').modal('show');
                this.spinner = false;
            }
        });
        // console.log(this.childChoices5)
    }

    getChildDetails6(id: any) {
        this.choice6 = id;
        this.spinner = true;

        this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
            this.childChoices6 = success.data;
            if (this.childChoices6.length == 0) {
                confirm('No child available for the selected video.');
                this.spinner = false;
            } else {
                this.resetIt = true;
                this.isSecond = true;
                this.currentItem6.totalViews = 0;
                for (const i of this.childChoices6) {
                    this.currentItem6.totalViews =
                        this.currentItem6.totalViews + i.viewCount;
                }
                if (this.currentItem6.totalViews == 0) {
                    this.currentItem6.totalViews = 1;
                }
                // this.currentItem6.
                // $('#anlyticsModal').modal('show');
                this.spinner = false;
            }
        });
        // console.log(this.childChoices6)
    }

    getChildDetails7(id: any) {
        this.choice7 = id;
        this.spinner = true;

        this.crudService.getTopChoicesAnalytics(id).subscribe((success) => {
            this.childChoices6 = success.data;
            if (this.childChoices6.length == 0) {
                confirm('No child available for the selected video.');
                this.spinner = false;
            } else {
                this.resetIt = true;
                this.isSecond = true;
                this.currentItem7.totalViews = 0;
                for (const i of this.childChoices6) {
                    this.currentItem7.totalViews =
                        this.currentItem7.totalViews + i.viewCount;
                }
                if (this.currentItem7.totalViews == 0) {
                    this.currentItem7.totalViews = 1;
                }
                // this.currentItem6.
                // $('#anlyticsModal').modal('show');
                this.spinner = false;
            }
        });
        // console.log(this.childChoices6)
    }

    emptyChoicesData() {
        this.topChoices = [];
        this.childChoices1 = [];
        this.childChoices2 = [];
        this.childChoices3 = [];
        this.childChoices4 = [];
        this.childChoices5 = [];
        this.childChoices6 = [];
    }

    resetStats() {
        this.childChoices1 = [];
        this.childChoices2 = [];
        this.childChoices3 = [];
        this.childChoices4 = [];
        this.childChoices5 = [];
        this.childChoices6 = [];
        this.resetIt = false;
    }

    openShareDialog(id) {
        const dialogRef = this.dialog.open(ShareVideoDialogComponent, {
            width: '100%',
            maxWidth: '600px',
            minHeight: '400px',
            panelClass: 'my-dialog',
            height: 'auto',
            data: {
                videoID: id,
                URL: `${environment.currentSharedDomain}videos?video=${id}`,
                embedURL: `${environment.currentSharedDomain}embed/${id}`,
            },
        });
    }

}
