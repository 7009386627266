// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-hidden-file
{
    position: absolute;
    opacity: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
}

.video-common-form .short-bio-form textarea {
    padding: 5px 10px 10px 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/afterLogin/profileCompletion/profileCompletion.component.css"],"names":[],"mappings":"AAAA;;IAEI,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,WAAW;IACX,YAAY;IACZ,OAAO;IACP,MAAM;IACN,eAAe;AACnB;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".input-hidden-file\r\n{\r\n    position: absolute;\r\n    opacity: 0;\r\n    z-index: 9;\r\n    width: 100%;\r\n    height: 100%;\r\n    left: 0;\r\n    top: 0;\r\n    cursor: pointer;\r\n}\r\n\r\n.video-common-form .short-bio-form textarea {\r\n    padding: 5px 10px 10px 10px;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
