import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { GroupVideo } from './group.model';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import axios from 'axios'; // Import axios to fetch image data

const API_URL = environment.API_URLV2;

interface GroupResponse {
  error: boolean;
  message: string;
  data: GroupVideo[];
}

@Injectable({ providedIn: 'root' })
export class GroupService {
  private _groupVideos = new BehaviorSubject<GroupVideo[]>([]);

  get groupVideos() {
    return this._groupVideos.asObservable();
  }

  constructor(private http: HttpClient) { }

  getGroupCompletionRate(groupId: string, limit: number, page: number) {
    return this.http
      .get<GroupResponse>(
        `${API_URL}/users/getGroupCompletionRate?groupId=${groupId}&limit=${limit}&page=${page}`
      )
      .pipe(
        map((groupVideoData) => {
          return {
            groupVideos: groupVideoData.data.map((groupVideo) => {
              const completedCount = groupVideo.groupMembersInfo.filter(m => m.isCompleted);
              const totalMembers = groupVideo.groupMembersInfo.length;
              const completionRate = completedCount.length !== 0 ? (completedCount.length / totalMembers) : 0;
              return {
                _id: groupVideo._id,
                videoInfo: groupVideo.videoInfo,
                groupMembersInfo: groupVideo.groupMembersInfo,
                viewCompletionBadgeInfo: groupVideo.viewCompletionBadgeInfo,
                totalMembers,
                progressCount: completedCount.length,
                completionRate
              };
            }),
          };
        }),
        tap((groupData) => {
          this._groupVideos.next(groupData.groupVideos);
        })
      );
  };

  getGroupVideoCompletionRate(groupId: string, videoId: string, limit: number, page: number) {
    return this.http
      .get<GroupResponse>(
        `${API_URL}/users/getGroupVideoCompletionRate?groupId=${groupId}&videoId=${videoId}&limit=${limit}&page=${page}`
      )
      .pipe(
        map((groupVideoData: any) => {
          groupVideoData.data = [groupVideoData.data];
          return {
            groupVideos: groupVideoData.data.map((groupVideo) => {
              const completedCount = groupVideo.groupMembersInfo.filter(m => m.isCompleted);
              const totalMembers = groupVideo.groupMembersInfo.length;
              const completionRate = completedCount.length !== 0 ? (completedCount.length / totalMembers) : 0;
              return {
                _id: groupVideo._id,
                videoInfo: groupVideo.videoInfo,
                groupMembersInfo: groupVideo.groupMembersInfo,
                viewCompletionBadgeInfo: groupVideo.viewCompletionBadgeInfo,
                totalMembers,
                progressCount: completedCount.length,
                completionRate
              };
            }),
          };
        }),
        tap((groupData) => {
          this._groupVideos.next(groupData.groupVideos);
        })
      );
  }

  getGroupCompletionRateWithDates(groupId: string, videoId: string, limit: number, page: number) {
    return this.http
      .get<GroupResponse>(
        `${API_URL}/users/getGroupCompletionRateWithDates?groupId=${groupId}&videoId=${videoId}&limit=${limit}&page=${page}`
      )
      .pipe(
        map((groupVideoData: any) => {
          groupVideoData.data = groupVideoData.data;
          return {
            groupVideos: groupVideoData.data.map((groupVideo) => {
              const completedCount = groupVideo.groupMembersInfo.filter(m => m.isCompleted);
              const totalMembers = groupVideo.groupMembersInfo.length;
              const completionRate = completedCount.length !== 0 ? (completedCount.length / totalMembers) : 0;
              return {
                _id: groupVideo._id,
                videoInfo: groupVideo.videoInfo,
                groupMembersInfo: groupVideo.groupMembersInfo,
                viewCompletionBadgeInfo: groupVideo.viewCompletionBadgeInfo,
                totalMembers,
                progressCount: completedCount.length,
                completionRate
              };
            }),
          };
        }),
        tap((groupData) => {
          this._groupVideos.next(groupData.groupVideos);
        })
      );
  }

  generateExcel(fileInfo: any) {
    const title = fileInfo?.title;
    const groupName = fileInfo?.groupName;
    const groupProgress = fileInfo?.groupProgress;
    const header = fileInfo?.header;
    const data = fileInfo?.data;

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet(`Group Completion Report for ${title}`);

    // Add Title Rows
    const titleRows = [
      [`Video Name: ${title}`],
      [`Group Name: ${groupName}`],
      [`Group Progress: ${groupProgress}`]
    ];

    titleRows.forEach((rowText, index) => {
      const row = worksheet.addRow(rowText);
      row.eachCell((cell) => {
        cell.font = { name: 'Corbel', size: 14, bold: true };
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
      worksheet.mergeCells(`A${index + 1}:F${index + 1}`);
      row.height = 20;
    });

    // Add an empty row and remove borders
    worksheet.addRow([]);


    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'FFE5E5E5' }, // background color (light gray)
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      cell.alignment = { vertical: 'middle', horizontal: 'center' };
    });

    headerRow.height = 30;

    // Add Data and Conditional Formatting
    data.forEach((d, rowIndex) => {
      const row = worksheet.addRow(d);
  
      const startDateCell = row.getCell(4);

      // Check if value is a valid ISO date string and convert to Date object
      if (typeof startDateCell.value === 'string' && startDateCell.value !== '-') {
        const date = new Date(startDateCell.value);
        if (!isNaN(date.getTime())) {
          const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
          startDateCell.value = utcDate;
          startDateCell.numFmt = 'mm/dd/yyyy';
        } else {
          startDateCell.value = '-';
        }
      } else if (startDateCell.value === '-') {
        startDateCell.value = '-'; 
      } else {
        startDateCell.numFmt = 'mm/dd/yyyy';
      }

      const completionDateCell = row.getCell(5);

      // Check if value is a valid ISO date string and convert to Date object
      if (typeof completionDateCell.value === 'string' && completionDateCell.value !== '-') {
        const date = new Date(completionDateCell.value);
        if (!isNaN(date.getTime())) {
          const utcDate = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));
          completionDateCell.value = utcDate;
          completionDateCell.numFmt = 'mm/dd/yyyy';
        } else {
          completionDateCell.value = '-';
        }
      } else if (completionDateCell.value === '-') {
        completionDateCell.value = '-'; 
      } else {
        completionDateCell.numFmt = 'mm/dd/yyyy';
      }

      row.eachCell((cell, number) => {
        cell.alignment = { vertical: 'middle', horizontal: 'center' };
      });
    });

    // Adjust column widths
    worksheet.getColumn(1).width = 50; 
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 20;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 30;
    worksheet.getColumn(6).width = 50;

    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, `Group Completion Report for ${title}.xlsx`);
    });
  }


}
