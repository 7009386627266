import { Component, OnInit } from '@angular/core';
import {LocalStorageService} from '../../services/localStorage.service';

@Component({
  selector: 'sideMenu',
  templateUrl: './sideMenu.component.html',
  styleUrls: ['./sideMenu.component.css']
})
export class SideMenuComponent implements OnInit {
  color: any;
  data: any;
  viewMenuItem: boolean;
  isView = true;
  isProfilePick = true
  profilePic: any;
  isMenuShow = true;

  constructor(
      private localStorageService:LocalStorageService
  ) {}
  ngOnInit() {
    const color = this.localStorageService.getItem('color');
    const isExist = this.localStorageService.getItem('title');
    if (isExist) {
      this.isView = false;
    }
    if (color) {
      this.color = color;
    } else {
      this.color = 'white'
    }
    this.getProfilePic();
    this.getCurrentWidth();


  }
  getCurrentWidth() {
    const width = window.innerWidth;
    if (width > 991) {
      this.viewMenuItem = false;
    } else {
      this.viewMenuItem = true
    }
  }
  changeColorTrending() {
    this.localStorageService.setItem('color', 'black')
  }
  changeColorHome() {
    this.localStorageService.setItem('color', 'white')

  }
  changeColorExplore() {
    this.localStorageService.setItem('color', 'white')
  }
  getProfilePic() {
    this.profilePic = this.localStorageService.getItem('profilePic')
    if (this.profilePic) {
      // alert()
      this.isProfilePick = false;
    }
  }
}
