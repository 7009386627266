import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-video',
  templateUrl: './single-video.component.html',
  styleUrls: ['./single-video.component.css'],
})
export class SingleVideoComponent implements OnInit {
  @Input() video: any;
  @Input() currentVideoId = '';
  @Input() isCompleted = false;

  constructor() {}

  ngOnInit(): void {}

  onSelectVideoFromTree(item) {}
}
