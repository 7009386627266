import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {environment} from '../../../environments/environment';
import {DashboardService} from '../../services/dashboard.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {LocalStorageService} from '../../services/localStorage.service';

declare var Stripe;
declare var $;

@Component({
    selector: 'app-partner-checkout',
    templateUrl: './partner-checkout.component.html',
    styleUrls: ['./partner-checkout.component.css']
})
export class PartnerCheckoutComponent implements OnInit, OnDestroy {
    spinner = false;
    allShoppingCart = [];
    shoppingCart: any;
    shoppingCartId: any;
    groupId: any;
    isShoppingCartShown: any = false;
    totalCharge = 0;
    currentUser: any;
    sellerInformation: any;
    sellerPayCustomer: any;
    selectedGroupId: any = ''; // this is the groupId passed from the buyer's community page
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private stripe: any;
    private elements: any;

    constructor(
        private urlService: CrudService,
        private dashboardService: DashboardService,
        private activeRoute: ActivatedRoute,
        private router: Router,
        private localStorageService: LocalStorageService,
        @Inject(DOCUMENT) private document
    ) {

    }

    ngOnInit(): void {

        this.isShoppingCartShown = false;

        this.activeRoute.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe(res => {
            if (res.id) {
                this.selectedGroupId = res.id;
                this.dashboardService.onGetShoppingCart();
                //console.log('this.selectedGroupId = ', this.selectedGroupId);
            }
        });

        this.dashboardService.getUserData.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
            this.currentUser = res;
        });

        this.dashboardService.onGetShoppingCart();
        this.dashboardService.getShoppingCarts.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
            // res contains the array of shopping carts
            if (res.length) {
                this.allShoppingCart = res;
                //console.log('this.dashboardService.getShoppingCarts -- res = ', res);

                if (this.selectedGroupId !== '') {
                    // Show the selected shopping cart
                    // this.shoppingCart = res.find(x => (x.groupId === this.selectedGroupId));
                    this.shoppingCart = res.find(x => (x?.groupId === this.selectedGroupId));
                }
                if (!this.shoppingCart) {
                    // Show the first shopping cart in the array
                    this.shoppingCart = res[0];
                }
                this.shoppingCartId = this.shoppingCart._id;

                //console.log('Existing shoppingCart = ', this.shoppingCart);

                if (this.shoppingCart) {
                    this.totalCharge = this.shoppingCart.totalAmount || 0;
                    if (this.shoppingCart.totalDiscount) {
                        this.totalCharge = (this.shoppingCart.totalAmount || 0) - (this.shoppingCart.totalDiscount || 0);
                    }
                    const firstItem = this.shoppingCart.shoppingCartItems[0].shoppingCartItemDetails;
                    this.groupId = firstItem.groupId;
                    this.localStorageService.setItem('groupSubscriptionId', firstItem.groupId);
                    this.getSellerInformation(firstItem.userId);
                } else {
                    this.shoppingCart.totalAmount = 0;
                    this.shoppingCart.totalDiscount = 0;
                    this.totalCharge = 0;
                }
                this.isShoppingCartShown = true;

                $('.owl-carousel-two').owlCarousel('destroy');
                setTimeout(() => {
                        const owl = $('.owl-carousel-two');
                        owl.owlCarousel({
                            loop: false,
                            margin: 10,
                            autoWidth: true,
                            nav: true,
                            dots: false,
                            responsive: {
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 3
                                },
                                1000: {
                                    items: 10
                                }
                            }
                        });
                    }, 500);
                }


        });


    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    deleteItem(itemNo: any) {
        this.spinner = true;

        const payload = {
            shoppingCartId: this.shoppingCartId,
            items: [itemNo]
        }

        this.urlService.deleteShoppingCartItems(payload).subscribe(() => {
            this.urlService.deactivateShoppingCart({shoppingCartId: this.shoppingCartId}).subscribe(() => {
                this.spinner = false;
                this.dashboardService.onGetShoppingCart();
                this.router.navigateByUrl('community/' + this.groupId);
            });
        });
    }


    getSellerInformation(userId) {
        if (!this.sellerInformation) {
            this.spinner = true;
            this.urlService.getUserInfoV2(userId).subscribe((res) => {
                this.sellerInformation = res.data?.userInfo;

                //console.log("getSellerInformation - this.sellerInformation = ", this.sellerInformation);
                
                if (this.sellerInformation) {
                    this.localStorageService.setItem('groupSubscriptionAdminId', this.sellerInformation._id);
                    this.localStorageService.setItem('isFollowingSeller', this.sellerInformation.isFollowing);

                    if (this.sellerInformation.business?.isPartnerProgram)
                        this.localStorageService.setItem('isPartnerProgram', 'true');
                    else
                        this.localStorageService.setItem('isPartnerProgram', 'false');

                    this.spinner = true;
                    // Get Stripe Pay Customer for the Connected Account
                    // this.urlService.getSellerPayCustomer(userId).subscribe(success => {
                    //     this.sellerPayCustomer = success.data;
                    //     console.log('seller pay customer', this.sellerPayCustomer)
                    // }).add(() => this.spinner = false);
                }
            }).add(() => this.spinner = false);
        }
    }


    // WC 03132022 - initializePayment will create Stripe payment intent based on the shopping cart id
    // Fetches a Stripe payment intent and captures the client secret
    initializePayment(planType: string) {

        // Set localstorage data for payment confirmation
        this.localStorageService.setItem('sellerShoppingCartId', this.shoppingCartId);

        // console.log("this.sellerInformation.accountOnboardingStatus = ", this.sellerInformation.accountOnboardingStatus);

        // if (this.sellerInformation.accountOnboardingStatus === 'Completed' || this.sellerInformation.accountOnboardingStatus === 'Rejected') {
        //     this.localStorageService.setItem('sellerAccountId', this.sellerPayCustomer.sellerAccountId);
        //     this.localStorageService.setItem('payCustomerId', this.sellerPayCustomer.customerAccountId);
        // }

        if (planType === 'subscriptionPlanZero') {
            this.localStorageService.setItem('subscriptionPlanZero', 'true');
            this.router.navigateByUrl('/community-payment-confirmation');
            return;
        }

        //if (this.sellerInformation.accountOnboardingStatus === 'Completed' || this.sellerInformation.accountOnboardingStatus === 'Rejected') {
            this.localStorageService.setItem('sellerClientSecret', this.shoppingCart.clientSecret);
            this.localStorageService.setItem('sellerSubscriptionId', this.shoppingCart.subscriptionId);
        //}

        //this.localStorageService.removeItem('subscriptionPlanZero');

        this.spinner = true;
        this.isShoppingCartShown = false;
        this.stripe = Stripe(environment.Stripe_PK);
        $('#payment-term-agreement-Modal').modal('hide');

        // Hide the payment terms
        document.getElementById('payment-term-agreement-Modal').classList.add('hidden');

        // Show the payment form
        document.getElementById('selected-plan-container').classList.remove('hidden');
        document.getElementById('payment-container').classList.remove('hidden');

        // Initialize Stripe payment element
        const clientSecret = this.shoppingCart.clientSecret;

        const appearance = {
            theme: 'stripe',
            variables: {
                fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
            }
        };

        //console.log('clientSecret = ', clientSecret);

        this.elements = this.stripe.elements({appearance, clientSecret});

        // Create and mount the payment element
        const paymentElement = this.elements.create('payment');

        paymentElement.mount('#payment-element');

        this.spinner = false;

    };

    onSubmitPayment() {
        this.handleSubmit(this.elements, this.document)
    };

    async handleSubmit(elements: any, document: any) {

        this.setLoading(true, document);

        const result = await this.stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: environment.Community_Success_Payment_Redirect
            },
        });

        //console.log('stripe.confirmPayment result = ', result);

        // Inform the customer that there was an error.
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (result && (result.error.type === 'card_error' || result.error.type === 'validation_error' || result.error.type === 'invalid_request_error')) {
            this.showMessage(result.error.message, document);
            this.setLoading(false, document);
        } else {
            this.showMessage('An unexpected error occured.', document);
            this.setLoading(false, document);
        }
    }

// ------- UI helpers -------

    showMessage(messageText: any, document: any) {
        const messageContainer = document.getElementById('payment-message');

        messageContainer.classList.remove('hidden');
        messageContainer.textContent = messageText;

        setTimeout(() => {
            messageContainer.classList.add('hidden');
            messageContainer.textContent = '';
        }, 4000);
    }

// Show a spinner on payment submission
    setLoading(isLoading: boolean, document: any) {

        if (isLoading) {
            // Disable the button and show a spinner
            document.getElementById('submit').disabled = true;
            document.getElementById('spinner').classList.remove('hidden');
            document.getElementById('button-text').classList.add('hidden');
        } else {
            document.getElementById('submit').disabled = false;
            document.getElementById('spinner').classList.add('hidden');
            document.getElementById('button-text').classList.remove('hidden');
        }
    }

    selectPlan() {
        if (this.localStorageService.getItem('groupSubscriptionId')) {
            this.router.navigateByUrl('community/' + this.localStorageService.getItem('groupSubscriptionId'));
        } else {
            this.router.navigateByUrl('my-groups');
        }
    }

    formatPhoneNumber(phoneNumberString) {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1,2})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
        }
        return '';
    }

    navigateToOtherShoppingCart(item: any) {
        const groupId = item.shoppingCartItems[0]?.shoppingCartItemDetails?.groupId;
        const sellerAccountId = item.shoppingCartItems[0]?.shoppingCartItemDetails?.sellerAccountId;

        if (groupId) {
            this.spinner = true;
            this.sellerInformation = null;

            if (sellerAccountId != "Partner Program")
                this.router.navigate(['/community-checkout'], {queryParams: {id: groupId}});
            else
                this.router.navigate(['/partner-checkout'], {queryParams: {id: groupId}});
        } else {
            this.router.navigate(['/checkout']);
        }
    }

    navigateToMainCommunity() {
        
        // this.router.navigate(['/main-community'], {queryParams: {user:this.sellerInformation._id}});
        this.router.navigate(['/my-community'], {queryParams: {user:this.sellerInformation._id}});
    }
}




