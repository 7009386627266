import {Component, OnInit} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr'
import * as _ from 'lodash';
import {Location} from '@angular/common';
import {LocalStorageService} from '../../services/localStorage.service';

declare var CanvasJS: any;
declare var $: any;
declare var owlCarousel: any;

@Component({
    selector: 'app-new-public-profile',
    templateUrl: './new-public-profile.component.html',
    styleUrls: ['./new-public-profile.component.css']
})
export class NewPublicProfileComponent implements OnInit {


    dashboardObj: any = {spinner: false}
    privacySettings: any = {};
    spinners = {
        badges: true
    }
    notificationSettings: any = {};
    userId: any;
    mainID: any;
    publishedVideos: Array<any> = [];
    viewerChoices: Array<any> = [];
    draftVideos: Array<any> = [];
    recentMainVideo: any = [];
    userData: any = {};

    followers: Array<any> = [];
    following: Array<any> = [];
    recentFollowing: Array<any> = [];
    recentFollowers: Array<any> = [];
    topViewedChoices: Array<any> = [];
    topChoicesLoaded = false;
    index: any = 0;
    followingFlag = false;
    watchHistory: Array<any> = [];
    likedVideos: Array<any> = [];
    sharedVideos: Array<any> = [];
    currentUser: any;  // logged-in user
    isLoggedIN: any = false;
    membershipName: any = '';
    spinner = false;

    /* new */
    currentTab = 'tab1';
    groups = [];
    isSeller = false;
    activities: Array<any> = [];
    badges = {
        creation: [],
        earned: [],
        creationCount: 0,
        earnedCount: 0,
    };
    currentBadgeTab = 'creation';
    isEmbed: any;

    constructor(public urlService: CrudService,
                public toastr: ToastrService,
                public router: Router,
                public activatedRoute: ActivatedRoute,
                public localStorageService: LocalStorageService,
                private location: Location,
    ) {
        this.activatedRoute.queryParams.subscribe(params => {

            if (params.embed && params.embed === 'true') {
                this.isEmbed = params?.embed;
            }

            this.userId = params.user;
            this.mainID = params.user;

            // this.isLoggedIN = !(this.localStorageService.getItem('token') == null && this.localStorageService.getItem('token') === undefined);
            // console.log("this.localStorageService.getItem('user') = ", this.localStorageService.getItem("user"));

            if (this.localStorageService.getItem('user')) {
                this.isLoggedIN = true;
                // console.log("this.isLoggedIN = ", this.isLoggedIN);
                this.currentUser = this.localStorageService.getItem('user');
            } else {
                this.isLoggedIN = false;
                if (this.localStorageService.getItem('anonymousToken') == null || this.localStorageService.getItem('anonymousToken') == undefined)
                    this.getAnonymousUser()
            }

            if (this.userId)
                this.init();
            else
                this.router.navigateByUrl('/profile');

        });
    }

    ngOnInit() {


    }

    getAnonymousUser() {
        this.urlService.getAnonymousUser().subscribe((success) => {
            try {
                this.localStorageService.setItem('anonymousToken', success.data.token);
            } catch {
                this.urlService.anonymousToken = success.data.token;
            }
        });
    }

    getRandowmValue() {
        return Math.floor((Math.random() * 70) + 30);
    }

    init() {
        this.dashboardObj.spinner = true;

        this.getProfileUserdata(true);
        this.getBadges();
    }

    showCategory(id: any) {

        this.router.navigate(['category'], {queryParams: {category: id}});

    }

    // Get user data (processed on the basis of user ID)
    getProfileUserdata(loadAll = false) {
        // console.log('getProfileUserdata - this.userId = ', this.userId);
        this.spinner = true;
        this.urlService.getUserInfoV2(this.userId).subscribe((success) => {
            this.userData = success.data;
            this.privacySettings = success?.data?.userInfo?.privacySettings;
            this.notificationSettings = success?.data?.userInfo?.notifications;
            this.spinner = false;

            // this.membershipName = this.userData.userInfo.planName === 'Freemium' ? '' : this.userData.userInfo.planName;

            if (this.userData.userInfo.planName.includes('Bronze', 0)) {
                this.membershipName = 'Bronze member';
              } else if (this.userData.userInfo.planName.includes('Silver', 0)) {
                this.membershipName = 'Silver member';
              } else if (this.userData.userInfo.planName.includes('Gold', 0)) {
                this.membershipName = 'Gold member';
              } else if (this.userData.userInfo.planName.includes('Platinum', 0)) {
                this.membershipName = 'Platinum member';
              } else if (this.userData.userInfo.planName.includes('Freemium', 0)) {
                this.membershipName = 'Freemium member';
              } else if (this.userData.userInfo.planName.includes('Pewter', 0)) {
                this.membershipName = 'Pewter member';
              }

            this.userData.userInfo.username = this.add3Dots(this.userData?.userInfo?.username, 12);

            if (this.userData?.userInfo?.bio.length > 0)
                this.userData.userInfo.bio[0] = this.add3Dots(this.userData?.userInfo?.bio[0], 50);

            if (loadAll) {
                if (this.userData?.userInfo?.connectedAccountId) {
                    this.isSeller = true;
                    this.currentTab = 'community';
                    this.getGroups();
                }

                // this.getWatchHistory();
                this.getActivityVideos();

                this.getRecentVideos();
            }
        });
    }

    getRecentVideos() {
        this.spinner = true;
        // this.urlService.getUserInfo(this.userId).subscribe(
        //     success => {
        //         this.publishedVideos = success.data.publishedVideo;
        //         let sortedArr = _.sortBy(this.publishedVideos, ['updatedAt']);
        //         sortedArr = _.chunk(sortedArr, 4);
        //         this.recentMainVideo = sortedArr[0];
        //     }).add(() => this.spinners.second = false);

        this.urlService.getInteractiveVideoV2(this.userId, true, 4, 1, 'recent').subscribe(
            success => {
                this.recentMainVideo = success.data;
            }).add(() => this.spinner = false)
    }

    add3Dots(s: any, limit: number): string {
        // var dots = "...";
        // if(s.length > limit) {
        //   s = s.substring(0,limit) + dots;
        // }
        return s;
    }

    userProfile(id: any) {
        this.router.navigate(['public-profile'], {queryParams: {user: id}});
        // window.location.reload();
    }

    // Get shared videos
    getSharedVideos() {
        this.urlService.getUserShareV2(this.userId, 4, 1).subscribe(
            success => {

                if (!Array.isArray(success.data)) {
                    this.sharedVideos = []
                } else {
                    this.sharedVideos = success.data;
                }
                this.getMatchedValue();
            });
    }

    // Get liked videos
    getLikedVideos() {
        this.urlService.getLikedVideosV2(4, 1, this.userId).subscribe(
            success => {
                if (!Array.isArray(success.data)) {
                    this.likedVideos = [];
                    // return;
                } else {
                    this.likedVideos = success.data;
                    // this.likedVideos = this.likedVideos.filter(i => i.videoData);
                    // this.likedVideos = this.likedVideos.map(i => {
                    //     i.videoData = i.videoData[0];
                    //     return i;
                    // });

                }

                this.getViewerChoices()

            });
    }

    getWatchHistory() {
        this.urlService.getWatchHistoryV2(this.userId, 4, 1).subscribe(
            success => {
                if (!Array.isArray(success.data)) {
                    this.watchHistory = [];
                    this.dashboardObj.spinner = false;
                    this.getSharedVideos();
                    this.getLikedVideos();
                    return;
                }

                this.watchHistory = success.data;

                this.dashboardObj.spinner = false;
                this.getSharedVideos();
                this.getLikedVideos();
            },
            error => {

            });
    }

    getActivityVideos() {
        this.urlService
            .getUserActivityV2(4, 1, this.userId)
            .subscribe((response) => {
              if(!Array.isArray(response.data)){
                this.activities = [];
                this.dashboardObj.spinner = false;
                this.getSharedVideos();
                this.getLikedVideos();
                return;
              }

              this.activities.push(...response.data);
              this.dashboardObj.spinner = false;
              this.getSharedVideos();
              this.getLikedVideos();

            });
    };

    // Split list into a group of n
    splitList(source: Array<any>, chunkSize: any): Array<any> {
        return _.chunk(source, chunkSize);
    }

    // Follow user
    followUser(id: any) {
        if (!this.isLoggedIN)
            this.router.navigate(['login']);
        else {
            $('#' + id).prop('disabled', true);
            const obj: any = {
                type: 'follow',
                followToUserID: this.userId
            };
            this.urlService.followUser(obj).subscribe(success => {
                // this.toastr.success('User subscription added');
            }).add(() => {
                this.getProfileUserdata();
                $('#' + id).prop('disabled', false);
            });
        }
    }

    unfollowUser(id: any) {
        if (!this.isLoggedIN)
            this.router.navigate(['login']);
        else {
            $('#' + id).prop('disabled', true);
            const obj: any = {
                type: 'unfollow',
                followToUserID: this.userId
            };
            this.urlService.followUser(obj).subscribe(success => {
                // this.toastr.success('User subscription added');
            }).add(() => {
                this.getProfileUserdata();
                $('#' + id).prop('disabled', false);
            });
        }
    }

    showVideo(id: any) {
        this.router.navigate(['videos'], {queryParams: {video: id}});
    }


    getViewerChoices() {
        // viewerChoices

        // this.spinners.sixth = true;
        this.urlService.getViewerChoiceHighlightV2(this.userId, 4, 1).subscribe(
            success => {
                this.viewerChoices = success.data;
                // console.log('this.viewerChoices = ', this.viewerChoices);

                // for (const i of this.viewerChoices) {
                //     ////// console.log(i.publishedVideo.totallevel)
                //     i.publishedVideo.currentLevel = 2;
                //     if (i.publishedVideo.currentLevel >= i.publishedVideo.totallevel) {
                //         i.nextDisabled = true;
                //     }
                // }

                for (const i of this.viewerChoices) {
                    i.publishedVideo.currentLevel = 2;
                    if (i.childVideos.length) {
                        i.childVideos = [...i.childVideos[0]];
                    }
                    if (i.publishedVideo.currentLevel >= i.publishedVideo.totallevel) {
                        i.nextDisabled = true;
                    }
                }


                // this.spinners.sixth = false;
                this.initOwlAndChart();
            }, error => {
            })
    }

    initOwlAndChart() {
        setTimeout(() => {
            $('.v-choice-carousel').owlCarousel({
                loop: false,
                margin: 15,
                nav: true,
                dots: false,
                mouseDrag: false,
                touchDrag: false,
                pullDrag: false,
                freeDrag: false,
                responsive: {
                    0: {
                        items: 1,
                    },
                    600: {
                        items: 2,
                    },
                    1000: {
                        items: 4,
                    }
                }
            })

            for (const i of this.viewerChoices) {
                i.prevDisabled = true;
                this.plotChilds(i)
            }

        }, 500)
    }

    plotChilds(i: any) {
        i.spinner = true;

        i.childVideos = i.childVideos.sort((a: any, b: any) => b.viewCount - a.viewCount);

        $('.childv-choice-carousel' + i.publishedVideo._id).owlCarousel('destroy');
        setTimeout(() => {

            $('.childv-choice-carousel' + i.publishedVideo._id).on('initialized.owl.carousel changed.owl.carousel', e => {
                if (!e.namespace) {
                    return;
                }
                const carousel = e.relatedTarget;
                $('.slider-counter' + i.publishedVideo._id).text('Choices ' + (carousel.relative(carousel.current()) + 1) + ' of ' + carousel.items().length);
            }).owlCarousel({
                items: 1,
                loop: true,
                margin: 0,
                nav: false,
                dots: true,
            });
            const item = $('.childv-choice-carousel' + i.publishedVideo._id).owlCarousel();
            item.on('changed.owl.carousel', event => {
                i.currentIndexItem = event.page.index;
            });
        }, 500)
        this.plotchart(i)

    }


    plotchart(i: any) {
        if (!!i.chart) {
            i.chart.destroy();
        }

        const data = []
        const colorData = ['#A649C7', '#EFB43A', '#3DC99C', '#DB6A8E'];
        let index = 0;
        let totalView = 0;
        for (const j of i.childVideos) {
            data.push({label: j.name, y: j.viewCount, color: colorData[index]})
            totalView = totalView + j.viewCount
            index++;
        }
        i.totalView = totalView;
        if (totalView === 0) {
            i.noViews = true;
        }
        const id = 'chartContainer' + i.publishedVideo._id;
        if (!$('#' + id).length) {
            return;
        }
        if (!i.noViews) {
            i.chart = new CanvasJS.Chart(id, {
                animationEnabled: true,
                backgroundColor: '#f5f7f6',
                data: [{
                    type: 'doughnut',
                    startAngle: 0,
                    indexLabelFontSize: 0,
                    indexLabelPlacement: 'inside',
                    innerRadius: '70%',
                    // innerRadius: 60,

                    toolTipContent: '<b>{label}:</b> {y} (#percent%)',
                    dataPoints: data
                }]
            });
            i.chart.render();

        }
        i.spinner = false;

    }

    nextVpopularPath(i) {
        if (i.childVideos.length < 1) {
            return;
        }
        i.publishedVideo.currentLevel++;
        if (i.publishedVideo.currentLevel >= i.publishedVideo.totallevel) {
            i.nextDisabled = true;
        }
        i.spinner = true;
        const nectItem = i.childVideos[0]
        this.urlService.getTopChoices(nectItem._id).subscribe(
            success => {

                // i.childVideos
                const correctArray: any = []
                for (const j of success.data) {
                    j.video.viewCount = j.viewCount
                    correctArray.push(j.video)
                }
                if (correctArray.length < 1) {
                    i.nextDisabled = true;
                    i.spinner = false;
                    this.toastr.warning('No child found!')
                    return;
                }

                i.childVideos = correctArray;
                i.prevDisabled = false;
                this.plotChilds(i)


                ////// console.log('xssd',correctArray)
            })
    }

    preVpopularPath(i) {
        i.spinner = true;
        this.urlService.getTopChoices(i.publishedVideo._id).subscribe(
            success => {

                // i.childVideos
                const correctArray: any = []
                for (const j of success.data) {
                    j.video.viewCount = j.viewCount
                    correctArray.push(j.video)
                }


                i.childVideos = correctArray;
                i.prevDisabled = true;
                i.nextDisabled = false;
                i.publishedVideo.currentLevel = 2;
                if (i.publishedVideo.currentLevel >= i.publishedVideo.totallevel) {
                    i.nextDisabled = true;
                }
                this.plotChilds(i)


                ////// console.log('xssd',correctArray)
            })

    }

    calculatePercentage(childViewcount: any, totalViews: any): any {
        if (!totalViews) return 0;
        const percantage = (childViewcount / totalViews) * 100;
        return Math.round(percantage);
    }


    getMatchedValue() {

        this.getCommonDatarecentFollowers(this.recentFollowers[0])

    }

    getCommonDatarecentFollowers(i) {

        if (!(!!i)) {
            this.getCommonDatarecentFolloweing(this.recentFollowing[0])
            return;
        }
        const item = this.userId + ',' + i.info._id;
        // let item = this.dashboardObj.userId+','+17;

        // WC 02092022 -- Remove the call to the Recommendation Engine since it's not working properly
        //  this.urlService.getCommonItem(item).subscribe(
        //   success=>{

        //     this.recentFollowers[this.index].info.content = success.data/this.watchHistory.length;
        //     if(this.watchHistory.length <1)
        //     {
        //       this.recentFollowers[this.index].info.content =success.data/success.data

        //     }
        //     this.recentFollowers[this.index].info.isshow = true
        //     this.index++;
        //     if(this.index ==this.recentFollowers.length ){
        //       this.index = 0
        //       this.getCommonDatarecentFolloweing(this.recentFollowing[0])
        //     }
        //     else if(this.index > 4){
        //       this.index = 0
        //       this.getCommonDatarecentFolloweing(this.recentFollowing[0])
        //     }
        //     else{
        //       this.getCommonDatarecentFollowers(this.recentFollowers[this.index])
        //     }

        //   },
        //   error =>{
        this.recentFollowers[this.index].info.content = 0;
        this.recentFollowers[this.index].info.isshow = true
        this.index++;
        if (this.index === this.recentFollowers.length) {
            this.index = 0
            this.getCommonDatarecentFolloweing(this.recentFollowing[0])
        } else if (this.index > 4) {
            this.index = 0
            this.getCommonDatarecentFolloweing(this.recentFollowing[0])
        } else {
            this.getCommonDatarecentFollowers(this.recentFollowers[this.index])
        }

        // }) WC 02092022
        // i.topViewed = success.data;
    }


    getCommonDatarecentFolloweing(i) {

        if (!(!!i)) {
            // this.getCommonDatarecentFolloweing(this.recentFollowing[0])
            return;
        }
        const item = this.userId + ',' + i.userInfo._id;
        // let item = this.dashboardObj.userId+','+17;

        // WC 02092022 -- Remove the call to the Recommendation Engine since it's not working properly
        //  this.urlService.getCommonItem(item).subscribe(
        //   success=>{

        //     this.recentFollowing[this.index].userInfo.content = success.data/this.watchHistory.length
        //     if(this.watchHistory.length <1)
        //     {
        //       this.recentFollowing[this.index].userInfo.content =success.data/success.data

        //     }
        //     this.recentFollowing[this.index].userInfo.isshow = true
        //     this.index++;
        //     if(this.index == this.recentFollowing.length){

        //     }
        //     else if(this.index > 4){

        //     }
        //     else{
        //       this.getCommonDatarecentFolloweing(this.recentFollowing[this.index])
        //     }

        //   },
        //   error=>{

        this.recentFollowing[this.index].userInfo.content = 0
        this.recentFollowing[this.index].userInfo.isshow = true
        this.index++;
        if (this.index === this.recentFollowing.length) {

        } else if (this.index > 4) {

        } else {
            this.getCommonDatarecentFolloweing(this.recentFollowing[this.index])
        }

        // }) WC 02092022
        // i.topViewed = success.data;
    }

    onClickBadgesTab(currentTab: any) {
        this.currentBadgeTab = currentTab;
    }

    onClickTab(currentTab: string) {
        if (currentTab === 'tab2') {
            this.initOwlAndChart();
            // this.getViewerChoices();
        }
        this.currentTab = currentTab;
    }


    goBack() {
        if ((this.location.getState() as any)?.navigationId > 1)
            this.location.back();
        else
            this.router.navigateByUrl('/profile');
    }


    private getGroups() {
        const payload = {
            accessType: 'ADMIN',
            userId: this.userId,
            isPlanAssigned: true,
            isSubscriptionGroup: true,
            limit: 4,
            page: 1
        };
        this.spinner = true;
        this.urlService.getAllGroups(payload).subscribe(res => {
            this.groups = res.data;
            // this.groups = this.groups.filter(x => x.isSubscriptionGroup);
            // this.groups = this.groups.slice(0, 4);
        }).add(() => this.spinner = false);
    }

    private getBadges() {
        this.spinners.badges = true;
        const payload = {
            limit: 8,
            page: 1,
            type: 'creation',
            userId: this.userId
        };
        this.urlService.getBadge(payload).subscribe((res: any) => {
            this.badges.creation = res.data;
        }).add(() => this.spinners.badges = false);

        payload.type = 'earned';
        this.urlService.getBadge(payload).subscribe((res: any) => {
            this.badges.earned = res.data;
        }).add(() => this.spinners.badges = false);

        this.urlService.getBadgeCount({userId:this.userId}).subscribe((res: any) => {
            this.badges.earnedCount = res.data.find(x => x.type === 'earned')?.count || 0;
            this.badges.creationCount = res.data.find(x => x.type === 'creation')?.count || 0;
        }).add(() => this.spinners.badges = false);
    }
}
