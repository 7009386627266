import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CrudService} from '../../services/crud.service';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  public isLoggedIn = false;

  constructor(public router: Router, public urlService: CrudService, private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.isLoggedIn = this.localStorageService.getItem('token') != null;

    // console.log("this.isLoggedIn : ", this.isLoggedIn);

    $(window).scroll(() => {
      const sc = $(window).scrollTop()
      if (sc > 1) {
        $('.header').addClass('scroll-fixed')
      } else {
        $('.header').removeClass('scroll-fixed')
      }
    });
    $(window).ready(() => {
      this.userlist();
    });
    $(window).resize(() => {
      this.userlist();
    });
  }

  // Responsive Header for User Top
  userlist() {
    const windowWidth = $(window).width();
    if (windowWidth < 992) {
      $('.header-user-block').insertAfter('.header .navbar-collapse .navbar-nav');
      $('.header-search').insertAfter('.header .navbar-collapse .header-user-block .navbar-nav');
    }
    else {
      $('.header-user-block').insertAfter('.header .header-right-block .header-video');
      $('.header-search').prependTo('.header .header-right-block');
    }
  }

  onClickNewVideo() {
    this.router.navigateByUrl('/upload-videos');
  }

  // Search
  onSearch(e: any) {
    this.urlService.simpleVideoSearch(e.target.value).subscribe((success) => {
      this.router.navigate(['/search-results'], { queryParams: { search: e.target.value } });
    }, (error) => {
      // console.log('error', error);
    });
  }

}
