// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.breadcrumb {*/
/*  display: flex;*/
/*  align-items: center;*/
/*  padding: 15px 0;*/
/*  margin-bottom: 0;*/
/*  background: transparent;*/
/*}*/

/*!*.breadcrumb svg {*!*/
/*!*  fill: #7a2df9;*!*/
/*!*  margin-right: 20px;*!*/
/*!*}*!*/

/*.breadcrumb a {*/
/*  font-weight: 400;*/
/*  !*font-weight: 700;*!*/
/*  !*font-size: 14px;*!*/
/*  font-size: 30px;*/
/*  !*color: #878395;*!*/
/*  color: #6c2fbb;*/
/*  padding-top: 5px;*/
/*  position: relative;*/
/*  text-decoration: none;*/
/*}*/

/*!*!*.breadcrumb a::after {*!*!*/
/*!*!*  position: absolute;*!*!*/
/*!*!*  top: 13px;*!*!*/
/*!*!*  left: -12px;*!*!*/
/*!*!*  content: "";*!*!*/
/*!*!*  width: 4px;*!*!*/
/*!*!*  height: 4px;*!*!*/
/*!*!*  background: #0f062b;*!*!*/
/*!*!*  border-radius: 50%;*!*!*/
/*!*!*}*!*!*/

/*@media (max-width: 425px) {*/
/*  .breadcrumb {*/
/*    padding: 15px 0;*/
/*  }*/
/*}*/
`, "",{"version":3,"sources":["webpack://./src/app/layout/breadcrumb/breadcrumb.component.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB,mBAAmB;AACnB,yBAAyB;AACzB,qBAAqB;AACrB,sBAAsB;AACtB,6BAA6B;AAC7B,IAAI;;AAEJ,wBAAwB;AACxB,uBAAuB;AACvB,4BAA4B;AAC5B,QAAQ;;AAER,kBAAkB;AAClB,sBAAsB;AACtB,0BAA0B;AAC1B,yBAAyB;AACzB,qBAAqB;AACrB,wBAAwB;AACxB,oBAAoB;AACpB,sBAAsB;AACtB,wBAAwB;AACxB,2BAA2B;AAC3B,IAAI;;AAEJ,iCAAiC;AACjC,gCAAgC;AAChC,uBAAuB;AACvB,yBAAyB;AACzB,yBAAyB;AACzB,wBAAwB;AACxB,yBAAyB;AACzB,iCAAiC;AACjC,gCAAgC;AAChC,YAAY;;AAEZ,8BAA8B;AAC9B,kBAAkB;AAClB,uBAAuB;AACvB,MAAM;AACN,IAAI","sourcesContent":["/*.breadcrumb {*/\n/*  display: flex;*/\n/*  align-items: center;*/\n/*  padding: 15px 0;*/\n/*  margin-bottom: 0;*/\n/*  background: transparent;*/\n/*}*/\n\n/*!*.breadcrumb svg {*!*/\n/*!*  fill: #7a2df9;*!*/\n/*!*  margin-right: 20px;*!*/\n/*!*}*!*/\n\n/*.breadcrumb a {*/\n/*  font-weight: 400;*/\n/*  !*font-weight: 700;*!*/\n/*  !*font-size: 14px;*!*/\n/*  font-size: 30px;*/\n/*  !*color: #878395;*!*/\n/*  color: #6c2fbb;*/\n/*  padding-top: 5px;*/\n/*  position: relative;*/\n/*  text-decoration: none;*/\n/*}*/\n\n/*!*!*.breadcrumb a::after {*!*!*/\n/*!*!*  position: absolute;*!*!*/\n/*!*!*  top: 13px;*!*!*/\n/*!*!*  left: -12px;*!*!*/\n/*!*!*  content: \"\";*!*!*/\n/*!*!*  width: 4px;*!*!*/\n/*!*!*  height: 4px;*!*!*/\n/*!*!*  background: #0f062b;*!*!*/\n/*!*!*  border-radius: 50%;*!*!*/\n/*!*!*}*!*!*/\n\n/*@media (max-width: 425px) {*/\n/*  .breadcrumb {*/\n/*    padding: 15px 0;*/\n/*  }*/\n/*}*/\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
