import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

declare var $;


@Directive({
    selector: '[imgWidthDirective]'
})
export class imgWidthDirective {

    @HostListener('load') onLoad() {
        // console.log('loaddddd')
        this.fitSize();
    }

    constructor(private el: ElementRef, renderer: Renderer2) {

    }

    fitSize() {
        let width = this.el.nativeElement.clientWidth;
        let height = this.el.nativeElement.clientHeight;
        if (width > height) {
            this.el.nativeElement.classList.add('w-100');
        }
    }
}