import {Directive, HostListener} from '@angular/core';

@Directive({
    selector: '[appEmojiInput]'
})
export class EmojiInputDirective {

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        this.checkInput(event)
    }

    checkInput(event) {
        const isEmojiInput = this.isEmoji(event.key);
        const isNavigationKey = this.isNavigation(event.key);

        if (!isEmojiInput && !isNavigationKey) {
            event.preventDefault();
        }
    }

    // Function to check if the input is an emoji
    isEmoji(input: string): boolean {
        // Add your emoji validation logic here, you can use a regular expression or any other method
        const emojiRegex = /(\p{Emoji_Presentation}|\p{Emoji}\uFE0F|\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Modifier_Base})+/gu;
        return emojiRegex.test(input);
    }

    // Function to check if the input is a navigation key (e.g., arrow keys, backspace, delete, etc.)
    isNavigation(input: string): boolean {
        // Define an array of allowed navigation keys (add any other keys you want to allow)
        const allowedNavigationKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Home', 'End'];

        return allowedNavigationKeys.includes(input);
    }

}
