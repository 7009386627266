import {NgModule} from '@angular/core';
import {DurationPickerDirective} from './duration-picker.directive';
import {imgName} from './imgDirective';
import {imgWidthDirective} from './imgWidthDirective';
import {thumnailsDirective} from './thumnailsDirective';
import {VideoJS} from './videojsDirective';
import { EmojiInputDirective } from './emoji-input.directive';

@NgModule({
  declarations: [imgName, DurationPickerDirective, imgWidthDirective, thumnailsDirective, VideoJS, EmojiInputDirective],
    exports: [imgName, DurationPickerDirective, imgWidthDirective, thumnailsDirective, VideoJS, EmojiInputDirective],
})
export class DirectiveModule {
}
