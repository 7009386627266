import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { CrudService } from "src/app/services/crud.service";

@Component({
  selector: "app-feature-request-group-info",
  templateUrl: "./feature-request-group-info.component.html",
  styleUrls: ["./feature-request-group-info.component.css"],
})
export class FeatureRequestGroupInfoComponent implements OnInit {
  spinner: boolean = false;
  groupDetail: any;
  groupId: any;
  submitted = false;
  RequestUserInfo!: UntypedFormGroup;
  groupAdmins: any;

  constructor(
    private api: CrudService,
    private activateRoute: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private toastr: ToastrService,
    public dialogRef: MatDialogRef<FeatureRequestGroupInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.groupId = this.data;
    this.getGroupDetail();
    this.getGroupAdmins();

    this.RequestUserInfo = this.formBuilder.group({
      firstname: ["", [Validators.required, this.trimValidator()]],
      lastname: ["", [Validators.required, this.trimValidator()]],
      email: ["", [Validators.required, Validators.email, this.trimValidator()]],
      title: ["", [Validators.required, this.trimValidator()]],
      linkedInLink: [
        "",
        [Validators.required, this.trimValidator(), this.validLinkedInURLValidator()],
      ],
    });
  }

  get f() {
    return this.RequestUserInfo.controls;
  }


  validLinkedInURLValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!control.value) {
        return null; 
      }
      const urlPattern = /^(https?:\/\/)?(www\.)?(linkedin\.com|in\.linkedin\.com)\/(in|pub|company)\/[a-zA-Z0-9_-]+\/?$/i;

      const isValid = urlPattern.test(control.value);
      return !isValid ? { invalidLinkedInURL: true } : null;
    };
  }
  
  

  trimValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const trimmedValue = control.value.trim();
      if (trimmedValue === "") {
        return { required: true };
      }
      return null;
    };
  }  
  

  getGroupDetail() {
    this.spinner = true;
    this.api.getGroupDetail(this.groupId).subscribe((res) => {
      this.spinner = false;
      this.groupDetail = res.data[0];
    });
  }

  getGroupAdmins() {
    this.spinner = true;
    this.api.getGroupAdmins(this.groupId).subscribe((res) => {
      this.spinner = false;
      this.groupAdmins = res.data;
    });
  }

  onSubmit() {
    this.submitted = true;
    this.spinner = true;

    if (this.RequestUserInfo.invalid) {
      this.spinner = false;
      return;
    }

    const getGroupAdminEmails = () => {

      const emails = this.groupAdmins.map(admin => admin.adminInfo.email);

      const emailsString = emails.join(', ');

      // console.log("getGroupAdminEmails - emailsString = ", emailsString);

      return emailsString;

    }

    let payload = {
      firstName: this.RequestUserInfo.value.firstname,
      lastName: this.RequestUserInfo.value.lastname,
      email: this.RequestUserInfo.value.email,
      title: this.RequestUserInfo.value.title,
      linkedInProfile: this.RequestUserInfo.value.linkedInLink,
      groupName: this.groupDetail?.groupName,
      groupAdminEmails: getGroupAdminEmails(),
    };

    this.api.requestFeaturedGroupInfo(payload).subscribe((res) => {
      this.spinner = false;
      this.toastr.success(res.message)
      this.dialogRef.close();
    }, (error) => {
      this.toastr.error(error.message)
    });
  }
}
