// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-vid-area {
    border: 1px dotted black;
    border-radius: 10px;
    padding: 28px 15px;
    margin: 15px;
}

.record-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin: 1rem 0;
}

::ng-deep .editorType .form-check-input:checked{
    background-color: #6c2fbb !important;
    border-color: #6c2fbb !important;
}

.betaTag {
    color: red;
}

.contentTitle{
    border-bottom: 1px solid #dfdddd;
    margin-bottom: 10px;
    padding-bottom: 12px;
}
.okBtnLine{
    border-top: 1px solid #dfdddd;
}
.btnOk{
    border: 1px solid #6e5eff;
    padding: 7px 30px;
    border-radius: 5px;
    color: #ffff;
    font-size: 15px;
    cursor: pointer;
    background: #6e5eff;
    text-align: center;
    margin-right: 10px;
}

@media (max-width: 991px) and (max-height: 430px) {
    .video-js {
        height: 80vh !important;
    }
}
@media (max-width: 768px) {
    .video-js {
        width: 100% !important;
        height: 60vh;
    }
}

@media (max-width: 360px) {
     ::ng-deep .mat-dialog-actions{
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
      }
}
`, "",{"version":3,"sources":["webpack://./src/app/layout/modals/record-video/record-video.component.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;IACR,cAAc;AAClB;;AAEA;IACI,oCAAoC;IACpC,gCAAgC;AACpC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,gCAAgC;IAChC,mBAAmB;IACnB,oBAAoB;AACxB;AACA;IACI,6BAA6B;AACjC;AACA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI;QACI,uBAAuB;IAC3B;AACJ;AACA;IACI;QACI,sBAAsB;QACtB,YAAY;IAChB;AACJ;;AAEA;KACK;YACO,sBAAsB;YACtB,uBAAuB;YACvB,SAAS;MACf;AACN","sourcesContent":[".select-vid-area {\n    border: 1px dotted black;\n    border-radius: 10px;\n    padding: 28px 15px;\n    margin: 15px;\n}\n\n.record-controls {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 5px;\n    margin: 1rem 0;\n}\n\n::ng-deep .editorType .form-check-input:checked{\n    background-color: #6c2fbb !important;\n    border-color: #6c2fbb !important;\n}\n\n.betaTag {\n    color: red;\n}\n\n.contentTitle{\n    border-bottom: 1px solid #dfdddd;\n    margin-bottom: 10px;\n    padding-bottom: 12px;\n}\n.okBtnLine{\n    border-top: 1px solid #dfdddd;\n}\n.btnOk{\n    border: 1px solid #6e5eff;\n    padding: 7px 30px;\n    border-radius: 5px;\n    color: #ffff;\n    font-size: 15px;\n    cursor: pointer;\n    background: #6e5eff;\n    text-align: center;\n    margin-right: 10px;\n}\n\n@media (max-width: 991px) and (max-height: 430px) {\n    .video-js {\n        height: 80vh !important;\n    }\n}\n@media (max-width: 768px) {\n    .video-js {\n        width: 100% !important;\n        height: 60vh;\n    }\n}\n\n@media (max-width: 360px) {\n     ::ng-deep .mat-dialog-actions{\n            flex-direction: column;\n            align-items: flex-start;\n            gap: 10px;\n      }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
