import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-security-policy',
  templateUrl: './security-policy.component.html',
  styleUrls: ['./security-policy.component.css']
})
export class SecurityPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
