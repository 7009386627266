import {AfterViewChecked, Component, Inject, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {CrudService} from 'src/app/services/crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {DOCUMENT} from '@angular/common';
import {DashboardService} from '../../services/dashboard.service';
import sanitizeSVG from '@mattkrick/sanitize-svg';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $;
declare var Stripe;

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit, AfterViewChecked {
    passwordPattern = /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/;

    private clientSecret = '';
    private paymentConf = '';
    isCustomerPaymentUpdateSuccessful = false;
    isPaymentMethodSetUpSuccessful = false;
    private isPaymentStatusAvail = false;
    private isCustomerPaymentUpdateStatusAvail = false;
    private stripe = null;
    currentPaymentMethod = null;
    allCommunityPlan = [];
    isMainPlanUnSubscribe = false;
    editProfileObject: any = {
        email: '',
        firstname: '',
        lastname: '',
        username: '',
        password: '',
        cPassowrd: '',
        newPassword: '',
        spinner: false,
        profilePic: '',
        bio: '',
        categories: [],
        show: 'first',
        imagePath: '',
        fileData: '',
        allCategories: [],
        useCase: '',
        selectedUseCase: '',
        useCases: [],
        checkedList: [],
        showingObj: [],
        privacySettings: {},
        notifications: {},
        updatedAt: '',
        createdAt: '',
        passwordUpdatedAt: '',
        // WC 05132022
        isSubscriber: false,
        subscriptionPlanId: '',
        planName: '',
        premiumUnit: '',
        premium: 0,
        payCustomerId: '',
        business: {
            name: '',
            cust_service_address: {
                city: '',
                country: '',
                line1: '',
                line2: '',
                postal_code: '',
                state: ''
            },
            cust_service_phone: '',
            website: ''
        },
        connectedAccountId: '',
        isDeactivated: false
        // WC 05132022
    }
    fieldPassTextType: any = false;
    privacySettings: any = {};
    notifications: any = {};

    adminGroups = [];
    oldPassword: any = '';
    confirmPassword: any = '';
    newPassword: any = '';
    fieldOldPassTextType = false;
    fieldNewPassTextType = false;
    fieldCNewPassTextType = false;
    // WC 07212022
    private elements: any;
    spinner = false;
    private isPaymentUpdateStatusShown = false;
    private isActionShown = false;
    private isShowingSubscriptionPlan = false;
    private action: any = '';
    otp:any;
    userPrompt: any;
    imageURL: any;

    // WC 07212022
    private cancelCommunitySubscriptionId: any;

    constructor(
        private urlService: CrudService,
        private localStorageService: LocalStorageService,
        private dashboardService: DashboardService,
        private router: Router,
        private route: ActivatedRoute,
        private toastr: ToastrService,
        @Inject(DOCUMENT) private document
    ) {
        this.route.queryParams.subscribe(params => {
            this.editProfileObject.spinner = true;
            // console.log("params.payment_intent_client_secret = ", params.payment_intent_client_secret);

            // console.log('params.setup_intent for payment method update = ', params.setup_intent);

            // There is a payment setup status coming back from Stripe
            if (params.setup_intent != undefined) {
                this.isPaymentStatusAvail = true;
                this.clientSecret = params.setup_intent_client_secret;
                this.paymentConf = params.setup_intent;
                if (params.redirect_status == 'succeeded') {
                    this.isPaymentMethodSetUpSuccessful = true;
                    // this.updateCustomerPaymentMethod();
                    this.updateSubscriptionPaymentMethod();
                } else
                    this.isPaymentMethodSetUpSuccessful = false;

                // Remove the query string URL from Stripe and reload the page to avoid showing payment status when cancelling the subscription after updating the payment method
                const currentUrl = window.location.href.split('?')[0];
                window.history.replaceState({}, '', currentUrl);
            } else {
                this.isPaymentStatusAvail = false
            }

            if (params.action != undefined)
                this.action = params.action;
        });
    }

    ngOnInit() {

        this.editProfileObject.spinner = true;

        this.urlService.getAllGroups({accessType: 'ADMIN'}).subscribe((res) => {
            if (res.data && res.data.length) {
                this.adminGroups = res.data;
            }
        });

        this.getUserData();
     
    }

    getUserData(){
        this.editProfileObject.spinner = true;
        this.urlService.userDetailsV2().subscribe(
            success => {
                const userObject = success.data.details;
                this.editProfileObject.email = userObject.email;
                this.editProfileObject.firstname = userObject.firstname;
                this.editProfileObject.lastname = userObject.lastname;
                this.editProfileObject.categories = userObject.category;
                this.editProfileObject.profilePic = userObject.profilePic;
                this.editProfileObject.privacySettings = userObject.privacySettings;
                this.editProfileObject.notifications = userObject.notifications;
                this.editProfileObject.useCase = userObject.useCase;
                this.editProfileObject.isDeactivated = userObject.isDeactivated;

                if (userObject.useCase == undefined )
                    this.editProfileObject.selectedUseCase = ''
                else
                    this.editProfileObject.selectedUseCase = userObject.useCase;

                if (userObject?.accountOnboardingStatus === 'Completed') {
                    if (userObject.business) {
                        this.editProfileObject.business = userObject.business;
                    }
                    this.editProfileObject.connectedAccountId = userObject.connectedAccountId;
                }

                if (userObject.bio.length > 0)
                    this.editProfileObject.bio = userObject.bio[0];
                else
                    this.editProfileObject.bio = '';

                // console.log("this.editProfileObject.bio = ", this.editProfileObject.bio);

                this.editProfileObject.username = userObject.username;
                this.editProfileObject.updatedAt = userObject.updatedAt;
                this.editProfileObject.createdAt = userObject.createdAt;
                this.editProfileObject.passwordUpdatedAt = userObject.passwordUpdatedAt;
                this.editProfileObject.spinner = false;

                // WC 05132022
                if (userObject.isSubscriber) {
                    this.editProfileObject.isSubscriber = userObject.isSubscriber;
                    this.editProfileObject.payCustomerId = userObject.payCustomerId

                    this.urlService.getUserSubscriptionPlan().subscribe(
                        success => {
                            if (success.data && success.data.subscriptionPlanInfo && success.data._id) {
                                const subscription = success.data.subscriptionPlanInfo;
                                // console.log('getUserSubscriptionPlan - subscription = ', subscription);
    
                                this.editProfileObject._id = success.data._id;
                                this.editProfileObject.planName = subscription.name;
                                this.editProfileObject.premiumUnit = subscription.unit;
    
                                if (this.editProfileObject.premiumUnit === 'Yearly')
                                    this.editProfileObject.premium = 12 * subscription.price;
                                else
                                    this.editProfileObject.premium = subscription.price;
                            }

                            // get Stripe subscription object
                            if (success.data && success.data.payCustomerSubscriptionId) {
                                this.urlService.getPaySubscription(success.data.payCustomerSubscriptionId, null)
                                    .subscribe(success => {
                                        const subscription = success.data;
                                        // console.log('Stripe subscription = ', subscription);
                                        if (subscription) {
                                            this.editProfileObject.billing_cycle_anchor = this.convertUnixTimeStampToMMDDYYYY(subscription.billing_cycle_anchor);
                                            this.editProfileObject.cancel_at = this.convertUnixTimeStampToMMDDYYYY(subscription.cancel_at);
                                            this.editProfileObject.canceled_at = this.convertUnixTimeStampToMMDDYYYY(subscription.canceled_at);
                                            this.editProfileObject.current_period_start = this.convertUnixTimeStampToMMDDYYYY(subscription.current_period_start);
                                            this.editProfileObject.current_period_end = this.convertUnixTimeStampToMMDDYYYY(subscription.current_period_end);
                                            this.editProfileObject.default_payment_method = subscription.default_payment_method;
                                        }
                                    });
                            }

                            // console.log('this.editProfileObject = ', this.editProfileObject);
                        });

                } else {
                    this.editProfileObject.isSubscriber = false;
                    this.editProfileObject.payCustomerId = null;
                }
                // WC 05132022

                this.filledItem();
                this.editProfileObject.spinner = false;

                this.urlService.fetchCategories().subscribe(
                    success => {
                        this.editProfileObject.allCategories = success.data.categories;
                        this.editProfileObject.useCases = success.data.useCases;

                        for (const i of this.editProfileObject.allCategories) {
                            const temp: any = {}
                            temp.name = i;
                            temp.checked = false;
                            if (!!this.editProfileObject.categories) {
                                for (const j of this.editProfileObject.categories) {
                                    if (j == i) {
                                        temp.checked = true;
                                    }
                                }
                                this.editProfileObject.showingObj.push(temp)
                                this.editProfileObject.checkedList = JSON.parse(JSON.stringify(this.editProfileObject.categories));
                            } else {

                                this.editProfileObject.showingObj.push({name: i, checked: false})

                            }
                        }
                    },
                    error => {
                    })
                this.getCommunitySubscription();

            },
            error => {
                this.editProfileObject.spinner = false;
            }
        )
    }
    ngAfterViewChecked() {

        // We have a successful payment method submission
        // We have a successful attaching payment method to the customer and setting it as the default payment for future invoices
        // console.log("this.isPaymentStatusAvail = ", this.isPaymentStatusAvail);
        // console.log("this.isCustomerPaymentUpdateStatusAvail = ", this.isCustomerPaymentUpdateStatusAvail);
        // console.log("isPaymentMethodSetUpSuccessful = ", this.isPaymentMethodSetUpSuccessful);
        // console.log("isCustomerPaymentUpdateSuccessful = ", this.isCustomerPaymentUpdateSuccessful);

        if (this.isPaymentStatusAvail && this.isCustomerPaymentUpdateStatusAvail) {

            if (!this.isPaymentUpdateStatusShown && this.document.getElementById('update-payment-status-Modal') != undefined) {

                $('#update-payment-status-Modal').modal('show');
                this.isPaymentUpdateStatusShown = true;

            }
        };

        if (this.action != '') {
            if (!this.isActionShown && this.document.getElementById(this.action) != null) {
                const button = this.document.getElementById(this.action);
                button.click();
                this.isActionShown = true;
            }
        }

    }

    getCommunitySubscription() {
        this.allCommunityPlan = [];
        this.urlService.getCommunitySubscriptionPlan().subscribe((res) => {

            // console.log('allCommunityPlan - res.data = ', res.data);

            if (res.data && res.data.length) {
                // this.allCommunityPlan = res.data;
                for (const datum of res.data) {

                    if (datum.payCustomerSubscriptionId !== 'ZeroAmtSubscription') {
                        if (datum.subscriptionPlanInfo.userId) {    //seller

                            this.urlService.getPaySubscription(datum.payCustomerSubscriptionId, datum.sellerAccountId)
                                .subscribe(success => {
                                    const subscription = success.data;
                                    console.log('paySubscription - subscription = ', subscription);
                                    if (subscription) {
                                        this.allCommunityPlan.push({
                                            _id: datum._id,  // subscriptionPlan document id
                                            groupId: datum.subscriptionPlanInfo.groupId,
                                            sellerAccountId: datum.sellerAccountId,
                                            payCustomerId: datum.payCustomerId,
                                            payCustomerSubscriptionId: datum.payCustomerSubscriptionId,
                                            planName: datum?.subscriptionPlanInfo?.name,
                                            businessName: datum?.subscriptionGroupAdminInfo?.business?.name,
                                            premiumUnit: datum?.subscriptionPlanInfo?.unit,
                                            premium: datum?.subscriptionPlanInfo?.price || 0,
                                            billing_cycle_anchor: this.convertUnixTimeStampToMMDDYYYY(subscription.billing_cycle_anchor),
                                            cancel_at: this.convertUnixTimeStampToMMDDYYYY(subscription.cancel_at),
                                            canceled_at: this.convertUnixTimeStampToMMDDYYYY(subscription.canceled_at),
                                            current_period_start: this.convertUnixTimeStampToMMDDYYYY(subscription.current_period_start),
                                            current_period_end: this.convertUnixTimeStampToMMDDYYYY(subscription.current_period_end),
                                            default_payment_method: subscription.default_payment_method,
                                        });

                                        
                                    }
                                });

                                
                            }
                        } else {
                            // console.log('ZeroAmtSubscription - datum = ', datum);
                            
                            this.allCommunityPlan.push({
                            _id: datum._id,
                            groupId: datum.subscriptionPlanInfo.groupId,
                            sellerAccountId: datum.sellerAccountId,
                            payCustomerId: datum.payCustomerId,
                            payCustomerSubscriptionId: datum.payCustomerSubscriptionId,
                            planName: datum?.subscriptionPlanInfo?.name,
                            businessName: datum?.subscriptionGroupAdminInfo?.business?.name,
                            premiumUnit: datum?.subscriptionPlanInfo?.unit,
                            premium: datum?.subscriptionPlanInfo?.price || 0,
                            billing_cycle_anchor: 'None',
                            cancel_at: this.convertUnixTimeStampToMMDDYYYY(datum?.subscriptionPlanInfo?.payCustomerCancelDate),
                            canceled_at: this.convertUnixTimeStampToMMDDYYYY(datum?.subscriptionPlanInfo?.payCustomerCancelDate),
                            current_period_start: this.convertUnixTimeStampToMMDDYYYY(datum?.startDate),
                            current_period_end: this.convertUnixTimeStampToMMDDYYYY(datum?.subscriptionPlanInfo?.payCustomerCancelDate),
                            default_payment_method: 'None',
                        });
                    }
                }

                // console.log('this.allCommunityPlan = ', this.allCommunityPlan);
            }
        });
    }


    filledItem() {
        this.editProfileObject.show = 'first';
        setTimeout(() => {
            this.filledLogin('user_firstName');
            this.filledLogin('user_lastName');
            this.filledLogin('user_userName');
            // this.filledLogin('user_password');
            this.filledLogin('user_bio');

        })
    }

    filledLogin(id) {
        $('#' + id).siblings('.input-field').addClass('video_input_focus');
        $('#' + id).addClass('focusIn');
    }

    changeUserLogin(id) {
        if ($('#' + id).val() != '') {
            $('#' + id).siblings('.input-field').addClass('video_input_focus');
            $('#' + id).addClass('focusIn');
        } else {
            $('#' + id).siblings('.input-field').removeClass('video_input_focus');
        }
    }


    updateProfilePic() {
        this.editProfileObject.spinner = true;
        const formData = new FormData();
        formData.append('file', this.editProfileObject.fileData);

        this.urlService.updateProfilePic(formData).subscribe(
            success => {
                this.dashboardService.onGetUserData();
                this.toastr.success('Image updated successfully');
                this.editProfileObject.spinner = false;

                 if (this.imageURL) {
                    $('#imageGenerateModal').modal('hide');
                    this.spinner = false;
                    this.imageURL = '';
                }
            }
        )
    }

    async preview(files) {
        if (files.length === 0)
            return;

        const mimeType = files[0].type;
        if (!(mimeType === 'image/jpeg' || mimeType === 'image/jpg' || mimeType === 'image/png' || mimeType === 'image/gif')) {
            this.toastr.error('Only jpeg and png files allowed');
            return;
        }

        if (files[0].size > 10485760) {
            this.toastr.error('File size should be less than 10MB');
            return;
        }

        const reader = new FileReader();
        // this.editProfileObject.imagePath = files;
        const cleanImage = await sanitizeSVG(files[0])
        if (!cleanImage) {
            this.toastr.error('invalid SVG file');
            return;
        }
        this.editProfileObject.fileData = cleanImage
        reader.readAsDataURL(files[0]);
        reader.onload = (_event) => {
            this.editProfileObject.profilePic = reader.result;
        }
        this.updateProfilePic();

    }

    updateDetails() {
        this.editProfileObject.spinner = true;
        const obj: any = {};
        obj.bio = [this.editProfileObject.bio];
        // obj.category": ["Jwplayer","Videojs","videogular"],
        obj.useCase = this.editProfileObject.selectedUseCase;
        obj.category = this.editProfileObject.categories;
        obj.username = this.editProfileObject.username
        obj.firstname = this.editProfileObject.firstname
        obj.lastname = this.editProfileObject.lastname
        obj.email = this.editProfileObject.email

        this.urlService.updateProfileProfile(obj).subscribe(
            success => {
                this.dashboardService.onGetUserData();
                this.getUserData();
                this.toastr.success('Updated successfully');
                this.editProfileObject.spinner = false;
            },
            error => {
                this.editProfileObject.spinner = false;
            }
        )
    }

    updateBusinessDetails() {
        this.editProfileObject.spinner = true;
        const obj = {business: this.editProfileObject.business};
        this.urlService.updateProfileProfile(obj).subscribe(
            success => {
                this.toastr.success('Updated successfully');
                this.editProfileObject.spinner = false;
            }
        )
    }

    checkChange(e: any, obj: any) {
        if (e.target.checked) {
            this.editProfileObject.checkedList.push(obj.name);
        } else {
            for (let i = this.editProfileObject.allCategories.length; i > -1; i--) {
                if (this.editProfileObject.checkedList[i] == obj.name) {
                    this.editProfileObject.checkedList.splice(i, 1);
                }
            }
        }
    }

    // Update privacy settings
    updatePrivacySettings(e: any, obj: any) {
        const flag: any = e.target;
        const finalObj = {
            scenario: e.target.name,
            flag: flag.checked
        }
        // console.log('final obj: ', finalObj);
        this.urlService.updatePrivacySetting(finalObj).subscribe(success => {
            this.toastr.success('Settings Updated');
        }, error => {
            this.toastr.error('Error');
        });
    }

    // Update notification settings
    updateNotificationSettings(e: any, obj: any) {
        const flag: any = e.target;
        const finalObj = {
            scenario: e.target.name,
            flag: flag.checked
        }
        // console.log('final obj: ', finalObj);
        this.urlService.updateNotificationSettings(finalObj).subscribe(success => {
            this.toastr.success('Settings Updated');
        }, error => {
            this.toastr.error('Error');
        });
    }


    updateCategories() {
        // console.log(this.editProfileObject.checkedList, this.editProfileObject.checkedList.length);
        const totalCategories = this.editProfileObject.checkedList.length;
        if (totalCategories < 1) {
            this.toastr.error('Please select at least one category');
        } else {
            this.editProfileObject.categories = JSON.parse(JSON.stringify(this.editProfileObject.checkedList));
            this.updateDetails();
            $('#update-cat-Modal').modal('hide');
        }
        // this.updateDetails();
    }

    updateUseCase() {
        if (this.editProfileObject.selectedUseCase == '')
            this.toastr.error('Please select a use case');
        else {
            this.updateDetails();
            $('#update-usecase-Modal').modal('hide');
            // window.location.reload();
        };
    }

    updateEmail() {
        // console.log(this.editProfileObject.email);
        const oldEmail = this.editProfileObject.email
        const finalObj: any = {
            email: oldEmail,
            type: 'updateEmail',
            newEmail: this.editProfileObject.email
        };
        // console.log(finalObj)

        this.urlService.updateProfileProfile(finalObj).subscribe(
            success => {
                this.router.navigate(['/otp']);
                this.localStorageService.setItem('email', this.editProfileObject.email)
                this.editProfileObject.spinner = false;
            },
            error => {
                this.editProfileObject.spinner = false;
            }
        )
        $('#edit-email-Modal').modal('hide');
    }

    updatePassword() {
        this.editProfileObject.spinner = true;
        const obj: any = {};
        obj.oldPassword = this.oldPassword;
        obj.newPassword = this.newPassword;
        this.urlService.updatePassword(obj).subscribe(success => {
            this.toastr.success('Password updated successfully');
            this.editProfileObject.spinner = false;

        }, error => {
            // this.toastr.error('Error while updating password');
            this.editProfileObject.spinner = false;
        });
        $('#edit-password-Modal2').modal('hide');
        // obj.password = this.editProfileObject.password;
        // this.urlService.updateProfilePassword(obj).subscribe(
        //   success => {
        //     this.toastr.success('Password updates successfully');
        //     this.editProfileObject.spinner = false;
        //   }
        // )
    }

    toggleOldPasswordTextType() {
        this.fieldOldPassTextType = !this.fieldOldPassTextType;
    }

    toggleNewPasswordTextType() {
        this.fieldNewPassTextType = !this.fieldNewPassTextType;
    }

    toggleCNewPasswordTextType() {
        this.fieldCNewPassTextType = !this.fieldCNewPassTextType;
    }

    changePassword() {
        this.localStorageService.removeItem('token');
        this.router.navigate(['/forgotPassword']);

    }

    openVerifyOtpModal(){
        this.otp = "";
        this.spinner = true;
        this.urlService.sendOtpToEmail().subscribe((response:any) => {
            this.toastr.success(response.data.msg);
            $('#deact-acc-Modal').modal('hide');
            $('#verifyOtpForDeactiveAccount').modal('show');
            this.spinner = false;
        });
    }
    
    openVerifyOtpModalForDeleteAccount(){
        this.otp = "";
        this.spinner = true;
        this.urlService.sendOtpToEmail().subscribe((response:any) => {
            this.toastr.success(response.data.msg);
            $('#del-acc-Modal').modal('hide');
            $('#verifyOtpForDeleteAccount').modal('show');
            this.spinner = false;
            
        });
    }

    deactivateAccount() {
        this.editProfileObject.spinner = true;

        if(!this.otp){
            this.toastr.error("OTP is required!");
            this.editProfileObject.spinner = false;
            return;
        }
        const requestObj: any = {
            requestType: this.editProfileObject.isDeactivated ? 'Activate' : 'Deactivate',
            otp: this.otp
        };
        this.urlService.deactivateAccount(requestObj).subscribe(
            success => {
                this.localStorageService.removeItem('token')
                $('#verifyOtpForDeactiveAccount').modal('hide');
                this.toastr.success(requestObj.requestType === 'Deactivate' ? 'Account Deactivated' : 'Account Activated');
                this.router.navigate(['login']);
                this.editProfileObject.spinner = false;
            }, error => {
                this.editProfileObject.spinner = false;
                if(error.error.data.error){

                    this.toastr.error(error.error?.data?.msg);
                }else{
                    this.toastr.error('Error occured during deactivation');
                }
            }
        );
    }

    deleteAccount() {
        // let deleteAccount: any = confirm('Are you sure you want to delete account?');
        this.editProfileObject.spinner = true;

        if(!this.otp){
            this.toastr.error("OTP is required!");
            this.editProfileObject.spinner = false;
            return;
        }

        this.urlService.deleteAccount({otp: this.otp}).subscribe(
            success => {
                this.toastr.success('Account deleted successfully!');
                this.localStorageService.clear();
                this.router.navigate(['login']);
                this.editProfileObject.spinner = false;
            },
            error => {
                this.toastr.error(error.error.data.msg)
                this.editProfileObject.spinner = false;
            }
        );
    }

    cancelSubscriptionPayload(subscriptionId: any, sellerAccountId: any)
    {
        if (sellerAccountId != undefined || sellerAccountId != null) {
            const payload = {
                cancelReason: 'user initiated',
                subscriptionId,
                sellerAccountId
            };

            return payload;

        } else {
            const payload = {
                cancelReason: 'user initiated',
                subscriptionId
            };

            return payload;
        }
    };

    cancelSubscriptionModal(_id: any) {
        this.cancelCommunitySubscriptionId = _id;
        $('#cancel-community-subscription-Modal').modal('show');
    }

    // WC 06242022
    cancelSubscription(community = false) {
        //console.log("in cancelSubscription");

        $('#cancel-subscription-Modal').modal('hide');

        this.editProfileObject.spinner = true;

        if (community && this.cancelCommunitySubscriptionId) {
            this.editProfileObject = this.allCommunityPlan.find(x => x._id === this.cancelCommunitySubscriptionId);
        };

        //console.log("cancelSubscription -  this.editProfileObject = ",  this.editProfileObject);

        //WC 10242024 --- Non Freemium
       // if (this.editProfileObject.payCustomerSubscriptionId !== 'ZeroAmtSubscription') {

        const payload = this.cancelSubscriptionPayload(this.editProfileObject._id, this.editProfileObject?.sellerAccountId);

        this.urlService.cancelUserSubscriptionPlan(payload).subscribe(success => {
            
            //WC 10242024 --- if the subscription plan is FreeFuse plan, assign Freemium to the user
            this.toastr.success('Subscription plan cancelled successfully!');

            //this.localStorageService.setItem('isSubscriber', 'false');
            if (!community) {
                //console.log("in cancelSubscription --- FreeFuse plan");

                //WC 10242024 --- assign Freemium plan
                const payload = {
                    "planId" : "6310da4c16d7473718a9ce79"
                };

                this.urlService.setUserSubscriptionPlan(payload).subscribe(success => {
                    //console.log("Assigning Freemium - success = ", success);

                    this.editProfileObject.spinner = false;

                    window.location.reload();
                });
            } else {
                this.removeSubscriber(this.editProfileObject.groupId);

                this.editProfileObject.spinner = false;
            }
            //WC 10242024
               
        
                //WC 10242024
                // if (community) {
                //     this.removeSubscriber(this.editProfileObject.groupId);
                // } else {
                //     window.location.reload();
                // }
            });
        // } else {
        //     //Freemium
        //     console.log("cancelSubscription --- Fremium");
        //     if (community) {


        //         this.removeSubscriber(this.editProfileObject.groupId);
        //     }
        // }
    }

    removeSubscriber(groupId: any) {
        this.urlService.leaveGroup(groupId).subscribe(res => {
            this.toastr.success('Subscription plan cancelled successfully!');
            window.location.reload();
        });
    }

    // WC 06242022

    convertUnixTimeStampToMMDDYYYY = (unixTimeStamp: any) => {
        let date = unixTimeStamp;
        if (unixTimeStamp) {
            date = new Date(unixTimeStamp * 1000).toLocaleDateString('en-US');
        }
        return date;
    };

    createPaymentIntentPayload = (currentObj: any) => {

        if (currentObj.sellerAccountId != undefined || currentObj.sellerAccountId != null) {
            const payload = {
                payCustomerId: currentObj.payCustomerId,
                sellerAccountId: currentObj.sellerAccountId
            };

            return payload;
        } else {
            const payload = {
                payCustomerId: currentObj.payCustomerId,
            };

            return payload;
        };
    }

    // WC 07212022
    initializePayment(_id = null) {

        this.spinner = true;
        let currentObj;
        if (_id) {
            currentObj = this.allCommunityPlan.find(x => x._id === _id);
        } else {
            currentObj = this.editProfileObject;
        }

        // console.log('this.currentObj = ', currentObj);

        this.urlService.getPaymentMethod(currentObj.default_payment_method, currentObj?.sellerAccountId).subscribe(success => {

            // console.log('getPaymentMethod response = ', success);
            if (success.data) {
                const response = success.data;

                // To be used for detaching this payment when the new payment is attached successfully
                window.sessionStorage.setItem('currentPaymentMethodId', response.id);

                // console.log('getCustomerPaymentMethods - response = ', response);

                const brand = response.card.brand.charAt(0).toUpperCase() + response.card.brand.slice(1);

                this.currentPaymentMethod = {
                    card: brand,
                    exp_month: response.card.exp_month,
                    exp_year: response.card.exp_year,
                    last4: response.card.last4,
                    zip: response.billing_details.address.postal_code
                };

                // console.log("currentPaymentMethod = ", this.currentPaymentMethod);

                $('#update-payment-method-Modal').modal('show');
            }

        });

        // console.log('this.editProfileObject.payCustomerId = ', currentObj.payCustomerId);

        // Save it for retrieval when updating the subscription with the new payment method after receiving Stripe's update
        this.localStorageService.setItem('updatePayment_payCustomerId', currentObj.payCustomerId);

        const payload = this.createPaymentIntentPayload(currentObj)

        this.urlService.createPaymentSetUpIntents(payload).subscribe(success => {

            // console.log("success.data = ", success.data);
            if (success.data) {
                this.clientSecret = success.data.client_secret;

                const clientSecret = success.data.client_secret;

                // console.log("clientSecret = ", this.clientSecret);

                const appearance = {
                    theme: 'stripe',
                    variables: {
                        fontFamily: 'Roboto, "Helvetica Neue", sans-serif',
                    }
                };

                if (currentObj.sellerAccountId != undefined || currentObj.sellerAccountId != null)
                    this.stripe = Stripe(environment.Stripe_PK, {stripeAccount: currentObj.sellerAccountId});
                else
                    this.stripe = Stripe(environment.Stripe_PK);

                // Save it for retrieval when updating the subscription with the new payment method after receiving Stripe's update
                this.localStorageService.setItem('updatePayment_sellerAccountId', currentObj.sellerAccountId);

                // Save it for retrieval when updating the subscription with the new payment method after receiving Stripe's update
                this.localStorageService.setItem('updatePayment_subscriptionId', currentObj._id);

                this.elements = this.stripe.elements({appearance, clientSecret});

                // Create and mount the payment element
                const paymentElement = this.elements.create('payment');

                paymentElement.mount('#payment-element');
            }

            this.spinner = false;
        })

    };

    updatePayment() {
        const submitUpdatePaymentButton = document.getElementById('submit');
        submitUpdatePaymentButton.click();
    }

    onSubmitPaymentUpdate() {
        this.handleSubmitPaymentUpdate(this.elements)
    }

    async handleSubmitPaymentUpdate(elements) {

        // console.log('handleSubmitPaymentUpdate');

        this.spinner = true;
        this.setLoading(true, document);

        const result = await this.stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: environment.Update_Payment_Redirect
            },
        });

        // console.log("stripe.setupIntents.confirm result = ", result);

        // Inform the customer that there was an error.
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (result && (result.error.type === 'card_error' || result.error.type === 'validation_error' || result.error.type === 'invalid_request_error')) {
            this.showMessage(result.error.message, document);
            this.setLoading(false, document);
            this.spinner = false;
        } else {
            this.showMessage('An unexpected error occured.', document);
            this.setLoading(false, document);
            this.spinner = false;
        }
    }

    // ------- UI helpers -------

    showMessage(messageText: any, document: any) {
        const messageContainer = document.getElementById('payment-message');

        messageContainer.classList.remove('hidden');
        messageContainer.textContent = messageText;

        setTimeout(function () {
            messageContainer.classList.add('hidden');
            messageContainer.textContent = '';
        }, 4000);
    }

    // Show a spinner on payment submission
    setLoading(isLoading: boolean, document: any) {

        if (isLoading) {
            // Disable the button and show a spinner
            document.getElementById('submit').disabled = true;
            document.getElementById('spinner').classList.remove('hidden');
            document.getElementById('button-text').classList.add('hidden');
        } else {
            document.getElementById('submit').disabled = false;
            document.getElementById('spinner').classList.add('hidden');
            document.getElementById('button-text').classList.remove('hidden');
        }
    };

    async updateSubscriptionPaymentMethod() {

        const sellerAccountId = this.localStorageService.getItem('updatePayment_sellerAccountId');

        if (this.localStorageService.getItem('updatePayment_sellerAccountId') != undefined || this.localStorageService.getItem('updatePayment_sellerAccountId') != null)
            this.stripe = Stripe(environment.Stripe_PK, {stripeAccount: sellerAccountId});
        else
            this.stripe = Stripe(environment.Stripe_PK);

        const setupIntent = await this.stripe.retrieveSetupIntent(this.clientSecret);
        // console.log('updateSubscriptionPaymentMethod - setupIntent = ', setupIntent);

        this.urlService.userDetailsV2().subscribe(success => {

            // console.log('userDetails - success.data = ', success.data);
            const email = success.data.details.email;

            // const payCustomerId = success.data.details.payCustomerId;  // Stripe customer Id
            const payCustomerId = this.localStorageService.getItem('updatePayment_payCustomerId');
            // console.log('updateSubscriptionPaymentMethod - payCustomerId = ', payCustomerId);

            // Get customer payment methods
            const paymentMethodId = setupIntent.setupIntent.payment_method;  // Stripe payment method Id
            // console.log('updateSubscriptionPaymentMethod - New payment method, Stripe paymentMethodId = ', paymentMethodId);

            // Get the subscription id
            const subscriptionPlanId = this.localStorageService.getItem('updatePayment_subscriptionId');

            this.urlService.getUserSubscriptionPlanById(subscriptionPlanId).subscribe(success => {
                // console.log('updateSubscriptionPaymentMethod - subscriptionPlan = ' + success.data);

                const payload = {
                    payCustomerId,
                    paymentMethodId,   // new payment method id
                    subscriptionId: success.data.payCustomerSubscriptionId,
                    email,
                    planName: success.data.subscriptionPlanInfo.name,
                    sellerAccountId
                }

                // Attach the new payment method to a customer and make the new payment method as the default payment method for future invoices
                this.urlService.updateSubscriptionPaymentMethod(payload).subscribe(success => {

                        // console.log('updateCustomerPaymentMethod - success.data = ', success.data);
                    this.isCustomerPaymentUpdateSuccessful = true;
                    this.isCustomerPaymentUpdateStatusAvail = true;

                    // Detach the old payment method
                    const detachPayload = {
                        paymentMethodId: window.sessionStorage.getItem('currentPaymentMethodId'),
                        sellerAccountId
                    };

                        // console.log('updateSubscriptionPaymentMethod - detachPayload = ', detachPayload);

                    this.urlService.deletePaymentMethod(detachPayload).subscribe(success => {
                            this.isCustomerPaymentUpdateSuccessful = true;
                            this.isCustomerPaymentUpdateStatusAvail = true;

                            // console.log('updateSubscriptionPaymentMethod - delete Old PaymentMethod - success.data = ', success.data);
                            window.sessionStorage.removeItem('currentPaymentMethodId');
                            window.sessionStorage.removeItem('updatePayment_sellerAccountId');
                            window.sessionStorage.removeItem('updatePayment_subscriptionId');

                            this.editProfileObject.spinner = false;
                        },
                        err => {
                            // console.log('updateSubscriptionPaymentMethod - delete Old PaymentMethod - error: ' + err.message)

                            this.editProfileObject.spinner = false;
                        },)

                    },
                    err => {
                        // console.log('updateSubscriptionPaymentMethod - error: ' + err.message)
                        this.isCustomerPaymentUpdateSuccessful = false;
                        this.isCustomerPaymentUpdateStatusAvail = true;
                        this.editProfileObject.spinner = false;
                    },)
            });
        });
    };

    reloadPage() {

        $('#update-payment-status-Modal').modal('hide');

        // Reload so the page is initialize and payment infos are reflected correctly
        window.location.reload();
    };

    upgradeSubscription(subscriptionId: string) {

        this.router.navigate(['/pricing'], {queryParams: {subId: this.editProfileObject._id}});

    }

     openImageGenerateModal() {
        this.userPrompt = '';
        this.imageURL = '';
        $('#imageGenerateModal').modal('show');
    }

    sendPrompt() {
        this.spinner = true;
        this.urlService.createImage({userPrompt: this.userPrompt}).subscribe(success => {
            this.spinner = false;
            this.imageURL = success.data;
        },
            (error) => {
           console.log(error);
        })
    }

    async createFileFromImageURL(): Promise<File> {
        const response = await fetch(this.imageURL);
        const blob = await response.blob();
        const file = new File([blob], 'generated_profile_image.png', { type: blob.type });
        return file;
    }

    async uploadImage() {
        this.spinner = true;
        const file = await this.createFileFromImageURL();
        this.preview([file]);
    }
}


