import {Directive, ElementRef, Renderer2} from '@angular/core';

declare var $;


@Directive({
    selector: '[imgName]'
})
export class imgName {


    constructor(el: ElementRef, renderer: Renderer2) {

        setTimeout(() => {
            let fName = el.nativeElement.getAttribute('title');
            fName = fName.split(',');
            const lName = fName[1];
            fName = fName[0];
            let fullName = fName.charAt(0);
            if (lName) {
                fullName += lName.charAt(0)
            } else {
                fullName += fName.charAt(1)
            }
            el.nativeElement.innerHTML = fullName
        })

    }
}