// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.FF-scroll-modify {
    max-height: 400px;
    height: auto;
    overflow-x: hidden;
    overflow-y: scroll;
}

.tab-box-video {
    width: 100%;
    margin-top: 20px;
}

.tab-box-video ul {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.tab-box-video ul li {
    list-style: none;
    font-weight: 500;
    font-size: 18px;
    padding: 5px 0;
    margin: 0px 25px;
    color: #878395;
    cursor: pointer;
}

.tab-box-video ul li.active {
    border-top: 3px solid rgba(108, 47, 187, 1);
    color: rgba(108, 47, 187, 1);
}

.img-box {
    height: 240px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    background: #f8f9fa;
    width: 100%;
    text-align: center
}

.img-box img {
    object-fit: cover;
    height: 100%;
}

@media only screen and (max-width: 575px) and (min-width: 320px) {
    .mat-dialog-content {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
    }
}
`, "",{"version":3,"sources":["webpack://./src/app/layout/modals/online-videos/online-videos.component.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,cAAc;IACd,eAAe;AACnB;;AAEA;IACI,2CAA2C;IAC3C,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;IACX;AACJ;;AAEA;IACI,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI;QACI,UAAU;QACV,SAAS;QACT,aAAa;QACb,uBAAuB;IAC3B;AACJ","sourcesContent":["\n.FF-scroll-modify {\n    max-height: 400px;\n    height: auto;\n    overflow-x: hidden;\n    overflow-y: scroll;\n}\n\n.tab-box-video {\n    width: 100%;\n    margin-top: 20px;\n}\n\n.tab-box-video ul {\n    display: flex;\n    width: 100%;\n    align-items: center;\n    justify-content: center;\n}\n\n.tab-box-video ul li {\n    list-style: none;\n    font-weight: 500;\n    font-size: 18px;\n    padding: 5px 0;\n    margin: 0px 25px;\n    color: #878395;\n    cursor: pointer;\n}\n\n.tab-box-video ul li.active {\n    border-top: 3px solid rgba(108, 47, 187, 1);\n    color: rgba(108, 47, 187, 1);\n}\n\n.img-box {\n    height: 240px;\n    border-radius: 12px;\n    overflow: hidden;\n    position: relative;\n    background: #f8f9fa;\n    width: 100%;\n    text-align: center\n}\n\n.img-box img {\n    object-fit: cover;\n    height: 100%;\n}\n\n@media only screen and (max-width: 575px) and (min-width: 320px) {\n    .mat-dialog-content {\n        padding: 0;\n        margin: 0;\n        display: flex;\n        justify-content: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
