import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, merge, Observable, of, Subject, throwError} from 'rxjs';
import {catchError, last, map, tap, timeout} from 'rxjs/operators';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {LocalStorageService} from './localStorage.service';
import { S3 } from 'aws-sdk';

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  progressPercentage: any = 0
  //apiUrl = environment.API_URL;
  apiUrlv2 = environment.API_URLV2;
  smartEditorAPIUrl = environment.SmartEditor_API_URL;
  api = environment
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  anonymousToken: string;

  private eventOccurredSubject = new Subject<boolean>();
  eventOccurred$ = this.eventOccurredSubject.asObservable();

  private aiFreeBoardSubject = new Subject<string>();
  aiFreeBoardSubjectObservable$ = this.aiFreeBoardSubject.asObservable();

  _notifications = new BehaviorSubject(true);
  notifications = this._notifications.asObservable();

  public HubSpotsubject$ = new BehaviorSubject<string>('');


  private s3: S3;
  constructor(public http: HttpClient,private localStorageService:LocalStorageService) {
    // this.s3 = new S3({
    //   accessKeyId: environment.AWS_ACCESS_KEY,
    //   secretAccessKey: environment.AWS_SECRET_ACCESS_KEY,
    //   useAccelerateEndpoint: true
    // });
  }

  setEventOccurred(value: boolean) {
    this.eventOccurredSubject.next(value);
  }

  aiFreeBoardClicked(value: string) {
    this.aiFreeBoardSubject.next(value);
  }

  signup(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/register`;
    return this.http.post(API_URL, data);
  }

  //WC 10032023 --- PenTestFix to move to V2 and retire V1
  regenrateOTP(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/generateotp`;
    return this.http.post(API_URL, data);
  }

  verifyOTP(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/verifyotp`;
    return this.http.post(API_URL, data);
  }

  login(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/auth/local`;
    return this.http.post(API_URL, data);
  }

  delegateAccess(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/delegateAccess`;
    return this.http.post(API_URL, data);
  }

  isAdmin(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/isAdmin`;
    return this.http.get(API_URL);
  }

  logout(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/auth/logout`;
    return this.http.post(API_URL, data);
  }

  loginDetailsV2(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/logindetails`;
    return this.http.post(API_URL, data);
  }

  userCategoryWiseVideosV2(params: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getVideosByCategory`;
    return this.http.get(API_URL, {params});
  }

  getVideosByCategoryCount(params: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getVideosByCategoryCount`;
    return this.http.get(API_URL, {params});
  }

  getLinkPreview(url: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getLinkPreview`;
    return this.http.post(API_URL, url);
  }

  uploadLinkPreview(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/uploadWebsiteURL`;
    return this.http.post(API_URL, data);
  }

  uploadWebsiteURLThumbnail(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/uploadWebsiteURLThumbnail`;
    return this.http.post(API_URL, data);
  }

  userDetailsV2(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/me`;
    return this.http.get(API_URL);
  }

  completeProfile(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/profilePic`;
    return this.http.post(API_URL, data);
  }

  fetchCategories(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/categories`;
    return this.http.get(API_URL);
  }

  // get top videos
  getAllTopVideos(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/admin/displayTopVideos`;
    return this.http.get(API_URL);
  }

  forgotPassword(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/forgotPassword`;
    return this.http.post(API_URL, data);
  }

  //WC 10032023 --- PenTestFix to retire v1
  confirmToken(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/verify/reset-password?token=` + data;
    return this.http.get(API_URL);
  }

  resetPassword(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/ResetPassword`;
    return this.http.post(API_URL, data);
  }

  updateProfilePic(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/updateprofilePic`;
    return this.http.post(API_URL, data);
  }

  // update profile Details
  updateProfileProfile(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/updateprofiledetails`;
    return this.http.post(API_URL, data);
  }

  updateProfilePassword(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/updateprofilepassword`;
    return this.http.post(API_URL, data);
  }

  // Delete Account
  deleteAccount(data): Observable<any> {
  const API_URL = `${this.apiUrlv2}/users/deleteuser`;
  return this.http.post(API_URL, data);
}

  // Deactivate Account
  deactivateAccount(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/deactivate`;
    return this.http.post(API_URL, data);
  }

  // Send otp to the email
  sendOtpToEmail(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/sendOtpToEmail`;
    return this.http.post(API_URL, '');
  }

  // WC 04062022 - Updated to handle listing of AI Editor Videos
  // WC 03102022 - V2
  // Get uploaded videos  // Endpoint: /api/v2/users/videolist?limit=15&page=1&sort=recent&aiEditorVideo=yes
  getUploadedVideoV2(limit: number, page: number, sort: string, aiEditorVideo: string, type: string, userID?: string): Observable<any> {
    let API_URL = '';
    if (userID) {
      API_URL = `${this.apiUrlv2}/users/videolist?limit=${limit}&page=${page}&sort=${sort}&aiEditorVideo=${aiEditorVideo}&type=${type}&userID=${userID}`;
    } else {
      API_URL = `${this.apiUrlv2}/users/videolist?limit=${limit}&page=${page}&sort=${sort}&aiEditorVideo=${aiEditorVideo}&type=${type}`;
    }
    // console.log("API_URL: ", API_URL);
    return this.http.get(API_URL);
  }

  updateUploadedVideoName(payload) {
    const API_URL = `${this.apiUrlv2}/users/updateUploadedVideoName`;
    return this.http.post(API_URL, payload);
  }

  addVideoToFolder(data:any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/addVideoToFolder`;
    return this.http.put<any>(API_URL, data);
  }

  addFolder(name:any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/addFolder`;
    return this.http.post<any>(API_URL, name);
  }

  getFolder(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getFolder`;
    return this.http.get<any>(API_URL);
  }

  deleteFolder(folderId:any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/deleteFolder?folderId=${folderId}`;
    return this.http.delete<any>(API_URL);
  }

  convertImageToBase64(data:any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/convertImageToBase64`;
    return this.http.post<any>(API_URL, data);
  }

  convertExcelFileImageToBase64(data:any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/convertExcelFileImageToBase64`;
    return this.http.post<any>(API_URL, data);
  }

  removeVideosFromFolder(data:any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/removeVideosFromFolder`;
    return this.http.put<any>(API_URL, data);
  }

  getVideoListByFolderId(data: any): Observable<any> {
    let API_URL: any;
    if (data.search) {
      API_URL = `${this.apiUrlv2}/users/getVideoListByFolderId?folderId=${data.folderId}&limit=${data.limit}&page=${data.page}&sort=${data.sort}&search=${data.search}`;
    } else {
      API_URL = `${this.apiUrlv2}/users/getVideoListByFolderId?folderId=${data.folderId}&limit=${data.limit}&page=${data.page}&sort=${data.sort}`;
    }
    return this.http.get<any>(API_URL);
  }

  getVideoCountByFolderId(params): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getVideoCountByFolderId`;
    return this.http.get<any>(API_URL, {params});
  }

  getLibraryVideos(params): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/videolist`;
    return this.http.get<any>(API_URL, {params});
  }

  getLibraryVideosCount(params): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/videolistCount`;
    return this.http.get<any>(API_URL, {params});
  }

  // WC 03302022 - V2
  // Get uploaded videos count with or without search crtieria
  getUploadedVideoCount(search: any, aiEditorVideo: any): Observable<any> {
    let API_URL = '';

    if (aiEditorVideo === undefined || (aiEditorVideo.toLowerCase() === 'no')) {

      if (search === undefined || search === '' || search == null)
        API_URL = `${this.apiUrlv2}/users/videolistCount`;
      else
        API_URL = `${this.apiUrlv2}/users/videolistCount?search=${search}`;

    } else {

      if (search === undefined || search === '' || search == null)
        API_URL = `${this.apiUrlv2}/users/videolistCount?aiEditorVideo=yes`;
      else
        API_URL = `${this.apiUrlv2}/users/videolistCount?search=${search}&aiEditorVideo=yes`;

    }

    // console.log("API_URL: ", API_URL);

    return this.http.get(API_URL);
  }


  // WC 03302022 - V2
  // Search uploaded videos by name or originalName which contains the search word and sort by sort value
  searchVideoListByName(search: any, limit: any, page: any, sort: any, aiEditorVideo: any): Observable<any> {
    let API_URL = '';

    if (aiEditorVideo === undefined || (aiEditorVideo.toLowerCase() === 'no')) {
      API_URL = `${this.apiUrlv2}/users/searchVideoListByName?search=${search}&limit=${limit}&page=${page}&sort=${sort}`;
    } else {
      API_URL = `${this.apiUrlv2}/users/searchVideoListByName?search=${search}&limit=${limit}&page=${page}&sort=${sort}&aiEditorVideo=yes`;
    }

    // console.log("API_URL: ", API_URL);

    return this.http.get(API_URL) as Observable<any>;
  }

  // create presignedUrl
  createpresignedUrlV2(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/createpresignedurl`;
    return this.http.post(API_URL, data);
  }

  // Parse video for AI editor
  parseAiEditorVideo(videoData: FormData) {
    const API_URL = environment.AI_PARSE_URL;

    // console.log('parseAIEditorVideo - API_URL = ', API_URL);
    // console.log("parseAIEditorVideo - videoData = ", videoData);

    // WC 08292022
    return this.http.post(API_URL, videoData)
      .pipe(
          timeout(10000),    // 10 secs
          catchError(e => {
            // console.log("Timeout error: ", e.message);
            throw e;
          })
      )
  }

  checkForMaxAIVideos(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/checkForMaxAIVideos`
    return this.http.get(API_URL);
  }

  parseAIEditorVideoV2(data): Observable<any> {
    const API_URL = `${environment.AI_PARSE_URL}/handleAIEditorRequest`;
    //console.log('parseAIEditorVideo - API_URL = ', API_URL);

    return this.http.post(API_URL, data);
  }
  
  async uploadFile(file, bucketName, key) {
    let uploadId;
    let paths = new URL(key).pathname.substr(1);
    const params = {
      Bucket: bucketName,
      Key: paths,
      //ContentType: file.type
    };

    try {
      const uploadData = await this.s3.createMultipartUpload(params).promise();
      uploadId = uploadData.UploadId;
      const chunkSize = 1 * 1024 * 1024 * 1024; // 1GB chunk size
      const numChunks = Math.ceil(file.size / chunkSize);

      const uploadPartPromises = [];
      let progressCount = 0;

      // Request presigned URLs for each part
      for (let i = 0; i < numChunks; i++) {
        const startByte = i * chunkSize;
        const endByte = Math.min((i + 1) * chunkSize, file.size);
        const partNumber = i + 1;

        // console.log("uploadFile - startBye = ", startByte);
        // console.log("uploadFile - endBye = ", endByte);
        // console.log("uploadFile - partNumber = ", partNumber);

        const partParams = {
          Bucket: bucketName,
          Key: paths,
          PartNumber: partNumber,
          UploadId: uploadId
        };

        const presignedUrl = await this.s3.getSignedUrlPromise('uploadPart', partParams);
        const chunk = file.slice(startByte, endByte);
        uploadPartPromises.push(this.uploadChunk(presignedUrl, chunk));

      }
      
      // Merge progress observables and subscribe to update progress bar
      merge(...uploadPartPromises).subscribe({
        next: progress => {
          progressCount++; // Increment progress count
          const percentDone = Math.round((progressCount * 100) / numChunks);
          this.showProgress(percentDone);
        },
        error: error => 
          console.error('Error uploading chunk:', error),
        complete: () => 
          console.log('All chunks uploaded successfully')
      });

      // Upload all parts
      const uploadResult = await Promise.all(uploadPartPromises);

      // console.log("uploadFile - uploadPartPromises = ", uploadPartPromises);
      // console.log("uploadFile - uploadResult = ", uploadResult);

      const parts = [];

      for (let i = 0; i < uploadResult.length; i++) {

        parts.push({
          ETag: JSON.parse(uploadResult[i]),
          PartNumber: i + 1
        })
      }

      // Finalize the multipart upload
      const completeParams = {
        Bucket: bucketName,
        Key: paths,
        MultipartUpload: {
          //Parts: [...Array(numChunks).keys()].map(i => ({ ETag: `"etag${i + 1}"`, PartNumber: i + 1 }))
          Parts: parts
        },
        UploadId: uploadId
      };

      const completeMultipartUploadResult = await this.s3.completeMultipartUpload(completeParams).promise();

      // console.log("uploadFile - completeMultipartUploadResult = ", completeMultipartUploadResult);

    } catch (error) {
        console.error('Error uploading file:', error);
        throw error;
    }
  }

  async uploadChunk(presignedUrl, chunk) {
    try {

      const response = await fetch(presignedUrl, {
        method: 'PUT',
        body: chunk
      });

      if (response.ok) {
        const eTag = response.headers.get('ETag');
        return eTag;
      } else {
        return null;
      };


    } catch (error) {
      console.error('Error uploading chunk:', error);
      throw error;
    }
  }

  // uploadChunk2(presignedUrl, chunk): Observable<any> {
  //   try {

  //     console.log("uploadChunk2 - presignedUrl = ", presignedUrl);

  //     return this.http.request('PUT', presignedUrl, {
  //       body: chunk, reportProgress: true, observe: 'events', headers: new HttpHeaders(
  //           {'ngsw-bypass': ''},
  //       )
  //     }).pipe(
  //         map(event => this.getEventMessage(event, chunk)),
  //         tap(message => this.showProgress(message)),
  //         last()
  //     );

  //   } catch (error) {
  //     console.error('Error uploading chunk:', error);
  //     throw error;
  //   }
  // }

  uploadChunk2(presignedUrl: string, chunk: any, presignedUrls:any[]): Promise<any> {
    // console.log("uploadChunk2 - presignedUrl = ", presignedUrl);
  
    return this.http.request('PUT', presignedUrl, {
        body: chunk,
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders({'ngsw-bypass': ''})
    }).pipe(
        map((event) => {
          if (event.type === HttpEventType.UploadProgress){
            let time = this.getEventMessage(event,chunk)        
            return time;
          }
  
          if (event.type === HttpEventType.Response){
            const etag = event.headers.get('ETag');
              return { etag, response: event };
          }
        }),
        tap(message => this.showProgress(message)),
        catchError(error => {
          console.error('Error in uploadChunk2:', error);
          return throwError('Upload Failed - Please try again!');
      }),
        last()
    ).toPromise();
    }

  // upload video to s3
  uploadVideo(data, file): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/octet-stream');
    // const headers = new HttpHeaders().set('Content-Type', 'application/pdf');
    // headers.set('Content-Type', 'application/octet-stream');
    const API_URL = data;
    const req = new HttpRequest('PUT', API_URL, file, {
      reportProgress: true,
      headers
    });

    // WC 05132022
    // Bypassing the service worker in prod to let the browser handle the request instead in order for the event message to show up
    // WC 05132022
    return this.http.request('PUT', API_URL, {
      body: file, reportProgress: true, observe: 'events', headers: new HttpHeaders(
          {'ngsw-bypass': '', 'Content-Type': 'application/octet-stream'},
      )
    }).pipe(
        map(event => this.getEventMessage(event, file)),
        tap(message => this.showProgress(message)),
        last()
    );
  }

  uploadFileToS3(formData): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/uploadTranscription`;
    return this.http.request('POST', API_URL, {body: formData})
  }

  //WC 06232023
    // upload audio to s3
    uploadAudio(data, file): Observable<any> {

      // console.log("Uploading audio to S3, data = " + data);
  
      const headers = new HttpHeaders().set('Content-Type', 'audio/mpeg');
      const API_URL = data;
      const req = new HttpRequest('PUT', API_URL, file, {
        reportProgress: true,
        headers
      });
  
      // WC 05132022
      // Bypassing the service worker in prod to let the browser handle the request instead in order for the event message to show up
      // WC 05132022
      return this.http.request('PUT', API_URL, {
        body: file, reportProgress: true, observe: 'events', headers: new HttpHeaders(
            {'ngsw-bypass': '', 'Content-Type': 'audio/mpeg'},
        )
      }).pipe(
          map(event => this.getEventMessage(event, file)),
          tap(message => this.showProgress(message)),
          last()
      );
    }

    // upload document to s3
    uploadDocument(data, file): Observable<any> {
  
      const headers = new HttpHeaders().set('Content-Type', 'application/pdf');
      const API_URL = data;
      const req = new HttpRequest('PUT', API_URL, file, {
        reportProgress: true,
        headers
      });
  
      // WC 05132022
      // Bypassing the service worker in prod to let the browser handle the request instead in order for the event message to show up
      // WC 05132022
      return this.http.request('PUT', API_URL, {
        body: file, reportProgress: true, observe: 'events', headers: new HttpHeaders(
            {'ngsw-bypass': '', 'Content-Type': 'application/pdf'},
        )
      }).pipe(
          map(event => this.getEventMessage(event, file)),
          tap(message => this.showProgress(message)),
          last()
      );
    }

  public getEventMessage(event: HttpEvent<any>, file: File) {
    // console.log("event ====> ", event);
    
    switch (event.type) {
      // case HttpEventType.Sent:
      //   return `Uploading file "${file.name}" of size ${file.size}.`;
      case HttpEventType.UploadProgress:
        // Compute and show the % done:
        const percentDone = Math.round(100 * event.loaded / event.total);
        // console.log("getEventMessage - Upload percentDone = ", percentDone);
        return percentDone;
    }
  }
  showProgress(s) {
    this.progressPercentage = s
  }

  getProgress() {
    if (this.progressPercentage === undefined) {
      return 0;
    } else {
      return this.progressPercentage
    }
  }

  cloneVideo(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/cloneVideo`
    return this.http.post(API_URL, data);
  }

  // Delete presigned Url
  deletePresignedURL(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/deletepresignedurl`
    return this.http.post(API_URL, data);
  }

  createInteractiveVideo(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/bookmarkvideos`
    return this.http.post(API_URL, data);
  }

  // insert Node between parent and child
  insertNode(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/insertNode`
    return this.http.post(API_URL, data);
  }

  // create Interactive Video
  createEmptyNodes(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/createEmptyNodes`
    return this.http.post(API_URL, data);
  }

  createNestedArrayNodes(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/createEmptyNodes?isReturnedNewNodeOnly=true`
    return this.http.post(API_URL, data);
  }

  getInteractiveVideo(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getallmainvideos`
    return this.http.get(API_URL);
  }

  getInteractiveVideoV2(userId: any, isPublished: any, limit: any, page: any, sort: any): Observable<any> {

    let API_URL = '';

    if (userId != null) {
      API_URL = `${this.apiUrlv2}/users/getallmainvideos?userID=${userId}&isPublished=${isPublished}&limit=${limit}&page=${page}&sort=${sort}`;
    } else {
      API_URL = `${this.apiUrlv2}/users/getallmainvideos?isPublished=${isPublished}&limit=${limit}&page=${page}&sort=${sort}`;
    }
    return this.http.get(API_URL);
  }

  getallmainvideos(params): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getallmainvideos`;
    return this.http.get(API_URL, {params});
  }

  updateInteractiveVideo(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/updatechildvideos`
    return this.http.post(API_URL, data);
  }

  setEndingNode(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/setEndingNode`
    return this.http.post(API_URL, data);
  }

  setConnectorNode(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/setConnectorNode`
    return this.http.post(API_URL, data);
  }

  htmlVideo_original(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/htmlVideo`
    return this.http.post(API_URL, data);
  }

  htmlVideo(data): Observable<any> {
    const API_URL = `${this.smartEditorAPIUrl}/htmlVideo`
    return this.http.post(API_URL, data);
  }

  trimVideo_original(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/trimVideo`
    return this.http.post(API_URL, data);
  }

  trimVideo(data): Observable<any> {
    const API_URL = `${this.smartEditorAPIUrl}/trimVideo`
    return this.http.post(API_URL, data);
  }

  stitchVideos_original(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/stitchVideos`
    return this.http.post(API_URL, data);
  }

  stitchVideos(data): Observable<any> {
    const API_URL = `${this.smartEditorAPIUrl}/stitchVideos`
    return this.http.post(API_URL, data);
  }

  getVideoStatus_original(renderId): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getVideoStatus/${renderId}`
    return this.http.get(API_URL);
  }

  getVideoStatus(renderId): Observable<any> {
    const API_URL = `${this.smartEditorAPIUrl}/getVideoStatus/${renderId}`
    return this.http.get(API_URL);
  }

  getOriginalUploadedVideoUrl(params): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getOriginalUploadedVideoUrl`
    return this.http.get(API_URL, {params});
  }

  // translate subtitle of video
  translateInteractiveVideo(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/translateVideo`
    return this.http.post(API_URL, data);
  }

  getChildVideosAll(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getbookmarkvideos?mainId=` + data
    return this.http.get(API_URL);
  }

  deleteBookMarkVideo(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/deletebookmarkvideos?videoId=` + data
    return this.http.delete(API_URL);
  }

  deleteChildVideo(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/deleteChildVideos`
    return this.http.post(API_URL, data);
  }

  uploadThumbnails(data): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/uploadMainvideotumb`
    return this.http.post(API_URL, data);
  }

  // Transcode video
  transcodeVideo(data: any): Observable<any> {
    const headers = new HttpHeaders().set('Access-Control-Allow-Methods', 'POST');
    const API_URL = `${this.apiUrlv2}/users/transcodevideo`;
    // console.log("transcodeVideo - data =", data);
    return this.http.post(API_URL, data);
  }

  // Check transcoding status
  checkTranscodingStatus(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/transcodevideostatus`;
    return this.http.post(API_URL, data);
  }

  // WC 08092022 --- Transcribe video
  transcribeVideo(data: any): Observable<any> {
    const headers = new HttpHeaders().set('Access-Control-Allow-Methods', 'POST');
    const API_URL = `${this.apiUrlv2}/users/transcribevideo`;
    return this.http.post(API_URL, data);
  }

  // Check transcribing status
  checkTranscribingStatus(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/transcribevideostatus`;
    return this.http.post(API_URL, data);
  }
  // WC 08092022

  commentAndReply(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/commentReply`;
    return this.http.post(API_URL, data);
  }

  likeAndUnlike(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/likeUnlike`;
    return this.http.post(API_URL, data);
  }

  getAllComments(videoId: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getallcomments?videoID=` + videoId;
    return this.http.get(API_URL);
  }

  getVideoLikesInfo(videoId: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getvideolikesinfo?videoID=` + videoId;
    return this.http.get(API_URL, videoId);
  }

  getCommentInfo(commentId: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getcommentinfo?videoID=` + commentId;
    return this.http.get(API_URL, commentId);
  }

  // Get comments info
  getVideoComments(params: { videoID: string, page: number, limit: number }): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getComment`;
    return this.http.get(API_URL, {params});
  }

  // Get user info
  getUserInfoV2(userId: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getuserinfo?userID=` + userId;
    return this.http.get(API_URL);
  }

  // Follow user
  followUser(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/followUnfollow`;
    return this.http.post(API_URL, data);
  }

  // 03042024 --- isFollowing
  isFollowing(followedUserId: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/isFollowing/${followedUserId}`;
    return this.http.get(API_URL);
  }

  publishunpublish(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/publish`;
    return this.http.post(API_URL, data);
  }

  updatePrivacySetting(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/updatePrivacySettings`;
    return this.http.post(API_URL, data);
  }

  updateNotificationSettings(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/updateNotificationsettings`;
    return this.http.post(API_URL, data);
  }

  shareVideo(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/share`;
    return this.http.post(API_URL, data);
  }

  getUserVideos(userId: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getVideoByUser?userID=` + userId;
    return this.http.get(API_URL, userId);
  }

  getVideoPermissions(videoId: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/publicVideoValidation?videoID=` + videoId;
    return this.http.get(API_URL, videoId);
  }

  // Get video views
  saveViewCount(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/viewCount`;
    return this.http.post(API_URL, data);
  }

  watchHistory(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/watchHistory`;
    return this.http.post(API_URL, data);
  }

  // WC 05132022
  // Get watch history
  getWatchHistoryV2(userID: any, limit: any, page: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getWatchHistory?userID=${userID}&limit=${limit}&page=${page}`;
    return this.http.get(API_URL);
  }

  // WC 05132022

  // Get watch history by user
  getWatchHistoryByUser(userID: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getWatchHistory?userID=${userID}&limit=5&page=1`
    return this.http.get(API_URL, userID);
  }

  setWatchHistory(data: any): Observable<any> {
    // const header = new HttpHeaders().set(
    //   'Authorization',
    //     this.localStorageService.getItem('token')
    // );
    const API_URL = `${this.apiUrlv2}/users/setwatchedDuration`;
    //return this.http.post(API_URL, data, { headers: header });
    return this.http.post(API_URL, data);
  }

  setWatchDuration(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/setwatchedDuration`;
    return this.http.post(API_URL, data);
  }

  getWatchedHistory(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getwatchedDuration?videoID=` + data;
    return this.http.get(API_URL);
  }

  // Get shared videos by user
  getUserShares(userID?: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getShareInfo?userID=` + userID;
    return this.http.get(API_URL);
  }

  getUserShareV2(userId: string, limit: number, page: number): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getShareInfo?userID=${userId}&limit=${limit}&page=${page}`;
    return this.http.get(API_URL);
  }

  // Get likes videos by user
  getLikedVideos(userID?: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getlikesinfobyuser?userID=` + userID;
    return this.http.get(API_URL);
  }

  // WC 05132022
  // Get likes videos by user
  getLikedVideosV2(limit: any, page:any, userID?: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getlikesinfobyuser?userID=${userID}&limit=${limit}&page=${page}`;
    return this.http.get(API_URL);
  }

  // WC 05132022

  // Get user activity
  getUserActivity(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/activityInfo`;
    return this.http.get(API_URL);
  }

  // Get user activity V2
  getUserActivityV2(limit: any, page: any, userID?: any): Observable<any> {
    let API_URL = `${this.apiUrlv2}/users/activityInfo?limit=${limit}&page=${page}`;
    if (userID) {
      API_URL = `${this.apiUrlv2}/users/activityInfo?userID=${userID}&limit=${limit}&page=${page}`;
    }
    return this.http.get(API_URL);
  }

  searchVideo(params: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/searchByvideoName`;
    return this.http.get(API_URL, {params});
  }

  simpleVideoSearch(term?: any, page?, sortValue?: any, limit = 10, searchType?:any, groupType?:any): Observable<any> {
    let API_URL;
    if(sortValue){
      API_URL = `${this.apiUrlv2}/users/searchByvideoName?word=${term}&limit=${limit}&skip=0&page=${page}&searchType=${searchType}&sortValue=${sortValue}&groupType=${groupType}`;
    }else{
      API_URL = `${this.apiUrlv2}/users/searchByvideoName?word=${term}&limit=${limit}&skip=0&page=${page}&searchType=${searchType}`;
    }
    return this.http.get(API_URL);
  }

  getSearchResultCount(term?: any, videoType?: any, groupType?: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getSearchResultCount?word=${term}&searchType=${videoType}&groupType=${groupType}`;
    return this.http.get(API_URL);
  }

  searchGroupNode(term: any, groupId: any, limit = 5): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/searchGroupNode?search=${term}&groupId=${groupId}&limit=${limit}&page=1`;
    return this.http.get(API_URL);
  }

  //WC 10032023 --- PenTestFixes to retire V1
  getvideoInfo(videoID?: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getvideoInfo?videoID=` + videoID;
    return this.http.get(API_URL);
  }

  getVideoInfov2(videoId?: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getvideoInfo?videoID=${videoId}`
    return this.http.get(API_URL);
  }

  getSingleUploadedVideo(videoId?: any, data?): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getUploadedVideo?videoID=${videoId}&isAIVideoCreator=${data.isAIVideoCreator}`
    return this.http.get(API_URL);
  }

  sendCollabRequest(reqData: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/sendCollabRequest`;
    return this.http.post(API_URL, reqData);
  }

  shareInternally(data?: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/share`;
    return this.http.post(API_URL, data);
  }

  // Google sign in
  googleLogin(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/auth/google`;
    return this.http.get(API_URL);
  }

  // Facebook sign in
  facebookLogin(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/auth/facebook`;
    return this.http.get(API_URL);
  }

  // Get notifications V2
  getNotificationsV2(type: any, limit: any, page: any): Observable<any> {
    // let queryParams: any = new HttpParams();
    const API_URL = `${this.apiUrlv2}/users/notification/${type}?limit=${limit}&page=${page}`;
    return this.http.get(API_URL);
  }

  // Get notifications
  getAllNotificationsV2(limit: any, page: any): Observable<any> {
    // let queryParams: any = new HttpParams();

    // WC 02092022 -- calling paginated and smaller response payload
    const API_URL = `${this.apiUrlv2}/users/notification/viewAll?limit=${limit}&page=${page}`;
    // console.log("crud.service.ts - getAllNotifications, API_URL = ", API_URL);

    // const API_URL = `${this.apiUrl}/users/notification/viewAll`;
    return this.http.get(API_URL);
  }


  // Change notification status
  postNotification(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/notification`;
    return this.http.post(API_URL, data);
  }

  allNotificationsSetToViewed(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/allNotificationsSetToViewed`;
    return this.http.post(API_URL, data);
  }

  setContributor(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/setcontributor`;
    return this.http.post(API_URL, data);
  }

  getContributors(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getcontributor`;
    return this.http.get(API_URL);
  }

  // WC 04062022
  // Set contributor
  setContributorV2(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/setContributor`;
    return this.http.post(API_URL, data);
  }

  // Delete contributor
  deleteContributor(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/deleteContributor`;
    return this.http.post(API_URL, data);
  }

  getFollowerContributors(videoId: any, limit: any, page: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getFollowerContributors?videoId=${videoId}&limit=${limit}&page=${page}`;
    return this.http.get(API_URL);
  }


  setChildWatchHistory(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/childWatchHistory`;
    return this.http.post(API_URL, data);
  }


  getChoiceBreakdown(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/choiceBreakdown`;
    return this.http.get(API_URL);
  }

  getTopViewedChild(videoID: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/topViewedChild?videoID=` + videoID;
    return this.http.get(API_URL);
  }

  // Update password
  updatePassword(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/password`;
    return this.http.put(API_URL, data);
  }

  yourFirstDecision(id: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/yourDecision?parentId=` + id;
    return this.http.get(API_URL);
  }

  mostPopularPath(id: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/mostPopularPath?mainId=` + id;
    return this.http.get(API_URL);
  }

  getAnonymousUser(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/anonymousUser`;
    return this.http.get(API_URL);
  }

  registerAnonymousUser(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/registerAnonymousUser`;
    return this.http.post(API_URL, data);
  }

  getTopChoices(id: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/topChoices?parentId=` + id;
    return this.http.get(API_URL);
  }

  getTopChoicesAnalytics(id: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/topChoices?parentId=` + id;
    return this.http.get(API_URL);
  }

  //WC 01102028 for the new analytics page
  getNodeTopChoices(id: any, type: any, timeline: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/nodeTopChoices?videoId=${id}&type=${type}&timeline=${timeline}`;
    return this.http.get(API_URL);
  }

  getViewerChoiceHighlight(id: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/viewerChoice?userId=` + id;
    return this.http.get(API_URL);
  }

  getViewerChoiceHighlightV2(id: any, limit: number, page: number): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/viewerChoice?userId=${id}&limit=${limit}&page=${page}`;
    return this.http.get(API_URL);
  }

  bookmarkTemplateVideos(id: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/bookmarkTemplateVideos`;
    return this.http.post(API_URL, id);
  }

  deleteThumbnails(id: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/deleteMainVideoThumb`;
    return this.http.post(API_URL, id);
  }

  getCategorywiseData(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getCategorywiseData`;
    return this.http.get(API_URL);
  }

  getexploreVideos(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/exploreVideos`;
    return this.http.get(API_URL);
  }

  getsearchVideosByKeyword(id: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/searchVideosByKeyword?keyword=` + id;
    return this.http.get(API_URL);
  }

  getgetRandomVideos(id: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getRandomVideos?page=` + id;
    return this.http.get(API_URL);
  }

  getrecommendedVideosV2(limit: any, page: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/recommendedVideos?limit=${limit}&page=${page}`;
    return this.http.get(API_URL);
  }

  gettopViewedVideosV2(limit:any, page:any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/topViewedVideos?limit=${limit}&page=${page}`;
    return this.http.get(API_URL);
  }

  // WC 0812022 - calling V2
  gethighlyInteractiveVideosV2(limit:any, page:any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/highlyInteractiveVideos?limit=${limit}&page=${page}`;
    return this.http.get(API_URL);
  }
  // WC 0812022

  // WC 08122022 -- calling V2
  getlatestPublishedVideosV2(limit:any, page:any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/latestPublishedVideos?limit=${limit}&page=${page}`;
    return this.http.get(API_URL);
  }
  // WC 08122022

  // WC 08122022 -- calling V2
  getpopularCategoriesV2(limit:any, page:any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/popularCategories?limit=${limit}&page=${page}`;
    return this.http.get(API_URL);
  }

  getTree(id: any) {
    let b;
    try {
      b = this.localStorageService.getItem('token');
      if (b) {
        const API_URL = `${this.apiUrlv2}/users/getVideosTree?mainId=` + id;
        return this.http.get<TreeData>(API_URL);
      }
      else {
        const header = new HttpHeaders().set('Authorization', this.localStorageService.getItem('anonymousToken'));
        const API_URL = `${this.apiUrlv2}/users/getVideosTree?mainId=` + id;
        return this.http.get<TreeData>(API_URL, {headers: header});
      }
    } catch {      
      b = this.anonymousToken;
      const header = new HttpHeaders().set('Authorization', b);
      const API_URL = `${this.apiUrlv2}/users/getVideosTree?mainId=` + id;
      return this.http.get<TreeData>(API_URL, {headers: header});
    }
  }

  getTreeForUser(data: any) {
    let b;
    try {
      b = this.localStorageService.getItem('token');
      if (b) {
        const API_URL = `${this.apiUrlv2}/users/getVideosTree?mainId=` + data.id + '&userId=' + data.userId;
        return this.http.get<TreeData>(API_URL);
      }
      else {
        const header = new HttpHeaders().set('Authorization', this.localStorageService.getItem('anonymousToken'));
        const API_URL = `${this.apiUrlv2}/users/getVideosTree?mainId=` + data.id;
        return this.http.get<TreeData>(API_URL, {headers: header});
      }
    } catch {      
      b = this.anonymousToken;
      const header = new HttpHeaders().set('Authorization', b);
      const API_URL = `${this.apiUrlv2}/users/getVideosTree?mainId=` + data.id;
      return this.http.get<TreeData>(API_URL, {headers: header});
    }
  }

  watchPathway(object): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/watchPathway`;
    return this.http.post(API_URL, object);
  }

  getPathway(params): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getPathway`;
    return this.http.get(API_URL, {params});
  }

  setPathway(object, update:boolean): Observable<any> {
    let API_URL = `${this.apiUrlv2}/users/setPathway`;
    if (update) {
      API_URL = `${this.apiUrlv2}/users/updatePathway`;
    }
    return this.http.post(API_URL, object);
  }

  deletePathway(id): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/deletePathway/${id}`;
    return this.http.post(API_URL, {});
  }

  setChoicePositions(object){
    const API_URL = `${this.apiUrlv2}/users/setChoicePositions`;
    return this.http.post(API_URL, object);
  }

  moveVideoNode(object) {
    const API_URL = `${this.apiUrlv2}/users/moveVideoNode`;
    return this.http.post(API_URL, object);
  }

  reorderNodeChildren(object) {
    const API_URL = `${this.apiUrlv2}/users/reorderNodeChildren`;
    return this.http.post(API_URL, object);
  }

  emphasizeVideo(object: any) {
    const API_URL = `${this.apiUrlv2}/emphasize`;
    return this.http.post<any>(API_URL, object);
  }

  questionVideo(object: any) {
    const API_URL = `${this.apiUrlv2}/question`;
    return this.http.post<any>(API_URL, object);
  }

  getAllCollections(params?:any) {
    const API_URL = `${this.apiUrlv2}/users/collections`;
    return this.http.get<any>(API_URL, {params});
  }

  addNode(object: any) {
    const API_URL = `${this.apiUrlv2}/users/addNode`;
    return this.http.post<any>(API_URL, object);
  }

  deleteCollection(id: any) {
    const API_URL = `${this.apiUrlv2}/users/deleteCollection?collectionId=${id}`;
    return this.http.delete<any>(API_URL);
  }

  addCollection(object: any) {
    const API_URL = `${this.apiUrlv2}/users/addCollection`;
    return this.http.post<any>(API_URL, object);
  }

  // updateCollection(object: any) {
  //   const API_URL = `${this.apiUrl}/users/updateCollection`;
  //   return this.http.post<any>(API_URL, object);
  // }

  updateCollection(object: any) {
    const API_URL = `${this.apiUrlv2}/users/updateCollection`;
    return this.http.post<any>(API_URL, object);
  }

  getAllNodeVideosV2(id: any) {
    const API_URL = `${this.apiUrlv2}/users/collectionNodes?collectionId=${id}`;
    return this.http.get<any>(API_URL);
  }

  deleteNode(object: any) {
    const API_URL = `${this.apiUrlv2}/users/deleteNode`;
    return this.http.post<any>(API_URL, object);
  }

  // CBS

  /** PLANS START */
  createPlan(object) {
    const API_URL = `${this.apiUrlv2}/users/setPlanInfo`;
    return this.http.post<any>(API_URL, object);
  }

  getPlan(key: any) {
    const API_URL = `${this.apiUrlv2}/users/getPlanInfo?planId=${key}`;
    return this.http.get<any>(API_URL);
  }

  deletePlan(key: any) {
    const API_URL = `${this.apiUrlv2}/users/deletePlanInfo/${key}`;
    return this.http.post<any>(API_URL, {});
  }

  getAllPlans(id?) {
    let API_URL = `${this.apiUrlv2}/users/getAllPlanInfo`;
    if (id) {
      API_URL = `${this.apiUrlv2}/users/getAllPlanInfo?userId=${id}`;
    }
    return this.http.get<any>(API_URL);
  }

  /** PLANS END */

  createGroup(object: any) {
    const API_URL = `${this.apiUrlv2}/users/group`;
    return this.http.post<any>(API_URL, object);
  }

  reorderGroups(object: any) {
    const API_URL = `${this.apiUrlv2}/users/reorderGroups`;
    return this.http.post<any>(API_URL, object);
  }

  reorderGroupContent(object: any) {
    const API_URL = `${this.apiUrlv2}/users/reorderGroupContent`;
    return this.http.post<any>(API_URL, object);
  }
  
  updateGroupThumbnailSetting(object: any) {
    const API_URL = `${this.apiUrlv2}/users/updateGroupThumbnailSetting`;
    return this.http.post<any>(API_URL, object);
  }

  sendEmailNotificationForShareLink(object: any) {
    const API_URL = `${this.apiUrlv2}/users/sendEmailNotificationForShareLink`;
    return this.http.post<any>(API_URL, object);
  }

  updateGroupSubscriptionStatus(object: any) {
    const API_URL = `${this.apiUrlv2}/users/updateGroupSubscriptionStatus`;
    return this.http.post<any>(API_URL, object);
  }

  getGroupDetail(key: any) {
    const API_URL = `${this.apiUrlv2}/users/groupDetails?groupId=${key}`;
    return this.http.get<any>(API_URL);
  }

  // get Sample mail File
  getFileTemplateForEmail(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/community/getFileTemplateForEmail`;
    return this.http.get(API_URL);
  }

  // Get user info
  getAllGroups(object?): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/userGroups`;
    return this.http.get(API_URL, {params: object});
  }

  getEligibleGroupsForSubscriptionPlan(object): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getEligibleGroupsForSubscriptionPlan`;
    return this.http.get(API_URL, {params: object});
  }

  updateGroup(id: any, object) {
    const API_URL = `${this.apiUrlv2}/users/group`;
    return this.http.post<any>(API_URL, object);
  }

  addComment(object) {
    const API_URL = `${this.apiUrlv2}/users/commentBoard`;
    return this.http.post<any>(API_URL, object);
  }

  addCommunityComment(object) {
    const API_URL = `${this.apiUrlv2}/users/communityCommentBoard`;
    return this.http.post<any>(API_URL, object);
  }

  createCommunityTopic(object) {
    const API_URL = `${this.apiUrlv2}/community/createCommunityTopic`;
    return this.http.post<any>(API_URL, object);
  }

  editCommunityTopic(object) {
    const API_URL = `${this.apiUrlv2}/community/editCommunityTopic`;
    return this.http.post<any>(API_URL, object);
  }

  deleteCommunityTopic(object) {
    const API_URL = `${this.apiUrlv2}/community/deleteCommunityTopic`;
    return this.http.post<any>(API_URL,object);
  }

  reOrderCommunityTopics(data: any) {
    const API_URL = `${this.apiUrlv2}/community/reOrderCommunityTopics`;
    return this.http.post<any>(API_URL, data);
  }

  getCommment(id) {
    const API_URL = `${this.apiUrlv2}/users/getGroupComments?groupId=${id}`;
    return this.http.get<any>(API_URL);
  }

  getGroupComments(params){
    const API_URL = `${this.apiUrlv2}/users/getGroupComments`;
    return this.http.get<any>(API_URL,{params});
  }

  getCommunityComments(params) {
    const API_URL = `${this.apiUrlv2}/users/getCommunityComments`;
    return this.http.get<any>(API_URL, {params});
  }

  deleteGroup(id: any) {
    const API_URL = `${this.apiUrlv2}/users/deleteGroup?groupId=${id}`;
    return this.http.delete<any>(API_URL);
  }

  leaveGroup(id: any) {
    const API_URL = `${this.apiUrlv2}/users/leaveGroup?groupId=${id}`;
    return this.http.delete<any>(API_URL);
  }

  saveVideoContent(object) {
    const API_URL = `${this.apiUrlv2}/users/groupContent`;
    return this.http.post<any>(API_URL, object);
  }

  moveGroupContent(object) {
    const API_URL = `${this.apiUrlv2}/users/moveGroupContent`;
    return this.http.post<any>(API_URL, object);
  }

  addGroupLinkContent(object) {
    const API_URL = `${this.apiUrlv2}/users/addGroupLinkContent`;
    return this.http.post<any>(API_URL, object);
  }

  getGroupContent(id: string, tabName = '') {
      let other = '';
      if (tabName) {
        // Encode the tabName parameter
        const encodedTabName = encodeURIComponent(tabName);
        other = `&tabName=${encodedTabName}`;
        //     other = `&tabName=${tabName}`;
    }
    const API_URL = `${this.apiUrlv2}/users/getGroupContent?groupId=${id}${other}`;
    return this.http.get<any>(API_URL);
}


  getGroupContentPlaylist(id: string) {
    const API_URL = `${this.apiUrlv2}/users/getGroupContentPlaylist?groupId=${id}`;
    return this.http.get<any>(API_URL);
  }

  getGroupLinkContent(id: any) {
    const API_URL = `${this.apiUrlv2}/users/getGroupLinkContent?groupId=${id}`;
    return this.http.get<any>(API_URL);
  }

  deleteGroupContent(id: any, grpContentId: any) {
    const API_URL = `${this.apiUrlv2}/users/deleteGroupContent?groupContentId=${grpContentId}&groupId=${id}`;
    return this.http.delete<any>(API_URL);
  }

  deleteGroupLinkContent(id: any, grpContentId: any) {
    const API_URL = `${this.apiUrlv2}/users/deleteGroupLinkContent?groupContentId=${grpContentId}&groupId=${id}`;
    return this.http.delete<any>(API_URL);
  }

  createGroupTab(object) {
    const API_URL = `${this.apiUrlv2}/users/createGroupTab`;
    return this.http.post<any>(API_URL, object);
  }

  editGroupTab(object) {
    const API_URL = `${this.apiUrlv2}/users/editGroupTab`;
    return this.http.post<any>(API_URL, object);
  }

  getGroupTabs(id: any) {
    const API_URL = `${this.apiUrlv2}/users/getGroupTabs?groupId=${id}`;
    return this.http.get<any>(API_URL);
  }

  reOrderGroupTabs(data: any) {
    const API_URL = `${this.apiUrlv2}/users/reOrderGroupTabs`;
    return this.http.post<any>(API_URL, data);
  }

  deleteGroupTab(object) {
    const API_URL = `${this.apiUrlv2}/users/deleteGroupTab`;
    return this.http.post<any>(API_URL,object);
  }

  createGroupTopic(object) {
    const API_URL = `${this.apiUrlv2}/users/createGroupTopic`;
    return this.http.post<any>(API_URL, object);
  }

  editGroupTopic(object) {
    const API_URL = `${this.apiUrlv2}/users/editGroupTopic`;
    return this.http.post<any>(API_URL, object);
  }

  deleteGroupTopic(object) {
    const API_URL = `${this.apiUrlv2}/users/deleteGroupTopic`;
    return this.http.post<any>(API_URL,object);
  }

  
  reOrderGroupTopics(data: any) {
    const API_URL = `${this.apiUrlv2}/users/reOrderGroupTopics`;
    return this.http.post<any>(API_URL, data);
  }

  isUserCompleteTabVideos(data) {
    const API_URL = `${this.apiUrlv2}/users/isUserCompleteTabVideos`;
    return this.http.post<any>(API_URL, data);
  }

  likeDislikeComment(object: any) {
    const API_URL = `${this.apiUrlv2}/users/groupCommentLikeUnlike`;
    return this.http.post<any>(API_URL, object);
  }

  communityCommentLikeUnlike(object: any) {
    const API_URL = `${this.apiUrlv2}/users/communityCommentLikeUnlike`;
    return this.http.post<any>(API_URL, object);
  }

  deleteComment(id: any) {
    const API_URL = `${this.apiUrlv2}/users/deleteGroupComment?commentId=${id}`;
    return this.http.delete<any>(API_URL);
  }

  deleteCommunityComment(params) {
    const API_URL = `${this.apiUrlv2}/users/deleteCommunityComment`;
    return this.http.delete<any>(API_URL, {params});
  }

  deleteVideoComment(id, videoId) {
    const API_URL = `${this.apiUrlv2}/users/deleteComment/${id}?videoId=${videoId}`;
    return this.http.delete<any>(API_URL);
  }

  addMember(object) {
    const API_URL = `${this.apiUrlv2}/users/addMember`;
    return this.http.post<any>(API_URL, object);
  }

  addGroupSubscriber(object) {
    const API_URL = `${this.apiUrlv2}/users/addGroupSubscriber`;
    return this.http.post<any>(API_URL, object);
  }

  removeMember(groupId: any, memberId: any) {
    const API_URL = `${this.apiUrlv2}/users/removeGroupMember?groupId=${groupId}&toRemoveMemberId=${memberId}`;
    return this.http.delete<any>(API_URL);
  }

  changeAccessLevel(object: any) {
    const API_URL = `${this.apiUrlv2}/users/updateAccessLevel`;
    return this.http.post<any>(API_URL, object);
  }

  getGroupUser(groupId: any, currentUserId: any) {
    const API_URL = `${this.apiUrlv2}/users/listFollowerToAdd?groupId=${groupId}&userId=${currentUserId}`;
    return this.http.get<any>(API_URL);
  }

  joinGroup(object) {
    const API_URL = `${this.apiUrlv2}/users/joinGroup`;
    return this.http.post<any>(API_URL, object);
  }

  acceptRejectRequest(object) {
    const API_URL = `${this.apiUrlv2}/users/acceptRequestToJoinGroup`;
    return this.http.post<any>(API_URL, object);
  }

  validateGroupPassword(object) {
    const API_URL = `${this.apiUrlv2}/users/validateGroupPassword`;
    return this.http.post<any>(API_URL, object);
  }

  getWholeGroupdata() {
    const API_URL = `${this.apiUrlv2}/users/getAllGroupComments`;
    return this.http.get<any>(API_URL);
  }

  getFollowers(limit?: any, page?: any, userId?: any) {
    let API_URL = `${this.apiUrlv2}/users/getFollowers?limit=${limit}&page=${page}`;
    if (userId) {
      API_URL = `${this.apiUrlv2}/users/getFollowers?userID=${userId}&limit=${limit}&page=${page}`;
    }
    return this.http.get<any>(API_URL);
  }

  getRecipientsFollowers(limit?: any, page?: any, userId?: any, videoId?:any) {
    let API_URL = `${this.apiUrlv2}/users/getRecipientsFollowers?userID=${userId}&videoId=${videoId}&limit=${limit}&page=${page}`;
    return this.http.get<any>(API_URL);
  }

  getRecipientsGroups(videoId?:any) {
    let API_URL = `${this.apiUrlv2}/users/getRecipientsGroups?videoId=${videoId}`;
    return this.http.get<any>(API_URL);
  }

  getSubscribers(sellerId:any, page?: any, limit?: any,) {
    let API_URL = `${this.apiUrlv2}/community/subscribers?sellerId=${sellerId}&page=${page}&limit=${limit}`;
    return this.http.get<any>(API_URL);
  }

  searchFollowers(value?: any) {
    let API_URL = `${this.apiUrlv2}/users/searchFollowers?searchString=${value}`;
    return this.http.get<any>(API_URL);
  }

  getUsersForMention(value?: any, page?: any, limit?: any) {
    if (!page)
      page = 1;

    if (!limit)
      limit = 10;

    if (value) {
      let API_URL = `${this.apiUrlv2}/users/getUsersForMention?mentionUserName=${value}&page=${page}&limit=${limit}`;
      return this.http.get<any>(API_URL);
    } else {
      let API_URL = `${this.apiUrlv2}/users/getUsersForMention?page=${page}&limit=${limit}`;
      return this.http.get<any>(API_URL);
    }
  }

  searchSubscribers(value?: any, sellerId?:any) {
    let API_URL = `${this.apiUrlv2}/community/searchSubscribers?sellerId=${sellerId}&searchString=${value}`;
    return this.http.get<any>(API_URL);
  }

  getFollowings(limit: any, page: any, userId?: any) {
    let API_URL = `${this.apiUrlv2}/users/getFollowings?limit=${limit}&page=${page}`;
    if (userId) {
      API_URL = `${this.apiUrlv2}/users/getFollowings?userID=${userId}&limit=${limit}&page=${page}`;
    }
    return this.http.get<any>(API_URL);
  }

  // WC 05132022
  // Feature flags
  getFeatureEnablements() {
    const API_URL = `${this.apiUrlv2}/users/getFeatureEnablements`;
    return this.http.get<any>(API_URL);
  }

  setShoppingCart(object: any) {
    const API_URL = `${this.apiUrlv2}/users/setShoppingCart`;
    return this.http.post<any>(API_URL, object);
  }

  getShoppingCart(shoppingCartId: any) {
    const API_URL = `${this.apiUrlv2}/users/getShoppingCart?shoppingCartId=${shoppingCartId}`;
    return this.http.get<any>(API_URL);
  }

  getShoppingCartByUser() {
    const API_URL = `${this.apiUrlv2}/users/getShoppingCartByUser`;
    return this.http.get<any>(API_URL);
  }

  saveClientSecretToShoppingCart(object: any) {
    const API_URL = `${this.apiUrlv2}/users/saveClientSecretToShoppingCart`;
    return this.http.post<any>(API_URL, object);
  }

  setPaymentConfirmation(object: any) {
    const API_URL = `${this.apiUrlv2}/users/setPaymentConfirmation`;
    return this.http.post<any>(API_URL, object);
  }

  setUserSubscriptionPlan(payload: any) {
    const API_URL = `${this.apiUrlv2}/users/setUserSubscriptionPlan`;
    return this.http.post<any>(API_URL, payload);
  }

  setUserBuyerSubscriptionPlan(payload: any) {
    const API_URL = `${this.apiUrlv2}/users/setUserBuyerSubscriptionPlan`;
    return this.http.post<any>(API_URL, payload);
  }

  getUserSubscriptionPlanById(subscriptionId: any) {
    const API_URL = `${this.apiUrlv2}/users/getUserSubscriptionPlanById?subscriptionId=${subscriptionId}`;
    return this.http.get<any>(API_URL);
  }

  getUserSubscriptionPlan() {
    const API_URL = `${this.apiUrlv2}/users/getUserSubscriptionPlan`;
    return this.http.get<any>(API_URL);
  }

  getCommunitySubscriptionPlan() {
    const API_URL = `${this.apiUrlv2}/users/getCommunitySubscriptionPlan `;
    return this.http.get<any>(API_URL);
  }

  cancelUserSubscriptionPlan(payload: any) {
    const API_URL = `${this.apiUrlv2}/users/cancelUserSubscriptionPlan`;
    return this.http.post<any>(API_URL, payload);
  }

  getPlanInfo(planId: any) {
    const API_URL = `${this.apiUrlv2}/users/getPlanInfo?planId=${planId}`;
    return this.http.get<any>(API_URL);
  }

  deactivateShoppingCart(object: any) {
    const API_URL = `${this.apiUrlv2}/users/deactivateShoppingCart`;
    return this.http.post<any>(API_URL, object);
  }

  deleteShoppingCartItems(items: any) {
    const API_URL = `${this.apiUrlv2}/users/deleteShoppingCartItems`;
    return this.http.post<any>(API_URL, items);
  }

  addShoppingCartItems(items: any) {
    const API_URL = `${this.apiUrlv2}/users/addShoppingCartItems`;
    return this.http.post<any>(API_URL, items);
  }

  getPaymentByConfirmationNo(paymentConfirmationNo: any) {
    const API_URL = `${this.apiUrlv2}/users/getPaymentByConfirmationNo?paymentConfirmationNo=${paymentConfirmationNo}`;
    return this.http.get<any>(API_URL);
  }

  /******************* STRIPE INTEGRATION *********************/

  // Create Stripe Payment Intent
  initializePayment(planId: any) {
    const API_URL = `${this.apiUrlv2}/payments/createPaymentIntent`;
    return this.http.post<any>(API_URL, planId);
  }

  // Create Stripe Customer
  createPayCustomer(user: any) {
    const API_URL = `${this.apiUrlv2}/payments/createPayCustomer`;
    // console.log('API_URL = ', API_URL);
    return this.http.post<any>(API_URL, user);
  }

  // Create Stripe Subscription
  createPaySubscription(object: any) {
    const API_URL = `${this.apiUrlv2}/payments/createPaySubscription`;
    return this.http.post<any>(API_URL, object);
  }

  // Update Stripe Subscription
  updatePaySubscription(object: any) {
    const API_URL = `${this.apiUrlv2}/payments/updatePaySubscription`;
    return this.http.post<any>(API_URL, object);
  }

  getProrationPreviewInvoice(paySubscriptionId: string, updatedPriceId: string) {
    const API_URL = `${this.apiUrlv2}/payments/previewProration?subscriptionId=${paySubscriptionId}&updatedPriceId=${updatedPriceId}`;
    return this.http.get<any>(API_URL);
  }


  // Get Stripe Subscription
  getPaySubscription(subscriptionId: any, sellerAccountId = null) {
    let API_URL: string;
    if (sellerAccountId)
      API_URL = `${this.apiUrlv2}/payments/getPaySubscription?subscriptionId=${subscriptionId}&sellerAccountId=${sellerAccountId}`;
    else
      API_URL = `${this.apiUrlv2}/payments/getPaySubscription?subscriptionId=${subscriptionId}`;

    return this.http.get<any>(API_URL);
  }

  // get Stripe payment method
  getPaymentMethod(paymentMethodId: any, sellerAccountId: any) {
    let API_URL: string;

    if (sellerAccountId)
      API_URL = `${this.apiUrlv2}/payments/getPaymentMethod?paymentMethodId=${paymentMethodId}&sellerAccountId=${sellerAccountId}`;
    else
      API_URL = `${this.apiUrlv2}/payments/getPaymentMethod?paymentMethodId=${paymentMethodId}`;

    //console.log('API_URL = ', API_URL);

    return this.http.get<any>(API_URL);
  }

  // Cancel Stripe Subscription
  cancelPaySubscription(payload: any) {

    const API_URL = `${this.apiUrlv2}/payments/cancelPaySubscription`;

    return this.http.post<any>(API_URL, payload);
  }

  updateUserSubscriptionPlan(payload: any) {

    const API_URL = `${this.apiUrlv2}/users/updateUserSubscriptionPlan`;

    return this.http.post<any>(API_URL, payload);
  }

  createPaymentSetUpIntents(payCustomerId: any) {
    const API_URL = `${this.apiUrlv2}/payments/createPaymentSetUpIntents`;
    return this.http.post<any>(API_URL, payCustomerId);
  }

  getCustomerPaymentMethods(payCustomerId: any) {
    const API_URL = `${this.apiUrlv2}/payments/getCustomerPaymentMethods/${payCustomerId}`;
    return this.http.get<any>(API_URL);
  }

  updateCustomerPaymentMethod(payload: any) {
    const API_URL = `${this.apiUrlv2}/payments/updateCustomerPaymentMethod`;
    return this.http.post<any>(API_URL, payload);
  }

  updateSubscriptionPaymentMethod(payload: any) {
    const API_URL = `${this.apiUrlv2}/payments/updateSubscriptionPaymentMethod`;
    return this.http.post<any>(API_URL, payload);
  }

  deletePaymentMethod(payload: any) {
    const API_URL = `${this.apiUrlv2}/payments/deletePaymentMethod`;
    return this.http.post<any>(API_URL, payload);
  }

  createConnectedAccount(user: any) {
    const API_URL = `${this.apiUrlv2}/payments/createConnectedAccount`;
    return this.http.post<any>(API_URL, user);
  }

  onboardConnectedAccount(connectedAccountId: any) {
    const API_URL = `${this.apiUrlv2}/payments/onboardConnectedAccount`;
    return this.http.post<any>(API_URL, connectedAccountId);
  }

  retrieveConnectedAccount(connectedAccountId: any) {
    const API_URL = `${this.apiUrlv2}/payments/retrieveConnectedAccount/${connectedAccountId}`;
    return this.http.get<any>(API_URL);
  }

  setAccountOnboardingStatus(onboardingStatus: any) {
    const API_URL = `${this.apiUrlv2}/users/setAccountOnboardingStatus`;
    return this.http.post<any>(API_URL, onboardingStatus);
  }


  createSellerPayCustomer(customer: any) {
    const API_URL = `${this.apiUrlv2}/payments/createSellerPayCustomer`;
    return this.http.post<any>(API_URL, customer);
  }

  getSellerPayCustomer(sellerUserId: any) {
    const API_URL = `${this.apiUrlv2}/payments/getSellerPayCustomer?sellerUserId=${sellerUserId}`;
    return this.http.get<any>(API_URL);
  }

  // Create Stripe Subscription for the Connected Account
  createSellerPaySubscription(object: any) {
    const API_URL = `${this.apiUrlv2}/payments/createSellerPaySubscription`;
    return this.http.post<any>(API_URL, object);
  }

  // WC 05132022
  /******************* STRIPE INTEGRATION *********************/

  // WC 07112022
  /******************* Marketing Campaign Tracking *********************/
  // Create Stripe Subscription
  setMarketingCampaign(campaignId: any) {
    const API_URL = `${this.apiUrlv2}/campaign/setMarketingCampaign`;
    return this.http.post<any>(API_URL, campaignId);
  }

  // get marketing campaign plan
  getMarketingCampaignPlan(campaignId: any) {
    const API_URL = `${this.apiUrlv2}/campaign/getMarketingCampaignPlan?campaignId=${campaignId}`;
    return this.http.get<any>(API_URL);
  }

  // emails for videos
  setEmailsForVideos(payload: any) {
    const API_URL = `${this.apiUrlv2}/campaign/setEmailsForVideos`;
    return this.http.post<any>(API_URL, payload);
  }

  // update user's email for videos prop
  updateUserEmailsForVideos(payload: any) {
    const API_URL = `${this.apiUrlv2}/users/updateUserEmailsForVideos`;
    return this.http.post<any>(API_URL, payload);
  }


  getGroupsByVideoIdAndUserId(videoId: string): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getGroupsByVideoIdAndUserId?videoId=${videoId}`;
    return this.http.get<any>(API_URL);
  }
  /******************* Marketing Campaign Tracking *********************/

  // WC 08242022
  updateUserOrg(org: any) {
    const API_URL = `${this.apiUrlv2}/users/updateUserOrg`;
    // console.log("API_URL: ", API_URL);
    return this.http.post<any>(API_URL, org);
  }

  // WC 08242022

  // WC 09212022
  getCollabVideosV2(params) {
    const API_URL = `${this.apiUrlv2}/users/getCollabVideos`;
    return this.http.get<any>(API_URL, {params});
  }

  getAllMainVideosCountV2(params?) {
    const API_URL = `${this.apiUrlv2}/users/getAllMainVideosCount`;
    return this.http.get<any>(API_URL, {params});
  }

  getCollabVideosCountV2(params?) {
    const API_URL = `${this.apiUrlv2}/users/getCollabVideosCount`;
    return this.http.get<any>(API_URL,{params});
  }

  // WC 09212022

  contactUs(data: any) {
    const API_URL = `${this.apiUrlv2}/users/contactUs`;
    return this.http.post<any>(API_URL, data);
  }

  getSubscriptionGroupByPlanId(planId: string) {
    const API_URL = `${this.apiUrlv2}/users/getSubscriptionGroupByPlanId?planId=${planId}`;
    return this.http.get<any>(API_URL);
  }

  /** build community */
  getEmailTemplate(groupId: string) {

    if (groupId == null) {
      const API_URL = `${this.apiUrlv2}/community/getEmailTemplate`;
      return this.http.get<any>(API_URL);
    } else {
      const API_URL = `${this.apiUrlv2}/community/getEmailTemplate?groupId=${groupId}`;
      return this.http.get<any>(API_URL);
    }
  }

  sendCommunityEmail(data: any) {
    const API_URL = `${this.apiUrlv2}/community/sendEmail`;
    return this.http.post<any>(API_URL,data);
  }

  sendEmail(data: any) {
    const API_URL = `${this.apiUrlv2}/users/sendEmail`;
    return this.http.post<any>(API_URL, data);
  }

  subscribeNewletter(data: any) {
    const API_URL = `${this.apiUrlv2}/crm/updateContactWithMarketingStage`;
    return this.http.post<any>(API_URL, data);
  }

  getAnnouncement(type: 'LandingPage' | 'ProfilePage') {
    const API_URL = `${this.apiUrlv2}/users/getAnnouncement/${type}`;
    return this.http.get<any>(API_URL);
  }

  getCommunityAnnouncement(sellerId: string) {
    const API_URL = `${this.apiUrlv2}/users/getCommunityAnnouncement/${sellerId}`;
    return this.http.get<any>(API_URL);
  }

  updateCommunityAnnouncement(payload) {
    const API_URL = `${this.apiUrlv2}/users/updateCommunityAnnouncement`;
    return this.http.post<any>(API_URL, payload);
  }

  deleteCommunityAnnouncement() {
    const API_URL = `${this.apiUrlv2}/users/deleteCommunityAnnouncement`
    return this.http.delete(API_URL);
  }

  getSignedUrl(url: string) {
    const API_URL = `${this.apiUrlv2}/users/getSignedUrl?url=${url}`;
    return this.http.get<any>(API_URL,{responseType: 'text' as any});
  }

  getTextData(url: string) {
    return this.http.get<any>(url,{responseType: 'text' as any});
  }

  getSellerIdByCommunityUrl(url: string) {
    const API_URL = `${this.apiUrlv2}/users/getSellerIdByCommunityUrl/${url}`;
    return this.http.get<any>(API_URL);
  }

  setDoNotShowItem(data: any) {
    const API_URL = `${this.apiUrlv2}/users/setDoNotShowItem`;
    return this.http.post<any>(API_URL, data);
  }

  removeDoNotShowItem(data: any) {
    const API_URL = `${this.apiUrlv2}/users/removeDoNotShowItem`;
    return this.http.post<any>(API_URL, data);
  }

  getDoNotShowItem(params) {
    const API_URL = `${this.apiUrlv2}/users/getDoNotShowItem`;
    return this.http.get<any>(API_URL, {params});
  }

  /** blogs API */

  getBlogs(params?: any) {
    const API_URL = `${this.apiUrlv2}/blog/getBlog`;
    return this.http.get<any>(API_URL, {params});
  }

  getPublishedBlogs(params?: any) {
    const API_URL = `${this.apiUrlv2}/blog/getPublishedBlog`;
    return this.http.get<any>(API_URL, {params});
  }

  searchBlogs(params?: any) {
    const API_URL = `${this.apiUrlv2}/blog/searchBlog`;
    return this.http.get<any>(API_URL, {params});
  }

  createBlog(payload: any) {
    const API_URL = `${this.apiUrlv2}/blog/createBlog`;
    return this.http.post<any>(API_URL, payload);
  }

  updateBlog(payload: any) {
    const API_URL = `${this.apiUrlv2}/blog/updateBlog`;
    return this.http.post<any>(API_URL, payload);
  }

  deleteBlog(id: string) {
    const API_URL = `${this.apiUrlv2}/blog/deleteBlog/${id}`;
    return this.http.get<any>(API_URL);
  }

  recommendedVideosByUserCategory(limit: number, page: number) {
    const API_URL = `${this.apiUrlv2}/users/recommendedVideosByUserCategory?limit=${limit}&page=${page}`;
    return this.http.get<any>(API_URL);
  }

  videosYouMightLike() {
    const API_URL = `${this.apiUrlv2}/users/videosYouMightLike`;
    return this.http.get<any>(API_URL);
  }

  testDriveFFVideos() {
    const API_URL = `${this.apiUrlv2}/users/videosYouMightLike/testDrive`;
    return this.http.get<any>(API_URL);
  }

  uploadAttachments(payload) {
    //WC 10052023 --- PenTestFixes
    //const API_URL = `${this.apiUrlv2}/users/uploadAttachments`;
    //console.log("uploadAttachments - payload = ", payload);

    const API_URL = `${this.apiUrlv2}/users/uploadGroupAttachments`;
    return this.http.post<any>(API_URL, payload);
  }

  deleteUploadedAttachments(payload) {
    const API_URL = `${this.apiUrlv2}/users/deleteUploadedAttachments`;
    return this.http.post<any>(API_URL, payload);
  }

  transferVideoOwnership(payload) {
    const API_URL = `${this.apiUrlv2}/users/transferVideoOwnership`;
    return this.http.post<any>(API_URL, payload);
  }

  downloadCommunitySubscribers() {
    const API_URL = `${this.apiUrlv2}/users/downloadCommunitySubscribers`;
    return this.http.get<any>(API_URL);
  }

  downloadGroupMembers(id) {
    const API_URL = `${this.apiUrlv2}/users/downloadGroupMembers/${id}`;
    return this.http.get<any>(API_URL);
  }


  getTranscripts(videoId) {
    const API_URL = `${this.apiUrlv2}/users/getTranscripts`;
    return this.http.get<any>(API_URL, {params: {videoId}});
  }

  setCmefyRedirect(payload) {
    const API_URL = `${this.apiUrlv2}/users/setCmefyRedirect`;
    return this.http.post<any>(API_URL, payload);
  }

  adminSharedVideos(payload) {
    const API_URL = `${this.apiUrlv2}/users/adminSharedVideos`;
    return this.http.post<any>(API_URL, payload);
  }

  getAdminSharedVideos(limit: number, page: number, sort: string, searchText?: any) {
    let API_URL;
    if(!searchText && searchText === undefined) {
      API_URL = `${this.apiUrlv2}/users/getAdminSharedVideos?limit=${limit}&page=${page}&sort=${sort}`;
    } else {
      API_URL = `${this.apiUrlv2}/users/getAdminSharedVideos?limit=${limit}&page=${page}&sort=${sort}&searchText=${searchText}`;
    }
    return this.http.get<any>(API_URL);
  }

  adminSharedVideosCount(params?:any) {
    const API_URL = `${this.apiUrlv2}/users/adminSharedVideosCount`;
    return this.http.get<any>(API_URL, {params});
  }

  getInspirationalVideos(platformSolution = 'All', limit, page) {
    const API_URL = `${this.apiUrlv2}/users/getInspirationalVideos/${platformSolution}?limit=${limit}&page=${page}`;
    return this.http.get<any>(API_URL);
  }

  createInspirationalVideos(data) {
    const API_URL = `${this.apiUrlv2}/users/inspirationalVideos`;
    return this.http.post<any>(API_URL, data);
  }

  deleteInspirationalVideos(data) {
    const API_URL = `${this.apiUrlv2}/users/deleteInspirationalVideos`;
    return this.http.post<any>(API_URL, data);
  }

  createPartner(data) {
    const API_URL = `${this.apiUrlv2}/users/partner`;
    return this.http.post<any>(API_URL, data);
  }

  getPartners(platformSolution = 'All', limit, page) {
    const API_URL = `${this.apiUrlv2}/users/getPartners?platformSolution=${platformSolution}&limit=${limit}&page=${page}`;
    return this.http.get<any>(API_URL);
  }

  getPartner(id) {
    const API_URL = `${this.apiUrlv2}/users/getPartners?partnerId=${id}`;
    return this.http.get<any>(API_URL);
  }

  deletePartner(data) {
    const API_URL = `${this.apiUrlv2}/users/deletePartner`;
    return this.http.post<any>(API_URL, data);
  }

  setVideoToDraft(data) {
    const API_URL = `${this.apiUrlv2}/users/setVideoToDraft`;
    return this.http.post<any>(API_URL, data);
  }

  getNoOfProcessedAIVideos(userId): Observable<any> {
    const API_URL = `${environment.AI_PARSE_URL}/noOfProcessedAIVideos/${userId}`;

    //console.log('getNoOfProcessedAIVideos - API_URL = ', API_URL);

    return this.http.get(API_URL);
  }

  getProviderVideos(data:any) {
    let API_URL = '';
    if(data.videoProvider === 'Pixabay'){
      API_URL = `${this.apiUrlv2}/videoProvider/getVideos?searchQuery=${data?.searchQuery}&page=${data?.page}&limit=${data?.limit}&videoProvider=${data?.videoProvider}`
    }else{
      API_URL = `${this.apiUrlv2}/videoProvider/getVideos?searchQuery=${data?.searchQuery}&page=${data?.page}&limit=${data?.limit}`
    }
    return this.http.get(API_URL);
  }

  mergeAudioToVideo(payload) {
    const API_URL = `${this.apiUrlv2}/users/mergeAudioToVideo`
    return this.http.post(API_URL, payload);
  }


  getBadgeCount(params) {
    const API_URL = `${this.apiUrlv2}/users/getBadgeCount`
    return this.http.get(API_URL, {params});
  }

  getBadge(params) {
    const API_URL = `${this.apiUrlv2}/users/getBadge`
    return this.http.get(API_URL, {params});
  }

  uploadBadgeThumbnail(payload) {
    const API_URL = `${this.apiUrlv2}/users/uploadBadgeThumbnail`
    return this.http.post(API_URL, payload);
  }

  uploadBadgeSignatureImage(payload) {
    const API_URL = `${this.apiUrlv2}/users/uploadBadgeSignatureImage`
    return this.http.post(API_URL, payload);
  }

  setBadge(payload) {
    const API_URL = `${this.apiUrlv2}/users/setBadge`
    return this.http.post(API_URL, payload);
  }

  deleteBadge(id) {
    const API_URL = `${this.apiUrlv2}/users/deleteBadge/${id}`
    return this.http.delete(API_URL);
  }

  getSpecificBadge(id) {
    const API_URL = `${this.apiUrlv2}/users/getSpecificBadge/${id}`
    return this.http.get(API_URL);
  }

  actionOnTabBadge(data) {
    const API_URL = `${this.apiUrlv2}/users/actionOnTabBadge`
    return this.http.post(API_URL, data);
  }

  setQuestionnaireResult(payload) {
    const API_URL = `${this.apiUrlv2}/users/setQuestionnaireResult`
    return this.http.post(API_URL, payload);
  }

  getQuestionnaireResult(id, videoId) {
    //const API_URL = `${this.apiUrlv2}/users/getQuestionnaireResult/${id}`
    const API_URL = `${this.apiUrlv2}/users/getQuestionnaireResult?id=${id}&videoId=${videoId}`
    return this.http.get(API_URL);
  }

  attachQuestionnaireToVideo(payload) {
    const API_URL = `${this.apiUrlv2}/users/attachQuestionnaireToVideo`
    return this.http.post(API_URL, payload);
  }

  detachQuestionnaireFromVideo(payload) {
    const API_URL = `${this.apiUrlv2}/users/detachQuestionnaireFromVideo`
    return this.http.post(API_URL, payload);
  }

  getQuestionnaire(params) {
    const API_URL = `${this.apiUrlv2}/users/getQuestionnaire`
    return this.http.get(API_URL, {params});
  }

  getQuestionnaireCount(params) {
    const API_URL = `${this.apiUrlv2}/users/getQuestionnaireCount`
    return this.http.get(API_URL, {params});
  }

  setQuestionnaire(payload) {
    const API_URL = `${this.apiUrlv2}/users/setQuestionnaire`
    return this.http.post(API_URL, payload);
  }

  deleteQuestionnaire(id) {
    const API_URL = `${this.apiUrlv2}/users/deleteQuestionnaire/${id}`
    return this.http.delete(API_URL);
  }

  //This API returns true if the user has completed viewing a video and it will assign a badge and send data to HubSpot if the video owner enables HubSpot integration
  //else will return false
  isUserCompleteViewVideo(videoId, firstName?, email?) {
    let API_URL: string;

    if(firstName || email){
      API_URL = `${this.apiUrlv2}/users/isUserCompleteViewVideo?videoId=${videoId}&firstName=${firstName}&email=${email}`
    }else{
      API_URL = `${this.apiUrlv2}/users/isUserCompleteViewVideo?videoId=${videoId}`
    }
    return this.http.get(API_URL);
  }

  // Update privacy settings
  updateHubSpotIntegrationSettings(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/updateHubSpotIntegrationSettings`;
    return this.http.post(API_URL, data);
  }

  getCrmLifeCycleStages(): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/getCrmLifeCycleStages`;
    return this.http.get<any>(API_URL);
  }

  removeMainVideoBadge(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/users/removeMainVideoBadge`;
    return this.http.post(API_URL, data);
  }

  uploadImageToS3(data: any) {
    const API_URL = `${this.apiUrlv2}/users/uploadImageToS3`;
    return this.http.post(API_URL, data);
  }

  isContributor(videoId: any) {
    const API_URL = `${this.apiUrlv2}/users/isContributor?videoId=${videoId}`;
    return this.http.get<any>(API_URL);
  }

  // Coupons & Promo Codes
  createCoupon(payload) {
    const API_URL = `${this.apiUrlv2}/payments/createCoupon`;
    return this.http.post(API_URL, payload);
  }

  updateCoupon(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/payments/updateCoupon`;
    return this.http.post(API_URL, data);
  }

  getAllCoupon(page: number, limit: number) {
    const API_URL = `${this.apiUrlv2}/payments/listCoupons?page=${page}&limit=${limit}`;
    return this.http.get(API_URL);
  }

  getCouponDetail(id: string) {
    const API_URL = `${this.apiUrlv2}/payments/getCoupon/${id}`;
    return this.http.get(API_URL);
  }

  deleteCoupon(id: string) {
    const API_URL = `${this.apiUrlv2}/payments/deleteCoupon/${id}`
    return this.http.delete(API_URL);
  }

  //couponId is Stripe coupon id
  listCustomerCouponRedemptions(couponId: string) {

      const API_URL = `${this.apiUrlv2}/payments/listCustomerCouponRedemptions/${couponId}`
      return this.http.get(API_URL);
  }

  createPromoCode(payload) {
    const API_URL = `${this.apiUrlv2}/payments/createPromoCode`;
    return this.http.post(API_URL, payload);
  }

  updatePromoCode(data: any): Observable<any> {
    const API_URL = `${this.apiUrlv2}/payments/updatePromoCode`;
    return this.http.post(API_URL, data);
  }

  getAllPromoCodes() {
    const API_URL = `${this.apiUrlv2}/payments/listPromoCodes`;
    return this.http.get(API_URL);
  }

  getPromoCodeDetail(id: string) {
    const API_URL = `${this.apiUrlv2}/payments/getPromoCode/${id}`;
    return this.http.get(API_URL);
  }

  deletePromoCode(id: string) {
    const API_URL = `${this.apiUrlv2}/payments/deletePromoCode/${id}`
    return this.http.delete(API_URL);
  }

  validatePromoCode(payload: any) {
    const API_URL = `${this.apiUrlv2}/payments/validatePromoCode`;
    return this.http.post(API_URL, payload);
  }

  deactivatePromoCode(id: string) {
    const API_URL = `${this.apiUrlv2}/payments/deactivatePromoCode/${id}`;
    return this.http.post(API_URL, null);
  }

  applyPromoCodeToShoppingCart(payload: any) {
    const API_URL = `${this.apiUrlv2}/payments/applyPromoCodeToShoppingCart`;
    return this.http.post(API_URL, payload);
  }

  removeParentIds(payload: any) {
    const API_URL = `${this.apiUrlv2}/users/deleteConnectorParentIds`
    return this.http.post(API_URL, payload);
  }

  cloneAGroup(payload: any) {
    const API_URL = `${this.apiUrlv2}/users/cloneAGroup`
    return this.http.post(API_URL, payload);
  }

  getMostCommonChoices(id: string, timeline: string) {
    const API_URL = `${this.apiUrlv2}/users/getTopCommonChoices?videoId=${id}&timeline=${timeline}`;
    return this.http.get(API_URL);
  }

  getCompletedVideoPathways(id: string, timeline: string) {
    const API_URL = `${this.apiUrlv2}/users/getCompletedVideoPathways?videoId=${id}&timeline=${timeline}`;
    return this.http.get(API_URL);
  }

  getViewingPatterns(id: string) {
    const API_URL = `${this.apiUrlv2}/users/getViewingPatterns?videoId=${id}`;
    return this.http.get(API_URL);
  }

  getQuestionnaireResultAnalytics(id: string) {
    const API_URL = `${this.apiUrlv2}/users/getQuestionnaireResultAnalytics/${id}`;
    return this.http.get(API_URL);
  }

  getQuestionnaireResultDetails(data:any) {
    const API_URL = `${this.apiUrlv2}/users/getQuestionnaireResultDetails?videoId=${data.videoId}&questionnaireId=${data.questionnaireId}&page=${data.page}&limit=${data.limit}`;
    return this.http.get(API_URL);
  }

  getNoOfSubmittedQuestionnaires(data:any) {
    const API_URL = `${this.apiUrlv2}/users/getNoOfSubmittedQuestionnaires?videoId=${data.videoId}&questionnaireId=${data.questionnaireId}`;
    return this.http.get(API_URL);
  }

  createLexieChat(payload) {
    //const API_URL = `${this.apiUrlv2}/chat/createChat`;

    //WC 06032024 --- chat GPT on Azure
    const API_URL = `${this.apiUrlv2}/azureChat/createChat`;
    return this.http.post(API_URL, payload);
  }

  setFeaturedVideosInCommunity(object: any) {
    const API_URL = `${this.apiUrlv2}/users/setFeaturedVideosInCommunity`;
    return this.http.post<any>(API_URL, object);
  }

  samlRedirection() {
    const API_URL = `${this.apiUrlv2}/auth/saml`;
    return this.http.get(API_URL);
  }

  setTimerInVideoChoice(payload) {
    const API_URL = `${this.apiUrlv2}/users/setTimerInVideoChoices`;
    return this.http.post<any>(API_URL, payload);
  }

  resetTimerInVideoChoice(payload) {
    const API_URL = `${this.apiUrlv2}/users/resetTimerInVideoChoices`;
    return this.http.post<any>(API_URL, payload);
  }

  getGroupTabsContentSize(groupId) {
    const API_URL = `${this.apiUrlv2}/users/getGroupTabsContentSize?groupId=${groupId}`;
    return this.http.get<any>(API_URL);
  }

  cloneTemplate(videoId:any) {
    const API_URL = `${this.apiUrlv2}/users/cloneTemplate`;
    return this.http.post<any>(API_URL, videoId);
  }

  generateQRCode(qr_code_url:any) {
    const API_URL = `${this.apiUrlv2}/qrCodeGenerator/generateQRCode`;
    return this.http.post<any>(API_URL, qr_code_url);
  }

  initiateMultipartUpload(payload) {
    const API_URL = `${this.apiUrlv2}/users/initiateMultipartUpload`;
    return this.http.post<any>(API_URL, payload);
  }

  presignedUrlForMultipartUpload(payload) {
    const API_URL = `${this.apiUrlv2}/users/presignedUrlForMultipartUpload`;
    return this.http.post<any>(API_URL, payload);
  }

  completeMultipartUpload(payload) {
    const API_URL = `${this.apiUrlv2}/users/completeMultipartUpload`;
    return this.http.post<any>(API_URL, payload);
  }

  getCrmData(limit, page, sort, searchText) {
    let API_URL: any;
    if (searchText !== '' && searchText !== null){
      API_URL = `${this.apiUrlv2}/users/getCrmData?limit=${limit}&page=${page}&sort=${sort}&searchText=${searchText}`;
    } else {
      API_URL = `${this.apiUrlv2}/users/getCrmData?&limit=${limit}&page=${page}&sort=${sort}`;
    }
    return this.http.get<any>(API_URL);
  }

  createStoryBoardFromScenarios(payload) {
    const API_URL = `${this.apiUrlv2}/users/createStoryBoardFromScenarios`;
    return this.http.post<any>(API_URL, payload);
  }

  callPexelAPI(searchQuery: string) {
    const API_URL = `${this.apiUrlv2}/videoProvider/getVideos?searchQuery=${searchQuery}&page=1&limit=1`;
    return this.http.get<any>(API_URL);
  }

  getVideosForStoryBoard(videoId: string) {
    const API_URL = `${this.apiUrlv2}/users/getVideosForStoryBoard?videoId=${videoId}`;
    return this.http.get<any>(API_URL);
  }

  getScriptsForStoryBoard(videoId: string) {
    const API_URL = `${this.apiUrlv2}/users/getMultipleScriptsForStoryBoard?videoId=${videoId}`;
    return this.http.get<any>(API_URL);
  }

  getVoiceModels() {
    const API_URL = `${this.apiUrlv2}/tts/getVoiceModels`;
    return this.http.get<any>(API_URL);
  }

  generateTTS(payload) {
    const API_URL = `${this.apiUrlv2}/tts/generateTTS`;
    return this.http.post<any>(API_URL, payload);
  }

  setVoiceModel(payload) {
    const API_URL = `${this.apiUrlv2}/users/setVoiceModel`;
    return this.http.post<any>(API_URL, payload);
  }

  createAIVideo(payload) {
    const API_URL = `${this.apiUrlv2}/users/createAIVideo`;
    return this.http.post<any>(API_URL, payload);
  }

  mergeVideoAndAudioOfNode(payload) {
    const API_URL = `${this.apiUrlv2}/users/mergeVideoAndAudio`;
    return this.http.post<any>(API_URL, payload);
  }

  fetchStreamOfFile(payload) {
    const API_URL = `${environment.API_URL}/auth/google/drive/downloadFile`;
    return this.http.post<any>(API_URL, payload, {responseType: 'blob' as 'json'});
  }

  updateAiGeneratedScript(payload) {
    const API_URL = `${this.apiUrlv2}/users/updateAIScript`;
    return this.http.post<any>(API_URL, payload);
  }

  refreshDropBoxToken(payload) {
    const API_URL = `${this.apiUrlv2}/auth/dropbox/refreshToken`;
    return this.http.post<any>(API_URL, payload);
  }

  cloneCommunity(payload) {
    const API_URL = `${this.apiUrlv2}/users/cloneCommunity`;
    return this.http.post<any>(API_URL, payload);
  }

  getFeaturedGroups(page:number) {
    const API_URL = `${this.apiUrlv2}/users/getFeaturedGroups?page=${page}&limit=12`;
    return this.http.get<any>(API_URL);
  }

  requestFeaturedGroupInfo(data:any) {
    const API_URL = `${this.apiUrlv2}/users/requestFeaturedGroupInfo`;
    return this.http.post<any>(API_URL, data);
  }

  getGroupAdmins(groupId: any) {
    const API_URL = `${this.apiUrlv2}/users/getGroupAdmins?groupId=${groupId}`;
    return this.http.get<any>(API_URL);
  }

  validateShoppingCart(shoppingCartId: any) {
    const API_URL = `${this.apiUrlv2}/users/validateShoppingCart/${shoppingCartId}`;
    return this.http.post<any>(API_URL, shoppingCartId);
  }

  updateVideoDuration(data: any) {
    const API_URL = `${this.apiUrlv2}/users/updateVideoDuration`;
    return this.http.post<any>(API_URL, data);
  }

  setPinnedGroupMessage(data: any) {
    const API_URL = `${this.apiUrlv2}/users/setPinnedGroupMessage`;
    return this.http.post<any>(API_URL, data);
  }

  removePinnedGroupMessage(data: any) {
    const API_URL = `${this.apiUrlv2}/users/removePinnedGroupMessage`;
    return this.http.post<any>(API_URL, data);
  }

  setPinnedVideoMessage(data: any) {
    const API_URL = `${this.apiUrlv2}/users/setPinnedVideoMessage`;
    return this.http.post<any>(API_URL, data);
  }

  removePinnedVideoMessage(data: any) {
    const API_URL = `${this.apiUrlv2}/users/removePinnedVideoMessage`;
    return this.http.post<any>(API_URL, data);
  }

  getFolderInfo(data: any) {
    const API_URL = `${this.apiUrlv2}/users/getFolderInfo`;
    return this.http.get<any>(API_URL, {params: data});
  }
  
  // generateRssFeed(data: any) {
  //   const API_URL = `${this.apiUrlv2}/blog/generateRssFeed`;
  //   return this.http.get<any>(API_URL, {params: data});
  // }
  
  generateRssFeedWithMulter(data: any) {
    const API_URL = `${this.apiUrlv2}/blog/generateRssFeedWithMulter`;
    return this.http.get<any>(API_URL, {params: data});
  }
}


// Custom brand apis
export class TreeData {
  data: any;
  media: any;
  url: any;
  name: any;
  actions: any;
  progress: any;
  output: any;
  project: any;

}
