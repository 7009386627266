import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Chart, ChartConfiguration, ChartItem, registerables } from 'chart.js';
import { MinuteSecondsPipe } from 'src/app/pipes/pipeFormatSecond';
import { CrudService } from 'src/app/services/crud.service';

declare var $: any;
declare var d3;

interface ModalData {
  topChoices: any
}

@Component({
  selector: 'app-analytics-modal',
  templateUrl: './analytics-modal.component.html',
  styleUrls: ['./analytics-modal.component.css']
})
export class AnalyticsModalComponent implements OnInit {

  timeFrameOptions = [
    {
      label: 'YTD',
      value: 'YTD'
    },
    {
      label: '7 days',
      value: '7'
    },
    {
      label: '14 days',
      value: '14'
    },
    {
      label: '30 days',
      value: '30'
    },
    {
      label: '3 months',
      value: '90'
    },
    {
      label: '6 months',
      value: '180'
    },
    {
      label: '12 months',
      value: '360'
    },
    {
      label: '13 months',
      value: '390'
    }
  ];
  isOpenTimeFrame: boolean = false;
  isOpenOverAll: boolean = true;
  selectedTime: string = 'YTD';
  selectedTimelineView: string = 'YTD';

  childVideosArray = [];
  createInteractiveVideoObj: any = {
    conclusionLinks: [],
    finalObjTree: [],
    spinner: false,
    pathways: [],
    finalObj: [],
    currentObj: {}
  };
  mainParentNode;

  private zoomListener: any;
  currentHeight: any;
  private visRef: any;
  footerSection: any;
  buttonView: any;
  zoomBtnPosition: any;
  dragDropNode = false;
  nodes = [];
  latestNodeId: string;
  private rootInitialPosition: any;
  rect: any = null;
  rangeValue = 1;
  maxRangeValue = 2;
  minRangeValue = 0.1;
  rangeValueToolTip = 1;
  analyticsData: any;
  mainId: string;
  reactionNumberOptions = [];
  completedVideoPathways = [];
  chartData = [];
  topChoicesChartData = [];
  tree:any = []
  heightOfText = null;
  nodeHoveredArr = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
    private minuteSecondsPipe: MinuteSecondsPipe,
    private urlService: CrudService,
    private datePipe: DatePipe
  ) {
    if(this.data.topChoices) {
      this.analyticsData = this.data.topChoices;
      this.mainId = this.data.topChoices._id;
      this.getMostCommonChoices();
      // this.getCompletedVideoPathways();
      this.getViewingPatterns();

      this.childVideosArray = [];
      this.getChildVideosArray(this.data.topChoices);
      this.createInteractiveVideoObj.conclusionLinks = [];
      this.markNewFlag(this.data.topChoices);

      const treeData = [];
      treeData.push(this.data.topChoices)
      this.createInteractiveVideoObj.finalObjTree = treeData;

      this.getMainVideoInfo();


      this.selectedTime = 'YTD';
      this.selectedTimelineView = 'YTD';
      setTimeout(() => {
        this.tree.map((i)=>{
              if(i._id === treeData[0]._id){
            if(i.questionnaireId){
              treeData[0].questionnaireId = i.questionnaireId
            }
          }
        })
        this.callTree(treeData);
      }, 1500);
      
    }
  }

  ngOnInit(): void {
  }

  collapse(type: string) {
    if(type === 'TIMEFRAME') {
      this.isOpenTimeFrame = !this.isOpenTimeFrame;
    }
    if(type === 'OVERALL') {
      this.isOpenOverAll = !this.isOpenOverAll;
    }

  }

  private _lineChart() {
    Chart.register(...registerables);

    const data = {
      labels: this.chartData.map((i) => this.datePipe.transform(new Date(i.label), "MM-yyyy")),
      datasets: [{
        label: 'Views',
        backgroundColor: '#7A2DF9',
        borderColor: '#7A2DF9',
        data: this.chartData.map((i) => i.value),
      }]
    };

    const options = {
      legend: {
        display: false,
      },
      lineTension: 0.3,
      scales: {
        y: {
          beginAtZero: true,
          display: false
        }
      }
    }

    const config: ChartConfiguration = {
      type: 'line',
      data: data,
      options: options
    }

    const chartItem: ChartItem = document.getElementById('my-chart') as ChartItem;

    new Chart(chartItem, config);

  };

  private _barChart() {
    var chartElement = Chart.getChart("top-choices-chart");
    if (chartElement) {
      chartElement.destroy();
    }

    let that = this;

    Chart.register(...registerables);

    const dataCount = 3;

    const colors = [
      '#800080',
      '#8C00D2',
      '#E7B6FF',
    ];

    const data = {
      labels: this.topChoicesChartData.map((i) => i._id.videoName),
      datasets: [
        {
          label: 'Top 3 Choices',
          data: this.topChoicesChartData.map((i) => i.percentage),
          backgroundColor: Object.values(colors),
        }
      ]
    };

    const config: ChartConfiguration = {
      type: 'bar',
      data: data,
      options: {
        plugins: {
          legend: {
              display: false
          }
        },
        indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each horizontal bar to be 2px wide
        elements: {
          bar: {
            borderWidth: 1,
          }
        },
        responsive: true,
        events: ['mouseout', 'mousemove'],
        onHover: function(event, elements) {
          for (const i of that.topChoicesChartData) {
            $(`rect[data-id=${i._id.videoID}]`).attr('fill', '#fff');
          }
          if (elements.length > 0) {
            var hoveredElement = elements[0];
            var dataIndex = hoveredElement.index;
            const elementColor = colors[dataIndex];
            var value = that.topChoicesChartData[dataIndex];

            $(`rect[data-id=${value._id.videoID}]`).attr('fill', '#fff');
            if (elementColor) {
              $(`rect[data-id=${value._id.videoID}]`).attr('fill', elementColor);
            }
          } else {
            for (const i of that.topChoicesChartData) {
              $(`rect[data-id=${i._id.videoID}]`).attr('fill', '#fff');
            }
          }
        }
      },
    };

    const chartItem: ChartItem = document.getElementById('top-choices-chart') as ChartItem;

    new Chart(chartItem, config);

  }

  getCurrentWidth() {
    const width = window.innerWidth;
    if (width < 991) {
      this.footerSection = 'none';
      this.buttonView = 'block';
      this.zoomBtnPosition = '115px';
    } else {
      this.footerSection = 'block';
      this.buttonView = 'none';
      this.zoomBtnPosition = '70px';
    }
    return width;
  }

  getMostCommonChoices() {
    this.urlService.getMostCommonChoices(this.mainId, this.selectedTime).subscribe((res:any) => {
      this.topChoicesChartData = res.data;
      this._barChart();
    }, (err) => {
    });
  }

  getMainVideoInfo() {
    this.urlService.getVideoInfov2(this.mainId).subscribe((res) => {
      this.createInteractiveVideoObj.currentObj = res.data.videoinfo;
      this.getAllChildVideos();
    }, (err) => {
    });
  }

  getViewingPatterns() {
    this.urlService.getViewingPatterns(this.mainId).subscribe((res:any) => {
      this.chartData = res.data;
      this._lineChart();
    }, (err) => {
    });
  }

 
  getAllChildVideos() {
    this.urlService.getChildVideosAll(this.mainId).subscribe((success) => {
      this.tree = [];
      this.tree.push(this.createInteractiveVideoObj.currentObj);
      for (const i of success.data) {
        this.tree.push(i);
      }
      this.createInteractiveVideoObj.finalObj = this.tree;
      this.getVideoPathWays();
    });
  }

  getVideoPathWays() {
    this.urlService.getPathway({videoId: this.mainId})
      .subscribe((res) => {
          this.createInteractiveVideoObj.pathways = res.data;
          for (const watchPath of this.createInteractiveVideoObj.pathways) {
              watchPath.startNode = this.createInteractiveVideoObj.finalObj.find(x => x._id === watchPath.startNodeId);
              watchPath.endNode = this.createInteractiveVideoObj.finalObj.find(x => x._id === watchPath.endNodeId);
          }
          this.getCompletedVideoPathways();
      });
  }

  getCompletedVideoPathways() {
    this.urlService.getCompletedVideoPathways(this.mainId, this.selectedTime).subscribe((res:any) => {
      this.completedVideoPathways = res.data;
      
      if(this.completedVideoPathways && this.completedVideoPathways.length > 0) {
        this.completedVideoPathways.forEach(element => {
          let path = this.createInteractiveVideoObj.pathways.find((i) => i._id === element._id)
          if(path) {
            element['pathwaysData'] = path;
          }
        });
      }
    }, (err) => {
    });
  }

  handleActiveWatchPath(activePath?) {
    this.createInteractiveVideoObj.activePathNodes = activePath?.pathNodes || [];
    $(`rect`).removeClass('active-pathways');
    $(`#tree-view`).removeClass('pathways');
    if (this.createInteractiveVideoObj.activePathNodes && this.createInteractiveVideoObj.activePathNodes.length) {
      for (const i of this.createInteractiveVideoObj.activePathNodes) {
        $(`rect[data-id=${i}]`).addClass('active-pathways');
      }
      $(`#tree-view`).addClass('pathways');
      return;
    }
  }

  // onHover(item?) {
  //   $(`rect`).removeClass('most-common-choice');
  //   if (item) {
  //     $(`rect[data-id=${item._id.videoID}]`).addClass('most-common-choice');
  //     return;
  //   }
  // }

  callTree(data, id = "tree-view") {
    // console.log("data", data);
    
    if (this.createInteractiveVideoObj.interactiveStep === 'two') {
      return;
    }
    const a = this.getCurrentWidth();
    $(`#${id} svg`).remove();
    this.createInteractiveVideoObj.spinner = true;
    this.createInteractiveVideoObj.interactiveStep = 'two';
    setTimeout(() => {
      const that = this;
      if (a < 991) {
        this.currentHeight = $(window).height();
      } else {
        this.currentHeight = $(window).height() - 250;
      }

      const m = [20, 20, 20, 20];
      const w = $(window).width();
      let h = this.currentHeight;
      let i = 0;

      const x = d3.scale.linear().domain([0, w]).range([0, w]);
      const y = d3.scale.linear().domain([0, h]).range([0, h]);
      let root;

      that.zoomListener = d3.behavior.zoom().x(x).y(y).scaleExtent([0.1, 10]).on('zoom', zoom);

      const vis = d3
        .select(`#${id}`)
        .append('svg:svg')
        .attr('viewBox', `0 150 ${w} ${h}`)
        .attr('class', 'svgViewBox w-100')
        .attr('width', '100vw')
        .attr('height', '70vh')
        .attr('xmlns', 'http://www.w3.org/2000/svg')
        .append('svg:g')
        .attr('id', 'rootg')
        .call(that.zoomListener);

      this.visRef = vis;

      vis.append('defs').append('marker')
        .attr('id', 'arrow')
        .attr('viewBox', '0 -5 10 10')
        .attr('refX', 10)
        .attr('refY', 0)
        .attr('markerWidth', 6)
        .attr('markerHeight', 6)
        .attr('orient', 'auto')
        .append('path')
        .attr('d', 'M0,-5L10,0L0,5');

      vis
        .append('rect')
        .attr('class', 'overlay')
        .attr('width', w + m[1] + m[3])
        .attr('height', h + m[0] + m[2])
        .attr('opacity', 0)
        .attr('fill', '#eee');

      let tree = d3.layout
        .tree()
        .size([h, w])
        .nodeSize([200 + 10, 700 + 10])
        .separation(() => {
            return 0.5;
        });

      const diagonal = d3.svg.diagonal().projection((d) => {
        return [d.x, d.y];
      });

      root = data[0];
      root.x0 = h / 2;
      root.y0 = 0;

      this.visRef.attr('class', this.dragDropNode ? 'rearrange' : '');

      update(root);

      function update(source) {
        i = 0;
        const duration = d3.event && d3.event.altKey ? 5000 : 500;
        const levelWidth = [1];
        const childCount = (level, n) => {

          if (n.children && n.children.length > 0) {
            if (levelWidth.length <= level + 1) levelWidth.push(0);

            levelWidth[level + 1] += n.children.length;
            n.children.forEach(d => {
              childCount(level + 1, d);
            });
          }

        };

        childCount(0, root);
        //WC 12/02/23 --- Resize the level width to make the tree half the size to fit the space better
        //h = (d3.max(levelWidth) + 1) * 175;
        h = (d3.max(levelWidth) + 1) * 80;

        const ww = (levelWidth.length + 1) * 250;
        tree = tree.size([ww, h]);
        vis.select('rect.overlay')
          // .attr('height', h)
          .attr('width', ww)

        // Compute the new tree layout.
        const nodes = tree.nodes(root).reverse();

        // Normalize for fixed-depth.
        //WC 12/02/23 --- Resize the level width to make the tree half the size to fit the space better
        //nodes.forEach((d) => d.y = d.depth * 250);
        nodes.forEach((d) => d.y = d.depth * 150);

        // Update the nodes...
        const node = vis.selectAll('g.node').data(nodes, (d) => {
          return (d.id = ++i);
        });

        // Enter any new nodes at the parent's previous position.
        const nodeEnter = node
          .enter()
          .append('svg:g')
          .attr('class', 'node')
          .attr('id', (d) => 'node-' + d.id)
          .attr('transform', (d) => 'translate(' + source.y0 + ',' + source.x0 + ')')
          .style('cursor', 'pointer')
          // Existing mouse enter
          // .on('mouseenter', function (d) {
          //   that.heightOfText = null;
          //   const self  = d3.select(this);
          //   d3.selectAll('.popover').remove();
          //   getPopOverOptions(d);

          //   function getPopOverOptions(d) {              
          //     let options = [];

          //     that.urlService.getNodeTopChoices(d._id, d.type, that.selectedTime).subscribe((success) => {
          //       that.heightOfText = null;
          //       if(success.data.length) {
          //         const selectedNode = success.data.find((node) => node._id === d._id);
          //         const node = success.data[0];
          //         if(selectedNode) {
          //           options.push({label: selectedNode.name, count: 'Name', fontWeight: 600});
          //           options.push({label: 'Views', count: node.viewCount ? node.viewCount : 0});
          //           options.push({label: 'Duration', count: that.minuteSecondsPipe.transform(node.duration)});
          //           options.push({label: 'Avg watch time', count: that.minuteSecondsPipe.transform(node.averageWatchDuration)});
          //           //console.log("d.questionnaireId = ", d.questionnaireId);
          //           if(d.questionnaireId) {
          //             //options.push({label: 'Questionnaire Answered', count: d.questionnaireResults.length()});
          //             options.push({label: 'Completed Questionnaires', count: node.questionnaireAnswersCount});
          //           }
          //           options.push({label: 'Social Interaction Numbers', iconHtml: `
          //             <div class="d-flex gap-2" style="color: #7A2DF9;">
          //               <div class="text-center" title="Emphasize Count">
          //                 <i class="fa fa-info-circle" style="font-size: 22px"></i>
          //                 <p style="font-size: 20px">${node.emphasezeCount ? node.emphasezeCount : 0}</p>
          //               </div>
          //               <div class="text-center" title="Question Count">
          //                 <i class="fa fa-question-circle" style="font-size: 22px"></i>
          //                 <p style="font-size: 20px">${node.questionCount ? node.questionCount : 0}</p>
          //               </div>
          //               <div class="text-center" title="Comment Count">
          //                 <i class="fa fa-comment" style="font-size: 22px"></i>
          //                 <p style="font-size: 20px">${node.commentCount ? node.commentCount : 0}</p>
          //               </div>
          //               <div class="text-center" title="Share Count">
          //                 <i class="fa fa-share-alt" style="font-size: 22px"></i>
          //                 <p style="font-size: 20px">${node.shareCount ? node.shareCount : 0}</p>
          //               </div>
          //               <div class="text-center" title="Collection Count">
          //                 <i class="fa fa-clone" style="font-size: 22px"></i>
          //                 <p style="font-size: 20px">${node.collectionCount ? node.collectionCount : 0}</p>
          //               </div>
          //             </div>
          //           `});
          //           that.reactionNumberOptions = options;
          //           showPopOver(self);
          //         }
          //       }
          //     })
          //   }
    
          //   function showPopOver(self) {
          //     d3.selectAll('.popover').remove();
          //     // Show popover on hover
          //     const popover = self.append('g')
          //       .attr('class', 'popover')
          //       .style('pointer-events', 'all')
          //       .attr('transform', `translate(50, 0)`);
        
          //     const popOverRect = popover.append('rect')
          //       .attr('width', 350)
          //       .attr('fill', '#FDFDFD')
          //       .attr('stroke', '#DDD')
          //       .attr('rx', 10);
  
          //     const tooltipContent = popover.append('g')
          //       .attr('class', 'tooltip-content');
  
          //     const verticalOffset = 10;
          
          //     that.reactionNumberOptions.forEach((option, index) => {
          //       let yPoint = that.heightOfText ? that.heightOfText : 20;
          //       const optionGroup = tooltipContent.append('g')
          //         .attr('transform', `translate(${option.label === 'Social Interaction Numbers' ? 100 : 160}, ${yPoint})`);
                
          //       // Add text
          //       const textElement = optionGroup.append('text')
          //       if(option.label !== 'Social Interaction Numbers') {
          //         textElement.attr('x', 110)
          //           .attr('y', 15)
          //           //.text(option.count !== 'Name' ? `(${option.count}) ${option.label}` : option.label)
          //           .text(option.count !== 'Name' ? `${option.label} (${option.count})` : option.label)
          //           .attr('text-anchor', 'middle')
          //           .attr('fill', '#7A2DF9')
          //           .attr('style', `font-size: 20px; font-weight: ${option?.fontWeight ? 700 : 400}; font-family: "Roboto";`)
          //           .attr("dy", "0em")
          //           .call(textWrap, 250);
          //       } else {
          //         optionGroup.append('foreignObject')
          //           .attr('width', 150)
          //           .attr('height', 50)
          //           .html(option.iconHtml);
          //       }
                
          //       if (index < that.reactionNumberOptions.length - 1) {
          //         const line = optionGroup.append('line')
          //           .attr('x1', -50)
          //           .attr('y1', option.label === 'Social Interaction Numbers' ? 60 : 30)
          //           .attr('x2', 70)
          //           .attr('y2', option.label === 'Social Interaction Numbers' ? 60 : 30)
          //           .attr('stroke', '#C3C3C3')
          //           .attr('stroke-width', 1);

          //           const titleHeight = textElement.node().getBBox().height;
          //           that.heightOfText = tooltipContent.node().getBBox().height + 20;
          //           if(titleHeight > 23) {
          //             line.attr('y1', titleHeight + 10).attr('y2', titleHeight + 10);
          //             that.heightOfText = that.heightOfText + 22;
          //           }
          //       }
          //     });
          //     const tooltipContentHeight = tooltipContent.node().getBBox().height + 30;
          //     popOverRect.attr('height', tooltipContentHeight)
          //   }
          // })
          .on('mouseenter', function (d) {
            that.heightOfText = null;
            const self  = d3.select(this);
            d3.selectAll('.popover').remove();

            const popover = self.append('g')
              .attr('class', 'popover')
              .style('pointer-events', 'all')
              .attr('transform', `translate(50, 0)`);
      
            const popOverRect = popover.append('rect')
              .attr('width', 350)
              .attr('fill', '#FDFDFD')
              .attr('stroke', '#DDD')
              .attr('rx', 10);

            const tooltipContent = popover.append('g')
              .attr('class', 'tooltip-content');

            getPopOverOptions(d);

            function getPopOverOptions(d) {              
              let currentNode = that.nodeHoveredArr.find((node) => node._id === d._id)
              if(currentNode) {
                createOptionArr(currentNode);
              } else {
                that.createInteractiveVideoObj.spinner = true;
                that.urlService.getNodeTopChoices(d._id, d.type, that.selectedTime).subscribe((success) => {
                  that.heightOfText = null;
                  if(success.data.length) {
                    that.nodeHoveredArr.push(success.data[0]);
                    createOptionArr(success.data[0]);
                    that.createInteractiveVideoObj.spinner = false;
                  }
                })
              }
            }

            function createOptionArr(node) {
              let options = [];

              if(node) {

                //console.log("createOptionArr - node = ", node);

                options.push({label: node.name, count: 'Name', fontWeight: 600});
                // options.push({label: 'Views', count: node.viewCount ? node.viewCount : 0});
                options.push({ label: 'Views', count: node.totalViews ? node.totalViews : 0});
                options.push({label: 'Duration', count: that.minuteSecondsPipe.transform(node.duration)});

                //console.log("createOptionArr - node.averageWatchDuration = ", node.averageWatchDuration);

                const avgWatchDurationRoundUp =  Math.round(parseFloat(node.averageWatchDuration));

                //console.log("createOptionArr - avgWatchDurationRoundUp = ", avgWatchDurationRoundUp);

                
                //options.push({label: 'Avg watch time', count: that.minuteSecondsPipe.transform(node.averageWatchDuration)});
                options.push({label: 'Avg watch time', count: that.minuteSecondsPipe.transform(avgWatchDurationRoundUp)});
                //console.log("d.questionnaireId = ", d.questionnaireId);
                if(d.questionnaireId) {
                  //options.push({label: 'Questionnaire Answered', count: d.questionnaireResults.length()});
                  options.push({label: 'Completed Questionnaires', count: node.questionnaireAnswersCount});
                }
                options.push({label: 'Social Interaction Numbers', iconHtml: `
                  <div class="d-flex gap-2" style="color: #7A2DF9;">
                    <div class="text-center" title="Emphasize Count">
                      <i class="fa fa-info-circle" style="font-size: 22px"></i>
                      <p style="font-size: 20px">${node.emphasezeCount ? node.emphasezeCount : 0}</p>
                    </div>
                    <div class="text-center" title="Question Count">
                      <i class="fa fa-question-circle" style="font-size: 22px"></i>
                      <p style="font-size: 20px">${node.questionCount ? node.questionCount : 0}</p>
                    </div>
                    <div class="text-center" title="Comment Count">
                      <i class="fa fa-comment" style="font-size: 22px"></i>
                      <p style="font-size: 20px">${node.commentCount ? node.commentCount : 0}</p>
                    </div>
                    <div class="text-center" title="Share Count">
                      <i class="fa fa-share-alt" style="font-size: 22px"></i>
                      <p style="font-size: 20px">${node.shareCount ? node.shareCount : 0}</p>
                    </div>
                    <div class="text-center" title="Collection Count">
                      <i class="fa fa-clone" style="font-size: 22px"></i>
                      <p style="font-size: 20px">${node.collectionCount ? node.collectionCount : 0}</p>
                    </div>
                  </div>
                `});
                that.reactionNumberOptions = options;
                showPopOver();
              }
            }
    
            function showPopOver() {
              that.reactionNumberOptions.forEach((option, index) => {
                let yPoint = that.heightOfText ? that.heightOfText : 20;
                const optionGroup = tooltipContent.append('g')
                  .attr('transform', `translate(${option.label === 'Social Interaction Numbers' ? 100 : 160}, ${yPoint})`);
                
                // Add text
                const textElement = optionGroup.append('text')
                if(option.label !== 'Social Interaction Numbers') {
                  textElement.attr('x', 110)
                    .attr('y', 15)
                    //.text(option.count !== 'Name' ? `(${option.count}) ${option.label}` : option.label)
                    .text(option.count !== 'Name' ? `${option.label} (${option.count})` : option.label)
                    .attr('text-anchor', 'middle')
                    .attr('fill', '#7A2DF9')
                    .attr('style', `font-size: 20px; font-weight: ${option?.fontWeight ? 700 : 400}; font-family: "Roboto";`)
                    .attr("dy", "0em")
                    .call(textWrap, 250);
                } else {
                  optionGroup.append('foreignObject')
                    .attr('width', 150)
                    .attr('height', 50)
                    .html(option.iconHtml);
                }
                
                if (index < that.reactionNumberOptions.length - 1) {
                  const line = optionGroup.append('line')
                    .attr('x1', -50)
                    .attr('y1', option.label === 'Social Interaction Numbers' ? 60 : 30)
                    .attr('x2', 70)
                    .attr('y2', option.label === 'Social Interaction Numbers' ? 60 : 30)
                    .attr('stroke', '#C3C3C3')
                    .attr('stroke-width', 1);

                  const titleHeight = textElement.node().getBBox().height;
                  that.heightOfText = tooltipContent.node().getBBox().height + 20;
                  if(titleHeight > 23) {
                    line.attr('y1', titleHeight + 10).attr('y2', titleHeight + 10);
                    that.heightOfText = that.heightOfText + 22;
                  }
                }
              });
              const tooltipContentHeight = tooltipContent.node().getBBox().height + 30;
              popOverRect.attr('height', tooltipContentHeight)
            }
          })
          .on('mouseleave', function () {
            d3.select(this)
              .select('.popover')
              .remove();
            d3.selectAll('.popover').remove();
            that.reactionNumberOptions = [];
            that.heightOfText = null;
          });

        function textWrap(text, width) {
          text.each(function() {
            var text = d3.select(this), title = text.text(), hasWhitespace = /\s/.test(title);
    
            if (hasWhitespace) {
              var text = d3.select(this),
              words = text.text().split(/\s+/).reverse(),
              word,
              line = [],
              lineNumber = 0,
              lineHeight = 1.5,
              y = text.attr("y"),
              dy = parseFloat(text.attr("dy")),
              tspan = text.text(null).append("tspan").attr("x", 10).attr("y", y).attr("dy", dy + "em").attr('text-anchor', 'middle');
              while (word = words.pop()) {
                line.push(word);
                tspan.text(line.join(" "));
                if (word.length > 18) {
                  var words = title.split("").reverse(),
                  line = [],
                  tspan = text.text(null).append("tspan").attr("x", 10).attr("y", y).attr("dy", dy + "em").attr('text-anchor', 'middle');
  
                  while (word = words.pop()) {
                    line.push(word);
                    tspan.text(line.join(""));
    
                    if (tspan.node().getComputedTextLength() > width) {
                      line.pop();
                      tspan.text(line.join(""));
                      line = [word];
                      tspan = text.append("tspan").attr("x", 10).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word).attr('text-anchor', 'middle');
                    }
                  }
                } else if (tspan.node().getComputedTextLength() > width) {
                  line.pop();
                  tspan.text(line.join(" "));
                  line = [word];
                  tspan = text.append("tspan").attr("x", 10).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word).attr('text-anchor', 'middle');
                }
              }
            } else {
              var words = title.split("").reverse(),
                  word,
                  line = [],
                  lineNumber = 0,
                  lineHeight = 1.5,
                  y = text.attr("y"),
                  dy = parseFloat(text.attr("dy")),
                  tspan = text.text(null).append("tspan").attr("x", 10).attr("y", y).attr("dy", dy + "em").attr('text-anchor', 'middle');
  
              while (word = words.pop()) {
                  line.push(word);
                  tspan.text(line.join(""));
  
                  if (tspan.node().getComputedTextLength() > width) {
                      line.pop();
                      tspan.text(line.join(""));
                      line = [word];
                      tspan = text.append("tspan").attr("x", 10).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word).attr('text-anchor', 'middle');
                  }
              }
            }
          });
        }

        // Update the nodes...
        vis.selectAll('g.node').data(nodes, (d) => {
          return (d.ele = this);
        });

        nodeEnter.append('svg:circle')
          .attr('r', 1e-6)
          .style('fill', (d) => {
            return d._children ? '#000' : '#000';
          })

        nodeEnter.append('rect')
          .attr('joint-selector', 'body')
          .attr('fill', 'rgba(255,255,255,0)')
          .attr('width', 140)
          .attr('height', 130)
          .attr('y', -60)
          .attr('x', -60);

        d3.selectAll('.popover').remove();
        
        nodeEnter.append('rect')
          .attr('joint-selector', 'body')
          .attr('fill', '#fff')
          .attr('width', '100')
          .attr('height', '82')
          .attr('stroke', '#ccc')
          .attr('y', -40)
          .attr('x', -40)
          .attr('rx', '8')
          .attr('ry', '8')
          .attr('id', (d) => 'node-button-' + d.id)
          .attr('data-id', (d) => d._id)
          .attr('class', (d) => {
            return `${d.children && d.children.length >= 4 ? 'not-droppable' : 'droppable'}`
          });

        const dragListener = d3.behavior.drag().on('dragstart', dragstart).on('drag', drag).on('dragend', dragEnd);
        nodeEnter.call(dragListener)

        const imgGroup = nodeEnter.append('g');

        imgGroup.append('image')
          .attr('y', -30)
          .attr('x', -35)
          .attr('clip-path', 'inset(0% round 8px)')
          .attr('xlink:href', (d) => d.poster)
          .attr('class', (d) => d.basck);

        imgGroup.append('image')
          .attr('y', -30)
          .attr('x', -5)
          .attr('clip-path', 'inset(0% round 8px)')
          .attr('cursor', 'pointer')
          .attr('class', (d) => d.imvd)
          .attr('xlink:href', (d) => d.poster1);

        const title = nodeEnter
          .append('text')
          .attr('y', 30)
          .attr('x', 10);

        title.append('tspan')
          .attr('text-anchor', 'middle')
          .text(d => d.name)
          .each(wrap);


        // questionnaire image
        const quesImgGroup = nodeEnter.append('svg:g')
          .attr('transform', 'matrix(1,0,0,1,45,-50)')
          .attr('fill', 'mediumpurple')
          .attr('cursor', 'pointer')
          .attr('class', 'node-action')
          .attr('visibility', (d) => {
            return `${d.questionnaireId ? 'visible' : 'hidden'}`
          });

        quesImgGroup.append('rect')
          .attr('rx', 6)
          .attr('ry', 6)
          .attr('height', 25)
          .attr('width', 25);

        quesImgGroup.append('image')
          .attr('x', 3)
          .attr('y', 3)
          .attr('height', 19)
          .attr('width', 19)
          .attr('xlink:href', 'assets/images/questionnaire.png')
        
        function wrap() {
          const self = d3.select(this);
          let textLength = self.node().getComputedTextLength();
          let text = self.text();
          while (textLength > (100 - 2 * 10) && text.length > 0) {
            text = text.slice(0, -1);
            self.text(text + '...');
            textLength = self.node().getComputedTextLength();
          }
        }

        // Transition nodes to their new position.
        const nodeUpdate = node
          .transition()
          .duration(duration)
          .attr('transform', (d) => {
            return 'translate(' + (d.x - 8) + ',' + d.y + ')';
          });

        nodeUpdate
          .select('circle')
          .attr('r', 4.5)
          .style('fill', (d) => {
            return d._children ? '#000' : '#000';
          });

        nodeUpdate.select('text').style('fill-opacity', 1);

        // Transition exiting nodes to the parent's new position.
        const nodeExit = node
          .exit()
          .transition()
          .duration(duration)
          .attr('transform', (d) => {
            return 'translate(' + source.y + ',' + source.x + ')';
          })
          .remove();

        nodeExit.select('circle').attr('r', 1e-6);
        nodeExit.select('text').style('fill-opacity', 1e-6);

        // Update the links...
        const jsonLinks = tree.links(nodes);
        for (const conclusionLink of that.createInteractiveVideoObj.conclusionLinks) {
          jsonLinks.push(conclusionLink)
        }
        vis.selectAll('path.link').remove();
        const link = vis.selectAll('path.link').data(jsonLinks);

        // Enter any new links at hte parent's previous position
        link
          .enter()
          .insert('svg:path', 'g')
          .attr('class', (d) => {
            return 'link' + (d.type ? (' ' + d.type) : '');
          })
          .attr('d', (d) => {
            const o = {
              x: source.x0,
              y: source.y0,
            };
            return diagonal({
              source: o,
              target: o,
            });
          })

        // Transition links to their new position.
        link.transition().duration(duration)
        .attr('d', (d) => {
          let sourceX = d.source.x;
          let sourceY = d.source.y;

          let targetX = d.target.x;
          let targetY = d.target.y - 40;

          if(d.source.y > d.target.y) {
              targetX = (d.target.x + 10);
              targetY = (d.target.y + 42);
          }

          if(d.source.y === d.target.y) {
              targetX = (d.target.x - 50);
              targetY = (d.target.y + 45);
          }

          return diagonal({
              source: { x: sourceX, y: sourceY },
              target: { x: targetX, y: targetY },
          });
        })
        .attr('marker-end', 'url(#arrow)');;

        // Transition exiting nodes to the parent's new position.
        link
          .exit()
          .transition()
          .duration(duration)
          .attr('d', (d) => {
            const o = {
              x: source.x,
              y: source.y,
            };
            return diagonal({
              source: o,
              target: o,
            });
          })
          .remove();

        // Stash the old positions for transition.
        nodes.forEach((d) => {
          d.x0 = d.x;
          d.y0 = d.y;
        });

        that.nodes = nodes;

        if(!that.rect) {
          const rootG = document.getElementById('rootg');
          const rect = rootG.getBoundingClientRect();
          that.rect = rect;
        }
        const svgWidth = window.innerWidth;
        const svgHeight = window.innerHeight;

        // Updated calculateTranslation function
        const calculateTranslation = (currentNode) => {
          const xAxis = svgWidth / 2 - that.rect.width / 1.2;  // Center horizontally
          const yAxis = svgHeight / 6; // Center vertically
          return [xAxis, yAxis];
        }

        let trans;
        if (that.latestNodeId) {
          const latestNodeItem = that.getLatestItem(nodes, that.latestNodeId);
          const latestNode = document.getElementById(`node-button-${latestNodeItem[0]?.id}`);
          if (latestNode) {
            latestNode.style.fill = '#d3c1eb';
          }
          trans = calculateTranslation(latestNodeItem);
        } else {
          const latestNodeItem = that.getLatestItem(nodes, root._id);
          trans = calculateTranslation(latestNodeItem);
        }
        if (!that.rootInitialPosition) {
          that.rootInitialPosition = trans;
        }
        that.visRef.attr('transform', 'translate(' + trans + ') scale(1)');
        that.zoomListener.translate(trans);
      }

      const panSpeed = 100;
      const panBoundary = 10;
      let panTimer;
      let translateCoords;
      let translateX;
      let translateY;
      let dragStarted = false;
      let nodesChild;
      let selectedNode = null
      let draggingNode = null;
      let domNode;

      function dragstart(d) {
        if (!that.dragDropNode) {
          return;
        }
        if (d === root) {
          return;
        }
        dragStarted = true;
        nodesChild = tree.nodes(d);
        d3.event.sourceEvent.stopPropagation();
        d3.select(this).classed('fixed', d.fixed = true);
      }

      function pan(domNode1, direction) {
        const speed = panSpeed;
        if (panTimer) {
          clearTimeout(panTimer);
          translateCoords = d3.transform(vis.attr('transform'));
          if (direction === 'left' || direction === 'right') {
            translateX = direction === 'left' ? translateCoords.translate[0] + speed : translateCoords.translate[0] - speed;
            translateY = translateCoords.translate[1];
          } else if (direction === 'up' || direction === 'down') {
            translateX = translateCoords.translate[0];
            translateY = direction === 'up' ? translateCoords.translate[1] + speed : translateCoords.translate[1] - speed;
          }
          const scaleX = translateCoords.scale[0];
          const scaleY = translateCoords.scale[1];
          const scale = that.zoomListener.scale();
          vis.transition().attr('transform', 'translate(' + translateX + ',' + translateY + ')scale(' + scale + ')');
          d3.select(domNode1).select('g.node').attr('transform', 'translate(' + translateX + ',' + translateY + ')');
          panTimer = setTimeout(() => {
            pan(domNode1, direction);
          }, 50);
        }
      }

      function drag(d) {
        if (!that.dragDropNode) {
          return;
        }
        if (d === root) {
          return;
        }
        if (!dragStarted) {
          d.x0 += d3.event.dy;
          d.y0 += d3.event.dx;
        }
        if (dragStarted) {
          initiateDrag(d, this);
        }

        // get coords of mouseEvent relative to svg container to allow for panning
        const relCoords = d3.mouse($(`#${id}`).get(0));
        if (relCoords[1] < panBoundary) {
          panTimer = true;
          pan(this, 'up');
        } else if (relCoords[1] > ($(`#${id} svg`).height() - panBoundary)) {
          panTimer = true;
          pan(this, 'down');
        } else {
          try {
            clearTimeout(panTimer);
          } catch (e) {
          }
        }

        const node1 = d3.select(this);
        node1.attr('transform', 'translate(' + d.x0 + ',' + d.y0 + ')');

      }

      function initiateDrag(d, domNode) {
        draggingNode = d;

        d3.select(domNode).select(`#node-button-${d.id}`).attr('class', 'active-drag');

        vis.selectAll('g.node').sort((a, b) => {
          if (a.id !== draggingNode.id) return 1;
          else return -1;
        });

        // remove parent link
        vis.selectAll('path.link').filter((d, i) => {
          return d.target.id === draggingNode.id;
        }).remove();

        dragStarted = false;
      }

      function dragEnd(d) {
        clearTimeout(panTimer);
        if (!that.dragDropNode) {
          return;
        }
        if (d === root) {
          return;
        }
        domNode = this;
        if (selectedNode) {
          if (!selectedNode.children) {
            selectedNode.children = [];
          }
          if (!selectedNode.isEndingNode && selectedNode.children.length < 4) {
            // now remove the element from the parent, and insert it into the new elements children
            const index = draggingNode.parent.children.indexOf(draggingNode);
            if (index > -1) {
              draggingNode.parent.children.splice(index, 1);
            }
            if (typeof selectedNode.children !== 'undefined' || typeof selectedNode._children !== 'undefined') {
              if (typeof selectedNode.children !== 'undefined') {
                selectedNode.children.push(draggingNode);
              } else {
                selectedNode._children.push(draggingNode);
              }
            } else {
              selectedNode.children = [];
              selectedNode.children.push(draggingNode);
            }
          } else {
            endDrag();
          }
        } else {
          endDrag();
        }
      }

      function endDrag() {
        selectedNode = null;
        d3.selectAll('.ghostCircle').attr('class', 'ghostCircle');
        d3.select(domNode).attr('class', 'node');
        // now restore the mouseover event or we won't be able to drag a 2nd time
        d3.select(domNode).select('.ghostCircle').attr('pointer-events', '');
        if (draggingNode !== null) {
          update(root);
          draggingNode = null;
          $(`.node button`).removeClass('drag-node');
        }
      }

      // zoom in / out
      function zoom() {
        clearTimeout(panTimer);

        const scale = d3.event.scale;
        const eTransform = d3.transform(vis.attr('transform'));
        const nTranslateX = (eTransform.translate[0] + d3.event.translate[0] * scale) / 2;
        const nTranslateY = (eTransform.translate[1] + d3.event.translate[1] * scale) / 2;
        that.visRef.attr('transform', 'translate(' + [nTranslateX, nTranslateY] + ') scale(' + d3.event.scale + ')');

        that.rangeValue = d3.event.scale;
      }

      this.createInteractiveVideoObj.selectedVideoCheck = false;
      this.createInteractiveVideoObj.interactiveStep = 'three';
      this.createInteractiveVideoObj.spinner = false;
    },500);
  }

  getChildVideosArray(videos) {
    if(videos.children && videos.children.length > 0) {
      videos.children.forEach((child: any) => {
        this.childVideosArray.push(child);
        this.getChildVideosArray(child);
      });
    }
  }

  markNewFlag(node) {
    if (node.type == "main")
      this.mainParentNode = node;

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        if (!!!child.URL) {
          child.poster1 = 'assets/images/H-vidcam.svg';
          child.basck = 'gt';
          child.imvd = 'imvd';
        } else {
            child.basck = 'videochart';
            child.imvd = 'ncs';
        }
        this.markNewFlag(child)
        if (child.parentIds && child.parentIds.length > 0) {
          for (const parentId of child.parentIds) {
            const parentData = this.childVideosArray.find((i) => i._id === parentId)
            if (parentData) {

              this.createInteractiveVideoObj.conclusionLinks.push({
                source: parentData,
                target: child,
              });
            } else {
              if (this.mainParentNode._id === parentId) {

                this.createInteractiveVideoObj.conclusionLinks.push({
                  source: this.mainParentNode,
                  target: child,
                });
              }

            }
            continue;
          }
        }
      });
    }
  }

  getLatestItem(nodes, id: string) {
    return nodes.filter(node => node._id === id);
  }

  trackByIndex(index: number): number {
		return index;
	}

  onClickTime(time) {
    this.nodeHoveredArr = [];
    if (time == undefined)
      time = "YTD";

    this.selectedTime = time.value;
    this.selectedTimelineView = time.label;

    this.getMostCommonChoices();
    this.getMainVideoInfo();

    setTimeout(() => {
      this.callTree(this.createInteractiveVideoObj.finalObjTree);
    }, 500);
  }

  decreaseRange() {
    const val = this.rangeValue - 0.1;
    if (val >= this.minRangeValue) {
      this.rangeValue = val;
      this.rangeValueToolTip += 0.1;
      this.performZoom();
    }
  }

  setRange(value) {
    this.rangeValue = value;
    this.performZoom();
  }

  increaseRange() {
    const val = this.rangeValue + 0.1;
    if (val <= this.maxRangeValue) {
      this.rangeValue = val;
      this.rangeValueToolTip -= 0.1;
      this.performZoom();
    }
  }

  performZoom() {
    this.zoomListener.scale(this.rangeValue);
    this.zoomListener.event(this.visRef);
  }
}
