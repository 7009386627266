export default [
    {
        language: 'Afrikaans',
        code: 'af-ZA'
    },
    {
        language: 'Arabic, Gulf',
        code: 'ar-AE'
    },
    {
        language: 'Arabic, Modern Standard',
        code: 'ar-SA'
    },
    {
        language: 'Chinese, Simplified',
        code: 'zh-CN'
    },
    {
        language: 'Chinese, Traditional',
        code: 'zh-TW'
    },
    {
        language: 'Danish',
        code: 'da-DK'
    },
    {
        language: 'Dutch',
        code: 'nl-NL'
    },
    {
        language: 'English, Australian',
        code: 'en-AU'
    },
    {
        language: 'English, British',
        code: 'en-GB'
    },
    {
        language: 'English, Indian',
        code: 'en-IN'
    },
    {
        language: 'English, Irish',
        code: 'en-IE'
    },
    {
        language: 'English, New Zealand',
        code: 'en-NZ'
    },
    {
        language: 'English, Scottish',
        code: 'en-AB'
    },
    {
        language: 'English, South African',
        code: 'en-ZA'
    },
    {
        language: 'English, US',
        code: 'en-US'
    },
    {
        language: 'English, Welsh',
        code: 'en-WL'
    },
    {
        language: 'French',
        code: 'fr-FR'
    },
    {
        language: 'French, Canadian',
        code: 'fr-CA'
    },
    {
        language: 'Farsi',
        code: 'fa-IR'
    },
    {
        language: 'German',
        code: 'de-DE'
    },
    {
        language: 'German, Swiss',
        code: 'de-CH'
    },
    {
        language: 'Hebrew',
        code: 'he-IL'
    },
    {
        language: 'Hindi, Indian',
        code: 'hi-IN'
    },
    {
        language: 'Indonesian',
        code: 'id-ID'
    },
    {
        language: 'Italian',
        code: 'it-IT'
    },
    {
        language: 'Japanese',
        code: 'ja-JP'
    },
    {
        language: 'Korean',
        code: 'ko-KR'
    },
    {
        language: 'Malay',
        code: 'ms-MY'
    },
    {
        language: 'Portuguese',
        code: 'pt-PT'
    },
    {
        language: 'Portuguese, Brazilian',
        code: 'pt-BR'
    },
    {
        language: 'Russian',
        code: 'ru-RU'
    },
    {
        language: 'Spanish',
        code: 'es-ES'
    },
    {
        language: 'Spanish, US',
        code: 'es-US'
    },
    {
        language: 'Swedish',
        code: 'sv-SE'
    },
    {
        language: 'Tamil',
        code: 'ta-IN'
    },
    {
        language: 'Telugu',
        code: 'te-IN'
    },
    {

        language: 'Thai',
        code: 'th-TH'
    },
    {
        language: 'Turkish',
        code: 'tr-TR'
    },
    {
        language: 'Vietnamese',
        code: 'vi-VN'
    }
];
