import {AfterViewChecked, Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CrudService} from 'src/app/services/crud.service';
import {DOCUMENT} from '@angular/common';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $;

@Component({
	selector: 'app-pricing',
	templateUrl: './pricing.component.html',
	styleUrls: ['./pricing.component.css'],
	// animations: [fadeInOutAnimation]
})
export class PricingComponent implements OnInit, AfterViewChecked {

	spinner = false;
	private paymentPriceId: any;
	private isMarketingCampaign: any = false;
	private isPlanSelectionClicked: any = false;
	subscriptionType: string;
	isHideFreemiumCard = false;
	isHideBronzeCard = false;
	isHidePewterCard = false;
	isHideSilverCard = false;
	isHideGoldCard = false;
	currentPlanId = '';
	currentPlanName = '';
	isPlanUpgrade = false;
	currentPricingTab = '';
	currentPlatformSolution = '';


	constructor(private urlService: CrudService,
				private router: Router,
				@Inject(DOCUMENT) private document,
				private route: ActivatedRoute,
				private localStorageService: LocalStorageService,
				private toastr: ToastrService) {
		this.subscriptionType = 'Monthly';

		this.route.queryParams.subscribe(params => {
			// Upgrade subscription
			if (params.subId != undefined) {
				this.localStorageService.setItem('upgrade_CurrentSubscriptionId', params.subId);	//subId is FreeFuse Subscription Id
				this.isPlanUpgrade = true;
			} else if (this.localStorageService.getItem("isSubscriber")) {
				this.isPlanUpgrade = true;
			}
		});
	}

	ngOnInit(): void {

		this.spinner = true;
		//console.log("this.spinner = ", this.spinner);

		if (this.localStorageService.getItem('subscriptionType') === 'Yearly') {
			this.subscriptionType = 'Yearly';
		}

		if (this.localStorageService.getItem('redirectPricingParam') != undefined) {
			this.currentPricingTab = this.localStorageService.getItem('redirectPricingParam');
			//console.log("this.currentPricingTab = ", this.currentPricingTab);
		} else
			this.currentPricingTab = 'individual';

		if (this.localStorageService.getItem('redirectSolutionParam') != undefined) {
			this.currentPlatformSolution = this.localStorageService.getItem('redirectSolutionParam');
			// console.log("this.currentPlatformSolution = ", this.currentPlatformSolution);
		} else
			this.currentPlatformSolution = 'Training and Learning';

		if (this.isPlanUpgrade) {
			this.spinner = true;
			this.urlService.getUserSubscriptionPlan().subscribe(success => {
				if (success.data.subscriptionPlanInfo._id) {
					this.localStorageService.setItem('planId', success.data.subscriptionPlanInfo._id);

					
					this.currentPlanId = success.data.subscriptionPlanInfo._id;


					switch (this.currentPlanId) {
						// Current plan is Monthly Freemium so hide the freemium card
						case '6310da4c16d7473718a9ce79':
							this.isHideFreemiumCard = true;
							// this.currentPricingTab = 'individual';
							this.spinner = false;
							break;

						// Current plan is Yearly Freemium so hide the freemium card
						case '64376b208a0bf123fc0c9ca5':
							this.isHideFreemiumCard = true;
							// this.currentPricingTab = 'individual';
							this.spinner = false;
							break;

						// Current plan is Monthly bronze so hide the freemium
						case '627e7191f76daf5009dc2ae7':
							this.isHideFreemiumCard = true;
							// this.currentPricingTab = 'individual';
							this.spinner = false;
							break;

						// Current plan is Yearly bronze so hide the freemium and bronze card
						case '629e7887467f4115b1f7dbfa':
							this.isHideFreemiumCard = true;
							this.isHideBronzeCard = true;
							// this.currentPricingTab = 'individual';
							this.spinner = false;
							break;

						// Current plan is monthly pewter
						case '643769ae8a0bf123fc0c9ca3':
							this.isHideFreemiumCard = true;
							this.isHideBronzeCard = true;
							this.isHidePewterCard = true;
							// this.currentPricingTab = 'individual';
							this.spinner = false;
							break;

						// Current plan is Yearly pewter so hide the pewter and bronze card
						case '643769908a0bf123fc0c9ca2':
							this.isHideFreemiumCard = true;
							this.isHideBronzeCard = true;
							this.isHidePewterCard = true;
							// this.currentPricingTab = 'business';
							this.spinner = false;
							break;

						// Current plan is monthly silver
						case '627e763ef76daf5009dc2af0':
							this.isHideFreemiumCard = true;
							this.isHideBronzeCard = true;
							this.isHidePewterCard = true;
							// this.currentPricingTab = 'business';
							this.spinner = false;
							break;

						// Current plan is Yearly silver so hide the pewter and bronze card
						case '629e7a2f467f4115b1f7dbfc':
							this.isHideSilverCard = true;
							this.isHidePewterCard = true;
							this.isHideBronzeCard = true;
							this.isHideFreemiumCard = true;
							// this.currentPricingTab = 'business';
							this.spinner = false;
							break;

						// Current plan is monthly gold
						case '627e75fcf76daf5009dc2aef':
							this.isHideSilverCard = true;
							this.isHidePewterCard = true;
							this.isHideBronzeCard = true;
							this.isHideFreemiumCard = true;
							// this.currentPricingTab = 'business';
							this.spinner = false;
							break;

						// Current plan is Yearly gold so hide the gold, bronze and silver cards
						case '629e78dd467f4115b1f7dbfb':
							this.isHideGoldCard = true;
							this.isHideSilverCard = true;
							this.isHidePewterCard = true;
							this.isHideBronzeCard = true;
							this.isHideFreemiumCard = true;
							// this.currentPricingTab = 'business';
							this.spinner = false;
							break;
						
						case '652f5fd53994fe41042638bf':
							this.isHideGoldCard = true;
							this.isHideSilverCard = true;
							this.isHidePewterCard = true;
							this.isHideBronzeCard = true;
							this.isHideFreemiumCard = true;
							// this.currentPricingTab = 'business';
							this.spinner = false;
							break;
					}

					this.currentPlanName = success.data.subscriptionPlanInfo.unit + ' ' + success.data.subscriptionPlanInfo.name;
				}
			})
		} else {
			//console.log("New subscription");

			//New subscription --- not plan upgrade
			this.spinner = true;

			if (this.localStorageService.getItem('planId') != undefined) {
				this.urlService.getPlanInfo(this.localStorageService.getItem('planId')).subscribe(success => {
					this.currentPlanName = success.data.unit + ' ' + success.data.name;

					//this.spinner = false;
					//console.log("new subscription - getPlanInfo, this.spinner = ", this.spinner);
				});
			}

			this.spinner = false;
		}

		if (this.localStorageService.getItem('campaignId') != undefined)
			this.isMarketingCampaign = true;


		// console.log("this.isMarketingCampaign : ", this.isMarketingCampaign);
		const that = this;
		$(document).on('mouseover', '.price .price-card .card', function () {
			if (!that.isPlanSelectionClicked) {
				$('.price .price-card .card').removeClass('border-active');
				$(this).addClass('border-active');
			}
		});

		$(document).on('mouseout', '.price .price-card .card', function () {
			if (!that.isPlanSelectionClicked) {
				$('.price .price-card .card').removeClass('border-active');
				$('.price .price-card .initial-active.card').addClass('border-active');
			}
		});

		$('[data-toggle="tooltip"]').tooltip();

	}

	ngAfterViewChecked(): void {

		$('.price-btn').attr('disabled', false);

		// WC 07312022 - User has selected the plan before being redirected back to the pricing page in the case of
		// non-login user or non-registered user selecting a plan

		//console.log("this.localStorageService.getItem('isPricingRedirect') = ", this.localStorageService.getItem('isPricingRedirect') );
		if (this.localStorageService.getItem('isPricingRedirect') && (this.localStorageService.getItem('planId') != undefined)) {

			this.spinner = true;
			//console.log("in ngAfterViewChecked");

			if (this.localStorageService.getItem('subscriptionType') === 'Yearly') {
				this.subscriptionType = 'Yearly';
			}

			$('#checbox').attr('disabled', true);
			$('.price-btn').attr('disabled', true);


			if (document.getElementById('freemiumCard')) {
				document.getElementById('freemiumCard').classList.remove('initial-active');
				document.getElementById('freemiumCard').classList.remove('border-active');
			};

			if (document.getElementById('bronzeCard')) {
				document.getElementById('bronzeCard').classList.remove('initial-active');
				document.getElementById('bronzeCard').classList.remove('border-active');
			};

			if (document.getElementById('pewterCard')) {
				document.getElementById('pewterCard').classList.remove('initial-active');
				document.getElementById('pewterCard').classList.remove('border-active');
			};

			if (document.getElementById('silverCard')) {
				document.getElementById('silverCard').classList.remove('initial-active');
				document.getElementById('silverCard').classList.remove('border-active');
			};

			if (document.getElementById('goldCard')) {
				document.getElementById('goldCard').classList.remove('initial-active');
				document.getElementById('goldCard').classList.remove('border-active');
			};

			if (document.getElementById('enterpriseCard')) {
				document.getElementById('enterpriseCard').classList.remove('initial-active');
				document.getElementById('enterpriseCard').classList.remove('border-active');
			}

			$('#' + this.localStorageService.getItem('planId')).attr('disabled', false);

			if (this.localStorageService.getItem('planId') == '6310da4c16d7473718a9ce79' || this.localStorageService.getItem('planId') == '64376b208a0bf123fc0c9ca5') {
				// console.log("*** freemiumCard");
				document.getElementById('freemiumCard')?.classList.add('initial-active');
				document.getElementById('freemiumCard')?.classList.add('border-active');
			} else if (this.localStorageService.getItem('planId') == '627e7191f76daf5009dc2ae7' || this.localStorageService.getItem('planId') == '629e7887467f4115b1f7dbfa') {
				// console.log("*** bronzeCard");
				document.getElementById('bronzeCard')?.classList.add('initial-active');
				document.getElementById('bronzeCard')?.classList.add('border-active');
			} else if (this.localStorageService.getItem('planId') == '643769ae8a0bf123fc0c9ca3' || this.localStorageService.getItem('planId') == '643769908a0bf123fc0c9ca2') {
				// console.log("*** pewterCard");
				document.getElementById('pewterCard')?.classList.add('initial-active');
				document.getElementById('pewterCard')?.classList.add('border-active');
			} else if (this.localStorageService.getItem('planId') == '627e763ef76daf5009dc2af0' || this.localStorageService.getItem('planId') == '629e7a2f467f4115b1f7dbfc') {
				// console.log("*** silverCard");
				document.getElementById('silverCard')?.classList.add('initial-active');
				document.getElementById('silverCard')?.classList.add('border-active');
			} else if (this.localStorageService.getItem('planId') == '627e75fcf76daf5009dc2aef' || this.localStorageService.getItem('planId') == '629e78dd467f4115b1f7dbfb' || this.localStorageService.getItem('planId') == '652f5fd53994fe41042638bf') {
				// console.log("*** goldCard");
				document.getElementById('goldCard')?.classList.add('initial-active');
				document.getElementById('goldCard')?.classList.add('border-active');
			}

			try {

				//console.log("Pricing Redirect so clicking the previously selected plan = " +  this.localStorageService.getItem('planId') + " behind the scene")

				//Click the plan behind the scene
				this.document.getElementById(this.localStorageService.getItem('planId')).click();
				
				this.localStorageService.removeItem('planId');
				this.localStorageService.removeItem('subscription');
				this.localStorageService.removeItem('isPricingRedirect');
				this.localStorageService.removeItem('redirectSolutionParam');
				this.localStorageService.removeItem('redirectPricingParam');


			} catch (err) {

				//console.log("ngAfterViewChecked - err = ", err.message);
				this.spinner = false;
			}

		}

	}

	setPlatformSolution(platformSolution: string) {
		this.currentPlatformSolution = platformSolution;
	}

	check() {
		this.subscriptionType = this.subscriptionType === 'Monthly' ? 'Yearly' : 'Monthly';
	}


	setShoppingCart(planIds: any): void {

		this.spinner = true;
		this.isPlanSelectionClicked = true;

		// WC 07312022
		// console.log("*** setShoppingCart - planId", planIds);
		this.localStorageService.setItem('planId', planIds);
		this.localStorageService.setItem('subscriptionType', this.subscriptionType);
		// WC 07312022

		let paymentPriceId = null;
		let zeroPlanSubscription = false;

		this.urlService.getPlanInfo([planIds][0]).subscribe(success => {
			paymentPriceId = success.data.paymentPriceId;
			// console.log("setShoppingCart -  getPlanInfo = ",  success.data);

			if (success.data.price == 0) {
				zeroPlanSubscription = true;
				this.localStorageService.setItem('subscriptionPlanZero', 'true');

				// console.log("setShoppingCart - zeroPlanSubscription = ", zeroPlanSubscription);

			}
		})

		let payload = null;

		//Non-anonymous user
		if (this.localStorageService.getItem('user')) {

			// console.log("*** Checking if shoppingCart exists");

			// Existing shopping cart
			if (this.localStorageService.getItem('shoppingCartId')) {

				payload = {
					shoppingCartId: this.localStorageService.getItem('shoppingCartId'),
					items: [planIds]
				}

				// console.log('Existing shopping cart id : ', this.localStorageService.getItem('shoppingCartId'));

				this.urlService.addShoppingCartItems(payload).subscribe(success => {

					//console.log("Adding a plan to the existing shopping cart");

					//const subscriptionId = this.localStorageService.getItem('subscriptionId');

					// WC 11/15/23 --- Moving this to Checkout to accomodate promo code
					// this.urlService.userDetailsV2().subscribe(success => {

					// 	const payCustomerId = success.data.details.payCustomerId;

					// 	// console.log("success.data.payCustomerId = ", success.data.details.payCustomerId);
					// 	// console.log("this.paymentPriceId = ", paymentPriceId);

					// 	payload = {
					// 		payCustomerId,
					// 		paymentPriceId
					// 	}

					// 	// console.log("createPaySubscription payload: ", JSON.stringify(payload));

					// 	this.urlService.createPaySubscription(payload).subscribe(success => {

					// 		// console.log('createPaySubscription is successful, success.data = ', success);

					// 		this.localStorageService.setItem('subscriptionId', success.data.subscriptionId);
					// 		this.localStorageService.setItem('clientSecret', success.data.clientSecret);

					// 		this.router.navigate(['checkout'], {queryParams: {subscription_clientSecret: success.data.clientSecret}});

					// 		this.spinner = false;
					// 		// console.log("this.spinner = ", this.spinner);
					// 	})
					// })

					this.router.navigate(['checkout']);
					this.spinner = false;

				}, error => this.spinner = false)
			} else {
				//console.log('New Shopping Cart');

				// New shopping cart
				payload = {
					items: [planIds],
					type: 'subscriptionPlan',
					isActive: true
				}

				this.urlService.setShoppingCart(payload).subscribe(success => {

					this.localStorageService.setItem('shoppingCartId', success.data._id);
					// console.log("shoppingCartId from pricing page = ", success.data._id);

					if (zeroPlanSubscription) {

						// console.log("!!! ShoppingCart is set and zeroPlanSubscription - navigate to paymentConfirmation");

						this.router.navigate(['paymentConfirmation']);
						this.spinner = false

						return;
					}

					this.paymentPriceId = success.data.shoppingCartItems ? success.data.shoppingCartItems[0].shoppingCartItemDetails.paymentPriceId : undefined;
					//console.log("this.paymentPriceId = ", success.data.shoppingCartItems[0].shoppingCartItemDetails.paymentPriceId);

					if (this.paymentPriceId != undefined) {
						// get the logged-in user info
						this.urlService.userDetailsV2().subscribe(success => {

							payload = {
								userId: success.data.details._id,
								email: success.data.details.email,
								firstname: success.data.details.firstname,
								lastname: success.data.details.lastname,
							}

							// Create Stripe Pay Customer
							this.urlService.createPayCustomer(payload).subscribe(success => {
								//console.log("payCustomer successfully created");

								//WC 11/15/2023 --- Moving this into checkout to accomodate promo code
								// payload = {
								// 	payCustomerId: success.data.id,
								// 	paymentPriceId: this.paymentPriceId
								// }

								// // Create Stripe Subscription
								// this.urlService.createPaySubscription(payload).subscribe(success => {
								// 	// console.log("createPaySubscription is successful - success = ", success);

								// 	this.localStorageService.setItem('subscriptionId', success.data.subscriptionId);
								// 	this.localStorageService.setItem('clientSecret', success.data.clientSecret);

								// this.router.navigate(['checkout'], {queryParams: {subscription_clientSecret: success.data.clientSecret}});
								// this.spinner = false
								// });


								this.router.navigate(['checkout']);
								//this.spinner = false;
							});
						});
					} else {

						this.router.navigate(['checkout']);
						this.spinner = false
					}
				});
			};
		} else {
			this.spinner = false
			this.router.navigate(['login'], {queryParams: {redirect: 'pricing'}});
		};
	};

	upgradePlan(upgradePlanId: any): void {

		const subId = this.localStorageService.getItem('upgrade_CurrentSubscriptionId');

		// Check if there's a Stripe pay customer id or not, if not then the current subscription is a Freemium so we'll set the shopping cart
		this.urlService.getUserSubscriptionPlanById(subId).subscribe(success => {
			if (success.data.payCustomerSubscriptionId == undefined)
				this.setShoppingCart([upgradePlanId]);
			else
				this.router.navigate(['plan-update'], {queryParams: {upgradePlanId}});
		});
	};
}
