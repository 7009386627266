// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `textarea {
    max-height: 400px;
}

.btn {
    box-shadow: none;
}

.btn:not(.btn-sm) {
    padding: 4px 25px;
}

.btn-primary {
    background: #6c2fbb;
    border-color: #6c2fbb;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #663399;
    border-color: #663399;
}`, "",{"version":3,"sources":["webpack://./src/app/afterLogin/my-plans/add-update-plan/add-update-plan.component.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;;;IAGI,WAAW;IACX,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":["textarea {\n    max-height: 400px;\n}\n\n.btn {\n    box-shadow: none;\n}\n\n.btn:not(.btn-sm) {\n    padding: 4px 25px;\n}\n\n.btn-primary {\n    background: #6c2fbb;\n    border-color: #6c2fbb;\n}\n\n.btn-primary:not(:disabled):not(.disabled).active,\n.btn-primary:not(:disabled):not(.disabled):active,\n.show > .btn-primary.dropdown-toggle {\n    color: #fff;\n    background-color: #663399;\n    border-color: #663399;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
