// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background: var(--purple-2);
}

.btn-primary {
    background: var(--purple-2);
    box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/layout/modals/create-questionnaire/create-questionnaire.component.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;AAC/B;;AAEA;IACI,2BAA2B;IAC3B,gBAAgB;AACpB","sourcesContent":[".nav-pills .nav-link.active, .nav-pills .show > .nav-link {\n    background: var(--purple-2);\n}\n\n.btn-primary {\n    background: var(--purple-2);\n    box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
