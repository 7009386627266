import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CrudService} from '../../../services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../environments/environment';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LocalStorageService} from '../../../services/localStorage.service';

declare var $: any;

@Component({
    selector: 'app-create-group',
    templateUrl: './create-group.component.html',
    styleUrls: ['./create-group.component.css']
})
export class CreateGroupComponent implements OnInit {

    fieldPassTextType = false;
    isFormSubmitted = false;
    isSubscriptionGroup = false;
    isPublic = true;
    createGroupForm: any;
    isCreate = true;
    selectedFile: any;
    followers: Array<any> = [];
    userInfo: any;
    allPlans = [];
    imgSrc: any;
    isImage = false;
    adminArray: any = [];
    members: Array<any> = [];
    finalArray: any = [];
    arrLength = 0;
    isClicked: any = [];
    term: any = '';
    group: any;
    page = 0;
    limit = 10;

    constructor(private api: CrudService,
                private toaster: ToastrService,
                public dialog: MatDialog,
                public localStorageService: LocalStorageService,
                public dialogRef: MatDialogRef<CreateGroupComponent>,
                @Inject(MAT_DIALOG_DATA) public data
    ) {
        this.isSubscriptionGroup = data?.type === 'subscription';
        this.group = data?.group;
    }

    ngOnInit(): void {
        // console.log(this.group)
        this.initiateForm();
        this.getAllPlans();
        this.getCurrentUserIngo()
    }

    getAllPlans() {
        this.api.getAllPlans().subscribe(res => {
            if (res.data) {
                this.allPlans = res.data;
                this.allPlans = this.allPlans.filter(x => !x.isActive);
            }
        });
    }

    initiateForm() {
        this.createGroupForm = new UntypedFormGroup({
            groupName: new UntypedFormControl(null, [Validators.required]),
            description: new UntypedFormControl(null),
            isPrivate: new UntypedFormControl(false),
            isSendingEmailRemainder: new UntypedFormControl(false),
            pageAdminTitle: new UntypedFormControl(null),
            pageContentTitle: new UntypedFormControl(null),
            // pageContentTitle: new FormControl('\'\''),
            thumbnail: new UntypedFormControl(null),
            customUrl: new UntypedFormControl(environment.currentDomain + '/my-group/'),
            isPasswordProtected: new UntypedFormControl(false),
            password: new UntypedFormControl(null)
        });
        if (this.group) {
            this.isPublic = this.group.isPrivate ? false : true 
            if (this.group?.thumbnail) {
                this.isImage = true;
                this.imgSrc = this.group?.thumbnail;
            }
            this.createGroupForm.patchValue({
                groupName: this.group.groupName.replace(/&amp;/g, '&'),
                description: this.group.description.replace(/&amp;/g, '&'),
                isPrivate: this.group.isPrivate,
                isSendingEmailRemainder: this.group.isSendingEmailReminder,
                pageAdminTitle: this.group.pageAdminTitle.replace(/&amp;/g, '&'),
                pageContentTitle: this.group.pageContentTitle.replace(/&amp;/g, '&'),
                thumbnail: this.group.thumbnail,
                customUrl: this.group.customUrl,
                isPasswordProtected: this.group.isPasswordProtected
            });
        }
        this.createGroupForm.controls.isPasswordProtected.valueChanges.subscribe((e) => {
            if (e) {
                this.createGroupForm.controls.password.addValidators(Validators.required);
            } else {
                this.createGroupForm.controls.password.clearValidators();
            }
            if (this.group && this.group.isPasswordProtected && e) {
                this.createGroupForm.controls.password.clearValidators();
            }
            this.createGroupForm.controls.password.updateValueAndValidity();
        });
    }

    getPublicPrivate(event: any) {
        if (event.target.checked) {
            this.isPublic = !this.isPublic;
        } else {
            this.isPublic = !this.isPublic;
        }
    }

    onSelectFile(event: any) {
        const file = event.target.files[0];
        if (file.size > 10485760) {
            this.toaster.error('File size should be less than 10MB');
            return;
        }
        if (file) {
            const fileType = file.type;
            if (fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png') {
                const img = new Image();
                img.src = window.URL.createObjectURL(file);
                img.onload = () => {
                    if (img.width < 400 || img.height < 150) {
                        this.toaster.info('Minimum image size should be 400 x 150');
                    } else {
                        const reader = new FileReader();
                        reader.onload = e => this.imgSrc = reader.result;
                        reader.readAsDataURL(file);
                        this.isImage = true;
                        this.selectedFile = event.target.files[0];
                    }
                }
            } else {
                this.toaster.info('Only jpeg and png files allowed');
            }
        }
    }

    updateGroup() {
        this.isFormSubmitted = true;
        if (this.createGroupForm.valid) {
            const updateData = this.createGroupForm.value;
            const formData = new FormData();
            formData.append('groupId', this.group._id);
            formData.append('groupName', updateData.groupName);
            formData.append('description', updateData.description);
            formData.append('isPrivate', updateData.isPrivate);
            formData.append('pageAdminTitle', updateData.pageAdminTitle);
            formData.append('pageContentTitle', updateData.pageContentTitle);
            formData.append('file', this.selectedFile);
            // formData.append('customUrl', updateData.customUrl);
            formData.append('isSendingEmailReminder', updateData.isSendingEmailRemainder);
            formData.append('isPasswordProtected', updateData.isPasswordProtected);
            if (updateData.password) {
                formData.append('password', updateData.password);
            }
            this.isCreate = false;
            this.api.updateGroup(this.group._id, formData).subscribe(res => {
                this.isCreate = true;
                this.toaster.success('Group updated successfully !!',)
                this.dialogRef.close(this.group._id);
            });
        }
    }

    createGroup() {
        this.isFormSubmitted = true;
        if (this.createGroupForm.valid) {
            const formData = new FormData();
            formData.append('groupName', this.createGroupForm.controls.groupName.value);

            if (!this.createGroupForm.controls.description.value)
                this.createGroupForm.controls.description.value = 'Description';

            formData.append('description', this.createGroupForm.controls.description.value);

            formData.append('isPrivate', this.createGroupForm.controls.isPrivate.value);
            formData.append('isSendingEmailReminder', this.createGroupForm.controls.isSendingEmailRemainder.value);

            formData.append('isPasswordProtected', this.createGroupForm.controls.isPasswordProtected.value);
            formData.append('password', this.createGroupForm.controls.password.value);

            if (!this.createGroupForm.controls.pageAdminTitle.value)
                this.createGroupForm.controls.pageAdminTitle.value = 'Admin title';

            formData.append('pageAdminTitle', this.createGroupForm.controls.pageAdminTitle.value);

            if (!this.createGroupForm.controls.pageContentTitle.value)
                this.createGroupForm.controls.pageContentTitle.value = 'Content title';

            formData.append('pageContentTitle', this.createGroupForm.controls.pageContentTitle.value);

            formData.append('file', this.selectedFile);
            formData.append('customUrl', environment.currentDomain + 'my-group/');
            if (this.isSubscriptionGroup) {
                formData.append('isSubscriptionGroup', 'true');
            }
            this.isCreate = false;
            // console.log(this.createGroupForm.value);
            this.api.createGroup(formData).subscribe(res => {
                // console.log('group res', res);
                this.isCreate = true;
                this.toaster.success('Group created successfully !!',)
                this.onSaveAdmin(res.data._id)
            })
        }
    }


    onSubmit() {
        if (this.group) {
            this.updateGroup();
        } else {
            this.createGroup();
        }
    }


    getVideoItem(id: any) {
        const index = this.adminArray.indexOf(id);
        if (index == -1) {
            this.adminArray.push(id);
        } else {
            this.adminArray.splice(index, 1)
        }
    }

    saveAdmin() {
        this.members = []
        for (const item of this.adminArray) {
            this.members.push({
                memberId: item,
                accessType: 'ADMIN'
            })
        }
        this.finalArray = {
            groupId: 'string',
            members: this.members
        }
        this.arrLength = this.finalArray.members.length
        this.hideModal()
    }

    onSaveAdmin(groupId: any) {
        const finalPayload = {
            groupId,
            members: this.members
        }
        this.isCreate = false;
        this.api.addMember(finalPayload).subscribe(res => {
            this.isCreate = true;
            this.dialogRef.close(groupId);
            // this.openAddPlanConfirmation(groupId)
        });
    }

    // openAddPlanConfirmation(groupId) {
    //     if (!this.isSubscriptionGroup || !this.allPlans.length) {
    //         this.dialogRef.close(groupId);
    //         return;
    //     }
    //     const planDialogRef = this.dialog.open(PlansDialogComponent, {
    //         minWidth: '40%',
    //         minHeight: '40vh',
    //         maxHeight: '90vh',
    //         data: {
    //             plans: this.allPlans
    //         }
    //     });
    //
    //     planDialogRef.afterClosed().subscribe((selectedPlanId) => {
    //         const payload = {
    //             groupId,
    //             isSubscriptionGroup: true,
    //             planId: selectedPlanId
    //         }
    //         this.api.updateGroupSubscriptionStatus(payload).subscribe(response => {
    //             console.log('updateGroupSubscriptionStatus response = ', response);
    //         });
    //
    //         this.dialogRef.close(groupId);
    //     })
    // }

    getCurrentUserIngo() {
        const x = this.localStorageService.getItem('user');
        // WC 10032023 --- PenTestFix to use V2
        this.api.getUserInfoV2(x).subscribe(res => {
            // this.followers = res.data.followersinfo;
            this.userInfo = res.data.userinfo;
        });
        this.onScroll();
    }

    onScroll() {
        ++this.page;

        // Followers V2
        this.api.getFollowers(this.limit, this.page).subscribe(success => {
            const followersArray = success.data.followers;
            for (const item of followersArray) {
                this.followers.push(item);
            }
        });
    }


    openAdminModal() {
        $('#add_admin').modal('show');
    }

    hideModal() {
        $('#add_admin').modal('hide');
    }

    togglePasswordTextType() {
        this.fieldPassTextType = !this.fieldPassTextType;
    }

    errorHandling = (control: string, error: string) => {
        return this.createGroupForm.controls[control].hasError(error) &&
            (this.createGroupForm.controls[control].dirty || this.createGroupForm.controls[control].touched || this.isFormSubmitted);
    }


}
