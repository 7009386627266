// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.group-box {
    padding: 30px;
    border-radius: 16px;
    background-color: #ffff;
}

th {
    color: #000;
    font-weight: 400;
    word-break: break-all;
}

td {
    font-weight: 400;
    color: #5c5c5c;
    word-break: break-all;
}

@media (max-width: 768px) {
    .table-borderless {
        width: 100% !important;
    }
}

.Popular {
    background: #e1e1f9;
    border-radius: 20px;
    border: none;
    outline: none;
    color: #6438b4;
    padding: 5px 15px;
    margin: 0 10px;
    font-weight: bold;
    font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/app/afterLogin/coupon/edit-coupon/edit-coupon.component.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;IAChB,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI;QACI,sBAAsB;IAC1B;AACJ;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;IACZ,aAAa;IACb,cAAc;IACd,iBAAiB;IACjB,cAAc;IACd,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".group-box {\n    padding: 30px;\n    border-radius: 16px;\n    background-color: #ffff;\n}\n\nth {\n    color: #000;\n    font-weight: 400;\n    word-break: break-all;\n}\n\ntd {\n    font-weight: 400;\n    color: #5c5c5c;\n    word-break: break-all;\n}\n\n@media (max-width: 768px) {\n    .table-borderless {\n        width: 100% !important;\n    }\n}\n\n.Popular {\n    background: #e1e1f9;\n    border-radius: 20px;\n    border: none;\n    outline: none;\n    color: #6438b4;\n    padding: 5px 15px;\n    margin: 0 10px;\n    font-weight: bold;\n    font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
