import {ApplicationRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {UtilityService} from '../../../services/utility.service';
import {CrudService} from '../../../services/crud.service';
import {environment} from '../../../../environments/environment';
import {OnlineVideosComponent} from '../online-videos/online-videos.component';
import {UploadedFileResponse} from '../../../interfaces/upload-video.interface';
import {CreateVideoComponent} from '../create-video/create-video.component';
import languages from '../../../services/spokenLanguages';
import CreativeEngine from '@cesdk/engine';
import {LocalStorageService} from '../../../services/localStorage.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as Dropbox from 'dropbox';

declare var videojs: any;
// const ffmpeg = require('@ffmpeg/ffmpeg');
declare var $;
declare var WaveSurfer;
declare var google: any;
declare var gapi: any;


interface ModalData {
    isAudio: boolean;
    isAIEditor: boolean;
    skipTranscribing: boolean;
    folderId: any;
    dropboxValue: any;
}

@Component({
    selector: 'app-record-video',
    templateUrl: './record-video.component.html',
    styleUrls: ['./record-video.component.css']
})
export class RecordVideoComponent implements OnInit, OnDestroy {

    uploadVideoObj = {
        file: [],
        // path: null,
        // fileData: null,
        // url: null,
        preSignedUrl: null,
    };
    videoName = 'recorded-video';

    options = {
        controls: true,
        autoplay: false,
        fluid: false,
        loop: false,
        width: 720,
        height: 320,
        bigPlayButton: true,
        plugins: {
            wavesurfer: {
                backend: 'WebAudio',
                waveColor: '#36393b',
                progressColor: 'black',
                displayMilliseconds: true,
                debug: true,
                cursorWidth: 1,
                hideScrollbar: true,
                plugins: [
                    // enable microphone plugin
                    WaveSurfer.microphone.create({
                        bufferSize: 4096,
                        numberOfInputChannels: 1,
                        numberOfOutputChannels: 1,
                        constraints: {
                            video: false,
                            audio: true
                        }
                    })
                ]
            },
            record: {
                screen: false,
                audio: true,
                video: true,
                debug: true,
                maxLength: 3600,
                displayMilliseconds: false,
                autoMuteDevice: true,
                convertEngine: 'ts-ebml',
                audioEngine: '',
                audioWebAssemblyURL: '',
            }
        }
    };
    selectedOnlineVideo: any;
    isProcessMergeVideo = false;
    isAudioOnly = false;
    isAudioRecord = false;
    isVideoRecord = false;
    isScreenRecord = false;
    isRecording = false;
    isDeviceReady = false;
    isAIEditor = false;
    isTranscribing: any;
    // WC 07012023 - Spoken language of the video, default to US English
    spokenLanguage: any = 'en-US';
    languages = languages;
    player: any;
    allUploading: boolean;
    deviceError = '';
    isReadyToUpload: boolean;
    isFinishRecord: boolean;
    isReadyToSave: boolean;
    folderId:any;
    private maxFileSizeAI = 20 * 1024 * 1024 * 1024;
    private maxFileSize = 2 * 1024 * 1024 * 1024;
    public recordedVideo: any;
    private engine: any;
    private dropBoxAccessToken: any;
    private dropBoxRefreshToken: any;

    scope = ['https://www.googleapis.com/auth/drive', 'https://www.googleapis.com/auth/drive.file'];
    oAuthToken;
    pickerApiLoaded = false;
    accessToken: string = '';
    selectedEditorType: any = "Tree";
    uploadType: any;

    get isAnyRecord() {
        return this.isVideoRecord || this.isScreenRecord || this.isAudioRecord;
    }

    constructor(
        // @Inject(MAT_DIALOG_DATA) public data: ModalData,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private toaster: ToastrService,
        private utilService: UtilityService,
        private crudService: CrudService,
        private dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private dialogRef: MatDialogRef<RecordVideoComponent>,
        private router: Router,
        private appRef: ApplicationRef,
        private activatedRoute: ActivatedRoute,
    ) {
        if(data?.folderId){
            this.folderId = data?.folderId
        }

        if (data.isAIEditor) {
            this.isAIEditor = data.isAIEditor;
        }
        if (data.isAudio) {
            this.isAudioOnly = data.isAudio;
            this.videoName = 'recorded-audio'
        }
        
        if (data.uploadType) {
            this.uploadType = data.uploadType;
        }
                
        if(data?.dropboxValue){
            this.dropBoxAccessToken = data.dropboxValue.token;
            this.dropBoxRefreshToken = data.dropboxValue.refreshToken;
            this.loadDropBoxChooser();
        }

    }

    ngOnInit(): void {
        setTimeout(() => {
            $('[data-toggle="tooltip"]').tooltip();
        })
    }

    loadDropBoxChooser() {
        const script = document.createElement('script');
        script.src = "https://www.dropbox.com/static/api/2/dropins.js";
        script.id = "dropboxjs";
        script.setAttribute('data-app-key', environment.DROPBOX_app_key);
        script.async = true;
        script.onload = () => {     
            document.getElementById('uploadVideoButton').click();
            document.getElementById('dropboxButton').click();
        };
        document.body.appendChild(script);
    }

    dropboxLogin() {

        if (!this.dropBoxAccessToken) {
            const url = environment.API_URLV2 + '/auth/dropbox';
            window.location.replace(url);
        }else{
            this.loadDropBoxChooser();
        }

        
    }

    refreshDropboxAccessToken() {
        this.crudService.refreshDropBoxToken({refreshToken: this.dropBoxRefreshToken}).subscribe((res) => {
            this.dropBoxAccessToken = res.access_token;
            this.uploadFromDropbox();
        })
    }

  uploadFromDropbox() {

        const options = {
            success: async (files) => {
                this.isReadyToUpload = true;
                const selectedFile = files[0];

                var dbx = new Dropbox.Dropbox({ accessToken: this.dropBoxAccessToken });

                await dbx.filesDownload({ path: selectedFile.id })
                    .then((response: any) => {

                        const fileBlob = response.result.fileBlob;
                        const fileName = selectedFile.name;

                        // Set MIME type based on file extension
                        const fileExtension = fileName.split('.').pop()?.toLowerCase();
                        let fileType = '';
                        if (fileExtension === 'mp4') {
                            fileType = 'video/mp4';
                        } else {
                            fileType = 'application/octet-stream'; // default binary type
                        }

                        const file = new File([fileBlob], fileName, { type: fileType });
                        if (file) {
                            this.onSelectFile([file], true);
                        }
                    })
                    .catch((error) => {
                        this.isReadyToUpload = false;
                        if(error.status === 401){
                            const url = environment.API_URLV2 + '/auth/dropbox';
                            //console.log(url)
                            window.location.replace(url);                  
                        }else{
                            this.toaster.error('Something went wrong, Please try again!');
                        }
                    });
            },
            cancel: () => {
                this.isReadyToUpload = false;
            },
            linkType: 'preview',
            multiselect: false,
            folderselect: false,
            extensions: ['.mp4']
        };

        (window as any).Dropbox.choose(options);
    }

    uploadFromGoogleDrive() {
        this.loadPicker();
    }

    loadPicker() {
        gapi.load('auth', { 'callback': this.onAuthApiLoad.bind(this) });
        gapi.load('picker', {});
    }

    onAuthApiLoad() {
        gapi.auth.authorize(
          {
            'client_id': environment.GAPI_GDrive_ClientId,
            'scope': this.scope,
            'immediate': false
          },
          this.handleAuthResult.bind(this)
        );
      }
    
    handleAuthResult(authResult) {
        //console.log("authResult = ", authResult);

        this.accessToken = authResult.access_token;

        if (authResult && !authResult.error) {
          this.createPicker();
        } else {
          //console.log('handleAuthResult - Auth was not successful');
        }
    }

    createPicker() {
        const view = new google.picker.View(google.picker.ViewId.DOCS);
        view.setMimeTypes('video/mp4');

        const picker = new google.picker.PickerBuilder()
            .addView(view)
            .setOAuthToken(gapi.auth.getToken().access_token)
            .setDeveloperKey(environment.GAPI_API_Key)
            .setCallback(this.pickerCallback)
            .build();

        //console.log("picker = ", picker)
        picker.setVisible(true);
      }

    pickerCallback = (data) => {
        if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
            const doc = data[google.picker.Response.DOCUMENTS][0];

            //console.log("Picked doc = ", doc);

            this.loadSelectedFile(doc);
        }
    }

    loadSelectedFile(doc) {
        let payload = {
            fileId: doc.id,
            accessToken: this.accessToken
        }

        this.isReadyToUpload = true;
        this.appRef.tick();

        this.crudService.fetchStreamOfFile(payload).subscribe((res) => {
            const file = new File([res], doc.name);
            this.onSelectFile([file], true);
        })
    }

    ngOnDestroy(): void {
        this.resetData();
        if (this.engine) {
            this.engine.element.remove();
            this.engine.dispose();
        }
    }

    screenRecord() {
        this.resetData();
        this.checkDevices();
        setTimeout(() => {
            this.isScreenRecord = true;
            this.options.plugins.record.video = false;
            this.options.plugins.record.screen = true;
            this.record();
        }, 500);
    }

    videoRecord() {
        this.resetData();
        this.checkDevices();
        setTimeout(() => {
            this.isVideoRecord = true;
            this.options.plugins.record.video = true;
            this.options.plugins.record.screen = false;
            this.record();
        }, 500);
    }

    audioRecord() {
        this.resetData();
        this.checkDevices();
        setTimeout(() => {
            this.isAudioRecord = true;
            this.options.plugins.record.convertEngine = '';
            this.options.plugins.record.audioEngine = 'vmsg';
            this.options.plugins.record.audioWebAssemblyURL = 'https://unpkg.com/vmsg/vmsg.wasm';
            this.options.plugins.record.audio = true;
            this.options.plugins.record.video = false;
            this.options.plugins.record.screen = false;
            this.record();
        }, 500);
    }

    checkDevices() {
        navigator.mediaDevices.enumerateDevices().then(devices => {
            const microphones = devices.filter(d => d.kind === 'audioinput');
            this.options.plugins.record.audio = !!microphones.length;
        });
    }

    record() {
        this.isDeviceReady = false;
        this.isRecording = false;
        try {
            setTimeout(() => {
                const dom = document.getElementById('videojs-record');
                this.player = videojs(dom, this.options, () => {
                    // console.log('player ready! id:', el);

                    // print version information at startup
                    // var msg = 'Using video.js ' + videojs.VERSION +
                    //     ' with videojs-record ' + videojs.getPluginVersion('record') +
                    //     ' and recordrtc ' + RecordRTC.version;
                    // videojs.log(msg);
                });

                setTimeout(() => {
                    // start immediate
                    this.player.record().getDevice();
                }, 500);

                // device is ready
                this.player.on('deviceReady', () => {
                    if (this.isScreenRecord) {
                        this.player.record().start();
                    }
                    this.isDeviceReady = true;
                    this.deviceError = '';
                    // console.log('device is ready!');
                });

                // user clicked the record button and started recording
                this.player.on('startRecord', () => {
                    if (this.recordedVideo) {
                        this.isRecording = false;
                        // alert("The video has already been recorded. Would you like to replace it with a new one?")
                    }
                    this.isRecording = true;
                    // console.log('started recording!');
                });

                // user clicked the record button and started recording
                this.player.on('audioOutputReady', () => {
                    this.isRecording = true;
                    // console.log('audioOutputReady recording!');
                });

                // user completed recording and stream is available
                this.player.on('finishRecord', () => {
                    this.isRecording = false;
                    // recordedData is a blob object containing the recorded data that
                    // can be downloaded by the user, stored on server etc.
                    // console.log('finished recording: ', this.player.recordedData);
                    // this.player.record().saveAs({'video': 'my-video-file-name.webm'});
                    // this.player.recordedData
                    // stop device stream only
                    // this.stopRecord();
                    // const data = this.player.recordedData;
                    this.recordedVideo = this.player.recordedData;
                    this.isFinishRecord = true;
                    if (this.isAudioRecord) {
                        this.isReadyToSave = true;
                    }
                    // this.isReadyToSave = true;
                    // this.onSelectFile([data]);
                    // const reader = new FileReader();
                    // this.uploadVideoObj.path = [data];
                    // this.uploadVideoObj.file = [data];
                    // reader.readAsDataURL(data);
                    // reader.onload = (_event) => {
                    //     this.uploadVideoObj.url = reader.result;
                    //     console.log(this.uploadVideoObj)
                    // }
                });

                this.player.on('finishConvert', async () => {
                    // console.log('finished converting: ', this.player.convertedData);
                    this.recordedVideo = this.player.convertedData;
                    // show save as dialog
                    this.isReadyToSave = true;
                    // this.convertToMp3(this.recordedVideo);
                });

                // error handling
                this.player.on('error', (element, error) => {
                    console.warn(error);
                });

                this.player.on('deviceError', () => {
                    this.deviceError = this.player.deviceErrorCode.message;
                    console.error('device error:', this.player.deviceErrorCode);
                });
            });
        } catch (e) {
            this.deviceError = 'Requested device not found';
        }

    }

    // async convertToMp3(file) {
    //     const { createFFmpeg, fetchFile } = ffmpeg;
    //     const ffmpegInstance = createFFmpeg({ log: true });
    //
    //     await ffmpegInstance.load();
    //
    //     if (file) {
    //         await ffmpegInstance.write('input.webm', await fetchFile(file));
    //
    //         await ffmpegInstance.run('-i', 'input.webm', '-vn', '-ar', '44100', '-ac', '2', '-ab', '192', '-f', 'mp3', 'output.mp3');
    //
    //         const data = ffmpegInstance.read('output.mp3');
    //
    //         const mp3Blob = new Blob([data.buffer], { type: 'audio/mp3' });
    //         const mp3Url = URL.createObjectURL(mp3Blob);
    //         // Do something with the converted MP3 file, like download or play it
    //         // For example:
    //         const downloadLink = document.createElement('a');
    //         downloadLink.href = mp3Url;
    //         downloadLink.download = 'converted.mp3';
    //         downloadLink.click();
    //         URL.revokeObjectURL(mp3Url);
    //     }
    // }

    resetData() {
        if (this.player) {
            try {
                this.player.record().reset();
            } catch (e) {
                this.player.reset();
            }
        }
        // this.uploadVideoObj.url = '';
        // this.uploadVideoObj.file = [];
        this.selectedOnlineVideo = null;
        this.recordedVideo = null;
        this.isFinishRecord = false;
        this.isReadyToSave = false;
        this.isAudioRecord = false;
        this.isScreenRecord = false;
        this.isVideoRecord = false;
        this.deviceError = '';
    }

    saveVideo() {
        if (this.isAudioRecord) {
            this.player.record().saveAs({audio: this.videoName + '.mp3'}, 'convert');
        } else {
            this.player.record().saveAs({video: this.videoName + '.webm'}, 'convert');
        }
    }

    uploadRecorded() {
        this.isReadyToUpload = true;
        this.onSelectFile([this.recordedVideo]);
        setTimeout(() => {
            this.uploadVideo();
        }, 2000);
    }

    uploadVideo() {
        // console.log(this.uploadVideoObj.file)
        if (this.uploadVideoObj.file.length) {
            this.toaster.info('Uploading Video');
            if ((this.isVideoRecord || this.isScreenRecord || this.isAudioRecord) && this.videoName) {
                // const fileExt = this.uploadVideoObj.file[0].name.split('.').pop();
                if (this.isAudioRecord) {
                    this.uploadVideoObj.file[0].name = this.videoName + '.' + 'mp3';
                } else {
                    this.uploadVideoObj.file[0].name = this.videoName + '.' + 'webm';
                }
                this.uploadVideoObj.file[0].originalName = this.utilService.sanitizeString(this.uploadVideoObj.file[0].name, true);
                this.uploadVideoObj.file[0].originalName = this.uploadVideoObj.file[0].originalName.split('.')[0];

            }
            if (this.isAudioOnly) {
                this.uploadAudio(this.uploadVideoObj.file[0]);
            } else {
                this.finalUpload(this.uploadVideoObj.file[0]);
            }
        } else {
            this.isReadyToUpload = false;
        }
    }

    uploadAudio(i) {
        this.allUploading = true;
        this.isReadyToUpload = false;
        // console.log(i)
        const obj = {
            name: this.utilService.sanitizeString(i.name, false),
            type: 'audio/mpeg',
            URL: null,
            duration: i.duration,
            thumbnail: '',
            size: i.size,
            originalName: i.originalName,
            isAIEditorVideo: this.isAIEditor
        };
        i.isUploading = true;
        this.crudService.createpresignedUrlV2(obj).subscribe((success) => {
            i.counter = success.data.counter;
            this.uploadVideoObj.preSignedUrl = success.data.details;
            // const pathArray = this.uploadVideoObj.preSignedUrl.split('?');
            this.crudService.uploadAudio(this.uploadVideoObj.preSignedUrl, i).subscribe(() => {
                i.isUploading = false;
                i.isTranscribing = false;
                i.uploadPercentage = 0;
                this.uploadVideoObj.file.shift();
                if (this.uploadVideoObj.file.length < 1) {
                    this.cancelUpload(i);
                } else {
                    this.uploadVideo();
                }
            });
            this.progressPercentage(i);
        });
    }


    onSelectFile(files, isUpload = false) {
        if (isUpload) {
            this.resetData();
        } else {
            this.uploadVideoObj.file = [];
        }
        let existItem = [];
        // this.uploadVideoObj.file = [];
        // this.uploadVideoObj.url = '';
        
        if (files) {
            for (const i of files) {
                if (this.isAudioOnly) {
                    if (i.type !== 'audio/mpeg') {
                        this.toaster.error('Invalid audio file : ' + i.name);
                        continue;
                    }
                }

                if (i.type !== 'video/mp4') {
                    this.toaster.error('Upload only mp4 video');
                    continue;
                }

                if (this.isAIEditor && i.size > this.maxFileSizeAI) {
                    this.toaster.error('The file size limit for AI Editor has been reached for ' + i.name);
                    continue;
                } 
                
                if (!this.isAIEditor && i.size > this.maxFileSize) {
                    this.toaster.error('The file size limit has been reached for ' + i.name);
                    continue;
                }

                i.originalName = this.utilService.sanitizeString(i.name, true);
                i.originalName = i.originalName.split('.')[0];
                // console.log("onSelectFile - After sanitizing i.originalName = ", i.originalName);

                // const reader = new FileReader();
                // this.uploadVideoObj.path = files;
                // this.uploadVideoObj.fileData = files[0];
                // reader.readAsDataURL(files[0]);
                // reader.onload = (_event) => {
                //     this.uploadVideoObj.url = reader.result;
                // }

                i.id = Math.random();
                i.uploadPercentage = 0;
                this.utilService.onGetDuration(i).then((duration) => {
                    i.duration = duration;
                    if (i.type.indexOf('image') > -1) {
                        i.format = 'image';
                    } else if (i.type.indexOf('video') > -1) {
                        i.format = 'video';
                    } else if (i.type.indexOf('audio') > -1) {
                        i.format = 'audio';
                    }
                    i.isUploading = true;
                    i.isTranscribing = false;
                    i.isTranscoding = false;
                    this.uploadVideoObj.file.push(i);

                    existItem = existItem.filter((item, pos) => {
                        return existItem.indexOf(item) === pos;
                    });
                    for (let k = this.uploadVideoObj.file.length - 1; k >= 1; k--) {
                        for (const j of existItem) {
                            if (this.uploadVideoObj.file[k].originalName === j) {
                                this.uploadVideoObj.file.splice(k, 1);
                                break;
                            }
                        }
                    }
                });
            }
        }
        if (isUpload) {
            this.isReadyToUpload = true;
            setTimeout(() => {
                this.uploadVideo();
            }, 2000);
        }
    }

    totalPresignedUrls:any;
    completedProcess:any[] = [];
    finalUpload(i) {
        this.allUploading = true;
        this.isReadyToUpload = false;
        // console.log(i)
        const obj:any = {
            name: this.utilService.sanitizeString(i.name, false),
            type: 'application/octet-stream',
            URL: null,
            duration: i.duration,
            thumbnail: '',
            size: i?.size ? i.size : i.sizeBytes,
            originalName: i.originalName,
            isAIEditorVideo: this.isAIEditor
        };

        if(this.folderId){
           obj.folderId = this.folderId 
        }

        if (this.isAIEditor) {
            // tslint:disable-next-line: no-string-literal
            obj['bucketName'] = environment.AI_VIDEO_UPLOAD_BUCKET;
            obj.aiEditorType = this.selectedEditorType
        }

        let Objdata;

        i.isUploading = true;

        this.crudService.createpresignedUrlV2(obj).subscribe((success) => {
            i.counter = success.data.counter;
            this.uploadVideoObj.preSignedUrl = success.data.details;
            // console.log("uploadVideoObj.file", this.uploadVideoObj.file);

            const maxSize = 5 * 1024 * 1024 * 1024;     //5GB

            if(this.isAIEditor && i.size > maxSize) {
                // this.crudService.uploadFile(i, environment.AI_VIDEO_UPLOAD_BUCKET, this.uploadVideoObj.preSignedUrl).then((res) => {
                //     this.afterUploadingVideo(i, obj);
                // }, (error) => {
                //     this.toaster.info('Error!', error);
                // });

                const fileName =  this.utilService.sanitizeString(i.name, false);

                const payload = {
                    bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
                    fileName: fileName 
                };

                //Initiate the multipart upload by getting the upload if
                this.crudService.initiateMultipartUpload(payload).subscribe(response => {

                    const multipartUploadId = response.data.uploadId;

                    const chunkSize = 500 * 1024 * 1024; // 500MB chunk size
                    const numChunks = Math.ceil(i.size / chunkSize);

                    const files = [];

                    //Create the payload for presignedUrls
                    for (let j = 0; j < numChunks; j++) {

                        files.push({
                            fileName: fileName,
                            partNumber : j + 1
                        });

                        if (j == (numChunks - 1)) {

                            const payload = {
                                bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
                                multipartUploadId: multipartUploadId,
                                files: files
                            };

                            //get the presigned URLs with the upload id
                            this.crudService.presignedUrlForMultipartUpload(payload).subscribe(async response => {
                                const presignedUrls = response.data
                                this.totalPresignedUrls = presignedUrls.length
                                const files = [];

                                //Upload the file chunk one at a time with the presigned url
                                for (let j = 0; j < numChunks; j++) {
                                    const startByte = j * chunkSize;
                                    const endByte = Math.min((j + 1) * chunkSize, i.size);  //i is the file
                                    const chunk = i.slice(startByte, endByte);
                                    const presignedUrl = presignedUrls[j].presignedUrl;

                                    // const response = await fetch(presignedUrl, {
                                    //     method: 'PUT',
                                    //     body: chunk,
                                    // });

                                    //   Replace fetch above with a call to uploadChunk2 in the crud
                                    
                                    // console.log("response ===> ", response);
                                    
                                    // if (response.ok) {
                                    //     const etag = response.headers.get('ETag');
                                    //     console.log(etag); // Ensure this is not null

                                    //     files.push({
                                    //         eTag: JSON.parse(etag),
                                    //         partNumber: j + 1
                                    //     })
                                    // } else {
                                    // // Handle error
                                    // };

                                    // =======================

                                    try {
                                        let result = await this.crudService.uploadChunk2(presignedUrl, chunk, presignedUrls);
                                        
                                        if((this.completedProcess.length + 1 ) !== presignedUrls.length){
                                            this.completedProcess.push(result);   
                                        }
                                        
                                        if (result.response.ok) {
                                            const etag = result.etag;
                                            // console.log(etag);
            
                                            files.push({
                                                eTag: JSON.parse(etag),
                                                partNumber: j + 1
                                            });
                                        } else {
                                            this.dialogRef.close(false);
                                            return this.toaster.error('Upload Failed - Please try again!');
                                        }
                                    } catch (error) {
                                        this.dialogRef.close(false);
                                        return this.toaster.error('Upload Failed - Please try again!');
                                    }

                                    
                                    if (j == (numChunks - 1)) {
                                        // Finalize the multipart upload
                                        const payload = {
                                            bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
                                            originalFileName: fileName,
                                            files: files,
                                            multipartUploadId: multipartUploadId
                                        };

                                        this.crudService.completeMultipartUpload(payload).subscribe(response => {
                                            clearInterval(this.interval);
                                            this.completedProcess = []
                                            this.totalPresignedUrls = 0
                                            this.afterUploadingVideo(i, obj);
                                        });
                                    };
                                };
                            });
                        };
                    };
                }, (error) => {
                    this.toaster.info('Uploading Error!', error.message);
                });
            } else {
                this.crudService.uploadVideo(this.uploadVideoObj.preSignedUrl, i).subscribe(() => {
                    this.afterUploadingVideo(i, obj);
                }, (error) => {
                    this.toaster.info('Error!', error);
                });
            }
            this.progressPercentage(i);
        }, (error) => {
            i.isTranscoding = false;
            this.toaster.error(error.error.data);
        });
    }

    afterUploadingVideo(i, obj) {
        let Objdata;
        i.isUploading = false;
        i.isTranscribing = true;
        i.uploadPercentage = 0;
        const pathArray = this.uploadVideoObj.preSignedUrl.split('?');
        const firstUrl = pathArray[0];
        
        if (this.isAIEditor) {
            Objdata = {
                name: i.name,
                counter: i.counter,
                bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
                file: firstUrl,
            };
            this.parseAiEditorVideoV2(firstUrl, i);
        } else {
            Objdata = {
                name: i.name,
                counter: i.counter,
                file: firstUrl,
            };
            const data: any = {
                name: obj.name,
                counter: i.counter,
                file: firstUrl,
            };
            if (this.data.skipTranscribing || !this.isTranscribing) {
                this.transcodingJob(data, i);
            } else {
                data.languageCode = this.spokenLanguage;
                this.transcribingJob(data, i);
            }
        }
    }
    interval:any
    progressPercentage(i) {
        this.interval = setInterval(() => {
            // i.uploadPercentage = this.crudService.getProgress();
            i.uploadPercentage = this.crudService.getProgress()?.etag ? 100 : this.crudService.getProgress();            

            const maxSize = 5 * 1024 * 1024 * 1024;     //5GB
            if(this.isAIEditor && i.size > maxSize) {
                return
            }

            if (i.uploadPercentage > 99.9) {
                clearInterval(this.interval);
            }
            this.appRef.tick();
        }, 1000);
    }

    transcribingJob(data, i) {
        this.toaster.info('Transcribing Video');
        let requestObj;
        this.crudService.transcribeVideo(data).subscribe((success) => {

            // console.log('### transcribingJob - success.data = ', success.data);

            i.uploadPercentage = 0;
            i.isOtherone = true;

            if (this.isAIEditor) {
                requestObj = {
                    name: data.name,
                    type: 'application/octet-stream',
                    counter: i.counter,
                    transcodeId: success.data.transcodeId,
                    bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
                };
            } else {
                requestObj = {
                    name: data.name,
                    counter: i.counter,
                    transcriptionJobName: success.data.TranscriptionJobName,
                };
            }

            if (i.uploadPercentage) {
                // console.log('in if i.uploadPercentage', i.uploadPercentage);
                i.uploadPercentage = i.uploadPercentage + 10;
            }

            this.checkTranscribingStatus(data, requestObj, i);
        }, (error) => {
        });
    }

    checkTranscribingStatus(data, requestObj, i) {
        // console.log("checkTranscribingStatus");

        this.crudService.checkTranscribingStatus(requestObj).subscribe((success) => {
            if (success.data.TranscriptionJobStatus === 'COMPLETED') {
                this.toaster.info('Transcribing Completed');

                data.transcriptFileUri = success.data.Transcript.TranscriptFileUri;

                // this.toastr.info('Transcoding Video');
                this.transcodingJob(data, i);
            } else if (success.data.TranscriptionJobStatus === 'FAILED') {
                this.toaster.error('Transcribing Failed');
                // this.toastr.info('Transcoding Video');
                this.transcodingJob(data, i);
            } else {
                // console.log('2. in else of check status', i.uploadPercentage);
                if (i.uploadPercentage < 95) {
                    i.uploadPercentage = i.uploadPercentage + 5;
                    // console.log('3. in if else of check status', i.uploadPercentage);
                } else {
                    i.uploadPercentage = 98;
                }

                setTimeout(() => {
                    this.checkTranscribingStatus(data, requestObj, i);
                }, 1500);
            }
        }, (error) => {
            this.toaster.error('Transcribing Failed');
        });
    }

    transcodingJob(data, i) {
        i.isTranscribing = false;
        i.isTranscoding = true;
        this.toaster.info('Transcoding Video');

        let requestObj;

        this.crudService.transcodeVideo(data).subscribe((success) => {
            // console.log("transcodVideo success.data = ", success.data);

            i.uploadPercentage = 0;
            i.isOtherone = true;
            if (this.isAIEditor) {
                requestObj = {
                    name: data.name,
                    type: 'application/octet-stream',
                    counter: i.counter,
                    transcodeId: success.data.transcodeId,
                    bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
                };
            } else {
                requestObj = {
                    name: data.name,
                    type: 'application/octet-stream',
                    counter: i.counter,
                    transcodeId: success.data.transcodeId,
                };
            }
            if (i.uploadPercentage) {
                // console.log('in if i.uploadPercentage', i.uploadPercentage);
                i.uploadPercentage = i.uploadPercentage + 10;
            }
            this.checkStatus(requestObj, i);
        }, (error) => {
        });
    }

    checkStatus(requestObj, i) {
        this.crudService.checkTranscodingStatus(requestObj).subscribe((success) => {
            if (success.data._id != null || success.data._id !== undefined) {
                this.toaster.info('Transcoding Completed');
                this.uploadVideoObj.file.shift();
                if (this.uploadVideoObj.file.length < 1) {
                    this.cancelUpload(i);
                    // this.getAllVideos(true);
                    i.isTranscoding = false;
                    i.isOtherone = false;
                } else {
                    this.uploadVideo();
                    i.isTranscoding = false;
                    i.isOtherone = false;
                }
            } else {
                // console.log('2. in else of check status', i.uploadPercentage);
                if (i.uploadPercentage < 95) {
                    i.uploadPercentage = i.uploadPercentage + 5;
                    // console.log('3. in if else of check status', i.uploadPercentage);
                } else {
                    i.uploadPercentage = 98;
                }
                setTimeout(() => {
                    this.checkStatus(requestObj, i);
                    // console.log('4. in setTimeout', i.uploadPercentage);
                }, 1500);
            }
        }, (error) => {
            this.toaster.error('Transcoding Failed');
        });
    }

    // Original call to Phyton AI Editor directly
    // parseAiEditorVideo(file: string, i) {
    //     const user = this.localStorageService.getItem('user');
    //     const formData = new FormData();
    //     formData.append('userID', user);
    //     formData.append('url', file);
    //     formData.append('webhook_url', environment.AI_WEB_HOOK);
    //     i.isTranscoding = false;
    //     i.isTranscribing = false;
    //     this.crudService.parseAiEditorVideo(formData).subscribe((res: any) => {
    //         const videoId = res.data.videoId;
    //         this.toaster.info(
    //             '',
    //             `Your video is being processed by the auto editor,
    //     the processing Id is ${videoId} and You'll receive an email notification when the processing is done.`
    //         );
    //         this.uploadVideoObj.file.shift();
    //         if (this.uploadVideoObj.file.length < 1) {
    //             this.cancelUpload(i);
    //             // this.getAllVideos(true);
    //             i.isTranscoding = false;
    //             i.isTranscribing = false;
    //         } else {
    //             this.uploadVideo();
    //             i.isTranscribing = false;
    //             i.isTranscoding = false;
    //         }
    //         // WC 08292022
    //     }, (error) => {
    //         // Error callback
    //         console.log(
    //             'Error in calling parse AI Editor API - error: ',
    //             error.message
    //         );
    //         this.toaster.error(
    //             'We are sorry, AI Editor is currently not available. Please try again later'
    //         );
    //         this.cancelUpload(i);
    //         // // this.getAllVideos(true);
    //         i.isTranscoding = false;
    //         i.isTranscribing = false;
    //     });
    // }

    // New call the aiRequestHandler
    parseAiEditorVideoV2(file: string, i) {
        const user = this.localStorageService.getItem('user');
        const email = this.localStorageService.getItem('email');

        // WC 06142023 --- To check for max no of AI Editor processed
        this.crudService.getUserSubscriptionPlan().subscribe(success => {

            const maxNoOfAIEditor = success.data.subscriptionPlanInfo.maxNoOfAIEditorVideos;

            const formData = {
                userID: user,
                userEmail: email,
                url: file,
                webhook_url: environment.AI_WEB_HOOK,
                // WC 060520 to add max allowable AI Editor processing as per the user's subscription plan
                maxNoOfAIEditor,
                aiEditorType: this.selectedEditorType
            };

            i.isTranscoding = false;
            i.isTranscribing = false;

            this.crudService.parseAIEditorVideoV2(formData).subscribe((res: any) => {
                this.toaster.info(
                    '',
                    `Your video is being processed by the auto editor, you'll receive an email notification when the processing is done.`
                );

                this.uploadVideoObj.file.shift();
                
                if (this.uploadVideoObj.file.length < 1) {
                    this.cancelUpload(i);
                    // this.getAllVideos(true);
                    i.isTranscoding = false;
                    i.isTranscribing = false;
                } else {
                    this.uploadVideo();
                    i.isTranscribing = false;
                    i.isTranscoding = false;
                }
                // WC 08292022
            }, (error) => {
                // Error callback
                // console.log(
                //     'Error in calling parse AI Editor API - error: ',
                //     error.message
                // );
                if (error.message == "")
                    this.toaster.error('We are sorry, AI Editor is currently not available. Please try again later');
                else
                    this.toaster.error(error.message);

                this.cancelUpload(i);
                // // this.getAllVideos(true);
                i.isTranscoding = false;
                i.isTranscribing = false;
            });
        });
    }

    cancelUpload(i) {
        // this.uploadVideoObj.file = [];
        this.allUploading = false;
        i.isTranscoding = false;
        this.dialogRef.close(true);
    }

    onlineVideo(type:any) {
        this.resetData();
        const dialogRef = this.dialog.open(OnlineVideosComponent, {
            data: {
                type
            },
            minWidth: '50vw'
        });
        dialogRef.afterClosed().subscribe((result: UploadedFileResponse) => {
            if (result) {
                this.selectedOnlineVideo = result;
            }
        });
    }

    downloadAndUpload() {
        const result = this.selectedOnlineVideo;
        this.allUploading = true;
        this.uploadVideoObj.file.push(result);
        this.uploadVideoObj.file[0].isDownloading = true;
        this.utilService.urlToFile(result.URL, result.name).then((res) => {
            this.uploadVideoObj.file[0] = res;
            this.uploadVideoObj.file[0].originalName = result.originalName;
            this.uploadVideoObj.file[0].id = Math.random();
            this.uploadVideoObj.file[0].uploadPercentage = 0;
            this.utilService.onGetDuration(this.uploadVideoObj.file[0]).then((duration) => {
                this.uploadVideoObj.file[0].duration = duration;
                if (this.uploadVideoObj.file[0].type.indexOf('image') > -1) {
                    this.uploadVideoObj.file[0].format = 'image';
                } else if (this.uploadVideoObj.file[0].type.indexOf('video') > -1) {
                    this.uploadVideoObj.file[0].format = 'video';
                }
                this.uploadVideoObj.file[0].isUploading = true;
                this.uploadVideoObj.file[0].isTranscribing = false;
                this.uploadVideoObj.file[0].isTranscoding = false;
                this.finalUpload(this.uploadVideoObj.file[0]);
            });
        });
    }

    selectAudio() {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            width: '95%',
            maxWidth: '1300px',
            panelClass: 'my-dialog',
            height: 'auto',
            minHeight: '500px',
            data: {
                audios: true,
                videos: false,
                links: false
            },
        });

        dialogRef.afterClosed().subscribe(async (result) => {
            if (result) {
                this.selectedOnlineVideo.dimension = await this.utilService.getVideoDimensions(this.selectedOnlineVideo.URL)
                this.selectedOnlineVideo.audioSourceUrl = result.URL;
                const obj = {
                    videoSourceUrl: this.selectedOnlineVideo.URL,
                    audioSourceUrl: result.URL,
                    // audioSourceUrl: 'https://s3-ap-southeast-2.amazonaws.com/shotstack-assets/music/disco.mp3',
                    videoDuration: this.selectedOnlineVideo.duration
                };
                this.isProcessMergeVideo = true;
                const trimBlob = await this.cesdkInit();
                this.selectedOnlineVideo.URL = URL.createObjectURL(trimBlob as any);
                // console.log(this.selectedOnlineVideo.URL)
                // this.crudService.mergeAudioToVideo(obj).subscribe((res: any) => {
                //     this.getVideoStatus(res.data.renderId);
                // }, err => {
                    this.isProcessMergeVideo = false;
                // });
            }
        });
    }

    cesdkInit() {


        var player = videojs('video-js');
        // console.log("player = ", player);
        player.controls(false)

        if (this.engine) {
            this.engine.element.remove();
            this.engine.dispose();
        }
        return new Promise((resolve => {
            const config = {
                //baseURL: 'https://cdn.img.ly/packages/imgly/cesdk-engine/1.20.0/assets',
                license: environment.ImgLy_License
            };

            CreativeEngine.init(config).then(async (engine) => {
                // Attach engine canvas to DOM
                document.getElementById('cesdk_container').append(engine.element);
                
                const scene = engine.scene.createVideo();
              
                const page = engine.block.create('page');
                engine.block.appendChild(scene, page);
              
                engine.block.setWidth(page, this.selectedOnlineVideo.dimension.width);
                engine.block.setHeight(page, this.selectedOnlineVideo.dimension.height);
              
                engine.block.setDuration(page, this.selectedOnlineVideo.duration);
              
                const video1 = engine.block.create('graphic');
                engine.block.setShape(video1, engine.block.createShape('rect'));
                const videoFill = engine.block.createFill('video');
                engine.block.setString(
                  videoFill,
                  'fill/video/fileURI',
                  this.selectedOnlineVideo.URL
                );
                engine.block.setFill(video1, videoFill);
            
              
                const track = engine.block.create('track');
                engine.block.appendChild(page, track);
                engine.block.appendChild(track, video1);
                engine.block.fillParent(track);
              
                engine.block.setDuration(video1, this.selectedOnlineVideo.duration);
              
                /* Make sure that the video is loaded before calling the trim APIs. */
                await engine.block.forceLoadAVResource(videoFill);
                engine.block.setTrimOffset(videoFill, 1);
                engine.block.setTrimLength(videoFill, 10);
              
                engine.block.setLooping(videoFill, false);
              
                engine.block.setMuted(videoFill, true);
              
                const audio = engine.block.create('audio');
                engine.block.appendChild(page, audio);
                engine.block.setString(
                  audio,
                  'audio/fileURI',
                  this.selectedOnlineVideo.audioSourceUrl
                );
              
                /* Set the volume level to 70%. */
                engine.block.setVolume(audio, 0.7);
              
                /* Start the audio after two seconds of playback. */
                //engine.block.setTimeOffset(audio, 2);
              
                /* Give the Audio block a duration of 7 seconds. */
                //engine.block.setDuration(audio, 7);
              
                /* Export page as mp4 video. */
                const mimeType = 'video/mp4' as any;
                const progressCallback = (renderedFrames, encodedFrames, totalFrames) => {
                //   console.log(
                //     'Rendered',
                //     renderedFrames,
                //     'frames and encoded',
                //     encodedFrames,
                //     'frames out of',
                //     totalFrames
                //   );
                };
                const blob = await engine.block.exportVideo(
                  page,
                  mimeType,
                  progressCallback,
                  {}
                );

                resolve(blob);

                player.controls(true);
              
                /* Download blob. */
                // const anchor = document.createElement('a');
                // anchor.href = URL.createObjectURL(blob);
                // anchor.download = 'exported-video.mp4';
                // anchor.click();
              });
        }))

    }

    handleUploadClick() {
        this.uploadType = 'upload';
    }

}
