// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms {
  margin-top: 70px;
}

.interact-vid-sec {
  padding: 40px 80px 0px 80px;
}

.interact-vid-sec.pad {
  padding-bottom: 130px;
  color:#5627d8;
}

ul {
  margin: 0 0 1.5em;
  padding: 0;
  counter-reset: item;
}

ul > li {
  margin: 0;
  padding: 0 0 0 2em;
  text-indent: -2em;
  list-style-type: none;
  counter-increment: item;
}

ul > li:before {
  display: inline-block;
  width: 1.5em;
  padding-right: 0.5em;
  font-weight: bold;
  text-align: right;
  content: counter(item) ".";
}

p {
  text-align: justify;
}

@media (max-width: 991px) {
  .interact-vid-sec {
    padding: 30px 30px 0px !important;
  }
  .custom-height,
  .position-relative.pop5 {
    height: auto !important;
    margin-bottom: 30px;
  }
  .card-body {
    padding-bottom: 20px !important;
  }
  .margin-right-0-mobile {
    margin-right: 0px !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/beforeLogin/terms-conditions/terms-conditions.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;EACrB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,SAAS;EACT,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,oBAAoB;EACpB,iBAAiB;EACjB,iBAAiB;EACjB,0BAA0B;AAC5B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE;IACE,iCAAiC;EACnC;EACA;;IAEE,uBAAuB;IACvB,mBAAmB;EACrB;EACA;IACE,+BAA+B;EACjC;EACA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".terms {\r\n  margin-top: 70px;\r\n}\r\n\r\n.interact-vid-sec {\r\n  padding: 40px 80px 0px 80px;\r\n}\r\n\r\n.interact-vid-sec.pad {\r\n  padding-bottom: 130px;\r\n  color:#5627d8;\r\n}\r\n\r\nul {\r\n  margin: 0 0 1.5em;\r\n  padding: 0;\r\n  counter-reset: item;\r\n}\r\n\r\nul > li {\r\n  margin: 0;\r\n  padding: 0 0 0 2em;\r\n  text-indent: -2em;\r\n  list-style-type: none;\r\n  counter-increment: item;\r\n}\r\n\r\nul > li:before {\r\n  display: inline-block;\r\n  width: 1.5em;\r\n  padding-right: 0.5em;\r\n  font-weight: bold;\r\n  text-align: right;\r\n  content: counter(item) \".\";\r\n}\r\n\r\np {\r\n  text-align: justify;\r\n}\r\n\r\n@media (max-width: 991px) {\r\n  .interact-vid-sec {\r\n    padding: 30px 30px 0px !important;\r\n  }\r\n  .custom-height,\r\n  .position-relative.pop5 {\r\n    height: auto !important;\r\n    margin-bottom: 30px;\r\n  }\r\n  .card-body {\r\n    padding-bottom: 20px !important;\r\n  }\r\n  .margin-right-0-mobile {\r\n    margin-right: 0px !important;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
