import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CrudService} from '../../services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../environments/environment';
import {DeleteConfirmationComponent} from '../../layout/modals/delete-confirmation/delete-confirmation.component';
import {ShareVideoDialogComponent} from '../../layout/modals/share-video-dialog/share-video-dialog.component';
import {MatDialog} from '@angular/material/dialog';

declare var $: any;

@Component({
  selector: 'app-saved-nodes-album',
  templateUrl: './saved-nodes-album.component.html',
  styleUrls: ['./saved-nodes-album.component.css']
})
export class SavedNodesAlbumComponent implements OnInit {
  @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;

  allNodes: any = []
  mainId: any;
  isProccess = false;
  // currentUser: any;
  // public myfollowers: any = [];
  // public currentUserData: any;
  // public currentUserFollowing: any = [];
  // public uploaderId: any = '';
  // public iAMFollowing: any = false;
  // public currentProfileDetails: any;
  // public shareVideos: any = [];
  // fullShareURL: any;
  // isCopied = false;
  // findorselect: any;
  // private followersPage = 1;


  constructor(
      private dialog: MatDialog,
      private activateRoute: ActivatedRoute,
      private crudService: CrudService,
      private toaster: ToastrService,
      private router: Router
  ) {
  }

  ngOnInit(): void {
    this.activateRoute.params.subscribe(res => {
      // console.log('params is',res);
      this.mainId = res.id
      this.getAllNodeVideos(res.id);
      // this.getMyData();
      // this.getFollowers()
    })
  }

  copyLink() {}

  getAllNodeVideos(id: any) {
    this.isProccess = true;
    this.crudService.getAllNodeVideosV2(id).subscribe(res => {
      this.isProccess = false;
      this.allNodes = res.data[0];

      // console.log("this.allNodes = ", this.allNodes);

    })
  }

  deleteNode(item: any) {
    // let cnfrm = confirm('Are you sure ? Do you want to delete current node ?')
    this.deleteDialogRef.open({
      header: 'Delete node',
      title: 'Are you sure you want to delete the current node?'
    }, (e) => {
      if (e) {
        const payload = {
          videoId: item._id,
          collectionId: this.mainId
        }
        this.isProccess = true;
        this.crudService.deleteNode(payload).subscribe(res => {
          this.toaster.success('Node deleted successfully');
          this.isProccess = false;
          this.getAllNodeVideos(this.mainId)
        })
      }
    });

  }

  watchCurrentVideo(item: any) {
    if (item.type === 'main') {
      this.router.navigate(['videos'], {queryParams: {video: item._id}});
    } else {
      this.router.navigate(['videos'], {queryParams: {video: item.mainId, child: item._id}});
    }
  }

  // openTreeShareModal(item: any) {
  //   this.fullShareURL = environment.currentSharedDomain + 'videos?video=' + item._id;
  //   $('#Tree-share-Modal').modal('show');
  // }

  openTreeShareModal(id) {
    id = id._id;
    const dialogRef = this.dialog.open(ShareVideoDialogComponent, {
      width: '100%',
      maxWidth: '600px',
      minHeight: '400px',
      panelClass: 'my-dialog',
      height: 'auto',
      data: {
        title: 'Share the Collection Node',
        videoID: id,
        URL: `${environment.currentSharedDomain}videos?video=${id}`
      },
    });
  }

  // getMyData() {
  //   //  //console.log('current user is',this.currentUser);
  //   if (this.currentUser) {
  //     this.crudService.getUserInfoV2(this.currentUser).subscribe(
  //       success => {
  //         this.currentUserData = success.data.userinfo
  //
  //         //WC 10062022
  //         // this.currentUserFollowing = success.data.followinginfo;
  //
  //         // for (const i of this.currentUserFollowing) {
  //         //   if (i.userinfo._id === this.uploaderId) {
  //         //     this.iAMFollowing = true;
  //         //     break;
  //         //   }
  //         // }
  //
  //         // this.currentProfileDetails = success.data.userinfo
  //       })
  //   }
  // }
  // selectToShare(id: any) {
  //   if (this.shareVideos.includes(id)) {
  //     const index = this.shareVideos.indexOf(id);
  //     if (index > -1) {
  //       this.shareVideos.splice(index, 1);
  //     }
  //   }
  //   else {
  //     this.shareVideos.push(id)
  //   }
  // }
  //
  // popupEmail() {
  //   const url = `mailto:?subject=Interactive Video&body=Check this interactive video experience I made on FreeFuse: ${this.fullShareURL}`;
  //   const newwindow = window.open(url);
  //   if (window.focus) {
  //     newwindow.focus()
  //   }
  // }
  //
  // Tpopupfacebook() {
  //   const url = 'http://www.facebook.com/sharer.php?u=' + this.fullShareURL;
  //   const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
  //   if (window.focus) {
  //     newwindow.focus()
  //   }
  // }
  //
  // Tpopuptweet() {
  //   const url = 'https://twitter.com/intent/tweet?text=' + this.fullShareURL;
  //   const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
  //   if (window.focus) {
  //     newwindow.focus()
  //   }
  // }
  //
  // Tpopuplinkedin() {
  //
  //   const url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + this.fullShareURL;
  //   const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
  //   if (window.focus) {
  //     newwindow.focus()
  //   }
  // }
  //
  // shareTousers(id: any, isMain?: any) {
  //   let x = null;
  //   let obj;
  //   let url = this.fullShareURL;
  //   x = url.substring(27)
  //   // this.fullShareURL =id;
  //   $('#' + id).prop('disabled', true);
  //   if (isMain) {
  //     obj = {
  //       sharedWith: this.shareVideos,
  //       videoID: this.mainId
  //     }
  //   } else {
  //     obj = {
  //       sharedWith: this.shareVideos,
  //       videoID: x
  //     }
  //   }
  //
  //
  //   this.crudService.shareInternally(obj).subscribe(
  //     success => {
  //       this.toaster.success('Video Shared!')
  //       this.shareVideos = [];
  //       for (const i of this.myfollowers) {
  //         i.selected = false;
  //       }
  //       $('#' + id).prop('disabled', false);
  //     }
  //   )
  //
  //
  // }
  //
  //
  // onScroll(): void {
  //   ++this.followersPage;
  //   this.getFollowers();
  // }
  //
  // getFollowers() {
  //
  //   this.crudService.getFollowers(10, this.followersPage).subscribe(success => {
  //
  //     const followers = success.data.followers;
  //
  //     for (const item of followers) {
  //       this.myfollowers.push(item);
  //     }
  //   })
  // }

}
