import { Component, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { forkJoin } from "rxjs";
import { SlugifyPipe } from "src/app/pipes/slugify.pipe";
import { CrudService } from "src/app/services/crud.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-clone-community",
  templateUrl: "./clone-community.component.html",
  styleUrls: ["./clone-community.component.css"],
})
export class CloneCommunityComponent implements OnInit {
  spinner: boolean = false;
  baseUrl = environment.currentDomain + "my-community/";
  cloneCommunityForm!: UntypedFormGroup;
  submitted = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private crudService: CrudService,
    private toastr: ToastrService,
    public slugifyPipe: SlugifyPipe,
  ) {}

  ngOnInit() {
    this.cloneCommunityForm = this.formBuilder.group({
      oldCommunityName: ["", Validators.required],
      newCommunityName: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      newCommunityWebsite: ["",[ Validators.required, this.validURLValidator()]],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.cloneCommunityForm.controls;
  }

  validURLValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      const str = control.value;
      let url;
      try {
        url = new URL(str);
      } catch (_) {
        return { invalidUrl: true };
      }
      return (url.protocol === 'http:' || url.protocol === 'https:') ? null : { invalidUrl: true };
    };
  }


  slugifyUrlForOldCommunity() {
    
    this.cloneCommunityForm.value.oldCommunityName = this.slugifyPipe.transform(
      this.cloneCommunityForm.value.oldCommunityName
    );
  }

  slugifyUrlForNewCommunity() {
    this.cloneCommunityForm.value.newCommunityName = this.slugifyPipe.transform(
      this.cloneCommunityForm.value.newCommunityName
    );
  }

  onSubmit() {

    this.submitted = true;
    this.spinner = true;

    if (this.cloneCommunityForm.invalid) {
      this.spinner = false;
      return;
    }

    const oldCommunity = this.crudService.getSellerIdByCommunityUrl(
      this.cloneCommunityForm.value.oldCommunityName
    );
    const newCommunity = this.crudService.getSellerIdByCommunityUrl(
      this.cloneCommunityForm.value.newCommunityName
    );

    forkJoin([oldCommunity, newCommunity]).subscribe(
      ([oldResponse, newResponse]) => {
        this.spinner = false;

        if (!oldResponse.data.sellerId) {
          this.toastr.error("Old Community does not exist!");
          return;
        }

        if (newResponse.data.sellerId) {
          this.toastr.error("New Community already exists!");
          return;
        }

        const payload = {
          oldCommunityName: this.cloneCommunityForm.value.oldCommunityName,
          newCommunityName: this.cloneCommunityForm.value.newCommunityName,
          email: this.cloneCommunityForm.value.email,
          communityWebsite: this.cloneCommunityForm.value.newCommunityWebsite,
        }

        this.crudService.cloneCommunity(payload).subscribe(success => {
          if (success)
            this.toastr.success("The " + this.cloneCommunityForm.value.newCommunityName + " has been created successfully!");
          else
            this.toastr.success("Error in creating " + this.cloneCommunityForm.value.newCommunityName);
        })
      },
      () => {
        this.spinner = false;
        this.toastr.error("An error occurred, please try again.");
      }
    );
  }
}
