import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {DeleteConfirmationComponent} from '../../layout/modals/delete-confirmation/delete-confirmation.component';
import {CrudService} from '../../services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute, Router} from '@angular/router';
import {AddUpdatePlanComponent} from './add-update-plan/add-update-plan.component';
import {MatDialog} from '@angular/material/dialog';
import {PlanDetailsComponent} from './plan-details/plan-details.component';
import {DashboardService} from '../../services/dashboard.service';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $;

@Component({
    selector: 'app-my-plans',
    templateUrl: './my-plans.component.html',
    styleUrls: ['./my-plans.component.css']
})
export class MyPlansComponent implements OnInit,AfterViewInit {

    @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;

    spinner: any = false
    allPlans = [];
    currentUserId: any;
    planCounts = {
        maxMonthly: 4,
        maxYearly: 4,
        total: 0,
        monthly: 0,
        yearly: 0,
        monthlyPopularId: '',
        yearlyPopularId: ''
    }
    isShowStep = false;
    currentUserData: any;

    constructor(
        public crudService: CrudService,
        public dashboardService: DashboardService,
        public toaster: ToastrService,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public localStorageService: LocalStorageService,
        private dialog: MatDialog
    ) {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.step === 'true') {
                this.isShowStep = true;
            }
        });
    }

    ngOnInit(): void {
        this.getAllPlans();
        this.dashboardService.getUserData.subscribe((res) => {
            this.currentUserData = res;
        });
        this.currentUserId = this.localStorageService.getItem('user')
    }

    getAllPlans() {
        this.spinner = true;
        this.crudService.getAllPlans().subscribe(res => {
            this.allPlans = res.data;

            this.planCounts.total = this.allPlans.length;
            this.planCounts.monthly = this.allPlans.filter(x => x.unit === 'Monthly').length;
            this.planCounts.yearly = this.allPlans.filter(x => x.unit === 'Yearly').length;
            this.planCounts.monthlyPopularId = this.allPlans.filter(x => x.unit === 'Monthly' && x.isMostPopular)[0]?._id;
            this.planCounts.yearlyPopularId = this.allPlans.filter(x => x.unit === 'Yearly' && x.isMostPopular)[0]?._id;

        }).add(() => this.spinner = false);
    }

    confirmDelete(item: any) {

        this.deleteDialogRef.open({
            header: 'Delete Plan',
            title: 'Are you sure you want to delete this plan?',
        }, (e) => {
            if (e) {
                this.crudService.deletePlan(item._id).subscribe(res => {
                    this.getAllPlans();
                    this.toaster.success('Plan successfully deleted');
                })
            }
        });
    }

    addUpdatePlan(item?) {
        if (!item && !(this.planCounts.monthly < this.planCounts.maxMonthly || this.planCounts.yearly < this.planCounts.maxYearly)) {
            return;
        }
        const dialogRef = this.dialog.open(AddUpdatePlanComponent, {
            width: '100%',
            maxWidth: '600px',
            minHeight: '400px',
            maxHeight: '90vh',
            panelClass: 'my-dialog',
            height: 'auto',
            autoFocus: false,
            data: {
                plan: item,
                planCounts: this.planCounts
            },
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.getAllPlans()
            }
        });
    }

    viewPlan(item?) {
        this.dialog.open(PlanDetailsComponent, {
            width: '90%',
            maxWidth: '700px',
            panelClass: 'my-dialog',
            maxHeight: '90vh',
            height: 'auto',
            autoFocus: false,
            data: item
        });
    }

    ngAfterViewInit(): void {
        $('[data-toggle="tooltip"]').tooltip();
    }

}
