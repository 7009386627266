// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-common-form{
    width: 600px !important;
}

label, .baseUrl, input {
    font-size: 15px;
}

input{
    height: 40px;
}

.title{
    border-bottom: 1px solid #d9d9d9;
    padding: 16px 0;
}

.signup-group{
    padding: 10px 20px;
}

.video-common-btn{
    width: auto !important;
    padding: 6px 15px !important;
}

.buttons{
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/feature-request-group-info/feature-request-group-info.component.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;AAC3B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,gCAAgC;IAChC,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,sBAAsB;IACtB,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,qBAAqB;IACrB,yBAAyB;IACzB,SAAS;AACb","sourcesContent":[".video-common-form{\n    width: 600px !important;\n}\n\nlabel, .baseUrl, input {\n    font-size: 15px;\n}\n\ninput{\n    height: 40px;\n}\n\n.title{\n    border-bottom: 1px solid #d9d9d9;\n    padding: 16px 0;\n}\n\n.signup-group{\n    padding: 10px 20px;\n}\n\n.video-common-btn{\n    width: auto !important;\n    padding: 6px 15px !important;\n}\n\n.buttons{\n    display: flex;\n    align-items: baseline;\n    justify-content: flex-end;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
