import {Component, OnDestroy, OnInit} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {DashboardService} from '../../services/dashboard.service';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from "rxjs";
import {LocalStorageService} from "../../services/localStorage.service";

declare var $;

@Component({
    selector: 'app-delegate-access',
    templateUrl: './delegate-access.component.html',
    styleUrls: ['./delegate-access.component.css']
})
export class DelegateAccessComponent implements OnInit, OnDestroy {
    passwordPattern = /(?=(.*[0-9]))(?=.*[!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=(.*[A-Z]))(?=(.*)).{8,}/;

    constructor(
        private router: Router,
        private crudService: CrudService,
        private dashboardService: DashboardService,
        private localStorageService: LocalStorageService,
    ) {
    }

    signupObject = {
        userId: '',
        spinner: false
    }
    fieldPassTextType = false;
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    ngOnInit() {

    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    changeUserLogin(id) {
        if ($('#' + id).val() != '') {
            $('#' + id).siblings('.input-field').addClass('video_input_focus');
            $('#' + id).addClass('focusIn');
        } else {
            $('#' + id).siblings('.input-field').removeClass('video_input_focus');
        }
    }

    onSubmit() {
        this.signupObject.spinner = true;

        const finalObj = {
            userId: this.signupObject.userId,
        }

        this.crudService.delegateAccess(finalObj).subscribe(res => {
            this.localStorageService.clear();
            this.dashboardService.resetSkipImage();
            this.dashboardService.clearUserData();
            this.localStorageService.setItem('token', res.data);
            this.dashboardService.onGetUserData();
            this.dashboardService.getUserData.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
                if (res) {
                    this.router.navigateByUrl('/profile');
                }
            });
        }).add(() => this.signupObject.spinner = false);

    }

    togglePasswordTextType() {
        this.fieldPassTextType = !this.fieldPassTextType;
    }
}
