import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CrudService } from "src/app/services/crud.service";
import { saveAs } from "file-saver";
import { copyImageToClipboard } from "copy-image-clipboard";
import { trigger, state, style, animate, transition } from "@angular/animations";

@Component({
  selector: "app-qr-code-modal",
  templateUrl: "./qr-code-modal.component.html",
  styleUrls: ["./qr-code-modal.component.css"],
  animations: [
    trigger("slideInOut", [
      state(
        "in",
        style({
          transform: "translateY(0)",
        })
      ),
      transition("void => *", [
        style({ transform: "translateY(100%)" }),
        animate("500ms ease-out"),
      ]),
      transition("* => void", [
        animate("500ms ease-in", style({ transform: "translateY(100%)" })),
      ]),
    ]),
  ],
})
export class QRCodeModalComponent implements OnInit {
  @ViewChild("qrCodeContainer") qrCodeContainer: ElementRef;

  spinner: boolean = false;
  QRcode: any;
  qrCodeContent: string | null = null;
  isDisplayCopied: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private crudService: CrudService,
    private dialogRef: MatDialogRef<QRCodeModalComponent>,
  ) {}

  ngOnInit(): void {
    this.spinner = true;
    this.crudService.generateQRCode({ qr_code_url: this.data }).subscribe(
      (res) => {
        this.QRcode = res.data;
        this.spinner = false;
      },
      // (error) => console.log("error", error)
    );
  }

  processQRCode(action: "copy" | "download") {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    const img = new Image();
    img.onload = () => {
      canvas.width = 400;
      canvas.height = 400;
      context.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");

      if (action === "copy") {
        this.copyImageToClipboardAndClose(dataURL);
      } else if (action === "download") {
        this.downloadDataURLAndClose(dataURL);
      }
    };
    img.src = "data:image/svg+xml;charset=utf-8," + encodeURIComponent(this.QRcode);
  }

  private copyImageToClipboardAndClose(dataURL: string) {
    copyImageToClipboard(dataURL)
      .then(() => {
        this.handleCopySuccess();
      })
      .catch((e) => {
        console.error("Error copying image to clipboard: ", e.message);
      });
  }

  private handleCopySuccess() {
    this.isDisplayCopied = true;
    setTimeout(() => {
      this.isDisplayCopied = false;
      this.dialogRef.close();
    }, 1000);
  }

  private downloadDataURLAndClose(dataURL: string) {
    const byteString = atob(dataURL.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      intArray[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: "image/png" });

    saveAs(blob, "QR Code");
    this.dialogRef.close();
  }
}
