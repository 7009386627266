// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-js {
    width: 100%;
    height: 40vh;
    height: -webkit-fill-available;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/modals/modal-video-player/modal-video-player.component.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,8BAA8B;AAClC","sourcesContent":[".video-js {\n    width: 100%;\n    height: 40vh;\n    height: -webkit-fill-available;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
