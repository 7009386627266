import {Component, Inject, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import {CrudService} from 'src/app/services/crud.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {LocalStorageService} from '../../services/localStorage.service';
import {DashboardService} from "../../services/dashboard.service";
import {ToastrService} from 'ngx-toastr';

declare var Stripe;

@Component({
  selector: 'app-payment-confirmation',
  templateUrl: './payment-confirmation.component.html',
  styleUrls: ['./payment-confirmation.component.css'],
})

export class PaymentConfirmationComponent implements OnInit {

  private clientSecret: any = '';
  private paymentConf: any = '';
  private stripe = Stripe(environment.Stripe_PK);
  spinner = false;
  headerSpinner = false;
  paymentStatus: any = '';
  paymentIntent: any = null;
  userEmail: any = '';
  paymentTransDate: any = '';
  private existingPayment: any;
  private shoppingCartId: any;
  private isPaymentSubmitted: any;
  private isZeroAmtSubscription = false;
  isVideoRedirect = false;
  videoId: string = '';

  constructor(
      private urlService: CrudService,
      private dashboardService: DashboardService,
      private router: Router,
      private localStorageService: LocalStorageService,
      private route: ActivatedRoute,
      @Inject(DOCUMENT) private document,
      private toaster: ToastrService) {

    this.route.queryParams.subscribe(params => {
      // console.log("params.payment_intent_client_secret = ", params.payment_intent_client_secret);

      this.clientSecret = params.payment_intent_client_secret;
      this.paymentConf = params.payment_intent;
    });


    //WC 02012024 -- To handle signing up from the video player during questionnaire
    if (this.localStorageService.getItem('redirect') != undefined && this.localStorageService.getItem('redirect').includes("video")) {
      this.isVideoRedirect = true;
      this.videoId = this.localStorageService.getItem('redirect').split("=")[1];
      this.localStorageService.removeItem('redirect');
    }
  }

  ngOnInit(): void {

    this.spinner = true;
    this.headerSpinner = true;
    this.shoppingCartId = this.localStorageService.getItem('shoppingCartId');
    this.isZeroAmtSubscription = this.localStorageService.getItem('subscriptionPlanZero') === 'true';

    if (this.isZeroAmtSubscription) {

      // console.log("isZeroAmtSubscription!!!");

      //this.deactivateShoppingCart();

      this.urlService.getShoppingCart(this.shoppingCartId).subscribe(success => {

        const shoppingCart = success.data;

        //console.log("shoppingCart = ", shoppingCart)

        if (shoppingCart.shoppingCartItems[0].shoppingCartItemDetails.price > 0) {
            this.toaster.error("Invalid Premium");
            this.spinner = false;
            this.headerSpinner = false;
            return
        }

        this.setSubscriptionPlan(shoppingCart);

        this.deactivateShoppingCart();
      });

      this.paymentStatus =  'ZeroAmtSubscription';
      this.localStorageService.removeItem('subscriptionPlanZero');
      // console.log("this.paymentStatus = ", this.paymentStatus);

    } else {

      // Retrieving the payment intent status
      this.checkStatus();
    }

  }

  deactivateShoppingCart() {

    // console.log("deactiveShoppingCart");

    const payload = {shoppingCartId : this.shoppingCartId};

        this.urlService.deactivateShoppingCart(payload).subscribe(success => {
          this.localStorageService.removeItem('shoppingCartId');
          // console.log("Successfully deactivate shopping cart id ", this.shoppingCartId);
          this.dashboardService.onGetShoppingCart();
          this.headerSpinner = false;
          this.spinner = false;
        });
  };

  setSubscriptionPlan(shoppingCart: any) {

    // console.log("setSubscriptionPlan");

    const payload = {
      planId : shoppingCart.shoppingCartItems[0].itemNo,
      // Stripe subscription Id
      subscriptionId : this.localStorageService.getItem('subscriptionId'),
      clientSecret : this.localStorageService.getItem('clientSecret')
    };

    this.urlService.setUserSubscriptionPlan(payload).subscribe(success => {

      this.localStorageService.setItem('isSubscriber', 'true');
      // console.log("successfully set subscriber status");

      this.spinner = false;

    });
  }

  async checkStatus() {

    if (!this.clientSecret) {
      this.spinner = false;
      this.headerSpinner = false;
      return;
    }

    // console.log("this.paymentConf = ", this.paymentConf);

    const paymentIntentResult = await this.stripe.retrievePaymentIntent(this.clientSecret);

    this.paymentIntent = paymentIntentResult.paymentIntent;
    // console.log("checkStatus - this.paymentIntent = ", this.paymentIntent);

    const date = new Date(this.paymentIntent.created * 1000);
    this.paymentTransDate = (date.getMonth()+1) + '/' + date.getDate() + '/' + date.getFullYear();

    if (!this.isPaymentSubmitted && this.paymentIntent.status == 'succeeded' ) {
      // deactivate shopping cart

      if (this.shoppingCartId) {
        // const payload = {shoppingCartId : this.shoppingCartId};

        // this.urlService.deactivateShoppingCart(payload).subscribe(success => {
        //   this.localStorageService.removeItem('shoppingCartId');
        //   // console.log("Successfully deactivate shopping cart id ", this.shoppingCartId);

        //   this.headerSpinner = false;
        //   this.spinner = false;
        // });

        this.deactivateShoppingCart();

      } else {
        this.headerSpinner = false;
        this.spinner = false;
      }

      // get user email addr
      this.urlService.userDetailsV2().subscribe(success => {
        this.userEmail = success.data.details.email;
        // console.log("this.userEmail = ", this.userEmail);
      })
    }

    this.paymentStatus = this.getPaymentStatus();

    this.urlService.getPaymentByConfirmationNo(this.paymentConf).subscribe(success => {

      // No payment by confirmation no found
      if (success.data == null) {
        let payload = null;

        payload = {
          shoppingCartId : this.shoppingCartId,
          clientSecret: this.clientSecret,
          paymentConfirmation : this.paymentConf,
          paymentStatus : this.paymentIntent.status
        }

        // save the payment confirmation and status
        this.urlService.setPaymentConfirmation(payload).subscribe((success) => {

          // console.log("Successful setting paymentConfirmation = ", success.data)

          this.urlService.getShoppingCart(this.shoppingCartId).subscribe(success => {

            const shoppingCart = success.data;

            if (shoppingCart.type == 'subscriptionPlan') {

              // console.log("Shpping cart type is subscriptionPlan");
              //console.log("this.localStorageService.getItem('upgrade_CurrentSubscriptionId') = ", this.localStorageService.getItem('upgrade_CurrentSubscriptionId'));

              // Deactive the current plan if this is an upgrade
              if (this.localStorageService.getItem('upgrade_CurrentSubscriptionId') != undefined && this.localStorageService.getItem('upgrade_CurrentSubscriptionId') != null) {
                const payload = {
                  cancelReason: 'user initiated plan upgrade',
                  subscriptionId: this.localStorageService.getItem('upgrade_CurrentSubscriptionId')
                };

                //console.log("Cancel payload = ", payload);

                this.urlService.cancelUserSubscriptionPlan(payload).subscribe(success => {
                  this.localStorageService.removeItem('upgrade_CurrentSubscriptionId');
                })
              }

              // payload = {
              //   planId : shoppingCart.shoppingCartItems[0].itemNo,
              //   // Stripe subscription Id
              //   subscriptionId : this.localStorageService.getItem('subscriptionId'),
              //   clientSecret : this.localStorageService.getItem('clientSecret')
              // };

              // this.urlService.setUserSubscriptionPlan(payload).subscribe(success => {

              //   this.localStorageService.setItem('isSubscriber', 'true');
              //   // console.log("successfully set subscriber status");

              //   this.spinner = false;

              // });

              this.setSubscriptionPlan(shoppingCart);

            }
          });
        });
      };
    });
  };

  getPaymentStatus() {

    let message = '';

    switch (this.paymentIntent.status) {
      case 'succeeded':
        message = 'Payment succeeded';
        break;
      case 'processing':
        message = 'Your payment is processing';
        break;
      case 'requires_payment_method':
        message = 'Your payment was not successful, please try again';
        break;
      default:
        message = 'Something went wrong';
        break;
    }

    return message;
  }

  redirect() {

    // this.router.navigate(['dashboard']);
    this.router.navigate(['profile']);
  }

  setUserData() {
    this.dashboardService.onGetUserData();
  }
}
