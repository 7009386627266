import {Component, OnInit, ViewChild} from '@angular/core';
import {CrudService} from '../../services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {DeleteConfirmationComponent} from '../../layout/modals/delete-confirmation/delete-confirmation.component';
import {ShareVideoDialogComponent} from '../../layout/modals/share-video-dialog/share-video-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import { DashboardService } from 'src/app/services/dashboard.service';
import { debounceTime, Subject, takeUntil } from 'rxjs';

declare var $: any;

@Component({
  selector: 'app-saved-nodes',
  templateUrl: './saved-nodes.component.html',
  styleUrls: ['./saved-nodes.component.css']
})
export class SavedNodesComponent implements OnInit {

  isCreatedNode = true;

  @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;
  @ViewChild('title', {static: false}) currentTitle: any;

  allCollections: any = [];
  isProccess = false;
  collectionName: any = null
  selectedFile: any;
  collectionValue: any;
  collectionId: any;
  isImage = false;
  imgSrc: any;
  isUpdateNode = true
// Share

  // allNodes: any = []
  mainId: any;
  // currentUser: any;
  // myfollowers: any = [];
  // currentUserData: any;
  // currentUserFollowing: any = [];
  // uploaderId: any = '';
  // iAMFollowing: any = false;
  // currentProfileDetails: any;
  // shareVideos: any = [];
  // fullShareURL: any;
  // isCopied = false;
  // findorselect: any;
  imgId: any;
  imgName: any;
  isEditable = false;
  currentItem: any = null;
  currentId: any;
  isLoading = false;
  searchText: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  // private followersPage = 1;

  constructor(
      private dialog: MatDialog,
      private crudService: CrudService,
      private dashboardService: DashboardService,
      private toaster: ToastrService,
      private router: Router
  ) {
    this.dashboardService.getSearchTerm
      .pipe(takeUntil(this._unsubscribeAll), debounceTime(1000))
      .subscribe((res) => {
        this.searchText = res;
        this.getAllCollection();
      });
  }


  ngOnInit(): void {
    this.getAllCollection();
    // this.getMyData();
    // this.getFollowers();
  }

  copyLink() {
  }

  getAllCollection() {
    this.isProccess = true;
    let obj: any = {};
    if (this.searchText) {
      obj.searchText = this.searchText;
    }
    this.crudService.getAllCollections(obj).subscribe((res:any) => {
      this.allCollections = res.data;
      this.isProccess = false;
    })
  }

  getAllCollectionSingleUdpdate() {
    this.crudService.getAllCollections().subscribe((res:any) => {
      this.allCollections = res.data;
    })
  }

  openCreateCollectionModal() {
    $('#createcollectionModal').modal('show');
  }

  hideModal() {
    $('#createcollectionModal').modal('hide');
    $('#updatecollectionModal').modal('hide');
  }

  openConfirmDelete(item: any) {
    this.deleteDialogRef.open({
      header: 'Delete collection',
      title: 'Are you sure you want to delete this collection?'
    }, (e) => {
      if (e) {
        this.isProccess = true;
        this.crudService.deleteCollection(item._id).subscribe(res => {
          this.toaster.success('Collection Deleted successfully');
        }).add(() => {
          this.getAllCollection();
          this.isProccess = false;
        });
      }
    });
  }

  updateCollection(item: any) {
    // console.log(item);
    this.collectionName = item.collectionName;
    this.collectionId = item._id
    $('#updatecollectionModal').modal('show');
  }

  viewAll(item: any) {
    this.router.navigate(['/saved-nodes-album/' + item._id])
  }

  onSelectFile(event) {

    this.selectedFile = event.target.files[0];
    if (this.selectedFile.size > 10485760) {
      this.selectedFile = null;
      this.toaster.error('File size should be less than 10MB');
      return;
    }
    if (this.selectedFile) {
      if (this.selectedFile.type === 'image/png' || this.selectedFile.type === 'image/jpeg') {
        const img = new Image();
        img.src = window.URL.createObjectURL(this.selectedFile);
        img.onload = () => {
          if (img.width < 400 || img.height < 150) {
            this.selectedFile = null;
            this.toaster.info('Minimum image size should be 400 x 150');
          } else {
            this.updateCurrentFile();
          }
        }
      } else {
        this.selectedFile = null;
        this.toaster.info('Please select a PNG or JPG image file.');
      }
    }
  }

  onselectFileForUpload(event) {
    this.selectedFile = event.target.files[0];
    if (this.selectedFile.size > 10485760) {
      this.selectedFile = null;
      this.toaster.error('File size should be less than 10MB');
      return;
    }
    // console.log(file.type)
    if (this.selectedFile) {
      if (this.selectedFile.type === 'image/png' || this.selectedFile.type === 'image/jpeg') {
        const img = new Image();
        img.src = window.URL.createObjectURL(this.selectedFile);
        img.onload = () => {
          if (img.width < 400 || img.height < 150) {
            this.selectedFile = null;
            this.toaster.info('Minimum image size should be 400 x 150');
          } else {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = e => this.imgSrc = reader.result;
            reader.readAsDataURL(file);
            this.isImage = true;
          }
        }
      } else {
        this.selectedFile = null;
        this.toaster.info('Please select a PNG or JPG image file.');
      }
    }


  }

  onCreateCollection() {
    if (!this.collectionValue) {
      this.toaster.error('Please enter collection name')
    } else {
      const formData = new FormData();
      formData.append('file', this.selectedFile);
      formData.append('name', this.collectionValue)
      // console.log('payload is', formData);
      this.isCreatedNode = false;
      this.crudService.addCollection(formData).subscribe(res => {
        this.isCreatedNode = true;
        this.toaster.success('Collection created successfully !')
        this.hideModal()
        this.getAllCollection()
      }, err => {
        if (err) {
          this.isCreatedNode = true;
        }
      })
    }

  }
  updateCurrentCollection() {
    if (!this.collectionName) {
      this.toaster.error('Please enter collection name')
    } else {
      const formData = new FormData();
      formData.append('file', this.selectedFile ? this.selectedFile : null);
      formData.append('name', this.collectionName);
      formData.append('collectionId', this.collectionId);
      this.isUpdateNode = false;
      this.crudService.updateCollection(formData).subscribe(res => {
        // console.log(res);
        this.toaster.success('Collection updated successfully !')
        this.isUpdateNode = true;
        this.hideModal()
        this.getAllCollection();
      }, err => {
        if (err) {
          this.isUpdateNode = true;
        }
      })
    }

  }

  getCurrentId(item: any) {
    this.collectionId = item._id
    // console.log(item);

  }

  updateCurrent(name, item: any) {
    if (name !== item.collectionName) {
      const formData = new FormData();
      formData.append('file', this.selectedFile ? this.selectedFile : null);
      formData.append('name', name);
      formData.append('collectionId', item._id);
      this.isUpdateNode = false;
      this.isProccess = true;
      if (item._id) {
        this.crudService.updateCollection(formData).subscribe(res => {
          this.isUpdateNode = true;
          this.isProccess = false;
          this.hideModal();
          this.getAllCollectionSingleUdpdate();
        }, err => {
          if (err) {
            this.isUpdateNode = true;
          }
        })
      }
    }

  }

  getImgItem(item) {
    this.imgId = item._id;
    this.imgName = item.collectionName;

  }

  updateCurrentFile() {
    const formData = new FormData();
    formData.append('file', this.selectedFile ? this.selectedFile : null);
    formData.append('name', this.imgName);
    formData.append('collectionId', this.imgId);
    this.isUpdateNode = false;
    this.isLoading = true;
    this.toaster.info('Processing...')
    this.crudService.updateCollection(formData).subscribe(res => {
      // console.log(res);
      this.toaster.success('Collection updated successfully !')
      this.isUpdateNode = true;
      this.getAllCollectionSingleUdpdate()
      this.isLoading = false;
      this.hideModal();
    }, err => {
      if (err) {
        this.isUpdateNode = true;
        this.toaster.error(err.error.data);
      }
    })

  }

  mychange(item: any) {
    // console.log(item);
    this.currentItem = item.collectionName
    this.isEditable = true;
    this.currentId = item._id;
  }

  //
  // openTreeShareModal(item:any) {
  //   this.fullShareURL = environment.currentSharedDomain + 'videos?video=' + item._id;
  //   $('#Tree-share-Modal').modal('show');
  // }

  openTreeShareModal(id) {
    id = id._id;
    const dialogRef = this.dialog.open(ShareVideoDialogComponent, {
      width: '100%',
      maxWidth: '600px',
      minHeight: '400px',
      panelClass: 'my-dialog',
      height: 'auto',
      data: {
        title: 'Share the Collection',
        videoID: id,
        URL: `${environment.currentSharedDomain}videos?video=${id}`
      },
    });
  }


// share collection code
// getMyData() {
//   //  //console.log('current user is',this.currentUser);
//   if (this.currentUser) {
//     this.crudService.getUserInfoV2(this.currentUser).subscribe(
//       success => {
//         this.currentUserData = success.data.userinfo
//         // this.currentUserFollowing = success.data?.followinginfo;
//         // this.myfollowers = success.data.followersinfo
//         // for (const i of this.currentUserFollowing) {
//         //   if (i.userinfo._id === this.uploaderId) {
//         //     this.iAMFollowing = true;
//         //     break;
//         //   }
//         // }
//
//         // for (const i of success.data.followersinfo) {
//         //
//         //   this.myfollowers.push(i.info)
//         // }
//         this.currentProfileDetails = success.data.userinfo
//       })
//   }
// }
// selectToShare(id: any) {
//   if (this.shareVideos.includes(id)) {
//     const index = this.shareVideos.indexOf(id);
//     if (index > -1) {
//       this.shareVideos.splice(index, 1);
//     }
//   } else {
//     this.shareVideos.push(id)
//   }
// }
//
//   popupEmail() {
//     const url = `mailto:?subject=Interactive Video&body=Check this interactive video experience I made on FreeFuse: ${this.fullShareURL}`;
//     const newwindow = window.open(url);
//     if (window.focus) {
//       newwindow.focus()
//     }
//   }
//
//   Tpopupfacebook() {
//     const url = 'http://www.facebook.com/sharer.php?u=' + this.fullShareURL;
//     const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
//     if (window.focus) {
//       newwindow.focus()
//     }
//   }
//
//   Tpopuptweet() {
//   const url = 'https://twitter.com/intent/tweet?text=' + this.fullShareURL;
//   const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
//   if (window.focus) {
//     newwindow.focus()
//   }
// }
//
// Tpopuplinkedin() {
//
//   const url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + this.fullShareURL;
//   const newwindow = window.open(url, 'name', 'height=500,width=520,top=200,left=300,resizable');
//   if (window.focus) {
//     newwindow.focus()
//   }
// }
//
// shareTousers(id: any,isMain?:any) {
//   let x= null;
//   let obj;
//   const url = this.fullShareURL;
//    x = url.substring(27)
//   // this.fullShareURL =id;
//   $('#' + id).prop('disabled', true);
//   if(isMain){
//      obj = {
//       sharedWith: this.shareVideos,
//       videoID: this.mainId
//     }
//   }else{
//      obj = {
//       sharedWith: this.shareVideos,
//        videoID: x
//     }
//   }
//
//
//   this.crudService.shareInternally(obj).subscribe(
//     success => {
//       this.toaster.success('Video Shared!')
//       this.shareVideos = [];
//       for (const i of this.myfollowers) {
//         i.selected = false;
//       }
//       $('#' + id).prop('disabled', false);
//     }
//   )
//
//
// }

  // onScroll(): void {
  //   ++this.followersPage;
  //   this.getFollowers();
  // }
  //
  // getFollowers() {
  //
  //   this.crudService.getFollowers(10, this.followersPage).subscribe(success => {
  //
  //     const followers = success.data.followers;
  //
  //     for (const item of followers) {
  //       this.myfollowers.push(item);
  //     }
  //   })
  // }

}

