import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CrudService } from 'src/app/services/crud.service';

declare var $;

@Component({
  selector: 'app-modern-template',
  templateUrl: './modern-template.component.html',
  styleUrls: ['./modern-template.component.css']
})
export class ModernTemplateComponent implements OnInit {

  @Input() sellerInformation;
  @Input() subscriptionGroups = [];
  @Input() nonSubscriptionGroups = [];
  @Input() welcomeVideo: any;
  @Input() spinner: any = false;
  @Input() allGroups: any = [];
  @Input() isOwnCommunity;
  @Input() logoPosition;
  @Input() editProfileObject: any;

  @Output() showPlans = new EventEmitter();
  @Output() invite = new EventEmitter();
  @Output() openReorderModal = new EventEmitter();
  @Output() downloadSubscribers = new EventEmitter();
  @Output() createAnnouncement = new EventEmitter();
  @Output() onMyGroup = new EventEmitter();
  @Output() getSellerDetails = new EventEmitter();
  @Output() openUpdateBusinessModal = new EventEmitter();

  currentTab = 'subscription';
  totalGroup = 7;
  updatedLogoPosition:any;
  doNotShowWelcomeVideo = false;

  constructor(public crudService: CrudService) { 
    
    this.getSellerDetails.emit(); 
  }
  
  ngOnInit(): void {
    if (this.currentTab === "subscription") {
      this.allGroups = this.subscriptionGroups;
    } else {
      this.allGroups = this.nonSubscriptionGroups;
    }
    console.log("sellerInformation", this.editProfileObject);
  }

  tabChange(tab) {
    this.currentTab = tab;
    this.totalGroup = 3;
    if (this.currentTab === "subscription") {
      this.allGroups = this.subscriptionGroups;
    } else {
      this.allGroups = this.nonSubscriptionGroups;
    }
    if (this.totalGroup >= this.allGroups.length) {
      this.totalGroup = this.allGroups.length;
    }
  }

  viewAllGroup() {
    this.totalGroup = this.allGroups.length;
  }

  showPricingPlans() {
    this.showPlans.emit();
  }

  openBusinessModal() {
    if (this.editProfileObject?.business?.templateName == "Modern") {
      this.openUpdateBusinessModal.emit();
    }
  }

  inviteModal() {
    this.invite.emit();
  }

  openReorderGroupModal() {
    $("#rearrangeGroupsDialog").modal("show");
  }

  downloadSubscribersUser() {
    this.downloadSubscribers.emit();
  }

  createAnnouncementModal() {
    this.createAnnouncement.emit();
  }

  onMyGroupClick(item) {
    this.onMyGroup.emit(item);
  }

  
  fliterGroupNameWithSpecialChar(groupName:any){
    return groupName.replace(/&amp;/g, '&');
  }

  
  drop(event: CdkDragDrop<string[]>) {
    let data = [];
    if (this.currentTab === "subscription") {
      moveItemInArray(
        this.subscriptionGroups,
        event.previousIndex,
        event.currentIndex
      );
      data = this.subscriptionGroups.map((x, i) => {
        return { groupId: x._id, currIndex: i };
      });
    } else {
      moveItemInArray(
        this.nonSubscriptionGroups,
        event.previousIndex,
        event.currentIndex
      );
      data = this.nonSubscriptionGroups.map((x, i) => {
        return { groupId: x._id, currIndex: i };
      });
    }
    this.crudService.reorderGroups(data).subscribe();
  }
}
