import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { CrudService } from 'src/app/services/crud.service';

declare const gapi: any; // Declare `gapi` as any to avoid TypeScript errors

@Component({
  selector: 'app-feature-group-overview',
  templateUrl: './feature-group-overview.component.html',
  styleUrls: ['./feature-group-overview.component.css']
})
export class FeatureGroupOverviewComponent implements OnInit {
  spinner:boolean = false;
  groupDetail:any;
  groupId:any;
  constructor(
    private api: CrudService, 
    private activateRoute: ActivatedRoute, 
    public dialogRef: MatDialogRef<FeatureGroupOverviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {    
    this.groupId = this.data;
    this.getGroupDetail();
  }


  getGroupDetail(){
    this.spinner = true;
    this.api.getGroupDetail(this.groupId).subscribe(res => {
      this.spinner = false;
      this.groupDetail = res.data[0];
  })
  }

  // generateGoogleCalendarLink() {
  //   const eventTitle = 'Project Meeting';
  //   const eventDescription = 'Discuss project details';
  //   const eventLocation = 'Online';
  //   const eventStart = '20240915T090000Z'; // In UTC format
  //   const eventEnd = '20240915T100000Z'; // In UTC format
  //   const attendees = ['attendee1@example.com', 'attendee2@example.com'];
  
  //   const googleCalendarUrl = `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(eventTitle)}&dates=${eventStart}/${eventEnd}&details=${encodeURIComponent(eventDescription)}&location=${encodeURIComponent(eventLocation)}&add=${attendees.join(',')}`;
  
  //   window.open(googleCalendarUrl, '_blank');
  // }

  
}
