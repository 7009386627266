export const environment = {
    production: false,

    // ================================================= STAGING ====================================================
    API_URL: 'https://backend.freefusetest.be/api/v1',
    API_URLV2: 'https://backend.freefusetest.be/api/v2',
    currentDomain: 'https://freefusetest.be/',
    currentSharedDomain: 'https://freefusetest.be/',
    AI_WEB_HOOK: 'https://smarteditor.freefusetest.be/api/v1/aiEditorStatus',
    SmartEditor_API_URL: 'https://smarteditor.freefusetest.be/api/v1',
    AI_PARSE_URL: 'https://ai-editor-requesthandler.freefusetest.be/api/v1',
    AI_VIDEO_UPLOAD_BUCKET: 'freefuse-ai-videos',
    GA_TRACKING_ID: 'UA-171114696-1',
    Stripe_PK: 'pk_test_51JgtGJK0aI2bBfvUruXy7RVe55THcnNkxbeqraRnJA0XF4bUlqfoao5FywGvQr4dhh1hUfVoqOJrmG4JkH0PUoIl0007PUrCnL',
    DataRequestForm: 'https://forms.gle/GCbsXmzc2kXgc9NU9',
    Success_Payment_Redirect: 'https://freefusetest.be/paymentConfirmation',
    Update_Payment_Redirect: 'https://freefusetest.be/settings',
    Plan_Listings: ['627e7191f76daf5009dc2ae7', '627e763ef76daf5009dc2af0', '627e75fcf76daf5009dc2aef'],
    Addl_Yearly_Discount_Pct: 5,

    // Landing Page Video for showing Why FreeFuse Video Tree for Engage, Educate, and Inspire with Personalized Content Journeys
    whyFreeFuseVideoId: '656238a0f38ad23529835f9e',

    //Landing Page Video for How FreeFuse Transforms Content Journeys
    homeVideoTreeId: '656238a0f38ad23529835f9e',
    homeRegularVideoUrl: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/real.mp4",
    homeRegularThumbnailUrl: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/EkZVisy_B8o4CzzBD3TTLUuO.png",

    //Landing Page Testimonial Videos
    testimonialVideoUrl1: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/Dale_Brown_DUST.mp4",
    testimonialPosterUrl1: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/DaleBrownDUST20221108T192542825Z.0000001.jpeg",

    testimonialVideoUrl2: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/David+Winkelman+Testimonial.mp4",
    testimonialPosterUrl2: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/DavidWinkelmanTestimonial.jpeg",

    testimonialVideoUrl3: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/Moises_Solis_CreateINterest.mp4",
    testimonialPosterUrl3: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/MoisesSolisCreateINterest20221108T192719470Z.0000000.jpeg",

    testimonialVideoUrl4: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/TapRooT+Testimonial.mp4",
    testimonialPosterUrl4: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/TapRooTTestimonial20221108T192154972Z.0000001.jpeg",

    testimonialVideoUrl5: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/Kim+Daniel+Texas+A%26M+Energy+Institute.mp4",
    testimonialPosterUrl5: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/KimDanielTexasAMEnergyInstitute20221108T192323223Z.0000000.jpeg",

    testimonialVideoUrl6: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/Rex+Testimonial.mp4",
    testimonialPosterUrl6: "https://videoplyback-encoding-bucket.s3.amazonaws.com/FreeFuse/RexTestimonial20240905T035122028Z.0000000.jpeg",

    Community_Success_Payment_Redirect: 'https://freefusetest.be/community-payment-confirmation',

    ImgLy_License: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ2ZXJzaW9uIjoxLCJleHAiOjMyNzMyNjQwMDAsInN1YiI6IkZyZWVGdXNlLCBJbmMuIiwidHlwZSI6IlByb2R1Y3Rpb24iLCJwcm9kdWN0VmVyc2lvbiI6MSwicGxhdGZvcm1zIjpbIldlYiJdfQ.oCpf_ulWyfhpDcg9JqACAuxgcusk6m2xZfAcnI39NPBZbzVHFTKcIwswLxuYogePVNsIC8F48vkVMbBpviRp3nyie92xf1nkbvKUBS8ZriQD8gv1lUDFl0gnyhTkMpAQQa2Glk17csyC8V77twKu9MoNauL6fLtSGf4w0WZnmf4Cr15LZ5cRDqh2yVpxLN9WgZ7rqsBm67VNfit_RDrOnp4Ha3qlSiKZ-9C6DTHLdVq_DUHNJJcHLjIUNI7hrq8Jb3nWY_9-rXPXXfI5DU0Kw5nrdTeVg0Swz-mqlHymA8pUPq9NOSveW5NILRFh_dsyso4xC_631WCK_lSay0y-J892pUEWtrymoYarMb1J5muzDFufj3kHEUCaD3pUJ0_Nprc0UDibdXh_YNgEEdV-ueR9rwLA-VNy3lfgoW9AUCO_aLC0N2yPIpgPlqQ9lN6EjUn3IKg1FnC0gzGijDd6PN2GVKkcprFayPvqwUG86aXmFRDJcQrx6qdqLg_R9Fgb',

    //Template Definitions
    How_can_I_help_Template : '65dff34ef463e0968b9a8fa4',
    No_Touch_Customer_Experience : '65e00751cd5f8bd45959ea5b',
    second_Scenario_Stack : '65e00987cd5f8bd45959f1c7',
    Training_Paths : '65e00bd9cd5f8bd45959f66b',
    Training_Paths_Scenarios : '65e00caccd5f8bd45959f9b5',
    Learning_Theory_Activity_Paths : '65e00e2acd5f8bd45959fe17',

    //Lexie AI ChatBot suggestion timeout
    lexieAI_Suggestion_PopUp_Timeout_Sec: 15,

    //Google API
    GAPI_GDrive_ClientId: '907581750443-4oipuvj1uggaucc5otlirp6m0f13bulc.apps.googleusercontent.com',
    GAPI_API_Key: 'AIzaSyA9VbH1Px2wS9Dm68NMauR3YKTMRLm9mIs',

    DROPBOX_app_key: 'tcb9j0b935x4ove',
    FreeFuse_GroupId: '66135bd2b305e4ddc1df9a44',

    maliciousHtmlTags: [
      "a2", "abbr", "animatemotion", "animatetransform", "applet", 
      "audio2", "b", "bdi", "bdo", "blockquote", 
      "col", "colgroup", "custom tags", "datalist", "dd", "del", "dfn", 
      "div", "dl", "dt", "embed", "fieldset", "figcaption", 
      "frameset", "h1", "hgroup", 
      "html", "i", "iframe", "iframe2", "image2", "image3", "img2", 
      "input2", "input3", "input4", "ins", "kbd", "keygen", 
      "multicol", "nav", "nextid", "nobr", "noembed", "noframes", "noscript", "optgroup", 
      "param", "plaintext", "pre", "q", "rb", 
      "rp", "rt", "rtc", "s", "samp", "sup", "svg", "tbody", "td", "textarea", "tfoot", "th", "thead", 
      "tr", "track", "tt", "var", "video2", "wbr", "xmp"
    ],
      
    //XSS Script Prevention
    maliciousEventHandlers: ["onafterprint", "onafterscriptexecute", "onanimationcancel", "onanimationend", 
      "onanimationiteration", "onanimationstart", "onauxclick", "onbeforecopy", "onbeforecut", "onbeforeinput", 
      "onbeforeprint", "onbeforescriptexecute", "onbeforetoggle", "onbeforeunload", "onbegin", "onblur", "oncanplay", 
      "oncanplaythrough", "onchange", "onclick", "onclose", "oncontextmenu", "oncopy", "oncuechange", "oncut", "ondblclick", 
      "ondrag", "ondragend", "ondragenter", "ondragexit", "ondragleave", "ondragover", "ondragstart", "ondrop", "ondurationchange", 
      "onend", "onended", "onerror", "onfocus", "onfocus(autofocus)", "onfocusin", "onfocusout", "onformdata", "onfullscreenchange", 
      "onhashchange", "oninput", "oninvalid", "onkeydown", "onkeypress", "onkeyup", "onload", "onloadeddata", "onloadedmetadata", 
      "onloadstart", "onmessage", "onmousedown", "onmouseenter", "onmouseleave", "onmousemove", "onmouseout", "onmouseover", "onmouseup",
      "onmousewheel", "onmozfullscreenchange", "onpagehide", "onpageshow", "onpaste", "onpause", "onplay", "onplaying", "onpointercancel", 
      "onpointerdown", "onpointerenter", "onpointerleave", "onpointermove", "onpointerout", "onpointerover", "onpointerrawupdate", 
      "onpointerup", "onpopstate", "onprogress", "onratechange", "onrepeat", "onreset", "onresize", "onscroll", "onscrollend", "onsearch",
      "onseeked", "onseeking", "onselect", "onselectionchange", "onselectstart", "onshow", "onsubmit", "onsuspend", "ontimeupdate", "ontoggle", 
      "ontoggle(popover)", "ontouchend", "ontouchmove", "ontouchstart", "ontransitioncancel", "ontransitionend", "ontransitionrun", 
      "ontransitionstart", "onunhandledrejection", "onunload", "onvolumechange", "onwebkitanimationend", "onwebkitanimationiteration", 
      "onwebkitanimationstart", "onwebkitmouseforcechanged", "onwebkitmouseforcedown", "onwebkitmouseforceup", 
      "onwebkitmouseforcewillbegin", "onwebkitplaybacktargetavailabilitychanged", "onwebkittransitionend", "onwebkitwillrevealbottom", 
      "onwheel"],

      // Co-Branding logo mapping & Replace any hypen with underscore.
      staaj_health_coBrandingLogo : '../assets/images/STAAJ HEALTH POWERED BY FREEFUSE LOGO.png',


//     // // ======================================================= LOCAL DEV WITH STAGING API ==========================================================

    // API_URL: 'https://backend.freefusetest.be/api/v1',
    // API_URLV2: 'https://backend.freefusetest.be/api/v2',
    // currentDomain: 'https://freefusetest.be/',
    // currentSharedDomain: 'https://freefusetest.be/',
    // AI_WEB_HOOK: 'https://smarteditor.freefusetest.be/api/v1/aiEditorStatus',
    // SmartEditor_API_URL: 'https://smarteditor.freefusetest.be/api/v1',
    // AI_PARSE_URL: 'https://ai-editor-requesthandler.freefusetest.be/api/v1',
    // AI_VIDEO_UPLOAD_BUCKET: 'freefuse-ai-videos',
    // // AI_VIDEO_UPLOAD_BUCKET: 'freefuse-ai-videos-staging',
    // GA_TRACKING_ID: 'UA-171114696-1',
    // Stripe_PK: 'pk_test_51JgtGJK0aI2bBfvUruXy7RVe55THcnNkxbeqraRnJA0XF4bUlqfoao5FywGvQr4dhh1hUfVoqOJrmG4JkH0PUoIl0007PUrCnL',
    // DataRequestForm: 'https://forms.gle/GCbsXmzc2kXgc9NU9',
    // Success_Payment_Redirect: 'https://freefusetest.be/paymentConfirmation',
    // Update_Payment_Redirect: 'https://freefusetest.be/settings',
    // Plan_Listings: ['627e7191f76daf5009dc2ae7', '627e763ef76daf5009dc2af0', '627e75fcf76daf5009dc2aef'],
    // Addl_Yearly_Discount_Pct: 5,

    // //Landing Page Video for showing Why FreeFuse Video Tree for Engage, Educate, and Inspire with Personalized Content Journeys
    // whyFreeFuseVideoId: '656238a0f38ad23529835f9e',

    //Landing Page Video for How FreeFuse Transforms Content Journeys
    //homeVideoTreeId: '656238a0f38ad23529835f9e',
    // homeRegularVideoUrl: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/real.mp4",
    // homeRegularThumbnailUrl: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/EkZVisy_B8o4CzzBD3TTLUuO.png",

    // //Landing Page Testimonial Videos
    // testimonialVideoUrl1: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/Dale_Brown_DUST.mp4",
    // testimonialPosterUrl1: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/DaleBrownDUST20221108T192542825Z.0000001.jpeg",

    // testimonialVideoUrl2: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/David+Winkelman+Testimonial.mp4",
    // testimonialPosterUrl2: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/DavidWinkelmanTestimonial.jpeg",

    // testimonialVideoUrl3: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/Moises_Solis_CreateINterest.mp4",
    // testimonialPosterUrl3: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/MoisesSolisCreateINterest20221108T192719470Z.0000000.jpeg",

    // testimonialVideoUrl4: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/TapRooT+Testimonial.mp4",
    // testimonialPosterUrl4: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/TapRooTTestimonial20221108T192154972Z.0000001.jpeg",

    // testimonialVideoUrl5: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/Kim+Daniel+Texas+A%26M+Energy+Institute.mp4",
    // testimonialPosterUrl5: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/KimDanielTexasAMEnergyInstitute20221108T192323223Z.0000000.jpeg",

    // testimonialVideoUrl6: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/Rex+Testimonial.mp4",
    // testimonialPosterUrl6: "https://videoplyback-encoding-bucket.s3.amazonaws.com/FreeFuse/RexTestimonial20240905T035122028Z.0000000.jpeg",

    // Community_Success_Payment_Redirect: 'https://freefusetest.be/community-payment-confirmation',

    // // //WC 09122023
    // ImgLy_License: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ2ZXJzaW9uIjoxLCJleHAiOjMyNzMyNjQwMDAsInN1YiI6IkZyZWVGdXNlLCBJbmMuIiwidHlwZSI6IlByb2R1Y3Rpb24iLCJwcm9kdWN0VmVyc2lvbiI6MSwicGxhdGZvcm1zIjpbIldlYiJdfQ.oCpf_ulWyfhpDcg9JqACAuxgcusk6m2xZfAcnI39NPBZbzVHFTKcIwswLxuYogePVNsIC8F48vkVMbBpviRp3nyie92xf1nkbvKUBS8ZriQD8gv1lUDFl0gnyhTkMpAQQa2Glk17csyC8V77twKu9MoNauL6fLtSGf4w0WZnmf4Cr15LZ5cRDqh2yVpxLN9WgZ7rqsBm67VNfit_RDrOnp4Ha3qlSiKZ-9C6DTHLdVq_DUHNJJcHLjIUNI7hrq8Jb3nWY_9-rXPXXfI5DU0Kw5nrdTeVg0Swz-mqlHymA8pUPq9NOSveW5NILRFh_dsyso4xC_631WCK_lSay0y-J892pUEWtrymoYarMb1J5muzDFufj3kHEUCaD3pUJ0_Nprc0UDibdXh_YNgEEdV-ueR9rwLA-VNy3lfgoW9AUCO_aLC0N2yPIpgPlqQ9lN6EjUn3IKg1FnC0gzGijDd6PN2GVKkcprFayPvqwUG86aXmFRDJcQrx6qdqLg_R9Fgb',
    // // //WC 09122023

    // //Template Definitions
    // How_can_I_help_Template : '65dff34ef463e0968b9a8fa4',
    // No_Touch_Customer_Experience : '65e00751cd5f8bd45959ea5b',
    // second_Scenario_Stack : '65e00987cd5f8bd45959f1c7',
    // Training_Paths : '65e00bd9cd5f8bd45959f66b',
    // Training_Paths_Scenarios : '65e00caccd5f8bd45959f9b5',
    // Learning_Theory_Activity_Paths : '65e00e2acd5f8bd45959fe17',

    // // Lexie AI ChatBot suggestion timeout
    // lexieAI_Suggestion_PopUp_Timeout_Sec: 15,

    // // Google API
    // GAPI_GDrive_ClientId: '',
    // GAPI_API_Key: '',

    // DROPBOX_app_key: 'tcb9j0b935x4ove',
    // FreeFuse_GroupId: '66135bd2b305e4ddc1df9a44',

    // maliciousHtmlTags: [
    //   "a2", "abbr", "animatemotion", "animatetransform", "applet", 
    //   "audio2", "b", "bdi", "bdo", "blockquote", 
    //   "col", "colgroup", "custom tags", "datalist", "dd", "del", "dfn", 
    //   "div", "dl", "dt", "embed", "fieldset", "figcaption", 
    //   "frameset", "h1", "hgroup", 
    //   "html", "i", "iframe", "iframe2", "image2", "image3", "img2", 
    //   "input2", "input3", "input4", "ins", "kbd", "keygen", 
    //   "multicol", "nav", "nextid", "nobr", "noembed", "noframes", "noscript", "optgroup", 
    //   "param", "plaintext", "pre", "q", "rb", 
    //   "rp", "rt", "rtc", "s", "samp", "sup", "svg", "tbody", "td", "textarea", "tfoot", "th", "thead", 
    //   "tr", "track", "tt", "var", "video2", "wbr", "xmp"
    // ],
      
    //   //XSS Script Prevention
    //   maliciousEventHandlers: ["onafterprint", "onafterscriptexecute", "onanimationcancel", "onanimationend", 
    //     "onanimationiteration", "onanimationstart", "onauxclick", "onbeforecopy", "onbeforecut", "onbeforeinput", 
    //     "onbeforeprint", "onbeforescriptexecute", "onbeforetoggle", "onbeforeunload", "onbegin", "onblur", "oncanplay", 
    //     "oncanplaythrough", "onchange", "onclick", "onclose", "oncontextmenu", "oncopy", "oncuechange", "oncut", "ondblclick", 
    //     "ondrag", "ondragend", "ondragenter", "ondragexit", "ondragleave", "ondragover", "ondragstart", "ondrop", "ondurationchange", 
    //     "onend", "onended", "onerror", "onfocus", "onfocus(autofocus)", "onfocusin", "onfocusout", "onformdata", "onfullscreenchange", 
    //     "onhashchange", "oninput", "oninvalid", "onkeydown", "onkeypress", "onkeyup", "onload", "onloadeddata", "onloadedmetadata", 
    //     "onloadstart", "onmessage", "onmousedown", "onmouseenter", "onmouseleave", "onmousemove", "onmouseout", "onmouseover", "onmouseup",
    //     "onmousewheel", "onmozfullscreenchange", "onpagehide", "onpageshow", "onpaste", "onpause", "onplay", "onplaying", "onpointercancel", 
    //     "onpointerdown", "onpointerenter", "onpointerleave", "onpointermove", "onpointerout", "onpointerover", "onpointerrawupdate", 
    //     "onpointerup", "onpopstate", "onprogress", "onratechange", "onrepeat", "onreset", "onresize", "onscroll", "onscrollend", "onsearch",
    //     "onseeked", "onseeking", "onselect", "onselectionchange", "onselectstart", "onshow", "onsubmit", "onsuspend", "ontimeupdate", "ontoggle", 
    //     "ontoggle(popover)", "ontouchend", "ontouchmove", "ontouchstart", "ontransitioncancel", "ontransitionend", "ontransitionrun", 
    //     "ontransitionstart", "onunhandledrejection", "onunload", "onvolumechange", "onwebkitanimationend", "onwebkitanimationiteration", 
    //     "onwebkitanimationstart", "onwebkitmouseforcechanged", "onwebkitmouseforcedown", "onwebkitmouseforceup", 
    //     "onwebkitmouseforcewillbegin", "onwebkitplaybacktargetavailabilitychanged", "onwebkittransitionend", "onwebkitwillrevealbottom", 
    //     "onwheel"],

    // Co-Branding logo mapping & Replace any hypen with underscore.
    // staaj_health_coBrandingLogo : '../assets/images/STAAJ HEALTH POWERED BY FREEFUSE LOGO.png',

// // ===================================== LOCAL DEV WITH LOCALHOST API ==================================================

// API_URL: 'http://localhost:9000/api/v1',
// API_URLV2: 'http://localhost:9000/api/v2',
// currentDomain: 'http://localhost:4200/',
// currentSharedDomain: 'http://localhost:4200/',
// AI_WEB_HOOK: 'https://smarteditor.freefusetest.com/api/v1/aiEditorStatus',
// //AI_PARSE_URL: 'http://localhost:9001/api/v1',
// AI_PARSE_URL: 'https://ai-editor-requesthandler.freefusetest.be/api/v1',
// SmartEditor_API_URL: 'http://localhost:3000/api/v1',

// AI_VIDEO_UPLOAD_BUCKET: 'freefuse-ai-videos',
// GA_TRACKING_ID: 'UA-171114696-1',
// Stripe_PK: 'pk_test_51JgtGJK0aI2bBfvUruXy7RVe55THcnNkxbeqraRnJA0XF4bUlqfoao5FywGvQr4dhh1hUfVoqOJrmG4JkH0PUoIl0007PUrCnL',
// DataRequestForm: 'https://forms.gle/GCbsXmzc2kXgc9NU9',
// Success_Payment_Redirect: 'http://localhost:4200/paymentConfirmation',
// Update_Payment_Redirect: 'http://localhost:4200/settings',
// Plan_Listings: ['627e7191f76daf5009dc2ae7', '627e763ef76daf5009dc2af0', '627e75fcf76daf5009dc2aef'],
// Addl_Yearly_Discount_Pct: 5,

// //Landing Page Video for How FreeFuse Transforms Content Journeys
// homeVideoTreeId: '656238a0f38ad23529835f9e',
// homeRegularVideoUrl: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/real.mp4",
// homeRegularThumbnailUrl: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/EkZVisy_B8o4CzzBD3TTLUuO.png",

// //Landing Page Video for showing Why FreeFuse Video Tree for Engage, Educate, and Inspire with Personalized Content Journeys
// whyFreeFuseVideoId: '656238a0f38ad23529835f9e',

// //Landing Page Testimonial Videos
// testimonialVideoUrl1: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/Dale_Brown_DUST.mp4",
// testimonialPosterUrl1: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/DaleBrownDUST20221108T192542825Z.0000001.jpeg",

// testimonialVideoUrl2: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/David+Winkelman+Testimonial.mp4",
// testimonialPosterUrl2: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/DavidWinkelmanTestimonial.jpeg",

// testimonialVideoUrl3: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/Moises_Solis_CreateINterest.mp4",
// testimonialPosterUrl3: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/MoisesSolisCreateINterest20221108T192719470Z.0000000.jpeg",

// testimonialVideoUrl4: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/TapRooT+Testimonial.mp4",
// testimonialPosterUrl4: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/TapRooTTestimonial20221108T192154972Z.0000001.jpeg",

// testimonialVideoUrl5: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/Kim+Daniel+Texas+A%26M+Energy+Institute.mp4",
// testimonialPosterUrl5: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/KimDanielTexasAMEnergyInstitute20221108T192323223Z.0000000.jpeg",

// testimonialVideoUrl6: "https://videoplyback-encoding-bucket.s3-accelerate.amazonaws.com/FreeFuse/Rex+Testimonial.mp4",
// testimonialPosterUrl6: "https://videoplyback-encoding-bucket.s3.amazonaws.com/FreeFuse/RexTestimonial20240905T035122028Z.0000000.jpeg",

// Community_Success_Payment_Redirect: 'http://localhost:4200/community-payment-confirmation',

// // //WC 09122023
// ImgLy_License: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJ2ZXJzaW9uIjoxLCJleHAiOjMyNzMyNjQwMDAsInN1YiI6IkZyZWVGdXNlLCBJbmMuIiwidHlwZSI6IlByb2R1Y3Rpb24iLCJwcm9kdWN0VmVyc2lvbiI6MSwicGxhdGZvcm1zIjpbIldlYiJdfQ.oCpf_ulWyfhpDcg9JqACAuxgcusk6m2xZfAcnI39NPBZbzVHFTKcIwswLxuYogePVNsIC8F48vkVMbBpviRp3nyie92xf1nkbvKUBS8ZriQD8gv1lUDFl0gnyhTkMpAQQa2Glk17csyC8V77twKu9MoNauL6fLtSGf4w0WZnmf4Cr15LZ5cRDqh2yVpxLN9WgZ7rqsBm67VNfit_RDrOnp4Ha3qlSiKZ-9C6DTHLdVq_DUHNJJcHLjIUNI7hrq8Jb3nWY_9-rXPXXfI5DU0Kw5nrdTeVg0Swz-mqlHymA8pUPq9NOSveW5NILRFh_dsyso4xC_631WCK_lSay0y-J892pUEWtrymoYarMb1J5muzDFufj3kHEUCaD3pUJ0_Nprc0UDibdXh_YNgEEdV-ueR9rwLA-VNy3lfgoW9AUCO_aLC0N2yPIpgPlqQ9lN6EjUn3IKg1FnC0gzGijDd6PN2GVKkcprFayPvqwUG86aXmFRDJcQrx6qdqLg_R9Fgb',
// // //WC 09122023

// // Template definitions
// How_can_I_help_Template : '65dff34ef463e0968b9a8fa4',
// No_Touch_Customer_Experience : '65e00751cd5f8bd45959ea5b',
// second_Scenario_Stack : '65e00987cd5f8bd45959f1c7',
// Training_Paths : '65e00bd9cd5f8bd45959f66b',
// Training_Paths_Scenarios : '65e00caccd5f8bd45959f9b5',
// Learning_Theory_Activity_Paths : '65e00e2acd5f8bd45959fe17',

// // Lexie AI ChatBot suggestion timeout
// lexieAI_Suggestion_PopUp_Timeout_Sec: 15,

// //Google API
// GAPI_GDrive_ClientId: '',
// GAPI_API_Key: '',

// DROPBOX_app_key: 'tcb9j0b935x4ove',
// FreeFuse_GroupId: '66135bd2b305e4ddc1df9a44',

// //XSS Script Prevention
// maliciousHtmlTags: [
//     "a2", "abbr", "animatemotion", "animatetransform", "applet", 
//     "audio2", "b", "bdi", "bdo", "blockquote", 
//     "col", "colgroup", "custom tags", "datalist", "dd", "del", "dfn", 
//     "div", "dl", "dt", "embed", "fieldset", "figcaption", 
//     "frameset", "h1", "hgroup", 
//     "html", "i", "iframe", "iframe2", "image2", "image3", "img2", 
//     "input2", "input3", "input4", "ins", "kbd", "keygen", 
//     "multicol", "nav", "nextid", "nobr", "noembed", "noframes", "noscript", "optgroup", 
//     "param", "plaintext", "pre", "q", "rb", 
//     "rp", "rt", "rtc", "s", "samp", "sup", "svg", "tbody", "td", "textarea", "tfoot", "th", "thead", 
//     "tr", "track", "tt", "var", "video2", "wbr", "xmp"
//   ],
  
//   //XSS Script Prevention
//   maliciousEventHandlers: ["onafterprint", "onafterscriptexecute", "onanimationcancel", "onanimationend", 
//     "onanimationiteration", "onanimationstart", "onauxclick", "onbeforecopy", "onbeforecut", "onbeforeinput", 
//     "onbeforeprint", "onbeforescriptexecute", "onbeforetoggle", "onbeforeunload", "onbegin", "onblur", "oncanplay", 
//     "oncanplaythrough", "onchange", "onclick", "onclose", "oncontextmenu", "oncopy", "oncuechange", "oncut", "ondblclick", 
//     "ondrag", "ondragend", "ondragenter", "ondragexit", "ondragleave", "ondragover", "ondragstart", "ondrop", "ondurationchange", 
//     "onend", "onended", "onerror", "onfocus", "onfocus(autofocus)", "onfocusin", "onfocusout", "onformdata", "onfullscreenchange", 
//     "onhashchange", "oninput", "oninvalid", "onkeydown", "onkeypress", "onkeyup", "onload", "onloadeddata", "onloadedmetadata", 
//     "onloadstart", "onmessage", "onmousedown", "onmouseenter", "onmouseleave", "onmousemove", "onmouseout", "onmouseover", "onmouseup",
//     "onmousewheel", "onmozfullscreenchange", "onpagehide", "onpageshow", "onpaste", "onpause", "onplay", "onplaying", "onpointercancel", 
//     "onpointerdown", "onpointerenter", "onpointerleave", "onpointermove", "onpointerout", "onpointerover", "onpointerrawupdate", 
//     "onpointerup", "onpopstate", "onprogress", "onratechange", "onrepeat", "onreset", "onresize", "onscroll", "onscrollend", "onsearch",
//     "onseeked", "onseeking", "onselect", "onselectionchange", "onselectstart", "onshow", "onsubmit", "onsuspend", "ontimeupdate", "ontoggle", 
//     "ontoggle(popover)", "ontouchend", "ontouchmove", "ontouchstart", "ontransitioncancel", "ontransitionend", "ontransitionrun", 
//     "ontransitionstart", "onunhandledrejection", "onunload", "onvolumechange", "onwebkitanimationend", "onwebkitanimationiteration", 
//     "onwebkitanimationstart", "onwebkitmouseforcechanged", "onwebkitmouseforcedown", "onwebkitmouseforceup", 
//     "onwebkitmouseforcewillbegin", "onwebkitplaybacktargetavailabilitychanged", "onwebkittransitionend", "onwebkitwillrevealbottom", 
//     "onwheel"],

// Co-Branding logo mapping & Replace any hypen with underscore.
// staaj_health_coBrandingLogo : '../assets/images/STAAJ HEALTH POWERED BY FREEFUSE LOGO.png',

};

