import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from 'rxjs/operators';
import {CrudService} from '../../../services/crud.service';
import {Subject} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CdkDragMove} from '@angular/cdk/drag-drop';
import {ToastrService} from 'ngx-toastr';
import * as _ from 'lodash';

declare var $;


@Component({
    selector: 'app-position-choices',
    templateUrl: './position-choices.component.html',
    styleUrls: ['./position-choices.component.css']
})
export class PositionChoicesComponent implements OnInit, OnDestroy {
    spinner = true;
    mainVideo: any;
    uploaderDetails: any;
    private uploaderId: any;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    nodes = [];
    dynamicPositionChoicesData = {
        width: 1250,
        height: 625
    }
    isChange = false;
    isDynamicPositionChoices = false;
    isLoadingChoicePosition = false;

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.setChoicesPosition();
    }

    constructor(
        private toasterService: ToastrService,
        private crudService: CrudService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public readonly dialogRef: MatDialogRef<PositionChoicesComponent>,
    ) {
        this.mainVideo = _.cloneDeep(data.video);
        this.nodes = _.cloneDeep(data.nodes);
        this.uploaderId = this.mainVideo.user;
        if (this.nodes.length < 4) {
            const ghostCount = 4 - this.nodes.length;
            for (let i = 0; i < ghostCount; i++) {
                this.nodes.push({
                    _id: 'ghost',
                    poster: '',
                    name: 'ghost',
                });
            }
        }

        if (this.mainVideo.height && this.mainVideo.width) {
            this.isDynamicPositionChoices = true;
            this.dynamicPositionChoicesData.height = this.mainVideo.height;
            this.dynamicPositionChoicesData.width =  this.mainVideo.width;
        }
        //console.log(this.nodes, this.mainVideo)
    }

    ngOnInit(): void {
        if (this.data?.video?.timeValue !== "000:00:00" && this.data?.video?.time !== 0) {
            this.getOriginalUploadedVideoUrl();
        } else {
            this.addOptions();
        }
    }

    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    getUploader() {
        this.crudService.getUserInfoV2(this.uploaderId).pipe(takeUntil(this._unsubscribeAll)).subscribe(success1 => {
            this.uploaderDetails = success1.data;
        });
    }

    addOptions() {
        setTimeout(() => {
            this.spinner = false;
            const boundaryRect = $('.vpc-choices-wrapper').offset();
            const nodes = $('.vpc-choice-box').children();
            for (const node of nodes) {
                const elementRect = $(node).offset();
                $(node).data('left', Math.max(0, elementRect.left - boundaryRect.left));
                $(node).data('top', Math.max(0, elementRect.top - boundaryRect.top));
            }
            this.setChoicesPosition();
        }, 1000);
        $('.choose-label').remove();
        $('.video_choices_v2 .actions').remove();
    }

    resetPosition() {
        this.isChange = true;
        this.isDynamicPositionChoices = false;
        const nodes = this.nodes;
        this.nodes = [];
        setTimeout(() => {
            this.nodes = nodes.map(x => {
                x.pos = {top: null, left: null};
                return x;
            });
            this.setChoicesPosition();
        },10);
    }

    savePosition() {
        const wrapper = $('.vpc-choices-wrapper');
        const nodes = $('.vpc-choice-box').children();
        const boundaryRect = wrapper.offset();
        const positionsData = {
            positions: [],
            width: wrapper.width(),
            height: wrapper.height(),
        };
        for (const node of nodes) {
            const elementRect = $(node).offset();
            let left = null;
            let top = null;
            if (this.isDynamicPositionChoices) {
                left = Math.max(0, elementRect.left - boundaryRect.left);
                top = Math.max(0, elementRect.top - boundaryRect.top);
            }
            const id = $(node).data('id');
            if (id !== 'ghost') {
                positionsData.positions.push({id, top, left});
            }
        }
        // console.log(this.nodes, 'save');
        this.spinner = true;
        this.crudService.setChoicePositions(positionsData).subscribe((res: any) => {
            const choices = res.data;
            for (const choice of choices) {
                if (choice) {
                    choice.URLName = choice.name;
                }
            }
            this.dialogRef.close({positionsData, choices});
            this.toasterService.success('Choice Positions are set successfully');
        }).add(() => this.spinner = false);
    }

    nodeMoved($event: CdkDragMove) {
        this.isDynamicPositionChoices = true;
        this.isChange = true;
        const boundaryRect = $('.vpc-choices-wrapper').offset();
        const elementRect = $($event.source.element.nativeElement).offset();
        $($event.source.element.nativeElement).data('left', Math.max(0, elementRect.left - boundaryRect.left));
        $($event.source.element.nativeElement).data('top', Math.max(0, elementRect.top - boundaryRect.top));
    }


    // setChoicesPosition() {
    //     if (this.isDynamicPositionChoices) {
    //         this.spinner = true;
    //         setTimeout(() => {
    //             const parentDiv = $('.vpc-choices-wrapper');
    //             parentDiv.addClass('opacity-0');
    //             const childDiv = $('.vpc-choice-card-wrapper');
    //             childDiv.removeClass('position-absolute');
    //             childDiv.removeAttr('style');
    //             $('.ghost-choice-card').removeClass('d-none');
    //             const data = [];
    //             for (const childDivElement of childDiv) {
    //                 // scale
    //                 const widthScalingFactor = parentDiv.width() / this.dynamicPositionChoicesData.width;
    //                 const heightScalingFactor = parentDiv.height() / (this.dynamicPositionChoicesData.height + 30);
    //
    //                 const width = $(childDivElement).width();
    //                 const height = $(childDivElement).height();
    //
    //                 data.push({widthScalingFactor, heightScalingFactor, width, height});
    //             }
    //             let i = 0;
    //             for (const childDivElement of childDiv) {
    //                 $(childDivElement).css('height', data[i].height);
    //                 $(childDivElement).css('width', data[i].width);
    //                 $(childDivElement).css('left', $(childDivElement).data('left') * data[i].widthScalingFactor);
    //                 $(childDivElement).css('top', $(childDivElement).data('top') * data[i].heightScalingFactor);
    //                 i++;
    //             }
    //             parentDiv.removeClass('opacity-0');
    //             childDiv.addClass('position-absolute');
    //             $('.ghost-choice-card').addClass('d-none');
    //             this.spinner = false;
    //         }, 500);
    //     }
    //     $('.vpc-choice-box').removeClass('opacity-0');
    // }

    setChoicesPosition() {
        if (!this.isLoadingChoicePosition) {
            this.spinner = true;
            this.isLoadingChoicePosition = true;
            setTimeout(() => {
                const parentDiv = $('.vpc-choices-wrapper');
                const childDiv = $('.vpc-choice-card-wrapper');
                const ghostDiv = $('.ghost-choice-card');
                const choiceBox = $('.vpc-choice-box');
                parentDiv.addClass('opacity-0');
                ghostDiv.removeClass('d-none');
                childDiv.removeClass('position-absolute');
                childDiv.removeAttr('style');
                const data = [];
                for (const childDivElement of childDiv) {
                    // scale
                    const widthScalingFactor = parentDiv.width() / this.dynamicPositionChoicesData.width;
                    const heightScalingFactor = parentDiv.height() / this.dynamicPositionChoicesData.height;

                    const width = $(childDivElement).width();
                    const height = $(childDivElement).height();

                    data.push({widthScalingFactor, heightScalingFactor, width, height});
                }
                if (this.isDynamicPositionChoices) {
                    let i = 0;
                    for (const childDivElement of childDiv) {
                        $(childDivElement).css('height', data[i].height);
                        $(childDivElement).css('width', data[i].width);
                        $(childDivElement).css('left', $(childDivElement).data('left') * data[i].widthScalingFactor);
                        $(childDivElement).css('top', $(childDivElement).data('top') * data[i].heightScalingFactor);
                        i++;
                    }
                    childDiv.addClass('position-absolute');
                } else {
                    let i = 0;
                    for (const childDivElement of childDiv) {
                        $(childDivElement).css('height', data[i].height);
                        $(childDivElement).css('width', data[i].width);
                        i++;
                    }
                }
                ghostDiv.addClass('d-none');
                parentDiv.removeClass('opacity-0');
                this.isLoadingChoicePosition = false;
                this.spinner = false;
            },500);
        }
    }

    getOriginalUploadedVideoUrl() {
        this.spinner = true;
        let payload = {
            videoId: this.data?.video?._id,
            type: this.data?.video?.type,
        }
        this.crudService.getOriginalUploadedVideoUrl(payload).subscribe((res) => {
            const videoUrl = res.data.videoSourceUrl;
            if (videoUrl) {
                this.createThumbnail(videoUrl);    
            }
        }, (error) => {
            console.error('Error:', error);
            this.spinner = false;
        })
    }

    createThumbnail(videoUrl: any) {
        this.spinner = true;
        let payload = {
            videoURL: videoUrl,
            timestamp: this.data?.video?.timeValue
        }
        this.crudService.createThumbnail(payload).subscribe((res) => {
            if (res.data) {
                // this.crudService.getSignedUrl(res.data).subscribe((preSignedUrl) => {
                //     this.addOptions();
                //     this.mainVideo.poster = preSignedUrl;
                //     this.spinner = false;
                // });

                this.mainVideo.poster = res.data;
                this.addOptions();
                this.spinner = false;
            }
        }, (error) => {
            console.log("error ==> ", error)
            // this.spinner = false;
        })
    }
}
