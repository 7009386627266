import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CreateCouponComponent } from './create-coupon/create-coupon.component';
import { CreatePromoCodeComponent } from './create-promo-code/create-promo-code.component';
import { DeleteConfirmationComponent } from '../../layout/modals/delete-confirmation/delete-confirmation.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CrudService } from 'src/app/services/crud.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
export class CouponComponent implements OnInit {

  @ViewChild('deleteDialogRef') deleteDialogRef: DeleteConfirmationComponent;
  @ViewChild('confirmationDialog') confirmationDialog: TemplateRef<any>;
  @Input() currentTab = 'coupons';
  spinners: boolean = false;
  couponCodeData = [];
  promoCodeData = [];
  addCouponPage: number = 1;

  constructor(
    public dialog: MatDialog,
    public activatedRoute: ActivatedRoute,
    private urlService: CrudService,
    private toastr: ToastrService,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      if (params.type === 'promo-code') {
        this.createPromoCode('');
      }
    });
  }

  ngOnInit(): void {
    this.getAllCoupons(true);
    //this.getAllPromoCodes();
  }

  onScrollCoupons(): void {
    if(this.currentTab === 'coupons') {
      ++this.addCouponPage;
      this.getAllCoupons();
    }
  }

  getAllCoupons(reset?: boolean) {

    if (reset) {
      this.addCouponPage = 1;
      this.couponCodeData = [];
    }
    this.spinners = true;
    this.urlService.getAllCoupon(this.addCouponPage, 10).subscribe((res: any) => {
      const allCoupons = res.data || [];
      allCoupons.forEach((coupon) => {
        if (coupon) {
          this.couponCodeData.push(coupon);
        }
      });
      this.spinners = false;
    }, (err) => {
      this.spinners = false;
      this.toastr.error('Fetching Coupons Failed');
    })
  }

  getAllPromoCodes() {
    this.spinners = true;
    this.urlService.getAllPromoCodes().subscribe((res: any) => {
      this.promoCodeData = res.data || [];
      this.spinners = false;
    }, (err) => {
      this.spinners = false;
      this.toastr.error('Fetching Promo Codes Failed!!');
    })
  }

  createCoupon(currentTab: string, data?) {
    if(currentTab === 'coupons') {
      const dialogRef = this.dialog.open(CreateCouponComponent, {
        width: '100%',
        maxWidth: '600px',
        maxHeight: '600px',
        panelClass: 'my-dialog',
        height: 'auto',
        data: {
          couponData: data ? data : '',
        }
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getAllCoupons(true);
          this.openConfirmationModal(result.data);
        }
      });
    }

    if(currentTab === 'promoCodes') {
      this.createPromoCode(data);
    }
  }

  editCoupon(id: string) {
    this.router.navigateByUrl('coupon/' + id);
  }

  createPromoCode(data) {
    const dialogRef = this.dialog.open(CreatePromoCodeComponent, {
      width: '100%',
      maxWidth: '600px',
      maxHeight: '600px',
      panelClass: 'my-dialog',
      height: 'auto',
      data: {
        promoCodeData: data ? data : '',
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getAllPromoCodes();
      }
    });
  }

  openConfirmationModal(id: string) {
    const dialogRef = this.dialog.open(this.confirmationDialog, {
      width: '500px',
      maxWidth: '90%',
      panelClass: 'my-dialog',
      height: 'auto'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.editCoupon(id)
      }
    });
  }

  tabChange(tab: string) {
    this.currentTab = tab;
  }

}
