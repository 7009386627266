import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CrudService } from 'src/app/services/crud.service';
import { DOCUMENT } from '@angular/common';
import { environment } from '../../../environments/environment';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $;

@Component({
  selector: 'discount-pricing',
  templateUrl: './discount-pricing.component.html',
  styleUrls: ['./discount-pricing.component.css']
})
export class DiscountPricingComponent implements OnInit {

  private isPaymentEnabled: any;
  isSpinner: any = false;
  private paymentPriceId: any;
  private isMarketingCampaign: any = false;
  private planListings: any;
  planMonthlyPrices: any;
  planYearlyPrices: any;
  monthlyDiscAmt: any = 0;
  yearlyDiscAmt: any = 0;

  constructor(private urlService: CrudService,
			  private router: Router,
			  private activatedRoute: ActivatedRoute,
			  private localStorageService: LocalStorageService,
			  @Inject(DOCUMENT) private document) { }

  ngOnInit(): void {

	this.isSpinner = true;

	this.activatedRoute.queryParams
	.subscribe(params => {
		if (params.promoCode) {
			this.getDiscountAmt(params.promoCode);
		}
	});

	if (window.sessionStorage.getItem('campaignId') != undefined)
		this.isMarketingCampaign = true;

	//console.log('this.isMarketingCampaign : ', this.isMarketingCampaign);

	this.bouncy_filter($('.container'));

	// this.urlService.getFeatureEnablements().subscribe(success => {
	// 	this.isPaymentEnabled = success.data.isPaymentEnabled;

	// 	this.isSpinner = false;
	// });

	// Individual Creator, Silver Package, Gold Package
	this.planListings =  environment.Plan_Listings;
	this.planMonthlyPrices = [];
	this.planYearlyPrices = [];

	for (let i = 0; i < this.planListings.length; i++) {

		this.urlService.getPlanInfo(this.planListings[i]).subscribe(success => {

			this.planMonthlyPrices[i] = Math.round(success.data.price - ((this.monthlyDiscAmt/100) * success.data.price));
			this.planYearlyPrices[i] = Math.round(success.data.price - (((this.yearlyDiscAmt)/100) * success.data.price));

			//console.log('current plan [' + i + ']: ', this.planListings[i]);
			//console.log('current monthly price [' + i + ']: ', this.planMonthlyPrices[i]);
			//console.log('current yearly price [' + i + ']: ', this.planYearlyPrices[i]);
		});
	};
  }

  getDiscountAmt(promoCode: any) {

	this.monthlyDiscAmt = parseInt(promoCode);
		this.yearlyDiscAmt = this.monthlyDiscAmt + environment.Addl_Yearly_Discount_Pct;

		//console.log('this.monthlyDiscAmt = ', this.monthlyDiscAmt);
		//console.log('this.yearlyDiscAmt = ', this.yearlyDiscAmt);

		this.isSpinner = false;
  }

  setShoppingCart(planIds: any): void {

	this.isSpinner = true;
	//console.log('setShopping - isSpinner: ', this.isSpinner);

	let paymentPriceId = null;

	this.urlService.getPlanInfo([planIds][0]).subscribe(success => {
		paymentPriceId = success.data.paymentPriceId;
		//console.log('paymentPriceId = ', paymentPriceId);
	})

	let payload = null;

	if (this.localStorageService.getItem('user')) {

		// Existing shopping cart
		if (this.localStorageService.getItem('shoppingCartId')) {

			payload = {
				shoppingCartId: this.localStorageService.getItem('shoppingCartId'),
				items: [planIds]
			}

			//console.log('Existing shopping cart id : ', this.localStorageService.getItem('shoppingCartId'));

			this.urlService.addShoppingCartItems(payload).subscribe(success => {

				//console.log('Adding a plan to the existing shopping cart');


				const subscriptionId = this.localStorageService.getItem('subscriptionId');

				this.urlService.getPaySubscription(subscriptionId, null).subscribe(success => {
					const subscriptionStatus = success.data.status;

					//console.log('getPaySubscription - subscription status : ', subscriptionStatus);

					// Stripe subscription status is in complete because the user hasn't submit any payments yet
					if (subscriptionStatus == 'incomplete' ) {

						//console.log('subscription status is incomplete');

						// Update Stripe Subscription
						payload = {
							subscriptionId,
							paymentPriceId
						}

						this.urlService.updatePaySubscription(payload).subscribe(success => {
							//console.log('updatePaySubscription with the new paymentPriceId ' + paymentPriceId + ' is successful');
							this.router.navigate(['checkout'], { queryParams: { subscription_clientSecret: this.localStorageService.getItem('clientSecret') }});
						})
					} else if (subscriptionStatus == 'incomplete_expired' ) {

						//console.log('subscription status is incomplete_expired');

						// The initial payment on the subscription failed and no successful payment was made within 23 hours of creating the subscription.
						// Create a new Stripe Subscription

						this.urlService.userDetailsV2().subscribe(success => {

							const payCustomerId = success.data.payCustomerId;

							payload = {
								payCustomerId : payCustomerId,
								paymentPriceId : this.paymentPriceId
							}

							this.urlService.createPaySubscription(payload).subscribe(success => {
								//console.log('createPaySubscription is successful for subscription status of incomplete_expired');

								this.localStorageService.setItem('subscriptionId', success.data.subscriptionId);
								this.localStorageService.setItem('clientSecret', success.data.clientSecret);

								this.isSpinner = false;
								this.router.navigate(['checkout'], { queryParams: { subscription_clientSecret: success.data.clientSecret }});
							})
						});

					}
				}, error => this.isSpinner = false)
			})
		} else {
			// New shopping cart
			payload = {
				items: [planIds],
				type: 'subscriptionPlan',
				isActive: true
			}

			this.urlService.setShoppingCart(payload).subscribe(success => {

				this.localStorageService.setItem('shoppingCartId', success.data._id);
				// console.log("shoppingCartId from pricing page = ", success.data._id);

				this.paymentPriceId = success.data.shoppingCartItems[0].shoppingCartItemDetails.paymentPriceId;

				// get the logged-in user info
				this.urlService.userDetailsV2().subscribe(success => {

					payload = {
						userId: success.data.details._id,
						email: success.data.details.email,
						firstname: success.data.details.firstname,
						lastname: success.data.details.lastname,
					}

					// Create Stripe Pay Customer
					this.urlService.createPayCustomer(payload).subscribe(success => {
						//console.log('payCustomer successfully created');

						payload = {
							payCustomerId : success.data.id,
							paymentPriceId : this.paymentPriceId
						}
						// Create Stripe Subscription
						this.urlService.createPaySubscription(payload).subscribe(success => {
							//console.log('createPaySubscription is successful');
							this.localStorageService.setItem('subscriptionId', success.data.subscriptionId);
							this.localStorageService.setItem('clientSecret', success.data.clientSecret);
							this.isSpinner = false;
							this.router.navigate(['checkout'], { queryParams: { subscription_clientSecret: success.data.clientSecret }});
						})
					})
				})
			})
		}
	} else  {

		this.router.navigate(['login'], { queryParams: { redirect: 'pricing' } });
	}
  }

  switch(plan : any): void {
    // console.log("Switching To ", plan);

    // switch from monthly to annual pricing tables
	this.bouncy_filter($('.container'));
  }

  bouncy_filter(container: any){
	container.each(function(){

	let pricing_table = $(this);
	// console.log('pricing_table = ', pricing_table);

	let filter_list_container = pricing_table.children('.Sava-switcher'),
	filter_radios = filter_list_container.find('input[type="radio"]'),
	pricing_table_wrapper = pricing_table.find('.Sava-wrapper');

	// console.log("pricing_table_wrapper = ", pricing_table_wrapper);

	// store pricing table items
	let table_elements = {};
	filter_radios.each(function(){
	let filter_type = $(this).val();
	// console.log('filter_type = ', filter_type);

    table_elements[filter_type] = pricing_table_wrapper.find('li[data-type="' + filter_type + '"]');

  	});

	// detect input change event
	filter_radios.on('change', function(event){
		// console.log("filter_radios CHANGED!");
		event.preventDefault();

		// detect which radio input item was checked
		let selected_filter = $(event.target).val();

        // console.log('selected_filter = ', selected_filter);
        // console.log('table_elements[selected_filter] = ', table_elements[selected_filter]);

		// give higher z-index to the pricing table items selected by the radio input
		show_selected_items(table_elements[selected_filter]);

		// rotate each Sava-wrapper
		// at the end of the animation hide the not-selected pricing tables and rotate back the .Sava-wrapper

		// if(!Modernizr.cssanimations){
		// 	hide_not_selected_items(table_elements, selected_filter);
		// 	pricing_table_wrapper.removeClass('is-switched');
		// }else{
			pricing_table_wrapper.addClass('is-switched').eq(0).one('webkitAnimationEnd oanimationend msAnimationEnd animationend', function() {
			hide_not_selected_items(table_elements, selected_filter);
			pricing_table_wrapper.removeClass('is-switched');

			// change rotation direction if .Sava-list has the .cd-bounce-invert class
			if(pricing_table.find('.Sava-list').hasClass('cd-bounce-invert'))
				pricing_table_wrapper.toggleClass('reverse-animation');
			})
		// }

        // hide_not_selected_items(table_elements, selected_filter);
        // pricing_table_wrapper.removeClass('is-switched');

	  });
	});
  }

}

function show_selected_items(selected_elements : any) {
  // console.log("In show_selected_items, selected_elements = ", selected_elements);
  selected_elements.addClass('is-selected');
}

function hide_not_selected_items(table_containers : any, filter : any) {
  $.each(table_containers, function(key, value){
      if ( key != filter ) {
      $(this).removeClass('is-visible is-selected').addClass('is-hidden');

      } else {
      $(this).addClass('is-visible').removeClass('is-hidden is-selected');
    }
  });
}

