import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {VideoTreeComponent} from '../../../layout/modals/video-tree/video-tree.component';
import {GroupVideo} from '../group.model';
import {GroupService} from '../group.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.css'],
})
export class GroupDetailComponent implements OnInit, OnDestroy {
  groupDetailId = '';
  groupVideoId = '';
  groupVideo: GroupVideo;
  groupMembersInfo = [];
  spinner: boolean;
  isEmbed: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private page = 0;

  constructor(
      private readonly route: ActivatedRoute,
      private readonly groupService: GroupService,
      private readonly router: Router,
      private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this._unsubscribeAll)).subscribe((params: Params) => {
      this.groupDetailId = params.id;
      this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe((queryParams: Params) => {
        if (queryParams.embed && queryParams.embed === 'true') {
          this.isEmbed = queryParams?.embed;
        }
        this.groupVideoId = queryParams.videoId;
        this.getGroupVideoData();
      });
    });
  }

  getGroupVideoData() {
    ++this.page;
    this.spinner = true;
    this.groupService
        .getGroupVideoCompletionRate(this.groupDetailId, this.groupVideoId, 10, this.page)
        .pipe(takeUntil(this._unsubscribeAll)).subscribe((res: any) => {
      // console.log(res)
      if (res?.groupVideos[0]) {
        this.groupVideo = res.groupVideos[0];
        for (const item of this.groupVideo.groupMembersInfo) {
          this.groupMembersInfo.push(item);
        }
      }
    }).add(() => this.spinner = false);
  }

  getPercentageCompletion(countOfUniqueChoices: number) {
    const totalVideos = this.groupVideo.videoInfo.totalchild + 1;
    return countOfUniqueChoices !== 0
        ? ((countOfUniqueChoices / totalVideos) * 100).toFixed(2)
        : 0;
  }

  onClickGroupDetail(member: any) {
    const videoId = this.groupVideo.videoInfo._id;
    const userName = member.firstname + member.lastname;
    const completionCount = this.getPercentageCompletion(member.countOfUniqueChoices);
    const isCompleted = completionCount === '100.00';
    if (!this.groupVideo) {
      return;
    }
    const dialogRef = this.dialog.open(VideoTreeComponent, {
      width: '90%',
      minHeight: 'calc(80vh - 100px)',
      panelClass: 'my-dialog',
      height: 'auto',
      data: {
        videoId,
        userName,
        isCompleted,
      },
    });

    dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {
      //console.log(result);
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
