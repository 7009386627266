import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { CrudService } from 'src/app/services/crud.service';
import { LocalStorageService } from 'src/app/services/localStorage.service';

@Component({
  selector: 'app-lead-gen-analytics',
  templateUrl: './lead-gen-analytics.component.html',
  styleUrls: ['./lead-gen-analytics.component.css']
})
export class LeadGenAnalyticsComponent implements OnInit {
  displayedColumns: string[] = [];
  // dataSource: MatTableDataSource<any>;
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  spinner = true;
  publishedPage = 1;
  limit = 20;
  sort = 'recent';
  userMeData:any;

  constructor(private crudService: CrudService, private router: Router) { 
  }

  ngOnInit(): void {
    this.getUserInfoWithHubSpot();
    this.getCrmData(this.limit, this.publishedPage, this.sort);
  }

  getCrmData(limit: number, page: number, sort: string) {
    this.crudService.getCrmData(limit, page, sort).subscribe((res) => {
      if (res && res.data && res.data.length > 0) {
        // Update dataSource only if response contains data
        // this.dataSource = new MatTableDataSource<any>(res.data);
        this.dataSource.data = this.dataSource?.data.concat(res.data);
      }
      this.spinner = false;
    });
  }

  onScrollTab3(): void {
    ++this.publishedPage;

    this.getCrmData(this.limit, this.publishedPage, this.sort);
  }

  navigatePage(id:any){
    this.router.navigate(['/videos'], {queryParams: {video: id}});
  }

  navigateProfilePage(data:any){
    if(!data?.isAnonymous){
      this.router.navigate(['/public-profile'], {queryParams: {user: data?.userId}});
    }
  }

  getUserInfoWithHubSpot(){
    this.crudService.userDetailsV2().subscribe(success => {
        this.userMeData = success?.data?.details
        
        this.displayedColumns = this.userMeData?.hubSpot ?
        ['crmId', 'email', 'firstName', 'lastName', 'completedVideo', 'completedPathway', 'lifecycleStage', 'completedTs'] :
        ['email', 'firstName', 'lastName', 'completedVideo', 'completedPathway', 'completedTs'];    
    })
}

}
