import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "sectionSpinner",
  templateUrl: "./sectionSpinner.component.html",
  styleUrls: ["./sectionSpinner.component.css"],
})
export class SectionSpinnerComponent implements OnInit {
  @Input() togglePosition = false;

  constructor() {}

  ngOnInit() {}
}
