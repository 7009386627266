// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.btnCloseQRCode{
    border-radius: 5px;
    border: solid 1px gray;
    background-color: gray;
    font-size: 15px;
    padding: 3px 10px;
    width: 100%;
    color: white;
}
.action img{
    height: 20px;
    width: 20px;
    filter: invert(1);
    cursor: pointer;
}
.action{
    background: gray;
    padding: 1px 4px;
    border-radius: 4px;
}

.copied{
    background-color: #dabeff70;
    color: #49207f;
    font-size: 13px;
    font-weight: 500;
    padding: 2px 7px;
    border-radius: 5px;
    position: absolute;
    top: -15px;
    right: 84px;

}`, "",{"version":3,"sources":["webpack://./src/app/afterLogin/qr-code-modal/qr-code-modal.component.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,sBAAsB;IACtB,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,YAAY;AAChB;AACA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;IACV,WAAW;;AAEf","sourcesContent":["\n.btnCloseQRCode{\n    border-radius: 5px;\n    border: solid 1px gray;\n    background-color: gray;\n    font-size: 15px;\n    padding: 3px 10px;\n    width: 100%;\n    color: white;\n}\n.action img{\n    height: 20px;\n    width: 20px;\n    filter: invert(1);\n    cursor: pointer;\n}\n.action{\n    background: gray;\n    padding: 1px 4px;\n    border-radius: 4px;\n}\n\n.copied{\n    background-color: #dabeff70;\n    color: #49207f;\n    font-size: 13px;\n    font-weight: 500;\n    padding: 2px 7px;\n    border-radius: 5px;\n    position: absolute;\n    top: -15px;\n    right: 84px;\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
