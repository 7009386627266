import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {VideoTreeComponent} from '../../../layout/modals/video-tree/video-tree.component';
import {GroupVideo} from '../group.model';
import {GroupService} from '../group.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import { CrudService } from 'src/app/services/crud.service';

@Component({
  selector: 'app-group-detail',
  templateUrl: './group-detail.component.html',
  styleUrls: ['./group-detail.component.css'],
})
export class GroupDetailComponent implements OnInit, OnDestroy {
  groupDetailId = '';
  groupVideoId = '';
  groupVideo: GroupVideo;
  groupMembersInfo = [];
  spinner: boolean;
  isEmbed: any;
  groupDetail: any;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  private page = 0;

  constructor(
      private readonly route: ActivatedRoute,
      private readonly groupService: GroupService,
      private readonly crudService: CrudService,
      private readonly router: Router,
      private dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.route.params.pipe(takeUntil(this._unsubscribeAll)).subscribe((params: Params) => {
      this.groupDetailId = params.id;
      this.route.queryParams.pipe(takeUntil(this._unsubscribeAll)).subscribe((queryParams: Params) => {
        if (queryParams.embed && queryParams.embed === 'true') {
          this.isEmbed = queryParams?.embed;
        }
        this.groupVideoId = queryParams.videoId;
        // this.getGroupVideoData();
        this.getGroupVideoData1();
        this.getCurrentGroupDetail();
      });
    });
  }

  getCurrentGroupDetail() {
    this.spinner = true;
    this.crudService.getGroupDetail(this.groupDetailId).subscribe(res => {
      this.spinner = false;
      this.groupDetail = res.data[0];
    }, (error) => {
      this.spinner = false;
    });
  }

  // getGroupVideoData() {
  //   ++this.page;
  //   this.spinner = true;
  //   this.groupService
  //       .getGroupVideoCompletionRate(this.groupDetailId, this.groupVideoId, 10, this.page)
  //       .pipe(takeUntil(this._unsubscribeAll)).subscribe((res: any) => {
  //         console.log('res: ', res.groupVideos[0]);
  //     if (res?.groupVideos[0]) {
  //       this.groupVideo = res.groupVideos[0];
  //       for (const item of this.groupVideo.groupMembersInfo) {
  //         this.groupMembersInfo.push(item);
  //       }
  //       console.log('this.groupMembersInfo 1111: ', this.groupMembersInfo);
  //     }
  //   }).add(() => this.spinner = false);
  // }

  groupMembersInfo2: any[] = [];
  getGroupVideoData1() {
    ++this.page;
    this.spinner = true;
    this.groupService
      .getGroupCompletionRateWithDates(this.groupDetailId, this.groupVideoId, 10, this.page)
        .pipe(takeUntil(this._unsubscribeAll)).subscribe((res: any) => {
          if (res?.groupVideos[0]) {
            this.spinner = false;
            this.groupVideo = res.groupVideos[0];
            for (const item of this.groupVideo.groupMembersInfo) {
              this.groupMembersInfo.push(item);
            }
      }
        }, (error) => {
          this.spinner = false;
    })
  }

  getPercentageCompletion(countOfUniqueChoices: number) {
    const totalVideos = this.groupVideo.videoInfo.totalchild + 1;
    return countOfUniqueChoices !== 0
        ? ((countOfUniqueChoices / totalVideos) * 100).toFixed(2)
        : 0;
  }

  // In your component
  formatTimeToComplete(timeToComplete: any): string {
    if (!timeToComplete) {
      return '-';
    }
    const { months, days, hours, minutes, seconds } = timeToComplete;
    return `${months} months, ${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
  }


  onClickGroupDetail(member: any) {
    const videoId = this.groupVideo.videoInfo._id;
    const userName = member.firstname + ' ' + member.lastname;
    const userId = member.memberId ? member.memberId : member._id;
    const completionCount:any = this.getPercentageCompletion(member.countOfUniqueChoices);
    // const isCompleted = completionCount === '100.00';
    const isCompleted = completionCount !== 0;
    if (!this.groupVideo) {
      return;
    }
    const dialogRef = this.dialog.open(VideoTreeComponent, {
      width: '90%',
      minHeight: 'calc(80vh - 100px)',
      panelClass: 'my-dialog',
      height: 'auto',
      data: {
        videoId,
        userName,
        isCompleted,
        userId
      },
    });

    dialogRef.afterClosed().pipe(takeUntil(this._unsubscribeAll)).subscribe((result) => {
      //console.log(result);
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  downloadGroupProgress() {
    this.spinner = true;
    this.groupService
      .getGroupCompletionRateWithDates(this.groupDetailId, this.groupVideoId, 0, 0)
      .pipe(takeUntil(this._unsubscribeAll)).subscribe((res: any) => {
        this.spinner = false;
        const includeBadgeColumn = this.groupVideo?.viewCompletionBadgeInfo?.length > 0;
        const modifiedHeader = includeBadgeColumn
          ? ['Name', 'Badge Awarded', 'Role', '% Complete', 'Start Date', 'Completion Date', 'Time to Complete']
          : ['Name', 'Role', '% Complete', 'Start Date', 'Completion Date', 'Time to Complete'];
        
        this.spinner = false;
        let fileData = {
          title: this.groupVideo?.videoInfo?.Title,
          header: modifiedHeader,
          poster: this.groupVideo?.videoInfo?.poster,
          groupName: this.groupDetail?.groupName,
          groupProgress: res?.groupVideos[0].progressCount + '/' + res?.groupVideos[0].totalMembers + ' (' + (res?.groupVideos[0].completionRate * 100).toFixed(2) + '%)',
          includeBadgeColumn: this.groupVideo?.viewCompletionBadgeInfo?.length > 0,
          data: res.groupVideos[0].groupMembersInfo.map(member => {
            const badgeAwarded = this.groupVideo?.viewCompletionBadgeInfo?.length && member.pctComplete === '100.00' ? 'Yes' : 'No';

            const row = [
              `${member.firstname} ${member.lastname}`,
              member?.accessType?.accessType,
              `${member.pctComplete}%`,
              member.startDate || '-',
              member.completionDate || '-',
              this.formatTimeToComplete(member?.timeToComplete),
            ];

            if (includeBadgeColumn) {
              row.splice(1, 0, badgeAwarded); 
            }

            return row;
          })
        }
        this.groupService.generateExcel(fileData);
      }, error => {
        this.spinner = false;
      });
  }

}
