import {Component, OnInit} from '@angular/core';
import {CrudService} from 'src/app/services/crud.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {LocalStorageService} from '../../services/localStorage.service';

declare var $;

@Component({
    selector: 'app-otp',
    templateUrl: './otp.component.html',
    styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {

    constructor(public urlService: CrudService,
                public router: Router,
                public localStorageService:LocalStorageService,
                public toastr: ToastrService) {
    }

    public otpObj = {spinner: false, otp: '', email: ''};

    ngOnInit() {
        this.otpObj.email = this.localStorageService.getItem('email')
    }

    /**
     * Regenerate OTP
     */
    regenerateOTP() {
        this.localStorageService.removeItem('anonymousToken')
        this.otpObj.spinner = true;
        // let finaObj:any = {email:this.otpObj.email}
        const finaObj: any = {
            // WC 09032022
            // email:this.otpObj.email,
            // This is to provide a way for the user to give an alternate email addr to send the OTP code
            // as sometimes emails sent out from Outreach@FreeFuse.com are being blocked
            email: this.localStorageService.getItem('email'),
            otp: this.otpObj.otp,
            type: 'null',
            newEmail: 'null',
            sendToEmailAddr: this.otpObj.email,
        }
        this.urlService.regenrateOTP(finaObj).subscribe(success => {
            this.toastr.success('OTP sent successfully');
            // this.router.navigate(['/otp']);
            // window.sessionStorage.setItem('email',this.signupObject.email)
            this.otpObj.spinner = false;
            // $('#edit-email-Modal').modal('hide');
        }, error => {
            this.otpObj.spinner = false;
        });
    }

    /**
     * Verify OTP
     */
    verifyOTP() {
        this.otpObj.spinner = true;
        this.localStorageService.removeItem('anonymousToken')
        const finaObj: any = {
            // email:this.otpObj.email,
            email: this.localStorageService.getItem('email'),
            otp: this.otpObj.otp,
            type: 'null',
            newEmail: 'null'
        }
        this.urlService.verifyOTP(finaObj).subscribe(success => {
            this.toastr.success(success.message);
            this.localStorageService.removeItem('email');
            this.router.navigate(['/login'], {queryParams: {token: success.data.token}});
            this.otpObj.spinner = false;
        }, error => {
            this.otpObj.spinner = false;
        })
    }

    /**
     * Change user login
     * @param {any} id user id
     */
    changeUserLogin(id: any) {
        if ($('#' + id).val() !== '') {
            $('#' + id).siblings('.input-field').addClass('video_input_focus');
            $('#' + id).addClass('focusIn');
        } else {
            $('#' + id).siblings('.input-field').removeClass('video_input_focus');
        }
    }

}
