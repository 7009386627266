import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges,} from '@angular/core';
import {ChildrenVideo, ChildVideo, ParentVideo,} from '../../interfaces/interactive-video.interface';
import {LocalStorageService} from '../../services/localStorage.service';
import { CrudService } from 'src/app/services/crud.service';

export interface CollabActions {
  type: string;
  video: ParentVideo | ChildrenVideo | ChildVideo;
}

@Component({
  selector: 'app-collab-single-video',
  templateUrl: './collab-single-video.component.html',
  styleUrls: ['./collab-single-video.component.css'],
})
export class CollabSingleVideoComponent implements OnInit, OnChanges {
  @Input() video: ParentVideo | ChildrenVideo | ChildVideo;
  @Input() parent?: ParentVideo | ChildrenVideo | ChildVideo;
  @Input() readonly = false;
  @Input() requestVideoId: string;
  // @Input() currentVideoId: string;
  @Output() collabActions = new EventEmitter<CollabActions>();
  loginUserInfo:any
  constructor(private localStorageService:LocalStorageService, private urlService: CrudService) {

      this.urlService.userDetailsV2().subscribe(success => {
        this.loginUserInfo = success.data.details;
      })
    
  }

  get videoOwner() {
    return this.localStorageService.getItem('user') === this.video?.user;
  }

  ngOnInit(): void {

    // console.log("video", this.video);
    
    // console.log({
    //   video: this.video,
    //   actions: this.actions,
    //   requestVideoId: this.requestVideoId,
    //   // currentVideoId: this.currentVideoId,
    // })
  }

  ngOnChanges(changes: SimpleChanges): void {
    // console.log({changes})
    // this.currentVideoId = changes.currentVideoId.currentValue;

  }

  onClickUploadVideo(
    video: ParentVideo | ChildrenVideo | ChildVideo,
    type: string
  ) {
    const action: CollabActions = {
      type,
      video,
    };
    // console.log(action)
    if (type === 'UPLOAD' && video._id) {
      return;
    }
    this.collabActions.emit(action);
  }
}
