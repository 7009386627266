import { Component, OnDestroy, OnInit } from '@angular/core';
import {CrudService} from '../../services/crud.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { isArray } from 'util';
import { ToastrService } from 'ngx-toastr';
import * as _ from 'lodash';
import {LocalStorageService} from '../../services/localStorage.service';
declare var $;
@Component({
  selector: 'headerone',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  isLogoShow = true;
  isMenuShow = true;
  private limit = 10;
  private page = 1;

  constructor(
    private urlService: CrudService,
    private router: Router,
    private toastr: ToastrService,
    private activRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
  ) {
    this.activRoute.queryParams.subscribe((params) => {
      if (params.logo && (JSON.parse(params.logo) === false)) {
        this.isLogoShow = false;
      }
      if (params.embed && JSON.parse(params.embed)) {
        this.isMenuShow = false;
      }
    })
    document.addEventListener('click', this.offClickHandler.bind(event));
  }

  public headerObj: any = { spinner: false, data: {} };
  public searchText: any = '';
  public results: Array<any> = [];
  public showSpinner = false;
  public resArray = false;
  public resObject = false;
  public users: any = [];
  public trimmedUser: any = [];
  public isLoggedIN = false;
  public notifications: Array<any> = [];
  public allnotifications: Array<any> = [];
  public notificationsCount: any = 0;
  nonViwed: any = [];
  public searchGroup: Array<any> = [];
  isSearch: any;

  // WC 05132022
  private userDetailsSub: any;
  private logoutSub: any;
  private simpleVideoSearchSub: any;
  private getAllNotificationsSub: any;
  private getNotificationsSub: any;
  private postNotificationSub: any;
  private getFeatureEnablementsSub: any;
  isPaymentFeatureEnabled: any = false;
  private getShoppingCartSub: any;
  noOfShoppingCartItems: any = 0;
  private shoppingCartId: any = this.localStorageService.getItem('shoppingCartId');
  // WC 05132022

  offClickHandler(event: any) {
    // this.showSpinner = false;
    $('.search-dropdown').hide();

  }

  clickOut(e: any) {
    this.showSpinner = false;
    e.stopPropagation();
  }

  ngOnInit() {
    let a;
    try {
      a = this.localStorageService.getItem('isSearch');
    } catch {
      this.isSearch = 'hide';
    }
    setTimeout(() => {
      if (a == null) {
        this.isSearch = 'show';
      } else {
        this.isSearch = 'hide';
      }
    }, 2000);
    $('.search-dropdown').hide();

    try {
      if (this.localStorageService.getItem('token') === null || this.localStorageService.getItem('token') === undefined) {
        this.isLoggedIN = false;
      }
      else {
        this.isLoggedIN = true;
      }
    } catch {
      this.isLoggedIN = false;
    }
    if (this.isLoggedIN) {
      // WC 06132022 - To show the first letter of first and last name if profile pic is not provided
      this.headerObj.spinner = true;
      // WC 06132022

      //WC 10032023 --- PenTestFixes to use V2
      this.userDetailsSub = this.urlService.userDetailsV2().subscribe((success) => {
          this.headerObj.data = success.data.details;

          this.headerObj.spinner = false;

          this.localStorageService.setItem('profilePic', success.data.details.profilePic)
          this.localStorageService.setItem('user', success.data.details._id)
      });

      // $('.search-dropdown').hide();

      this.getNotifications();

      // WC 06212022
      this.getShoppingCart();
      // WC 06212022
    }
  }

  ngOnDestroy(): void {

    if (this.userDetailsSub !== undefined)
      this.userDetailsSub.unsubscribe();

    if (this.logoutSub !== undefined)
      this.logoutSub.unsubscribe()

    if (this.simpleVideoSearchSub !== undefined)
      this.simpleVideoSearchSub.unsubscribe();

    if (this.getAllNotificationsSub !== undefined)
      this.getAllNotificationsSub.unsubscribe();

    if (this.getNotificationsSub !== undefined)
      this.getNotificationsSub.unsubscribe();

    if (this.postNotificationSub !== undefined)
      this.postNotificationSub.unsubscribe();

    if (this.getFeatureEnablementsSub !== undefined)
      this.getFeatureEnablementsSub.unsubscribe();

    if (this.getShoppingCartSub !== undefined)
      this.getShoppingCartSub.unsubscribe();
  }

  closeDropDown() {
    this.showSpinner = false;
    $('.search-dropdown').hide();
    this.searchText = '';
  }
  logout() {
    this.headerObj.spinner = true;

    this.logoutSub = this.urlService.logout('').subscribe(
      success => {
        this.localStorageService.clear();
        this.localStorageService.setItem('logout', 'true');
        // this.router.navigate(['/']);
        this.router.navigate(['']);

      })

    this.headerObj.spinner = false;
  }

  // Search
  onSearch(e: any) {
    this.simpleVideoSearchSub = this.urlService.simpleVideoSearch(e.target.value).subscribe(
      success => {
        this.router.navigate(['/search-results'], { queryParams: { search: e.target.value } });
        // $('.search-dropdown').hide();

        $('.search-dropdown').hide();
      },
      error => {
      }
    );
  }

  gotoSearch(search: any) {
    $('.search-dropdown').hide();
    this.router.navigate(['/search-results'], { queryParams: { search } });

  }

  onKeyPress(event: any) {
    this.results = [];
    this.trimmedUser = [];


    if (this.searchText.length > 1) {
      this.showSpinner = true;
      setTimeout(() => {

        this.simpleVideoSearchSub = this.urlService.simpleVideoSearch(event.target.value).subscribe(
          success => {
            // console.log('search result', success);

            if (!_.isEmpty(success.data.result.searchbyuser)) {
              this.users = success.data.result.searchbyuser;
              this.trimmedUser = this.users.slice(0, 10);
            }
            if (!_.isEmpty(success.data.result.searchdata)) {
              this.results = success.data.result.searchdata;
              this.searchGroup = success.data.result.searchbygroup
              //  $('.search-dropdown').show();
            }
            $('.search-dropdown').show();
            this.showSpinner = false;
          });
      }, 1000);
    }
    else {
      $('.search-dropdown').hide();
    }
  }

  focusFunction(event: any) {

    event.stopPropagation();
    setTimeout(() => {
      if (this.searchText.length > 2) {
        $('.search-dropdown').show();
      }
      else {
        $('.search-dropdown').hide();
      }
    }, 300)

  }
  gotoUser(id: any) {
    this.router.navigate(['public-profile'], { queryParams: { user: id } });
  }

  gotoGroup(id: any) {
    // console.log(id);
    if (!this.localStorageService.getItem('token')) {
      this.router.navigate(['login'])
      this.localStorageService.setItem('grpId', id)
    } else {
      this.router.navigate(['/my-group/' + id])
    }
    // this.router.navigate(['public-profile'], { queryParams: { user: id } });

  }

  preventDropDown() {
    // console.log('sasa')
    $('.search-dropdown').show();
  }

  getNotifications() {
    if (this.isLoggedIN) {
      setTimeout(() => {
        const type: any = 'notViewed'
        // WC 02092022 - Changed to V2 with pagination and limited payload
        // this.urlService.getAllNotifications().subscribe((success) => {

      //   this.getAllNotificationsSub = this.urlService.getAllNotificationsV2(this.limit, this.page).subscribe((success) => {
      //     if (Array.isArray(success.data)) {
      //       this.notifications = success.data
      //       if (this.notifications.length > 6) {
      //         this.notifications.length = 6;
      //       }
      //       this.getNotificationsSub = this.urlService.getNotifications(type).subscribe((succ) => {
      //         if (Array.isArray(succ.data)) {
      //           this.notificationsCount = succ.data;
      //         }
      //       });
      //     }
      //   });
      // }

      // WC 06212022 - Make only 1 call instead of 2
      this.getNotificationsSub = this.urlService.getNotificationsV2(type, 6, this.page).subscribe((succ) => {
              if (Array.isArray(succ.data)) {
                this.notifications = succ.data;
              }
            });
      }, 3000)
    }
  }

  viewUserProfile(userId: any) {
    this.router.navigate(['public-profile'], { queryParams: { user: userId } });
  }

  viewVideo(videoId: any) {
    this.router.navigate(['videos'], { queryParams: { video: videoId } });
  }

  // Split list into a group of n
  splitList(source: Array<any>, chunkSize: any): Array<any> {
    const chunks = _.chunk(source, chunkSize);
    return chunks;
  }

  viewNotification(id: any) {
    for (const i of this.notifications) {
      if (id === i.notificationId) {
        const requestObj: any = {
          id: i.notificationId
        };
        if (i.notificationType === 'follow') {
          this.router.navigate(['/public-profile'], { queryParams: { user: i.userInfo._id } });

        } else if (i.notificationType === 'contributor') {
          this.router.navigate(['/create-interactive-video'], { queryParams: { video: i.videoInfo._id } });

        } else if (i.notificationType === 'remove contributor') {

        }
        else if (i.notificationType === 'publishVideo') {
          this.router.navigate(['/videos'], { queryParams: { video: i.videoInfo._id } });

        }
        else if (i.notificationType === 'Comment') {
          this.router.navigate(['/videos'], { queryParams: { video: i.videoInfo._id } });

        }
        else if (i.notificationType === 'Like') {
          this.router.navigate(['/videos'], { queryParams: { video: i.videoInfo._id } });

        }
        else {

          this.router.navigate(['/videos'], { queryParams: { video: i.videoInfo._id } });

        }
      }
    }
  }

  clearAllNotifications() {
    // this.notificationsCount = 0;


    if (Array.isArray(this.notificationsCount)) {
      for (const i of this.notificationsCount) {

        const requestObj: any = {
          id: i.notificationId
        };
        this.nonViwed.push(requestObj)

      }


      this.viewNot()
    }
  }

  viewNot() {
    this.postNotificationSub = this.urlService.postNotification(this.nonViwed[0]).subscribe(success => {
      this.nonViwed.shift();

      if (this.nonViwed.length > 0)
        this.viewNot();

      else
        this.notificationsCount = [];
    });
  }

  tooltipMessage() {
    this.isSearch = false;
    this.localStorageService.setItem('isSearch', 'hide')
  }

  // WC 06212022
  redirect() {

    if (this.localStorageService.getItem('isSubscriber') === 'true')
      this.router.navigate(['upload-videos']);
    else
      this.router.navigate(['pricing']);

  }

  getShoppingCart() {

    // this.getFeatureEnablementsSub = this.urlService.getFeatureEnablements().subscribe(success => {

      // this.isPaymentFeatureEnabled = success.data.isPaymentEnabled;

      // if (this.isPaymentFeatureEnabled) {

        const shoppingCartId = this.localStorageService.getItem('shoppingCartId');

        // console.log("this.shoppingCartId = ", this.shoppingCartId);

        if (this.shoppingCartId != null) {

          this.getShoppingCartSub = this.urlService.getShoppingCart(this.shoppingCartId).subscribe(success => {

            // console.log("shopping cart isActive = ", success.data.isActive);

            // shopping cart is active
            if (success.data.isActive && success.data.shoppingCartItems != undefined) {
              this.noOfShoppingCartItems = success.data.shoppingCartItems.length;
              // console.log("getShoppingCartById - this.noOfShoppingCartItems = ", this.noOfShoppingCartItems);
            } else
              this.noOfShoppingCartItems = 0;

          })
        } else {

          this.noOfShoppingCartItems = 0;

          // Check if there's an active shopping cart by this user
          this.getShoppingCartSub = this.urlService.getShoppingCartByUser().subscribe(success => {

            // There's an active shopping cart
            if (success.data != null) {
              this.localStorageService.setItem('shoppingCartId', success.data._id);

              // console.log("shopping cart isActive = ", success.data.isActive);

              // shopping cart is active and there's an item in the shopping cart
              if (success.data.isActive && success.data.shoppingCartItems != undefined) {
                this.noOfShoppingCartItems = success.data.shoppingCartItems.length;
                // console.log("getShoppingCartById - this.noOfShoppingCartItems = ", this.noOfShoppingCartItems);
              }
            }
          })
        }
      // }
    // })
  }

  checkout() {

    // This needed to show the shopping cart when the user on the checkout and the shopping cart is hidden
    // and they click on the shopping cart to see the shopping cart
    // Causing issue when the current page is not checkout page
    // window.location.reload();

    // console.log("current page : ", this.router.url);
    // console.log("this.localStorageService.getItem('clientSecret') : ", this.localStorageService.getItem('clientSecret'));

    if (this.router.url.indexOf('/checkout') !== -1)
      window.location.reload()
    else {
      if (this.localStorageService.getItem('clientSecret'))
        this.router.navigate(['checkout'], { queryParams: { subscription_clientSecret: this.localStorageService.getItem('clientSecret') }});
    }

  }
  // WC 06122022

}
