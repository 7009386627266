import { Component, EventEmitter, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Options } from '@angular-slider/ngx-slider';
import { UtilityService } from '../../../services/utility.service';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, tap } from 'rxjs/operators';
import { CrudService } from '../../../services/crud.service';
import { ToastrService } from 'ngx-toastr';
import { CreateVideoComponent } from '../../../layout/modals/create-video/create-video.component';
import { environment } from '../../../../environments/environment';
import CreativeEngine from '@cesdk/engine';
import { LocalStorageService } from '../../../services/localStorage.service';
import { Router } from '@angular/router';

declare var $;
declare var videojs;

interface StateType {
    videoSourceUrl: string,
    oldVideoSourceUrl: string,
    startTime: any,
    oldStartTime: any,
    endTime: any,
    oldEndTime: any,
    duration: any,
    oldDuration: any,
    action: 'cut' | 'rename' | 'replace',
    oldName: string,
    name: string
}

@Component({
    selector: 'app-edit-video',
    templateUrl: './edit-video.component.html',
    styleUrls: ['./edit-video.component.css']
})
export class EditVideoComponent implements OnInit, OnDestroy {

    spinner = false;
    isAnyChange = false;
    localVideo = '../../../test.mp4'
    currentEditType: 'TRIM' | '' = '';
    player: any;
    video: any;

    currentType = 'video/mp4';
    // dataCurrentArray: StateType[] = [];
    dataUndoArray: StateType[] = [];
    dataRedoArray: StateType[] = [];
    previousData = {
        videoName: '',
        videoSourceUrl: '',
        trimDuration: 0,
        startValue: 0,
        highValue: 0
    }
    originalDuration = 0;
    trimDuration = 0;
    startValue = 0;
    highValue = 0;
    options: Options = {
        floor: 0,
        ceil: 0,
        step: 1,
        translate: (value: number): string => {
            return this.utilityService.convertSecondsToHHMMSSFormat(value);
        }
    };
    checkNodeType:any=''
    manualRefresh: EventEmitter<void> = new EventEmitter<void>();
    // videoName: any;
    titleChange$ = new BehaviorSubject('');
    loadedmetadata = false;
    isProcessingTask = false;
    finalFileObject: any = {};
    progressTrim = { encodedFrames: 0, totalFrames: 0 };
    isEditableVideo:boolean;
    private engine: any;

    get isPlaying() {
        return this.player && !this.player?.paused();
    }

    get isTrimmed() {
        return this.dataUndoArray.find(x => x.action === 'cut');
    }


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { video: any },
        private utilityService: UtilityService,
        private crudService: CrudService,
        private toaster: ToastrService,
        private dialog: MatDialog,
        private localStorageService: LocalStorageService,
        public dialogRef: MatDialogRef<EditVideoComponent>,
        private router: Router
    ) {
        this.video = data.video;
        //console.log("data ===> ", data)
        // if (this.video) {
        //     if (this.video.URL.includes('.mp4')) {
        //         this.currentType = 'video/mp4';
        //     } else {
        //         this.currentType = 'application/x-mpegURL';
        //     }
        // }

        const url = this.video?.URL.split('?')[0]; // Exclude query parameters if present
                
        if(url.toLowerCase().endsWith('.pdf')){
            this.checkNodeType = 'Document';
        }else if(this.video.URL.toLowerCase().endsWith('.m3u8')){
            this.checkNodeType = 'Video'
        }else{
            this.checkNodeType = 'Link'
        }

        this.originalDuration = this.video.duration;
        // this.video.name = this.video.name;
        this.previousData.videoName = this.video.name;
    }

    ngOnInit(): void {
        this.titleChange$
            .asObservable()
            .pipe(
                filter(Boolean),
                debounceTime(1500),
                distinctUntilChanged(),
                tap((event) => {
                    // console.log(event);
                    this.updateCurrentName(event);
                })
            )
            .subscribe();
        this.initPlayer(true);
        // console.log(this.video)
    }


    ngOnDestroy(): void {
        if (this.engine) {
            this.engine.element.remove();
            this.engine.dispose();
        }
    }

    initPlayer(ogUrl = false) {
        const that = this;
        this.video.open = true;
        const initVideoJs = () => {
        
                that.loadedmetadata = false;
                setTimeout(async () => {
                    this.player = await videojs(document.getElementById('video-all-edit'), {}, function () {
                        // tslint:disable-next-line:no-shadowed-variable
                        this.on('loadedmetadata', (e: any) => {
                            that.loadedmetadata = true;
                            // console.log(that.loadedmetadata, 'again');
                            let duration = this.duration();
                            if (duration === Infinity) {
                                duration = 0;
                            }
                            that.options.ceil = duration;
                            // console.log('load')
                            if (ogUrl) {
                                that.startValue = 0;
                                that.highValue = duration;
                                that.originalDuration = duration;
                                that.trimDuration = duration;
                                that.previousData.startValue = 0;
                                that.previousData.highValue = duration;
                                that.previousData.trimDuration = duration;
                            }
                            that.manualRefresh.emit();
                        })
                    })
                }, (0));
        }

        if (!this.video.isUrl) {
            if (ogUrl) {
                const payload = {
                    videoId: this.video._id,
                    type: this.video.type
                }
                this.spinner = true;
                this.crudService.getOriginalUploadedVideoUrl(payload).subscribe(async (res) => {
                    const videoUrl = res.data.videoSourceUrl.split('?')[0];
                    const fileName = new URL(videoUrl).pathname.split('/').pop() || '';
                    const extension = fileName.split('.').pop()?.toLowerCase() || '';     
                    this.isEditableVideo = extension.toLowerCase() !== 'mp4' ? false : true               
                    this.previousData.videoSourceUrl = res.data.videoSourceUrl;
                    this.video.videoSourceUrl = res.data.videoSourceUrl;
                    this.video.originalURL = res.data.videoSourceUrl;
                    this.video.dimension = await this.utilityService.getVideoDimensions(this.video.videoSourceUrl)
                    if (this.video) {
                        if (this.video.videoSourceUrl.includes('.mp4')) {
                            this.currentType = 'video/mp4';
                        } else if (this.video.videoSourceUrl.includes('.webm')) {
                            this.currentType = 'video/webm';
                        } else {
                            this.currentType = 'application/x-mpegURL';
                        }
                    }
                    initVideoJs();
                }, error => {
                    this.toaster.error('source video not found for editing.')
                    this.dialogRef.close();
                }).add(() => this.spinner = false);
            } else {
                initVideoJs();
            }
        }
    }

    handleLoadedMetadata() {
        this.loadedmetadata = true;
        const duration = this.player.duration() || 0;
        this.options.ceil = duration === Infinity ? 0 : duration;
    }
    
    

    updateVideoTitle() {
        setTimeout(() => {
            const name = this.video.name;
            const title = document.getElementById('videoName'); // 39
            let initialSize = 24;
            if (name.length > 39) {
                const size = name.length - 39;
                initialSize = 24 - size;
            }
            if (initialSize > 15) {
                title.style.fontSize = initialSize + 'px';
            } else {
                title.style.fontSize = '15px';
            }
            if (name.length > 2) {
                this.titleChange$.next(name);
            }
            // else {
            //   this.toastr.warning('Minimum 3 letter are allowed!');
            // }
        });
    }

    updateCurrentName(name: any, state = true) {
        this.isAnyChange = true;
        this.spinner = true;
        const obj: any = {
            id: this.video._id,
            type: this.video.type,
            name,
            URL: this.video.URL,
            Title: this.video.Title,
            Description: this.video.Description,
            // WC 07272022 - Add Tag
            Tag: this.video.Tag,
            // WC 07272022
            Categories: this.video.Categories,
            time: this.video.time,
            parentId: this.video.parentId,
            poster: this.video.poster,
            videoID: this.video.videoID,
            // WC 08092022 - subtitleUrl
            subtitleUrl: this.video.subtitleUrl,
            // WC 08092022
        };

        this.crudService.updateInteractiveVideo(obj).subscribe((success) => {
            if (state) {
                this.saveState('rename');
                this.toaster.info('Updated Successfully!');
            }
            this.spinner = false;
        },
            (error) => {
                this.spinner = false;
            });
    }

    replaceVideo() {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
            width: '95%',
            maxWidth: '1300px',
            panelClass: 'my-dialog',
            height: 'auto',
            minHeight: '500px',
            data: {
                links: !(this.video.children && this.video.children.length)
            }
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.video.open = false;
                this.isAnyChange = true;
                this.spinner = true;
                // REPLACING A VIDEO
                const obj = {
                    id: this.video._id,
                    type: this.video.type,
                    name: result.name,
                    URL: result.URL,
                    poster: result.poster ? result.poster : result.thumbnail,
                    Title: this.video.Title,
                    Description: this.video.Description,
                    // WC 07272022
                    Tag: this.video.Tag,
                    // WC 07272022
                    Categories: this.video.Categories,
                    time: this.video.time,
                    parentId: this.video.parentId,
                    videoID: result._id,
                    // WC 08092022
                    subtitleUrl: result.subtitleUrl ? result.subtitleUrl : '',
                    // WC 08092022
                    isEndingNode: result.type === 'url'
                };
                
                if(result.type === 'application/pdf'){
                    obj.poster = "assets/images/pdf-img.png";
                }

                this.crudService.updateInteractiveVideo(obj).subscribe((success) => {
                    this.video.isUrl = !this.utilityService.checkIsUrl(result.URL || '');
                    this.video.poster1 = '';
                    this.video.poster = result.poster ? result.poster : result.thumbnail;
                    this.video.imvd = 'ncs';
                    this.video.basck = 'videochart';
                    this.video.URL = result.URL;
                    if (this.video.name === 'Add Video') {
                        this.video.name = result.name;
                        this.video.Title = '';
                    }
                    this.video.name = result.name;
                    this.spinner = false;

                    if(result.type === 'application/pdf'){
                        this.video.poster = "assets/images/pdf-img.png";
                    }
                    
                    this.toaster.info('Updated Successfully!');
                    this.video.open = true;
                    // console.log(this.video)
                    this.initPlayer(true);
                    this.saveState('replace');
                }, (error) => {
                    this.spinner = false;
                });
            }
        });
    }

    trimVideo() {

        this.dialogRef.close();
        
        // const videoId = this.video?.videoID; //uploaded video ID
        
        let videoId;
        let isAIVideoCreator:boolean = false;
        if(this.video?.isAIVideoCreator){
            videoId = this.video._id
            isAIVideoCreator = this.video?.isAIVideoCreator;
        }else{
            videoId = this.video?.videoID;
            // videoId = this.video?._id;
            isAIVideoCreator = false;
        }

        const redirectUrl = `create-interactive-video?video=${this.video?.mainId ? this.video?.mainId : this.video?._id}&node=${this.video._id}`;

        this.router.navigate(['/video-editor'], {
            queryParams: { video: videoId, redirect: redirectUrl, isAIVideoCreator }
        });
    }

    // trimVideo(s?) {
    //     if (s) {
    //         this.currentEditType = 'TRIM';
    //         this.manualRefresh.emit();
    //         setTimeout(() => {
    //             this.calculateDuration();
    //         });
    //     } else {
    //         this.startValue = 0;
    //         this.highValue = this.originalDuration;
    //         this.trimDuration = this.originalDuration;
    //         this.currentEditType = '';
    //     }
    // }

    cesdkInit() {
        if (this.engine) {
            this.engine.element.remove();
            this.engine.dispose();
        }
        return new Promise((resolve => {
            const config = {
                //baseURL: 'https://cdn.img.ly/packages/imgly/cesdk-engine/1.20.0/assets',
                license: environment.ImgLy_License
            };
            CreativeEngine.init(config).then(async (engine) => {
                this.engine = engine;
                // Attach engine canvas to DOM
                document.getElementById('cesdk_container').append(engine.element);
                const scene = engine.scene.createVideo();
                const stack = engine.block.findByType('stack' as any)[0];

                // const page1 = engine.block.create('page');
                const page2 = engine.block.create('page' as any);
                // engine.block.appendChild(stack, page1);
                engine.block.appendChild(stack, page2);

                // engine.block.setWidth(page1, 1280);
                // engine.block.setHeight(page1, 720);
                // console.log(this.video)
                engine.block.setWidth(page2, this.video.dimension.width);
                engine.block.setHeight(page2, this.video.dimension.height);

                /* Show the first page for 4 seconds and the second page for 20 seconds. */
                // engine.block.setDuration(page1, 4);
                engine.block.setDuration(page2, this.trimDuration);

                const rectShape = engine.block.create('shapes/rect' as any);
                engine.block.destroy(engine.block.getFill(rectShape));
                const videoFill = engine.block.createFill('video');
                engine.block.setFill(rectShape, videoFill);

                engine.block.setString(
                    videoFill,
                    'fill/video/fileURI',
                    this.video.videoSourceUrl
                );

                engine.block.appendChild(page2, rectShape);
                engine.block.setPositionX(rectShape, 0);
                engine.block.setPositionY(rectShape, 0);
                engine.block.setWidth(rectShape, engine.block.getWidth(page2));
                engine.block.setHeight(rectShape, engine.block.getHeight(page2));

                /* Make sure that the video is loaded before calling the trim APIs. */
                await engine.block.forceLoadAVResource(videoFill);
                engine.block.setTrimOffset(videoFill, this.startValue);
                engine.block.setTrimLength(videoFill, this.highValue);

                engine.block.setLooping(videoFill, true);

                // engine.block.setMuted(videoFill, true);

                // const audio = engine.block.create('audio');
                // engine.block.appendChild(scene, audio);
                // engine.block.setString(
                //     audio,
                //     'audio/fileURI',
                //     'https://cdn.img.ly/assets/demo/v1/ly.img.audio/audios/far_from_home.m4a'
                // );
                //
                // /* Set the volume level to 70%. */
                // engine.block.setVolume(audio, 0.7);
                //
                // /* Start the audio after two seconds of playback. */
                // engine.block.setTimeOffset(audio, 2);
                //
                // /* Give the Audio block a duration of 7 seconds. */
                // engine.block.setDuration(audio, 7);

                /* Export scene as mp4 video. */
                const mimeType = 'video/mp4' as any;
                const progressCallback = (renderedFrames, encodedFrames, totalFrames) => {
                    this.progressTrim = {
                        encodedFrames,
                        totalFrames
                    }
                    // console.log('Rendered', renderedFrames, 'frames and encoded', encodedFrames, 'frames out of', totalFrames);
                };
                const blob = await engine.block.exportVideo(scene, mimeType, progressCallback, {});


                resolve(blob);

                /* Download blob. */
                // const anchor = document.createElement('a');
                // anchor.href = URL.createObjectURL(blob);
                // anchor.download = 'exported-video.mp4';
                // anchor.click();
            })
        }))

    }

    async saveTrimVideo() {
        this.video.open = false;
        // this.spinner = true;
        this.isProcessingTask = true;
        this.video.isTrimming = true;
        this.currentEditType = '';
        const obj: any = {
            videoSourceUrl: this.video?.videoSourceUrl,
            startTime: this.startValue,
            endTime: this.highValue,
            type: this.video.type,
        }
        const trimBlob = await this.cesdkInit();
        this.video.videoSourceUrl = URL.createObjectURL(trimBlob as any);
        this.saveState('cut');
        this.initPlayer();
        this.isProcessingTask = false;
        this.video.isTrimming = false;
        // this.crudService.trimVideo(obj).subscribe(res => {
        //     this.getTrimmedVideoStatus(res.data.renderId);
        // }, error => {
        //     this.initPlayer();
        //     // this.spinner = false;
        //     this.isProcessingTask = false;
        //     this.video.isTrimming = false;
        // });
    }

    getTrimmedVideoStatus(rid) {
        this.crudService.getVideoStatus(rid).subscribe(res => {
            if (res.data?.status === 'done') {
                this.video.videoSourceUrl = res.data.clipUrl;
                this.saveState('cut');
                this.initPlayer();
                // this.spinner = false;
                this.isProcessingTask = false;
                this.video.isTrimming = false;
            } else if (res.data?.status !== 'failed') {
                setTimeout(() => {
                    this.getTrimmedVideoStatus(rid);
                }, 2000);
            }
        }, error => {
            // this.spinner = false;
            this.isProcessingTask = false;
            this.video.isTrimming = false;
        })
    }

    calculateDuration() {
        this.trimDuration = this.highValue - this.startValue;
        const trimDurationHHMMSS = this.utilityService.convertSecondsToHHMMSSFormat(this.trimDuration);
        $('.ng5-slider-span.ng5-slider-bar.ng5-slider-selection').html(trimDurationHHMMSS);
    }

    onOffsetChange($event?: any) {
        this.player.offset({
            start: this.startValue,
            end: this.highValue,
            restart_beginning: true
        });
        this.player.pause();

        if ($event.pointerType === 0) {
            this.player.currentTime(this.startValue);
        } else {
            this.player.currentTime(Math.max((this.highValue - 1), 0));
        }

        this.calculateDuration();
    }


    playVideo(b: boolean) {
        if (this.player.paused()) {
            this.player.play();
        } else {
            this.player.pause();
        }
    }

    saveState(action: StateType['action']) {
        this.isAnyChange = true;
        if (action === 'replace') {
            this.dataRedoArray = [];
            this.dataUndoArray = [];
            return;
        }
        const data: StateType = {
            videoSourceUrl: this.video.videoSourceUrl,
            oldVideoSourceUrl: this.previousData.videoSourceUrl,
            startTime: this.startValue,
            oldStartTime: this.previousData.startValue,
            endTime: this.highValue,
            oldEndTime: this.previousData.highValue,
            duration: this.trimDuration,
            oldDuration: this.previousData.trimDuration,
            name: this.video.name,
            oldName: this.previousData.videoName,
            action
        }
        this.dataRedoArray = [];
        // if (this.dataCurrentArray.length === 0) {
        //     this.dataCurrentArray.push(data);
        // } else {
        //     // if (this.dataUndoArray.length == this.undoLimit) {
        //     //     this.dataUndoArray.reverse().pop();
        //     //     this.dataUndoArray.reverse();
        //     // }
        this.dataUndoArray.push(data);
        // this.dataCurrentArray.push(data);
        // }

        this.previousData.videoSourceUrl = this.video.videoSourceUrl;
        this.previousData.highValue = this.highValue;
        this.previousData.startValue = this.startValue;
        this.previousData.trimDuration = this.trimDuration;
        this.previousData.videoName = this.video.name;
    }

    undoRedo(type: number) {
        let currentState;
        if (type) {
            if (this.dataUndoArray.length) {
                currentState = this.dataUndoArray.pop();
                this.dataRedoArray.push(JSON.parse(JSON.stringify(currentState)));
                currentState.duration = currentState.oldDuration;
                currentState.videoSourceUrl = currentState.oldVideoSourceUrl;
                currentState.name = currentState.oldName;
                currentState.startTime = currentState.oldStartTime;
                currentState.endTime = currentState.oldEndTime;
            }
        } else {
            if (this.dataRedoArray.length) {
                currentState = this.dataRedoArray.pop();
                this.dataUndoArray.push(JSON.parse(JSON.stringify(currentState)));
            }
        }

        if (currentState.action === 'cut') {
            this.video.videoSourceUrl = currentState.videoSourceUrl;
            this.trimDuration = currentState.duration;
            this.startValue = currentState.startTime;
            this.highValue = currentState.endTime;
            this.onOffsetChange({ pointerType: 0 });
            this.video.open = false;
            setTimeout(() => {
                this.initPlayer();
            }, 100)
        } else if (currentState.action === 'rename') {
            this.video.name = currentState.name;
            this.updateCurrentName(this.video.name, false);
        } else {
            this.video.videoSourceUrl = currentState.videoSourceUrl;
            this.trimDuration = currentState.duration;
            this.startValue = currentState.startTime;
            this.highValue = currentState.endTime;
            this.onOffsetChange({ pointerType: 0 });
            this.video.name = currentState.name;
            this.updateCurrentName(this.video.name, false);
        }
        // console.log(currentState)
    }

    close() {
        let data = {
            isAnyChange: this.isAnyChange,
            video: this.video
        }
        this.dialogRef.close(data);
    }

    save(b?) {
        if (this.isTrimmed) {
            // this.spinner = true;
            this.isProcessingTask = true;
            this.finalFileObject.isDownloading = true;
            const fileName = `${this.video.name}- Trimmed on ${new Date().toLocaleString()}.mp4`;
            this.utilityService.urlToFile(this.video.videoSourceUrl, fileName).then((res) => {
                this.finalFileObject = res;
                this.finalFileObject.originalName = this.utilityService.sanitizeString(this.finalFileObject.name, true);
                // console.log("onSelectFile - After sanitizing this.finalFileObject.originalName = ", this.finalFileObject.originalName);

                this.finalFileObject.id = Math.random();
                this.finalFileObject.uploadPercentage = 0;
                this.utilityService.onGetDuration(this.finalFileObject).then((duration) => {
                    this.finalFileObject.duration = duration;
                    if (this.finalFileObject.type.indexOf('image') > -1) {
                        this.finalFileObject.format = 'image';
                    } else if (this.finalFileObject.type.indexOf('video') > -1) {
                        this.finalFileObject.format = 'video';
                    }
                    this.finalFileObject.isUploading = true;
                    this.finalFileObject.isTranscribing = false;
                    this.finalFileObject.isTranscoding = false;
                    this.finalUpload(res);
                });
            });
        } else {
            this.close();
        }
    }

    finalUpload(i) {

        //console.log('create-video.finalUpload - i = ', i);

        const obj = {
            name: this.utilityService.sanitizeString(i.name, false),
            type: 'application/octet-stream',
            URL: null,
            duration: i.duration,
            thumbnail: '',
            size: i.size,
            originalName: i.name.split('.')[0],
        };

        let Objdata;

        this.crudService.createpresignedUrlV2(obj).subscribe((success) => {
            i.counter = success.data.counter;
            i.preSignedUrl = success.data.details;
            const pathArray = i.preSignedUrl.split('?');
            const firstUrl = pathArray[0];
            this.crudService.uploadVideo(i.preSignedUrl, i).subscribe(() => {
                i.isUploading = false;
                i.isTranscribing = true;
                i.uploadPercentage = 0;

                Objdata = {
                    name: i.name,
                    counter: i.counter,
                    file: firstUrl,
                };
                const data: any = {
                    name: obj.name,
                    counter: i.counter,
                    file: firstUrl,
                };
                // if (this.data.skipTranscribing || !this.isTranscribing) {
                if (this.video?.subtitleUrl) {
                    this.transcribingJob(data, i);
                } else {
                    this.transcodingJob(data, i)
                }
                // } else {
                //     this.transcribingJob(data, i);
                // }
            },
                (error) => {
                    this.toaster.info('Error!', error);
                }
            );

            this.progressPercentage(i);
        },
            (error) => {
                i.isTranscoding = false;
            }
        );
    }

    progressPercentage(i) {
        const interval = setInterval(() => {
            i.uploadPercentage = this.crudService.getProgress();
            if (i.uploadPercentage > 99.9) {
                clearInterval(interval);
            }
        }, 1000);
    }

    transcribingJob(data, i) {
        this.toaster.info('Transcribing Video');
        let requestObj;
        this.crudService.transcribeVideo(data).subscribe(
            (success) => {
                i.uploadPercentage = 0;
                i.isOtherone = true;

                if (this.localStorageService.getItem('interactive') === 'isEditor') {
                    requestObj = {
                        name: data.name,
                        type: 'application/octet-stream',
                        counter: i.counter,
                        transcodeId: success.data.transcodeId,
                        bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
                    };
                } else {
                    requestObj = {
                        name: data.name,
                        counter: i.counter,
                        transcriptionJobName: success.data.TranscriptionJobName,
                    };
                }

                if (i.uploadPercentage) {
                    // console.log('in if i.uploadPercentage', i.uploadPercentage);
                    i.uploadPercentage = i.uploadPercentage + 10;
                }

                this.checkTranscribingStatus(data, requestObj, i);
            },
            (error) => {
            }
        );
    }

    checkTranscribingStatus(data, requestObj, i) {
        // console.log("checkTranscribingStatus");

        this.crudService.checkTranscribingStatus(requestObj).subscribe(
            (success) => {
                if (success.data.TranscriptionJobStatus === 'COMPLETED') {
                    this.toaster.info('Transcribing Completed');

                    data.transcriptFileUri = success.data.Transcript.TranscriptFileUri;

                    // this.toastr.info('Transcoding Video');
                    this.transcodingJob(data, i);
                } else {
                    // console.log('2. in else of check status', i.uploadPercentage);
                    if (i.uploadPercentage < 95) {
                        i.uploadPercentage = i.uploadPercentage + 5;
                        // console.log('3. in if else of check status', i.uploadPercentage);
                    } else {
                        i.uploadPercentage = 98;
                    }

                    setTimeout(() => {
                        this.checkTranscribingStatus(data, requestObj, i);
                    }, 1500);
                }
            },
            (error) => {
                this.isProcessingTask = false;
                this.toaster.error('Transcribing Failed');
            }
        );
    }

    transcodingJob(data, i) {
        i.isTranscribing = false;
        i.isTranscoding = true;
        this.toaster.info('Transcoding Video');

        let requestObj;

        this.crudService.transcodeVideo(data).subscribe(
            (success) => {
                // console.log("transcodVideo success.data = ", success.data);

                i.uploadPercentage = 0;
                i.isOtherone = true;
                if (this.localStorageService.getItem('interactive') === 'isEditor') {
                    requestObj = {
                        name: data.name,
                        type: 'application/octet-stream',
                        counter: i.counter,
                        transcodeId: success.data.transcodeId,
                        bucketName: environment.AI_VIDEO_UPLOAD_BUCKET,
                    };
                } else {
                    requestObj = {
                        name: data.name,
                        type: 'application/octet-stream',
                        counter: i.counter,
                        transcodeId: success.data.transcodeId,
                    };
                }
                if (i.uploadPercentage) {
                    // console.log('in if i.uploadPercentage', i.uploadPercentage);
                    i.uploadPercentage = i.uploadPercentage + 10;
                }
                this.checkTranscodingStatus(requestObj, i);
            },
            (error) => {
            }
        );
    }

    checkTranscodingStatus(requestObj, i) {
        this.crudService.checkTranscodingStatus(requestObj).subscribe(
            (success) => {
                if (success.data._id != null || success.data._id !== undefined) {
                    this.toaster.info('Transcoding Completed');
                    // this.uploadVideoObject.file.shift();
                    // if (this.uploadVideoObject.file.length < 1) {
                    //     this.cancelUpload(i);
                    //     this.resetVideoData();
                    //     this.getAllVideos('new');
                    //     i.isTranscoding = false;
                    //     i.isOtherone = false;
                    // } else {
                    //     this.uploadVideo();
                    //console.log({success: success.data})
                    i.isTranscoding = false;
                    i.isOtherone = false;
                    this.spinner = false;
                    this.isProcessingTask = false;
                    this.updateVideo(success.data);
                    // }
                } else {
                    // console.log('2. in else of check status', i.uploadPercentage);
                    if (i.uploadPercentage < 95) {
                        i.uploadPercentage = i.uploadPercentage + 5;
                        // console.log('3. in if else of check status', i.uploadPercentage);
                    } else {
                        i.uploadPercentage = 98;
                    }
                    setTimeout(() => {
                        this.checkTranscodingStatus(requestObj, i);
                        // console.log('4. in setTimeout', i.uploadPercentage);
                    }, 1500);
                }
            },
            (error) => {
                this.isProcessingTask = false;
                this.toaster.error('Transcoding Failed');
            }
        );
    }

    updateVideo(result) {        
        this.spinner = true;
        // REPLACING A VIDEO
        const obj = {
            id: this.video._id,
            type: this.video.type,
            name: this.video.name,
            URL: result.URL,
            poster: result.poster ? result.poster : result.thumbnail,
            Title: this.video.Title,
            Description: this.video.Description,
            // WC 07272022
            Tag: this.video.Tag,
            // WC 07272022
            Categories: this.video.Categories,
            time: this.video.time,
            parentId: this.video.parentId,
            videoID: result._id,
            // WC 08092022
            subtitleUrl: result.subtitleUrl ? result.subtitleUrl : '',
            // WC 08092022
        };
        
        this.crudService.updateInteractiveVideo(obj).subscribe((success) => {
            this.video.isUrl = !this.utilityService.checkIsUrl(result.URL || '');
            this.video.poster1 = '';
            this.video.poster = result.poster ? result.poster : result.thumbnail;
            this.video.imvd = 'ncs';
            this.video.basck = 'videochart';
            this.video.URL = result.URL;
            if (this.video.name === 'Add Video') {
                this.video.name = result.name;
                this.video.Title = '';
            }
            this.spinner = false;
            this.toaster.info('Updated Successfully!');
            this.close();
        });
    }

}
