import {Injectable} from '@angular/core';
import JSConfetti from 'js-confetti';

@Injectable({
    providedIn: 'root'
})
export class JsConfettiService {
    private jsConfetti: JSConfetti;

    constructor() {
        this.jsConfetti = new JSConfetti()
    }

    showConfetti(emojis: string[]) {
        this.jsConfetti.addConfetti({
            confettiNumber: 500
        });
        if (emojis.length) {
            this.jsConfetti.addConfetti({
                emojis,
                emojiSize: 30,
            });
        }
    }
}
