import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CrudService } from 'src/app/services/crud.service';
import { LocalStorageService } from 'src/app/services/localStorage.service';
import languages from "../../../../services/languages";
import { UtilityService } from 'src/app/services/utility.service';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, tap } from 'rxjs';
import {
  EventType,
  IFormField,
  MessageType,
} from "../../createInteractiveVideo.interface";
import { createVideoEvent, HoursCount, MinutesSecondsCount } from '../../createInteractiveVideo.data';
import { ConnectionPoint, LinkData, TreeConfig, TreeNode } from 'src/app/interfaces/interactive-video.interface';
import * as d3 from 'd3';
import { MatDialog } from '@angular/material/dialog';
import { DashboardService } from 'src/app/services/dashboard.service';
import { CreateVideoComponent } from 'src/app/layout/modals/create-video/create-video.component';
import { UploadedFileResponse } from 'src/app/interfaces/upload-video.interface';
import { PopoverDirective } from "ngx-bootstrap/popover";
import { DeleteConfirmationComponent } from 'src/app/layout/modals/delete-confirmation/delete-confirmation.component';
import { EditVideoComponent } from '../../edit-video/edit-video.component';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { CreateQuestionnaireComponent } from 'src/app/layout/modals/create-questionnaire/create-questionnaire.component';
import { PositionChoicesComponent } from '../../position-choices/position-choices.component';
import sanitizeSVG from "@mattkrick/sanitize-svg";
import * as _ from "lodash";
import { get } from 'http';
import { NgForm } from '@angular/forms';

declare var $;
declare var bootstrap: any;

@Component({
  selector: 'app-new-create-inter-active-video',
  templateUrl: './new-create-inter-active-video.component.html',
  styleUrl: './new-create-inter-active-video.component.css'
})
export class NewCreateInterActiveVideoComponent implements OnInit, AfterViewInit, OnDestroy {
  spinner: boolean = false;
  mainID: any;
  isOwnerOfVideo: boolean;
  createInteractiveVideoObj: any = {
    spinner: false,
    selectedType: "",
    selectedVideoCheck: false,
    interactiveStep: "one",
    tree: "",
    root: "",
    duration: 750,
    diagonal: "",
    svg: "",
    i: 0,
    currentObj: {},
    selectedItem: "",
    finalObj: [],
    endingNodes: [],
    connectorNodes: [],
    currentPlayingItem: {},
    mainVideo: "",
    child: [],
    publish: false,
    currentNodeOption: "",
    preview: false,
    // WC 03102022
    totalUploadedVideoCount: 0,
    // WC 04062022
    isRestricted: false,
    pathways: [],
    finalObjTree: [],
    conclusionLinks: [],
    activePathNodes: [],
  };
  isOpenCreateVideo: any = false;


  private readonly defaultConfig: TreeConfig = {
    dragScrollSpeed: 20, // Adjust this value to control the speed of the drag scroll
    dragScrollMargin: 50, // Adjust this value to control the margin for drag scroll
    nodeConfig: {
      width: 220, // node width 
      height: 200, // node height
      detailsIconBg: 44, // top right icon background color
      detailsIconSize: 38, // top right icon size
      borderRadius: 15, // node border radius
      borderStrokeWidth: 3, // node stroke width when focus is off
      borderStrokeWidthFocusOn: 4,  // node stroke width when focus is on
      heightRatio: 2, // node height ratio in tree chart ( tree size will be effected with height & width)
      //widthRatio: 3.7, // node width ratio in tree chart
      widthRatio: 2.7, // node width ratio in tree chart
      //imagePadding: 15, // thumbnail image padding
      imagePadding: 20, // thumbnail image padding
      labelSize: 26, // node label size
      connectorDotSize: 15, // connector dot size
      imageAspectRatio: 'xMidYMid slice' // 'xMidYMid slice' : 'xMidYMid meet'
    },
    minScale: 0.1, // minimum zoom level
    maxScale: 4, // maximum zoom level
    dropTargetWidth: 80, // drop target width , detect drop target width
    dropTargetHeight: 20, // drop target height , detect drop target height
    miniMapScale: 0.15, // minimap scale ratio with screen size
    linkArrowSize: 9,  // link arrow size
    linkConfig: { // link config
      width: 3,
      linkOpacity: 1,
      linkColor: '#ccc',
      highlightedWidth: 4,
      highlightedLinkOpacity: 1,
      highlightedLinkColor: 'red'
    },
    useInitialZoom: true, // use initial zoom
    initialZoom: 35, // initial zoom level
  };

  private readonly imageConfig: any = {
    questionnaires: {
      height: 40,
      width: 40
    },
    timerForeignObject: {
      height: 60,
      width: 60
    },
    timer: {
      height: 60,
      width: 60
    },
    script: {
      height: 45,
      width: 45
    },
    subTitle: {
      height: 43,
      width: 43
    },
  };

  alreadyTargetedLanguages = [];
  languages = languages;
  item: any = ";";
  isPrivate = false;
  isAssistCreateVideo: any;
  assistEventsSubject = new BehaviorSubject<EventType>({ type: "" });
  private msgEvent = createVideoEvent;
  buttonType: string;
  treeData1: any = [];
  currentVideoData: any = {};
  clonedArr;
  selectedNodesScript: string = "";
  objectivesPopupData: any;
  isDisplayPopupWithObjective: boolean = false;
  // Create a Map to store the _iconCount values
  iconCountMap = new Map();
  findorselect: string;
  private collabLimit = 10;
  private collabPage = 1;
  followers: Array<any> = [];
  following: Array<any> = [];
  removeContributorsIds: any[] = [];
  addContributorsIds: any[] = [];
  contributorsList: any = [];
  contibutorsTab: any = 'remove'
  isShareOpen: any = false;
  followersLength: number;
  findorselectcontributors: any;
  currentHoveredNode: any = null;
  childTitleChange$ = new BehaviorSubject("");
  titleChange$ = new BehaviorSubject("");
  duationChange$ = new BehaviorSubject(0);
  userPromptChange$ = new BehaviorSubject("");
  private interval: any;
  language;
  textareaData;
  initialValue;
  hoursCount = HoursCount;
  minutesSecondsCount = MinutesSecondsCount;
  private hasInteractedWithMinimap: boolean = false; // ✅ Declare at the class level
  allowTranslate = false;
  targetLanguages = [];
  connectorNodesToBeDeleted = {sourceId: null, targetId: null};
  isShowNewPopup: boolean = false;
  private aiEditorVideo = "no";
  currentBtnName: any = null;

  @ViewChild('svgContainer', { static: true }) private svgContainer!: ElementRef<HTMLElement>;
  @ViewChild('minimapContainer', { static: true }) private minimapContainer!: ElementRef<HTMLElement>;
  @ViewChild('chartContent') private chartContent!: ElementRef<HTMLElement>;
  @ViewChild("updateAIGeneratedScriptDialog") updateAIGeneratedScriptDialog: any;
  @ViewChildren("inputField") inputFields: QueryList<ElementRef>;
  @ViewChild("popTmpAddChoiceEle") popTmpAddChoiceEle: PopoverDirective;
  @ViewChild("deleteDialogRef") deleteDialogRef: DeleteConfirmationComponent;
  @ViewChild("updateTranscriptDialog") updateTranscriptDialog: any;
  @ViewChild("updateAiKeyInsightsDialog") updateAiKeyInsightsDialog: any;
  @ViewChild("confirmationDialog") confirmationDialog: any;
  @ViewChild("setTimerForCurrentNodeDialog") setTimerForCurrentNodeDialog: any;å
  @Input() treeData: { data: any | []; customLinks: LinkData[] } = { data: [], customLinks: [] };
  @Input() treeConfig: TreeConfig = this.defaultConfig; // Use this to manage config for links,nodes & tree height width

  private readonly MIN_DIMENSION = 100;

  private svg!: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  private minimap!: d3.Selection<SVGSVGElement, unknown, null, undefined>;
  private zoom!: d3.ZoomBehavior<SVGSVGElement, unknown>;
  private mainG!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private minimapG!: d3.Selection<SVGGElement, unknown, null, undefined>;
  private viewportRect!: d3.Selection<SVGRectElement, unknown, null, undefined>;
  private width = window.innerWidth;
  private height = window.innerHeight;
  private minimapWidth = 250;
  private minimapHeight = 150;
  private currentTransform = d3.zoomIdentity;
  private treeWidth = 0;
  private treeHeight = 0;
  private minimapScale = this.treeConfig.miniMapScale;
  private root!: d3.HierarchyNode<TreeNode>;
  private isInitialized = false;
  private resizeTimer: ReturnType<typeof setTimeout> | null = null;
  private selectedLink: SVGPathElement | null = null;
  public selectedLinkData: LinkData | null = null;
  private isCustomLink: boolean = false;
  private tooltip!: d3.Selection<HTMLDivElement, unknown, null, undefined>;
  private activeNode: any = null;
  private highlightedNode: any = null;
  private isDraggingLink = false;
  private startConnectionPoint: any | null = null;
  private temporaryLink: d3.Selection<SVGPathElement, unknown, null, undefined> | null = null;
  private contextMenu!: d3.Selection<HTMLDivElement, unknown, null, undefined>;
  private isNodeHoverActive: boolean = false;
  private isLinkHoverActive: boolean = false;
  zoomValue: number = 100;
  userData: any;
  isEditPathWay: boolean = false;
  isReorderChoice = false;
  videoName: any = null;
  createChoiceObj: any = { child: [], currentNo: 2, parent: "" };
  subtool = false;
  minValue = 50;
  maxValue = 200;
  value = 0;
  highValue = 100;
  options: any = {
    floor: 0,
    ceil: 100,
  };
  currentType = "application/x-mpegURL";
  checkNodeType: any = "";
  aiEditorType: any;
  canDownloadVideo = false;
  name;
  isChatWindowVisible: boolean = false;
  userPrompt: any;
  hh: string = "00";
  mm: string = "00";
  ss: string = "00";
  isHHSelected = false;
  isMMSelected = false;
  isSSSelected = false;
  userMessage: string = "";
  OpenPopupAll: any = false;
  latestNodeId: string;
  deleteCurrentNodeType = 1;
  allNodes: any = [];
  currSubscriptionId = "";
  allFollowers: any = [];
  assignPublishBadge: any;
  availableCategory: any = [];
  groupList = [];
  followerListForPublic = [];
  followersList: any[] = [];
  allGroups: any = [];
  groupLength: number;
  pricingUrl = "";
  treeAspectRatio = 1;
  isAiContentCreatorassist: any;
  planInfo: any = null;
  videoInfo: any;

  private validateDimensions(width: number, height: number): { width: number; height: number } {
    // Ensure dimensions are not negative and meet minimum requirements
    const validWidth = Math.max(this.MIN_DIMENSION, width || this.MIN_DIMENSION);
    const validHeight = Math.max(this.MIN_DIMENSION, height || this.MIN_DIMENSION);

    if (width !== validWidth || height !== validHeight) {
      console.warn('Invalid dimensions detected, using corrected values:', { validWidth, validHeight });
    }

    return { width: validWidth, height: validHeight };
  }
  
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef, private dashboardService: DashboardService, private urlService: CrudService, private localStorageService: LocalStorageService, private toastr: ToastrService, private utilityService: UtilityService, private dialog: MatDialog) {
    
     this.isAssistCreateVideo =
        this.localStorageService.getItem("assistCreateVideo");
      this.isAiContentCreatorassist = this.localStorageService.getItem(
        "aiContentCreatorassist"
      );

    this.activatedRoute.queryParams.subscribe((params) => {
      this.createInteractiveVideoObj.currentObj.id = params.video;
      this.createInteractiveVideoObj.currentObj._id = params.video;
      this.aiEditorType = params.aiEditorType;
      this.mainID = params.video;
      if (params.node) {
          setTimeout(() => {
            $("#tree-Modal").modal("show");

            let nodeData = this.treeData.data.filter(
              (i: any) => i._id === params.node
            );

            this.createInteractiveVideoObj.currentObj = nodeData[0];
            this.createInteractiveVideoObj.currentObj.open = true;
            this.videoName = this.createInteractiveVideoObj.currentObj.name;
            $("#tree-Modal").on("hidden.bs.modal", () => {
              this.router.navigate([], {
                queryParams: {
                  node: null,
                },
                queryParamsHandling: "merge",
              });
            });
          }, 5000);
      }

      this.initialFunction("");
      // this.getMainVideoInfo();
      this.handleResize = this.handleResize.bind(this);
      window.addEventListener('resize', this.handleResize);
    })
  }

  get isStoryBoard() {
    return this.buttonType === "storyBoard";
  }

  initialFunction(p) {
    console.log("in initialFunction");
    // this.getCurrentWidth();
    const freeTemp = this.localStorageService.getItem("freeTemp");
    console.log('freeTemp: ', freeTemp);

    if (freeTemp) {
      this.clickSelectVideo();
    }
    if (this.mainID) {
      this.getMainVideoInfo();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("in ngOnChanges");

    if (changes['treeData']) {
      console.log("in ngOnChanges - treeData changes");

      const currentValue = changes['treeData'].currentValue;

      if (currentValue?.data) {
        this.root = d3.hierarchy(currentValue.data as TreeNode);

        setTimeout(() => {
          if (this.chartContent) {
            if (this.isInitialized) {
              this.calculateTreeDimensions();
              this.renderTree(true);
              this.updateMinimapViewport();
              this.generateCustomLinks();
            } else {
              this.calculateTreeDimensions();
              this.initializeChart();
              this.initializeMinimap();
              this.renderTree();
              this.isInitialized = true;
              this.generateCustomLinks();
            }
          }
        });
      }
    }
  }


  ngAfterViewInit(): void {
    // Create tooltip div
    this.tooltip = d3.select('body')
      .append('div')
      .attr('class', 'node-tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background-color', 'white')
      .style('padding', '5px 10px')
      .style('border-radius', '4px')
      .style('border', '1px solid #ccc')
      .style('font-size', '12px')
      .style('pointer-events', 'none')
      .style('z-index', '1000')
      .style('transform', 'translate(-50%, -100%)')
      .style('box-shadow', '0 2px 4px rgba(0,0,0,0.1)') as unknown as d3.Selection<HTMLDivElement, unknown, null, undefined>;
    
    this.titleChange$
      .asObservable()
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event) => {
          // console.log(event);
          this.updateCurrentName(event);
        })
      )
      .subscribe();
    
    this.childTitleChange$
      .asObservable()
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event) => {
          // console.log(event);
          this.updateChildNodeName(event);
        })
      )
      .subscribe();

    this.duationChange$
      .asObservable()
      .pipe(
        filter(Boolean),
        debounceTime(1500),
        distinctUntilChanged(),
        tap((event) => {
          // console.log('duration', event);
          this.updateCurrentTime(event);
        })
      )
      .subscribe();

      this.userPromptChange$
        .asObservable()
        .pipe(
          filter(Boolean),
          debounceTime(1500),
          distinctUntilChanged(),
          tap((event) => {
            // console.log('duration', event);
            this.updateVideoUserPromptValue(event);
          })
        )
        .subscribe();
  }


  ngOnDestroy(): void {
    window.removeEventListener('resize', this.handleResize);
    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }

    if (this.svg) {
      this.svg.selectAll('*').remove();
    }

    if (this.minimap) {
      this.minimap.selectAll('*').remove();
    }

    if (this.tooltip) {
      this.tooltip.remove();
    }

    if (this.contextMenu) {
      this.contextMenu.remove();
    }
    this.localStorageService.removeItem("interactive");
    this.localStorageService.removeItem("freeTemp");
    this.localStorageService.removeItem("assistCreateVideo");
    this.localStorageService.removeItem("aiContentCreatorassist");
    this.localStorageService.removeItem("aiVideoCreatorFlow");
  }


  ngOnInit() { 
    this.getNewVideoPopup();
    this.checkTranslationAvailability();
    this.getUserInfo();

    this.buttonType = this.localStorageService.getItem("interactive");

    // WC 04062022 -- To handle listing of uploaded AI Editor Video
    if (this.localStorageService.getItem("interactive") === "isEditor")
      this.aiEditorVideo = "yes";
    else this.aiEditorVideo = "no";

    if (this.isAssistCreateVideo) {
      this.assistEventsSubject.next({ type: this.msgEvent.init });
    }
    if (this.isAiContentCreatorassist) {
      this.assistEventsSubject.next({ type: this.msgEvent.init });
    }

    $(document).ready(() => {
      $(".filterBtn,.fa-times").click(() => {
        $("#dropdownMenu").slideToggle();
      });
    });

    $(".modal").on("hidden.bs.modal", () => {
      $("video").trigger("pause");
    });

    if (this.buttonType === "isInteractive") {
      this.currentBtnName = "Create interactive Video";
    } else {
      this.currentBtnName = "Auto Edit";
    }

    if (this.isStoryBoard && !this.mainID && !this.isAssistCreateVideo) {
      this.createEmptyNodes();
    }


    //WC 02142025
    if (this.localStorageService.getItem("membershipName") == "Gold member")
      this.canDownloadVideo = true;
    else {
      this.dashboardService.getUserData
        .subscribe((res) => {
          if (res) {
            // console.log("res = ", res);
            if (res.canDownloadVideo != undefined)
              this.canDownloadVideo = res.canDownloadVideo;
          }
        });
    }
  }

   checkTranslationAvailability() {
      this.urlService.getUserSubscriptionPlan().subscribe((success) => {
        this.planInfo = success.data.subscriptionPlanInfo;

        // console.log("this.planInfo.name = ", this.planInfo.name);

        this.allowTranslate =
          !this.planInfo.name.includes("Freemium") &&
          !this.planInfo.name.includes("Bronze");
      });
  }
  
  reinitializeChart() {
    console.log("in reinitializeChart");

    this.getMainVideoInfo();

    //time delay
    setTimeout(() => {
      if (this.chartContent) {
        this.calculateTreeDimensions();
        this.renderTree(true);
        this.updateMinimapViewport();
        this.generateCustomLinks();
      }
    }, 500);
  };

  goBack() {
    this.router.navigateByUrl("/my-videos");
  }

  getMainVideoInfo() {
    console.log("in getMainVideoInfo - this.mainID = ", this.mainID);

    this.spinner = true;
    this.urlService.getVideoInfov2(this.mainID).subscribe(
      (res) => {
        // WC 08182023 - To redirect user to their profile page if they are not the owner of the video.
        // Only the owner of the video can edit it
        this.isOwnerOfVideo = this.localStorageService.getItem("user") === res.data.videoinfo.user
        // The current logged in user is not the owner of the video
        if (
          this.localStorageService.getItem("user") != res.data.videoinfo.user
        ) {

          // Check if the current logged in user is the contributor to collab on this video
          this.urlService.isContributor(this.mainID).subscribe((res) => {
            //console.log('isContributor = ', res);

            if (!res.data.isContributor) {
              this.toastr.error("You don't have the ownership of the video");
              this.router.navigate(["/profile"]);
            }
          });
        }

        this.createInteractiveVideoObj.currentObj = res.data.videoinfo;
        this.videoInfo = res.data.videoinfo;

        if (this.createInteractiveVideoObj.currentObj.subtitleTranslations) {
          this.createInteractiveVideoObj.currentObj.subtitleTranslations =
            this.createInteractiveVideoObj.currentObj.subtitleTranslations.map(
              (x) => {
                x.subtitle = languages.find(
                  (c) => c.code === Object.keys(x)[0]
                );
                return x;
              }
            );
          if (this.createInteractiveVideoObj.currentObj.subtitleUrl) {
            const defaultLang = this.getDefaultLangTranscription(
              this.createInteractiveVideoObj.currentObj
            );
            this.createInteractiveVideoObj.currentObj.subtitleTranslations.unshift(
              defaultLang
            );
          }
        }

        let isCheckUrl = false;

        if (this.createInteractiveVideoObj.currentObj.URL) {
          isCheckUrl = this.utilityService.checkIsVideoUrl(
            this.createInteractiveVideoObj.currentObj.URL
          );
        }

        this.createInteractiveVideoObj.currentObj.isUrl = !isCheckUrl;
        this.createInteractiveVideoObj.currentObj.transcriptLang =
          this.utilityService.getTranscriptLang(
            this.createInteractiveVideoObj.currentObj.subtitleUrl
          );
        this.createInteractiveVideoObj.mainVideo = JSON.parse(
          JSON.stringify(this.createInteractiveVideoObj.currentObj)
        );

        this.alreadyTargetedLanguages =
          this.createInteractiveVideoObj.mainVideo?.subtitleTranslations || [];
        this.alreadyTargetedLanguages = this.alreadyTargetedLanguages.map(
          (x) => {
            return Object.keys(x)[0];
          }
        );

        this.languages = this.languages.map((x) => {
          x.disabled = this.alreadyTargetedLanguages.includes(x.code);
          return x;
        });

        this.item = res.data.videoinfo.poster;

        this.createInteractiveVideoObj.currentPlayingItem =
          this.createInteractiveVideoObj.currentObj;

        this.isPrivate = res.data.videoinfo.isRestricted;

        this.iconCountMap = new Map();

        this.callChild("");

        if (this.isAssistCreateVideo) {
          this.assistEventsSubject.next({
            // type: 'this.msgEvent.editFirstNodeName',
            type: this.msgEvent.editFirstNodeName,
            data: this.createInteractiveVideoObj.mainVideo,
          });
        }
        if (this.createInteractiveVideoObj.mainVideo.Tag === "storyboard") {
          this.buttonType = "storyBoard";
        }
      },
      (err) => {
        this.router.navigateByUrl("/my-videos");
      }
    );
  }

  async callChild(p: any) {
    //console.log('in callChild - this.createInteractiveVideoObj.currentObj._id: ', this.createInteractiveVideoObj.currentObj._id);
    this.urlService
      .getChildVideosAll(this.createInteractiveVideoObj.currentObj._id)
      .subscribe(async (success) => {
        
        this.allNodes = success.data;
        //console.log('in callChild - this.allNodes: ', this.allNodes);

        const tree = [];
        //console.log('*** in callChild - tree.length after initialization = ', tree.length);
        
        this.allNodes.push(this.createInteractiveVideoObj.currentObj);
        //console.log('in callChild - this.createInteractiveVideoObj.currentObj: ', this.createInteractiveVideoObj.currentObj);

        if (!!!this.createInteractiveVideoObj.currentObj.URL) {
  
          this.createInteractiveVideoObj.currentObj.name = this
            .createInteractiveVideoObj.currentObj.name
            ? this.createInteractiveVideoObj.currentObj.name
            : "Add Video";
          this.createInteractiveVideoObj.currentObj.poster1 =
            "assets/images/H-vidcam.svg";
          this.createInteractiveVideoObj.currentObj.basck = "gt";
          this.createInteractiveVideoObj.currentObj.title = this
            .createInteractiveVideoObj.currentObj.title
            ? this.createInteractiveVideoObj.currentObj.title
            : "Add Video";
          this.createInteractiveVideoObj.currentObj.imvd = "imvd";
        } else {
          this.createInteractiveVideoObj.currentObj.basck = "videochart";
          this.createInteractiveVideoObj.currentObj.imvd = "ncs";
        }
        this.createInteractiveVideoObj.currentObj.parentId = "0";

        for (const i of success.data) {
          // i.children = null;
          let isCheckUrl = false;
          if (i.URL) {
            isCheckUrl = this.utilityService.checkIsVideoUrl(i.URL);
          }
          i.transcriptLang = this.utilityService.getTranscriptLang(
            i.subtitleUrl
          );
          i.isUrl = !isCheckUrl;
          i.durationInHHMMSS = this.utilityService.convertSecondsToHHMMSSFormat(
            i.duration
          );
          if (!!!i.URL) {
            i.name = i.name ? i.name : "Add Video";
            i.poster1 = "assets/images/H-vidcam.svg";
            i.basck = "gt";
            i.title = i.title ? i.title : "Add Video";
            i.imvd = "imvd";
          } else {
            i.basck = "videochart";
            i.imvd = "ncs";
          }
          if (i.subtitleTranslations) {
            i.subtitleTranslations = i.subtitleTranslations.map((x) => {
              x.subtitle = languages.find((c) => c.code === Object.keys(x)[0]);
              return x;
            });
            if (i.subtitleUrl) {
              const defaultLang = this.getDefaultLangTranscription(i);
              i.subtitleTranslations.unshift(defaultLang);
            }
          }
          tree.push(i);

          // this.treeData.data = tree;
        }
        this.createInteractiveVideoObj.selectedVideoCheck = false;
        this.createInteractiveVideoObj.interactiveStep = "three";
        this.createInteractiveVideoObj.spinner = false;
        this.spinner = false;
        this.createInteractiveVideoObj.finalObj = tree;
        this.createInteractiveVideoObj.endingNodes = tree.filter(
          (x) => x.isEndingNode
        );
        this.createInteractiveVideoObj.connectorNodes = tree.filter(
          (x) => x.isConnectorNode
        );
        this.createInteractiveVideoObj.currentPlayingItem = tree[0];

        this.createInteractiveVideoObj.currentObj.open = true;

        //console.log("in callChild - calling createNestedArray with passing tree = ", tree);

        this.createInteractiveVideoObj.finalObjTree = await this.createNestedArray(tree);
        this.getVideoPathWays();

        this.treeData.data = this.createInteractiveVideoObj.finalObjTree[0];
        //console.log('***in callChild - this.treeData.data after calling createNestedArray = ', this.treeData.data);
        
        if (this.treeData?.data) {
          this.root = d3.hierarchy(this.treeData.data as TreeNode);

          setTimeout(() => {
            if (this.chartContent) {
              if (this.isInitialized) {
                this.calculateTreeDimensions();
                this.renderTree(true);
                this.updateMinimapViewport();
                this.generateCustomLinks();
              } else {
                this.calculateTreeDimensions();
                this.initializeChart();
                this.initializeMinimap();
                this.renderTree();
                this.isInitialized = true;
                this.generateCustomLinks();
              }
            }
          });
        }

        if (this.currentVideoData?.isEdit) {
          const currentObj = this.createInteractiveVideoObj.finalObj.find(
            (x) => x._id === this.currentVideoData._id
          );
          if (currentObj) {
            this.clickedItem(currentObj);
          }
        }
      });
  }

  callChild1(d?) {
    $('svg[width="100%"]').remove();
    this.iconCountMap = new Map();

    this.urlService.getChildVideosAll(this.mainID).subscribe((success) => {
      const tree = [];
      this.createInteractiveVideoObj.mainVideo.parentId = "0";
      this.createInteractiveVideoObj.mainVideo.level = 0;
      if (!!!this.createInteractiveVideoObj.mainVideo.URL) {
        this.createInteractiveVideoObj.mainVideo.name = this
          .createInteractiveVideoObj.mainVideo.name
          ? this.createInteractiveVideoObj.mainVideo.name
          : "Add Video";
        this.createInteractiveVideoObj.mainVideo.poster1 =
          "assets/images/H-vidcam.svg";
        this.createInteractiveVideoObj.mainVideo.basck = "gt";
        this.createInteractiveVideoObj.mainVideo.title = this
          .createInteractiveVideoObj.mainVideo.title
          ? this.createInteractiveVideoObj.mainVideo.title
          : "Add Video";
        this.createInteractiveVideoObj.mainVideo.imvd = "imvd";
      } else {
        this.createInteractiveVideoObj.mainVideo.basck = "videochart";
        this.createInteractiveVideoObj.mainVideo.imvd = "ncs";
      }
      tree.push(this.createInteractiveVideoObj.mainVideo);
      for (const i of success.data) {
        // i.children = null;
        let isCheckUrl = false;
        if (i.URL) {
          isCheckUrl = this.utilityService.checkIsVideoUrl(i.URL);
        }
        i.transcriptLang = this.utilityService.getTranscriptLang(i.subtitleUrl);
        i.isUrl = !isCheckUrl;
        i.durationInHHMMSS = this.utilityService.convertSecondsToHHMMSSFormat(
          i.duration
        );
        if (!!!i.URL) {
          i.name = i.name ? i.name : "Add Video";
          i.poster1 = "assets/images/H-vidcam.svg";
          i.basck = "gt";
          i.title = i.title ? i.title : "Add Video";
          i.imvd = "imvd";
        } else {
          i.basck = "videochart";
          i.imvd = "ncs";
        }
        i.id = undefined;
        if (i.subtitleTranslations) {
          i.subtitleTranslations = i.subtitleTranslations.map((x) => {
            x.subtitle = languages.find((c) => c.code === Object.keys(x)[0]);
            return x;
          });
          if (i.subtitleUrl) {
            const defaultLang = this.getDefaultLangTranscription(i);
            i.subtitleTranslations.unshift(defaultLang);
          }
        }
        tree.push(i);
      }
      this.createInteractiveVideoObj.spinner = false;
      this.createInteractiveVideoObj.finalObj = tree;
      this.createInteractiveVideoObj.endingNodes = tree.filter(
        (x) => x.isEndingNode
      );
      this.createInteractiveVideoObj.connectorNodes = tree.filter(
        (x) => x.isConnectorNode
      );

      if (d) {
        this.createInteractiveVideoObj.connectorNodes = tree.filter(
          (x) => x.isConnectorNode === true && d._id !== x._id
        );
      }
      //console.log("in callChild1 - calling createNestedArray with passing tree = ", tree);

      this.createInteractiveVideoObj.finalObjTree =
        this.createNestedArray(tree);
      this.getVideoPathWays();
      this.treeData.data = this.createInteractiveVideoObj.finalObjTree[0];

      if (this.treeData?.data) {
        this.root = d3.hierarchy(this.treeData.data as TreeNode);

        setTimeout(() => {
          if (this.chartContent) {
            if (this.isInitialized) {
              console.log('this.isInitialized: ', this.isInitialized);
              this.calculateTreeDimensions();
              this.renderTree(true);
              // this.updateMinimapViewport();
              this.generateCustomLinks();
            } else {
              this.calculateTreeDimensions();
              this.initializeChart();
              this.initializeMinimap();
              this.renderTree()
              this.isInitialized = true;
              this.generateCustomLinks();
            }
          }
        });
      }
    });
  }
  
  selectWatchPath(d) {
    if (
      (this.currentMessageStep === 1 || this.currentMessageStep === 2) &&
      !this.isMessageLoad
    ) {
      if (!this.startWatchNode) {
        this.startWatchNode = d;
        // console.log(d)
        this.getBotAnswer("Starting node", "image", d.poster, d.name);
      } else {
        this.getBotAnswer("Ending node", "image", d.poster, d.name);
        this.endWatchNode = d;
      }
      this.updateCurrentHighlight();
    }
  }

  clickedItem(d) {
      console.log("d ==> ", d);

      if (!this.createInteractiveVideoObj) {
        this.createInteractiveVideoObj = {};
      }

      if (!this.createInteractiveVideoObj.currentObj) {
        this.createInteractiveVideoObj.currentObj = {};
      }

      if (this.createInteractiveVideoObj.finalObj.length) {
        this.createInteractiveVideoObj.connectorNodes =
          this.createInteractiveVideoObj.finalObj.filter(
            (x) => x.isConnectorNode === true && d._id !== x._id
          );
      }
      console.log('this.isAddWatchPath: ', this.isAddWatchPath);
      if (this.isAddWatchPath) {
        return this.selectWatchPath(d);
      }
      console.log('this.isAssistCreateVideo: ', this.isAssistCreateVideo);
      if (this.isAssistCreateVideo) {
        this.assistEventsSubject.next({
          type: this.msgEvent.nodeSelected,
          data: d,
        });
        return;
      }
      this.isReorderChoice = false;

      //WC 01232024 --- to handle the clicking of the main parent video (bookmark video)

      // if (d?.type == 'child')
      //     var str = d?.name as string;
      // else
      //     var str = d?.Title as string;
      //WC 01232024

      var str = d?.name ? d?.name : d?.Title;
      this.videoName = str?.split("20")[0];
      this.createChoiceObj.child = [];
      // this.createInteractiveVideoObj.currentObj = {};

      setTimeout(() => {
        this.value = 0;
        this.highValue = d?.duration;
        this.options = {
          floor: 0,
          ceil: d?.duration,
        };
        // console.log('clicked duration is', d.duration);
      }, 2000);

      if (d?.URL) {
        if (d?.URL.includes(".mp4")) {
          this.currentType = "video/mp4";
        } else {
          const url = d?.URL.split("?")[0]; // Exclude query parameters if present

          if (url.toLowerCase().endsWith(".pdf")) {
            this.checkNodeType = "Document";
          } else if (d.URL.toLowerCase().endsWith(".m3u8")) {
            this.checkNodeType = "Video";
          } else {
            this.checkNodeType = "Link";
          }

          this.currentType = "application/x-mpegURL";
        }
      }

      const videoTitleElement = document.getElementById("videoName");
      if(videoTitleElement){
        videoTitleElement.style.fontSize = "1.5rem"; 
      }

      this.createInteractiveVideoObj.currentObj = d;

      // if (this.createInteractiveVideoObj.currentObj.subtitleTranslations) {
      //     this.createInteractiveVideoObj.currentObj.subtitleTranslations = this.createInteractiveVideoObj.currentObj.subtitleTranslations.map(x => {
      //         x.subtitle = languages.find(c => c.code === Object.keys(x)[0]);
      //         return x;
      //     });
      //     if(this.createInteractiveVideoObj.currentObj.subtitleUrl) {
      //         const defaultLang = this.getDefaultLangTranscription(this.createInteractiveVideoObj.currentObj)
      //         this.createInteractiveVideoObj.currentObj.subtitleTranslations.unshift(defaultLang);
      //     }
      // }
      if (this.createInteractiveVideoObj.currentObj) {
        this.createInteractiveVideoObj.currentObj.open = true;
      }

      for (const i of this.createInteractiveVideoObj.finalObj) {
        //i is the child of d
        if (d?._id === i.parentId) {
          if (i.parentIds) {
            if (Array.isArray(i.parentIds) && !i.parentIds.includes(d?._id)) {
              i.URLName = i.name;
              this.name = i.name;
              this.createChoiceObj.child.push(i);
            }
          } else {
            i.URLName = i.name;
            this.name = i.name;
            this.createChoiceObj.child.push(i);
          }
        }
      }

      //WC 12/01/2023 --- To add connector node children to be shown in the add choices modal
      for (const i of this.createInteractiveVideoObj.connectorNodes) {
        if (i.parentIds && i.parentIds.includes(d?._id)) {
          i.URLName = i.name;
          this.name = i.name;
          this.createChoiceObj.child.push(i);
        }
      }
      //WC 12/01/2023 --- To add connector node children

      // console.log(d, this.createInteractiveVideoObj.currentObj)
      if (d?.name === "Add Video" && !d?.videoID && !this.isStoryBoard) {
        this.replaceVideo();
      } else {
        if (!this.createInteractiveVideoObj.currentObj?.time) {
          this.createInteractiveVideoObj.currentObj &&
            (this.createInteractiveVideoObj.currentObj.time = 0);
        }
        const minutes = Math.floor(
          this.createInteractiveVideoObj.currentObj?.time / 60
        );
        const seconds = Math.floor(
          this.createInteractiveVideoObj.currentObj?.time - minutes * 60
        );
        let m: number | string;
        let s: number | string;
        if (minutes > 10) {
          m = minutes;
        } else {
          m = `0${minutes}`;
        }
        if (seconds > 10) {
          s = seconds;
        } else {
          s = `0${seconds}`;
        }
        this.createInteractiveVideoObj.currentObj &&
          (this.createInteractiveVideoObj.currentObj.timeValue = `000:${m}:${s}`);
        this.currentVideoData = this.createInteractiveVideoObj.currentObj;

        if (!this.isChatWindowVisible) {
          this.userPrompt = this.createInteractiveVideoObj.currentObj?.userPrompt
          ? this.createInteractiveVideoObj.currentObj?.userPrompt
          : "Make your next choice";
          $("#tree-Modal").modal("show");
        }
        $("#tree-Modal").on("hidden.bs.modal", () => {
          this.closeInteractiveVideo();
        });
        const popData = this.dashboardService.getHelpPopOver();
        if (!popData?.popTmpAddChoice) {
          setTimeout(() => {
            if (this.popTmpAddChoiceEle) {
              this.popTmpAddChoiceEle.show();
            }
          }, 500);
        }
      }

      if (this.createInteractiveVideoObj.currentObj?.timer) {
        const timer = this.createInteractiveVideoObj.currentObj?.timer;
        this.hh = timer?.hh ? timer.hh : "00";
        this.mm = timer?.mm ? timer.mm : "00";
        this.ss = timer?.ss ? timer.ss : "00";
        if (this.createInteractiveVideoObj.currentObj?.timer?.timerUpMessage) {
          this.userMessage =
            this.createInteractiveVideoObj.currentObj?.timer?.timerUpMessage;
        }
      }
  }
  
  closeInteractiveVideo() {
    $("#tree-Modal").modal("hide");
    this.createChoiceObj.child = [];
    this.createInteractiveVideoObj.currentObj = {};
  }

  replaceVideo(isAI: boolean = false) {
    $("#tree-Modal").modal("hide");
    // $("#select-vid-Modal").modal(
    //   { backdrop: "static", keyboard: false },
    //   "show"
    // );
    this.openVideoCreateModel("videoTemplate", isAI);
    this.createInteractiveVideoObj.currentObj.open = false;
    this.OpenPopupAll = true;
  }

  openVideoCreateModel(type: string, isAI: boolean = false) {
      const dialogRef = this.dialog.open(CreateVideoComponent, {
        width: "95%",
        maxWidth: "1300px",
        panelClass: "my-dialog",
        height: "auto",
        minHeight: "500px",
        data: {
          isAddChoice: this.localStorageService.getItem('interactive') === 'isEditor' ? false : true
        },
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.createInteractiveVideoObj.selectedItem = result;
          if (result.type === "application/pdf") {
            this.createInteractiveVideoObj.selectedItem.poster =
              "assets/images/pdf-img.png";
          }

          if (type === "videoTemplate") {
            this.replaceVideoData();
            if (isAI) {
              this.assistEventsSubject.next({
                type: this.msgEvent.close,
                data: result,
              });
            }
          } else {
            this.createInteractiveVideo();
          }
        } else {
          if (this.isAssistCreateVideo) {
            this.assistEventsSubject.next({ type: this.msgEvent.addFirstNode });
          }
        }
        this.dialog.closeAll();
      });
  }
  
  createInteractiveVideo() {
    console.log("in createInteractiveVideo");

    if (typeof this.createInteractiveVideoObj.selectedItem !== "object") {
      this.toastr.warning("Please select an initial video!");
      return;
    }
    this.createInteractiveVideoObj.spinner = true;
    const obj: any = {
      type: "main",
      poster: this.createInteractiveVideoObj.selectedItem.poster
        ? this.createInteractiveVideoObj.selectedItem.poster
        : this.createInteractiveVideoObj.selectedItem.thumbnail,
      name: this.createInteractiveVideoObj.selectedItem.name,
      URL: this.createInteractiveVideoObj.selectedItem.URL,
      Thumbnail: this.createInteractiveVideoObj.selectedItem.Thumbnail,
      Title: "",
      Description: "",
      // WC 07272022 - Add Tagging
      Tag: "",
      // WC 07272022
      Categories: [],
      time: 0,
      parentId: null,
      videoID: this.createInteractiveVideoObj.selectedItem._id,
      // WC 08092022
      subtitleUrl: this.createInteractiveVideoObj.selectedItem.subtitleUrl,
      // WC 08092022
    };
    if (this.createInteractiveVideoObj.selectedItem.originalName) {
      obj.name = this.createInteractiveVideoObj.selectedItem.originalName;
    }
    // Use original name if it exist in creating interating video
    this.urlService.createInteractiveVideo(obj).subscribe(
      (success) => {
        this.closeModal();
        this.isOpenCreateVideo = false;
        // $('#upload-vid-Modal').modal('hide');
        this.createInteractiveVideoObj.spinner = false;
        this.createInteractiveVideoObj.selectedItem = {};
        this.createInteractiveVideoObj.selectedVideoCheck = false;
        this.router.navigate(["new-create-interactive-video"], {
          queryParams: { video: success.data.data._id },
        });
        this.localStorageService.removeItem("freeTemp");
        this.initialFunction("");
        // sync user data
        this.dashboardService.onGetUserData();
        if (this.isAssistCreateVideo) {
          this.assistEventsSubject.next({
            type: this.msgEvent.addFirstNode,
            data: success.data.data,
          });
        }
      },
      (error) => {
        this.createInteractiveVideoObj.spinner = false;
      }
    );
    this.createInteractiveVideoObj.step = "three";
  }

   closeModal() {
      this.createInteractiveVideoObj.selectedItem = {};
      $("video").trigger("pause");
  }
  
  replaceVideoData() {
    console.log("in replaceVideoData");
    
    let obj: any = {};
    if (this.createInteractiveVideoObj.currentObj.name === "Add Video") {
      obj = {
        // ADDING A VIDEO
        id: this.createInteractiveVideoObj.currentObj._id,
        type: this.createInteractiveVideoObj.currentObj.type,
        name: this.createInteractiveVideoObj.selectedItem.name,
        URL: this.createInteractiveVideoObj.selectedItem.URL,
        poster: this.createInteractiveVideoObj.selectedItem.poster
          ? this.createInteractiveVideoObj.selectedItem.poster
          : this.createInteractiveVideoObj.selectedItem.thumbnail,
        Title: this.createInteractiveVideoObj.selectedItem.Title,
        Description: this.createInteractiveVideoObj.currentObj.Description,
        // WC 07272022
        Tag: this.createInteractiveVideoObj.currentObj.Tag,
        // WC 07272022
        Categories: this.createInteractiveVideoObj.currentObj.Categories,
        time: this.createInteractiveVideoObj.currentObj.time,
        parentId: this.createInteractiveVideoObj.currentObj.parentId,
        videoID: this.createInteractiveVideoObj.selectedItem._id,
        // WC 08092022
        subtitleUrl: this.createInteractiveVideoObj.selectedItem.subtitleUrl,

        // WC 08092022
      };
      if (this.createInteractiveVideoObj.selectedItem?.userPrompt) {
        obj.userPrompt = this.createInteractiveVideoObj.selectedItem?.userPrompt
          ? this.createInteractiveVideoObj.selectedItem?.userPrompt
          : "Make your next choice";
      }
    } else {
      // REPLACING A VIDEO
      let subtitleUrl = "";
      if (this.createInteractiveVideoObj.selectedItem.subtitleUrl != undefined)
        subtitleUrl = this.createInteractiveVideoObj.selectedItem.subtitleUrl;
      obj = {
        id: this.currentVideoData._id,
        type: this.currentVideoData.type,
        // name: this.currentVideoData.name,
        name: this.createInteractiveVideoObj.selectedItem.name,
        URL: this.createInteractiveVideoObj.selectedItem.URL,
        poster: this.createInteractiveVideoObj.selectedItem.poster
          ? this.createInteractiveVideoObj.selectedItem.poster
          : this.createInteractiveVideoObj.selectedItem.thumbnail,
        Title: this.currentVideoData.Title,
        Description: this.currentVideoData.Description,
        // WC 07272022
        Tag: this.currentVideoData.Tag,
        // WC 07272022
        Categories: this.currentVideoData.Categories,
        time: this.currentVideoData.time,
        parentId: this.currentVideoData.parentId,
        videoID: this.createInteractiveVideoObj.selectedItem._id,
        // WC 08092022
        subtitleUrl,
        // WC 08092022
      };

      if (this.createInteractiveVideoObj?.userPrompt) {
        obj.userPrompt = this.createInteractiveVideoObj?.userPrompt
          ? this.createInteractiveVideoObj?.userPrompt
          : "Make your next choice";
      }
    }
    this.createInteractiveVideoObj.spinner = true;
    this.urlService.updateInteractiveVideo(obj).subscribe(
      (success) => {
        let isCheckUrl = false;
        if (success.data.URL) {
          isCheckUrl = this.utilityService.checkIsVideoUrl(success.data.URL);
        }
        this.createInteractiveVideoObj.currentObj.isUrl = isCheckUrl;
        this.createInteractiveVideoObj.currentObj.poster1 = "";
        this.createInteractiveVideoObj.currentObj.poster = this
          .createInteractiveVideoObj.selectedItem.poster
          ? this.createInteractiveVideoObj.selectedItem.poster
          : this.createInteractiveVideoObj.selectedItem.thumbnail;
        this.createInteractiveVideoObj.currentObj.imvd = "ncs";
        this.createInteractiveVideoObj.currentObj.basck = "videochart";
        this.createInteractiveVideoObj.currentObj.URL =
          this.createInteractiveVideoObj.selectedItem.URL;
        this.createInteractiveVideoObj.currentObj.videoID =
          this.createInteractiveVideoObj.selectedItem._id;
        if (this.createInteractiveVideoObj.currentObj.name === "Add Video") {
          this.createInteractiveVideoObj.currentObj.name =
            this.createInteractiveVideoObj.selectedItem.name;
          this.createInteractiveVideoObj.currentObj.Title = "";
        }
        this.createInteractiveVideoObj.currentObj.open = true;
        this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
        // this.callChild1();
        this.currentVideoData.isEdit = true;
        this.getMainVideoInfo();
        this.toastr.info("Updated Successfully!");
        this.OpenPopupAll = false;
        this.closeModal();
        // this.createInteractiveVideoObj.selectedItem.URL = null
        // $('#tree-Modal').modal('show');
        // $('#select-vid-Modal').modal('hide');
      },
      (error) => {
        this.toastr.error(error.error.data.message);
        this.createInteractiveVideoObj.spinner = false;
      }
    );
  }

  async createNestedArray(list) {
    //console.log("*** in createNestedArray - list: ", list);

    //initialize customLinks
    this.treeData.customLinks = [];
    //console.log("*** in createNestedArray - this.treeData.customLinks = ", this.treeData.customLinks);

    this.createInteractiveVideoObj.conclusionLinks = [];
    const map = {};
    let node;
    let roots = [];
    let i;

    for (i = 0; i < list.length; i += 1) {
      map[list[i]._id] = i; // initialize the map
      list[i].children = []; // initialize the children
      list[i].connectorChildren = []; // initialize the connectorChildren
      list[map[list[i]._id]].children = []
      list[map[list[i]._id]].connectorChildren = []
    }

    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.endingNodeId && list[map[node.endingNodeId]]) {
        if (!list[map[node.endingNodeId]].connection) {
          list[map[node.endingNodeId]].connection = 1;
        } else {
          list[map[node.endingNodeId]].connection += 1;
        }
        if (list[map[node.endingNodeId]].isEndingNode) {
          if (!list[map[node.parentId]].endNodes) {
            list[map[node.parentId]].endNodes = [];
          }
          list[map[node.parentId]].endNodes.push(list[map[node.endingNodeId]]);
          this.createInteractiveVideoObj.conclusionLinks.push({
            source: list[map[node.endingNodeId]],
            target: list[map[node.parentId]],
            type: "conclusion",
          });
          continue;
        }
      }

      if (node.isConnectorNode && node.parentIds && node.parentIds.length > 0) {
        for (const parentId of node.parentIds) {
          //console.log("&&& in createNestedArray - parentId = ", parentId);
          //console.log("&&& in createNestedArray - list[map[parentId]] = ", list[map[parentId]]);
          //console.log("&&& in createNestedArray - list[map[node._id.toString()]] = ", list[map[node._id.toString()]]);
          if (list[map[parentId]]) {

            this.createInteractiveVideoObj.conclusionLinks.push({
              source: list[map[parentId]],
              target: list[map[node._id.toString()]],
              type: "conclusion",
            });
            this.treeData.customLinks.push({
              source: list[map[parentId]],
              target: list[map[node._id.toString()]],
            });
          }
          continue;
        }
      }

      if (node.parentId !== "0") {
        // if you have dangling branches check that map[node.parentId] exists
        if (list[map[node.parentId]]){
          list[map[node.parentId]].children.push(node);
        }

        //WC 12/09/2023 --- To store connectorChildren
        if (node.parentIds && node.parentIds.length) {
          //console.log('in createNestedArray - node.parentIds: ', node.parentIds);
          
          for (const parentId of node.parentIds) {

            if (
              list[map[parentId]]?.children &&
              list[map[parentId]].children.length > 0
            ) {
              //console.log('node: ', node);
              //If the connector child is registered as a children then don't add it to the connector children
              const existingChild = list[map[parentId]].children.filter((x) => {
                x._id == node._id
              });

              //console.log('existingChild: ', existingChild);
              if (!existingChild.length)
                list[map[parentId]].connectorChildren.push(node);
            } else {
              setTimeout(() => {
                //console.log("CALLED")
                list[map[parentId]]?.connectorChildren.push(node)
              }, 100);
            };
          }
        }
      } else {
        roots.push(node);
      }
    }

    //console.log("in createNestedArray - roots before cloning = ", roots);

    const clonedArr = _.cloneDeep(roots);
    this.clonedArr = clonedArr;
    this.utilityService.updateConnectorChildren(clonedArr[0]);

    //console.log("in createNestedArray - roots after cloning = ", roots);

    return roots;
  }

  // Function to remove connectorChildren from children if they exist
  removeConnectorChildrenFromChildren(roots) {
    roots.forEach((node) => {
      //console.log('node: ', node);
      if (node.connectorChildren && node.connectorChildren.length > 0) {
        node.connectorChildren.forEach((connectorChild) => {
          // Check if connectorChild exists in the children array
          console.log('connectorChild: ', connectorChild);
          const childIndex = node.children.findIndex(child => {
            //console.log('child._id: ', child._id);
            //console.log('connectorChild._id: ', connectorChild._id);
            return child._id === connectorChild._id
          });
          //console.log('childIndex: ', childIndex);
          if (childIndex !== -1) {
            // Remove the connectorChild from children array
            node.children.splice(childIndex, 1);
            //console.log('node.children: ', node.children);
          }
        });
      }

      // Recursively check children of the node if they have any connectorChildren
      console.log('node.children: ', node.children);
      if (node.children.length > 0) {
        node.children = this.removeConnectorChildrenFromChildren(node.children);
      }
    });

    return roots; // Return the modified roots
  }
  
  createNestedArray1(list) {
    console.log('list: ', list);

    // Remove duplicates from the list based on _id
    list = list.filter((value, index, self) =>
      index === self.findIndex((t) => t._id === value._id)
    );

    console.log('list updated: ', list);

    // Initialize conclusionLinks array
    this.createInteractiveVideoObj.conclusionLinks = [];

    const map = {};
    let node;
    const roots = [];
    let i;

    // Initialize map and children arrays for each node
    for (i = 0; i < list.length; i += 1) {
      map[list[i]._id] = i; // map node _id to index
      list[i].children = []; // initialize the children array
      list[i].connectorChildren = []; // initialize the connectorChildren array
    }

    // Loop through the list to establish the relationships between nodes
    for (i = 0; i < list.length; i += 1) {
      node = list[i];

      // Handle endingNodeId connections
      if (node.endingNodeId && list[map[node.endingNodeId]]) {
        if (!list[map[node.endingNodeId]].connection) {
          list[map[node.endingNodeId]].connection = 1;
        } else {
          list[map[node.endingNodeId]].connection += 1;
        }
        if (list[map[node.endingNodeId]].isEndingNode) {
          if (!list[map[node.parentId]].endNodes) {
            list[map[node.parentId]].endNodes = [];
          }
          list[map[node.parentId]].endNodes.push(list[map[node.endingNodeId]]);
          // Add direct links between end nodes
          this.createInteractiveVideoObj.conclusionLinks.push({
            source: list[map[node.endingNodeId]],
            target: list[map[node.parentId]],
            type: "conclusion",
          });
          continue;
        }
      }

      // Handle connector node to parentId connections
      if (node.isConnectorNode && node.parentIds && node.parentIds.length > 0) {
        for (const parentId of node.parentIds) {
          if (list[map[parentId]]) {
            this.createInteractiveVideoObj.conclusionLinks.push({
              source: list[map[parentId]],
              target: node,
              type: "conclusion",
            });
          }
          continue;
        }
      }

      // If node has a valid parentId, add it to the parent's children array
      if (node.parentId !== "0") {
        if (list[map[node.parentId]]) {
          const parentNode = list[map[node.parentId]];
          // If it's a connector node, add it to connectorChildren
          if (!node.isConnectorNode) {
            parentNode.children.push(node);
          } else {
            // If it's not a connector node, add it to children
            parentNode.connectorChildren.push(node);
          }
        }
      } else {
        // If no parentId, treat as a root node
        roots.push(node);
      }
    }

    // Now filter the children arrays to ensure that no connector nodes are in them
    for (let i = 0; i < list.length; i++) {
      const node = list[i];
      // Remove connector nodes from the children array
      node.children = node.children.filter(child => !child.isConnectorNode);
    }

    // Store the roots in clonedArr and update the connector children if necessary
    this.clonedArr = roots;
    this.utilityService.updateConnectorChildren(roots[0]);

    console.log('roots: ', roots);
    return roots;
  }

  getVideoPathWays() {
    this.urlService.getPathway({ videoId: this.mainID }).subscribe((res) => {
      this.createInteractiveVideoObj.pathways = res.data;
      //console.log("=====> ", this.createInteractiveVideoObj.pathways)
      for (const watchPath of this.createInteractiveVideoObj.pathways) {
        watchPath.startNode = this.createInteractiveVideoObj.finalObj.find(
          (x) => x._id === watchPath.startNodeId
        );
        watchPath.endNode = this.createInteractiveVideoObj.finalObj.find(
          (x) => x._id === watchPath.endNodeId
        );
      }
      setTimeout(() => {
        // this.setHorizontalScrollbarPosition();
      }, 500);
    });
  }

  // setHorizontalScrollbarPosition() {
  //   const footer = document.getElementById("vid-publish-footer");
  //   const pathwayWrapper = document.getElementById("container-fluid");
  //   const treeView = document.getElementById("tree-view-chart-sec");
  //   console.log('treeView: ', treeView);
  //   let footerHeight = 263;
  //   if (footer) {
  //     footerHeight = this.createInteractiveVideoObj.pathways.length
  //       ? footer.offsetHeight + 176
  //       : footer.offsetHeight + 161;
  //   }

  //   (treeView as any).style.height = `calc(100% - ${footerHeight + "px"})`;

  //   if (pathwayWrapper?.offsetHeight > 60) {
  //     (treeView as any).style.height = `calc(100% - ${footerHeight + (pathwayWrapper.offsetHeight - 60) + "px"
  //       })`;
  //   }
  // }

  getDefaultLangTranscription(video) {
    if (video.subtitleUrl) {
      const defaultLang = this.utilityService.getTranscriptLang(
        video.subtitleUrl
      );
      const langCode = defaultLang.code.split("-")[0];
      let defaultSubTitle = {
        subtitle: {
          code: langCode,
          disabled: false,
          language: defaultLang.language,
        },
      };
      defaultSubTitle[langCode] = video.subtitleUrl;
      return defaultSubTitle;
    }
  }

  zoomToFit(): void {
    if (!this.mainG || !this.chartContent) return;

    const containerRect = this.chartContent.nativeElement.getBoundingClientRect();
    const scale = Math.min(
      containerRect.width / this.treeWidth,
      containerRect.height / this.treeHeight
    ) * 0.9;

    const centerX = (containerRect.width - (this.treeWidth * scale)) / 2;
    const centerY = (containerRect.height - (this.treeHeight * scale)) / 2;

    this.currentTransform = d3.zoomIdentity
      .translate(centerX, centerY)
      .scale(scale);

    this.svg.transition()
      .duration(750)
      .call(this.zoom.transform, this.currentTransform);
    this.updateZoomPercentage(scale);
  }

  private updateZoomPercentage(scale: number): void {
    // if (this.zoomPercentage) {
    const percentage = Math.round(scale * 100);
    this.zoomValue = percentage;
    // this.zoomPercentage.nativeElement.querySelector('span').textContent = ` ${percentage}%`;
    // }
  }

  zoomIn(): void {
    this.svg.transition()
      .duration(300)
      .call(this.zoom.scaleBy, 1.2);
    this.updateZoomPercentage(this.currentTransform.k * 1.2);
  }

  updateZoom(): void {
    const zoomPercentage = this.zoomValue / 100;
    this.svg.transition().duration(300).call(this.zoom.scaleTo, zoomPercentage);
    this.updateZoomPercentage(zoomPercentage);
  }

  zoomOut(): void {
    this.svg.transition()
      .duration(300)
      .call(this.zoom.scaleBy, 1 / 1.2);
    this.updateZoomPercentage(this.currentTransform.k / 1.2);
  }

  //selectedLinkData is passed in the HTML to onContextMenuItem
  onContextMenuAction(action: string, data?: any): void {
    //this.selectedLinkData = data;  // ✅ Store the link data

    console.log("in onContextMenuAction - data", data);

    //if (!this.selectedLinkData) return;
    if (!data) return;

    switch (action) {
        case 'delete':

            // const sourceIdToDelete = data.source.node.data._id;  // ✅ Store source
            // const targetIdToDelete = data.target.node.data._id;  // ✅ Store target

            this.connectorNodesToBeDeleted = {sourceId: data.source.node.data._id, targetId: data.target.node.data._id};

            $("#deleteConnectorModal").modal("show");  // Show delete modal
            break;
    }

    this.closeContextMenu(); // Close the context menu after selection
  }
  
  private deleteSelectedLink(): void {
    if (!this.selectedLink || !this.selectedLinkData) return;

    // Remove the link from the links array
    const index = this.treeData.customLinks.findIndex(link =>
      // link.source.node.data._id === this.selectedLinkData!.source.node.data._id &&
      // link.target.node.data._id === this.selectedLinkData!.target.node.data._id
      (link.source.node as any).data._id === (this.selectedLinkData!.source.node as any).data._id &&
      (link.target.node as any).data._id === (this.selectedLinkData!.target.node as any).data._id
    );
    if (index > -1) {
      this.treeData.customLinks.splice(index, 1);
    }
    d3.select(this.selectedLink).remove();

    // Reset selection state
    this.selectedLink = null;
    this.selectedLinkData = null;
    this.isCustomLink = false;

    this.closeContextMenu();

    this.renderLinks(this.mainG, false);
    this.renderLinks(this.minimapG, true);
  }

  private deleteNode(node: any): void {
    if (!node || !node.parent) return;

    const connectingLinks:any = this.treeData?.customLinks.filter((link:any) => link.target.node.data._id === node.data._id);

    if (connectingLinks.length > 0) {
      const sourceNode = connectingLinks[0].source.node.data;
      const updateTreeData = (data: any): any => {
        if (data.children?.length) {
          data.children = data.children.filter((child: any) => child._id !== node.data._id);
          if (data.children?.length === 0) {
            delete data.children;
          }
          data.children = data.children?.map((child: any) => updateTreeData(child));
        }
        if (data._id === sourceNode._id && node.data) {
          if (!data?.children) {
            data.children = [];
          }
          data.children.push(node.data);
        }
        return data;
      };

      this.treeData.data = updateTreeData({ ...this.treeData.data });

      this.treeData.customLinks = this.treeData.customLinks.filter((link:any) =>
        !(link.source.node.data._id === sourceNode._id && link.target.node.data._id === node.data._id)
      );
      this.root = d3.hierarchy(this.treeData.data as TreeNode);
      this.renderTree(true);
    } else {
      const parent = node.parent;
      const index = parent.children.indexOf(node);
      if (index > -1) {
        parent.children.splice(index, 1);
        if (parent.children.length === 0) {
          delete parent.children;
        }
      }
      this.treeData.customLinks = this.treeData.customLinks.filter((link:any) => link.source.node.data._id !== node.data._id);
    }
  }

  private closeContextMenu(): void {
    const menu = document.querySelector('.context-menu') as HTMLElement;
    if (menu) {
      menu.style.display = 'none';
    }

    // this.selectedLink = null;
    // this.selectedLinkData = null;
  }

  private renderTree(forceUpdate: boolean = false): void {
    console.log("in renderTree");

    this.iconCountMap = new Map();
    if (forceUpdate) {
      this.mainG.selectAll('*').remove();
      this.minimapG.selectAll('*').remove();

      d3.select('body').selectAll('.drag-node').remove();
    }

    // Render links first
    this.renderLinks(this.mainG, false);
    // this.renderLinks(this.minimapG, true);

    // Then render nodes
    this.renderNodes(this.mainG, false);
    this.renderNodes(this.minimapG, true);

    this.updateMinimapViewport();
  }

  private focusOnNode(node: any): void {
    if (!node || !this.svg || !this.chartContent) return;

    const containerRect = this.chartContent.nativeElement.getBoundingClientRect();
    const centerX = containerRect.width / 2;
    const centerY = containerRect.height / 2;

    // Calculate the translation needed to center the node
    const tx = Math.min(0, centerX - (node.y * this.currentTransform.k));
    const ty = centerY - (node.x * this.currentTransform.k);

    this.currentTransform = d3.zoomIdentity
      .translate(tx, ty)
      .scale(this.currentTransform.k);

    this.svg.transition().duration(750).call(this.zoom.transform, this.currentTransform);

  }

  private highlightParentNode(node: any): void {
    // Highlight the parent node
    this.highlightedNode = node;
    const parentNodeGroup = this.mainG.select(`g[data-id="${node.data._id}"]`);
    parentNodeGroup.select('.node-rect')
      .attr('stroke', '#6c2fbb')
      .attr('stroke-width', this.treeConfig.nodeConfig.borderStrokeWidthFocusOn)
      .attr('fill', '#eee3ee');

    const removeHighlight = () => {
      this.highlightedNode = null;
      parentNodeGroup.select('.node-rect')
        .attr('stroke', '#ccc')
        .attr('stroke-width', this.treeConfig.nodeConfig.borderStrokeWidth)
        .attr('fill', 'white');
      // Remove event listeners
      this.svg.on('click.highlight', null);
      this.mainG.selectAll('.node-rect').on('click.highlight', null);
    };

    // Listen for clicks anywhere on svg or other nodes
    this.svg.on('click.highlight', removeHighlight);
    this.mainG.selectAll('.node-rect').on('click.highlight', removeHighlight);
  }

  private calculateTreeDimensions(): void {
    if (!this.root) {
      throw new Error('Tree root is not initialized');
    }

    // const nodeCount = this.root.descendants().length;
    const dx = this.treeConfig.nodeConfig.height * this.treeConfig.nodeConfig.heightRatio; // Double the node height for vertical spacing
    const dy = this.treeConfig.nodeConfig.width * this.treeConfig.nodeConfig.widthRatio; // 2.5x node width for horizontal spacing

    const tree = d3.tree<TreeNode>()
      .nodeSize([dx, dy])
      .separation((a, b) => (a.parent === b.parent ? 0.8 : 0.8));

    tree(this.root);

    let x0 = Infinity, x1 = -Infinity;
    let y0 = Infinity, y1 = -Infinity;

    this.root.each((d: any) => {
      if (typeof d.x !== 'number' || typeof d.y !== 'number') {
        throw new Error('Invalid node coordinates detected');
      }
      x1 = Math.max(x1, d.x);
      x0 = Math.min(x0, d.x);
      y1 = Math.max(y1, d.y);
      y0 = Math.min(y0, d.y);
    });

    // const padding = Math.max(30, Math.min(80, Math.sqrt(nodeCount) * 5));
    const padding = this.treeConfig.nodeConfig.width;
    const ratio = 250 / 150;
    let dimensions = this.validateDimensions(
      y1 - y0 + padding * 2,
      x1 - x0 + padding * 2
    );
    const largeSize = dimensions.width > dimensions.height ? 'width' : 'height';
    const newPadding = Math.max(largeSize == 'width' ? ((dimensions.width / ratio) - dimensions.height) / 2 : ((dimensions.height * ratio) - dimensions.width) / 2, padding);

    dimensions = this.validateDimensions(
      y1 - y0 + newPadding * 2,
      x1 - x0 + newPadding * 2
    );
    this.treeWidth = dimensions.width;
    this.treeHeight = dimensions.height;

    this.root.each((d: any) => {
      d.x = Math.max(0, d.x - x0 + newPadding);
      d.y = Math.max(0, d.y - y0 + newPadding);
    });
    // Adjust the root node position
    this.root.x = this.treeHeight / 2;

    this.root.descendants().forEach((d: any) => {
      d.x += dimensions.height > (dimensions.width * 2) ? this.treeHeight / 2 : 0;
    });
    const { scale, width, height } = this.calculateOptimalScale();
    this.minimapScale = scale;
    this.minimapWidth = width;
    this.minimapHeight = height;
  }

  private generateCustomLinks(): void {
    try {
      console.log('*** in generateCustomLinks - this.treeData.customLinks: ', this.treeData.customLinks);
      if (this.treeData.customLinks.length > 0) {
        this.treeData.customLinks.forEach((link: any) => {

          //console.log('*** in generateCustomLinks - link: ', link);
          if (link.source?._id && link.target?._id) {
            //console.log('*** in generateCustomLinks - link is VALID: ', link);
          
            const sourceNode = this.findNodeById(link.source._id);
            const targetNode = this.findNodeById(link.target._id);
            // console.log('in generateCustomLink - sourceNode: ', sourceNode);
            // console.log('in generateCustomLink - targetNode: ', targetNode);
            
            if (sourceNode && targetNode) {
              const sourceSide = link.sourceSide || "right"; // Default side
              const targetSide = link.targetSide || "left"; // Default side

              const sourceOffset = this.getConnectionPointOffset(sourceSide);
              const targetOffset = this.getConnectionPointOffset(targetSide);

              const source: ConnectionPoint = {
                x: sourceOffset.x || 0, // Ensure no undefined values
                y: sourceOffset.y || 0,
                side: sourceSide,
                node: sourceNode
              };

              const target: ConnectionPoint = {
                x: targetOffset.x || 0,
                y: targetOffset.y || 0,
                side: targetSide,
                node: targetNode
              };

              //console.log('*** in generateCustomLinks - source: ', source);
              //console.log('*** in generateCustomLinks - target: ', target);
              
              //OperationType of render is used to just draw the connector link only without creating a new connector child
              //Add delay to ensure that source and target nodes are available
              setTimeout(() => {
                this.createLink(source, target, "renderOnly");
              }, 100);  // Delay by 100ms

            }
          }
        });
      }
    } catch (error) {
      console.error('Error loading links:', error);
    }
  }

  private renderLinks(g: any, isMinimap: boolean): void {
      const linkGroups = g.append('g')
        .selectAll('g')
        .data(this.root.links())
        .join('g')
        .attr('class', 'link-group');

      const links = linkGroups.append('path')
        .attr('cursor', isMinimap ? 'default' : 'pointer')
        .attr('fill', 'none')
        .attr('stroke', this.treeConfig.linkConfig.linkColor)
        .attr('stroke-opacity', isMinimap ? 0.5 : this.treeConfig.linkConfig.linkOpacity)
        .attr('stroke-width', isMinimap ? 0.5 : this.treeConfig.linkConfig.width)
        .attr('marker-end', 'url(#arrow)')
        .attr("d", (d: { source: d3.HierarchyNode<any>; target: d3.HierarchyNode<any> }) => {
          const sourceX = d.source.x!;
          const sourceY = d.source.y! + this.treeConfig.nodeConfig.width / 2;
          const targetX = d.target.x!;
          const targetY = d.target.y! - this.treeConfig.nodeConfig.width / 2 - this.treeConfig.linkArrowSize;
          return d3.linkHorizontal()({
            source: [sourceY, sourceX],
            target: [targetY, targetX],
          });
        });
  
      if (!isMinimap) {
        links
          .on('mouseover', (event, d) => {
            this.highlightLinks(d3.select(event.target), true, isMinimap);
            d3.select(event.target.parentNode).select('.delete-icon-group').attr('opacity', 1);   //Show the arrow 
          })
          .on('mouseout', (event, d) => {
            this.highlightLinks(d3.select(event.target), false, isMinimap);
            d3.select(event.target.parentNode).select('.delete-icon-group').attr('opacity', 0); //Hide the arrow 
          })
          .on('click', (event, d) => {
            event.stopPropagation();
            this.onContextMenuAction('delete', d); // ✅ Delete link when clicked
          });
      }
      
  }
  

  private renderNodes(g: d3.Selection<SVGGElement, unknown, null, undefined>, isMinimap: boolean): void {
    // Create links container first so it's behind nodes
    if (!isMinimap) {
      g.selectAll('.links-container').remove();
      g.append('g')
        .attr('class', 'links-container');
    }

    const nodes = g.append('g')
      .selectAll('g')
      .data(this.root.descendants())
      .join('g')
      .attr('transform', (d: any) => `translate(${d.y},${d.x})`);

    if (isMinimap) {
      const minimapAspectRatio = this.treeWidth / this.treeHeight;

      console.log("in renderNodes for minimap - minimapAspectRatio = ", minimapAspectRatio);

      //Tall or Wide Tree
      if ((minimapAspectRatio > 0.7 && minimapAspectRatio < 1) || (minimapAspectRatio > 2.6 && minimapAspectRatio < 3)) {
        //Increase the minimap node size
        const minimapNodeConfigWidth = this.treeConfig.nodeConfig.width * 1.5;
        const minimapNodeConfigHeight = this.treeConfig.nodeConfig.height * 1.5;

        nodes.append('rect')
          .attr('x', -minimapNodeConfigWidth / 2)
          .attr('y', -minimapNodeConfigHeight / 2)
          .attr('width', minimapNodeConfigWidth)
          .attr('height', minimapNodeConfigHeight)
          .attr('fill', '#999')
          .attr("id", (d) => "node-minimap-" + d.data._id)
          .attr('minimap-data-id', (d: any) => d.data._id)
      } else {

        nodes.append('rect')
          .attr('x', -this.treeConfig.nodeConfig.width / 2)
          .attr('y', -this.treeConfig.nodeConfig.height / 2)
          .attr('width', this.treeConfig.nodeConfig.width)
          .attr('height', this.treeConfig.nodeConfig.height)
          .attr('fill', '#999')
          .attr("id", (d) => "node-minimap-" + d.data._id)
          .attr('minimap-data-id', (d: any) => d.data._id)

      }
    } else {

      const nodeGroup = nodes.append('g')
        .attr('cursor', 'pointer')
        .attr('id', 'node')
        .attr('data-id', (d: any) => d.data._id) // Add data-id attribute here

      nodeGroup.append('rect')
        .attr('class', 'node-rect')
        .attr('x', -this.treeConfig.nodeConfig.width / 2)
        .attr('y', -this.treeConfig.nodeConfig.height / 2)
        .attr('width', this.treeConfig.nodeConfig.width)
        .attr('height', this.treeConfig.nodeConfig.height)
        .attr('rx', this.treeConfig.nodeConfig.borderRadius)
        .attr('ry', this.treeConfig.nodeConfig.borderRadius)
        .attr('fill', 'white')
        .attr("stroke", (d) =>
            d.data?.isEndingNode || d.data?.isConnectorNode ? "#DC3545" : "#ccc"
          )
        .attr("id", (d) => "node-button-" + d.data._id)
        .attr('data-id', (d: any) => d.data._id)
        .attr('stroke-width', this.treeConfig.nodeConfig.borderStrokeWidth);

      // Flag to track if image was clicked
      let isImageClicked = false;

      nodeGroup.append('image')
        .attr('x', -this.treeConfig.nodeConfig.width / 2 + this.treeConfig.nodeConfig.imagePadding)
        .attr('y', -(this.treeConfig.nodeConfig.height / 2) + this.treeConfig.nodeConfig.imagePadding)
        .attr('width', this.treeConfig.nodeConfig.width - (this.treeConfig.nodeConfig.imagePadding * 2))
        .attr('height', this.treeConfig.nodeConfig.height / 2)
        // .attr('height', this.treeConfig.nodeConfig.height * 0.6)
        // .attr('href', (d: any) => d.poster  ? d.data.poster : '../../../../assets/images/noimage.jpg')
        .attr('href', (d: any) => {
          return d.data.poster !== undefined ? d.data.poster : d.data.poster1 !== null ? d.data.poster1 :  '../../../../assets/images/noimage.jpg'
        })
        // .attr("preserveAspectRatio", "xMidYMid slice")
        .attr("preserveAspectRatio", "xMidYMid meet")
        .attr("class", "nodeImage")

        .on('mouseover', function (event, d) {
          
          // Call your function on mouse hover
          self.currentHoveredNode = d;
          // console.log("self.currentHoveredNode", self.currentHoveredNode)
          self.createInteractiveVideoObj.currentObj = d.data
          self.openNodeContextMenu(event, d);

          if (!isImageClicked)
            self.updateNodeContextMenuPosition(event);
        })
        .on('mouseout', function (event, d) {
          if (!isImageClicked) 
            self.updateNodeContextMenuPosition(event);
        })
        .on('mouseleave', function (event, d) {
          
          self.hideTooltip();
          const node = d3.select(this);
          node.select('rect')
            .attr('stroke', '#ccc')
            .attr('stroke-width', self.treeConfig.nodeConfig.borderStrokeWidth);
          setTimeout(() => {
            if (!self.isNodeHoverActive) {
              self.closeNodeContextMenu();
              self.currentHoveredNode = ''
            }
          }, 200);
        }) // Click event
        .on('click', function (event, d) {
            isImageClicked = true;
            
            self.onNodeContextMenuAction('update-video');

            // ✅ Reset after a short delay to allow normal behavior again
            setTimeout(() => {
              isImageClicked = false;
            }, 200);
        });

      // Create a tooltip div (hidden by default) to show that the user can drag a line from a connection point
      const tooltip = d3.select('body')
        .append('div')
        .attr('class', 'tooltip')
        .style('position', 'absolute')
        .style('background', '#fff')
        .style('border', '1px solid #ccc')
        .style('border-radius', '5px')
        .style('padding', '5px 10px')
        .style('font-size', '12px')
        .style('color', '#333')
        .style('pointer-events', 'none') // Prevent interference
        .style('display', 'none'); // Initially hidden

      // Add connection points container
      const connectionPoints = nodeGroup.append('g')
        .attr('class', 'connection-points')
        .style('display', 'none');

      // Add connection points at four sides
      const connectionPositions = [
        { x: 0, y: -this.treeConfig.nodeConfig.height / 2, side: 'top' },
        { x: this.treeConfig.nodeConfig.width / 2, y: 0, side: 'right' },
        { x: 0, y: this.treeConfig.nodeConfig.height / 2, side: 'bottom' },
        { x: -this.treeConfig.nodeConfig.width / 2, y: 0, side: 'left' }
      ];

      connectionPositions.forEach(pos => {

        // Append connection points
        connectionPositions.forEach(pos => {
          const isRightSide = pos.side === 'right';

          const circle = connectionPoints.append('circle')
            .attr('class', 'connection-point')
            .attr('cx', pos.x)
            .attr('cy', pos.y)
            .attr('r', this.treeConfig.nodeConfig.connectorDotSize)
            .attr('fill', 'red')
            .attr('stroke', '#555')
            .attr('stroke-opacity', 0.4)
            .attr('stroke-width', 1)
            .attr('cursor', 'pointer')
            .attr('data-side', pos.side)
            .attr('visibility', isRightSide ? 'visible' : 'hidden') // Hide all except right side
            .call(d3.drag<SVGCircleElement, d3.HierarchyNode<TreeNode>>()
              .on('start', (event, d) => this.handleLinkDragStart(event, d, pos.side))
              .on('drag', (event) => this.handleLinkDrag(event))
              .on('end', (event) => this.handleLinkDragEnd(event)));

          // Add tooltip events only to the right-side connection point
          if (isRightSide) {
            circle
              .on('mouseover', function (event) {
                console.log("in renderNodes - mouseover the red dot");
            
                // Debugging: Log tooltip display state before modification
                console.log("in renerNodes - Tooltip before mouseover:", tooltip.style("display"));
            
                tooltip
                  .style('display', 'block')
                  .style('opacity', 1)  // 🔹 Ensure opacity is set
                  .style('z-index', '9999') // 🔹 Ensure tooltip is above everything
                  .style('left', `${event.pageX + 20}px`) // 🔹 Shift right for better visibility
                  .style('top', `${event.pageY - 20}px`) // 🔹 Shift up for better visibility
                  .html('Drag a line to another node');
            
                  // Debugging: Log tooltip display state after modification
                  console.log("in renderNodes - Hover over the Red Dot. Tooltip after mouseover:", tooltip.style("display"));
                })
            
                .on('mousemove', function (event) {
                  tooltip
                    .style('left', `${event.pageX + 10}px`)
                    .style('top', `${event.pageY - 10}px`);
                })
                .on('mouseout', function () {
                  tooltip.style('display', 'none');
                });
          };
        });
      });

        
      const addIcons = (config: { className: string, imageUrl: string, icon: string, position: number }) => {
        const iconSpacing = 4;
        const topMargin = 15;

        const xOffset = -this.treeConfig.nodeConfig.detailsIconBg / 2 - (this.treeConfig.nodeConfig.detailsIconBg + iconSpacing) * config.position;
        const yOffset = -this.treeConfig.nodeConfig.height / 2 - topMargin;
        const iconXOffset = xOffset + this.treeConfig.nodeConfig.detailsIconBg / 2;
        const iconYOffset = yOffset + this.treeConfig.nodeConfig.detailsIconBg / 2;

        let iconGroup = null;
        let subTitleimgGroup = null;
        let questionnareimgGroup = null;

        if (config.icon) {          
          iconGroup = nodeGroup.append('g')
            .attr('class', `icon ${config.className}`)
            //.style('opacity', 0)
            .style('opacity', 1)
            .attr('transform', `translate(${this.treeConfig.nodeConfig.width / 2}, 0)`); // Anchor to right edge
  
          iconGroup.append('rect')
            .attr('x', xOffset)
            .attr('y', yOffset)
            .attr('width', this.treeConfig.nodeConfig.detailsIconBg)
            .attr('height', this.treeConfig.nodeConfig.detailsIconBg)
            .attr('rx', 3)
            .attr('ry', 3)
            .attr('fill', '#c495c4')
            .style('cursor', 'pointer')
            .on('mouseover', function () {
              d3.select(this).attr('fill', '#4a0080');
            })
            .on('mouseout', function () {
              d3.select(this).attr('fill', '#c495c4');
            });
          
          iconGroup.append('text')
            .attr('x', iconXOffset)
            .attr('y', iconYOffset)
            .attr('text-anchor', 'middle')
            .attr('dominant-baseline', 'central')
            .attr('font-family', 'FontAwesome')
            .attr('font-size', `${this.treeConfig.nodeConfig.detailsIconSize}px`)
            .attr('fill', '#fff')
            .style('cursor', 'pointer')
            .style('pointer-events', 'none')
            .text(config.icon);
        }

        if (config.imageUrl) {
          // For subTitleURL
          subTitleimgGroup = nodeGroup.append('g')
            .attr('class', `icon ${config.className}`)
            //.style('opacity', 0)
            .style('opacity', 1)
            .attr("visibility", (d) => {
              return `${d.data.videoID && (d.data.subtitleUrl || d.data.subtitleTranslations?.length > 0)
                ? "visible"
                : "hidden"
                }`;
            })
            .attr('transform', `translate(${this.treeConfig.nodeConfig.width / 2}, 0)`)
            .on('mouseover', (event, d) => {
              if (d.data.subtitleTranslations.length > 0) {
                let subTitleContent = "<div><b>Subtitles</b>";
                d.data.subtitleTranslations.forEach((subtitleTranslation:any) => {
                  subTitleContent += `
                      <div>
                        ${subtitleTranslation?.subtitle?.language} 
                        (${subtitleTranslation?.subtitle?.code})
                      </div>
                    `;
                });
                subTitleContent += "</div>";

                const popover = new bootstrap.Popover(event.currentTarget, {
                  content: subTitleContent,
                  placement: 'right',
                  trigger: 'hover',
                  html: true
                });

                popover.show();
                event.currentTarget._popoverInstance = popover;

              }
            })
            .on('mouseout', function (event) {
              const popover = event.currentTarget._popoverInstance;
              if (popover) {
                popover.dispose(); 
                delete event.currentTarget._popoverInstance;
              }
            });

          subTitleimgGroup.append('rect')
            .attr('x', xOffset)
            .attr('y', yOffset)
            .attr('width', this.treeConfig.nodeConfig.detailsIconBg)
            .attr('height', this.treeConfig.nodeConfig.detailsIconBg)
            .attr('rx', 3)
            .attr('ry', 3)
            .attr('fill', 'white')
            .style('cursor', 'pointer')
            .on('mouseover',  (event, d) => {
              // d3.select(this).attr('fill', '#4a0080')
            })
            .on('mouseout', function (event) {
              d3.select(this).attr('fill', 'white');
            });
          
          subTitleimgGroup.append('image')
            .attr('x', iconXOffset - this.treeConfig.nodeConfig.detailsIconSize / 2)
            .attr('y', iconYOffset - this.treeConfig.nodeConfig.detailsIconSize / 2)
            .attr('width', this.treeConfig.nodeConfig.detailsIconSize)
            .attr('height', this.treeConfig.nodeConfig.detailsIconSize)
            .attr('href', config.imageUrl)
            .style('cursor', 'pointer');
          
          
          // For questionnaires
          questionnareimgGroup = nodeGroup.append('g')
            .attr('class', `icon ${config.className}`)
            //.style('opacity', 0)
            .style('opacity', 1)
            .attr("visibility", (d) => {
              return `${d.data.questionnaireInfo
                ? "visible"
                : "hidden"
                }`;
            })
            .attr('transform', `translate(${this.treeConfig.nodeConfig.width / 2}, 0)`)

          questionnareimgGroup.append('rect')
            .attr('x', xOffset)
            .attr('y', yOffset)
            .attr('width', this.treeConfig.nodeConfig.detailsIconBg)
            .attr('height', this.treeConfig.nodeConfig.detailsIconBg)
            .attr('rx', 3)
            .attr('ry', 3)
            .attr('fill', 'white')
            .style('cursor', 'pointer')
            .on('mouseover',  (event, d) => {
              // d3.select(this).attr('fill', '#4a0080')
            })
            .on('mouseout', function (event) {
              d3.select(this).attr('fill', 'white');
            });
          
          // Add image instead of icon
          questionnareimgGroup.append('image')
            .attr('x', iconXOffset - this.treeConfig.nodeConfig.detailsIconSize / 2)
            .attr('y', iconYOffset - this.treeConfig.nodeConfig.detailsIconSize / 2)
            .attr('width', this.treeConfig.nodeConfig.detailsIconSize)
            .attr('height', this.treeConfig.nodeConfig.detailsIconSize)
            .attr('href', config.imageUrl)
            .style('cursor', 'pointer');
        }


        return iconGroup || subTitleimgGroup || questionnareimgGroup;
      }

      const handleIconClick = (event: any, d: any, iconType: string) => {
        event.stopPropagation();
        event.preventDefault();
        console.log(`${iconType} clicked for node:`, d);
      };

      const quesImgGroup = nodeGroup
        .append("svg:g")
        //.attr("transform", "matrix(1,0,0,1,80,-130)")   //Move the image 80px to the right and 130 px up
        .attr("transform", (d) => {
          if (d.data.questionnaireInfo) {
            const position = this.getIconPosition(d);
            return position;
          }
        })
        .attr("fill", "mediumpurple")
        .attr("cursor", "pointer")
        .attr("class", "node-action")
        .attr("visibility", (d) => {
          return `${d.data.questionnaireInfo ? "visible" : "hidden"}`;
        });

      quesImgGroup
        .append("rect")   //outer rectangle for questionnaire icon
        .attr("rx", 6)
        .attr("ry", 6)
        // .attr("height", this.imageConfig.questionnaires.height + 7)
        // .attr("width", this.imageConfig.questionnaires.width + 7);
        .attr("height", this.imageConfig.questionnaires.height + 20)
        .attr("width", this.imageConfig.questionnaires.width + 20);

      quesImgGroup
        .append("image")  //questionnaire icon
        // .attr("x", 3)
        // .attr("y", 3)
        .attr("x", 10) //7.5px inside the outer rectangle
        .attr("y", 10)
        .attr("height", this.imageConfig.questionnaires.height)
        .attr("width", this.imageConfig.questionnaires.width)
        .attr("xlink:href", "assets/images/questionnaire.png");
      
      // For timer
      const timerIcon = nodeGroup
        .append("svg:g")
        .attr("transform", (d) => {
          if (d?.data.timer) {
            const position = this.getIconPosition(d);
            // console.log("timerIcon for " + d?.data.name + ", position = ", position);
            return position;
          }
        })
        .attr("fill", "transparent")
        .attr("cursor", "pointer")
        .attr("class", "node-action")
        .attr("visibility", (d) => {
          // console.log("d?.data.timer for " + d?.data.name + " " + d?.data.timer);
          return `${d?.data.timer ? "visible" : "hidden"}`;
        });

      let timerIconHTML = `<div style="
        font-size: 40px;   
        background: mediumpurple;
        border-radius: 7px;
        padding: 1px;
        color: white;
        text-align: center;
        display: flex;
        justify-content: center;
        width: ${this.imageConfig.timer.width}px;
        align-items: center;
        height: ${this.imageConfig.timer.height}px;
      "><i class="fa fa-clock"></i></div>`;

      timerIcon
        .append("foreignObject")
        .attr("height", this.imageConfig.timerForeignObject.height)
        .attr("width", this.imageConfig.timerForeignObject.width)
        .html(timerIconHTML);

      // For AI-generated script
      const aiGeneratedScriptIcon = nodeGroup
        .append("svg:g")
        //.attr("transform", "matrix(1,0,0,1,80,-130)")   //Move the image 80px to the right and 130 px up
        .attr("transform", (d) => {
          if (d?.data?.aiGeneratedScript) {
            const position = this.getIconPosition(d);
            return position;
          }
        })
        .attr("fill", "mediumpurple")
        .attr("cursor", "pointer")
        .attr("class", "node-action")
        .attr("visibility", (d) => {
          return `${d?.data?.aiGeneratedScript ? "visible" : "hidden"}`;
        });

      aiGeneratedScriptIcon
        .append("rect")   //outer rectangle for questionnaire icon
        .attr("rx", 6)
        .attr("ry", 6)
        .attr("height", this.imageConfig.script.height + 15)
        .attr("width", this.imageConfig.script.width + 15 );

      aiGeneratedScriptIcon
        .append("image")  //questionnaire icon
        .attr("x", 8) //10px inside the outer rectangle
        .attr("y", 8)
        .attr("height", this.imageConfig.script.height)
        .attr("width", this.imageConfig.script.width)
        .attr("xlink:href", "assets/images/script.png");

      // For subtitle
      const subtitleImgGroup = nodeGroup
        .append("svg:g")
        //.attr("transform", "matrix(1,0,0,1,80,-130)")   //Move the image 80px to the right and 130 px up
        .attr("transform", (d) => {
          if (d.data?.videoID && (d.data?.subtitleUrl || d.data?.subtitleTranslations?.length > 0)) {
            const position = this.getIconPosition(d);
            return position;
          }
        })
        .attr("fill", "mediumpurple")
        .attr("cursor", "pointer")
        .attr("class", "node-action")
        .attr("visibility", (d) => {
          return `${d.data?.videoID && (d.data?.subtitleUrl || d.data?.subtitleTranslations?.length > 0) ? "visible" : "hidden"}`;
        });

      subtitleImgGroup
        .append("rect")   //outer rectangle for subtitle icon
        .attr("rx", 6)
        .attr("ry", 6)
        .attr("height", 60)
        .attr("width", 60)

      subtitleImgGroup
        .append("image")  //subtitle icon
        .attr("x", 6.0) //7.5px inside the outer rectangle
        .attr("y", 6.0)
        .attr("height", 50)
        .attr("width", 50)
        .attr("xlink:href", "assets/images/subtitle01.png");

      subtitleImgGroup
        .on("mouseover", function (d) {
          if (d) {
            let subTitleContent = "<div><b>Subtitles</b>";
            d.data?.subtitleTranslations.forEach((subtitleTranslation) => {
              subTitleContent += `
                                  <div>
                                      ${subtitleTranslation?.subtitle?.language} 
                                      (${subtitleTranslation?.subtitle?.code})
                                  </div>
                              `;
            });
            subTitleContent += "</div>";
            $(this).popover({
              content: subTitleContent,
              placement: "right",
              trigger: "hover",
              html: true,
            });
            $(this).popover("show");
          }
        })
        .on("mouseout", function () {
          $(this).popover("hide");
        });

      
      const self = this;

      // Node text
      nodeGroup.append('text')
        //.attr('dy', '2.32em')
        //.attr('x', 0) // Center text horizontally
        .attr('y', this.treeConfig.nodeConfig.imagePadding + this.treeConfig.nodeConfig.height / 2 - 50)
        .attr('text-anchor', 'middle')
        .attr('fill', '#333')
        .text((d: any) => {
          const name = d.data.name;
          return name?.length >= 15 ? name.substring(0, 10) + '...' : name;
        })
        .style('font-size', `${this.treeConfig.nodeConfig.labelSize}px`)
        .style('font-family', 'Roboto')
        .on('mouseover', function (event, d) {
          // Call your function on mouse hover
          self.showTooltip(event, d.data.name);
        })
        .on('mouseout', function (event, d) {
          // Handle mouse out if needed
          self.hideTooltip();
        });


      nodeGroup
        .on('click', (event: MouseEvent, d: any) => {
          console.log("d ====>", d)
          // this.clickedItem(d?.data);

          event.stopPropagation();
          // this.handleNodeClick(event, d);
          // self.openNodeContextMenu(event, d);
        })
        .call(
          d3.drag<any, any>()
            // .on('start', this.handleDragStart.bind(this))
            .on('drag', this.handleDragMove.bind(this))
            .on('end', this.handleDragEnd.bind(this))
        )
        .on('mouseenter', function (event: MouseEvent, d: any) {
          if ((self.highlightedNode && d.data._id == self.highlightedNode?.data._id) || self.isDraggingLink || self.startConnectionPoint) return;
          
          const node = d3.select(this);
          node.select('rect')
            .attr('stroke', '#6c2fbb')
            .attr('stroke-width', 4)
            .attr('fill', '#eee3ee') // Light purple background
            .classed('node-focused', true); 

          self.activeNode = d;
        })

        // .on('mousemove', (event: MouseEvent) => {
        // })
        
        .on('mouseleave', function (event, d) {
          self.hideTooltip();

            // ✅ Don't reset styles if context menu is open
          if (!self.isNodeHoverActive) {  
            const node = d3.select(this);
            node.select('rect')
            .attr("stroke", (d:any) =>
                d.data?.isEndingNode || d.data?.isConnectorNode ? "#DC3545" : "#ccc"
              )
              .attr('stroke-width', self.treeConfig.nodeConfig.borderStrokeWidth)
              .attr('fill', 'none') 
            }
        })
    }
  }

  private showTooltip(event: MouseEvent, text: string): void {
    this.tooltip
      .style('visibility', 'visible')
      .style('background', '#616161')
      .style('color', '#fff')
      .text(text);
    this.updateTooltipPosition(event);
  }

  private updateNodeContextMenuPosition(event: MouseEvent): void {

    console.log("in updateNodeContextMenuPosition - event = ", event);

    const menu = document.querySelector('.node-context-menu') as HTMLElement;
    if (!menu) return;
    const menuWidth = menu.offsetWidth;
    const menuHeight = menu.offsetHeight;
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    let x = event.pageX + (menu.offsetWidth / 2);
    let y = event.pageY + 20; // Default below the mouse pointer

    // Check if the menu overflows the right side
    if (x + menuWidth > windowWidth) {
      x = event.pageX - menuWidth / 2; // Shift left if needed
    }

    // Check if the menu overflows the bottom side
    if (y + menuHeight > windowHeight) {
      y = event.pageY - menuHeight - 10; // Shift up if needed
    }

    // Show the menu at the computed position
    menu.style.display = 'block';
    menu.style.left = `${x}px`;
    menu.style.top = `${y}px`;
  }

  
  private updateMinimapViewport(): void {
    if (!this.svg || !this.mainG || !this.viewportRect) return;

    const visibleProportion = this.calculateVisibleProportion();

    // Calculate visible area in minimap coordinates with dynamic scaling
    const visibleX = (-this.currentTransform.x / this.currentTransform.k) * this.minimapScale;
    const visibleY = (-this.currentTransform.y / this.currentTransform.k) * this.minimapScale;

    // Calculate viewport dimensions maintaining proportions
    const viewportWidth = Math.min(
      this.minimapWidth,
      this.minimapWidth * visibleProportion.width
    );
    const viewportHeight = Math.min(
      this.minimapHeight,
      this.minimapHeight * visibleProportion.height
    );

    // Ensure viewport stays within bounds while maintaining proportions
    const boundedX = Math.max(0, Math.min(visibleX, this.minimapWidth - viewportWidth));
    const boundedY = Math.max(0, Math.min(visibleY, this.minimapHeight - viewportHeight));

    this.viewportRect
      .attr('x', boundedX)
      .attr('y', boundedY)
      .attr('width', viewportWidth)
      .attr('height', viewportHeight)
      .attr('rx', 0)
      .attr('ry', 0);
  }

  private findNodeById(id: string): any {
    let foundNode = null;
    this.root.descendants().forEach((node: any) => {
      if (node.data._id === id) {
        foundNode = node;
      }
    });
    return foundNode;
  }

  private getConnectionPointOffset(side: 'top' | 'right' | 'bottom' | 'left'): { x: number, y: number } {
    const halfWidth = this.treeConfig.nodeConfig.width / 2;
    const halfHeight = this.treeConfig.nodeConfig.height / 2;

    switch (side) {
      case 'top': return { x: 0, y: -halfHeight };
      case 'right': return { x: halfWidth, y: 0 };
      case 'bottom': return { x: 0, y: halfHeight };
      case 'left': return { x: -halfWidth, y: 0 };
    }
  }

  //Create connector links
  private createLink(source: ConnectionPoint, target: ConnectionPoint, operationType?: string): void {
    //console.log('in createLink - operationType: ', operationType);

    if (operationType === undefined)
        operationType = 'createConnectorNode';

    const linkData: LinkData = { source, target };
    //console.log('in createLink - linkData: ', linkData);

    // Ensure defs exists
    let defs = this.mainG.select('defs');
    if (defs.empty()) {
        defs = this.mainG.append('defs');
    }

    // Remove existing glow filter to prevent duplication
    defs.selectAll("#glow").remove();

    // Define a red glow filter
    const filter = defs.append("filter")
        .attr("id", "glow")
        .attr("x", "-50%")
        .attr("y", "-50%")
        .attr("width", "200%")
        .attr("height", "200%");

    // Add blur effect
    filter.append("feGaussianBlur")
        .attr("stdDeviation", "3.5") // Adjust for more or less glow
        .attr("result", "coloredBlur");

    // Merge original with the glow effect
    const feMerge = filter.append("feMerge");
    feMerge.append("feMergeNode").attr("in", "coloredBlur");
    feMerge.append("feMergeNode").attr("in", "SourceGraphic"); // Keep the original link

    // Remove old marker if exists to prevent duplication
    const marker = defs.select('#connector-arrow');
    if (!marker.empty()) {
        marker.remove();
    }

    // Define arrow marker
    defs.append('marker')
    .attr('id', 'connector-arrow')
    .attr('viewBox', '0 0 10 10')
    .attr('refX', 5) // Move arrow outward
    .attr('refY', 5)
    .attr('fill', 'red')  
    .attr('markerWidth', 10)  
    .attr('markerHeight', 10)          
    .attr('orient', 'auto')
    .append('path')
    .attr('d', 'M 0 0 L 10 5 L 0 10 Z') // Triangle shape


    // Highlighted arrow (bigger)
    defs.append('marker')
    .attr('id', 'connector-arrow-highlighted')
    .attr('viewBox', '0 0 10 10')
    .attr('refX', 5)
    .attr('refY', 5)
    .attr('markerWidth', 12)  // Bigger arrow for hover state
    .attr('markerHeight', 12)
    .attr('orient', 'auto')
    .append('path')
    .attr('d', 'M 0 0 L 10 5 L 0 10 Z')
    .attr('fill', 'red');  // Red color for hover state

    // Add the visual link to the links container
    const links = this.mainG.select('.links-container')
        .append('path')
        .datum(linkData)  // ✅ Attach link data so .datum() works later
        .attr('class', 'link')
        .attr('fill', 'none')
        .attr('stroke-width', 2)  // Ensure arrow is attached correctly
        .attr('cursor', 'pointer')
        .attr('d', this.calculateLinkPath(source, target, "connector"));

      setTimeout(() => {
          links.attr('marker-end', 'url(#connector-arrow)');
      }, 100); // Delay ensures nothing else overwrites it
      
    //console.log("in createLink - marker-end set to: ", links.attr("marker-end"));

    this.highlightConnectorLinks(links, false, false);

    //console.log("in createLink - setting connector node with target: ", target);

    if (operationType === 'createConnectorNode') {
        this.setConnectorNode(target); // Create the node for the connector link

        this.reinitializeChart()
    }

    links
        .on('mouseover', (event: MouseEvent, d: any) => {
          
          if (this.selectedLink) return;
          const clickedLink = event.target as SVGPathElement;
          console.log("in createLink - mouseover event, clickedLink = ", clickedLink);

          this.highlightConnectorLinks(d3.select(clickedLink), true, false);
          this.isCustomLink = true;
          this.selectedLink = clickedLink;
          this.selectedLinkData = linkData;
          this.isLinkHoverActive = false;
          this.openContextMenu(event, linkData);

        })
        .on('mouseout', (event: MouseEvent, d: any) => {
          
          setTimeout(() => {
              if (this.isLinkHoverActive) return;
              const clickedLink = event.target as SVGPathElement;
              console.log("in createLink - mouseoout event, clickedLink = ", clickedLink);

              this.highlightConnectorLinks(d3.select(clickedLink), false, false);

              this.selectedLink = null;
              this.selectedLinkData = null;

              this.closeContextMenu();
          }, 500);
        });
  }

  setConnectorNode(target: any): void {
    
    const payload = {
      URL: target.node.data.URL,
      isConnectorNode: true,
      isEndingNode: false,
      name: target.node.data.name,
      oldParentId: target.node.data.parentId,
      parentId: this.startConnectionPoint?.node.data._id,
      parentIds: target.node.data.parentIds,
      poster: target.node.data.poster ? target.node.data.poster : target.node.data.thumbnail,
      time: 0,
      type: 'child',
      videoID: target.node.data.videoID,
      _id: target.node.data._id,
    }

    console.log('in setConnectorNode - payload: ', payload);
    const index = this.currentHoveredNode?.data?.children?.length;

    this.urlService.createInteractiveVideo(payload).subscribe(
      (success) => {
        console.log("in setConnectorNode - Adding NEW CHILD --- success = ", success);

        this.createChoiceObj.child[index] = success.data?.data;

        //console.log("Adding NEW CHILD - this.createChoiceObj.child[index].name = ", this.createChoiceObj.child[index].name);

        this.createChoiceObj.child[index].URLName =
          this.createChoiceObj.child[index].name;
        this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;

        if (
          this.createInteractiveVideoObj.currentObj.children === undefined
        ) {
          this.createInteractiveVideoObj.currentObj.children = [];
        }
        const childObjFinal = success.data?.data;
        // this.createInteractiveVideoObj.currentObj.children.push(objFinal);
        this.createInteractiveVideoObj.currentObj.children.push(
          childObjFinal
        );
        this.callChild1();
        this.toastr.info("Connected Successfully!");
        if (this.isAssistCreateVideo) {
          this.assistEventsSubject.next({
            type: this.msgEvent.addChildNode,
            data: payload,
          });
        }
      },
      (error) => {
        this.createInteractiveVideoObj.spinner = false;
        this.toastr.error("Error in creating connector node. " + error.message);
      }
    );
  }

  private highlightLinks(links: any, isHighLighted: boolean, isMinimap: boolean): void {
    console.log("in highlightLinks - links = ", links);

    if (isHighLighted) {
      links.attr('stroke', this.treeConfig.linkConfig.highlightedLinkColor)
        .attr('stroke-opacity', isMinimap ? 0.5 : this.treeConfig.linkConfig.highlightedLinkOpacity)
        .attr('stroke-width', isMinimap ? 0.5 : this.treeConfig.linkConfig.highlightedWidth)
        .attr("marker-end", "url(#arrow-highlighted)"); // Use highlighted marker
    } else {
      links.attr('stroke', this.treeConfig.linkConfig.linkColor)
        .attr('stroke-opacity', isMinimap ? 0.5 : this.treeConfig.linkConfig.linkOpacity)
        .attr('stroke-width', isMinimap ? 0.5 : this.treeConfig.linkConfig.width)
        .attr("marker-end", "url(#arrow)"); // Use normal marker
    }
  }

  private highlightConnectorLinks(links: any, isHighLighted: boolean, isMinimap: boolean): void {
    //console.log("in highlightConnectorLinks - isHighLighted = " + isHighLighted + ", links = " + links);

    if (isHighLighted) {
      //console.log("in highlightConnectorLinks - isHighLighted = ", isHighLighted);

      links.attr('stroke', this.treeConfig.linkConfig.highlightedLinkColor)
        .attr('stroke-opacity', this.treeConfig.linkConfig.highlightedLinkOpacity)
        .attr('stroke-width', this.treeConfig.linkConfig.highlightedWidth)
        .attr("filter", "url(#glow)") // Apply glow effect
        .attr("marker-end", "url(#connector-arrow-highlighted)"); // Use highlighted marker
        
    } else {
      links.attr('stroke', this.treeConfig.linkConfig.linkColor)
        .attr('stroke-opacity', this.treeConfig.linkConfig.linkOpacity)
        .attr('stroke-width', this.treeConfig.linkConfig.width)
        .attr("filter", "none") // Remove glow when not highlighted
        .attr("marker-end", "url(#connector-arrow)"); // Use normal marker
    }

    links.style('stroke-width', isHighLighted ? '${this.treeConfig.linkConfig.highlightedWidth}px' : `${this.treeConfig.linkConfig.width}px`);
    
  }

  private openContextMenu(event: MouseEvent, linkData: any): void {
    event.preventDefault();
    event.stopPropagation();

    // Set selected link data
    this.selectedLinkData = linkData;

    console.log("in openContextMenu - this.selectedLinkData:", this.selectedLinkData);

    // Get the context menu element
    const menu = document.querySelector('.context-menu') as HTMLElement;
    if (menu) {
        // Position the menu near the clicked link
        const rect = (event.target as SVGElement).getBoundingClientRect();
        const centerX = rect.left + (rect.width / 2);

        menu.style.display = 'block';
        menu.style.left = `${centerX}px`;
        menu.style.top = `${event.pageY}px`;

        // Function to close the menu
        const closeMenu = () => {
            menu.style.display = 'none';
            menu.removeEventListener('mouseleave', handleMouseLeave);
        };

        // Handle mouse leaving the menu
        const handleMouseLeave = () => {
            if (!menu.contains(document.elementFromPoint(event.clientX, event.clientY))) {
                this.isLinkHoverActive = false;
                closeMenu();

                //Remove the connector link highlight when the mouse leaving the delete context menu
                //this.highlightConnectorLinks(d3.select(this.selectedLink), false, false);

            }
        };

        // Prevent menu from closing while hovering over it
        menu.addEventListener("mouseover", () => {
            this.isLinkHoverActive = true;
        });

        // Close menu when the mouse leaves
        menu.addEventListener('mouseleave', handleMouseLeave);
    }
}
       
  private openContextMenu_ORIGINAL(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const menu = document.querySelector('.context-menu') as HTMLElement;
    if (menu) {
      const rect = (event.target as SVGElement).getBoundingClientRect();
      const centerX = rect.left + (rect.width / 2);

      menu.style.display = 'block';
      menu.style.left = `${centerX}px`;
      menu.style.top = `${event.pageY}px`;

      const closeMenu = () => {
        if (this.selectedLink) {
          d3.select(this.selectedLink)
            .attr('stroke', this.treeConfig.linkConfig.linkColor)
            .attr('stroke-opacity', this.treeConfig.linkConfig.linkOpacity)
            .attr('stroke-width', this.treeConfig.linkConfig.width)
        }
        this.closeContextMenu();
        menu.removeEventListener('mouseleave', handleMouseLeave);
      };

      const handleMouseLeave = () => {
        if (!menu.contains(document.elementFromPoint(event.clientX, event.clientY))) {
          this.isLinkHoverActive = false;
          closeMenu();
        }
      };
      menu.addEventListener("mouseover", () => {
        this.isLinkHoverActive = true;
      })
      menu.addEventListener('mouseleave', handleMouseLeave);
    }
  }

  private handleLinkDragStart(event: any, node: any, side: string): void {
    console.log('node: ', node);
    event.sourceEvent.stopPropagation();
    this.isDraggingLink = true;

    const sourceNode = node;

    let x = sourceNode.y;  // D3 tree layout uses y for horizontal
    let y = sourceNode.x;  // D3 tree layout uses x for vertical

    // Adjust position based on the side
    switch (side) {
      case 'top':
        y -= this.treeConfig.nodeConfig.height / 2;
        break;
      case 'right':
        x += this.treeConfig.nodeConfig.width / 2;
        break;
      case 'bottom':
        y += this.treeConfig.nodeConfig.height / 2;
        break;
      case 'left':
        x -= this.treeConfig.nodeConfig.width / 2;
        break;
    }

    this.startConnectionPoint = {
      x: x - sourceNode.y,
      y: y - sourceNode.x,
      side: side as 'top' | 'right' | 'bottom' | 'left',
      node: sourceNode
    };

    // Create temporary link
    this.temporaryLink = this.mainG.append('path')
      .attr('class', 'temporary-link')
      //.attr('stroke', '#3d69ef')
      .attr('stroke', 'red')
      .attr('stroke-width', this.treeConfig.linkConfig.width)
      .attr('stroke-dasharray', '4,4')
      .attr('fill', 'none');
  }

  private handleLinkDrag(event: any): void {
    if (!this.isDraggingLink || !this.startConnectionPoint || !this.temporaryLink) return;

    // Get mouse position in SVG coordinates
    const mousePoint = d3.pointer(event, this.mainG.node());

    // Calculate the path
    const path = this.calculateLinkPath(
      this.startConnectionPoint,
      { x: mousePoint[0], y: mousePoint[1] }
    );
    
    this.temporaryLink.attr('d', path);

    // Highlight valid connection points
    this.highlightValidConnectionPoints(mousePoint);
  }

  private handleLinkDragEnd(event: any): void {
    if (!this.isDraggingLink || !this.startConnectionPoint) return;

    const endPoint = d3.pointer(event, this.mainG.node());
    console.log('endPoint: ', endPoint);
    const targetConnectionPoint = this.findNearestConnectionPoint(endPoint);
    console.log('targetConnectionPoint: ', targetConnectionPoint);

    if (targetConnectionPoint && this.isValidConnection(this.startConnectionPoint, targetConnectionPoint)) {
      this.createLink(this.startConnectionPoint, targetConnectionPoint);
    }

    // Cleanup
    if (this.temporaryLink) {
      this.temporaryLink.remove();
      this.temporaryLink = null;
    }

    this.isDraggingLink = false;
    this.startConnectionPoint = null;

    this.mainG.selectAll('.connection-point')
      //.attr('fill', '#3d69ef')
      .attr('fill', 'red')
      .attr('r', this.treeConfig.nodeConfig.connectorDotSize);
  }

  private openNodeContextMenu(event: MouseEvent, d: any): void {

    this.isNodeHoverActive = true; // ✅ Track that the menu is open

    // return
    event.preventDefault();
    event.stopPropagation();

    const menu = document.querySelector('.node-context-menu') as HTMLElement;
    if (!menu) return;

    const showMenu = (x: number, y: number) => {
      menu.style.display = 'block';
      menu.style.left = `${x}px`;
      menu.style.top = `${y + 20}px`;
    };

    showMenu(event.pageX, event.pageY); // Position at mouse pointer

    const closeMenu = () => {
      this.closeNodeContextMenu();
      menu.removeEventListener('mouseleave', handleMouseLeave);
    };

    const handleMouseLeave = () => {
      if (!menu.contains(document.elementFromPoint(event.clientX, event.clientY))) {
        this.isNodeHoverActive = false;
        closeMenu();
      }
    };
    menu.addEventListener("mouseover", () => {
      this.isNodeHoverActive = true;
    })
    menu.addEventListener('mouseleave', handleMouseLeave);
  }

  private handleDragMove(event: any, draggedNode: any) {
    this.closeContextMenu();
    this.handleDragStart(event, draggedNode);
    if (!event?.sourceEvent || !this.mainG) return;

    try {
      const coordinates = d3.pointer(event, this.svg.node());
      const dragNode = this.mainG.select('.drag-node');

      if (!dragNode.empty()) {
        // Transform coordinates to account for zoom and pan
        const x = (coordinates[0] - this.currentTransform.x) / this.currentTransform.k;
        const y = (coordinates[1] - this.currentTransform.y) / this.currentTransform.k;
        dragNode.attr('transform', `translate(${x},${y})`);
      }

      // Get SVG container bounds
      const svgBounds = this.svgContainer?.nativeElement.getBoundingClientRect();
      if (!svgBounds) return;

      // Get mouse coordinates relative to SVG container
      const mouseX = event.sourceEvent.x - svgBounds.left;
      const mouseY = event.sourceEvent.y - svgBounds.top;

      // Create a new transform object with bounds checking
      const containerRect = this.chartContent?.nativeElement.getBoundingClientRect();
      if (!containerRect) return;

      const newTransform: any = d3.zoomIdentity
        .translate(this.currentTransform.x, this.currentTransform.y)
        .scale(this.currentTransform.k);

      let shouldUpdateTransform = false;

      // Add scroll margin checks with bounds
      if (mouseX < this.treeConfig.dragScrollMargin) {
        newTransform.x = Math.min(0, newTransform.x + this.treeConfig.dragScrollSpeed);
        shouldUpdateTransform = true;
      } else if (mouseX > svgBounds.width - this.treeConfig.dragScrollMargin) {
        const maxX = Math.max(0, this.treeWidth * newTransform.k - containerRect.width);
        newTransform.x = Math.max(-maxX, newTransform.x - this.treeConfig.dragScrollSpeed);
        shouldUpdateTransform = true;
      }

      if (mouseY < this.treeConfig.dragScrollMargin) {
        newTransform.y = Math.min(0, newTransform.y + this.treeConfig.dragScrollSpeed);
        shouldUpdateTransform = true;
      } else if (mouseY > svgBounds.height - this.treeConfig.dragScrollMargin) {
        const maxY = Math.max(0, this.treeHeight * newTransform.k - containerRect.height);
        newTransform.y = Math.max(-maxY, newTransform.y - this.treeConfig.dragScrollSpeed);
        shouldUpdateTransform = true;
      }

      if (shouldUpdateTransform) {
        this.currentTransform = newTransform;
        this.mainG.attr('transform', newTransform.toString());
        this.updateMinimapViewport();
      }

      // Find and highlight drop target
      const dropTarget = this.findDropTarget(coordinates[0], coordinates[1], draggedNode);
      this.highlightDropTarget(dropTarget, draggedNode);

    } catch (error) {
      console.error('Error in handleDragMove:', error);
      this.resetDragState();
    }
  }


  private handleDragEnd(event: any, draggedNode: any) {
    if (!event) return;

    // Clear any existing drag operations
    this.mainG.selectAll('.drag-node').remove();

    try {
      const coordinates = d3.pointer(event, this.svg.node());
      const dropTarget = this.findDropTarget(coordinates[0], coordinates[1], draggedNode);

      console.log("in handleDragEnd - draggedNode: ", draggedNode);
      console.log("in handleDragEnd - dropTarget: ", dropTarget);

      if (dropTarget && this.isValidDropTarget(dropTarget, draggedNode)) {

        //Save the dragged node
        this.saveDragDropNode(draggedNode, dropTarget);

        // Update tree structure
        this.updateTreeDataStructure(draggedNode, dropTarget);
        this.root = d3.hierarchy(this.treeData.data as TreeNode);
        this.updateChart(dropTarget);
      }

      // Reset all node colors
      this.mainG.selectAll('.node-rect')
      .attr('stroke', '#ccc')
      .attr('stroke-width', this.treeConfig.nodeConfig.borderStrokeWidth)
      .attr('fill', 'white');


    } catch (error) {
      console.error('Error in handleDragEnd:', error);
      this.resetDragState();
    }
  }

  saveDragDropNode(draggedNode: any, dropTarget: any) {
    console.log("in saveDragDropNode - draggedNode: ", draggedNode);
    console.log("in saveDragDropNode - dropTarget: ", dropTarget);

    let payload = {
      videoId: draggedNode.data._id,
      parentId: draggedNode.data.parentId,
      newParentId: dropTarget.data._id,
    }
    console.log("in saveDragDropNode - payload: ", payload);

    this.spinner = true;
    this.urlService
      .moveVideoNode(payload)
      .subscribe(() => {
        this.spinner = false;
      })
      .add(() => {
        this.createInteractiveVideoObj.spinner = false;
      });
  }


  private updateTooltipPosition(event: MouseEvent): void {
    this.tooltip
      .style('left', (event.pageX) + 'px')
      .style('top', (event.pageY + 40) + 'px'); // Position above cursor
      // .style('top', (event.pageY - 5) + 'px'); // Position above cursor
  }

  private hideTooltip(): void {
    this.tooltip.style('visibility', 'hidden');
  }


  private closeNodeContextMenu(): void {
    this.isNodeHoverActive = false;

    d3.selectAll('.node-focused')
    .classed('node-focused', false) // ✅ Remove focus class from all elements
    .attr("stroke", (d: any) =>
        d.data?.isEndingNode || d.data?.isConnectorNode ? "#DC3545" : "#ccc"
    )
    .attr('stroke-width', this.treeConfig.nodeConfig.borderStrokeWidth)
    .attr('fill', 'none'); // ✅ Reset background color

    const menu = document.querySelector('.node-context-menu') as HTMLElement;
    if (menu) {
      menu.style.display = 'none';
    }
  }

  private calculateVisibleProportion(): { width: number; height: number } {
    const containerRect = this.chartContent.nativeElement.getBoundingClientRect();
    return {
      width: Math.min(1, containerRect.width / (this.treeWidth * this.currentTransform.k)),
      height: Math.min(1, containerRect.height / (this.treeHeight * this.currentTransform.k))
    };
  }

  private calculateLinkPath(start: ConnectionPoint, end: ConnectionPoint | { x: number; y: number }, linkType?: string): string {
    // Calculate absolute positions for source with safe defaults
    const sourceX = ((start.node as any)?.y ?? 0) + start.x;
    const sourceY = ((start.node as any)?.x ?? 0) + start.y;

    // Calculate absolute positions for target
    let targetX: number;
    let targetY: number;
    let targetSide: 'top' | 'right' | 'bottom' | 'left' | null = null;

    if ('node' in end) {
      // If end is a ConnectionPoint with a valid node (final connection)
      targetX = ((end.node as any)?.y ?? 0) + end.x;
      targetY = ((end.node as any)?.x ?? 0) + end.y;
      targetSide = end.side;
    } else {
      // If end is a mouse position
      targetX = end.x;
      targetY = end.y;
    }

    let CURVE_OFFSET = this.treeConfig.nodeConfig.width / 2; //curveIntensity

    if (linkType === 'connector') {
      CURVE_OFFSET = this.treeConfig.nodeConfig.width;
    }

    let controlPoint1X = sourceX;
    let controlPoint1Y = sourceY;
    let controlPoint2X = targetX;
    let controlPoint2Y = targetY;

    // Adjust control points based on the source side
    switch (start.side) {
      case 'top':
        controlPoint1Y = sourceY - CURVE_OFFSET;
        break;
      case 'right':
        controlPoint1X = sourceX + CURVE_OFFSET;
        break;
      case 'bottom':
        controlPoint1Y = sourceY + CURVE_OFFSET;
        break;
      case 'left':
        controlPoint1X = sourceX - CURVE_OFFSET;
        break;
    }

    // Adjust control points based on the target side if it's a final connection
    if (targetSide) {
      switch (targetSide) {
        case 'top':
          controlPoint2Y = targetY - CURVE_OFFSET;
          targetY = targetY - this.treeConfig.linkArrowSize;
          break;
        case 'right':
          controlPoint2X = targetX + CURVE_OFFSET;
          targetX = targetX + this.treeConfig.linkArrowSize;
          break;
        case 'bottom':
          controlPoint2Y = targetY + CURVE_OFFSET;
          targetY = targetY + this.treeConfig.linkArrowSize;
          break;
        case 'left':
          controlPoint2X = targetX - CURVE_OFFSET;
          targetX = targetX - this.treeConfig.linkArrowSize;
          break;
      }
    } else {
      // For mouse dragging, make the curve follow the mouse more naturally
      controlPoint2X = targetX;
      controlPoint2Y = targetY;
    }

    // Create a curved path using cubic Bezier curve
    return `M ${sourceX},${sourceY} ` +
      `C ${controlPoint1X},${controlPoint1Y} ` +
      `${controlPoint2X},${controlPoint2Y} ` +
      `${targetX},${targetY}`;
  }

  private highlightValidConnectionPoints(currentPoint: [number, number]): void {
    this.mainG.selectAll('.connection-point')
      .each((d: any, i: any, nodes: any) => {
        const connectionPoint = nodes[i];
        const node = d;
        const side = connectionPoint.getAttribute('data-side') as 'top' | 'right' | 'bottom' | 'left';

        let x = node.y;
        let y = node.x;

        // Adjust position based on the side
        switch (side) {
          case 'top':
            y -= this.treeConfig.nodeConfig.height / 2;
            break;
          case 'right':
            x += this.treeConfig.nodeConfig.width / 2;
            break;
          case 'bottom':
            y += this.treeConfig.nodeConfig.height / 2;
            break;
          case 'left':
            x -= this.treeConfig.nodeConfig.width / 2;
            break;
        }

        const distance = Math.sqrt(
          Math.pow(currentPoint[0] - x, 2) +
          Math.pow(currentPoint[1] - y, 2)
        );
        const isValid = distance < (this.treeConfig.nodeConfig.width / 2) &&
          this.isValidConnection(
            this.startConnectionPoint!,
            {
              x: x,
              y: y,
              side: side,
              node: node
            }
          );

        d3.select(connectionPoint)
          //.attr('fill', isValid ? '#81C784' : '#3d69ef')
          .attr('fill', isValid ? '#81C784' : 'red')
          .attr('r', this.treeConfig.nodeConfig.connectorDotSize);
      });
  }

  private findNearestConnectionPoint(point: [number, number]): ConnectionPoint | null {
    const SNAP_DISTANCE = Math.max(30, (Math.min(this.treeConfig.nodeConfig.width, this.treeConfig.nodeConfig.height) / 2));

    let minDistance = Infinity;
    let nearest: ConnectionPoint | null = null;

    this.mainG.selectAll('.connection-point')
      .each((d: any, i: any, nodes: any) => {
        const connectionPoint = nodes[i];
        const node = d;
        const side = connectionPoint.getAttribute('data-side') as 'top' | 'right' | 'bottom' | 'left';

        let x = node.y;  // D3 tree layout uses y for horizontal
        let y = node.x;  // D3 tree layout uses x for vertical

        // Adjust position based on the side
        switch (side) {
          case 'top':
            y -= this.treeConfig.nodeConfig.height / 2;
            break;
          case 'right':
            x += this.treeConfig.nodeConfig.width / 2;
            break;
          case 'bottom':
            y += this.treeConfig.nodeConfig.height / 2;
            case 'left':
            break;
            x -= this.treeConfig.nodeConfig.width / 2;
            break;
        }

        const distance = Math.sqrt(
          Math.pow(point[0] - x, 2) +
          Math.pow(point[1] - y, 2)
        );

        if (distance < SNAP_DISTANCE && distance < minDistance) {
          minDistance = distance;
          nearest = {
            x: x - node.y,
            y: y - node.x,
            side: side,
            node: node
          };
        }
      });

    return nearest;
  }

  private isValidConnection(source: ConnectionPoint, target: ConnectionPoint): boolean {
    // Prevent connecting to the same node
    if (source.node === target.node) return false;

    // Prevent connecting to already connected points
    const existingLink = this.treeData.customLinks.find(link =>
      (link.source.node === source.node && link.target.node === target.node) ||
      (link.source.node === target.node && link.target.node === source.node)
    );

    // Prevent connecting nodes that are already directly connected in the tree
    if (this.isDirectlyConnected(source.node, target.node)) {
      return false;
    }

    return !existingLink;
  }

  private handleDragStart(event: any, d: any) {
    console.log('event: ', event);
    // Disable zoom during drag
    const dragNodes = this.mainG.select('.drag-node');
    if (dragNodes) {
      this.mainG.select('.drag-node').remove();
    }
    this.svg.on('.zoom', null);

    const coordinates = d3.pointer(event, this.svg.node());
    const dragNode = this.mainG.append('g')
      .attr('class', 'drag-node')
      .attr('transform', `translate(${(coordinates[0] - this.currentTransform.x) / this.currentTransform.k},${(coordinates[1] - this.currentTransform.y) / this.currentTransform.k})`);

    dragNode
      .append('rect')
      .attr('x', -40)
      .attr('y', -20)
      .attr('width', this.treeConfig.nodeConfig.width)
      .attr('height', this.treeConfig.nodeConfig.height)
      .attr('rx', this.treeConfig.nodeConfig.borderRadius)
      .attr('ry', this.treeConfig.nodeConfig.borderRadius)
      .attr('stroke', '#ccc')
      .attr('stroke-width', this.treeConfig.nodeConfig.borderStrokeWidth)
      .attr('fill', '#ff7f0e')
      .attr('opacity', 0.8);

    dragNode.append('text')
      .attr('dy', '3.32em')
      .attr('dx', '2.32em')
      .attr('text-anchor', 'middle')
      .attr('fill', '#333')
      .text((e: any) => {
        const name = d.data.name;
        return name.length >= 12 ? name.substring(0, 8) + '...' : name;
      })
      .style('font-size', `${this.treeConfig.nodeConfig.labelSize}px`)
      .style('font-family', 'Roboto');
  }

  private findDropTarget(x: number, y: number, draggedNode: any): any {
    let closestNode = null;
    let minDistance = Infinity;
    const MAX_DISTANCE = Math.sqrt(this.treeConfig.dropTargetWidth * this.treeConfig.dropTargetWidth + this.treeConfig.dropTargetHeight * this.treeConfig.dropTargetHeight); // Maximum drop distance

    // Get the current transform
    const transform = this.currentTransform;

    // Inverse transform the drop coordinates to get them in the same space as the tree layout
    const [transformedX, transformedY] = transform.invert([x, y]);

    this.root.descendants().forEach((node: any) => {
      // Skip if it's the dragged node itself or its descendants
      if (node === draggedNode || this.isDescendant(draggedNode, node)) {
        return;
      }

      // Calculate the bounds of the node's drop zone
      const nodeLeft = node.y - this.treeConfig.dropTargetWidth / 2;
      const nodeRight = node.y + this.treeConfig.dropTargetWidth / 2;
      const nodeTop = node.x - this.treeConfig.dropTargetHeight / 2;
      const nodeBottom = node.x + this.treeConfig.dropTargetHeight / 2;

      // Calculate distance to the nearest point on the node's rectangle
      const dx = Math.max(nodeLeft - transformedX, 0, transformedX - nodeRight);
      const dy = Math.max(nodeTop - transformedY, 0, transformedY - nodeBottom);
      const distance = Math.sqrt(dx * dx + dy * dy);

      // Update closest node if this one is closer and within maximum distance
      if (distance < minDistance && distance < (MAX_DISTANCE / 2)) {
        minDistance = distance;
        closestNode = node;
      }
    });

    return closestNode;
  }

  private resetDragState(): void {
    if (!this.mainG) return;

    // Remove drag shadows
    this.mainG.selectAll('.drag-node').remove();

    // Reset node styles
    this.mainG.selectAll('.node-rect')
      .attr('stroke', '#ccc')
      .attr('stroke-width', this.treeConfig.nodeConfig.borderStrokeWidth)
      .attr('fill', 'white');

    // Re-enable zoom
    if (this.svg && this.zoom) {
      this.svg.call(this.zoom);
    }
  }

  private highlightDropTarget(dropTarget: any, draggedNode: any): void {
    if (!this.mainG) return;

    // Reset all node styles
    this.mainG.selectAll('.node-rect')
      .attr('stroke', '#ccc')
       //.attr('stroke-width', 1)
      .attr('stroke-width', this.treeConfig.nodeConfig.borderStrokeWidth)
      .attr('fill', 'white');

    // Highlight valid drop target
    if (dropTarget && this.isValidDropTarget(dropTarget, draggedNode)) {
      const node = this.mainG.selectAll('g')
        .filter((d: any) => d === dropTarget)
        .select('.node-rect');
      if (!node.empty()) {
        node.attr('stroke', '#3d69ef')
          //.attr('stroke-width', 2)
          .attr('stroke-width', this.treeConfig.nodeConfig.borderStrokeWidthFocusOn)
          .attr('fill', '#E8F5E9');
      }
    }
  }

  private handleZoom(event: d3.D3ZoomEvent<SVGSVGElement, unknown>): void {
    try {
      if (!this.mainG || !this.viewportRect) return;

      const transform = event.transform;
      if (transform.k < this.treeConfig.minScale || transform.k > this.treeConfig.maxScale) return;

      this.currentTransform = transform;
      this.mainG.attr('transform', transform.toString());
      this.updateMinimapViewport();
      this.updateZoomPercentage(transform.k);
    } catch (error) {
      console.error('Zoom operation failed:', error);
    }
  }

  private isValidDropTarget(target: any, draggedNode: any): boolean {
    // Prevent dropping on self or children
    return target !== draggedNode &&
      !this.isDescendant(draggedNode, target);
  }

  private isDescendant(parent: any, node: any): boolean {
    if (!parent.children) return false;
    return parent.children.some((child: any) =>
      child === node || this.isDescendant(child, node)
    );
  }

  private updateChart(node?: any): void {
    console.log("in updateChart")
    this.calculateTreeDimensions();
    this.initializeMinimap();
    this.renderTree(true);
    this.generateCustomLinks();
    this.initializeZoom();
    // Focus on drop target with delay to ensure rendering is complete
    if (node) {
      setTimeout(() => {
        //this.focusOnNode(node);
        this.highlightParentNode(node);
      }, 100);
    }
  }

  private updateTreeDataStructure(draggedNode: any, dropTarget: any) {
    // First remove the node from its current parent
    if (draggedNode.parent) {
      const parentData = draggedNode.parent.data;
      if (parentData.children) {
        const index = parentData.children.findIndex((child: any) => child._id === draggedNode.data._id);
        if (index !== -1) {
          parentData.children.splice(index, 1);
          if (parentData.children.length === 0) {
            delete parentData.children;
          }
        }
      }
    }

    // Add to new parent
    if (!dropTarget.data.children) {
      dropTarget.data.children = []; // Initialize if empty
    }

    dropTarget.data.children.push(draggedNode.data); // Assign the dragged node to new parent
    draggedNode.parent = dropTarget; // Update its parent reference
    }

  private calculateOptimalScale(): { scale: number, width: number, height: number } {
    const containerRect = this.chartContent?.nativeElement?.getBoundingClientRect();
    const containerAspectRatio = containerRect.width / containerRect.height;
    const treeAspectRatio = this.treeWidth / this.treeHeight;

    let baseWidth: number;
    let baseHeight: number;

    if (containerAspectRatio > treeAspectRatio) {
      // Height is the limiting factor
      baseHeight = containerRect.height * 0.2;
      baseWidth = baseHeight * treeAspectRatio;
    } else {
      // Width is the limiting factor
      baseWidth = containerRect.width * 0.2;
      baseHeight = baseWidth / treeAspectRatio;
    }

    // Calculate scale that will fit these dimensions
    const scaleX = baseWidth / this.treeWidth;
    const scaleY = baseHeight / this.treeHeight;
    const scale = Math.min(scaleX, scaleY);

    return {
      scale: scale,
      width: this.treeWidth * scale,
      height: this.treeHeight * scale
    };
  }

  private isDirectlyConnected(node1: any, node2: any): boolean {
    // Check if node1 is parent of node2
    if (node1.children && node1.children.includes(node2)) {
      return true;
    }
    // Check if node2 is parent of node1
    if (node2.children && node2.children.includes(node1)) {
      return true;
    }
    return false;
  }

  onNodeContextMenuAction(action: string): void {
    console.log("in onNodeContextMenuAction - action", action)
    if (action == 'connect-node') {
      this.handleNodeClick();
    }else if(action === 'add-node'){
      this.openVideoListModal(this.currentHoveredNode.data?.children?.length || 0);  
    }else if(action === 'delete-node'){
      $("#deleteCurrentNodeModal").modal("show");
    } else if (action === 'update-video') {
      this.clickedItem(this.currentHoveredNode.data)
    }
  
    this.closeNodeContextMenu();
  }

  private handleNodeClick(): void {
    if (!this.activeNode) return;
    // Toggle connection points of clicked node
    const nodeGroup = this.mainG.select(`g[data-id="${this.activeNode.data._id}"]`);
    if (!nodeGroup.empty()) {
      const connectionPoints = nodeGroup.select('.connection-points');
      const isVisible = connectionPoints.style('display') !== 'none';
      connectionPoints.style('display', isVisible ? 'none' : 'block');


      // // Use setTimeout to prevent instant hiding
        setTimeout(() => {
          document.addEventListener('click', (event) => {
              const clickedElement = event.target as Element;

              // Hide connection points only if clicked outside the node and connection points
              if (!clickedElement.closest('.connection-points') && 
                  !clickedElement.closest(`g[data-id="${this.activeNode.data._id}"]`)) {
                  connectionPoints.style('display', 'none');
              }
          }, { once: true }); // Remove event listener after one execution
      }, 0); // Short delay to allow event propagation
    }

    
  }


  private handleResize(): void {
    //reset the iconCount
    this.iconCountMap = new Map();

    if (this.resizeTimer) {
      clearTimeout(this.resizeTimer);
    }

    this.resizeTimer = setTimeout(() => {
      if (!this.chartContent || !this.isInitialized) return;

      this.setHeightWidth();
      this.calculateTreeDimensions();

      this.svg
        .attr('width', this.width)
        .attr('height', this.height)

      this.zoom.translateExtent([
        [0, 0],
        [this.treeWidth, this.treeHeight]
      ]);

      this.minimapWidth = this.treeWidth * this.treeConfig.miniMapScale;
      this.minimapHeight = this.treeHeight * this.treeConfig.miniMapScale;

      this.initializeMinimap();
      this.renderTree(true);
      this.updateMinimapViewport();
      this.generateCustomLinks();

    }, 50);
  }

  private setHeightWidth(): void {
    const containerRect = this.chartContent.nativeElement.getBoundingClientRect();
    this.width = containerRect.width;
    this.height = containerRect.height;
  }

  private onViewportClick(event: MouseEvent): void {
    console.log("onViewportClick called"); // Debugging log
    event.stopPropagation(); // Prevent zoom from being called
  
    // ✅ Ensure clicking does nothing if fully zoomed out
    const visibleProportion = this.calculateVisibleProportion();
    if (visibleProportion.width >= 1 && visibleProportion.height >= 1) {
        console.log("Fully zoomed out - Clicking does nothing.");
        return;
    }
  
    // ✅ Get click position inside the minimap viewport
    const [clickX, clickY] = d3.pointer(event, this.viewportRect.node());
  
    // ✅ Convert minimap coordinates to main canvas coordinates
    const targetX = (clickX / this.minimapScale) * this.currentTransform.k;
    const targetY = (clickY / this.minimapScale) * this.currentTransform.k;
  
    console.log(`Viewport clicked at: (${clickX}, ${clickY}), moving to (${targetX}, ${targetY})`);
  
    // ✅ Move the zoomed view smoothly to the clicked position
    this.svg.call(this.zoom.translateTo, targetX, targetY);
  }


  private initializeMinimap(): void {
    console.log("in initializeMinimap");

    if (!this.minimapContainer) return;

    d3.select(this.minimapContainer.nativeElement).selectAll('*').remove();

    // Calculate minimap dimensions based on main tree proportions
    const minimapAspectRatio = this.treeWidth / this.treeHeight;
    let baseMinimapHeight = 150;
    let maxMinimapWidth = 250;

    //Tall Tree then double the minimap
    if (minimapAspectRatio > 0.7 && minimapAspectRatio < 1) {
      baseMinimapHeight = 300; // Increase from 150 to 200
      maxMinimapWidth = 500;   // Increase max width for better visibility
    }

    this.minimapHeight = baseMinimapHeight;
    this.minimapWidth = this.minimapHeight * minimapAspectRatio;

    this.minimapWidth = Math.min(this.minimapWidth, maxMinimapWidth);
    this.minimapHeight = this.minimapWidth / minimapAspectRatio;

    // Calculate proper minimap scale
    this.minimapScale = Math.min(
      this.minimapWidth / this.treeWidth,
      this.minimapHeight / this.treeHeight
    );

    this.minimap = d3.select(this.minimapContainer.nativeElement)
      .append('svg')
      .attr('width', this.minimapWidth)
      .attr('height', this.minimapHeight);

    // Add background
    this.minimap.append('rect')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('fill', '#fff');

    this.minimapG = this.minimap.append('g')
      .attr('transform', `scale(${this.minimapScale})`);

    // ✅ Ensure correct initial state
    this.hasInteractedWithMinimap = false;
    this.currentTransform = d3.zoomTransform(this.svg.node()); // ✅ Save correct zoom state

    // Add viewport rectangle
    this.viewportRect = this.minimap.append('rect')
      .attr('class', 'viewport')
      .attr('stroke', '#2563eb')
      .attr('stroke-width', '1.5px')
      .attr('fill', 'rgba(37, 99, 235, 0.1)')
      .attr('cursor', 'move')
      .call(d3.drag<SVGRectElement, unknown>()
        .on('start', this.onMinimapDragStart.bind(this))
        .on('drag', this.onMinimapDrag.bind(this))
        .on('end', this.onMinimapDragEnd.bind(this)));

  // ✅ Safely attach event listener AFTER ensuring viewportRect exists
  setTimeout(() => {
    const viewportSelection = d3.select<SVGRectElement, unknown>(".viewport");

    if (!viewportSelection.empty()) {
        viewportSelection.on("mousedown", (event) => {
            event.stopPropagation();
            console.log("Viewport clicked, but zoom is NOT triggered");
            this.onViewportClick(event);
        });

        //console.log("in inializeMinimap Viewport event listener attached successfully!");
    } else {
        console.warn("⚠️ ViewportRect not found when trying to add event listener.");
    }
  }, 0); // Delay to ensure D3 renders before attaching events

  //console.log("ViewportRect selection:", this.viewportRect);
  //console.log("ViewportRect node:", this.viewportRect.node());

  // ✅ Fix initial position of viewport
  this.correctMinimapViewport(this.currentTransform);
}

private onMinimapDragStart(): void {
    this.minimap.style('cursor', 'grabbing');

    // ✅ Ensure no first-click jump
    if (!this.hasInteractedWithMinimap) {
        this.hasInteractedWithMinimap = true;
        this.currentTransform = d3.zoomTransform(this.svg.node()); // Lock initial state
    }
}

private onMinimapDrag(event: d3.D3DragEvent<SVGRectElement, unknown, unknown>): void {
    const containerRect = this.chartContent.nativeElement.getBoundingClientRect();
    const dx = event.dx / this.minimapScale * this.currentTransform.k;
    const dy = event.dy / this.minimapScale * this.currentTransform.k;

    // Calculate new position with bounds checking
    let newX = this.currentTransform.x - dx;
    let newY = this.currentTransform.y - dy;

    // Calculate bounds
    const maxX = Math.max(0, this.treeWidth * this.currentTransform.k - containerRect.width);
    const maxY = Math.max(0, this.treeHeight * this.currentTransform.k - containerRect.height);

    // Apply bounds
    newX = Math.max(-maxX, Math.min(0, newX));
    newY = Math.max(-maxY, Math.min(0, newY));

    // ✅ Update transform without resizing
    this.currentTransform = d3.zoomIdentity.translate(newX, newY).scale(this.currentTransform.k);
    this.svg.call(this.zoom.transform, this.currentTransform);
}

private onMinimapDragEnd(): void {
    this.minimap.style('cursor', 'auto');
}

private correctMinimapViewport(transform: d3.ZoomTransform): void {
  if (!this.minimap || !this.viewportRect || !this.root || !this.svg || !this.zoom) return;

  const minimapScale = this.minimapScale;
  let visibleWidth = (this.width / transform.k) * minimapScale;
  let visibleHeight = (this.height / transform.k) * minimapScale;

  let visibleX = (-transform.x / transform.k) * minimapScale;
  let visibleY = (-transform.y / transform.k) * minimapScale;

  // ✅ Ensure values are within valid bounds
  visibleX = Math.max(Math.round(visibleX), 0);
  visibleY = Math.max(Math.round(visibleY), 0);
  visibleWidth = Math.max(Math.round(visibleWidth), 3);
  visibleHeight = Math.max(Math.round(visibleHeight), 3);

  if (visibleX + visibleWidth > this.minimapWidth) {
    visibleWidth = this.minimapWidth - visibleX;
  }
  if (visibleY + visibleHeight > this.minimapHeight) {
    visibleHeight = this.minimapHeight - visibleY;
  }

  // 🔥 Ensure the **root node is inside the viewport**
  const rootMinimapX = this.root.y * minimapScale;
  const rootMinimapY = this.root.x * minimapScale;

  const paddingX = visibleWidth * 0.2;
  const paddingY = visibleHeight * 0.2;

  let adjusted = false;

  if (rootMinimapX < visibleX + paddingX || rootMinimapX > visibleX + visibleWidth - paddingX) {
    visibleX = Math.max(rootMinimapX - visibleWidth / 3, 0); // 🔥 Keeps root on left, not centered
    adjusted = true;
  }
  if (rootMinimapY < visibleY + paddingY || rootMinimapY > visibleY + visibleHeight - paddingY) {
    visibleY = Math.max(rootMinimapY - visibleHeight / 3, 0); // 🔥 Adjusts only if necessary
    adjusted = true;
  }

  // ✅ Update viewport only if needed
  if (adjusted) {
    this.viewportRect
      .transition().duration(300)
      .attr('x', visibleX)
      .attr('y', visibleY)
      .attr('width', visibleWidth)
      .attr('height', visibleHeight)
      .attr('stroke', '#2563eb')
      .attr('stroke-width', 1.2)
      .attr('vector-effect', 'non-scaling-stroke')
      .attr('fill', 'rgba(37, 99, 235, 0.1)')
      .attr('rx', 1)
      .attr('ry', 1);
  }

  // ✅ Adjust the main view to **only bring the root into view, NOT center it**
  this.ensureRootNodeIsVisible(transform);
}

/**
 * ✅ Ensures the root node is visible, but does NOT center it.
 */
private ensureRootNodeIsVisible(transform: d3.ZoomTransform): void {
  if (!this.root || !this.svg || !this.zoom) return;

  const svgWidth = parseFloat(this.svg.attr("width"));
  const svgHeight = parseFloat(this.svg.attr("height"));

  // ✅ Check if root is outside of the current visible area
  const rootCanvasX = this.root.y * transform.k + transform.x;
  const rootCanvasY = this.root.x * transform.k + transform.y;

  const rootPaddingX = svgWidth * 0.1; // 🔥 Keeps root in the left portion, not centered
  const rootPaddingY = svgHeight * 0.2;

  let newX = transform.x;
  let newY = transform.y;
  let adjusted = false;

  // ✅ Move left just enough to show the root
  if (rootCanvasX < rootPaddingX || rootCanvasX > svgWidth * 0.5) {
    newX = svgWidth * 0.1 - this.root.y * transform.k; // 🔥 Keeps root near the left
    adjusted = true;
  }

  // ✅ Move vertically only if needed
  if (rootCanvasY < rootPaddingY || rootCanvasY > svgHeight - rootPaddingY) {
    newY = svgHeight * 0.3 - this.root.x * transform.k;
    adjusted = true;
  }

  // ✅ Apply transform only if adjustments were made
  if (adjusted) {
    const newTransform = d3.zoomIdentity.translate(newX, newY).scale(transform.k);
    this.svg.transition().duration(300).call(this.zoom.transform, newTransform);
  }
}

private initializeZoom(): void {
  this.zoom = d3.zoom<SVGSVGElement, unknown>()
    .scaleExtent([this.treeConfig.minScale, this.treeConfig.maxScale])
    .translateExtent([
      [0, 0],
      [this.treeWidth, this.treeHeight]
    ])
    .on('zoom', (event: d3.D3ZoomEvent<SVGSVGElement, unknown>) => {
      if (this.isInitialized) {
        this.handleZoom(event);
      }
    });

  this.svg.call(this.zoom);
}

private initializeChart(): void {
  if (!this.chartContent) return;

  this.setHeightWidth();
  const containerRect = this.chartContent.nativeElement.getBoundingClientRect();

  this.svg = d3.select(this.chartContent.nativeElement)
    .append('svg')
    .attr('width', this.width)
    .attr('height', this.height)
    .style('font', '10px Roboto');

  const createMarker = (id: string, color: string) => {
    this.svg.select("defs")
      .append("marker")
      .attr("id", id)
      .attr("viewBox", "0 0 10 10")
      .attr("refX", 5)
      .attr("refY", 5)
      .attr("markerWidth", this.treeConfig.linkArrowSize)
      .attr("markerHeight", this.treeConfig.linkArrowSize)
      .attr("orient", "auto")
      .append("path")
      .attr("d", "M 0 0 L 10 5 L 0 10 Z")
      .attr("fill", color); // Dynamic color
  };
  
  // ✅ Define Normal & Highlighted Arrows
  this.svg.append("defs");
  createMarker("arrow", "#555"); // Normal arrow
  createMarker("arrow-highlighted", "red"); // 🔥 Highlighted (Red) arrow

  this.mainG = this.svg.append('g');

  // ✅ Calculate initial scale and center position
  const initialScale = this.treeConfig.useInitialZoom
      ? (this.treeConfig.initialZoom / 100)
      : Math.min(containerRect.width / this.treeWidth, containerRect.height / this.treeHeight);

  const centerX = (containerRect.width - (this.treeWidth * initialScale)) / 2;
  const centerY = (containerRect.height - (this.treeHeight * initialScale)) / 2;

  const initialTransform = d3.zoomIdentity.translate(centerX, centerY).scale(initialScale);
  this.mainG.attr("transform", initialTransform.toString()); // ✅ Set transform before zoom

  this.zoom = d3.zoom<SVGSVGElement, unknown>()
    .scaleExtent([this.treeConfig.minScale, this.treeConfig.maxScale])
    .translateExtent([[0, 0], [this.treeWidth, this.treeHeight]])
    .on('zoom', (event) => {
        if (this.isInitialized) {
            this.handleZoom(event);
            this.updateMinimapViewport(); // ✅ Ensure viewport updates on zoom
        }
    });

  this.svg.call(this.zoom);
  this.svg.call(this.zoom.transform, initialTransform); // ✅ Apply zoom immediately

  // 🔥 **Fix: Properly Update Minimap After Initial Transform**
  setTimeout(() => {
      this.correctMinimapViewport(initialTransform); // ✅ Fix incorrect viewport size
  }, 100);

  this.updateZoomPercentage(initialTransform.k);
  this.isInitialized = true;
}


  getIconPosition(d) {
    const nodeId = d.data._id;
    let iconCount = this.iconCountMap.get(nodeId);

    if (!iconCount)
      iconCount = 0;
  
    // console.log("getIconPosition - d.data._id = " + d.data._id + ", d.data.name = " + d.data.name + ", iconCount = " + iconCount + ", this.iconCountMap = " + [...this.iconCountMap]);
  
    let position;
  
    switch (iconCount) {
      case 3:
        position = "matrix(1,0,0,1,-130,-130)"; // All three icons present
        break;
      case 2:
        position = "matrix(1,0,0,1,-60,-130)"; // Two icons present
        break;
      case 1:
        position = "matrix(1,0,0,1,10,-130)"; // One icon present
        break;
      default:
        position = "matrix(1,0,0,1,80,-130)"; // No icon present
        break;
    }
  
    this.iconCountMap.set(nodeId, iconCount + 1); // Increment iconCount for the node
  
    // console.log("getIconPosition - returning position = " + position + " for " + d.data.name);

    return position;
  }

  updateAIGeneratedScriptOfNode(currentNode) {
    this.selectedNodesScript = currentNode?.aiGeneratedScript;
    const dialogRef = this.dialog.open(this.updateAIGeneratedScriptDialog, {
      width: "100%",
      maxWidth: "700px",
      panelClass: "position-choices",
      height: "auto",
    });
    
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let payload = {
          videoId: currentNode._id,
          type: currentNode.type,
          updatedScript: this.selectedNodesScript,
        };
        
        this.spinner = true;
        this.urlService.updateAiGeneratedScript(payload).subscribe(
          (res) => {
            this.callChild("");
          },
          (err) => {
            this.toastr.error("Error!", err);
          }
        );
      }
    });
  }


  userMeData: any;
  lifeCycleStages: any;
  hubSpotLifeCycle: any;
  isDisableInput: boolean = false;
  isMessageLoad = false;
  dragDropNode = false;
  private visRef: any;
  msgValue = "";
  isAddWatchPath = false;
  messageMap = {
    rename: "Rename Pathway",
    restart: "Update Starting & Ending Nodes",
    addBadge: "Assign Badge",
    selectBadge: "Choose Badge",
    reChooseEndNode: "Update Ending Node",
    addLearningObjectives: "Set Objectives",
    hubSpotLifeCycle: "Set HubSpot LifeCycle",
    save: "Save",
    cancel: "Cancel",
  };


  openAddWatchPath() {
    this.getUserInfoWithHubSpot();
    this.hubSpotLifeCycle = "";
    this.isDisableInput = false;
    if (this.isMessageLoad) {
      return;
    }
    this.toggleDragDropNode(false);
    this.cancelAddWatchPath();
    // $("#tree-view").addClass("add-pathways");
    this.isAddWatchPath = true;
    this.getBotAnswer("");
  }

  getUserInfoWithHubSpot() {
    this.urlService.userDetailsV2().subscribe((success) => {
      this.userMeData = success?.data?.details;
      if (this.userMeData?.hubSpot?.integrationCompletedTS) {
        this.getCrmLifeCycleStages();
      }
    });
  }


  getCrmLifeCycleStages() {
    this.urlService.getCrmLifeCycleStages().subscribe((success) => {
      this.lifeCycleStages = success?.data;
      if (!this.createInteractiveVideoObj?.mainVideo?.hubSpotLifeCycle) {
        this.createInteractiveVideoObj.mainVideo.hubSpotLifeCycle = "None";
      }
      if (!this.hubSpotLifeCycle) {
        this.hubSpotLifeCycle = "None";
      }
    });
  }

  toggleDragDropNode(value) {
    this.dragDropNode = value;
    // this.visRef.attr("class", this.dragDropNode ? "rearrange" : "");
  }

  messages: MessageType[] = [];
  currentMessageStep = 0;
  editWatchPath: any;
  startWatchNode: any;
  endWatchNode: any;
  assignBadge: any;
  allPathNodes = [];
  selectedPathNodes = [];
  selectedPathIndex = 0;
  isSelectedPathIndex = false;
  totalPaths = [];
  pathNodes = [];
  inputValues: string[] = [];
  inputLength: any[] = [""];
  watchPathName = "";
  submitPathWaysFormError = "";
  isSubmitPathWaysForm = false;

  cancelAddWatchPath() {
    this.msgValue = "";
    this.messages = [];
    this.currentMessageStep = 0;
    this.assignBadge = null;
    this.editWatchPath = null;
    this.startWatchNode = null;
    this.endWatchNode = null;
    this.allPathNodes = [];
    this.totalPaths = [];
    this.clearAllPathNodesColor(this.pathNodes);
    this.pathNodes = [];
    this.selectedPathNodes = [];
    this.inputLength = [""];
    this.inputValues = [];
    this.isSelectedPathIndex = false;
    this.selectedPathIndex = 0;
    this.isAddWatchPath = false;
    this.isSubmitPathWaysForm = false;
    this.submitPathWaysFormError = "";
    this.watchPathName = "";
    this.handleActiveWatchPath("");
    this.handleHoveredPath("");
    $("rect").removeClass("nodeSelected");
    $("#tree-view").removeClass("add-pathways");
  }

  clearAllPathNodesColor(pathNodes) {
    if (pathNodes) {
      for (const video of pathNodes) {
        $(`rect[data-id=${video._id}]`).attr("fill", "#fff");
      }
    }
  }

  handleActiveWatchPath(activePath?) {
    this.createInteractiveVideoObj.activePathNodes =
      activePath?.pathNodes || [];
    $(`rect`).removeClass("active");
    $(`#tree-view`).removeClass("pathways");
    if (
      this.createInteractiveVideoObj.activePathNodes &&
      this.createInteractiveVideoObj.activePathNodes.length
    ) {
      for (const i of this.createInteractiveVideoObj.activePathNodes) {
        // console.log(activePathElement)
        $(`rect[data-id=${i}]`).addClass("active");
      }
      $(`#tree-view`).addClass("pathways");
      return;
    }
    // if (this.editWatchPath) {
    //   this.handleActiveWatchPath(this.editWatchPath);
    // }
  }

  handleHoveredPath(hoveredPath?) {
    if (hoveredPath && hoveredPath.length) {
      this.clearAllPathNodesColor(this.pathNodes);
      for (const i of hoveredPath) {
        $(`rect[data-id=${i._id}]`).attr(
          "fill",
          hoveredPath[hoveredPath.length - 1].color
        );
      }
      return;
    } else {
      this.updateCurrentHighlight();
    }
  }

  updateCurrentHighlight() {
    //console.log("updateCurrentHighlight");

    this.submitPathWaysFormError = "";
    $("rect").removeClass("nodeSelected");
    if (this.allPathNodes.length && this.selectedPathIndex) {
      this.selectedPathNodes = this.allPathNodes[this.selectedPathIndex - 1];
      const startFind = this.selectedPathNodes.find(
        (x) => x._id === this.startWatchNode?._id
      );
      if (startFind) {
        for (const video of this.selectedPathNodes) {
          $(`rect[data-id=${video._id}]`).addClass("nodeSelected");
        }
      }
    } else if (this.pathNodes) {
      this.selectedPathNodes = this.pathNodes;
      const startFind = this.pathNodes.find(
        (x) => x._id === this.startWatchNode?._id
      );
      if (startFind) {
        for (const video of this.pathNodes) {
          if (this.totalPaths.length && this.totalPaths.length === 1) {
            $(`rect[data-id=${video._id}]`).addClass("nodeSelected");
          } else {
            $(`rect[data-id=${video._id}]`).attr("fill", video.color);
          }
        }
      }
    }
    if (this.startWatchNode) {
      $(`rect[data-id=${this.startWatchNode._id}]`).addClass("nodeSelected");
    }
    if (this.endWatchNode) {
      $(`rect[data-id=${this.endWatchNode._id}]`).addClass("nodeSelected");
    }
    // console.log(this.pathNodes)
    // console.log(this.pathNodes,'pathNodes')
    // console.log(this.selectedPathNodes,'selectedPathNodes')
  }

  addMessage = (author, content?, type?, url?, description?, data?) => {
    this.messages.push({ author, content, type, url, description, data });

    $("#messagesCard").animate(
      { scrollTop: $("#messagesCard .messages").height() },
      500
    );
  };

  ishubSpotLifeCycle: boolean = false;
  private isInitialStep = true;
  allPossiblePaths = [];


  getBotAnswer(
    userMessage: string,
    type = "text",
    url = "",
    description = "",
    data = null
  ) {
    if (userMessage && userMessage !== this.messageMap.addLearningObjectives) {
      this.addMessage("user", userMessage, type, url, description, data);
      // this.messages.push({author: 'user', content: userMessage, type});
    }
    this.isMessageLoad = true;
    setTimeout(() => {
      let botMessage = "";
      if (!this.messages.length) {
        botMessage = "Hello!";
        // this.messages.push({author: 'bot', content: botMessage, type: 'text'});
        this.addMessage("bot", botMessage, "text");
        this.getBotAnswer("");
        return;
      } else if (!this.startWatchNode) {
        this.currentMessageStep = 1;
        botMessage = "Please choose a starting node from the tree map";
      } else if (!this.endWatchNode) {
        this.currentMessageStep = 2;
        botMessage = "Please choose an ending node from the tree map";
      } else if (this.currentMessageStep === 111) {
        botMessage = "Select badge from library";
      } else if (this.currentMessageStep === 8) {
        botMessage = "You can add up to 5 objectives";
        // this.addMessage('bot', '', 'input')
      } else if (this.currentMessageStep === 9) {
        botMessage = "Please choose the HubSpot LifeCycle stages";
        this.ishubSpotLifeCycle = true;
      } else if (this.currentMessageStep === 7) {
        if (!this.watchPathName) {
          botMessage = "Please enter a name of the pathways";
        } else {
          if (this.isInitialStep) {
            this.currentMessageStep = 0;
            this.isInitialStep = false;
          } else {
            this.currentMessageStep = 6;
          }
          botMessage = "What would you like to do next?";
        }
      } else if (this.currentMessageStep === 5) {
        if (this.isInitialStep) {
          this.currentMessageStep = 0;
          this.isInitialStep = false;
        } else {
          this.currentMessageStep = 6;
        }
        botMessage = "What would you like to do next?";
      } else {
        this.currentMessageStep = 3;
        if (
          (this.allPossiblePaths.length === 0 || this.pathNodes.length === 0) &&
          type !== "updatePathways"
        ) {
          this.allPossiblePaths = [];
          this.allPossiblePaths = this.utilityService.listPaths(
            this.clonedArr[0],
            this.startWatchNode._id,
            this.endWatchNode._id
          );
          this.totalPaths = this.allPossiblePaths;
          this.pathNodes = this.allPossiblePaths.flat(Infinity);
          this.pathNodes = this.pathNodes.filter(
            (obj, index, self) =>
              index === self.findIndex((o) => o["_id"] === obj["_id"])
          );
        }
        if (type === "updatePathways") {
          if (this.editWatchPath) {
            this.pathNodes = [];
            this.editWatchPath.pathNodes.forEach((id) => {
              let node = this.createInteractiveVideoObj.finalObj.find(
                (i) => i._id === id
              );
              if (node) {
                this.pathNodes.push({
                  URL: node.URL,
                  poster: node.poster,
                  time: node.time,
                  name: node.name,
                  _id: node._id,
                  color: "#ffb46e",
                });
              }
            });
            this.allPossiblePaths = [this.pathNodes];
          }
        }
        // this.pathNodes = this.utilityService.parentNode(this.createInteractiveVideoObj.finalObjTree, this.endWatchNode._id);
        //console.log("this.pathNodes = ", this.pathNodes);

        if (this.endWatchNode.isEndingNode) {
          this.allPathNodes = this.utilityService.getParentNodesForEndNodes(
            this.createInteractiveVideoObj.finalObjTree,
            this.endWatchNode._id
          );
        }
        // select index number to highlight nodes
        if (this.editWatchPath) {
          //console.log("this.editWatchPath = ", this.editWatchPath);
          if (
            this.utilityService.areArraysEqual(
              this.editWatchPath.pathNodes,
              this.pathNodes.map((x) => x._id)
            )
          ) {
            this.selectedPathIndex = 0;
          }
          let i = 0;
          for (const allPathNode of this.allPathNodes) {
            ++i;
            if (
              this.utilityService.areArraysEqual(
                this.editWatchPath.pathNodes,
                allPathNode.map((x) => x._id)
              )
            ) {
              this.selectedPathIndex = i;
              break;
            }
          }
        }
        this.updateCurrentHighlight();
        if (!this.pathNodes) {
          botMessage = "The end node is not a child of the start node.";
        } else if (
          !this.pathNodes.find((x) => x._id === this.startWatchNode._id)
        ) {
          botMessage = "The end node is not a child of the start node.";
        } else if (this.startWatchNode._id === this.endWatchNode._id) {
          botMessage = "The end node and the start node must be unique.";
        } else if (
          this.endWatchNode.isEndingNode &&
          this.allPathNodes.length &&
          !this.isSelectedPathIndex
        ) {
          this.currentMessageStep = 4;
          botMessage = "We have detected multiple pathways";
          this.updateCurrentHighlight();
        } else if (this.allPossiblePaths.length > 1) {
          this.currentMessageStep = 10;
          botMessage =
            "We have detected multiple pathways, Please choose any one path";
        } else if (!this.watchPathName) {
          this.currentMessageStep = 5;
          botMessage = "Please enter a name of the pathways";
        } else {
          if (this.isInitialStep) {
            this.currentMessageStep = 0;
            this.isInitialStep = false;
          } else {
            this.currentMessageStep = 6;
          }
          botMessage = "What would you like to do next?";
        }
      }

      this.isMessageLoad = false;
      // console.log(this.currentMessageStep)
      // this.messages.push({author: 'bot', content: botMessage, type: 'text'});

      type =
        this.currentMessageStep === 8
          ? "input"
          : this.currentMessageStep === 9
            ? "select"
            : "text";

      this.addMessage("bot", botMessage, type);
    }, 1000);
  }

   addInput() {
        if (this.inputLength.length + 1 > 5) {
          this.toastr.info("You can add up to 5 objectives");
          return;
        }
  
        if (this.inputValues.length !== this.inputLength.length) {
          this.toastr.info("You can not add empty objectives");
          return;
        }
  
        this.inputLength.push("");
        $("#messagesCard").animate(
          { scrollTop: $("#messagesCard .messages").height() },
          500
        );
  
        // Autofocus on the newly added input field
        this.inputFields.changes.subscribe(() => {
          this.inputFields.last?.nativeElement.focus();
        });
      }
  
      removeInput(i: number) {
        this.inputLength = this.inputLength.filter((_, index) => index !== i);
        this.inputValues = this.inputValues.filter((_, index) => index !== i);
      }

  getUserInfo() {
    this.dashboardService.getUserData.subscribe((res) => {
      if (res) {
        this.userData = res;
      }
    });
  }
  updateInputValue(index: number, value: string) {
    this.inputValues[index] = value;
  }

  btnNext() {
        this.isDisableInput = true;
        this.inputValues = this.inputValues.filter(Boolean);
        this.inputLength.length = this.inputValues.length;
        this.currentMessageStep = 6;
        $("#messagesCard").animate(
          { scrollTop: $("#messagesCard .messages").height() },
          500
        );
  }
  btnCancelObjective() {
    this.currentMessageStep = 6;
    this.messages.pop();
    this.inputLength = [];
    this.inputLength.push("");
    this.inputValues = [];
  }

  changeHubSpotLifeCycleStage(value: any) {
    this.hubSpotLifeCycle = value;
    this.currentMessageStep = 6;
    this.ishubSpotLifeCycle = false;
    if (this.inputValues?.length) {
      this.isDisableInput = true;
    }
    this.messages.push({
      author: "user",
      content: this.hubSpotLifeCycle,
      type: "select",
    });
    $("#messagesCard").animate(
      { scrollTop: $("#messagesCard .messages").height() },
      500
    );
    this.cdr.detectChanges();
  }

  deSelectWatchPath(start) {
    if (start) {
      this.startWatchNode = null;
    } else {
      this.endWatchNode = null;
    }
    this.selectedPathNodes = [];
    this.pathNodes = [];
    this.selectedPathIndex = 0;
    this.isSelectedPathIndex = false;
    this.updateCurrentHighlight();
  }

  sendMessage(msg?: string) {
    if (msg) {
      this.msgValue = msg;
    }
    //console.log('this.currentMessageStep',this.currentMessageStep)

    this.msgValue = this.msgValue.trim();
    const msgValueUc = this.msgValue.toUpperCase();

    if (msgValueUc === this.messageMap.reChooseEndNode.toUpperCase()) {
      this.deSelectWatchPath(false);
      this.currentMessageStep = 2;
    } else if (msgValueUc === this.messageMap.restart.toUpperCase()) {
      this.clearAllPathNodesColor(this.pathNodes);
      this.deSelectWatchPath(true);
      this.deSelectWatchPath(false);
      this.currentMessageStep = 1;
    }
    if (
      this.currentMessageStep === 4 &&
      msgValueUc === this.messageMap.save.toUpperCase()
    ) {
      let paths = "";
      if (this.selectedPathIndex < 1) {
        let i = 0;
        for (const pathNode of this.pathNodes.reverse()) {
          paths += `${i ? ' <i class="fas fa-chevron-right"></i> ' : ""} ${pathNode.name
            }`;
          i++;
        }
      } else {
        let i = 0;
        for (const pathNode of this.allPathNodes[this.selectedPathIndex - 1]) {
          paths += `${i ? ' <i class="fas fa-chevron-right"></i> ' : ""} ${pathNode.name
            }`;
          i++;
        }
      }
      this.addMessage("bot", paths, "text");
      this.isSelectedPathIndex = true;
    }
    if (
      this.currentMessageStep === 6 &&
      msgValueUc === this.messageMap.save.toUpperCase()
    ) {
      this.createWatchPath();
      this.msgValue = "";
      return;
    }
    if (
      (this.currentMessageStep === 0 || this.currentMessageStep === 6) &&
      msgValueUc === this.messageMap.rename.toUpperCase()
    ) {
      this.msgValue = this.watchPathName;
      this.watchPathName = "";
    }
    if (this.currentMessageStep === 111) {
      if (msgValueUc === this.messageMap.cancel.toUpperCase()) {
        this.currentMessageStep = 6;
      } else {
        this.openBadgeModal();
      }
    }
    if (
      (this.currentMessageStep === 0 || this.currentMessageStep === 6) &&
      msgValueUc === this.messageMap.addBadge.toUpperCase()
    ) {
      this.currentMessageStep = 111;
    }
    if (this.currentMessageStep === 5) {
      this.watchPathName = this.msgValue;
      this.isInitialStep = false;
    }
    if (this.currentMessageStep === 7) {
      this.currentMessageStep = 5;
      this.watchPathName = this.msgValue;
      this.isInitialStep = false;
    }
    if (this.msgValue === this.messageMap.addLearningObjectives) {
      // console.log("this.isEditPathWay", this.isEditPathWay);

      if (this.isEditPathWay) {
        this.isDisableInput = false;
        this.messages = this.messages.filter((i) => i.content !== "Objectives");
        this.inputValues = this.inputValues.length
          ? this.inputValues
          : (this.inputValues = [""]);
        this.inputLength = this.inputLength.length
          ? this.inputLength
          : (this.inputLength = [""]);
      }
      this.currentMessageStep = 8;
    }

    if (this.msgValue === this.messageMap.hubSpotLifeCycle) {
      this.messages = this.messages.filter(
        (i: any) =>
          i.type !== "select" && i.content !== this.messageMap.hubSpotLifeCycle
      );
      this.currentMessageStep = 9;
    }

    if (this.msgValue) {
      this.getBotAnswer(this.msgValue);
    }
    this.msgValue = "";
    // console.log(this.currentMessageStep)
  }

  createWatchPath() {
    this.isSubmitPathWaysForm = true;
    const pathNodes = [];
    for (const selectedPathNode of this.selectedPathNodes) {
      pathNodes.push(selectedPathNode._id);
    }
    const payload: any = {
      videoId: this.mainID,
      pathName: this.watchPathName,
      startNodeId: this.startWatchNode._id,
      startNodeType: this.startWatchNode.type,
      endNodeId: this.endWatchNode._id,
      endNodeType: this.endWatchNode.type,
      pathNodes,
      learningObjectives: this.inputValues,
    };
    if (this.assignBadge) {
      payload.viewCompletionBadge = this.assignBadge._id;
    }
    if (this.editWatchPath) {
      payload._id = this.editWatchPath._id;
    }
    if (this.hubSpotLifeCycle) {
      payload.hubSpotLifeCycle = this.hubSpotLifeCycle;
    }
    // console.log(payload)
    this.spinner = true;
    this.urlService
      .setPathway(payload, this.editWatchPath)
      .subscribe(
        (res) => {
          this.isDisableInput = false;
          this.cancelAddWatchPath();
          this.getVideoPathWays();
          this.toastr.success(
            `Pathways ${this.editWatchPath ? "updated" : "created"
            } successfully`
          );
        },
        (err) => {
          this.isSubmitPathWaysForm = false;
          this.watchPathName = "";
          this.addMessage(
            "bot",
            "The name of the pathway already exists! select a different name",
            "text"
          );
          this.getBotAnswer("");
        }
      )
      .add(() => (this.spinner = false));
  }
      openBadgeModal() {
        const dialogRef = this.dialog.open(CreateVideoComponent, {
          data: {
            badges: true,
            links: false,
            videos: false,
          },
          minWidth: "50vw",
        });
        dialogRef.afterClosed().subscribe((result: UploadedFileResponse) => {
          if (result) {
            this.currentMessageStep = 6;
            this.assignBadge = result;
            this.getBotAnswer("Badge", "badge", result.pic, result.name, result);
          }
        });
      }
  
  getFirstAndLastTwoElements(array: any[]): any[] {
    if (array.length < 4) {
      return array;
    } else {
      return array.slice(0, 2).concat(array.slice(-2));
    }
  }

  getSelectedPath(item) {
    this.clearAllPathNodesColor(this.pathNodes);
    this.pathNodes = item;
    this.pathNodes.forEach((i) => (i["color"] = item[item.length - 1].color));
    this.allPossiblePaths = [item];
    this.updateCurrentHighlight();
    this.currentMessageStep = 7;
    this.getBotAnswer("");
    this.addMessage(
      "user",
      "Selected Path :",
      "pathColor",
      "",
      item[item.length - 1].color
    );
  }

  clickSelectVideo() {
    if (!this.isAssistCreateVideo) {
      this.isOpenCreateVideo = true;
      this.openVideoCreateModel("freeboard");
      this.createInteractiveVideoObj.selectedVideoCheck = false;
      this.createInteractiveVideoObj.interactiveStep = "three";
    }
  }

  onEditWatchPath(i) {
    this.getUserInfoWithHubSpot();

    this.isInitialStep = true;
    this.isEditPathWay = true;

    if (this.isMessageLoad) {
      return;
    }
    this.toggleDragDropNode(false);
    this.cancelAddWatchPath();
    $("#tree-view").addClass("add-pathways");
    this.isAddWatchPath = true;
    this.editWatchPath = i;
    this.startWatchNode = i.startNode;
    this.endWatchNode = i.endNode;
    this.watchPathName = i.pathName;
    this.isSelectedPathIndex = true;
    this.updateCurrentHighlight();
    this.addMessage("bot", "Pathway name: " + this.watchPathName, "text");
    this.addMessage(
      "bot",
      "Starting node",
      "image",
      this.startWatchNode?.poster,
      this.startWatchNode?.name
    );
    this.addMessage(
      "bot",
      "Ending node",
      "image",
      this.endWatchNode?.poster,
      this.endWatchNode?.name
    );

    if (i?.learningObjectives) {
      this.inputValues = i.learningObjectives;
      this.inputLength = i.learningObjectives;
      this.addMessage("bot", "Objectives", "input");
      this.isDisableInput = true;
    }

    if (i?.hubSpotLifeCycle) {
      this.hubSpotLifeCycle = i.hubSpotLifeCycle;
      this.addMessage(
        "bot",
        `HubSpot LifeCycle: ${this.hubSpotLifeCycle}`,
        "select"
      );
    }

    if (this.editWatchPath.viewCompletionBadge) {
      this.addMessage(
        "bot",
        "Badge",
        "badge",
        this.editWatchPath?.viewCompletionBadgeInfo[0]?.pic,
        this.editWatchPath?.viewCompletionBadgeInfo[0]?.name,
        this.editWatchPath?.viewCompletionBadgeInfo[0]
      );
    }
    this.getBotAnswer("", "updatePathways");
    // this.handleActiveWatchPath(i);
  }


  showObjectivesPopup(data: any) {
    this.objectivesPopupData = data;
  }

  hideObjectivesPopup() {
    if (!this.isDisplayPopupWithObjective) {
      this.objectivesPopupData = null;
    }
  }

  showPopupWithObjective() {
    this.isDisplayPopupWithObjective = true;
  }

  hidePopupWithObjective() {
    this.isDisplayPopupWithObjective = false;
    this.objectivesPopupData = null;
  }

  deleteWatchPath(i) {
    this.deleteDialogRef.open(
      {
        header: "Delete path",
        title: `Are you sure? Do you want to delete "${i.pathName}" path?`,
        trueButton: "Delete",
      },
      (e) => {
        if (e) {
          this.spinner = true;
          this.urlService
            .deletePathway(i._id)
            .subscribe((res) => {
              this.toastr.success(res.message);
              this.getVideoPathWays();
            })
            .add(() => (this.spinner = false));
        }
      }
    );
  }

  deleteCurrentNode() {
    console.log("in deleteCurrentNode");

    this.createInteractiveVideoObj.spinner = true;
    if (this.deleteCurrentNodeType) {
      // this.createInteractiveVideoObj.spinner = true;
      if (this.createInteractiveVideoObj.currentObj.type === "main") {
        this.urlService
          .deleteBookMarkVideo(this.createInteractiveVideoObj.currentObj._id)
          .subscribe((success) => {
            this.toastr.success("Delete successfully!");
            $("#tree-Modal").modal("hide");
            $("#deleteCurrentNodeModal").modal("hide");
            // this.createChoiceObj.child = [];
            this.getMainVideoInfo();
            this.deleteCurrentNodeType = 1;
          })
          .add(() => {
            this.createInteractiveVideoObj.spinner = false;
          });
      } else {
        const Payload = {
          videoId: this.createInteractiveVideoObj.currentObj._id,
          mainId: this.createInteractiveVideoObj.currentObj.parentId,
          isDeleteSingleNode: this.deleteCurrentNodeType === 1,
        };
        
        this.urlService
          .deleteChildVideo(Payload)
          .subscribe((success) => {
            // this.createInteractiveVideoObj.spinner = false;
            $("#tree-Modal").modal("hide");
            $("#deleteCurrentNodeModal").modal("hide");
            // this.createChoiceObj.child = [];
            this.latestNodeId = this.createInteractiveVideoObj.currentObj.parentId;
            this.getMainVideoInfo();
            this.calculateTreeDimensions();  // :straight_ruler: Recalculate tree size
            this.renderTree(true);           // :deciduous_tree: Re-render tree
            this.generateCustomLinks();       // :link: Ensure links are updated (if applicable)
            this.updateMinimapViewport();     // :world_map: Sync minimap viewport

            if (!success.error) this.toastr.info("Updated Successfully!");
            
            else this.toastr.error(success.message);

            this.deleteCurrentNodeType = 1;
          })
          .add(() => {
            this.createInteractiveVideoObj.spinner = false;
          });
      }
    }
  }

  //Cancel deleting connection of the connector node
  cancelDeleteCurrentConnection() {
    console.log("in cancelDeleteCurrentConnection - this.selectedLink = ", this.selectedLink);
    console.log("in cancelDeleteCurrentConnection - this.selectedLinkData = ", this.selectedLinkData);
 
    this.highlightConnectorLinks(d3.select(this.selectedLink), false, false);
    
    this.selectedLink = null;
    this.selectedLinkData = null;
   
  }

  //Delete connection of the connector node
  deleteCurrentConnection() {
    console.log("in deleteCurrentConnection - this.connectorNodesToBeDeleted = ", this.connectorNodesToBeDeleted);

    this.createInteractiveVideoObj.spinner = true;

    const payload = {
      "videoId":this.mainID,
      "connectorNodeId":this.connectorNodesToBeDeleted.targetId,
      "removeParentIds":[this.connectorNodesToBeDeleted.sourceId]
    };

    this.urlService
      .removeParentIds(payload)
      .subscribe((success) => {
        this.toastr.success("Connection Deleted successfully!");
        $("#deleteConnectorModal").modal("hide");
        this.connectorNodesToBeDeleted = {targetId: null, sourceId: null};

        console.log("in deleteCurrentConnection - this.selectedLink = ", this.selectedLink);

        d3.select(this.selectedLink).remove();
        
        this.selectedLink = null;
        this.selectedLinkData = null;

        //this.reinitializeChart();
        this.getMainVideoInfo();
      })
      .add(() => {
        this.createInteractiveVideoObj.spinner = false;
      });
  }
  
  openContributorModal() {
    this.findorselect = "";
    // WC 04062022
    this.collabPage = 1;
    this.followers = [];
    this.contributorsList = [];
    this.getFollowerContributors();
    this.getAddedContributors();
    this.removeContributorsIds = [];
    this.addContributorsIds = [];
    this.contibutorsTab = 'remove'
    // WC 04062022
    this.isShareOpen = true;
    $("#contributor-Modal").modal("show");
  }

  searchUser() {
    this.followers = []
    this.getFollowerContributors();
  }

  getFollowerContributors() {
    // console.log("this.mainID ", this.mainID);
    if (!this.mainID) {
      return;
    }

    // this.createInteractiveVideoObj.spinner = true
    this.urlService
      .getFollowerContributors(this.mainID, this.collabLimit, this.collabPage, this.findorselect)
      .subscribe((res) => {
        this.createInteractiveVideoObj.spinner = false
        this.followers.push(...res.data);

        // console.log("this.followers: ", this.followers);

        this.followersLength = this.followers.length;
      });
  }

  onScrollCollab(): void {
    ++this.collabPage;
    this.getFollowerContributors();
  }

  onScrollRemoveCollab(): void {
    ++this.collabPage;

    this.getAddedContributors();
  }

  getAddedContributors() {
    this.createInteractiveVideoObj.spinner = true
    this.urlService.getAddedContributors({ videoId: this.mainID, limit: this.collabLimit, page: this.collabPage, searchText: this.findorselectcontributors }).subscribe((res) => {
      this.createInteractiveVideoObj.spinner = false
      this.contributorsList.push(...res.data);
    })
  }

  closeContributorModal() {
    $("#contributor-Modal").modal("hide");
    this.isShareOpen = false;
  }

  changeContibutorsTab(value: any) {
    this.contibutorsTab = value;
    this.findorselectcontributors = ''
    this.findorselect = ''
    this.addContributorsIds = [];
    this.removeContributorsIds = [];

    this.collabPage = 1;
    if (value === 'add') {
      this.followers = [];
      this.getFollowerContributors()
    } else {
      this.contributorsList = [];
      this.getAddedContributors();
    }
  }

  onCollabChange(follower: any): void {

    let checkId = this.addContributorsIds.some((i) => i === follower.followersDetails._id);

    if (checkId) {
      const index = this.addContributorsIds.indexOf(follower.followersDetails._id);
      if (index !== -1) {
        this.addContributorsIds.splice(index, 1);
      }
    } else {
      this.addContributorsIds.push(follower.followersDetails._id);
    }

   }

  findContibutors() {
    this.contributorsList = [];
    this.getAddedContributors();
  }

  onAddedCollabChange(value: any) {
    let checkId = this.removeContributorsIds.some((i) => i === value._id);

    if (checkId) {
      const index = this.removeContributorsIds.indexOf(value._id);
      if (index !== -1) {
        this.removeContributorsIds.splice(index, 1);
      }
    } else {
      this.removeContributorsIds.push(value._id);
    }
  }

  removeContributor() {
    this.createInteractiveVideoObj.spinner = true
    let data = {
      ids: this.removeContributorsIds
    }
    this.urlService
      .deleteContributor(data)
      .subscribe((success) => {
        this.createInteractiveVideoObj.spinner = false;
        this.findorselectcontributors = ''
        this.contributorsList = [];
        this.collabPage = 1;
        this.getAddedContributors()
        this.toastr.success("Removed contributors successfully");
      })
      .add(() => (this.createInteractiveVideoObj.spinner = false));
  }

  addContributor() {
    const contributor = {
      videoID: this.mainID,
      contributorIDs: this.addContributorsIds,
    };

    this.urlService
      .setContributorV2(contributor)
      .subscribe((success) => {
        this.toastr.success(
          "Added contributors successfully"
        );
        this.collabPage = 1;
        this.followers = [];
        this.findorselect = ''
        this.addContributorsIds = [];

        this.getFollowerContributors()
      })
      .add(() => (this.createInteractiveVideoObj.spinner = false));
  }

    openVideoListModal(index: number, item?: any) {
      let data = [];

      if (this.createInteractiveVideoObj.finalObj.length) {
        //Exclude the parent node and children nodes of the to-be-added new node
        if (item) {
          data = this.createInteractiveVideoObj.finalObj.filter(
            (i) =>
              i._id !== item.parentId &&
              i.parentId !== item.parentId &&
              i.type == "child"
          );
          this.getConnectorNodeData(item, data, item);
        }

        //console.log("openVideoListModal - tree nodes after filtering parent and children nodes = ", data);
      }

      if (item?.URL) {
        return;
      }

      if (item?._id) {
        this.createInteractiveVideoObj.currentNodeOption = item;
      }

      console.log('data: ', data);
      // const dialogRef = this.dialog.open(VideoListComponent, {
      const dialogRef = this.dialog.open(CreateVideoComponent, {
        data: {
          treeNodes: this.createInteractiveVideoObj.endingNodes,
          connectorNodes: this.createInteractiveVideoObj.connectorNodes,
          nodes: data && data.length ? data : [],
          isAddChoice: true
        },
        minWidth: "50vw",
      });

      dialogRef.afterClosed().subscribe((result) => {
        console.log('result: ', result);
        if (result) {
          // this.createChoiceObj.child[index] = { ...result };

          this.createChoiceObj.child[index] = result;
          this.createChoiceObj.child[index].poster = result.poster
            ? result.poster
            : result.thumbnail;
          this.createChoiceObj.child[index].URLName = result.name;

          //WC 03262024 --- result.videoID not result._id as this will impact connector node.
          //this.createChoiceObj.child[index].videoID = result._id;
          this.createChoiceObj.child[index].videoID = result.videoID;

          if (result.type === "application/pdf") {
            this.createChoiceObj.child[index].poster =
              "assets/images/pdf-img.png";
          }

          this.selectionChanged(result, index);
        }
      });
    }

  getConnectorNodeData(video, allObj, selectedVideo?) {

    if (video && video?.parentId) {
      let videoObj = this.createInteractiveVideoObj.finalObj.find(
        (i) => i._id === video.parentId
      );
      let parentOfSelectedVideo = videoObj?.parent;
      let index = allObj.indexOf(parentOfSelectedVideo);
      if (index >= 0) {
        allObj.splice(index, 1);
      }
      if (selectedVideo && selectedVideo.parentId) {
        let selectedVideoObj = this.createInteractiveVideoObj.finalObj.find(
          (i) => i._id === selectedVideo.parentId
        );
        if (selectedVideoObj?.parentIds && selectedVideoObj?.parentIds.length) {
          for (const id of selectedVideoObj.parentIds) {
            let parentObj = this.createInteractiveVideoObj.finalObj.find(
              (i) => i._id === id
            );
            let index = allObj.indexOf(parentObj);
            if (index >= 0) {
              allObj.splice(index, 1);
            }
          }
        }
      }
      if (parentOfSelectedVideo?.parent) {
        this.getConnectorNodeData(
          { parentId: parentOfSelectedVideo._id },
          allObj
        );
      }
    }
  }

  selectionChanged(e: any, index) {
    console.log("in selectionChanged");

    this.createInteractiveVideoObj.spinner = true;
    if (!this.createInteractiveVideoObj.currentNodeOption) {
      const objFinal: any = {
        type: "child",
        name: e.name,
        //parentId: this.createInteractiveVideoObj.currentObj._id,
        URL: e.URL,
        time: 0,
        poster: e.poster ? e.poster : e.thumbnail,
        videoID: e._id,
        // WC 08092022
        subtitleUrl: e.subtitleUrl,
        // isEndingNode: e.type === 'url'
        isEndingNode: false,
        // WC 08092022
      };

      if (e?.isEndingNode) {
        objFinal.videoID = e.videoID;
        objFinal.endingNodeId = e._id;
      }
      if (e?.isConnectorNode) {
        //console.log("setting connector node property: e.videoID and e._id")

        objFinal._id = e._id;
        objFinal.isConnectorNode = e.isConnectorNode;
        objFinal.parentIds = e.parentIds;
        objFinal.videoID = e.videoID;

        //Save the original parentId
        objFinal.oldParentId = e.parentId;

        //Set the new parent Id
        objFinal.parentId = this.createInteractiveVideoObj.currentObj._id;

        //console.log("*** objFinal = ", objFinal);
      } else {
        objFinal.parentId = this.createInteractiveVideoObj.currentObj._id;
      }

      this.urlService.createInteractiveVideo(objFinal).subscribe(
        (success) => {
          console.log("Adding NEW CHILD --- success = ", success);

          this.createChoiceObj.child[index] = success.data?.data;

          //console.log("Adding NEW CHILD - this.createChoiceObj.child[index].name = ", this.createChoiceObj.child[index].name);

          this.createChoiceObj.child[index].URLName =
            this.createChoiceObj.child[index].name;
          this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
          if (
            this.createInteractiveVideoObj.currentObj.children === undefined
          ) {
            this.createInteractiveVideoObj.currentObj.children = [];
          }
          const childObjFinal = success.data?.data;
          // this.createInteractiveVideoObj.currentObj.children.push(objFinal);
          this.createInteractiveVideoObj.currentObj.children.push(
            childObjFinal
          );
          
          this.getMainVideoInfo()
          this.toastr.info("Added Successfully!");
          if (this.isAssistCreateVideo) {
            this.assistEventsSubject.next({
              type: this.msgEvent.addChildNode,
              data: objFinal,
            });
          }
        },
        (error) => {
          this.createInteractiveVideoObj.spinner = false;
        }
      );
    } else {
      // REPLACING A VIDEO
      let subtitleUrl = "";
      if (this.createInteractiveVideoObj.selectedItem.subtitleUrl != undefined)
        subtitleUrl = e.subtitleUrl;
      const objFinal: any = {
        id: this.createInteractiveVideoObj.currentNodeOption._id,
        type: this.createInteractiveVideoObj.currentNodeOption.type,
        name: e.name
          ? e.name
          : this.createInteractiveVideoObj.currentNodeOption.name,
        URL: e.URL,
        poster: e.poster ? e.poster : e.thumbnail,
        Title: this.createInteractiveVideoObj.currentNodeOption.Title,
        Description:
          this.createInteractiveVideoObj.currentNodeOption.Description,
        // WC 07272022
        Tag: this.createInteractiveVideoObj.currentNodeOption.Tag,
        // WC 07272022
        Categories: this.createInteractiveVideoObj.currentNodeOption.Categories,
        time: this.createInteractiveVideoObj.currentNodeOption.time,
        parentId: this.createInteractiveVideoObj.currentNodeOption.parentId,
        videoID:
          this.createInteractiveVideoObj.currentNodeOption.videoID || e._id,
        // WC 08092022
        subtitleUrl,
        // WC 08092022
      };

      if (this.createInteractiveVideoObj.currentNodeOption?.userPrompt) {
        objFinal.userPrompt = this.createInteractiveVideoObj.currentNodeOption
          ?.userPrompt
          ? this.createInteractiveVideoObj.currentNodeOption?.userPrompt
          : "Make your next choice";
      }

      this.urlService.updateInteractiveVideo(objFinal).subscribe(
        (success) => {
          // this.createChoiceObj.child[index] = success.data;
          // this.createChoiceObj.child[index].URLName = this.createChoiceObj.child[index].name
          this.createInteractiveVideoObj.spinner = false;
          this.createInteractiveVideoObj.currentNodeOption = null;
          this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
          this.getMainVideoInfo();
          this.toastr.info("Updated Successfully!");
        },
        (error) => {
          this.toastr.error(error.error.data.message);
          this.createInteractiveVideoObj.spinner = false;
        }
      );
    }
  }

  updateVideoTitle(name: any, child = false) {
    if (child) {
      this.childTitleChange$.next({ ...name });
      return;
    }
    const title = document.getElementById("videoName"); // 39
    let initialSize = 24;
    if (name.length > 39) {
      const size = name.length - 39;
      initialSize = 24 - size;
    }
    if (initialSize > 15) {
      title.style.fontSize = initialSize + "px";
    } else {
      title.style.fontSize = "15px";
    }
    if (name.length > 2) {
      this.titleChange$.next(name);
    }
    // else {
    //   this.toastr.warning('Minimum 3 letter are allowed!');
    // }
  }

   openPreviewUrl(url: string, isAI: boolean = false) {
      if (url) {
        window?.open(url, "_blank");
        if (isAI) {
          this.assistEventsSubject.next({ type: this.msgEvent.close, data: "" });
        }
      } else {
        this.replaceVideo(isAI);
      }
  }

  getDuration(e) {
    //console.log("getDuration - e = ", e);

    //WC 11192024 --- Persist the e.target.duration to the database if they are not the same
    if (this.createInteractiveVideoObj.currentObj.duration != e.target.duration) {
      const payload = {
        videoId: this.createInteractiveVideoObj.currentObj._id,
        type: this.createInteractiveVideoObj.currentObj.type,
        duration: e.target.duration
      };

      this.urlService.updateVideoDuration(payload).subscribe();
    }
    //WC 11192024

    this.createInteractiveVideoObj.currentObj.duration = e.target.duration;
    let hasChilren = false;
    if (
      this.createInteractiveVideoObj.currentObj.children == null ||
      this.createInteractiveVideoObj.currentObj.children == undefined
    ) {
      hasChilren = false;
    } else {
      hasChilren =
        this.createInteractiveVideoObj.currentObj.children.length > 0;
    }
    this.placeMarker(
      hasChilren,
      this.createInteractiveVideoObj.currentObj.time,
      "video-all",
      e.target.duration
    );
  }
  
  placeMarker(haveChildren, markerTime, id, duration) {
    const that = this;

    const resetDom = document.createElement("button");
    resetDom.innerHTML =
      '<svg class="bi bi-arrow-clockwise" width="1.5em" height="1.5em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">' +
      '<path fill-rule="evenodd" d="M3.17 6.706a5 5 0 017.103-3.16.5.5 0 10.454-.892A6 6 0 1013.455 5.5a.5.5 0 00-.91.417 5 5 0 11-9.375.789z" clip-rule="evenodd"/>' +
      ' <path fill-rule="evenodd" d="M8.147.146a.5.5 0 01.707 0l2.5 2.5a.5.5 0 010 .708l-2.5 2.5a.5.5 0 11-.707-.708L10.293 3 8.147.854a.5.5 0 010-.708z" clip-rule="evenodd"/>' +
      "</svg>";
    resetDom.setAttribute("class", "vjs-control vjs-button");
    resetDom.setAttribute("title", "Start Over");
    resetDom.setAttribute("ids", "Start Over");
    $("#" + id + " .vjs-control-bar").append(resetDom);
    resetDom.addEventListener("click", () => {
      that.createInteractiveVideoObj.preview = false;
      setTimeout(() => {
        that.createInteractiveVideoObj.currentPlayingItem =
          that.createInteractiveVideoObj.mainVideo;
        if (id !== "video-all") {
          that.createInteractiveVideoObj.preview = true;
        }
        clearInterval(that.interval);
      }, 100);
    });

    if (!haveChildren) {
      $(".marker").remove();
    } else {
      const width = $("#" + id).width();
      const precentageOfMarker = (markerTime / duration) * 100;
      $(".marker").remove();
      $(
        "#" + id + " .vjs-progress-holder.vjs-slider.vjs-slider-horizontal"
      ).append(
        '<div class="marker" style="left:' + precentageOfMarker + '%"></div>'
      );

      // $('#'+id).parent().append();
    }
  }

  openDeleteConfirmation() {
    $("#deleteCurrentNodeModal").modal("show");
  }

  downloadVideo() {
    const payload = {
      videoId: this.createInteractiveVideoObj.currentObj?._id,
      type: this.createInteractiveVideoObj.currentObj?.type,
    };

    this.urlService.getOriginalUploadedVideoUrl(payload).subscribe((res) => {
      const filePath = res.data.videoSourceUrl;

      const isInternetExplorer = !!document.DOCUMENT_NODE;

      const pos = this.createInteractiveVideoObj.currentObj.name.lastIndexOf(".");
      const fileExt = this.createInteractiveVideoObj.currentObj.name.substring(pos);

      const fileName = this.createInteractiveVideoObj.currentObj.name + fileExt;

      if (isInternetExplorer) {
        window.open(filePath, fileName);
      } else {
        const link = document.createElement("a");
        link.download = fileName;
        link.href = filePath;

        link.click();
      }
    });
  }

  updateTranscript() {
    //console.log(this.createInteractiveVideoObj.currentObj)
    this.language =
      this.createInteractiveVideoObj.currentObj.subtitleTranslations[0];
    this.getVttFile();
    const dialogRef = this.dialog.open(this.updateTranscriptDialog, {
      width: "100%",
      maxWidth: "700px",
      panelClass: "position-choices",
      height: "auto",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.uploadTranscription();
      }
    });
  }

  updateAiKeyInsights() {
    this.createInteractiveVideoObj.currentObj = this.videoInfo;
    const dialogRef = this.dialog.open(this.updateAiKeyInsightsDialog, {
      width: "100%",
      maxWidth: "750px",
      panelClass: "position-choices",
      height: "auto",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.saveKeyInsights();
      }
    });
  }

  onSubmitForKeyInsight(form: NgForm) {
      if (!form.valid) {
        this.toastr.error("Please fill all the fields");
      }
  }

  removePoint(catIndex, pointIndex) {
    this.deleteDialogRef.open(
      {
        header: "Remove category point",
        title: "Are you sure you want to remove this category point?",
        trueButton: "Yes",
        falseButton: "No",
      },
      (e) => {
        if (e) {
          this.spinner = true;
          let payload = {
            id: this.mainID,
            aiKeyInsights: this.createInteractiveVideoObj.currentObj.aiKeyInsights
          }

          this.createInteractiveVideoObj.currentObj.aiKeyInsights.insights[catIndex].points.splice(pointIndex, 1);
          this.urlService.updateKeyInsights(payload).subscribe((res) => {
            this.spinner = false;
            this.toastr.success("Key insights updated successfully!");
          }, (error) => {
            this.spinner = false;
            this.toastr.error("Error!");
          })
        }
      }
    );
  }

  removeCategory(catIndex) {
    this.deleteDialogRef.open(
      {
        header: "Remove category",
        title: "Are you sure you want to remove this category?",
        trueButton: "Yes",
        falseButton: "No",
      },
      (e) => {
        if (e) {
          this.spinner = true;
          let payload = {
            id: this.mainID,
            aiKeyInsights: this.createInteractiveVideoObj.currentObj.aiKeyInsights
          }

          this.createInteractiveVideoObj.currentObj.aiKeyInsights.insights.splice(catIndex, 1);

          this.urlService.updateKeyInsights(payload).subscribe((res) => {
            this.spinner = false;
            this.toastr.success("Key insights updated successfully!");
          }, (error) => {
            this.spinner = false;
            this.toastr.error("Error!");
          })
        }
      }
    );
  }

  uploadTranscription() {
    const url = this.language[this.language.subtitle.code].split("?")[0];
    const formData = new FormData();
    formData.append("inputStr", this.textareaData);
    formData.append("fileName", url);
    this.createInteractiveVideoObj.spinner = true;
    this.urlService.uploadFileToS3(formData).subscribe((res) => {
      this.urlService.getSignedUrl(res.data).subscribe((preSignedUrl) => {
        this.language[this.language.subtitle.code] = preSignedUrl;
      });
      this.toastr.success(res.message);
      this.createInteractiveVideoObj.spinner = false;
    });
  }

  getVttFile(url = null) {
    this.urlService
      .getTextData(url || this.language[this.language.subtitle.code])
      .subscribe((res) => {
        this.textareaData = res;
        this.initialValue = res;
      });
  }

  changeLanguage() {
    if (this.textareaData !== this.initialValue) {
      this.confirmationModal();
    } else {
      this.getVttFile();
    }
  }

  confirmationModal() {
    const dialogRef = this.dialog.open(this.confirmationDialog, {
      width: "100%",
      maxWidth: "500px",
      panelClass: "my-dialog",
      height: "auto",
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getVttFile();
      }
    });
  }

  editVideo() {
    $("#tree-Modal").modal("hide");
    this.currentVideoData.isEdit = true;
    const dialogRef = this.dialog.open(EditVideoComponent, {
      disableClose: true,
      width: "100%",
      height: "80%",
      maxWidth: "80%",
      panelClass: "my-dialog",
      data: {
        video: this.createInteractiveVideoObj.currentObj,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result: ', result);
      if (result?.isAnyChange) {
        this.refreshInteractiveVideo();
      } else {
        this.clickedItem(result?.video);
      }
    });
  }

  refreshInteractiveVideo() {
    console.log("in refreshInteractiveVideo");

    $("#tree-Modal").modal("hide");
    this.createChoiceObj.child = [];
    this.createInteractiveVideoObj.currentObj.open = false;
    this.initialFunction("");
  }

  UptheMouse() {
    if (this.createChoiceObj.currentNo > 4) {
      this.createChoiceObj.currentNo = 4;
    } else if (this.createChoiceObj.currentNo < 1) {
      this.createChoiceObj.currentNo = 1;
    }
  }

  closePopover(popEle3: PopoverDirective, popEle32: string) {
    popEle3.hide();
    this.dashboardService.setHelpPopOver(popEle32);
  }

  addChoices(no) {
    for (let i = 0; i < no; i++) {
      const name = "Untitled " + i;
      const obj: any = {
        name,
        URL: "",
        _id: "",
        parentId: this.createInteractiveVideoObj.currentObj._id,
      };
      this.createChoiceObj.child.push(obj);
    }
    this.createChoiceObj.parent = this.createInteractiveVideoObj.currentObj;
  }

  addPath() {
    console.log ("in addPath");

      const dialogRef = this.dialog.open(CreateVideoComponent, {
        data: {
          isAddChoice: true,
          isAddPath: true,
        },
        minWidth: "50vw",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.createInteractiveVideoObj.spinner = true;
          $('#tree-Modal').modal('hide')
          let payload = {
            parentId: this.createInteractiveVideoObj.currentObj?.parentId === "0" && !this.createInteractiveVideoObj.currentObj?.parent ? this.mainID : this.createInteractiveVideoObj.currentObj?._id,
            type: this.createInteractiveVideoObj.currentObj?.parentId === "0" && !this.createInteractiveVideoObj.currentObj?.parent ? 'main' : 'child',
            mainVideoId: this.mainID,
            childIds: result,
          }
          this.urlService.bookmarkvideospath(payload).subscribe((res) => {
            this.toastr.success("Path Created Successfully!");
            this.getMainVideoInfo();
            this.assistEventsSubject.next({ type: this.msgEvent.addedPath });
          }, (error) => {
            console.log("error", error)
            this.createInteractiveVideoObj.spinner = false;
          })
        }
      });
    }
  
  durationChange(duration?: string) {
    console.log("in durationChange - duration = ", duration);

    const time = duration.split(":");
    const currentTime =
      Number(time[2]) + Number(time[1]) * 60 + Number(time[0]);
    if (currentTime > this.createInteractiveVideoObj.currentObj.duration) {
      this.toastr.error("Time should be less then duration!");
      return;
    } else {
      this.duationChange$.next(currentTime);
    }
  }

  updateVideoUserPrompt(userPrompt: any) {
    this.userPromptChange$.next(userPrompt);
  }

  updateVideoUserPromptValue(userPrompt) {
    this.createInteractiveVideoObj.spinner = true;

    const obj: any = {
      id: this.createInteractiveVideoObj.currentObj._id,
      type: this.createInteractiveVideoObj.currentObj.type,
      name: this.createInteractiveVideoObj.currentObj.name,
      URL: this.createInteractiveVideoObj.currentObj.URL,
      Title: this.createInteractiveVideoObj.currentObj.Title,
      Description: this.createInteractiveVideoObj.currentObj.Description,
      // WC 07272022 - Add Tag
      Tag: this.createInteractiveVideoObj.currentObj.Tag,
      // WC 07272022
      Categories: this.createInteractiveVideoObj.currentObj.Categories,
      time: this.createInteractiveVideoObj.currentObj.time,
      parentId: this.createInteractiveVideoObj.currentObj.parentId,
      poster: this.createInteractiveVideoObj.currentObj.poster,
      videoID: this.createInteractiveVideoObj.currentObj.videoID,
      // WC 08092022 - subtitleUrl
      subtitleUrl: this.createInteractiveVideoObj.currentObj.subtitleUrl,
      // WC 08092022
      userPrompt,
    };

    this.urlService.updateInteractiveVideo(obj).subscribe(
      (success) => {
        this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
        if (this.createInteractiveVideoObj.currentObj.type === "main") {
          this.createInteractiveVideoObj.mainVideo = success.data;
        }
        this.callChild1()
        this.toastr.info("Updated Successfully!");
        this.createInteractiveVideoObj.spinner = false;
      },
      (error) => {
        this.toastr.error(error.error.data.message);
        this.createInteractiveVideoObj.spinner = false;
      }
    );
  }

  updateCurrentName(name: any) {
    this.createInteractiveVideoObj.spinner = true;
    this.createInteractiveVideoObj.currentObj.name = name;
    if (this.createInteractiveVideoObj.currentObj.type === "main") {
      this.createInteractiveVideoObj.mainVideo.name = name;
    }
    const obj: any = {
      id: this.createInteractiveVideoObj.currentObj._id,
      type: this.createInteractiveVideoObj.currentObj.type,
      name,
      URL: this.createInteractiveVideoObj.currentObj.URL,
      Title: this.createInteractiveVideoObj.currentObj.Title,
      Description: this.createInteractiveVideoObj.currentObj.Description,
      // WC 07272022 - Add Tag
      Tag: this.createInteractiveVideoObj.currentObj.Tag,
      // WC 07272022
      Categories: this.createInteractiveVideoObj.currentObj.Categories,
      time: this.createInteractiveVideoObj.currentObj.time,
      parentId: this.createInteractiveVideoObj.currentObj.parentId,
      poster: this.createInteractiveVideoObj.currentObj.poster,
      videoID: this.createInteractiveVideoObj.currentObj.videoID,
      // WC 08092022 - subtitleUrl
      subtitleUrl: this.createInteractiveVideoObj.currentObj.subtitleUrl,
      // WC 08092022
    };

    if (this.createInteractiveVideoObj.currentObj?.userPrompt) {
      obj.userPrompt = this.createInteractiveVideoObj.currentObj?.userPrompt
        ? this.createInteractiveVideoObj.currentObj?.userPrompt
        : "Make your next choice";
    }

    this.urlService.updateInteractiveVideo(obj).subscribe(
      (success) => {
        this.latestNodeId = this.createInteractiveVideoObj.currentObj._id;
        this.callChild1();
        this.toastr.info("Updated Successfully!");
        if (this.isAssistCreateVideo) {
          this.refreshInteractiveVideo();
          // this.assistEventsSubject.next({type: 'editFirstNodeName', data: success.data});
        }
      },
      (error) => {
        this.toastr.error(error.error.data.message);
        this.createInteractiveVideoObj.spinner = false;
      }
    );
  }

  updateChildNodeName(i) {
    this.createInteractiveVideoObj.spinner = true;
    if (!i.name) {
      i.name = "untitled";
    }
    const obj: any = {
      id: i._id,
      type: i.type,
      name: i.name,
      URL: i.URL,
      Title: i.Title,
      Description: i.Description,
      Tag: i.Tag,
      Categories: i.Categories,
      time: i.time,
      parentId: i.parentId,
      poster: i.poster,
      videoID: i.videoID,
      subtitleUrl: i.subtitleUrl,
    };

    if (i?.userPrompt) {
      obj.userPrompt = i?.userPrompt ? i?.userPrompt : "Make your next choice";
    }

    this.urlService.updateInteractiveVideo(obj).subscribe(
      (success) => {
        this.latestNodeId = i._id;
        // this.callChild1();
        this.toastr.info("Updated Successfully!");
      },
      (error) => {
        this.toastr.error(error.error.data.message);
        this.createInteractiveVideoObj.spinner = false;
      }
    );
  }

  updateCurrentTime(duration: any) {
    // console.log("In updateCurrentTime - this.createInteractiveVideoObj.currentObj.subtitleUrl = ", this.createInteractiveVideoObj.currentObj.subtitleUrl);

    this.createInteractiveVideoObj.currentObj.time = duration;
    const obj: any = {
      id: this.createInteractiveVideoObj.currentObj._id,
      type: this.createInteractiveVideoObj.currentObj.type,
      poster: this.createInteractiveVideoObj.currentObj.poster,
      name: this.createInteractiveVideoObj.currentObj.name,
      URL: this.createInteractiveVideoObj.currentObj.URL,
      Title: this.createInteractiveVideoObj.currentObj.Title,
      Description: this.createInteractiveVideoObj.currentObj.Description,
      // WC 07272022
      Tag: this.createInteractiveVideoObj.currentObj.Tag,
      // WC 07272022
      Categories: this.createInteractiveVideoObj.currentObj.Categories,
      time: this.createInteractiveVideoObj.currentObj.time,
      parentId: this.createInteractiveVideoObj.currentObj.parentId,
      videoID: this.createInteractiveVideoObj.currentObj.videoID,
      // WC 08092022
      subtitleUrl: this.createInteractiveVideoObj.currentObj.subtitleUrl,
      // WC 08092022
    };

    if (this.createInteractiveVideoObj.currentObj?.userPrompt) {
      obj.userPrompt = this.createInteractiveVideoObj.currentObj?.userPrompt
        ? this.createInteractiveVideoObj.currentObj?.userPrompt
        : "Make your next choice";
    }

    this.createInteractiveVideoObj.spinner = true;
    this.urlService.updateInteractiveVideo(obj).subscribe(
      (success) => {
        this.callChild1();
        this.toastr.info("Updated Successfully!");
      },
      (error) => {
        this.toastr.error(
          error.error.data?.message
            ? error.error.data?.message
            : "Update Failed!"
        );
        this.createInteractiveVideoObj.spinner = false;
        // this.toastr.error('Update Failed!');
      }
    );
  }

   drop(event: CdkDragDrop<string[]>) {
        moveItemInArray(
          this.createChoiceObj.child,
          event.previousIndex,
          event.currentIndex
        );
        // console.log(this.createChoiceObj)
  }
  
  addMoreChoices() {
    if (this.createChoiceObj.child.length > 3) {
      this.toastr.warning("Choices can not be more than 4!");
      return;
    }
    const i = this.createChoiceObj.child.length - 1;
    const name = "Untitled " + i;
    const obj: any = {
      name,
      URL: "",
      _id: "",
      parentId: this.createInteractiveVideoObj.currentObj._id,
    };
    this.createChoiceObj.child.push(obj);
  }

  
  editQuestionnaire(questionnaire) {
    const dialogRef = this.dialog.open(CreateQuestionnaireComponent, {
      width: "100%",
      maxWidth: "1000px",
      height: "auto",
      autoFocus: false,
      data: {
        questionnaire,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.currentVideoData.isEdit = true;
        // this.refreshInteractiveVideo();
        location.reload();
      }
    });
  }

      openSetTimerModal() {
      const timerData = this.createInteractiveVideoObj.currentObj?.timer;
      //console.log("timerData = ", timerData);

      this.hh = timerData?.hh ? timerData.hh.toString() : "00";
      this.mm = timerData?.mm ? timerData.mm.toString() : "00";
      this.ss = timerData?.ss ? timerData.ss.toString() : "00";
      //console.log("this.ss  = ", this.ss );

      this.userMessage = timerData?.timerUpMessage
        ? timerData?.timerUpMessage
        : "";

      const dialogRef = this.dialog.open(this.setTimerForCurrentNodeDialog, {
        width: "100%",
        maxWidth: "500px",
        panelClass: "position-choices",
        height: "auto",
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.saveTimer();
        }
      });
    }

  saveTimer() {
    const timer = this.getTime();

    const payload = {
      videoId: this.createInteractiveVideoObj.currentObj._id,
      type: this.createInteractiveVideoObj.currentObj.type,
      timer: timer,
    };

    this.urlService.setTimerInVideoChoice(payload).subscribe(
      (res) => {
        this.createInteractiveVideoObj.currentObj.timer = res.data.timer;
        this.toastr.success("Timer added successfully!");
        location.reload();
      },
      (err) => {
        this.toastr.error("Error!");
      }
    );
  }

  getTime() {
    const timer = {
      hh: this.hh,
      mm: this.mm,
      ss: this.ss,
      timerUpMessage: this.userMessage,
    };
    return timer;
  }

  prependZero(number: any) {
    if (number == "00") return "00";
    if (number < 9) return "0" + number;
    else return number;
  }

  resetTimer() {
    const payload = {
      videoId: this.createInteractiveVideoObj.currentObj._id,
      type: this.createInteractiveVideoObj.currentObj.type,
    };

    this.urlService.resetTimerInVideoChoice(payload).subscribe(
      (res) => {
        this.createInteractiveVideoObj.currentObj.timer = null;
        this.toastr.success("Timer reset successfully!");
        location.reload();
      },
      (err) => {
        this.toastr.error("Error!");
      }
    );
  }

  addQuestionnaire() {
    const dialogRef = this.dialog.open(CreateVideoComponent, {
      width: "95%",
      maxWidth: "1300px",
      panelClass: "my-dialog",
      height: "auto",
      minHeight: "500px",
      data: {
        questionnaire: true,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.createInteractiveVideoObj.spinner = true;
      if (result) {
        const payload = {
          questionnaireId: result._id,
          videoId: this.createInteractiveVideoObj.currentObj._id,
          type: this.createInteractiveVideoObj.currentObj.type,
        };
        this.urlService.attachQuestionnaireToVideo(payload).subscribe((res) => {
          this.currentVideoData.isEdit = true;
          // this.refreshInteractiveVideo();
          location.reload();
          this.createInteractiveVideoObj.spinner = false;
        });
      } else {
        this.createInteractiveVideoObj.spinner = false;
      }
    });
  }

  removeQuestionnaire() {
    this.deleteDialogRef.open(
      {
        header: "Remove Questionnaire",
        title: "Are you sure you want to remove this questionnaire?",
        trueButton: "Yes",
        falseButton: "No",
      },
      (e) => {
        this.createInteractiveVideoObj.spinner = true;
        if (e) {
          const payload = {
            questionnaireId:
              this.createInteractiveVideoObj.currentObj.questionnaireId,
            videoId: this.createInteractiveVideoObj.currentObj._id,
            type: this.createInteractiveVideoObj.currentObj.type,
          };
          this.urlService
            .detachQuestionnaireFromVideo(payload)
            .subscribe((res) => {
              this.currentVideoData.isEdit = true;
              // this.refreshInteractiveVideo();
              location.reload();
              // this.createInteractiveVideoObj.spinner = false;
            });
        } else {
          this.createInteractiveVideoObj.spinner = false;
        }
      }
    );
  }

     positionChoices() {
        const dialogRef = this.dialog.open(PositionChoicesComponent, {
          width: "95%",
          maxWidth: "1300px",
          panelClass: "position-choices",
          height: "auto",
          data: {
            video: this.createInteractiveVideoObj.currentObj,
            nodes: this.createInteractiveVideoObj.currentObj.children,
          },
        });
  
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            this.currentVideoData.isEdit = true;
            this.refreshInteractiveVideo();
  
            // WC 08162023 - Adding reload to resolve the issue in Staging where it's showing 1 empty node and all nodes are gone unless the user refreshes
            setTimeout(() => {
              location.reload();
            }, 500);
            // WC 08162023
  
            // this.createChoiceObj.child = result.choices;
            // this.createInteractiveVideoObj.currentObj.width = result.positionsData.width;
            // this.createInteractiveVideoObj.currentObj.height = result.positionsData.height;
          }
        });
      }
  
      reorderChoices(s) {
        this.isReorderChoice = s;
      }
  
      saveReorderChoices() {
        const object = {
          videoId: this.mainID,
          parentId: this.createChoiceObj.child[0].parentId,
          childrenOrder: this.createChoiceObj.child.map((x) => x._id),
        };
        // console.log(object)
        this.createInteractiveVideoObj.spinner = true;
        this.urlService.reorderNodeChildren(object).subscribe((res: any) => {
          this.latestNodeId = res.data[0].parentId;
          this.callChild1();
          this.reorderChoices(false);
          this.createInteractiveVideoObj.spinner = false;
        });
      }

  get canSaveChoices() {
    return this.createChoiceObj.child.find((x) => !x?._id);
  }

  get canPublishVideo() {
    return !this.createInteractiveVideoObj.finalObj.find((x) => !!!x.URL);
    // return !this.createInteractiveVideoObj.finalObj.find((x) => !x.URL);
  }

  saveStoryBoardChildNodes(currentObj?) {
    const nodes = [];
    let i = 0;
    for (const childElement of this.createChoiceObj.child) {
      ++i;
      if (childElement._id === "") {
        nodes.push({
          level: currentObj
            ? currentObj?.level + 1
            : this.createInteractiveVideoObj.currentObj.level + 1,
          parentId: currentObj
            ? currentObj._id
            : this.createInteractiveVideoObj.currentObj._id,
          mainId: this.mainID,
          name: childElement.name ? childElement.name : "Child " + i,
        });
      }
    }

    // console.log("saveStoryBoardChildNodes - nodes.length = ", nodes.length);

    if (nodes.length) {
      this.createEmptyNodes(nodes, "Object");
      // WC 07122023 - The newly added nodes are not shown in Staging and Prod
      // location.reload();
    } else {
      this.refreshInteractiveVideo();
    }
  }

  createEmptyNodes(obj?: any, type: string = "notObject", emptyNodeData?) {
    this.createInteractiveVideoObj.spinner = true;
    if (!obj) {
      obj = [
        {
          level: 0,
          parentId: null,
          name: "Parent Node",
          Tag: "storyboard",
        },
      ];
    }
    this.urlService.createEmptyNodes(obj).subscribe(
      (success) => {
        this.createInteractiveVideoObj.mainVideo = success.data;
        this.isOpenCreateVideo = false;
        $("#tree-Modal").modal("hide");
        this.createInteractiveVideoObj.spinner = false;
        this.createInteractiveVideoObj.selectedItem = {};
        this.createInteractiveVideoObj.selectedVideoCheck = false;
        if (!this.mainID) {
          this.mainID = success.data._id;
          const obj1: any = {
            id: this.createInteractiveVideoObj.mainVideo._id,
            type: this.createInteractiveVideoObj.mainVideo.type,
            name: this.createInteractiveVideoObj.mainVideo.name,
            URL: this.createInteractiveVideoObj.mainVideo.URL,
            Title: this.createInteractiveVideoObj.mainVideo.Title,
            Description: this.createInteractiveVideoObj.mainVideo.Description,
            Tag: "storyboard",
            Categories: this.createInteractiveVideoObj.mainVideo.Categories,
            time: this.createInteractiveVideoObj.mainVideo.time,
            parentId: this.createInteractiveVideoObj.mainVideo.parentId,
            poster: this.createInteractiveVideoObj.mainVideo.poster,
            videoID: this.createInteractiveVideoObj.mainVideo.videoID,
          };

          if (this.createInteractiveVideoObj.mainVideo?.userPrompt) {
            obj1.userPrompt = this.createInteractiveVideoObj.mainVideo
              ?.userPrompt
              ? this.createInteractiveVideoObj.mainVideo?.userPrompt
              : "Make your next choice";
          }

          this.urlService.updateInteractiveVideo(obj1).subscribe(() => {
            this.router.navigateByUrl(
              `/new-create-interactive-video?video=${this.createInteractiveVideoObj.mainVideo._id}`
            );
          });
          // this.urlService.updateInteractiveVideo(obj1).subscribe();
          // this.router.navigate(['create-interactive-video'], {
          //     queryParams: { video: success.data._id },
          // });
        }
        this.localStorageService.removeItem("freeTemp");
        // this.intialFunction('');
        if (obj[0].parentId) {
          setTimeout(() => {
            // console.log("createEmptyNodes - calling this.refreshInteractiveVideo()");
            // this.refreshInteractiveVideo();
            if (this.isAssistCreateVideo) {
              this.callChild1()
            } else {
              location.reload();
            }
          }, 1000);
        }

        if (type === "Object" && this.isAssistCreateVideo) {
          this.assistEventsSubject.next({ type: this.msgEvent.addMoreChoices });
        } else {
          this.assistEventsSubject.next({
            type: this.msgEvent.addFirstNode,
            data: success.data.data,
          });
          if (this.mainID && !obj[0].parentId) {
            this.router.navigate(["new-create-interactive-video"], {
              queryParams: { video: success.data._id },
            });
          }
        }
      },
      (error) => {
        this.createInteractiveVideoObj.spinner = false;
      }
    );
    this.createInteractiveVideoObj.step = "three";
  }

  headerSearchValues(value: string) {
    
    if (value) {
      //console.log('in headerSearchValues - value NOT EMPTY: ', value);

      const matchingItems = this.allNodes.filter(
        (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
      const notMatchingItems = this.allNodes.filter(
        (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) === -1
      );
      //console.log('in headerSearchValues - matchingItems: ', matchingItems);
      matchingItems.forEach((i) => {
        const matchNodeItem = document.getElementById(`node-button-${i._id}`);

        if (matchNodeItem)
          matchNodeItem.style.fill = "#d3c1eb";
      });
      //console.log('in headerSearchValues - notMatchingItems: ', notMatchingItems);
      notMatchingItems.forEach((i) => {
        const notMatchNodeItem = document.getElementById(`node-button-${i._id}`);

        if (notMatchNodeItem)
          notMatchNodeItem.style.fill = null;
      });
    } else {

      //console.log('in headerSearchValues - value IS EMPTY: ', value);
      
      this.allNodes.forEach((i) => {
        const allNodeItem = document.getElementById(`node-button-${i._id}`);

        if (allNodeItem)
          allNodeItem.style.fill = null;
      });

      // const latestItem = this.getLatestItem(this.allNodes, this.latestNodeId);

      // if (latestItem) {
      //   console.log("in headerSearchValues - latestItem = ", latestItem);

      //   const latestNodeItem = document.getElementById(
      //     `node-button-${latestItem[0]?._id}`
      //   );

      //   if (latestNodeItem)
      //     latestNodeItem.style.fill = "#d3c1eb";
      // }
    }

    this.headerSearchValuesInMinimap(value);
  }

  headerSearchValuesInMinimap(value: string) {
    //console.log('in headerSearchValuesInMinimap - value: ', value);
    if (value) {
      const matchingItems = this.allNodes.filter(
        (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
      );
      const notMatchingItems = this.allNodes.filter(
        (item) => item.name.toLowerCase().indexOf(value.toLowerCase()) === -1
      );
      //console.log('in headerSearchValuesInMinimap - matchingItems: ', matchingItems);
      matchingItems.forEach((i) => {
        const matchNodeItem = document.getElementById(`node-minimap-${i._id}`);
        if (matchNodeItem)
          matchNodeItem.style.fill = "#b887fa";
      });
      //console.log('in headerSearchValuesInMinimap - notMatchingItems: ', notMatchingItems);
      notMatchingItems.forEach((i) => {
        const notMatchNodeItem = document.getElementById(`node-minimap-${i._id}`);

        if (notMatchNodeItem)
          notMatchNodeItem.style.fill = null;
      });
    } else {
      this.allNodes.forEach((i) => {
        const allNodeItem = document.getElementById(`node-minimap-${i._id}`);

        if (allNodeItem)
          allNodeItem.style.fill = null;
      });

      // const latestItem = this.getLatestItem(this.allNodes, this.latestNodeId);

      // if (latestItem) {
      //   const latestNodeItem = document.getElementById(
      //     `node-minimap-${latestItem[0]._id}`
      //   );

      //   if (latestNodeItem)
      //     latestNodeItem.style.fill = "#d3c1eb";
      // }
    }
  }


  getLatestItem(nodes, id: string) {
    return nodes.filter((node) => node._id === id);
  }

  addVideoTitle(isAI: boolean = false) {
    const obj: any = {
      id: this.createInteractiveVideoObj.mainVideo._id,
      type: this.createInteractiveVideoObj.mainVideo.type,
      name: this.createInteractiveVideoObj.mainVideo.name,
      URL: this.createInteractiveVideoObj.mainVideo.URL,
      Title: this.createInteractiveVideoObj.mainVideo.Title,
      Description: this.createInteractiveVideoObj.mainVideo.Description,
      // WC 07272022 - Add tagging
      Tag: this.createInteractiveVideoObj.mainVideo.Tag,
      // WC 07282022
      Categories: this.createInteractiveVideoObj.mainVideo.Categories,
      time: this.createInteractiveVideoObj.mainVideo.time,
      parentId: this.createInteractiveVideoObj.mainVideo.parentId,
      poster: this.createInteractiveVideoObj.mainVideo.poster,
      videoID: this.createInteractiveVideoObj.mainVideo.videoID,
    };

    if (this.createInteractiveVideoObj.mainVideo?.userPrompt) {
      obj.userPrompt = this.createInteractiveVideoObj.mainVideo?.userPrompt
        ? this.createInteractiveVideoObj.mainVideo?.userPrompt
        : "Make your next choice";
    }
    this.urlService.updateInteractiveVideo(obj).subscribe(
      (success) => {
        if (isAI) {
          this.assistEventsSubject.next({
            type: this.msgEvent.saveStoryBoardName,
            data: this.createInteractiveVideoObj.mainVideo.Title,
          });
        }
      },
      (error) => {
        this.toastr.error(error.error.data.message);
        this.createInteractiveVideoObj.spinner = false;
      }
    );
  }

  previewVideo() {
    if (this.createInteractiveVideoObj.mainVideo.name == "Add Video") {
      this.toastr.warning("Please Add parent Video!");
      return;
    }
    this.createInteractiveVideoObj.preview = true;
    // this.toastr.info('Preparing Video!')
    $("#tree-preview-vid-Modal").modal("show");
    $("#tree-publish-vid-Modal").modal("hide");
  }

  closePreview() {
    $("#tree-preview-vid-Modal").modal("hide");
    this.createInteractiveVideoObj.preview = false;
    clearInterval(this.interval);
    // this.createInteractiveVideoObj.currentPlayingItem = {};
  }

  getCurrentTime(e) {
    this.interval = setInterval(() => {
      this.createInteractiveVideoObj.currentPlayingItem.currentTime =
        e.target.currentTime;
    }, 1000);

    let hasChilren: any = false;
    if (
      this.createInteractiveVideoObj.currentPlayingItem.children == null ||
      this.createInteractiveVideoObj.currentPlayingItem.children == undefined
    ) {
      hasChilren = false;
    } else {
      hasChilren =
        this.createInteractiveVideoObj.currentPlayingItem.children.length > 0;
    }

    //  this.createInteractiveVideoObj.currentPlayingItem.currentTime = e.target.currentTime
    this.placeMarker(
      hasChilren,
      this.createInteractiveVideoObj.currentPlayingItem.time,
      "previewVideo",
      e.target.duration
    );

    this.createInteractiveVideoObj.currentPlayingItem.currentTime =
      e.target.currentTime;
  }

  loadNextOption(e) {
    clearInterval(this.interval);
    this.createInteractiveVideoObj.currentPlayingItem.currentTime = 0;
    this.createInteractiveVideoObj.preview = false;
    // this.toastr.info('Choice Selected');
    setTimeout(() => {
      this.createInteractiveVideoObj.currentPlayingItem = e;
      this.createInteractiveVideoObj.preview = true;
    }, 100);
    // this.createInteractiveVideoObj.currentPlayingItem = e;
  }

  publishPreview() {
    this.getUserInfoWithHubSpot();

    if (this.createInteractiveVideoObj.mainVideo?.hubSpotLifeCycle) {
      if (this.userMeData?.hubSpot?.integrationCompletedTS) {
        this.getCrmLifeCycleStages();
      }
    }

    // First time this video is being published
    if (!this.createInteractiveVideoObj.mainVideo.isPublished) {
      // WC 03072023 -- Check if the user exceeds the allowable max no of published video as per their subsctiption package
      this.urlService.getUserSubscriptionPlan().subscribe((success) => {
        this.currSubscriptionId = success.data._id;
        // console.log('this.currSubscriptionId = ', success.data);

        const maxNoOfAllowedPublishedVideos =
          success.data.subscriptionPlanInfo.maxNoOfVideos;
        // console.log("maxNoOfAllowedPublishedVideos = ", maxNoOfAllowedPublishedVideos);

        this.urlService.getAllMainVideosCountV2().subscribe((success) => {
          const videoCounts = success.data;

          const getCountOfPublishedVideos = () => {
            let countOfPublishedVideos = 0;
            videoCounts.forEach((item) => {
              if (item._id.isPublished != undefined && item._id.isPublished)
                countOfPublishedVideos = item.count;

              // console.log("countOfPublishedVideos = ", countOfPublishedVideos);
            });

            return countOfPublishedVideos;
          };

          if (getCountOfPublishedVideos() < maxNoOfAllowedPublishedVideos) {
            this.publishModal();
          } else {
            this.pricingUrl = "/pricing?subId=" + this.currSubscriptionId;

            $("#exceed-max-videos-Modal").modal("show");
          }
        });
      });
    } else {
      this.publishModal();
    }
  }

   publishModal() {
    console.log("in publishModal");

        const checkAllUrl = this.createInteractiveVideoObj.finalObj.filter(
          (x) => !!!x.URL
        );
        if (checkAllUrl.length) {
          this.toastr.error("Unable to publish until all nodes have videos");
          return;
        }
        clearInterval(this.interval);
        this.toastr.info("Preparing Video!");
        this.allFollowers = [];
        this.getGroupList();
        this.getFollowersList(true);
        this.getMainVideoInfo();
        this.createInteractiveVideoObj.spinner = true;
  
        if (this.createInteractiveVideoObj.mainVideo?.viewCompletionBadge) {
          this.assignPublishBadge =
            this.createInteractiveVideoObj.mainVideo?.viewCompletionBadgeInfo[0];
        }
        this.urlService.fetchCategories().subscribe((sucess) => {
          this.availableCategory = sucess.data.categories;
          this.availableCategory.sort();
          this.closePreview();
          this.createInteractiveVideoObj.spinner = false;
          this.createInteractiveVideoObj.publish = true;
          this.groupList = [];
          this.followerListForPublic = [];
          this.followersList = [];
          $("#tree-publish-vid-Modal").modal(
            { backdrop: "static", keyboard: false },
            "show"
          );
          this.filledLogin("main_title");
          this.filledLogin("user_Description");
        });
  }
  
  getGroupList() {
    // const id = this.localStorageService.getItem('users');
    this.urlService.getRecipientsGroups(this.mainID).subscribe((res) => {
      this.allGroups = res?.data;
      this.groupLength = this.allGroups?.length;
    });
  }

  onScrollFollowers() {
    ++this.collabPage;
    this.getFollowersList();
  }

  getFollowersList(reset = false) {
    if (reset) {
      this.collabPage = 1;
      this.allFollowers = [];
    }
    this.urlService
      .getRecipientsFollowers(this.collabLimit, this.collabPage, this.localStorageService.getItem("user"), this.mainID)
      .subscribe((res) => {
        this.allFollowers.push(...res.data.followers);
      });
    // console.log(this.allFollowers)
  }

   filledLogin(id) {
      $("#" + id)
        .siblings(".input-field")
        .addClass("video_input_focus");
      $("#" + id).addClass("focusIn");
    }

     confirmDelete(id: any) {
      // this.deleteWaitingId = id;
      // console.log(this.deleteWaitingId)
      this.deleteDialogRef.open(
        {
          header: "Delete Video",
          title: "Are you sure you want to delete this video?",
        },
        (e) => {
          if (e) {
            this.spinner = true;
            this.urlService.deleteBookMarkVideo(id).subscribe(
              (success) => {
                // this.getMainVideos();
                this.dashboardService.onGetUserData();
                // this.createInteractiveVideoObj.spinner = false;
                this.router.navigateByUrl("/my-videos");
                this.toastr.success("Deleted successfully!");
              },
              (err) => {
                this.spinner = false;
              }
            );
          }
        }
      );
    }

  saveDraft() {
    this.toastr.success("Your video is successfully saved in draft.");
    this.router.navigate(["my-videos"], {
      queryParams: { currentTab: "draft" },
    });
  }

  private translateVideo() {
    const obj = {
      videoId: this.createInteractiveVideoObj.mainVideo._id,
      targetLanguages: this.targetLanguages,
    };
    if (obj.targetLanguages.length) {
      this.urlService.translateInteractiveVideo(obj).subscribe((response) => {
        if (!response.error && response.data.status) {
          this.toastr.success(response.data.status);
        }
      });
    }
  }

  checkTimeLinePath(node) {
    if (node.children && node.children?.length === 1) {
      const child = node.children[0];

      if (child.parentId === node._id) {
        return this.checkTimeLinePath(child);
      } else {
        return false; // Parent-child mismatch
      }
    } else if (node.children?.length === 0 || !node.children) {
      return true;
    } else {
      return false;
    }
  }

    onSubmit() {
      console.log("onSubmit - new create interactive video")
      //WC 031725 --- Verify if title, description and categories are blank or not before proceeding
      const mainTitle = this.createInteractiveVideoObj.mainVideo.Title;
      const totalCategories = this.createInteractiveVideoObj.mainVideo.Categories.length;
      const description = this.createInteractiveVideoObj.mainVideo.Description;

      if (totalCategories < 1) {
        this.toastr.warning("Category is required");
        return
      } else if (mainTitle === "") {
        this.toastr.error("Title is required");
        return
      } else if (description === "") {
        this.toastr.error("Description is required");
        return
      }

      if (this.allowTranslate) {
        this.translateVideo();
      }
      let obj;
      if (!!this.item) {
        this.createInteractiveVideoObj.mainVideo.poster = this.item;
      }

      // this.assistEventsSubject.subscribe((res) => {
      //   console.log('res: ', res);
      // })

      // console.log('this.createInteractiveVideoObj.mainVideo: ', this.createInteractiveVideoObj.mainVideo);
      const isPathOfChoice = this.checkTimeLinePath(this.createInteractiveVideoObj.finalObjTree[0]);
      obj = {
        id: this.createInteractiveVideoObj.mainVideo._id,
        type: this.createInteractiveVideoObj.mainVideo.type,
        name: this.createInteractiveVideoObj.mainVideo.name,
        URL: this.createInteractiveVideoObj.mainVideo.URL,
        Title: this.createInteractiveVideoObj.mainVideo.Title,
        Description: this.createInteractiveVideoObj.mainVideo.Description,
        // WC 07272022 - Add tagging
        Tag: this.createInteractiveVideoObj.mainVideo.Tag,
        // WC 07272022
        Categories: this.createInteractiveVideoObj.mainVideo.Categories,
        time: this.createInteractiveVideoObj.mainVideo.time,
        parentId: this.createInteractiveVideoObj.mainVideo.parentId,
        poster: this.createInteractiveVideoObj.mainVideo.poster,
        videoID: this.createInteractiveVideoObj.mainVideo.videoID,
        isPrivate: this.isPrivate,
        // WC 08092022 - Add subtitleUrl
        subtitleUrl: this.createInteractiveVideoObj.mainVideo.subtitleUrl,
        hubSpotLifeCycle:
          this.createInteractiveVideoObj.mainVideo?.hubSpotLifeCycle,
        aiEditorType: isPathOfChoice ? 'Timeline' : 'Tree'
        // WC 08092022
      };

      if (this.createInteractiveVideoObj.mainVideo?.userPrompt) {
        obj.userPrompt = this.createInteractiveVideoObj.mainVideo?.userPrompt
          ? this.createInteractiveVideoObj.mainVideo?.userPrompt
          : "Make your next choice";
      }

      if (this.assignPublishBadge) {
        obj.viewCompletionBadge = this.assignPublishBadge._id;
      }

      // if (this.createInteractiveVideoObj.mainVideo?.isAIEditorVideo) {
      //   obj.aiEditorType = this.aiEditorType;
      // }

      if (this.groupList.length > 0 || this.followerListForPublic.length > 0) {
        obj.shareTo = {
          groupCount: this.groupLength,
          followerCount: this.followersLength,
          shareWithFollower: this.followerListForPublic,
          shareInGroup: this.groupList,
        };
      }
      
      this.createInteractiveVideoObj.spinner = true;
      this.urlService.updateInteractiveVideo(obj).subscribe(
        (success) => {

          let payload = {
            videoId: this.mainID,
            title: this.createInteractiveVideoObj.mainVideo.Title,
            description: this.createInteractiveVideoObj.mainVideo.Description,
            tags: this.createInteractiveVideoObj.mainVideo.Tag,
            isRestricted: this.isPrivate,
          }
          //WC 031725 -- generateBookmarkVectorIndex is called by the BE in the publish endpoint so no need for UI to call it separately.
          //this.urlService.generateBookmarkVectorIndex(payload).subscribe((success) => { 
            if (!this.createInteractiveVideoObj.mainVideo.isPublished) {
              const finalObj = {
                type: "publish",
                videoID: this.createInteractiveVideoObj.mainVideo._id,
              };
              //WC 031725 --- Move this up front to validate
              // const totalCategories =
              //   this.createInteractiveVideoObj.mainVideo.Categories.length;
              // if (totalCategories < 1) {
              //   this.createInteractiveVideoObj.spinner = false;
              //   this.toastr.warning("Select at least one category");
              // } else if (mainTitle === "") {
              //   this.createInteractiveVideoObj.spinner = false;
              //   this.toastr.error("Title can not be blank");
              // } else {

                  this.urlService.publishunpublish(finalObj).subscribe((success) => {
                    this.createInteractiveVideoObj.spinner = false;
                    this.toastr.success("Published successfully!");
                    $("#tree-publish-vid-Modal").modal("hide");
                    this.cancelPublish();
                    // this.localStorageService.setItem('tempToken', '848484');
                    this.localStorageService.setItem(
                      "latestPublishedVideoId",
                      this.createInteractiveVideoObj.mainVideo._id
                    );
                    this.router.navigate(["my-videos"]);
                  });

              //}
            } else {
              $("#tree-publish-vid-Modal").modal("hide");
              this.cancelPublish();
              // this.localStorageService.setItem('tempToken', '848484');
              this.localStorageService.setItem(
                "latestPublishedVideoId",
                this.createInteractiveVideoObj.mainVideo._id
              );
              this.router.navigate(["my-videos"]);
              this.createInteractiveVideoObj.spinner = false;
            }
          // }, (error) => {
          //   this.createInteractiveVideoObj.spinner = false;
          // })

        },
        (error) => {
          this.toastr.error(error.error.data.message);
          this.createInteractiveVideoObj.spinner = false;
        }
      );
    }

  cancelPublish() {
    $("#tree-publish-vid-Modal").modal("hide");
    this.createInteractiveVideoObj.publish = false;
  }

  getPublicPrivate(event: any) {
    this.isPrivate = event.checked;
    $(document).ready(() => {
      $(".filterBtn,.fa-times").click(() => {
        $("#dropdownMenu").slideToggle();
      });
    });
  }

     getCurrentTimePublish(e) {
      let hasChilren: any = false;
      if (
        this.createInteractiveVideoObj.mainVideo.children == null ||
        this.createInteractiveVideoObj.mainVideo.children == undefined
      ) {
        hasChilren = false;
      } else {
        hasChilren = this.createInteractiveVideoObj.mainVideo.children.length > 0;
      }

      //// console.log(hasChilren,this.createInteractiveVideoObj.mainVideo,'publishVideo',e.target.duration)
      //  this.createInteractiveVideoObj.mainVideo.currentTime = e.target.currentTime
      this.placeMarker(
        hasChilren,
        this.createInteractiveVideoObj.mainVideo.time,
        "publishVideo",
        e.target.duration
      );
    }

    async preview(files) {
      if (files.length === 0) return;

      const mimeType = files[0].type;
      if (mimeType.match(/image\/*/) == null) {
        this.toastr.error("Only images are supported!");
        return;
      }

      const reader = new FileReader();
      // this.createInteractiveVideoObj.mainVideo.imagePath = files;
      const cleanImage = await sanitizeSVG(files[0]);
      if (!cleanImage) {
        this.toastr.error("invalid SVG file");
        return;
      }
      this.createInteractiveVideoObj.mainVideo.fileData = cleanImage;
      reader.readAsDataURL(files[0]);
      reader.onload = (_event) => {
        // this.createInteractiveVideoObj.mainVideo.profilePic = reader.result;
        // this.createInteractiveVideoObj.mainVideo.thumbnail.push(reader.result)
      };
      this.updateProfilePic();
    }

  updateProfilePic() {
    this.createInteractiveVideoObj.spinner = true;
    const formData = new FormData();
    formData.append("file", this.createInteractiveVideoObj.mainVideo.fileData);
    formData.append("VideoID", this.createInteractiveVideoObj.mainVideo._id);

    this.urlService.uploadThumbnails(formData).subscribe((success) => {
      this.toastr.success("Image updated successfully");
      // this.createInteractiveVideoObj.mainVideo.poster = success.data;
      this.createInteractiveVideoObj.mainVideo.thumbnail.push(success.data.url);
      // console.log('success.data = ', success.data);
      // console.log('this.createInteractiveVideoObj.mainVideo.thumbnail = ', this.createInteractiveVideoObj.mainVideo.thumbnail);

      this.createInteractiveVideoObj.spinner = false;
    }, (error) => {
      this.createInteractiveVideoObj.spinner = false;
      this.toastr.error(error.error.data)
    }
    );
  }

  resetFileInput(fileInput: HTMLInputElement) {
    fileInput.value = '';
  }


  deleteThumbnails(i: any) {
    const obj = {
      url: i,
      videoId: this.mainID,
    };
    this.createInteractiveVideoObj.spinner = true;
    this.urlService.deleteThumbnails(obj).subscribe((success) => {
      this.createInteractiveVideoObj.spinner = false;
      for (
        let j = this.createInteractiveVideoObj.mainVideo.thumbnail.length - 1;
        j > -1;
        j--
      ) {
        if (i == this.createInteractiveVideoObj.mainVideo.thumbnail[j]) {
          this.createInteractiveVideoObj.mainVideo.thumbnail.splice(j, 1);
          break;
        }
      }
    });
  }

  openPublishBadgeModal() {
      const dialogRef = this.dialog.open(CreateVideoComponent, {
        data: {
          badges: true,
          links: false,
          videos: false,
        },
        minWidth: "50vw",
      });
      dialogRef.afterClosed().subscribe((result: UploadedFileResponse) => {
        if (result) {
          this.assignPublishBadge = result;
        }
      });
    }

  removeBadge() {
    this.urlService
      .removeMainVideoBadge({ videoId: this.mainID })
      .subscribe((res) => {
        if (res.data.viewCompletionBadge == undefined) {
          this.toastr.success("Badge was successfully removed");
          this.assignPublishBadge = false;
        }
      });
  }

  btnDoNotShow() {
    this.spinner = true
    this.urlService.setDoNotShowItem({item: 'showNewVideoCreationPopup'}).subscribe((res) =>{
       this.spinner = false
       this.isShowNewPopup = false;
     })
  }

  getNewVideoPopup(){
     this.urlService.getDoNotShowItem({item: 'showNewVideoCreationPopup'}).subscribe((res) => {
        if(res.data?.length === 0){
          this.isShowNewPopup = true;
        }else{
          this.isShowNewPopup = false;
        }
      }) 
  }

  saveKeyInsights() {
    this.spinner = true;
    if (this.createInteractiveVideoObj.currentObj.aiKeyInsights.isDisable === undefined) {
      this.createInteractiveVideoObj.currentObj.aiKeyInsights.isDisable = false
    }
    let payload = {
      id: this.mainID,
      aiKeyInsights: this.createInteractiveVideoObj.currentObj.aiKeyInsights
    }
    this.urlService.updateKeyInsights(payload).subscribe((res) => {
      this.spinner = false;
      this.toastr.success("Key insights updated successfully!");
    }, (error) => {
      this.spinner = false;
      this.toastr.error("Error!");
    }) 
  }
  trackByIndex(index: number, obj: any): any {
    return index;
  }

  onChangeDisableValue(event){
    this.createInteractiveVideoObj.currentObj.aiKeyInsights.isDisable = event.target.checked
  }
};