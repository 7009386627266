// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#signatureImage {
    height: 29px;
    padding: 0;
}

canvas {
    display: block;
    border: 1px solid rgb(211 211 211);
    /* width: 465px;
    height: 200px; */
    border-radius: 6px;
}

.video-common-btn {
    width: auto;
}

.signPreviewImg img{
    border: 1px solid #dbdbdb;
}


div#signaturePadModal{
    background: rgba(0, 0, 0, .32);
}

.header__center {
    font-size: 17px;
    display: grid;
    grid-template-columns: 1fr max-content 1fr;
    grid-column-gap: 7px;
    align-items: center;
}

.header__center::before,
.header__center::after {
    content: "";
    display: block;
    height: 1px;
    background-color: #bfbfbf;
}

#certificateForBadge{
    height: 20px;
    width: 17px;
}
/* ::ng-deep .modal-backdrop.show:nth-of-type(even) {
    z-index: 1061 !important;
} */


input[type='checkbox'] {
    accent-color: #6c2fbb;
}

.demo .input-group-sm>.form-control:not(textarea) {
    height: calc(1.5em + .5rem + 8px);
}

@media (max-width: 760px) {
    .video-common-btn {
        width: 46% !important;
    }
}
@media (max-width: 575px) {
    #signaturePadModal .modal-content {
       overflow: auto;
    }
    #signaturePadModal .modal-body {
       width: 500px;
    }
}`, "",{"version":3,"sources":["webpack://./src/app/layout/modals/create-badge/create-badge.component.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,cAAc;IACd,kCAAkC;IAClC;oBACgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;;AAGA;IACI,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,aAAa;IACb,0CAA0C;IAC1C,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;;IAEI,WAAW;IACX,cAAc;IACd,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,YAAY;IACZ,WAAW;AACf;AACA;;GAEG;;;AAGH;IACI,qBAAqB;AACzB;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ;AACA;IACI;OACG,cAAc;IACjB;IACA;OACG,YAAY;IACf;AACJ","sourcesContent":["#signatureImage {\n    height: 29px;\n    padding: 0;\n}\n\ncanvas {\n    display: block;\n    border: 1px solid rgb(211 211 211);\n    /* width: 465px;\n    height: 200px; */\n    border-radius: 6px;\n}\n\n.video-common-btn {\n    width: auto;\n}\n\n.signPreviewImg img{\n    border: 1px solid #dbdbdb;\n}\n\n\ndiv#signaturePadModal{\n    background: rgba(0, 0, 0, .32);\n}\n\n.header__center {\n    font-size: 17px;\n    display: grid;\n    grid-template-columns: 1fr max-content 1fr;\n    grid-column-gap: 7px;\n    align-items: center;\n}\n\n.header__center::before,\n.header__center::after {\n    content: \"\";\n    display: block;\n    height: 1px;\n    background-color: #bfbfbf;\n}\n\n#certificateForBadge{\n    height: 20px;\n    width: 17px;\n}\n/* ::ng-deep .modal-backdrop.show:nth-of-type(even) {\n    z-index: 1061 !important;\n} */\n\n\ninput[type='checkbox'] {\n    accent-color: #6c2fbb;\n}\n\n.demo .input-group-sm>.form-control:not(textarea) {\n    height: calc(1.5em + .5rem + 8px);\n}\n\n@media (max-width: 760px) {\n    .video-common-btn {\n        width: 46% !important;\n    }\n}\n@media (max-width: 575px) {\n    #signaturePadModal .modal-content {\n       overflow: auto;\n    }\n    #signaturePadModal .modal-body {\n       width: 500px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
