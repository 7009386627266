import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-collab-tree-node',
    templateUrl: './collab-tree-node.component.html',
    styleUrls: ['./collab-tree-node.component.css']
})
export class CollabTreeNodeComponent implements OnInit {

    @Input('currentParent') currentParent: any;
    currentVideoId: any;

    constructor() {
    }

    ngOnInit(): void {
    }
}
