// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-primary {
    background: #6c2fbb;
    border-color: #6c2fbb;
}

.btn-link {
    color: #6c2fbb;
    padding: 0;
    margin: 0;
}

td {
    line-break: auto;
    width: 75%;
}

th{
    width: 25%;
}`, "",{"version":3,"sources":["webpack://./src/app/afterLogin/my-plans/plan-details/plan-details.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,cAAc;IACd,UAAU;IACV,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[".btn-primary {\n    background: #6c2fbb;\n    border-color: #6c2fbb;\n}\n\n.btn-link {\n    color: #6c2fbb;\n    padding: 0;\n    margin: 0;\n}\n\ntd {\n    line-break: auto;\n    width: 75%;\n}\n\nth{\n    width: 25%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
