// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/@angular-devkit/build-angular/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.int-home {
    margin: 85px 0;
    padding: 25px 35px;
    width: 100%;
    background-color: white;
    border-radius: 12px;
}

.mat-cell, .mat-header-cell {
    font-size: 14px;
    min-height: 48px;
    text-align: center;
    /* border: 1px solid #e0e0e0; */
    border: 1px solid #d8d8d8;
    text-align: left;
    padding-left: 8px !important;
}

.mat-header-cell{
    background-color: #e1e0f8;
    color: #6c2fbb;
    text-align: left;
    padding-left: 8px !important;
}

.mat-table-div{
    height: 100%;
    max-height: 600px;
    overflow: auto;
    /* overflow-x: scroll; */
    /* overflow-y: scroll; */
    position: relative;
}

.mat-table-div span img, p img, span, .clickAble{
    cursor: pointer;
    text-decoration: underline;
}


::ng-deep .mat-table-div .mat-table thead{
    position: sticky;
    top: -1px;
    z-index: 1;
}
.hl{
    display: flex;
    justify-content: center;
    margin: 0;
}
.hl span{
    border-bottom: 1px solid black;
    display: block;
    width: 20px;
}

.mat-elevation-z8{
    box-shadow: none;
}
.title{
    text-align: center;
    margin-bottom: 22px;
    font-weight: bold;
    color: #6c2fbb
}`, "",{"version":3,"sources":["webpack://./src/app/afterLogin/lead-gen-analytics/lead-gen-analytics.component.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,WAAW;IACX,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,+BAA+B;IAC/B,yBAAyB;IACzB,gBAAgB;IAChB,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,4BAA4B;AAChC;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,cAAc;IACd,wBAAwB;IACxB,wBAAwB;IACxB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,0BAA0B;AAC9B;;;AAGA;IACI,gBAAgB;IAChB,SAAS;IACT,UAAU;AACd;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,SAAS;AACb;AACA;IACI,8BAA8B;IAC9B,cAAc;IACd,WAAW;AACf;;AAEA;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB;AACJ","sourcesContent":[".int-home {\n    margin: 85px 0;\n    padding: 25px 35px;\n    width: 100%;\n    background-color: white;\n    border-radius: 12px;\n}\n\n.mat-cell, .mat-header-cell {\n    font-size: 14px;\n    min-height: 48px;\n    text-align: center;\n    /* border: 1px solid #e0e0e0; */\n    border: 1px solid #d8d8d8;\n    text-align: left;\n    padding-left: 8px !important;\n}\n\n.mat-header-cell{\n    background-color: #e1e0f8;\n    color: #6c2fbb;\n    text-align: left;\n    padding-left: 8px !important;\n}\n\n.mat-table-div{\n    height: 100%;\n    max-height: 600px;\n    overflow: auto;\n    /* overflow-x: scroll; */\n    /* overflow-y: scroll; */\n    position: relative;\n}\n\n.mat-table-div span img, p img, span, .clickAble{\n    cursor: pointer;\n    text-decoration: underline;\n}\n\n\n::ng-deep .mat-table-div .mat-table thead{\n    position: sticky;\n    top: -1px;\n    z-index: 1;\n}\n.hl{\n    display: flex;\n    justify-content: center;\n    margin: 0;\n}\n.hl span{\n    border-bottom: 1px solid black;\n    display: block;\n    width: 20px;\n}\n\n.mat-elevation-z8{\n    box-shadow: none;\n}\n.title{\n    text-align: center;\n    margin-bottom: 22px;\n    font-weight: bold;\n    color: #6c2fbb\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
