import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PageScrollService } from 'ngx-page-scroll-core';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})

export class PrivacyPolicyComponent implements OnInit {

  constructor(private pageScrollService: PageScrollService,
    @Inject(DOCUMENT) private document: any) {}

  ngOnInit() {}

  public scroll(): void {
    this.pageScrollService.scroll({
      document: this.document,
      scrollTarget: '.theEnd'
    });
  }

  getDataRequestForm() {

    return environment.DataRequestForm;
  }

}
