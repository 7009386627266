import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UtilityService } from "src/app/services/utility.service";
import videojs from "video.js";
declare var $: any;

interface VideoPlayerOption {
  fluid?: boolean;
  aspectRatio?: string;
  autoplay: boolean;
  poster: string;
  controls: boolean;
  sources: {
    src: string;
    type: string;
  }[];
}

interface ModalData {
  options: VideoPlayerOption;
  video: any;
}

@Component({
  selector: "app-modal-video-player",
  templateUrl: "./modal-video-player.component.html",
  styleUrls: ["./modal-video-player.component.css"],
})
export class ModalVideoPlayerComponent implements OnInit, OnDestroy {
  @ViewChild("target", { static: true }) target: ElementRef;
  player: videojs.Player;
  timer = 0;

  constructor(
    private readonly utilityService: UtilityService,
    public readonly dialogRef: MatDialogRef<ModalVideoPlayerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ModalData,
  ) {}

  ngOnInit(): void {
    this.setVideoPlayerMetaInfo();
  }

  setVideoPlayerMetaInfo() {
    clearInterval(this.timer);
    const that = this;
    if (this.player) {
      this.player.load();
    } else {
      this.player = videojs(
        this.target.nativeElement,
        this.data.options,
        function onPlayerReady() {
          this.on("loadedmetadata", function (e: any) {
            setTimeout(() => {
              let hasChildren = false;
              if (
                that.data.video.children == null ||
                that.data.video.children === undefined
              ) {
                hasChildren = false;
              } else {
                hasChildren = that.data.video.children.length > 0;
              }
              let plotNumber: any = 0;
              if (that.data.video.children) {
                this.timer = setInterval(() => {
                  if (this.currentTime() > that.data.video.time) {
                    if (plotNumber === 0) {
                      that.addOptions();
                      clearInterval(this.timer);
                    }
                    plotNumber++;
                  } else {
                    plotNumber = 0;
                    that.removeoptions();
                  }
                }, 500);
                that.placeMarker(
                  hasChildren,
                  that.data.video.time,
                  "previewVideo",
                  this.duration()
                );
              } else {
                that.removeoptions();
                $(".marker").remove();
              }
            });
          });
        }
      );
    }
  }

  onCloseModal() {
    this.dialogRef.close();
    clearInterval(this.timer);
  }

  // Marker for Choices
  placeMarker(
    haveChildren: any,
    markerTime: number,
    id: string,
    duration: number
  ) {
    if (!haveChildren) {
      $(".marker").remove();
    } else {
      if (markerTime == null) markerTime = 0;

      if (duration == null) duration = 0;

      const width = $("#" + id).width();
      let precentageOfMarker = (markerTime / duration) * 100;

      if (precentageOfMarker > 100) precentageOfMarker = 99;

      $(".marker").remove();
      $(`#${id} .vjs-progress-holder.vjs-slider.vjs-slider-horizontal`).append(
        `<div class="marker" style="left:${precentageOfMarker}%"></div>`
      );
    }
  }

  addOptions() {
    const that = this;
    let length = 0;
    if (this.data.video.children) {
      for (const i of this.data.video.children) {
        if (!!i.name) {
          length++;
        }
      }
    }
    let width = 100 / length;
    width = Math.round(width);
    let index = 0;
    if (this.data.video.children) {
      for (const i of this.data.video.children) {
        if (!!i.name) {
          index++;
          const backward = this.player.controlBar.addChild("button", {});
          //  //console.log(i)
          const backwardDom = backward.el();
          backwardDom.innerHTML = i.name;
          backwardDom.setAttribute("title", i.name);
          backwardDom.setAttribute("customChoices", "yes");
          backwardDom.setAttribute("id", i._id);
          backwardDom.setAttribute("custom-width", width);
          backwardDom.setAttribute("custom-distance", "distance" + index);
          $(".vjs-control-bar").addClass("added_choices");

          backwardDom.onclick = (e: any) => {
            that.loadNextOption(i);
          };
          // tslint:disable-next-line:only-arrow-functions
          backwardDom.ontouchend = function () {
            that.loadNextOption(i);
          };
        }
      }
    }
  }

  removeoptions() {
    $('.vjs-control.vjs-button[customchoices="yes"]').remove();
    $('.vjs-control-bar').removeClass('added_choices')
  }

  loadNextOption(video: any) {
    clearInterval(this.timer);
    const isUrl = this.utilityService.checkIsUrlMatch(video.URL);
    if (!isUrl) {
        window.open(video.URL, "_blank");
    } else {
      const options = {
        autoplay: true,
        poster: video.poster,
        controls: true,
        sources: [{ src: video.URL, type: "application/x-mpegURL" }],
      };
      this.data.options = options;
      this.data.video = video;
      const player = videojs(this.target.nativeElement);
      player.src({ src: video.URL, type: "application/x-mpegURL" });
      this.setVideoPlayerMetaInfo();
    }
  }

  ngOnDestroy() {
    // destroy player
    if (this.player) {
      this.player.dispose();
    }
    clearInterval(this.timer);
  }
}
