import {NgModule} from '@angular/core';
import {DateAgoPipe} from './date-ago.pipe';
import {MinuteSecondsPipe} from './pipeFormatSecond';
import {SearchPipe} from './pipeSearch';
import {SizePipe} from './pipeSize';
import {SecondsToMinutePipe} from './seconds-to-minute.pipe';
import {SafePipe} from './safe.pipe';
import {SlugifyPipe} from './slugify.pipe';
import { LinkifyPipe } from './linkify.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { FilterPipe } from './filter.pipe';
import { UserFilterPipe } from './user-filter.pipe';


@NgModule({
    declarations: [
        DateAgoPipe,
        MinuteSecondsPipe,
        SecondsToMinutePipe,
        SearchPipe,
        SizePipe,
        SafePipe,
        SlugifyPipe,
        LinkifyPipe,
        SafeHtmlPipe,
        FilterPipe,
        UserFilterPipe,
    ],
    exports: [
        DateAgoPipe,
        MinuteSecondsPipe,
        SecondsToMinutePipe,
        SearchPipe,
        SizePipe,
        SafePipe,
        SlugifyPipe,
        LinkifyPipe,
        SafeHtmlPipe,
        FilterPipe,
        UserFilterPipe
    ],
    providers: [SlugifyPipe]
})
export class PipesModule {
}
