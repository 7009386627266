import {Component, OnInit} from '@angular/core';
import {CrudService} from 'src/app/services/crud.service';
import {ToastrService} from 'ngx-toastr';
import {ActivatedRoute} from '@angular/router';

declare var $;

@Component({
  selector: 'app-seller-onboarding',
  templateUrl: './seller-onboarding.component.html',
  styleUrls: ['./seller-onboarding.component.css']
})
export class SellerOnboardingComponent implements OnInit {

  user: any;
  connectedAccount: any;
  spinner: boolean;
  progress = 0;
  agreeToTerms = false;
  appFeePct = 25;
  returnFromStripe = false;

  constructor(private readonly crudService: CrudService, private route: ActivatedRoute, private toaster: ToastrService) {

    this.route.queryParams.subscribe(params => {
      // Return from Stripe
      //console.log('params.return = ', params.return);

      this.returnFromStripe = true;

    });
  }

  ngOnInit(): void {

    this.spinner = true;

    this.crudService.userDetailsV2().subscribe((response) => {
      this.user = response.data.details;

      if (this.returnFromStripe)
        this.updateOnStripeSiteFlag(this.user._id, false);

      if (this.user?.agreeToTerms)
        this.agreeToTerms = this.user.agreeToTerms;

      //console.log('this.user = ', this.user);

      if (this.user?.appFeePct)
        this.appFeePct = this.user?.appFeePct;

      if (this.user.connectedAccountId) {
        this.agreeToTerms = true;
        // If there's a connectedAccountId, that means user has gone through the onboarding process
        if (this.user.accountOnboardingStatus == 'In Progress') {
          this.spinner = true;
          this.crudService.retrieveConnectedAccount(this.user.connectedAccountId).subscribe((response) => {
            //console.log('retrieveConnectedAccount response = ', response);
            this.user = response.data;
            if (this.user.accountOnboardingStatus === 'Completed') {
              this.progress = 1;
              this.checkSubscriptionGroups();
            }
            if (this.user.accountOnboardingStatus === 'In Progress' || this.user.accountOnboardingStatus === 'Rejected') {
              this.progress = 1; // WC 03092023 Open up the next step as rejected seller is allowed to offer Freemium subscription
              this.checkSubscriptionGroups();
              if (!this.user?.business?.isPartnerProgram) {
                $('#inprogress-Modal').modal('show');
              }
            }
          }).add(() => this.spinner = false);
        } else if (this.user.accountOnboardingStatus === 'Rejected') {
          //console.log("REJECTED Seller!!")
            this.progress = 1; // WC 03092023 Open up the next step as rejected seller is allowed to offer Freemium subscription
            this.checkSubscriptionGroups();
            if (!this.user?.business?.isPartnerProgram) {
              $('#inprogress-Modal').modal('show');
            }
        }else {
          // step 1 complete
          this.progress = 1;
          this.checkSubscriptionGroups();
        }
      } else {
        $('#agreement-Modal').modal('show');
      }
    }).add(() => this.spinner = false);
  };

  sellerOnboard() {
    // No connectedAccountId so the User hasn't gone through the onboarding process then set the account onboarding status to In Progress
    if (this.agreeToTerms) {
      if (!this.user.connectedAccountId) {
        this.spinner = true;
        // Stripe create connected account payload
        const user = {
          email: this.user.email,
          agreeToTerms: this.agreeToTerms
        };

        // Create Stripe connected account and persist the connected account id to the user collection
        this.crudService.createConnectedAccount(user).subscribe(res => {
          //console.log('createConnectedAccount response - res.data = ', res.data);

          this.user.connectedAccountId = res.data.connectedAccountId;

          this.onboardAccount(this.user.connectedAccountId)

        }).add(() => this.spinner = false);
      } else {
        // Seller onboarding is not completed, send them to Stripe onboarding to complete it
        if (this.user.accountOnboardingStatus !== 'Completed') {
          this.onboardAccount(this.user.connectedAccountId);
        }
      }
    } else {
      $('#agreement-Modal').modal('show');
    }
  };

  updateOnStripeSiteFlag(userId: any, onStripeSite: boolean) {

    const obj = {
      _id: userId,
      userOnStripeSite: onStripeSite
    };

    this.crudService.updateProfileProfile(obj).subscribe();
  }

  onboardAccount(connectedAccountId: string) {
    this.spinner = true;
    const payload = {
      connectedAccountId
    };
    // Get the Stripe onboarding URL and redirect the user to it
    this.crudService.onboardConnectedAccount(payload).subscribe(res => {
      if (res.data?.url) {

        // Update the flag to yes to avoid sending emails to the user while they are on Stripe updating their Account
        this.updateOnStripeSiteFlag(this.user._id, true);

        window.location.href = res.data?.url;
      } else {
        this.toaster.error('Error in generating account onboarding URL, please try again later');
      }
    }).add(() => this.spinner = false);
  }

  private checkPlans() {
    this.spinner = true;
    this.crudService.getAllPlans().subscribe((res) => {
      if (res.data && res.data.length) {
        this.progress = 3;
        this.getEmailTemplate();
      }
    }).add(() => this.spinner = false);
  }

  private checkSubscriptionGroups() {
    this.spinner = true;
   const obj = {
      accessType: 'ADMIN',
      isSubscriptionGroup: true
    }
    this.crudService.getAllGroups(obj).subscribe((res) => {
      if (res.data && res.data.length) {
        const subscriptionGroupExist = res.data.find(x => x.isSubscriptionGroup);
        if (subscriptionGroupExist) {
          this.progress = 2;
          this.checkPlans();
        }
      }
    }).add(() => this.spinner = false);
  }

  private getEmailTemplate() {
    this.spinner = true;
    this.crudService.getEmailTemplate(null).subscribe((res) => {
      if (res.data.result) {
        this.progress = 4;
      }
    }).add(() => this.spinner = false);
  }

  setPartnerProgram(isEnrolled: boolean) {

    if (isEnrolled) {

      let business = this.user.business;
      business.isPartnerProgram = true;
  
      const payload = {business: business};
  
      this.crudService.updateProfileProfile(payload).subscribe(res => {
        if (res)
          //console.log("setPartnerProgram - res =", res);
          this.toaster.info("You have been enrolled successfully in FreeFuse Partner Program!")
      });
    } else {
      $('#NotEnrolledInPartnerProgram-Modal').modal('show');
    }
  }
 
}

